import * as React from 'react'
import { useP } from '../../services/i18n';

type ErrorType = {
  error?: string | null
}

function Error({ error }: ErrorType) {

  const p = useP();

  return (
    <div role="alert">
      <p>{p.t('error.defaultMessage')}</p>
      {error && <pre>{error}</pre>}
    </div>
  )
}

export default Error;