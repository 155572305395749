

import { Bars4Icon } from '@heroicons/react/24/outline';
import React, { useState } from 'react'
import Button from '../../Components/Button';
import DescriptionList, { DescriptionListItemAlignType } from '../../Components/DescriptionList/DescriptionList';
import Modal from '../../Components/Modal/Modal';
import ModalFooterAction from '../../Components/Modal/ModalFooterAction';
import { useP } from '../../services/i18n';
import { GET_DONNEES_REFERENCE_MODALE } from '../Support/ModaleCreationTicket/queries';
import ParametrageSupportLogins from './ParametrageSupportLogin/ParametrageSupportLogins';
import { useQuery } from '@apollo/client';

type InfosTechniquesLienType = {
  lien: any
}

export default function InfosTechniquesLien({
  lien
}: InfosTechniquesLienType) {
  const p = useP()
  const [openDetail, setOpenDetail] = useState(false)
  const alignTop: DescriptionListItemAlignType = "top"

  const informationsTechniquesLogin = [
    {
      label: `Nb Paires`,
      value: lien.nombrePaires,
    },
    {
      label: `Porte collecte`,
      value: lien.porteCollecte?.description,
    },
    {
      label: `VLAN`,
      value: lien.vlan,
    },
    {
      label: `Code NRA`,
      value: lien.codeNra,
    },
    {
      label: `Débit Up`,
      value: `${lien.debitUp ? lien.debitUp : "-"} ${lien.debitUp ? "Mo" : ""}`,
    },
    {
      label: `Débit Down`,
      value: `${lien.debitDown ? lien.debitDown : "-"} ${lien.debitDown ? "Mo" : ""}`,
    },
    {
      label: `Paramétrage support`,
      value: <ParametrageSupportLogins lien={lien} openDetail={openDetail} />,
      align: alignTop
    }
  ];


  return <>
    <Button size='small' color='primary' onClick={() => setOpenDetail(true)}>
      <div className='flex items-center'>
        <Bars4Icon className="h-4 w-4 mr-1" />
        <div className=''>{p.t('lien.infosTechniques')}</div>
      </div>
    </Button>

    <Modal
      onClose={() => setOpenDetail(false)}
      isOpen={openDetail}
      content={{
        title: p.t('lien.infosTechniques'),
        body:
          <>
            <DescriptionList datas={informationsTechniquesLogin} columnWidthClassName="w-[250px]" />
          </>,
        footer: <ModalFooterAction onClickAction={() => setOpenDetail(false)} label={p.t('actions.fermer')} />,
      }}
      className="w-full lg:w-6/12"
    />
  </>
}