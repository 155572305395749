import React, { useEffect, useRef, useState } from "react"
import Card from "../../../Components/Card"
import { registerLocale } from "react-datepicker";
import "../../../Components/Datepicker/react-datepicker.css"
import fr from "date-fns/locale/fr";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import parse from 'html-react-parser';

registerLocale("fr", fr);

type CardChartDescriptionType = {
  descriptionHtml: string,
}

export default function CardChartDescription({ descriptionHtml }: CardChartDescriptionType) {
  const descRef = useRef<any>()
  const [descIsOpen, setDescIsOpen] = useState(false)

  useEffect(() => {
    !descIsOpen && descRef?.current?.scrollIntoView({ behavior: "smooth" })
  }, [descIsOpen])

  return <div className="flex flex-col items-center">
    <Card className={{ root: `flex flex-col  w-full ${descIsOpen ? `h-auto` : `max-h-28`} lg:max-h-72 lg:overflow-hidden` }}>
      <div ref={descRef} className="lg:overflow-auto p-2 text-gray-700 text-sm">
        <>{parse(descriptionHtml)}</>
      </div>
    </Card>
    <div
      className="lg:hidden flex items-center justify-center border border-gray-200 bg-white h-10 w-20 rounded-b-md"
      onClick={() => setDescIsOpen(!descIsOpen)}
    >
      {
        descIsOpen
          ? <ChevronUpIcon className="h-5 w-5 text-primary-600" />
          : <ChevronDownIcon className="h-5 w-5 text-primary-600" />
      }
    </div>
  </div>

}