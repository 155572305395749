import React from "react"
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(...registerables, zoomPlugin);

import "chartjs-adapter-moment";
import * as moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

type LineChartType = {
  datasetIdKey: any,
  data: any,
  onClickPoint?: (data: any) => void,
  options: any,
  colorPalette?: Array<string>
}

function LineChart({ datasetIdKey, data, onClickPoint, options, colorPalette }: LineChartType) {

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 4,
        backgroundColor: "#FFF",
        borderWidth: 2,
      },
    },
    plugins: {
      tooltip: {
        enable: true,
      },
      legend: {
        labels: {
          boxWidth: 60,
          boxHeight: 2,
          usePointStyle: true,
        },
        position: "bottom",
        align: "end",
      },
      title: {
        display: false,
      },
    },
    onHover: (event, chartElement) => {
      event.chart.canvas.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: function (evt, element) {
      if (element.length > 0) {
        onClickPoint && onClickPoint(
          data.datasets[element[0].datasetIndex].data[element[0].index]
        );
      }
    },
  };

  const chartData = data;

  chartData.datasets = chartData.datasets.map((d, i) => {
    return {
      ...d,
      parsing: {
        xAxisKey: "codeLegend",
        yAxisKey: "value",
      },
      borderColor: colorPalette ? colorPalette[i] : null
    };
  });
  return (
    <>
      <div className="flex-1 h-full">
        <Line
          options={{ ...defaultOptions, ...options }}
          datasetIdKey={datasetIdKey}
          data={chartData}
        />
      </div>
    </>
  );
}
export default LineChart;
