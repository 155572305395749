const OPERATOR_EQ = { code: "EQ", label: "est", symbol: "=" };
const OPERATOR_NE = { code: "NE", label: "n'est pas", symbol: "≠" };
const OPERATOR_LIKE = { code: "LIKE", label: "contient", symbol: "⊂" };
const OPERATOR_NOTLIKE = {
  code: "NOTLIKE",
  label: "ne contient pas",
  symbol: "⊄",
};

const OPERATOR_INTERVALLE = { code: "BETWEEN", label: "entre" };
const OPERATOR_MIN = { code: "GTE", label: "min" };
const OPERATOR_MAX = { code: "LTE", label: "max" };

const OPERATOR_FIXE = { code: "FIXE", label: "est" };

const STRING_TYPE = "STRING";
const NUMBER_TYPE = "NUMBER";
const DATE_TYPE = "DATE";
const BOOL_TYPE = "BOOLEAN";
const DATA_TYPE = "DATA";
const LIST_TYPE = "LIST";
const SELECT_TYPE = "SELECT";
const EMPTY_TYPE = "EMPTY";

//retourne tab by code EQ => return OPERATOR_EQ

const getOperatorByCode = (code) => {
  let operator = false;
  switch (code) {
    case "EQ":
      operator = OPERATOR_EQ;
      break;
    case "NE":
      operator = OPERATOR_NE;
      break;
    case "LIKE":
      operator = OPERATOR_LIKE;
      break;
    case "NOTLIKE":
      operator = OPERATOR_NOTLIKE;
      break;
    case "BETWEEN":
      operator = OPERATOR_INTERVALLE;
      break;
    case "GTE":
      operator = OPERATOR_MIN;
      break;
    case "LTE":
      operator = OPERATOR_MAX;
      break;
    case "FIXE":
      operator = OPERATOR_FIXE;
      break;
  }
  return operator;
};
const operators = [
  {
    code: "string",
    items: [
      {
        operator: OPERATOR_LIKE,
        type: STRING_TYPE,
      },
      {
        operator: OPERATOR_NOTLIKE,
        type: STRING_TYPE,
      },
      {
        operator: OPERATOR_EQ,
        type: STRING_TYPE,
      },
      {
        operator: OPERATOR_NE,
        type: STRING_TYPE,
      },
    ],
  },

  {
    code: "number",
    items: [
      {
        operator: OPERATOR_EQ,
        type: NUMBER_TYPE,
      },
      {
        operator: OPERATOR_NE,
        type: NUMBER_TYPE,
      },
      {
        operator: OPERATOR_INTERVALLE,
        type: NUMBER_TYPE,
        items: [OPERATOR_MIN, OPERATOR_MAX],
      },
    ],
  },

  {
    code: "date",
    items: [
      {
        operator: OPERATOR_EQ,
        type: DATE_TYPE,
      },
      {
        operator: OPERATOR_INTERVALLE,
        type: DATE_TYPE,
        items: [OPERATOR_MIN, OPERATOR_MAX],
      },
      {
        operator: OPERATOR_FIXE,
        label: "prédéfini",
        type: SELECT_TYPE,
      },
    ],
  },

  {
    code: "bool",
    items: [
      {
        operator: OPERATOR_EQ,
        type: BOOL_TYPE,
      },
    ],
  },

  {
    code: "data",
    items: [
      {
        operator: OPERATOR_EQ,
        type: DATA_TYPE,
      },
    ],
  },

  {
    code: "list",
    items: [
      {
        operator: OPERATOR_EQ,
        type: LIST_TYPE,
      },
    ],
  },
];

export {
  OPERATOR_EQ,
  OPERATOR_NE,
  OPERATOR_LIKE,
  OPERATOR_NOTLIKE,
  OPERATOR_INTERVALLE,
  OPERATOR_MIN,
  OPERATOR_MAX,
  STRING_TYPE,
  NUMBER_TYPE,
  DATE_TYPE,
  BOOL_TYPE,
  DATA_TYPE,
  LIST_TYPE,
  SELECT_TYPE,
  EMPTY_TYPE,
  operators,
  getOperatorByCode,
};
