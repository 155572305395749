import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentContrats_Q1 from "./HelpContent/HelpContentContrats_Q1";

export default function HelpContrats(){
  const p = useP();
  const datas = [{
    libelle : p.t('helpBox.helpContrats.q1') ,
    content : <HelpContentContrats_Q1 />
  }]
  return <>
      <HelpBoxAcordions datas={datas} />
  </>
}