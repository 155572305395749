import Modal from "../Modal/Modal";
import React, { useEffect, useState } from 'react';
import Button from "../Button";
import { useP } from "../../services/i18n";
import { Input } from "../Form/Input";
import { OptionSelectType } from "../Select/Select";
import SelectNative from "../Form/SelectNative";
import InputSelect from "../Form/InputSelect/InputSelect";
import {InputSelectOptionType} from "../Form/InputSelect/InputSelect";
import Badge from "../Badge";

type inputPropsType = {
  label: string,
  type: string,
  placeholder?: string
}

type ModalPromptType = {
  onClose: (boolean, value: string | null | undefined) => void,
  title: string | JSX.Element,
  isOpen: boolean,
  okButtonLabel?: string,
  cancelButtonLabel?: string,
  inputProps: inputPropsType
  selectDatas?: (OptionSelectType | InputSelectOptionType)[]
  value?: string
}

export function ModalPrompt({ title, isOpen, onClose, okButtonLabel, cancelButtonLabel, inputProps, selectDatas = [], value = "" }: ModalPromptType) {
  const p = useP();

  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {

    if (value) {
      setInputValue(value)
    } else {
      switch(inputProps.type){
      case "select" : 
        setInputValue(selectDatas.length > 0 ? (selectDatas[0] as OptionSelectType).code.toString() : '')
      break;
      case "searchselect" : 
        setInputValue(selectDatas.length > 0 ? (selectDatas[0] as InputSelectOptionType).value : '')
      break;
      }
      
    }

  }, [selectDatas, value])
  
  const formatGroupLabel = (data: any) => {
    return(
    <div className="flex items-center pr-2">
      <div className="flex-1">{data.label}</div>
      {!data.options[0].disabled 
        ? <Badge itemColor="primary" itemLibelle={data.options.length} />
        : <Badge itemColor="primary" itemLibelle={0} />
      }
    </div>
  )};
  
  const getInput = (inputProps)=>{
    switch(inputProps.type) {
      case "select":
        return <SelectNative
          label={inputProps.label}
          defaultValue={inputValue}
          selectDatas={selectDatas as Array<OptionSelectType>}
          onSelect={(val) => setInputValue(val)}
        />
      case "searchselect":
        return <InputSelect 
          label={inputProps.label} 
          options={selectDatas as Array<InputSelectOptionType>}
          onChange={(val) => {
            if(val){
              setInputValue(val.value)
            }
          }}
          isMulti={false}
          formatGroupLabel={formatGroupLabel}   
          placeholder={inputProps.placeholder}
        />
      default:
        return <Input
          onChange={(event) => setInputValue(event.target.value)}
          errors={null}
          name=''
          label={inputProps.label}
          type={inputProps.type}
          value={inputValue}
        />
    }
  }

  return <>
    <Modal
      onClose={() => onClose(false, null)}
      isOpen={isOpen}
      content={{
        title: <>{title}</>,
        body: <div className="p-2">
          {getInput(inputProps)}          
        </div>,
        footer: <div className="flex justify-end items-center w-full">
          <div className="flex items-center">
            <div className="flex items-center">
              <Button
                type="button"
                color="primaryLight"
                onClick={() => onClose(false, null)}
                className="mr-4">{cancelButtonLabel ? cancelButtonLabel : p.t('actions.annuler')}</Button>
              <Button
                type="button"
                color="primary"
                disabled={!inputValue}
                onClick={() => onClose(true, inputValue)}
                className="">{okButtonLabel ? okButtonLabel : p.t('actions.ok')}</Button>
            </div>
          </div>
        </div>,
      }}
      className="w-full lg:w-2/6"
    />
  </>
}