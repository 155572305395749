export type CompteType = {
  id: string,
  raisonSociale: string,
  compteParent?: CompteType,
  comptesEnfant: Array<CompteType>
  isVip: boolean
}

export const EmptyCompte: CompteType = {
  id: '0',
  raisonSociale: '',
  compteParent: undefined,
  comptesEnfant: [],
  isVip: false
}
