import React, { useContext, useState } from 'react'
import { authContext } from '../../AuthContext';
import NotificationModal from './NotificationModal';

export default function NotificationsGenerator() {

  const { notifications } = useContext(authContext);
  const [codeNotification, setCodeNotifications] = useState<string | null>(notifications.length > 0 ? notifications[0].code : null);

  const onCloseModal = () => {

    const idx = notifications.findIndex((n) => n.code === codeNotification);
    const nextIdx = idx + 1;

    setCodeNotifications(nextIdx < notifications.length ? notifications[nextIdx].code : null)
  }

  return <NotificationModal code={codeNotification || ''} isOpen={codeNotification !== null} onCloseModal={onCloseModal} />

}