import clsx from 'clsx';
import React, { useRef, useState } from 'react'
import { TableOptionsType } from './Types/TableOptionsType';
import Draggable from 'react-draggable'
import { colWidthResizeType } from './TableContainer';
import { ArrowSmallDownIcon, ArrowSmallUpIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { ColumnType } from './Types/ColumnType';
import { SortingType } from './Thead';
import Tooltip from '../Tooltip/Tooltip';
import { useP } from '../../services/i18n';

type ThType = {
  libelle: string,
  colType?: string,
  tableOptions: TableOptionsType
  width?: string,
  className?: string,
  tableHeight: number,
  colIndex: number,
  columns: Array<any>,
  resizable?: boolean,
  sortable?: boolean,
  colWidthResize?: colWidthResizeType | undefined,
  initialWidth?: string | number | null,
  sortingDirection?: SortingType,
  sortingColumn?: ColumnType,
  selectedRows?: Array<any> | null,
  totalCount?: number,
  selectAll: () => void | null,
  onResize: (width: string, index: number) => void,
  handleSortingChange?: (index: number) => void,
}

function Th({
  libelle,
  colType,
  tableOptions,
  className,
  tableHeight,
  colIndex,
  columns,
  onResize,
  handleSortingChange,
  resizable = true,
  sortable = false,
  initialWidth,
  sortingDirection,
  sortingColumn,
  selectAll,
  selectedRows,
  totalCount
}: ThType) {

  let alignClass;
  let detailClass;
  switch (colType) {
    case "money":
    case "number":
      alignClass = "text-right"
      break;
    case "boolean":
    case "checkbox":
      alignClass = "text-center"
      break;
    case "detail":
      alignClass = "text-left"
      detailClass = "px-2"
      break;
    default:
      alignClass = "text-left"
      break;
  }
  const ThRef = useRef<any | null>(null);
  const ThRefInner = useRef<any | null>(null);
  const ResizerRef = useRef<any | null>(null);
  const DraggableEntity = useRef<any | null>(null);
  const minCellWidth = 100;
  const [newWidth, setNewWidth] = useState('');

  const handleDrag = (e, data) => {

    ResizerRef.current.style.height = `${tableHeight}px`
    ResizerRef.current.classList.add('border-r')
    ResizerRef.current.classList.add('border-primary-500');
    const newWidth = data.x + ThRef.current.offsetWidth;
    if (newWidth >= minCellWidth)
      setNewWidth(newWidth)
  }

  const handleStop = (e, data) => {
    onResize(newWidth, colIndex)

    ThRef.current.style.width = `${newWidth}px`;
    ThRefInner.current.style.width = `${newWidth}px`;

    ResizerRef.current.style.height = ``
    ResizerRef.current.classList.remove('border-r')
    ResizerRef.current.classList.remove('border-primary-500');

    // reset translate draggable element
    ResizerRef.current.style.transform = "";

    // reset draggable state 
    DraggableEntity.current.state.x = 0;
    DraggableEntity.current.state.y = 0;
  }

  const onDoubleClickDragHandle = () => {
    ThRef.current.style.width = `auto`;
    ThRefInner.current.style.width = `auto`;
    onResize('auto', colIndex)
  }

  const columnsVisibles = columns.filter((column) => column.visible !== false && column)
  const DraggableInst: any = Draggable;
  return (
    <>
      <th
        ref={ThRef}
        className={
          clsx(`text-sm font-medium text-gray-800 border-b `, alignClass, detailClass, className,
            sortable && `hover:bg-gray-50 cursor-pointer select-none pl-2`,
            sortable && sortingColumn?.code === columns[colIndex].code ? `border-primary-600 text-primary-600` : `border-gray-200`
          )}
        style={
          initialWidth ? { width: `${initialWidth}` } : {}
        }
        onClick={() => {
          if (sortable) {
            handleSortingChange?.(colIndex)
          }
        }}
      >
        <div
          ref={ThRefInner}
          className={
            clsx(`flex items-center justify-between relative px-2 py-2 m-0`,
              (columnsVisibles.length > colIndex + 1) && `after:absolute after:top-1.5 after:-right-0.5 after:w-0.5 after:h-[26px] after:bg-gray-300  after:rounded-full`,
              tableOptions.fullBordered && `after:hidden`
            )}
          style={
            !tableOptions.naturalWidth && initialWidth ? { width: `${initialWidth}` } : {}
          }

        >

          <div className={clsx(
            `truncate w-full`,
            alignClass
          )}>
            {tableOptions.colSelect && colType === "select" ? (
              <SelectAll selectAll={selectAll} selectedRows={selectedRows} totalCount={totalCount} />
            ) : (
              libelle === "" ? <div>&nbsp;</div> : libelle
            )}
          </div>

          {
            (tableOptions.resizable && resizable) &&
            <DraggableInst
              ref={DraggableEntity}
              nodeRef={ResizerRef}
              axis='x'
              onDrag={handleDrag}
              onStop={handleStop}
              defaultPosition={{ x: 0, y: 0 }}
            >
              <div
                ref={ResizerRef}
                className='absolute top-0 right-0 z-10 h-[36px] cursor-col-resize w-2'
                onDoubleClick={onDoubleClickDragHandle}
              />
            </DraggableInst>
          }
          {sortable &&
            <div className='absolute -left-1 top-3'>
              {
                sortingColumn?.code === columns[colIndex].code ?
                  sortingDirection === "ASC" ?
                    <ArrowSmallUpIcon className='h-3 w-3 text-primary-600' />
                    : <ArrowSmallDownIcon className='h-3 w-3 text-primary-600' />
                  :
                  <ArrowsUpDownIcon className='h-3 w-3 text-gray-500' />
              }
            </div>
          }
        </div>
      </th>
    </>
  )

}

// Composant checkbox pour sélection des ligne du tableau
type SelectAllType = {
  selectedRows?: Array<any> | null,
  totalCount?: number,
  selectAll: () => void | null,
}
function SelectAll({ selectAll, selectedRows, totalCount }: SelectAllType) {
  const p = useP()
  return <>
    <Tooltip
      button={
        <div className='flex items-center justify-center py-1'>
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            onChange={selectAll}
            checked={selectedRows?.length === totalCount}
          />
        </div>
      }
      content={<div className="text-xs">{p.t('components.table.selectAll')}</div>}
      withArrow
    />

  </>
}

export default Th