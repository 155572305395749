import React, { useEffect } from 'react'
import clsx from 'clsx'
import { CheckSquareFill as CheckSquareFillIcon, Square as SquareIcon } from 'react-bootstrap-icons'

export type ButtonsGroupItemButtonType = {
  value: any,
  libelle: string,
  default?: boolean,
  description?: string
}

type ButtonsGroupItemType = {
  button: ButtonsGroupItemButtonType,
  index: number | null,
  lastButton: boolean,
  clickButton: (value: string) => void,
  active: boolean,
  multi: boolean,
  vertical: boolean,
  hasPrepend: boolean,
  selectAll?: boolean,
  forceHorizontalOnMobile?: boolean
}

function ButtonsGroupItem({ button, index, lastButton, clickButton, active, multi, hasPrepend, vertical, forceHorizontalOnMobile, selectAll = false }: ButtonsGroupItemType) {

  let roundedClass = '';
  if (!hasPrepend && index === 0) {
    roundedClass = forceHorizontalOnMobile ? `rounded-l-md rounded-tr-none` : 'rounded-t-md lg:rounded-l-md lg:rounded-tr-none'
  }
  if (hasPrepend && index === 0) {
    roundedClass = 'rounded-t-md lg:rounded-none '
  }

  if (vertical && index === 0) {
    roundedClass = selectAll ? 'rounded-none' : 'rounded-t-md'
  }

  let borderClass = '';
  if (vertical) { borderClass = 'border-b border-x' }
  if (!vertical && !lastButton) borderClass = 'border border-r-0';
  if (!vertical && lastButton) { borderClass = 'border' }

  return (
    <>
      <button
        onClick={() => clickButton(button.value)}
        type="button"
        className={clsx(
          `relative block w-full items-center border-gray-300  text-sm font-medium `,
          ` focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 ring-inset`,
          roundedClass,
          lastButton ? vertical ? `rounded-b-md` : `rounded-r-md` : ``,
          vertical ? `px-2 py-2.5` : `px-2 py-1.5`,
          borderClass,
          active ? 'bg-secondary-200 text-secondary-800 hover:bg-secondary-300  shadow-inner' : `bg-white hover:bg-gray-50 text-gray-700`
        )}
      >
        <div className='flex items-center'>
          {
            multi ?
              active
                ? <CheckSquareFillIcon className='h-3 w-3 mr-1.5' />
                : <SquareIcon className='h-3 w-3 mr-1.5' />
              : <></>
          }
          <div className='truncate'>{button.libelle}</div>
        </div>
      </button>
    </>
  )

}

export default ButtonsGroupItem