import React from "react"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";

export default function HelpContentAnnuaire_Q1(){
  const p = useP()
  return <>
    <P>
      <>{parse(p.t('helpBox.helpAnnuaire.a1'))}</>
    </P>    
  </>
}