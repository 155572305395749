import React from 'react'
import { Disclosure } from '@headlessui/react'

import MobileNavigation from './MobileNavigation/MobileNavigation'
import MobileProfile from './MobileProfile/MobileProfile'

function MobileHeader({ navigation, userNavigation, user }: Header.ContainerProps) {

  return (
    <Disclosure.Panel className="lg:hidden">
      <MobileNavigation navigation={navigation} />
      <MobileProfile userNavigation={userNavigation} user={user.user} />
    </Disclosure.Panel>
  )

}

export default MobileHeader