import React from "react"
import { useP } from "../../../services/i18n";
import { useSearchParams } from "react-router-dom";
import Constants from "../../../commons/Constants";
import { TableOptionsType } from "../../Table/Types/TableOptionsType";
import dayjs from "dayjs";
import TableFactures from "../../../Shell/Factures/TableFactures";
import WidgetLayout from "../WidgetLayout/WidgetLayout";
import WidgetSize from "../WidgetSize";
import WidgetElementType from "../WidgetElementType";

const {
  NB_JOURS_AFFICHAGE_FACTURE_PLUGIN,
} = Constants;

export default function WidgetFacturesWide(props: WidgetElementType) {
  const p = useP();

  const [searchParams] = useSearchParams();
  const numcli = searchParams.get('contrat');

  const tableOptions: TableOptionsType = {
    pagination: false,
    search: false,
    toolbar: false
  }

  // Date du jour et date du jour - XX jours
  const dateFin = dayjs().format("YYYY-MM-DD")
  const dateDebut = dayjs().subtract(NB_JOURS_AFFICHAGE_FACTURE_PLUGIN, 'day').format("YYYY-MM-DD")

  return (

    <WidgetLayout
      header={
        <div>
          {p.t('dashboardCompte.factures').replace('XX', String(NB_JOURS_AFFICHAGE_FACTURE_PLUGIN))}
        </div>
      }
      size={WidgetSize.WIDE}
      addWidget={props.addWidget}
      moveUpWidget={props.moveUpWidget}
      moveDownWidget={props.moveDownWidget}
      removeWidget={props.removeWidget}
    >

      <div className="">
        <TableFactures numcli={numcli === null ? undefined : numcli} selectAnnee={false} dateMinSelecteurAnnee={0} dateMaxSelecteurAnnee={0} tableOptions={tableOptions} dateDebut={dateDebut} dateFin={dateFin} />
      </div>

    </WidgetLayout>
  )

}