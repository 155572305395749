import React, { useContext, useState } from 'react';
import { authContext } from '../../AuthContext';
import Button from '../../Components/Button';
import DescriptionList from '../../Components/DescriptionList/DescriptionList';
import Tabs from '../../Components/Tabs/Tabs';
import { useP } from '../../services/i18n';
import ChangeOtpModeModale from './ChangeOtpModeModale/ChangeOtpModeModale';
import ChangeProfilePasswordModale from './ChangeProfilePasswordModale/ChangeProfilePasswordModale';

export default function ProfilModaleBody() {
  const p = useP();

  const {
    loggedInUser,
  } = useContext(authContext)

  const profilInformations = [
    {
      label: p.t('profil.tabs.informations.nom'),
      value: loggedInUser?.user.nom ? loggedInUser.user.nom : "",
    },
    {
      label: p.t('profil.tabs.informations.prenom'),
      value: loggedInUser?.user.prenom ? loggedInUser.user.prenom : "",
    },
    {
      label: p.t('profil.tabs.informations.email'),
      value: loggedInUser?.user.mail ? loggedInUser.user.mail : "",
    },
    {
      label: p.t('profil.tabs.informations.telephoneMobile'),
      value: loggedInUser?.user.telephoneMobile ? loggedInUser.user.telephoneMobile : "",
    },
    {
      label: p.t('profil.tabs.informations.telephoneFixe'),
      value: loggedInUser?.user.telephoneFixe ? loggedInUser.user.telephoneFixe : "",
    },
  ]

  const profilSecurite = [
    {
      label: p.t('profil.tabs.securite.motDePasse'),
      value: <div className='mb-2.5 md:mb-0 md:mr-2'>
        <Button onClick={() => setModaleChangePasswordOpen(true)} color='primaryLight' size="small" className=''>{p.t('profil.tabs.securite.modifierMotDePasse')} </Button>
      </div>,
    },
    {
      label: p.t('profil.tabs.securite.2FA'),
      value: <div className='mb-2.5 md:mb-0 md:mr-2'>
        <Button onClick={() => setModaleSelect2FAOpen(true)} color='primaryLight' size="small" className=''>{p.t('profil.tabs.securite.selection2FA')} </Button>

      </div>,
    },
  ]

  const profilTabs = {
    navigation: [
      {
        libelle: p.t('profil.tabs.informations.title'),
        jsx: <>{p.t('profil.tabs.informations.title')}</>
      },
      {
        libelle: p.t('profil.tabs.securite.title'),
        jsx: <>{p.t('profil.tabs.securite.title')}</>
      }
    ],
    panels: [
      <DescriptionList datas={profilInformations} key="Infos_Profil" columnWidthClassName='w-[134px]' />,
      <DescriptionList datas={profilSecurite} key="Securite_Profil" columnWidthClassName='w-[134px]' />,
    ]
  }

  const [modaleSelect2FAOpen, setModaleSelect2FAOpen] = useState(false)
  const [modaleChangePasswordOpen, setModaleChangePasswordOpen] = useState(false)

  return <>
    <div className='flex flex-col h-full'>
      <Tabs
        navigation={profilTabs.navigation}
        panels={profilTabs.panels}
        cardStyle={false}
      />
    </div>
    {
      modaleSelect2FAOpen && <ChangeOtpModeModale isOpen={modaleSelect2FAOpen} onClose={() => setModaleSelect2FAOpen(false)} />
    }
    {
      modaleChangePasswordOpen && <ChangeProfilePasswordModale isOpen={modaleChangePasswordOpen} onClose={() => setModaleChangePasswordOpen(false)} />
    }
  </>
}