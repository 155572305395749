import React, { useEffect, useState } from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { Input } from "../../../Components/Form/Input";
import InputCombobox from "../../../Components/Form/InputCombobox/InputCombobox";
import { useP } from "../../../services/i18n";
import { registerLocale } from "react-datepicker";
import "../../../Components/Datepicker/react-datepicker.css"
import fr from "date-fns/locale/fr";
import dayjs from 'dayjs';
import { businessDaysAdd, isWeekday } from "../../../Components/Utils/Utils";
import { GsmMsisdnType } from "../../../types/GsmMsisdn";
import { SiteType } from "../../../types/SiteType";
import SelectionContactEtPersonne from "../../../Components/SelectionContactEtPersonne/SelectionContactEtPersonne";
import DatepickerContainer from "../../../Components/Datepicker/DatepickerContainer";
import { CompteType } from "../../../types/CompteType";

registerLocale("fr", fr);

type ModalCommandeGSMBodyType = {
  register: UseFormRegister<any>,
  control: Control<any>,
  errors: any,
  msisdns: Array<GsmMsisdnType>,
  getValues: (payload?: string | string[]) => any,
  setValue: (name: string, value: unknown, config?: any) => void,
  joursFeries: string[],
  sites: SiteType[],
  siteDefaut: SiteType | null
  dataOnly: boolean,
  reloadMsisdn: () => void,
  compte: CompteType
}

export default function ModalCommandeGSMBody({
  register,
  control,
  errors,
  msisdns,
  reloadMsisdn,
  getValues,
  setValue,
  joursFeries,
  sites,
  siteDefaut,
  dataOnly,
  compte
}: ModalCommandeGSMBodyType) {
  const lignes = msisdns.map((m) => ({
    code: m.msisdn,
    libelle: m.msisdn,
  }))

  const p = useP();

  const [displayDatePortabilite, setDisplayDatePortabilite] = useState(getValues('rio') ? true : false)
  const onChangeRIO = (event) => {
    setValue('rio', event.target.value.toUpperCase().replace(/\s/g, ''))
    if (event.target.value != '') {
      setDisplayDatePortabilite(true)
    } else {
      setDisplayDatePortabilite(false)
    }
  }
  const [startDate, setStartDate] = useState(getValues('dateporta') ? getValues('dateporta') : new Date(businessDaysAdd(3, new Date(), joursFeries).format()));

  const filterDate = (date) => {
    const date3WorkingDays = businessDaysAdd(3, new Date(), joursFeries);
    const date60j = dayjs().add(60, 'day');
    const validDate =
      date >= date3WorkingDays &&
      date <= date60j &&
      !joursFeries.includes(dayjs(date).format("YYYY-MM-DD")) &&
      isWeekday(date);
    return validDate;
  }

  const [contactSelected] = useState(getValues('contact'))

  useEffect(() => {
    getValues('siteId')
  }, [])

  const onChangePersonne = (value) => {
    setValue('contact', value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }
  return <>
    <div className='flex items-start flex-col lg:flex-row'>
      <div className="flex-1">
        <div className="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-12">
            <div className="w-full">
              <Controller
                control={control}
                name="contact"
                defaultValue={contactSelected}
                render={() => <SelectionContactEtPersonne
                  defaultValue={contactSelected}
                  formErrors={errors}
                  onSelectPersonne={(value) => onChangePersonne(value?.obj)}
                  entity={null}
                  inlineEditable={false}
                  filters={[
                    {
                      column: 'compte.id',
                      value: `${compte?.id}`
                    }
                  ]}
                />}
              />
            </div>
          </div>
          <div className="sm:col-span-12">
            <Input className="uppercase" onChange={(event) => setValue('sim', event.target.value.toUpperCase().replace(/\s/g, ''))} register={register} errors={errors} name="sim" label={p.t('commandeGSM.form.sim')} type='text' autoComplete='off' />
          </div>
          {!dataOnly &&
            <div className="sm:col-span-12">
              <Input className="uppercase" onChange={(event) => onChangeRIO(event)} register={register} errors={errors} name="rio" label={p.t('commandeGSM.form.rio')} type='text' autoComplete='off' />
            </div>
          }
          {!dataOnly && displayDatePortabilite &&
            <div className="sm:col-span-12 transition-colors delay-1000 bg-gray-100 p-2 rounded-md">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {p.t('commandeGSM.form.dateporta')}
                <div className="text-xs mt-0.5 text-gray-600 font-normal">{p.t('commandeGSM.form.dateportaHelpText')}</div>
              </label>
              <Controller
                control={control}
                name="dateporta"
                defaultValue={startDate}
                render={({ field }) => <DatepickerContainer
                  {...field}
                  stateDate={startDate}
                  onChange={(date: Date) => {
                    setStartDate(date)
                    setValue('dateporta', date, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                  }}
                  locale="fr"
                  filterDate={filterDate}
                  dateFormat="dd/MM/yyyy"
                  required={true}
                  className="focus:ring-primary-500 focus:border-primary-500 shadow-sm block sm:text-sm border-gray-300 rounded-md w-full"
                  monthPicker={true}
                />}
              />
            </div>
          }
          {!dataOnly && <div className="sm:col-span-12">
            {displayDatePortabilite ?
              <Input
                register={register}
                errors={errors}
                name="numLignePorta"
                label={p.t('commandeGSM.form.numLignePorta')}
                type='text'
                autoComplete='off'
                onChange={(event) => {
                  const trimmedData = event.target.value.replace(/\s/g, '');
                  const truncatedData = trimmedData.substring(0, 10);
                  setValue('numLignePorta', truncatedData)
                }
                }
              />

              : <Controller
                control={control}
                name="numLigne"
                render={({ field }) => <InputCombobox
                  onFocus={() => reloadMsisdn()}
                  errors={errors}
                  options={lignes}
                  label={p.t('commandeGSM.form.numLigne')}
                  autoComplete='off' {...field} />}
              />

            }
          </div>
          }
          <div className="sm:col-span-12">
            <Controller
              control={control}
              name="siteId"
              defaultValue={{
                code: siteDefaut ? siteDefaut.id.toString() : '',
                libelle: siteDefaut ? `${siteDefaut.nom} - ${siteDefaut.codePostal} ${siteDefaut.commune}` : ''
              }}
              render={({ field }) => <InputCombobox
                onFocus={() => reloadMsisdn()}
                errors={errors}
                options={sites.map((s) => ({ code: s.id.toString(), libelle: `${s.nom} - ${s.codePostal} ${s.commune}` }))}
                label={p.t('commandeGSM.form.site')}
                autoComplete='off'
                {...field}
              />
              }
            />
          </div>
          < div className="sm:col-span-12">
            <Input register={register} errors={errors} name="affectation" label={p.t('commandeGSM.form.affectation')} type='text' autoComplete='off' />
          </div>
          <div className="sm:col-span-12">
            <Input register={register} errors={errors} name="matricule" label={p.t('commandeGSM.form.matricule')} type='text' autoComplete='off' />
          </div>

        </div>
      </div>
    </div>
  </>
}