import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import clsx from "clsx";
import React from "react"
import Tooltip from "../Tooltip/Tooltip"

type SplitterCollapserType = {
  onCollapse: () => void;
  collapserRef: any;
  collapsible: 'first' | 'second' | false;
  orientation: "horizontal" | "vertical";
} 

function SplitterCollapser({
  onCollapse,
  collapserRef,
  collapsible,
  orientation,
}:SplitterCollapserType){
  return <>
  <div 
        className={clsx("absolute z-10 lg:group-hover/collapsible:block hidden cursor-pointer transition-all",
        orientation === "vertical" 
          ? `left-1/2 -ml-[12.5px] ${collapsible==="first" ? `bottom-1` : `top-1`}`
          : `bottom-1/2 -mb-[12.5px] ${collapsible==="first" ? `right-1` : `left-1`}`,
        )} 
        ref={collapserRef}
        >
          <Tooltip

            button={
              <span 
                onClick={onCollapse}
                className={clsx("flex items-center justify-center bg-gray-800/50 rounded-sm h-6 w-6",
                )}>
                {collapsible==='first'
                  ? orientation==="vertical" ? <ChevronUpIcon className="text-white h-5 w-5" /> : <ChevronLeftIcon className="text-white h-5 w-5" />
                  : orientation==="vertical" ? <ChevronDownIcon className="text-white h-5 w-5" /> :  <ChevronRightIcon className="h-5 w-5 text-white" />
                }
              </span>
            }
            content={<div className="text-xs">Masquer</div>}
            withArrow={false}
          />  
        </div>
  </>
}
export default SplitterCollapser