import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

type BreadcrumbItemType = {
  item: {
    libelle: string,
    href: string,
    current: boolean
  }
}

function BreadcrumbItem({ item }: BreadcrumbItemType) {
  return (
    <>
      <li key={item.libelle}>
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <Link
            to={item.href}
            className={clsx(`ml-0 text-sm font-medium`,
              item.current
                ? `text-gray-800 hover:text-gray-800`
                : `text-gray-400 hover:text-gray-700`)}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.libelle}
          </Link>
        </div>
      </li>
    </>
  )

}
export default BreadcrumbItem

