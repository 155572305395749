import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentDetailLien_Q1 from "./HelpContent/HelpContentDetailLien_Q1";
import HelpContentDetailLien_Q2 from "./HelpContent/HelpContentDetailLien_Q2";
import HelpContentDetailLien_Q3 from "./HelpContent/HelpContentDetailLien_Q3";
import HelpContentDetailLien_Q4 from "./HelpContent/HelpContentDetailLien_Q4";

export default function HelpDetailLien(){
  const p = useP();
  const datas = [{
    libelle : p.t('helpBox.helpPrestations.helpDetailLien.q1'),
    content : <HelpContentDetailLien_Q1 />
  },{
    libelle : p.t('helpBox.helpPrestations.helpDetailLien.q2'),
    content : <HelpContentDetailLien_Q2/>
  },{
    libelle : p.t('helpBox.helpPrestations.helpDetailLien.q3'),
    content : <HelpContentDetailLien_Q3/>
  },{
    libelle : p.t('helpBox.helpPrestations.helpDetailLien.q4'),
    content : <HelpContentDetailLien_Q4 />
  }]
  return <>
      <HelpBoxAcordions datas={datas} />
  </>
}