import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Constants from '../../../../commons/Constants';
import Badge from '../../../../Components/Badge';
import Button from '../../../../Components/Button';
import { useCheckRight } from '../../../../Components/CheckRight/CheckRight';
import DescriptionList from '../../../../Components/DescriptionList/DescriptionList';
import Editable, { EMPTY_FIELD } from '../../../../Components/Editable/Editable';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Spinner';
import { toastError } from '../../../../Components/Utils/Utils';
import { useP } from '../../../../services/i18n';
import PrestationDetailModaleBody from '../../PrestationDetail/PrestationDetailModaleBody';
import PrestationHistoriqueModaleBody from '../../PrestationDetail/PrestationHistoriqueModaleBody';
import { GET_DONNEES_TECHNIQUES_VCD, SAVE_VCD } from '../../queries';

type PrestationDetailModaleVCDType = {
  isOpen: boolean,
  prestation: any,
  onClose: () => void,
  onSave: (vcd: any) => void
}

const {
  ROLE_ADMIN_PRESTATION,
} = Constants;

function PrestationDetailModaleVCD({ prestation, isOpen = false, onClose, onSave }: PrestationDetailModaleVCDType) {
  
  const p = useP();
  const { checkRight } = useCheckRight();

  const rightAdminPrestation = checkRight(ROLE_ADMIN_PRESTATION);
  const [prestationAffiche, setPrestationAffiche] = useState<any>(prestation);
  const [saveVcd] = useMutation(SAVE_VCD);
  
  const [getDonneesTech, {data: dataTech, loading: loadingTech}] = useLazyQuery(GET_DONNEES_TECHNIQUES_VCD, {
    fetchPolicy: 'network-only',    
  });

  useEffect(() => {
    setPrestationAffiche(prestation)
    if (prestation){
      getDonneesTech({ 
        variables: { 
          vcdId: prestation.vcdId 
        }
      })
    }
  }, [prestation])

  const onSaveVcd = async (vcd) => {
    let result = true;
    
    await saveVcd({
      variables: { vcd: { id: prestationAffiche?.vcdId, ...vcd } },
      onCompleted: (data) => {
        setPrestationAffiche({ ...prestationAffiche, ...vcd })
        onSave(data.saveVcd)
      },
    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
      result = false
    })

    return result;
  }

  const infosVCD =
    [
      {
        label: p.t('prestations.vcd.detail.nom'),
        value: rightAdminPrestation ?
          <Editable value={prestationAffiche?.nom ? prestationAffiche.nom : EMPTY_FIELD} onSaveField={(value) => onSaveVcd({ nom: value })} />
          : prestationAffiche?.nom,
      },      
      {
        label: p.t('prestations.vcd.detail.vm'),
        value: dataTech?.getDonneesTechniquesVcd?.vm ? `${dataTech?.getDonneesTechniquesVcd.vm} ${p.t('prestations.vcd.detail.unite')}` : EMPTY_FIELD,
      },
      {
        label: p.t('prestations.vcd.detail.vRam'),
        value: dataTech?.getDonneesTechniquesVcd?.vRam ? `${dataTech?.getDonneesTechniquesVcd?.vRam} Go` : EMPTY_FIELD,
      },
      {
        label: `${p.t('prestations.vcd.detail.vCpu')} (${dataTech?.getDonneesTechniquesVcd?.cpuSpeed/1000} GHz)` ,
        value: dataTech?.getDonneesTechniquesVcd?.vCpu ? `${dataTech?.getDonneesTechniquesVcd?.vCpu} ${p.t('prestations.vcd.detail.unite')}` : EMPTY_FIELD,
      },
      {
        label: p.t('prestations.vcd.detail.stockage'),
        value: dataTech?.getDonneesTechniquesVcd?.stockage ? `${dataTech?.getDonneesTechniquesVcd?.stockage} Go` : EMPTY_FIELD,
      },
      {
        label: p.t('prestations.vcd.detail.backup'),
        value: dataTech?.getDonneesTechniquesVcd?.backups ? `${dataTech?.getDonneesTechniquesVcd?.backups} ${p.t('prestations.vcd.detail.jour')}` : EMPTY_FIELD,
      },
      {
        label: p.t('prestations.vcd.detail.ip'),
        value: dataTech?.getDonneesTechniquesVcd?.ipAllocation ? `${dataTech?.getDonneesTechniquesVcd?.ipAllocation} ${p.t('prestations.vcd.detail.unite')}` : EMPTY_FIELD,
      },
    ]

  const VCDTabs = {
    navigation: [
      {
        libelle: p.t('prestations.detail.informations'),
        jsx: <>{p.t('prestations.detail.informations')}</>
      },
      {
        libelle: p.t('prestations.detail.historique'),
        jsx: <>{p.t('prestations.detail.historique')}</>
      }
    ],
    panels: [
      <DescriptionList datas={infosVCD} key="Infos_VCD" columnWidthClassName='w-[200px]' />,
      <PrestationHistoriqueModaleBody key="Historique_VCD" prestationId={prestation.id} />,
    ]
  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('prestations.vcd.detail.title')}</div>
              <div className='ml-1'>{prestationAffiche?.nom}</div>
              <div className='ml-2'><Badge itemLibelle={prestation?.etat?.libelle} itemColor={prestation?.etatColor} /></div>
            </div>            
          </>
          ,
          body: loadingTech ? <Spinner /> : <PrestationDetailModaleBody tabsNavigation={VCDTabs.navigation} tabsPanel={VCDTabs.panels} />,
      
          footer: <><div className='flex items-center justify-end'>
            <Button onClick={onClose}>
              {p.t('actions.fermer')}
            </Button>
          </div></>,
        }}
        className="w-full lg:w-6/12 lg:h-5/6"
      />
    </>
  )
}

export default PrestationDetailModaleVCD