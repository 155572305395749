import clsx from "clsx";
import React, { HTMLProps } from "react";


type TreeContainerType = {
  containerProps: HTMLProps<any>,
  children: React.ReactNode
}

export default function TreeContainer({ containerProps, children }: TreeContainerType) {
  return (
    <>
      <div className={clsx("renderTreeContainer")}>
        <ul className="" {...containerProps}>
          {children}
        </ul>
      </div>
    </>
  );
}
