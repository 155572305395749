import React, { useEffect, useState } from "react";
import Niveau from "../Niveau";
import { useParams } from 'react-router-dom';
import { GET_ABONNEMENTS } from "../../queries";
import { useQuery } from "@apollo/client";
import Spinner from "../../../../Components/Spinner";
import Produit from "../Produit";
import Button from "../../../../Components/Button";
import InputSwitch from "../../../../Components/Form/InputSwitch";
import { useP } from "../../../../services/i18n";

type ModalContratBodyType = {
  idAbo: any,
  withOutVente: boolean
}
export default function ModalContratBody({ idAbo, withOutVente }: ModalContratBodyType) {
  const p = useP();
  const { numcli } = useParams();

  const { loading, data: dataAbonnements } = useQuery(GET_ABONNEMENTS, {
    variables: {
      numContrat: numcli,
      withOutVente: withOutVente,
      idAbonnement: idAbo
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  if (loading)
    return <div className="flex items-center justify-center h-[300px] w-full p-10"><Spinner /></div>

  // Cas si problème sur abonnement
  if (!dataAbonnements.abonnementsActifs)
    return <></>

  // Y'a t'il au moins un niveau ?
  let isNiveau = false;
  dataAbonnements.abonnementsActifs[0].lignes.forEach(element => {
    if (element.lignes.length != 0)
      isNiveau = true
  });

  // S'il y a au moins niveau, affichage accordéon
  // Sinon affichage des produits seuls
  return <>
    <div className="pl-4 pr-6">
      {(isNiveau) ? <Niveau withOutVente={withOutVente} datas={dataAbonnements.abonnementsActifs[0].lignes} openAllAccordions={true} /> : <div className='pl-4 pb-4'><Produit withOutVente={withOutVente} datas={dataAbonnements.abonnementsActifs[0].lignes} /></div>}
    </div>
  </>
}