import clsx from 'clsx'
import React, { ReactNode } from 'react'
import Spinner from './Spinner'

export type ButtonsColorType = 'primary' | 'secondary' | 'primaryLight' | 'secondaryLight' | 'white' | 'transparent' | 'secondaryBordered' | 'grayBordered' | 'primaryBordered' | 'ghost'

export type ButtonsType = {
  children: string | ReactNode,
  color?: ButtonsColorType,
  size?: 'small' | 'normal' | 'large' | 'medium',
  className?: string,
  onClick?: () => void,
  type?: 'button' | 'submit',
  disabled?: boolean,
  loading?: boolean
}

const buttonColors = {
  'primary': 'bg-primary-500 hover:bg-primary-600 focus:ring-primary-600/80 text-white shadow-sm',
  'primaryLight': 'bg-primary-100 hover:bg-primary-200 focus:ring-primary-300/50 text-primary-600 shadow-sm',
  'secondary': 'bg-secondary-500 hover:bg-secondary-600 focus:ring-secondary-600 text-white shadow-sm',
  'primaryBordered': 'bg-white hover:bg-white focus:ring-transparent text-primary-600 border border-primary-600 shadow-sm',
  'secondaryBordered': 'bg-white hover:bg-white focus:ring-transparent text-secondary-600 border border-secondary-600 shadow-sm',
  'grayBordered': 'bg-white hover:bg-gray-100 focus:ring-transparent text-gray-600 border border-gray-300 shadow-sm',
  'secondaryLight': 'bg-secondary-200 hover:bg-secondary-300 focus:ring-secondary-300 text-secondary-600 shadow-sm',
  'white': 'bg-white hover:bg-gray-100 focus:ring-primary-300 text-primary-700 shadow-sm',
  'transparent': 'bg-white/30 hover:bg-white/40 text-white shadow-sm',
  'ghost': 'bg-transparent border-transparent shadow-none'
}

const buttonSizes = {
  'small': 'px-1.5 py-1 text-xs ',
  'medium': 'px-2 py-1.5 text-sm ',
  'normal': 'px-3 py-2 text-sm ',
  'large': 'px-6 py-4 text-sm ',
}

function Button({ children, color = 'primary', size = 'normal', className, onClick, type = 'button', disabled = false, loading = false }: ButtonsType) {

  const colorClasses = buttonColors[color]
  const sizeClasses = buttonSizes[size]

  return (
    <>
      <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={clsx(
          `inline-flex items-center justify-center border leading-4 font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-inset`,
          disabled ? 'bg-gray-200 text-gray-400' : colorClasses,
          //colorClasses,
          sizeClasses,
          className)}
      >
        {loading
          ? <div className='mr-1'>
            <Spinner
              size={size === "small" ? "h-4 w-4" : "h-5 w-5"}
              color={
                disabled
                  ? "gray"
                  : color === "primary" || color === "secondary" ? "white" : "primary"
              }
            />
          </div>
          : <></>}{children}
      </button>
    </>
  )

}

export default Button
