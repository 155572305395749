import React, { useState } from 'react'
import { useP } from '../../services/i18n'
import Card from '../../Components/Card';
import Table from '../../Components/Table/Table';
import { useQuery } from '@apollo/client';
import { GET_CONTRATS } from './queries';
import ButtonsGroup from '../../Components/ButtonsGroup/ButtonsGroup';
import { useNavigate } from 'react-router-dom';
import Constants from '../../commons/Constants';
import CellDateFormatter from '../../Components/Table/Formatters/CellDateFormatter';

const {
  CONTRAT_STATE_ACTIVE,
  CONTRAT_STATE_TACITE,
  CONTRAT_STATE_TERMINE,
  CONTRAT_STATE_VERSION_PRELIMINAIRE,
} = Constants;


export default function TableContrats() {
  const p = useP();
  const navigate = useNavigate();
  // Table Datas
  const { loading, data } = useQuery(GET_CONTRATS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      sorters: [
        {
          column: "numcli",
          order: "ASC"
        },
      ],
      attributes: ["numcli", "nom", "dateDebut", "dateFin", "contratEnProduction"],
      includes: [
        {
          association: 'prestations',
          attributes: ['type', 'etat', 'dateResiliation'],
          includes: [
            {
              association: 'site',
              attributes: ['id'],
            }
          ]
        },
        {
          association: 'statutContrat',
          attributes: ['code', 'libelle'],
        },
        {
          association: 'siteFacturation',
          attributes: ['libelleVoie', 'numeroVoie', 'codePostal', 'commune']
        }

      ]
    },
  });

  const filterPrestations = (p, types) => {
    return types.includes(p.type) && p.dateResiliation === null && p.site !== null
  }

  const datasTableContrat = data?.clients.items.map((item) => {

    let color = "";
    switch (item.statutContrat.code) {
      case CONTRAT_STATE_ACTIVE: color = "emerald"; break;
      case CONTRAT_STATE_TERMINE: color = "red"; break;
      case CONTRAT_STATE_TACITE: color = "orange"; break;
      case CONTRAT_STATE_VERSION_PRELIMINAIRE: color = "blue"; break;
    }

    return {
      ...item,
      siteFacturationFormate: <div className='truncate'>{item.siteFacturation?.numeroVoie ? item.siteFacturation?.numeroVoie : ""} {item.siteFacturation?.libelleVoie} <br /> {item.siteFacturation?.codePostal} {item.siteFacturation?.commune}</div>,
      telephonie: item.prestations.filter((p) => filterPrestations(p, ["GSM", "VGA", "NDI", "CENTILE", "TRUNK"])).length,
      data: item.prestations.filter((p) => filterPrestations(p, ["LIEN"])).length,
      contratState: { ...item.statutContrat, colorClasses: `bg-${color}-200 text-${color}-800` }
    }
  })

  const [filterStatut, setFilterStatut] = useState<any>([CONTRAT_STATE_ACTIVE, CONTRAT_STATE_VERSION_PRELIMINAIRE, CONTRAT_STATE_TACITE]);

  const filtreDatas = (datas) => {
    if (!datas) return [];
    return datas.filter((d) => (filterStatut.includes(d?.statutContrat.code)))
  }

  // Définition des colonnes de la table Contacts : 
  const columns = [
    {
      libelle: p.t('contrats.columns.contrat'),
      code: 'numcli',
      colType: 'string',
      sortable: true
      //width: "150px",
    },
    {
      libelle: p.t('contrats.columns.statut'),
      code: 'contratState',
      colType: 'state',
    },
    {
      libelle: p.t('contrats.columns.nom'),
      code: 'nom',
      colType: 'string',
      sortable: true
      //width: "300px",
    },
    {
      libelle: p.t('contrats.columns.siteFacturation'),
      code: 'siteFacturationFormate',
      colType: 'string',
      //width: "300px",
    },
    {
      libelle: p.t('contrats.columns.dateDebut'),
      code: 'dateDebut',
      colType: 'date',
      sortable: (_, row: any)=> row.contratEnProduction ? row.dateDebut : "",      
      customFormatter: (row: any) => (
        row.contratEnProduction ? <CellDateFormatter value={row.dateDebut} /> : "Déploiement en cours")      
    },
    {
      libelle: p.t('contrats.columns.dateFin'),
      code: 'dateFin',
      colType: 'date',
      sortable: (_, row: any)=> row.contratEnProduction ? row.dateFin : "",      
      customFormatter: (row: any) => (
        row.contratEnProduction ? <CellDateFormatter value={row.dateFin} /> : "-")      
    },
    {
      libelle: p.t('contrats.columns.data'),
      code: 'data',
      colType: 'number',
      sortable: true
    },
    {
      libelle: p.t('contrats.columns.telephonie'),
      code: 'telephonie',
      colType: 'number',
      sortable: true
    },
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: (row) => {
        navigate(`${row.numcli}`)
      },
      resizable: false
    },
  ];

  // Filtres statut
  const filterButtonsStatuts = data ? data.statutsContrat.map((s) => ({
    libelle: s.libelle, value: s.code
  })) : [];

  return (
    <>
      <Card className={{ root: "flex-1 h-full" }}>
        <Table
          idTable='ec-table-contrats-pagination'
          loading={loading}
          datas={filtreDatas(datasTableContrat)}
          columns={columns}
          options={{
            resizable: false,
            search: {
              placeholder: p.t('contrats.rechercher'),
              attributs: ["numcli", "nom", "siteFacturation"]
            }
          }}
          customToolbarActions={
            <>
              <div className='flex items-center'>
                <div><ButtonsGroup selectedButtons={filterStatut} onSelectedButton={(value) => setFilterStatut(value)} buttons={filterButtonsStatuts} multi={true} /></div>
              </div>
            </>
          }
          onClickRow={(row) => navigate(`${row.numcli}`)}
        />
      </Card>
    </>
  )

} 