import React, { ReactNode } from "react";
import clsx from "clsx";
import Card from "./Card";
import Spinner from "./Spinner";

type CardIconItemType = {
  id: string | number,
  name?: string,
  statType?: string,
  classNameIconColor: string,
  icon: any,
  loading?: boolean,
  error?: string | null,
}


type CardIconType = {
  item: CardIconItemType,
  bordered?: boolean,
  shadow?: boolean,
  align?: 'top' | 'center' | 'bottom'
}

const afficheStat = (item) => {
  if (item.loading) {
    return (
      <div className="pt-3 pb-2 h-full w-full">
        <div className="h-full w-full flex items-center justify-center">
          <Spinner color="primary" />
        </div>
      </div>
    );
  }

  if (item.error) {
    return (
      <div className="flex items-center justify-center h-full italic">
        Une erreur est survenue
      </div>
    );
  }
  return item.stat;
};

const DefaultStat = ({ item }: CardIconType) => {
  return (
    <>
      <p className="text-sm font-medium text-gray-500 truncate">{item.name}</p>
      <span className="text-2xl font-semibold text-primary-700">
        {afficheStat(item)}
      </span>
    </>
  );
};

const CardIcon = ({ item, bordered, shadow, align = 'top' }: CardIconType) => {
  let stat;
  switch (item.statType) {
    case "JSX":
      stat = <>{afficheStat(item)}</>;
      break;
    case "default":
    default:
      stat = <DefaultStat item={item} />;
      break;
  }

  let alignClass;
  switch (align) {
    case 'center':
      alignClass = 'items-center'
      break;
    case 'bottom':
      alignClass = 'items-end'
      break;
    case 'top':
    default:
      alignClass = 'items-start'
      break;
  }

  return (
    <>
      <Card overflow={false} bordered={bordered} shadow={shadow}>
        <div key={item.id} className={clsx(`relative flex`, alignClass)}>
          <div className={clsx(`h-full flex`, alignClass)}>
            <div
              className={clsx(
                `rounded-md p-3 h-[52px] w-[52px] flex items-center justify-center`,
                item.classNameIconColor
              )}
            >
              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
          </div>
          <div className="ml-3 w-full">{stat}</div>
        </div>
      </Card>
    </>
  );
};

export default CardIcon;
