import React from "react";
import { TicketType } from "../types/TicketType";
import Tooltip from "../Components/Tooltip/Tooltip";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Badge from "../Components/Badge";
import { useP } from "../services/i18n";
import HeaderIcon from "../Shell/Header/HeaderIcon";

type TicketPublicHeaderType = {
  ticket: TicketType,
}

function TicketPublicHeader({ ticket }: TicketPublicHeaderType) {

  const p = useP()

  return <>

    <div className="w-full mx-auto pr-4 sm:pr-6 lg:pr-8 bg-primary-800 shadow-lg">
      <div className="flex items-center justify-between h-14">
        <div className="flex items-center">
          <HeaderIcon />
        </div>
      </div>
    </div>

    <div className="border-b border-gray-200 px-4 py-4 flex justify-between items-center">
      <h3 className="text-xl font-medium leading-6 text-primary-900 flex-1">
        <div>
          {ticket.qualificatif} N° {ticket.numero} - {ticket.ticketStatut.statutPublic}
        </div>
        <div className='flex items-center space-x-2 pt-2'>
          {ticket.activiteTotaleImpactee && <Tooltip
            button={
              <ExclamationTriangleIcon className='text-red-600 h-5 w-5' />
            }
            content={<div className="text-xs">{p.t('support.detail.activiteTotaleImpactee')}</div>}
            withArrow
          />}
          <div><Badge itemLibelle={ticket.ticketCriticite.libelle} itemColor='red' /></div>
          <div className='text-gray-600 text-base'>{ticket.titre}</div>
        </div>
      </h3>
    </div>
  </>
}

export default TicketPublicHeader;

