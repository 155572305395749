import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import useToggle from '../../Hooks/UseToggle'
import MultiSelectContainer from './MultiSelectContainer'
import MultiSelectSearchBox from './MultiSelectSearchBox'
import { MultiSelectOptionType } from './Types/MultiSelectOptionType'


type MultiSelectType = {
  datas: Array<MultiSelectOptionType>,
  placeholder?: string,
  alwaysVisible?: boolean, // option permettant un affichage systématique de la liste (true) ou affichage par toggle et recherche en mode dropdown ( false ) 
  showBadges?: boolean, // option permettant d'afficher une liste des élément sélectionnés dans la searchbox du composant
  onSelect: (values: Array<MultiSelectOptionType>) => void,
  searchBoxClassName?: string,
  dropdownClassName?: string,
  dropdownContainerClassName?: string,
}

export default function MultiSelect(
  { datas = [],
    showBadges,
    placeholder,
    onSelect,
    alwaysVisible = true,
    searchBoxClassName,
    dropdownClassName,
    dropdownContainerClassName = "flex-1",
  }: MultiSelectType) {

  const [filteredList, setFilteredList] = useState(datas)
  const [searchValue, setSearchValue] = useState('');

  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useToggle();


  useEffect(() => {
    const filtered = datas.filter(value => value.libelle.toUpperCase().includes(searchValue.toUpperCase()))
    setFilteredList(filtered)
  }, [searchValue])

  useEffect(() => {
    if (datas.length > 0) {
      setFilteredList(datas)
      setSelected(datas.filter((opt) => opt.selected))
    }
  }, [datas])

  const onFilter = (v) => {
    setSearchValue(v)
    setIsOpenDropdownMenu(true)
  }

  const [selected, setSelected] = useState<Array<MultiSelectOptionType>>([])

  const toggleOption = (option) => {
    if (selected.includes(option)) {
      removeOption(option)
    } else {
      setSelected(selected.concat(option))
    }
  }

  const removeOption = (option) => {
    const filteredOption = selected.filter((e) => e !== option);
    setSelected(filteredOption);
  }

  useEffect(() => {
    onSelect(selected)
  }, [selected])

  return <>
    <div className='relative flex flex-col flex-1 overflow-hidden'>
      <div className="relative flex flex-col flex-1 overflow-hidden">
        <div className='flex flex-col flex-1 overflow-hidden'>
          <MultiSelectSearchBox
            searchBoxClassName={searchBoxClassName}
            datas={datas}
            onFilterList={onFilter}
            showBadges={showBadges}
            placeholder={placeholder}
            alwaysVisible={alwaysVisible}
            toggleDropdown={() => setIsOpenDropdownMenu()} />
          <div className={
            clsx((isOpenDropdownMenu || alwaysVisible) ? `flex flex-col overflow-hidden` : `hidden`, dropdownContainerClassName)
          }>
            <MultiSelectContainer
              dropdownClassName={dropdownClassName}
              toggleOption={toggleOption}
              datas={filteredList}
              selected={selected}
              alwaysVisible={alwaysVisible} />
          </div>
        </div>
      </div>

    </div >
  </>


}
