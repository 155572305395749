import { gql } from "@apollo/client"

const GET_TICKETS = gql`
  query Tickets($filters: [FilterInput], $sorters: [SorterInput], $includes: [IncludeInput]) {
    tickets(filters: $filters, sorters: $sorters, includes: $includes) {          
      items {
        id
        numero
        titre
        qualificatif
        dateCreation
        dateModification
        activiteTotaleImpactee
        ticketCriticite {
          libelle
        } 
        ticketStatut {
          id
          statutPublic
        }          
        compte {
          raisonSociale
        }        
      }
    }   
  }`


const GET_TICKETS_STATUTS = gql`
  query TicketStatuts {
    ticketStatutPublics {
      code
      libelle
      isClos
      ticketStatuts {
        id
      }
    }
  }`


const TELECHARGER_TICKETS_CSV = gql`
  query TelechargerTicketCsv {
    telechargerTicketCsv {
      filename
      hash
    }
  }`


const NEW_TICKET_COMMENTAIRE = gql`
  mutation NewTicketCommentaireClient($ticketId: ID!, $commentaire: String!) {
    newTicketCommentaireClient(ticketId: $ticketId, commentaire: $commentaire) {
      message
      success
    }
  }
`
const TICKET_CLOTURE_CLIENT = gql`
  mutation TicketClotureClient($ticketId: ID!) {
    ticketClotureClient(ticketId: $ticketId) {
      message
      success
    }
  }
`

const TICKET_REOUVERTURE = gql`
  mutation TicketReouverture($ticketId: ID!, $commentaire: String!) {
    ticketReouverture(ticketId: $ticketId, commentaire: $commentaire) {
      message
      success
    }
  }
`

export {
  GET_TICKETS,
  GET_TICKETS_STATUTS,
  TELECHARGER_TICKETS_CSV,
  NEW_TICKET_COMMENTAIRE,
  TICKET_CLOTURE_CLIENT,
  TICKET_REOUVERTURE,
}