import React from 'react'
import Card from '../Components/Card';
import Textarea from '../Components/Form/Textarea';
import Logo from '../Components/Logo';
import Rating from '../Components/Rating/Rating';
import { useP } from '../services/i18n';
type SatisfactionProjetContainerType = {
  submit: () => void
  note?: number,
  setNote?: any,
  commentaire: string,
  nom: string,
  setCommentaire: any,
}

function SatisfactionProjetContainer(props: SatisfactionProjetContainerType) {

  const p = useP()

  const onSubmit = (event) => {
    event.preventDefault();
    props.submit()
  }

  return (
    <>
      <div className="flex-1 flex flex-col justify-center p-4">
        <div className='flex items-center justify-center'>
          <Logo className="w-52 drop-shadow-2xl" />
        </div>
        <div className="mx-auto w-full max-w-md py-4">
          <Card bordered className={{ root: "p-4" }}>
            <Card.Header>
              <div>{p.t('satisfactionProjet.titre')}</div>
              <div className='text-gray-700 text-sm mt-2 font-normal text-justify'>{p.t('satisfactionProjet.sousTitre')}</div>
            </Card.Header>
            <div className="mt-3 p-2">
              <form className="space-y-6" onSubmit={onSubmit}>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {p.t('satisfactionProjet.form.projet')}
                  </label>
                  <div className="mt-1 text-sm font-medium text-primary-700">
                    {props.nom}
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    {p.t('satisfactionProjet.form.note')}
                  </label>
                  <div className="mt-1">
                    <Rating
                      sizeClass='h-10 w-10'
                      precision={1}
                      onSelect={(note) => props.setNote(note)}
                      value={props.note}
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    {p.t('satisfactionProjet.form.commentaire')}
                  </label>
                  <div className="mt-1">
                    <Textarea
                      name='commentaire'
                      value={props.commentaire}
                      onChange={e => props.setCommentaire(e.target.value)}
                      height={100}
                    />
                  </div>
                </div>

                <div className="flex flex-row space-x-2">

                  <button
                    type='submit'
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {p.t('actions.enregistrer')}
                  </button>
                </div>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default SatisfactionProjetContainer