import React from 'react';
import { ArrowSmallDownIcon, ArrowSmallUpIcon, PlusIcon, TrashIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useP } from '../../../services/i18n'

type WidgetCardHeaderMenuType = {
  addWidget: any,
  moveUpWidget: any,
  moveDownWidget: any,
  removeWidget: any
}

function WidgetCardHeaderMenu({
  addWidget,
  moveUpWidget,
  moveDownWidget,
  removeWidget
}: WidgetCardHeaderMenuType) {

  const p = useP();

  return (
    <div>
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md pl-4 pt-1 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <EllipsisHorizontalIcon
              className='w-6 h-6 text-gray-400 hover:text-gray-500'
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >

          <Menu.Items className="absolute z-50 right-2 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className='text-sm p-3 bg-gray-100'>
              {p.t("widgets.header.title")}
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? 'bg-primary-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={addWidget}
                  >
                    <PlusIcon
                      className="mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    {p.t("widgets.header.addNewWidget")}
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? 'bg-primary-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={moveUpWidget}
                  >

                    <ArrowSmallUpIcon
                      className="mr-2 h-5 w-5"
                      aria-hidden="true"
                    />

                    {p.t("widgets.header.moveUpWidget")}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? 'bg-primary-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={moveDownWidget}
                  >

                    <ArrowSmallDownIcon
                      className="mr-2 h-5 w-5"
                      aria-hidden="true"
                    />

                    {p.t("widgets.header.moveDownWidget")}
                  </button>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? 'bg-red-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={removeWidget}
                  >

                    <TrashIcon
                      className="mr-2 h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />

                    {p.t("widgets.header.removeWidget")}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default WidgetCardHeaderMenu