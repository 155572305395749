import React, { useEffect, useState } from "react"
import { ThumbnailType } from "./DocumentationThumbnail"
import { DocumentType } from "../../../types/DocumentType"
import DocumentationFichiersContainer from "./DocumentationFichiersContainer"
import FooterModalDocument from "./FooterModalDocument"
import Constants from "../../../commons/Constants"
import Button from "../../../Components/Button"
import { useLazyQuery, useMutation } from "@apollo/client"
import { APPROUVE_DOCUMENT, TELECHARGER_DOCUMENT } from "../queries"
import { copyTextToClipboard, toastError, toastSuccess } from "../../../Components/Utils/Utils"
import { useP } from "../../../services/i18n"
import dayjs from "dayjs"
import { DocumentApprovalType } from "../../../types/DocumenApprovalType"
import { Squares2X2Icon, TableCellsIcon } from "@heroicons/react/24/outline"
import { SwitchIconDataType } from "../../../Components/SwitchIcon/SwitchIcon"
import { useSearchParams } from "react-router-dom"

type DocumentationFichiersType = {
  dataDocuments: Array<DocumentType>,
  dataDocumentApprovals: Array<DocumentApprovalType>,
  loadingDocuments: boolean,  
}

const {
  VIGNETTES,
  LISTE,
  APPROBATION_READ,
  FILER_URL
} = Constants

function DocumentationFichiers({ dataDocuments, dataDocumentApprovals, loadingDocuments }: DocumentationFichiersType) {
  const p = useP();
  const [searchParams] = useSearchParams();

  const [modalDocumentationPdViewerIsOpen, setModalDocumentationPdViewerIsOpen] = useState<boolean>(false)
  const [modalThumbnail, setModalThumbnail] = useState<ThumbnailType | null>(null)
  const [modalPdfFile, setModalPdfFile] = useState<string>("")
  const [scrollBarIsAtBottom, setScrollBarIsAtBottom] = useState(false)
  const [datas, setDatas] = useState<Array<ThumbnailType>>([])

  // Téléchargement du document
  const [telechargerDocument] = useLazyQuery(TELECHARGER_DOCUMENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const openDocument = (documentTelecharge, thumbnail) => {
    if (documentTelecharge) {
      const { hash, filename } = documentTelecharge
      setModalPdfFile(`${FILER_URL}?hash=${hash}&filename=${filename}`)
      setModalThumbnail(thumbnail)
      setModalDocumentationPdViewerIsOpen(true)
    } else {
      toastError(p.t('documentation.erreurDocumentNonDisponible'))
    }
  }

  useEffect(() => { 
    const thumbnail = datas.find((d) => d.guid === searchParams.get("file"))
    if (thumbnail){
      telechargerDocument({
      variables: {
        documentId: thumbnail.id,
      },
      onCompleted: async (data) => {
        openDocument(data.telechargerDocument, thumbnail)       
      },
    })
  }
  }, [searchParams, datas])

  const [approuveDocument] = useMutation(APPROUVE_DOCUMENT)

  const handleClickThumbnail = (thumbnail: ThumbnailType) => {
    setScrollBarIsAtBottom(false)
    telechargerDocument({
      variables: {
        documentId: thumbnail.id,
      },
      onCompleted: async (data) => {
        openDocument(data.telechargerDocument, thumbnail)       
      },
    })
  }

  const onClosePdfViewer = () => {
    setModalDocumentationPdViewerIsOpen(false)
  }

  const onApprouveDocument = () => {

    approuveDocument({
      variables: {
        documentId: modalThumbnail?.id
      },
      onCompleted: () => {
        toastSuccess(p.t('documentation.approvalSuccess'));

        const updateDatas = [...datas]
        const idx = updateDatas.findIndex((d) => d.id === modalThumbnail?.id)
        if (idx !== -1) {
          updateDatas[idx] = {
            ...updateDatas[idx],
            dateApproval: dayjs().format("YYYY-MM-DD HH:mm")
          }
        }
        setDatas(updateDatas)

        onClosePdfViewer()
      },
      onError: () => {
        toastError(p.t('error.defaultMessage'))
      }
    })
  }

  useEffect(() => {
    setDatas(dataDocuments.map((d) => {
      const approval = dataDocumentApprovals.find((a) => a.document?.id === d.id && a.versionApproval === d.version)
      return {
        id: d.id,
        guid: d.guid,
        titre: d.titre,
        description: d.description,
        extension: d.extension,
        version: d.version,
        dateModification: d.dateModification,
        approvalMode: d.approvalMode,
        dateApproval: approval?.versionApproval === d.version ? approval.dateApproval : null
      }
    }))
  }, [dataDocuments])

  const switchIconsDatas = [
    {
      id: 0,
      label: VIGNETTES,
      icon: <Squares2X2Icon className='h-4 w-4' />,
    },
    {
      id: 1,
      label: LISTE,
      icon: <TableCellsIcon className='h-4 w-4' />,
    }
  ]

  const [selectedView, setSelectedView] = useState<SwitchIconDataType>(switchIconsDatas[0])

  const handleCopyLink = (thumbnail: ThumbnailType) => {
    const url = `${window.location.origin}/${p.t("router.compte.documentation.route")}/${thumbnail.guid}`
    copyTextToClipboard(url, 
      () => toastSuccess(p.t("documentation.toasts.lienCopie")),
      () => toastError(p.t("error.defaultMessage")))
  }

  return <DocumentationFichiersContainer
    datas={datas}
    loading={loadingDocuments}
    onClickThumbnail={handleClickThumbnail}
    onCopyLink={handleCopyLink}
    onClosePdfViewer={onClosePdfViewer}
    scrollBarIsAtBottom={(isAtBottom) => setScrollBarIsAtBottom(isAtBottom)}
    modalPdfViewerTitle={modalThumbnail?.titre || ""}
    modalPdfFile={modalPdfFile}
    modalDocumentationPdViewerIsOpen={modalDocumentationPdViewerIsOpen}
    footerComponent={
      modalThumbnail?.approvalMode === APPROBATION_READ ?
        < FooterModalDocument
          scrollBarIsAtBottom={scrollBarIsAtBottom}
          thumbnail={modalThumbnail}
          onApprouve={onApprouveDocument}
        /> :
        <div className="flex items-center justify-center">
          <Button onClick={() => onClosePdfViewer()}>Fermer</Button>
        </div>
    }
    switchIconsDatas={switchIconsDatas}
    onSelectView={(view) => setSelectedView(view)}
    selectedView={selectedView}
  />

}

export default DocumentationFichiers;