import clsx from 'clsx'
import React, { Fragment, useEffect } from 'react'
import Card from '../../Card'
import WidgetSize from '../WidgetSize'
import WidgetCardHeader from './WidgetCardHeader'

type WidgetLayoutType = {
    scrollable?: boolean,
    libelle?: string,
    Icon?: any,
    number?: string | number | null | undefined,
    color?: string,
    header?: any,
    children: any,
    size: WidgetSize
    addWidget: any,
    moveUpWidget: any,
    moveDownWidget: any,
    removeWidget: any
}



export default function WidgetLayout({ scrollable, libelle = '', Icon = Fragment, number = null, color = 'white', size, header, children, addWidget, moveUpWidget, moveDownWidget, removeWidget }: WidgetLayoutType) {

    return (
        <>
            <Card className={{ root: `w-full` }}>
                <Card.Header>

                    <WidgetCardHeader
                        libelle={libelle}
                        Icon={Icon}
                        number={number}
                        color={color}
                        header={header}
                        addWidget={addWidget}
                        moveUpWidget={moveUpWidget}
                        moveDownWidget={moveDownWidget}
                        removeWidget={removeWidget}
                    />

                </Card.Header>
                <div className={clsx(
                    "lg:overflow-y-auto overflow-x-hidden p-2",
                    `${scrollable && (size === WidgetSize.TINY ? `max-h-[25vh]` : `max-h-[40vh]`)}`,
                )}>
                    {children}
                </div>
            </Card>
        </>
    )
}