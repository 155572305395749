import React, { useState } from 'react';

import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

type InputWithClearButtonProps = {
    value: string,
    setValue: (value: string) => void
}

const InputWithClearButton = ({ value, setValue }: InputWithClearButtonProps) => {

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleClear = () => {
        setValue('');
    };

    return (
        <div className="group flex flex-row items-center justify-center px-2 py-0.5 ">
            <div>
                <MagnifyingGlassIcon className='w-4 h-4' />
            </div>
            <input
                type="text"
                value={value}
                onChange={handleChange}
                className="w-full border-0 rounded-md focus:outline-none focus:ring-0 text-sm "
                placeholder='Rechercher'
                onKeyDown={(e) => e.stopPropagation()}
            />

            {value && (
                <button
                    type="button"
                    onClick={handleClear}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-transparent group-hover:text-gray-600 p-0.5 rounded-full group-hover:bg-gray-200 hover:bg-gray-300 transition"
                >
                    <XMarkIcon className='w-3 h-3' />
                </button>
            )}
        </div>
    );
};

export default InputWithClearButton;