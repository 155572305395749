import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentPrestations_Q1 from "./HelpContent/HelpContentPrestations_Q1";
import HelpContentPrestations_Q2 from "./HelpContent/HelpContentPrestations_Q2";

export default function HelpPrestations(){
  const p = useP();

  
  const datas = [{
    libelle : p.t('helpBox.helpPrestations.q1'),
    content : <HelpContentPrestations_Q1/>
  },{
    libelle : p.t('helpBox.helpPrestations.q2'),
    content : <HelpContentPrestations_Q2/>
  }]
  return <>
      <HelpBoxAcordions datas={datas} />
  </>
}