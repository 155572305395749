import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useP } from '../../../../services/i18n';
import OtpModeType from '../OtpModeType';

type OtpModeSelectionType = {
    otpModes: Array<OtpModeType>,
    otpMode: OtpModeType,
    onChangeOtpMode: (value: string) => void
    setCanContinue: (value: boolean) => void
}

function OtpModeSelection({ otpModes = [], otpMode, onChangeOtpMode, setCanContinue }: OtpModeSelectionType) {

    const p = useP();

    useEffect(() => {
        setCanContinue(!otpMode?.isDisabled())
    }, [otpMode])

    return (
        <>
            <div className='text-gray-800 font-medium'>{p.t('profil.tabs.securite.otpSelection.otpMode')}</div>
            <div className="space-y-4">
                {otpModes.map((value) => (
                    <div key={value.code} className="relative flex items-start">
                        <div className="flex h-5 items-center">
                            <input
                                id={value.code}
                                aria-describedby={`${value.code}-description`}
                                name="otpMode"
                                type="radio"
                                defaultChecked={value.code === otpMode.code}
                                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                                onChange={() => onChangeOtpMode(value.code)}
                                disabled={value?.isDisabled()}
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor={value.code} className={clsx(
                                "font-medium text-gray-700",
                                value.isDisabled() && 'line-through'
                            )}>
                                {value.libelle}
                            </label>
                            <p id={`${value.code}-description`} className="text-gray-500">
                                {value.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )

}

export default OtpModeSelection;