import React from 'react'

export default function PopInformationModalBodyPegase() {
  return <>
    <div className="text-md px-8 xl:px-24">
      <h1>
        <span className="mt-2 block text-4xl font-bold leading-8 tracking-tight text-primary-700 sm:text-3xl">Votre espace client devient multi-comptes !</span>
      </h1>

      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Les utilisateurs qui gèrent <strong>plusieurs comptes</strong> peuvent désormais lister tous les éléments de l&apos;ensemble des comptes.</h2>
        </div>
      </div>
      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-4/12 text-gray-500'>
          <div className="mt-4 text-md leading-5 text-gray-500">La nouvelle version de la liste de sélection des comptes, présente dans la barre de menus, permet de définir les comptes pour lequels vous souhaitez travailler.<br /><br />Tous les listings de l&apos;espace client s&apos;adaptent en affichant la colonne &quot;compte&quot;.</div>
        </div>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/multi_comptes.png" alt="" className='rounded-md shadow-xl border-gray-200 border' />
        </div>
      </div>

      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <h1>
          <span className="mt-2 block text-4xl font-bold leading-8 tracking-tight text-primary-700 sm:text-3xl">Nouvelle version de votre interface de support !</span>
        </h1>
      </div>

      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Nous mettons à votre disposition une nouvelle version de votre outil &quot;Support&quot; qui vous permet de créer des tickets et de les suivre.</h2>
        </div>
      </div>

      <div className='flex flex-col-reverse xl:flex-row items-start mb-8 space-y-reverse  space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/nouveau_pegase_menu.png" alt="" className='rounded-md shadow-xl border-gray-200 border' />
        </div>
        <div className='w-full xl:w-4/12 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Un nouveau menu</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">L&apos;accès à la nouvelle version se fait par le menu &quot;<strong>Support (V2)</strong>&quot;.<br /><br />L&apos;ancien outil reste encore accessible le temps que vous preniez le nouvel outil en main.</div>
        </div>
      </div>

      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-4/12 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Le listing des tickets</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">La liste des tickets reprend l&apos;ergonomie et le fonctionnement (filtre, recherche, tri,...) des listes que vous avez déjà rencontré dans notre espace client.<br /><br />Le bouton &quot;<strong>Créer un ticket</strong>&quot; vous permet de saisir une nouvelle demande ou un nouvel incident.</div>
        </div>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/nouveau_pegase_creer_ticket.png" alt="" className='rounded-md shadow-xl border-gray-200 border' />
        </div>
      </div>

      <div className='flex flex-col-reverse xl:flex-row items-start mt-8 space-y-reverse  space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/nouveau_pegase_formulaire_ticket.png" alt="" className='rounded-md shadow-xl border-gray-200 border' />
        </div>
        <div className='w-full xl:w-8/12 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Le nouveau formulaire</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Le formulaire a subit une évolution ergonomique mais les champs à votre disposition n&apos;ont pas changé.<br /><br />Vous pouvez désormais <strong>coller des captures d&apos;écran</strong> directement dans la description du ticket pour faciliter la compréhension de votre demande par nos équipes.</div>
        </div>
      </div>

      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-4/12 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Le suivi des tickets</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Le détail des tickets vous permet de suivre leur évolution et de retrouver l&apos;ensemble de votre échange avec nos équipes.<br /><br />Comme lors de la création des tickets, vous pouvez coller des captures d&apos;écran dans la description du ticket.</div>
        </div>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/nouveau_pegase_suivi_ticket.png" alt="" className='rounded-md shadow-xl border-gray-200 border' />
        </div>
      </div>

      <div className='flex flex-col-reverse xl:flex-row items-start mt-8 space-y-reverse  space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/nouveau_pegase_notation_ticket.png" alt="" className='rounded-md shadow-xl border-gray-200 border' />
        </div>
        <div className='w-full xl:w-4/12 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>L&apos;évaluation des tickets</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Lors de la clôture des tickets, vous serez invité à donner votre avis sur leur prise en charge et à noter la réponse apportée.<br />Si vous le jugez nécessaire, vous pourrez aussi commenter votre évaluation.</div>
        </div>
      </div>

      <div className="mt-8 mb-6 text-md leading-5 text-gray-500 flex items-center justify-center">
        <div>Très bonne navigation !</div>
      </div>

    </div>
  </>
}