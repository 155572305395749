import React from "react";

type PType = {
  children?: JSX.Element|JSX.Element[]|string;
}

export default function P({children}:PType){
  return <>
    <div className="mt-2 text-xs text-justify">{children}</div>
  </>
}