import clsx from "clsx";
import React from "react";

import { Folder2, Folder2Open } from "react-bootstrap-icons";
import { TreeItem, TreeItemRenderContext } from "react-complex-tree";

type CustomTreeItem = TreeItem & {
  notification?: boolean
}

type ItemType = {
  item: CustomTreeItem,
  children: any,
  title: React.ReactNode,
  arrow: React.ReactNode,
  context: TreeItemRenderContext,
  iconsItem?: object,
  depth: number,
  notification?: boolean
}

function NotificationIcon() {
  return (<div className="h-2 w-2 bg-red-700 rounded-full absolute -top-0.5 -right-0.5"></div>)
}

export default function Item({
  item,
  children,
  title,
  arrow,
  context,
  iconsItem,
  depth
}: ItemType) {
  const defaultIcons = {
    folderOpen: <Folder2Open className="h-4 w-4 text-primary-500" />,
    folder: <Folder2 className="h-4 w-4" />,
    noChildren: <Folder2 className="h-4 w-4" />,
  };
  const iconsConfig = { ...defaultIcons, ...iconsItem };

  return (
    <li {...context.itemContainerWithChildrenProps}>
      <div
        {...context.itemContainerWithoutChildrenProps}
        {...context.interactiveElementProps}
        className={clsx(
          `group flex items-center p-1 my-0.5 cursor-pointer rounded-md `,
          `text-sm text-gray-700  hover:bg-gray-100 focus:ring-inset focus:ring-1 focus:border-none focus:ring-primary-500 focus:outline-none`,
          context.isSelected || context.isDraggingOver
            ? `bg-secondary-100 hover:bg-secondary-100`
            : ``,
        )}
        style={{ paddingLeft: `${depth * 8}px` }}
      >
        {item.isFolder ? (
          arrow
        ) : (
          <div className={"w-8 lg:w-6"}></div>
        )}

        {!item.isFolder ? (
          <div className="relative flex items-center justify-center">
            {iconsConfig.noChildren}
            {item.notification && <NotificationIcon />}
          </div>
        ) : context.isExpanded ? (
          <div className="relative">{iconsConfig.folderOpen}{item.notification && <NotificationIcon />}</div>
        ) : (
          <div className="relative">{iconsConfig.folder}{item.notification && <NotificationIcon />}</div>
        )}

        <div className="flex-1 mx-2 text-sm font-medium">{title}</div>
      </div>
      {children}
    </li >
  );
}
