import React, { useEffect } from "react"
import { getDataFromHostname } from "../utils/utils";

function FaviconManager() {

  useEffect(() => {
    // Fonction pour définir le favicon en fonction du nom de domaine
    const setFavicon = () => {

      const {favicon, manifest, description, title: titleHost} = getDataFromHostname()

      document.querySelector('link[rel="icon"]')?.setAttribute('href', `${process.env.PUBLIC_URL}/${favicon}`);     
      document.querySelector('link[rel="manifest"]')?.setAttribute('href', `${process.env.PUBLIC_URL}/${manifest}`);     
      document.querySelector('meta[name="description"]')?.setAttribute('content', description);     
      
      const title = document.querySelector('title')
      if (title)
        title.innerHTML = titleHost
    };

    // Appelez la fonction au montage du composant
    setFavicon();
  }, []);

  return null; // Ce composant ne rend rien
}

export default FaviconManager;