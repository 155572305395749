import React, { ReactNode } from 'react'


type ToolbarType = {
  children: ReactNode
}

function Toolbar({ children }: ToolbarType) {

  return (
    <>
      <div className="flex flex-col items-end lg:flex-row justify-between mt-0 mb-2">
        {children}
      </div>
    </>
  )

}

export default Toolbar