import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowPathIcon, LockClosedIcon } from '@heroicons/react/24/outline'

import { useP } from '../../services/i18n';
import Button from '../../Components/Button';
import SequentialInput from '../../Components/SequentialInput';
import Constants from '../../commons/Constants';

const { OTP_MODE_SMS, OTP_MODE_APPLICATION, OTP_MODE_EMAIL } = Constants;

type MfaModaleType = {
  open: boolean,
  setOpen: (value: boolean) => void,
  submit: (otp: string) => void,
  reset: () => void,
  loading: boolean,
  otpMode: string
}

function MfaModale(props: MfaModaleType) {

  const p = useP();

  const [value, setValue] = useState<string>('');

  const OTP_LENGTH = 6;

  const onClickReset = () => {
    setValue('');
    props.reset();
  }

  const onKeyDown = (event) => {

    const { key } = event;

    switch (key) {
      case 'Enter':
        if (value.length === OTP_LENGTH) {
          props.submit(value);
        }
        break;

      case 'Escape':
        props.setOpen(false);
        break;
    }

  }

  const getSubtitle = () => {

    let res = '';
    switch (props.otpMode) {
      case OTP_MODE_EMAIL:
        res = p.t('login.mfaModale.subtitleEmail');
        break;

      case OTP_MODE_APPLICATION:
        res = p.t('login.mfaModale.subtitleApplication');
        break;

      case OTP_MODE_SMS:
        res = p.t('login.mfaModale.subtitleSms')
        break;
    }

    return res;
  }

  return (
    <>

      <Transition.Root show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => false}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg" onKeyDown={onKeyDown}>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                        <LockClosedIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          {p.t('login.mfaModale.title')}
                        </Dialog.Title>

                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {getSubtitle()}
                          </p>
                        </div>

                        <div className="mt-5">
                          <SequentialInput valueLength={OTP_LENGTH} value={value} onChange={setValue} />
                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-3 py-3 sm:flex sm:flex-row items-center justify-between">

                    <div className="flex flex-row items-center space-x-1 cursor-pointer mb-6 mt-3 sm:mt-0 sm:mb-0" onClick={onClickReset}>
                      <p className="text-sm text-gray-500 underline">
                        {p.t('login.mfaModale.resend')}
                      </p>
                      <ArrowPathIcon className="w-4 h-4" />
                    </div>

                    <div className="space-x-2">
                      <Button color='primaryLight' onClick={() => props.setOpen(false)}>{p.t('login.mfaModale.annuler')}</Button>
                      <Button color='primary' disabled={value.length !== OTP_LENGTH} loading={props.loading} onClick={() => props.submit(value)}>{p.t('login.mfaModale.valider')}</Button>
                    </div>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )

}

export default MfaModale;