import React from 'react'
import Logo from '../../Components/Logo';

import { useP } from '../../services/i18n';
import PasswordStrength from './PasswordStrength/PasswordStrength';

type ChangePasswordContainerType = {
  submit: () => void
  currentPassword?: string,
  setCurrentPassword?: any,
  password: string,
  setPassword: any,
  checkPassword: string,
  setCheckPassword: any,
  passwordLevel: number,
  setPasswordLevel: any,
  loggedIn?: boolean
  canCancel?: boolean
  onClose?: any
}

function ChangePasswordContainer(props: ChangePasswordContainerType) {

  const p = useP();

  const onSubmit = (event) => {
    event.preventDefault();
    props.submit()
  }

  return (
    <div className="flex-1 flex flex-col justify-center p-4">
      <div className="mx-auto w-full max-w-sm lg:w-96 py-4">
        <div>     
          <Logo className='h-12 w-auto pb-2' />          
          <h2 className="text-3xl font-extrabold text-gray-900">{p.t("changePassword.title")}</h2>
          <p className="mt-2 text-sm text-gray-600">
            {p.t("changePassword.subtitle")}
          </p>
        </div>

        <div className="mt-8">
          <div>
            <div className="mt-6 relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">

              </div>
            </div>
          </div>

          <div className="mt-6">
            <form className="space-y-4" onSubmit={onSubmit}>

              {
                props.loggedIn && (
                  <div className="space-y-1">
                    <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700">
                      {p.t("changePassword.changePasswordForm.oldPassword")}
                    </label>
                    <div className="mt-1">
                      <input
                        id="oldPassword"
                        name="oldPassword"
                        type="password"
                        autoComplete="old-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                        value={props.currentPassword}
                        onChange={e => props.setCurrentPassword(e.target.value)}
                      />
                    </div>
                  </div>
                )
              }


              <div className="space-y-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {p.t("changePassword.changePasswordForm.password")}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    value={props.password}
                    onChange={e => props.setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="space-y-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {p.t("changePassword.changePasswordForm.confirmPassword")}
                </label>
                <div className="mt-1">
                  <input
                    id="password-confirm"
                    name="password-confirm"
                    type="password"
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    value={props.checkPassword}
                    onChange={e => props.setCheckPassword(e.target.value)}
                  />
                </div>
              </div>

              <PasswordStrength password={props.password} checkPassword={props.checkPassword} passwordLevel={props.passwordLevel} setPasswordLevel={props.setPasswordLevel} />

              <div className="flex flex-row space-x-2">
                {
                  props.canCancel && (
                    <button
                      type='button'
                      onClick={props.onClose}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-primary-100 hover:bg-primary-200 focus:ring-primary-300/50 text-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                      {p.t("changePassword.changePasswordForm.cancel")}
                    </button>
                  )
                }
                <button
                  type='submit'
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {p.t("changePassword.changePasswordForm.submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  )
}

export default ChangePasswordContainer