
import React from "react"
import { Bar } from "react-chartjs-2"
import chroma from "chroma-js"
type BarChartType = {
  datasetIdKey: any,
  data: any,
  options: any,
  plugins: Array<any>,
  colorPalette?: Array<string>
}

export default function BarChart({ datasetIdKey, data, options, plugins, colorPalette }: BarChartType) {
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    borderWidth: 1,
  }

  const chartData = data;
  chartData.datasets = chartData.datasets.map((d, i) => {
    const alphaColor = chroma(colorPalette ? colorPalette[i] : "black").alpha(0.4);
    return {
      ...d,
      borderColor: colorPalette ? colorPalette[i] : null,
      backgroundColor: colorPalette ? alphaColor.hex() : null
    };
  });

  return <>
    <div className="flex-1 h-full">
      <Bar
        options={{ ...defaultOptions, ...options }}
        datasetIdKey={datasetIdKey}
        data={chartData}
        plugins={plugins}
      />
    </div>
  </>
}