import React, { useState } from 'react'
import Rating from '../../../Components/Rating/Rating'
import Textarea from '../../../Components/Form/Textarea'
import { useP } from '../../../services/i18n';

type ModaleRatingTicketBodyType = {
  cloture: boolean
  noteDefaut?: number
  commentaireDefaut?: string
  isModifiable?: boolean
  onChange: (note: number, commentaire: string) => void
  error?: string
}

export default function ModaleRatingTicketBody({ cloture, noteDefaut = 5, commentaireDefaut = "", isModifiable = true, onChange, error }: ModaleRatingTicketBodyType) {

  const p = useP();
  const [commentaire, setCommentaire] = useState(commentaireDefaut)
  const [note, setNote] = useState<number>(noteDefaut)

  return <>

    <div className="mb-2 flex flex-col justify-center items-center">
      {cloture && <div className='text-gray-700 text-sm font-medium'>
        {p.t('support.detail.modaleRating.texteAideCloture')}
      </div>}

      <div className="mt-2">
        <Rating
          sizeClass='h-8 w-8'
          precision={1}
          onSelect={(note) => {
            setNote(note)
            onChange(note, commentaire)
          }}
          value={note}
          readonly={!isModifiable}
        />
      </div>

    </div>

    <div className="mb-2 flex flex-col justify-center items-center">
      <div className="mt-1 w-full">
        <Textarea
          placeholder={p.t('support.detail.modaleRating.placeholderCommentaire')}
          name='commentaire'
          value={commentaire}
          onChange={e => {
            setCommentaire(e.target.value)
            onChange(note, e.target.value)
          }}
          className="h-[100px]"
          disabled={!isModifiable}
        />
        {
          error && <div className='text-red-600 text-xs font-medium mt-0.5'>{error}</div>
        }
      </div>

    </div>
    <div className='text-gray-700 mt-4 text-sm font-medium'>
      <div>{p.t('support.detail.modaleRating.texteAide')}</div>
    </div>
  </>
}