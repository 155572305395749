import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import ButtonsGroup from '../../../../Components/ButtonsGroup/ButtonsGroup';
import Table from '../../../../Components/Table/Table'
import { useP } from '../../../../services/i18n';
import { PrestationsViewType } from '../../PrestationsType';
import { GET_VCC } from '../../queries';
import PrestationDetailModaleVCC from './PrestationDetailModaleVCC';

export default function VCCView({ siteId }: PrestationsViewType) {

  const [openModaleDetailPrestation, setOpenModaleDetailPrestation] = useState(false)
  const [modalePrestation, setModalePrestation] = useState<number>(0)

  const onOpenModaleDetailPrestation = (row) => {
    setModalePrestation(row)
    setOpenModaleDetailPrestation(true)
  }
  const onCloseModaleDetailPrestation = () => {
    setOpenModaleDetailPrestation(false)
  }
  const editRow = (row) => { onOpenModaleDetailPrestation(row) }

  // Table Datas
  const { loading, data } = useQuery(GET_VCC, {
    variables: {
      idSite: siteId,
    },
  });

  const onSaveVcc = (vcc) => {
    const newTableDatas = [...tableDatas]
    const vccIndex = newTableDatas.findIndex((e) => e.vccId == vcc.id)
    if (vccIndex >= 0) {
      const vccUpdate = { ...newTableDatas[vccIndex], ...vcc }
      newTableDatas[vccIndex] = vccUpdate
      setTableDatas(newTableDatas)
    }
  }

  const p = useP();

  const columns = [
    {
      libelle: p.t('prestations.vcc.columns.etat'),
      code: 'etat',
      colType: 'state',
    },
    {
      libelle: p.t('prestations.vcc.columns.nom'),
      code: 'nom',
      colType: 'string',
      sortable: true
    },   
    {
      libelle: p.t('prestations.vcc.columns.pool'),
      code: 'tenantId',
      colType: 'string',
      sortable: true
    },        
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: editRow,
      resizable: false
    }, 
  ]

  const filterButtons = [
    { libelle: p.t('etat.prestation.EN SERVICE'), value: "EN SERVICE" },
    { libelle: p.t('etat.prestation.INITIEE'), value: "INITIEE" },
    { libelle: p.t('etat.prestation.COMMANDE EN COURS'), value: "COMMANDE EN COURS" },
    { libelle: p.t('etat.prestation.COMMANDE REJETEE'), value: "COMMANDE REJETEE" },
    { libelle: p.t('etat.prestation.RESILIEE'), value: "RESILIEE" },
  ]

  const [tableDatas, setTableDatas] = useState<any[]>([]);
  const [filterEtatPresta, setFilterEtatPresta] = useState<string | string[]>(["EN SERVICE", "INITIEE", "COMMANDE EN COURS"]);

  useEffect(() => {
    let datas = data ? data.prestationVccs : [];
    if (filterEtatPresta) {
      datas = datas.filter((d) => filterEtatPresta.includes(d.etat))
    }

    setTableDatas(datas)
  }, [data, filterEtatPresta]);

  return (
    <>
      <Table
        idTable='table-vcc'
        loading={loading}
        datas={
          tableDatas.map((d) => ({
            ...d,
            etat: {
              code: d.etat,
              libelle: p.t(`etat.prestation.${d.etat}`),
              colorClasses: `bg-${d.etatColor}-200 text-${d.etatColor}-800`
            }
          }))
        }
        columns={columns}
        customToolbarActions={
          <>
            <div className=''>
              <ButtonsGroup
                onSelectedButton={(value) => setFilterEtatPresta(value)}
                buttons={filterButtons}
                selectedButtons={filterEtatPresta}
                multi={true}
              />
            </div>
          </>
        }
        onClickRow={(row) => onOpenModaleDetailPrestation(row)}
        options={{
          search: {
            placeholder: p.t('prestations.searchPlaceholder'),
          },
        }}
      />
      <PrestationDetailModaleVCC
        prestation={modalePrestation}
        isOpen={openModaleDetailPrestation}
        onClose={onCloseModaleDetailPrestation}
        onSave={(onSaveVcc)}
      />
    </>)
}