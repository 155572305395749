import clsx from 'clsx';
import React from 'react';
import { useP } from '../../services/i18n';
import { getStatutData } from '../utils';

function SupervisionTabs(props: { services: Array<any> }) {

    const p = useP();

    return (
        <div className="border border-gray-300 rounded-md divide-y shadow-sm">

            {
                props.services?.map((item, index) => (
                    <div key={index} className="flex flex-row items-center px-4 py-3 text-left">
                        <div className='flex-1 font-semibold'>{item.libelle}</div>
                        <div className={clsx(
                            'text-sm font-medium',
                            `text-${getStatutData(p, item.statut).color}-600`
                        )}>
                            {getStatutData(p, item.statut).label}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SupervisionTabs