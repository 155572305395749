import React from "react";

import Operators from "./Operators/Operators";
import clsx from "clsx";
import { Tab } from "@headlessui/react";
import { filterTypes } from "./Types/filterTypes";

type TabFiltersType = {
  filters: Array<filterTypes>,
  addFilters: (filters: Array<filterTypes>) => void,
  closePopover: () => void
}

function TabFilters({ filters, addFilters, closePopover }: TabFiltersType) {
  return (
    <Tab.Group vertical>
      <Tab.List
        className="mt-2 p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-gray-500"
        style={{ minWidth: "200px" }}
      >
        {filters.map((filter, idx) => (
          <Tab className="block w-full text-left my-2" key={idx}>
            {({ selected }) => (
              <div
                className={clsx(
                  selected ? "bg-primary-50  border-primary-200" : "bg-white",
                  `p-2 rounded-md border border-transparent text-sm hover:bg-gray-100 `
                )}
              >
                {filter.label}
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {filters.map((filter, idx) => (
          <Tab.Panel
            key={idx}
            style={{ minWidth: "300px" }}
            className="divide-y-2 divide-gray-100  mt-2 md:ml-2 p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <Operators
              filter={filter}
              addFilters={addFilters}
              closePopover={closePopover}
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default TabFilters;
