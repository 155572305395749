import { createContext } from 'react';

type Args = { [key: string]: string };
type ContextProps = {
  locale: string,
  setLocale: (value: string) => void,
  p: { t: (value: string, args?: Args) => string, has: (key: string) => boolean },
}

const defaultProps = {
  locale: 'fr',
  setLocale: () => null,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const I18nContext = createContext<ContextProps>(defaultProps);

export default I18nContext;