import React from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useP } from '../../services/i18n';

type SlideOverSearchType = {
  value: string,
  onChange: (value: string) => void,
  placeholder?: string
}

export default function SlideOverSearch({ value, onChange, placeholder = '' }: SlideOverSearchType) {
  const p = useP();
  return (
    <div className="border-b border-gray-200">
      <div className="px-4 py-4">
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="search"
            name="slideoverfilter"
            id="slideoverfilter"
            className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            // slideOver
            // search
            // placeholderSites
            // placeholderContrats
            placeholder={placeholder}
            autoComplete='off'
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}