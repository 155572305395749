import React from 'react'
import Logo from '../../Components/Logo'

function LoginBanner() {

  return (
    <div className="flex-1 hidden lg:flex justify-center items-center bg-radient-ellipse-c from-primary-700 from-0% to-primary-800 to-70%">
      <Logo type="gold" className="w-52 drop-shadow-2xl hover:animate-pulse" />      
    </div>
  )

}

export default LoginBanner