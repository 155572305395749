import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import SequentialInput from '../../../../Components/SequentialInput';
import { useP } from '../../../../services/i18n';
import { SEND_OTP } from '../../queries';
import OtpModeType from '../OtpModeType';
import toast from 'react-hot-toast';


type OtpModeConfirmType = {
  otpMode: OtpModeType,
  setCanContinue: (value: boolean) => void,
  confirmationCode: string
  setConfirmationCode: (value: string) => void
}

const verificationCodeSize = 6;

function OtpModeConfirm({ otpMode, setCanContinue, confirmationCode, setConfirmationCode }: OtpModeConfirmType) {

  const p = useP();

  const [sendOtp] = useMutation(SEND_OTP);

  const onClickReset = () => {
    setConfirmationCode('');

    if (otpMode.canResend) {

      toast.promise(
        sendOtp({
          variables: {
            otpMode: otpMode.code
          }
        }),
        {
          loading: p.t('profil.tabs.securite.otpSelection.toasts.envoiOtpEnCours'),
          success: p.t('profil.tabs.securite.otpSelection.toasts.envoiOtpSucces'),
          error: p.t('profil.tabs.securite.otpSelection.toasts.envoiOtpErreur'),
        }
      );

    }
  }


  useEffect(() => {
    setCanContinue(confirmationCode.length === verificationCodeSize)
  }, [confirmationCode])

  useEffect(() => {
    onClickReset()
  }, [])

  return (
    <div>
      <div className='text-gray-800 font-medium'>{p.t('profil.tabs.securite.otpSelection.codeVerification')}</div>

      <SequentialInput valueLength={verificationCodeSize} value={confirmationCode} onChange={setConfirmationCode}></SequentialInput>

      {
        otpMode.canResend && (
          <div className="w-fit flex flex-row items-center space-x-1 cursor-pointer text-gray-500 hover:text-gray-700 font-medium" onClick={onClickReset}>
            <p className="text-sm  underline">
              {p.t('login.mfaModale.resend')}
            </p>
            <ArrowPathIcon className="w-4 h-4" />
          </div>
        )
      }
    </div>
  )

}

export default OtpModeConfirm;