import React from 'react';
import ButtonsGroup from '../../ButtonsGroup/ButtonsGroup';
import { ButtonsGroupItemButtonType } from '../../ButtonsGroup/ButtonsGroupItem';

type ButtonsGroupType = {
  buttons: Array<ButtonsGroupItemButtonType>,
  onSelectedButton: (value: any) => void,
  multi?: boolean,
  errors?: object,
  name: string,
  selectedButtons?: Array<any>
  forceHorizontalOnMobile?: boolean
}

export default function InputButtonsGroup({ buttons, onSelectedButton, multi, errors, name, selectedButtons, forceHorizontalOnMobile = false }: ButtonsGroupType) {
  return <>
    <ButtonsGroup
      onSelectedButton={onSelectedButton}
      buttons={buttons}
      multi={multi}
      selectedButtons={selectedButtons}
      vertical={false}
      forceHorizontalOnMobile={forceHorizontalOnMobile}
    />
    {
      (errors && errors[name]) && <div className='text-red-600 text-xs font-medium mt-0.5'>{errors[name].message}</div>
    }
  </>
}
