import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { TableDetailRenderType } from './Types/TableDetailRenderType'
import { ColumnType } from './Types/ColumnType'
import TableContainer, { colWidthResizeType } from './TableContainer'
import { TableRuptureType } from './Types/TableRuptureType'
import { TableOptionsType } from './Types/TableOptionsType'
import { getLocalStorage, setLocalStorage } from '../Utils/Utils'
import { useP } from '../../services/i18n'
import { SortingType } from './Thead'
import dayjs from 'dayjs'
import { useTable } from './UseTableContext'
import { tableContext } from './TableContext'

type TableType = {
  idTable: string
  datas: Array<object>,
  columns: Array<ColumnType>,
  options?: TableOptionsType,
  loading: boolean,
  error?: boolean,
  detailComponentRender?(renderProps: TableDetailRenderType): ReactNode,
  onSelectRow?: (row: any, checked: boolean) => void | undefined,
  getSelectedRows?: (selectedRows: Array<any>) => void,
  customToolbarActions?: ReactNode,
  onDoubleClickRow?: (row: any) => void,
  onClickRow?: (row: any) => void,
  onSearch?: (val: string) => void,
  onSort?: (column: ColumnType, direction: SortingType) => void,
  rupture?: TableRuptureType,
  className?: string
}

function Table({
  idTable,
  datas,
  columns,
  rupture = {
    isVisible: () => false,
    value: <></>
  },
  options,
  detailComponentRender,
  loading,
  error = false,
  onSelectRow,
  customToolbarActions,
  onDoubleClickRow,
  onClickRow,
  onSort,
  onSearch,
  className,
  getSelectedRows
}: TableType) {

  const tableCtx = useTable();
  const p = useP();

  // Gestion des options 
  const defaultOptions = {
    colSelect: false,
    pagination: {
      optionsPerPage: [25, 50, 100],
      defaultPerPage: 100
    },
    toolbar: true,
    search: {
      placeholder: 'Rechercher',
      attributs: undefined,
    },
    fullBordered: false,
    emptyMessage: p.t("components.table.noresults"),
  }
  const tableOptions = { ...defaultOptions, ...options }

  // Pagination
  const perPage = getLocalStorage(idTable) ? getLocalStorage(idTable).perPage : null
  const [pagination, setPagination] = useState({ currentPage: 1, pageSize: tableOptions.pagination ? perPage ? perPage : tableOptions.pagination.defaultPerPage : 0 });

  // Recherche
  const [search, setSearch] = useState('');

  // Refresh des data en fonction de la recherche et de la pagination
  const filterTableDatas = (datas: object[]) => {

    // On filtre les données par rapport à la recherche
    let tableDatas = datas;
    if (tableOptions?.search && search !== '') {

      const check = (item, search) => {

        if (typeof item === 'object') {

          // On vérifie que l'objet n'est pas un element react.
          if (!item || "$$typeof" in item)
            return false;

          for (const attr in item) {
            if (check(item[attr], search)) {
              return true;
            }
          }
        } else {
          return item.toString().toLowerCase().includes(search.toLowerCase().trim().replace(/[\s]{2,}/g, " "))
        }
      }

      tableDatas = datas.filter((d) => {
        for (const attr in d) {
          if (tableOptions?.search &&
            (tableOptions?.search?.attributs === undefined || (tableOptions?.search.attributs && tableOptions?.search.attributs.includes(attr)))
            && d[attr] && check(d[attr], search)) { //) {
            return true;
          }
        }
        return false
      });
    }
    // Pour finir on applique la pagination
    return tableDatas;
  }

  const datasLignes = datas.map((row, index) => { return { ...row, idLigne: index } })
  const [tableDatas, setTableDatas] = useState<any>(datasLignes)

  // Sorting datas
  const [sortedDatas, setSortedDatas] = useState<any>(getLocalStorage(`${idTable}-sortedColumn`) || null)
  const sortDatas = (datas, column, direction) => {

    const getCellValue = typeof column.sortable === "function" ? column.sortable : (columnCode, value) => {
      let cellValue = "";
      columnCode.split(".").forEach((key) => {
        cellValue = (cellValue == "" ? value[key] : cellValue[key])
      })      
      return cellValue
    }

    let sortedDatas: any[];

    direction === "ASC"
      ? sortedDatas = [...datas].sort((a, b) => {        
        const aValue = getCellValue(column.code, a);
        const bValue = getCellValue(column.code, b);
        
        if (column.colType === 'string' || column.colType === 'adresse' || column.colType === 'detail_row') {
          return (aValue?.toLowerCase() || '') > (bValue?.toLowerCase() || '') ? 1 : -1
        }
        else if (column.colType === 'date' || column.colType === 'dateTime') {
          return (aValue ? dayjs(aValue) : "") > (bValue ? dayjs(bValue) : "") ? 1 : -1
        }
        else if (column.colType === 'boolean') {
          return aValue == bValue ? 0 : aValue ? 1 : -1;
        }
        else {
          return aValue > bValue ? 1 : -1
        }
      })
      : sortedDatas = [...datas].sort((a, b) => {
        const aValue = getCellValue(column.code, a);
        const bValue = getCellValue(column.code, b);
        if (column.colType === 'string' || column.colType === 'adresse' || column.colType === 'detail_row') {
          return (aValue?.toLowerCase() || '') < (bValue?.toLowerCase() || '') ? 1 : -1
        }
        else if (column.colType === 'date' || column.colType === 'dateTime') {
          return (aValue ? dayjs(aValue) : "") < (bValue ? dayjs(bValue) : "") ? 1 : -1
        }
        else if (column.colType === 'boolean') {
          return aValue == bValue ? 0 : aValue ? -1 : 1;
        }
        else {
          return aValue < bValue ? 1 : -1
        }
      })
    return sortedDatas
  }
  const sortTable = (column, direction) => {
    setPagination({ ...pagination, currentPage: 1 })
    const sortedElements = {
      "column": column,
      "direction": direction
    }
    setLocalStorage(`${idTable}-sortedColumn`, sortedElements);
    setSortedDatas(sortedElements)
  }

  useEffect(() => {   
    if (sortedDatas) {
      const columnSort = columns.find(c => c.code === sortedDatas?.column.code)
      setTableDatas(sortDatas(filterTableDatas(datasLignes), columnSort, sortedDatas?.direction))
    } else {
      setTableDatas(filterTableDatas(datasLignes))
    }
  }, [datas, search, sortedDatas])

  // resize  
  const tableElement = useRef<any | null>(null);
  const [tableHeight, setTableHeight] = useState(0);
  useEffect(() => {
    if (tableElement.current) {
      setTableHeight(tableElement.current.offsetHeight);
    }
  }, []);

  const [colWidthResize, setColWidthResize] = useState<colWidthResizeType>()


  // Sélection
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  const onSelectTableRow = (row: any, checked: boolean) => {
    onSelectRow?.(row, checked)
    if (isShiftPressed && selectedRows.length > 0) {
      const startIdx = selectedRows[selectedRows.length - 1].idLigne;
      const endIdx = row.idLigne;
      const newSelectedRows = [...selectedRows];

      for (let i = Math.min(startIdx, endIdx); i <= Math.max(startIdx, endIdx); i++) {
        if (!newSelectedRows.find((r) => r.idLigne === i)) {
          newSelectedRows.push(datasLignes.find((r) => r.idLigne === i));
        }
      }
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows((prevSelectedRows) => {
        const existingRow = prevSelectedRows.find((r) => r.idLigne === row.idLigne);
        if (existingRow) {
          return prevSelectedRows.filter((r) => r.idLigne !== row.idLigne);
        } else {
          return [...prevSelectedRows, row];
        }
      });
    }
  }

  const selectAll = () => {
    if (selectedRows.length === tableDatas.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableDatas.map((row) => row));
    }
  }

  useEffect(() => {
    if (selectedRows) {
      getSelectedRows?.(selectedRows)
    }
  }, [selectedRows])


  return (
    <>
      <tableContext.Provider value={tableCtx}>
        <TableContainer
          idTable={idTable}
          loading={loading}
          error={error}
          columns={columns.filter(c => c.visible != false)}
          rupture={rupture}
          datas={tableOptions.pagination ? tableDatas.slice((pagination.currentPage - 1) * pagination.pageSize, pagination.pageSize * pagination.currentPage) : tableDatas}
          options={tableOptions}
          currentPage={pagination.currentPage}
          pageSize={pagination.pageSize}
          totalCount={tableDatas.length}
          colWidthResize={colWidthResize}
          customToolbarActions={customToolbarActions}
          selectedRows={selectedRows}
          detailComponentRender={detailComponentRender}
          tableHeight={tableHeight}
          refTable={tableElement}
          defaultSortColumn={sortedDatas}
          className={className}
          selectAll={selectAll}
          onPaginate={(page, size) => {
            setPagination({ currentPage: size, pageSize: page })
          }}
          onSearch={(search) => {
            onSearch ? onSearch(search) : setSearch(search)
          }}
          onResize={(newWidth, colIndex) => setColWidthResize({ width: newWidth, index: colIndex })}
          onSelectRow={onSelectTableRow}
          onDoubleClickRow={onDoubleClickRow}
          onClickRow={(row, event) => {
            if (tableOptions.colSelect) {
              const TargetElement = event.target as Element;
              if (TargetElement.tagName !== "INPUT") {
                onSelectTableRow(row, selectedRows.includes(row))
              }
            }
            else {
              onClickRow?.(row)
            }
          }
          }
          onSort={(column, direction) => {
            sortTable(column, direction)
            onSort?.(column, direction)
          }}

        />
      </tableContext.Provider>
    </>
  )
}

export default Table