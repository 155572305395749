import React, { useState } from "react"
import Card from "../../../Components/Card"
import DateRangePicker from "../../../Components/Datepicker/DateRangePicker"
import CyberChart from "../CyberChart/CyberChart"
import CyberChartHistorique from "../CyberChart/CyberChartHistorique"

type CardChartType = {
  chartType: string,
  chartCode: string,
  compteId: string,
  libelle: string,
  minHeight?: number,
  historique?: boolean
}

export default function CardChart({ chartType, chartCode, compteId, libelle, minHeight, historique = false }: CardChartType) {

  const dateDebutDefault = new Date();
  dateDebutDefault.setHours(0, 0, 0, 0);

  const dateFinDefault = new Date();
  dateFinDefault.setHours(23, 59, 59, 0);

  const [dateDebut, setDateDebut] = useState<Date | null>(dateDebutDefault)
  const [dateFin, setDateFin] = useState<Date | null>(dateFinDefault)

  return chartType ? <>
    <Card className={{ root: "flex-1 flex flex-col", container: "flex-1" }} >
      <Card.Header fullscreen>{`${libelle}`}</Card.Header>
      <div className="flex flex-col flex-1 h-full mt-2" style={{ minHeight: minHeight ? `${minHeight}px` : `auto` }}>
        <CyberChart chartType={chartType} chartCode={chartCode} chartLabel={libelle} compteId={compteId} />
      </div>
    </Card>

    {historique &&
      <div className="flex-1 flex flex-col space-y-4 mt-4">
        <Card className={{ root: "flex flex-col h-full" }} overflow={false} >
          <Card.Header fullscreen>Historique des données</Card.Header>
          <div className="lg:flex lg:items-center lg:justify-between mb-2">
            <div className="flex-1 flex items-center space-x-2">
              <div className="text-primary-700 font-semibold text-lg"></div>
            </div>
            <div className="mt-2">
              <DateRangePicker
                defaultStartDate={dateDebut}
                defaultEndDate={dateFin}
                className="px-0 py-1"
                onChange={(startDate, endDate) => {
                  console.log(startDate, endDate)
                  setDateDebut(startDate)
                  setDateFin(endDate)
                }}
              />
            </div>
          </div>
          <div className="flex-1 h-full">
            <CyberChartHistorique chartCode={chartCode} compteId={compteId} dateDebut={dateDebut} dateFin={dateFin} />
          </div>
        </Card>
      </div>
    }
  </> : <></>
}