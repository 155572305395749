import React, { ReactNode, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useP } from '../../../services/i18n'

import Modal from '../../../Components/Modal/Modal'
import ModalFooterAction from '../../../Components/Modal/ModalFooterAction'
import { ModalConfirmation } from '../../../Components/ModalDialog/ModalConfirmation'
import ModalContactsBody from './ModalContactsBody'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CHECK_EMAIL, SAVE_PERSONNE } from '../../Personnes/queries'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_DONNEES_REFERENCE_MODALE, SAVE_CONTACT } from '../queries'
import { toastSuccess } from '../../../Components/Utils/Utils'
import Constants from '../../../commons/Constants';

const {
  TYPE_CONTACT,
} = Constants;


type FormContactType = {
  id: number,
  civilite: string,
  prenom: string,
  nom: string,
  poste: string,
  telephoneFixe: string,
  telephoneMobile: string,
  mail: string,
  isActif: boolean,
  isMailMaintenance: boolean,
  isMultiCompte: boolean,
  isAccesEspaceClient: boolean,
  isAvisIncident: boolean,
  isNewsCommerciale: boolean,
  isNewsTechnique: boolean,
  isNewsletterMensuelle: boolean,
  isRegroupementFactureMail: boolean,
  compte: number,
  isContactProjets: boolean,
  isContactSupervision: boolean,
  isContactSupervisionHierarchie:boolean
  isFacture: boolean,
  isFactureHierarchie:boolean,
  isMailingActif: boolean
}

type ModalContactsType = {
  isOpen: boolean,
  onClose: () => void,
  onSaveContact: (object) => void,
  idContact?: number | null,
  confirmOpen: boolean,
  confirmMessage: string | ReactNode,
  confirmAction: (confirm: boolean) => void,
  typeContact: string
}

export default function ModalContacts({ isOpen = false, onClose, onSaveContact, idContact, confirmOpen, confirmMessage, confirmAction, typeContact }: ModalContactsType) {
  const p = useP();

  const [checkEmail] = useLazyQuery(CHECK_EMAIL);

  const { data: dataDonneesReference } = useQuery(GET_DONNEES_REFERENCE_MODALE);

  const validationSchemaContact = yup.object({
    compte: yup.object().required(p.t('form.required')).typeError(p.t('form.required')),
    civilite: yup.object().required(p.t('form.required')).typeError(p.t('form.required')),
    prenom: yup.string().trim().required(p.t('form.required')),
    nom: yup.string().trim().required(p.t('form.required')),
    poste: yup.object().required(p.t('form.required')).typeError(p.t('form.required')),
    telephoneFixe: yup.string().nullable().when('telephoneMobile', (telephoneMobile, schema) => {
      return (telephoneMobile) ? schema : schema.required(p.t('contacts.contact.telephoneRules'))
    }),
    telephoneMobile: yup.string().nullable(),
    mail: yup.string()
      .email()
      .required(p.t('form.required'))
      .test('mailServerValidation', p.t('contacts.contact.mailServerValidation'), async (mail, params) => {
        const { parent } = params;

        const result = await checkEmail({
          variables: {
            mail,
            contactId: parent.id
          }
        })
        return !result.data.checkEmailContactExiste;
      }),

    isActif: yup.boolean(),
    isMultiCompte: yup.boolean(),
    isAccesEspaceClient: yup.boolean(),
    isMailMaintenance: yup.boolean(),
    isAvisIncident: yup.boolean(),
    isNewsCommerciale: yup.boolean(),
    isNewsTechnique: yup.boolean(),
    isNewsletterMensuelle: yup.boolean(),
    isContactProjets: yup.boolean(),
  }).required();

  const validationSchemaPersonne = yup.object({
    compte: yup.object().required(p.t('form.required')).typeError(p.t('form.required')),
    prenom: yup.string().trim().required(p.t('form.required')),
    nom: yup.string().trim().required(p.t('form.required')),
    poste: yup.object().nullable(),
    telephoneFixe: yup.string().nullable(),
    telephoneMobile: yup.string().nullable(),
    mail: yup.string().nullable().email(),
  }).required();

  const { register, control, handleSubmit, formState: { errors }, reset } = useForm<FormContactType>({
    resolver: yupResolver(typeContact === TYPE_CONTACT ? validationSchemaContact : validationSchemaPersonne)
  });

  // RAZ du formulaire
  useEffect(() => {
    if (isOpen && !idContact) {
      reset({})
    }
  }, [isOpen])

  // Save CONTACT
  const [saveContact] = useMutation(
    SAVE_CONTACT,
    {
      refetchQueries: [
        'GetContacts'
      ],
    }
  );

  // Save PERSONNE
  const [savePersonne] = useMutation(
    SAVE_PERSONNE,
    {
      refetchQueries: [
        'GetContactsEtPersonnes'
      ],
    }
  );

  const onSubmit: SubmitHandler<FormContactType> = async data => {

    if (typeContact === TYPE_CONTACT) {
      const dataSave = {
        "id": data.id,
        "nom": data.nom,
        "prenom": data.prenom,
        "telephoneFixe": data.telephoneFixe,
        "telephoneMobile": data.telephoneMobile,
        "mail": data.mail,
        "isActif": data.isActif,
        "isMultiCompte": data.isMultiCompte,
        "isMailMaintenance": data.isMailMaintenance,
        "isAvisIncident": data.isAvisIncident,
        "isNewsCommerciale": data.isNewsCommerciale,
        "isNewsTechnique": data.isNewsTechnique,
        "isNewsletterMensuelle": data.isNewsletterMensuelle,
        "isAccesEspaceClient": data.isAccesEspaceClient,
        "salutation": data.civilite['code'],
        "fonction": data.poste['code'],
        "id_site": 0,
        "isRegroupementFactureMail": data.isRegroupementFactureMail,
        isContactProjets: data.isContactProjets,
        isContactSupervision : data.isContactSupervision,
        isContactSupervisionHierarchie : data.isContactSupervisionHierarchie,
        isFacture : data.isFacture,
        isFactureHierarchie : data.isFactureHierarchie,
        isMailingActif: data.isMailingActif
      };
      
      await saveContact({
        variables: {
          contact: {
            ...(!data.id && { id_compte: parseInt(data.compte['code']) }),
            ...dataSave
          }
        },
        onCompleted: (data) => {
          toastSuccess(p.t('actions.sauvegardeOK'));
          onSaveContact(data.saveContact);
        },
        onError: (err) => {
          throw err;
        },
      }).catch((err) => {
        throw err;
      });

    } else {
      const dataSave = {
        "id": data.id,
        "nom": data.nom,
        "prenom": data.prenom,
        "telephoneFixe": data.telephoneFixe,
        "telephoneMobile": data.telephoneMobile,
        "mail": data.mail,
        "isActif": data.isActif,
        "salutation": data.civilite ? data.civilite['code'] : null,
        "fonction": data.poste ? data.poste['code'] : null,
      };

      await savePersonne({
        variables: {
          personne: {
            ...(!data.id && { compteId: parseInt(data.compte['code']) }),
            ...dataSave
          }
        },
        onCompleted: (data) => {
          toastSuccess(p.t('actions.sauvegardeOK'));
          onSaveContact(data.savePersonne.personne);
        },
        onError: (err) => {
          throw err;
        },
      }).catch((err) => {
        throw err;
      });
    }
  }


  return (<>
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      content={{
        title: idContact ? `Edition du contact` : "Ajout d'un contact",
        body:
          <>
            <ModalContactsBody
              reset={reset}
              register={register}
              control={control}
              errors={errors}
              idContact={idContact}
              donneesReference={dataDonneesReference}
              typeContact={typeContact} />
            <ModalConfirmation
              isOpen={confirmOpen}
              message={confirmMessage}
              onClose={(confirm) => confirmAction(confirm)} />
          </>,
        footer: <ModalFooterAction onClickAction={handleSubmit(onSubmit)} label={p.t('actions.enregistrer')} />,
      }}
      className="w-full lg:w-6/12"
    />
  </>)
}