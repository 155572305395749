import clsx from 'clsx'
import React from 'react'
import InputSwitch from '../Form/InputSwitch'
import { MultiSelectOptionType } from './Types/MultiSelectOptionType'

type MultiSelectOptionCompType = {
  option: MultiSelectOptionType,
  toggleOption: (option: MultiSelectOptionType) => void,
  selected: Array<MultiSelectOptionType>,
  id: number | string
}

export default function MultiSelectOption({ option, toggleOption, selected, id }: MultiSelectOptionCompType) {
  const isSelected = selected.includes(option);
  return <>
    <div className="relative flex items-start mb-1" >
      <label
        htmlFor={`item-${id}`}
        className={clsx(`font-medium select-none flex py-2 px-2 w-full h-full   text-primary-700 rounded-md`,
          isSelected
            ? `bg-primary-100 hover:bg-primary-100`
            : `bg-white hover:bg-gray-100`
        )}
      >
        <div className="mr-3 flex items-center h-5" >
          <input
            onChange={() => toggleOption(option)}
            checked={isSelected}
            id={`item-${id}`}
            type="checkbox"
            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded" />
        </div>
        <div className="min-w-0 text-sm">
          {option.libelle}
        </div>
      </label>
    </div>
  </>
}