import { gql } from "@apollo/client";

const GET_CONTRATS = gql`
  query Clients($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput, $includes: [IncludeInput], $attributes: [String]) {
    clients(filters: $filters, sorters: $sorters, pagination: $pagination, includes: $includes, attributes: $attributes) {          
      items {
        numcli
        nom
        dateDebut
        dateFin
        contratEnProduction
        statutContrat{
          code
          libelle                    
        }
        siteFacturation {
          libelleVoie
          numeroVoie
          codePostal
          commune          
        } 
        prestations {
          type
          etat
          dateResiliation
          site {
            id
          }          
        }
      }
    }
    statutsContrat {
      code
      libelle
      statut {
        code
        libelle
      }
    }
  }`

const GET_CONTRAT = gql`
  query Client($id: ID!) {
    client(id: $id) {                
      numcli
      nom
      nomContrat
      siteFacturation {
        nom
        libelleVoie
        numeroVoie
        codePostal
        commune          
      } 
    }
  }`

const GET_ABONNEMENTS = gql`
  query abonnementsActifs($numContrat: String, $withOutVente: Boolean, $idAbonnement: ID) {
    abonnementsActifs(numContrat: $numContrat, withOutVente: $withOutVente, idAbonnement: $idAbonnement) {
      idAbo
      groupe
      complementGroupe
      productCode
      nomProduit
      quantite
      quantiteLivree
      quantiteALivrer
      prixUnitaire
      prixUnitaireFas
      reference
      codeAnalytique
      nature
      dateFin
      description
      numSection
      idCompte
      mode
      periodicite
      bonDeCommandeModele {
        espaceClient
        fournisseur
        modele
        nom
      }      
      statutContrat    
      isActif  
      lignes {
        idAbo
        groupe
        productCode
        nomProduit
        quantite
        quantiteLivree
        quantiteALivrer
        prixUnitaire
        prixUnitaireFas
        reference
        codeAnalytique
        nature
        dateFin      
        description
        numSection
        mode
        periodicite
        bonDeCommandeModele {
          espaceClient
          fournisseur
          modele
          nom
        }     
        statutContrat
        isActif
        lignes {
          idAbo
          groupe
          productCode
          nomProduit
          quantite
          quantiteLivree
          quantiteALivrer
          prixUnitaire
          prixUnitaireFas
          reference
          codeAnalytique
          nature
          dateFin  
          description
          numSection
          mode
          periodicite
          bonDeCommandeModele {
            espaceClient
            fournisseur
            modele
            nom
          }   
          statutContrat  
          isActif
          lignes {
            idAbo
            groupe
            productCode
            nomProduit
            quantite
            quantiteLivree
            quantiteALivrer
            prixUnitaire
            prixUnitaireFas
            reference
            codeAnalytique
            nature
            dateFin     
            description
            numSection
            mode
            periodicite
            bonDeCommandeModele {
              espaceClient
              fournisseur
              modele
              nom
            }     
            statutContrat
            isActif
            lignes {
              idAbo
              groupe
              productCode
              nomProduit
              quantite
              quantiteLivree
              quantiteALivrer
              prixUnitaire
              prixUnitaireFas
              reference
              codeAnalytique
              nature
              dateFin      
              description
              numSection
              mode
              periodicite
              bonDeCommandeModele {
                espaceClient
                fournisseur
                modele
                nom
              }     
              statutContrat
              isActif
              lignes {
                idAbo
                groupe
                productCode
                nomProduit
                quantite
                quantiteLivree
                quantiteALivrer
                prixUnitaire
                prixUnitaireFas
                reference
                codeAnalytique
                nature
                dateFin   
                description
                numSection
                mode
                periodicite
                bonDeCommandeModele {
                  espaceClient
                  fournisseur
                  modele
                  nom
                }  
                statutContrat   
                isActif
                lignes {
                  idAbo
                  groupe
                  productCode
                  nomProduit
                  quantite
                  quantiteLivree
                  quantiteALivrer
                  prixUnitaire
                  prixUnitaireFas
                  reference
                  codeAnalytique
                  nature
                  dateFin   
                  description
                  numSection
                  mode
                  periodicite
                  bonDeCommandeModele {
                    espaceClient
                    fournisseur
                    modele
                    nom
                  } 
                  statutContrat    
                  isActif
                  lignes {
                    idAbo
                    groupe
                    productCode
                    nomProduit
                    quantite
                    quantiteLivree
                    quantiteALivrer
                    prixUnitaire
                    prixUnitaireFas
                    reference
                    codeAnalytique
                    nature
                    dateFin  
                    description  
                    numSection                      
                    mode
                    periodicite                                
                    bonDeCommandeModele {
                      espaceClient
                      fournisseur
                      modele
                      nom
                    }                                       
                  }                                                            
                }             
              }
            }
          }
        }
      }
    }
  }`

const UPDATE_ABONNEMENTS = gql`
  mutation UpdateAbonnements($abonnement: AbonnementInput, $id: ID) {
    updateAbonnements(abonnement: $abonnement, id : $id)
  }
`

const GET_DSLS_ACTIFS_BLUE = gql`
  query DslsActifsBlue($numcli: ID!) {
    dslsActifsBlue(numcli: $numcli) {
      nbDeMois
      prix
      typeOffre
      ndi
      periodicite
      regroupement
      commentaire
      client {
        compte {
          id
        }
      }
    }
  }
`

const GET_VGAS_ACTIFS_BLUE = gql`
  query VgasActifsBlue($numcli: ID!) {
    vgasActifsBlue(numcli: $numcli) {
      prix
      quantite
      commentaire
      ndi {
        ndi
      }
      catalogueVga {
        libelle
      }
      client {
        compte {
          id
        }
      }
    }
  }
`

const GET_DEVIS_LISTE = gql`
  query DevisListe($filters: [FilterInput], $sorters: [SorterInput]) {
    devisListe(filters: $filters, sorters: $sorters) {
      reference
      projetNom
      opportunite {
        nom
        type
        dateSignature
      }
      dateCreation
    }
  }
`

const GET_DEVIS_ACTIFS = gql`
  query DevisActifs($numContrat: String) {
    devisActifs(numContrat: $numContrat) {
      reference
      projetNom
      opportunite {
        nom
        type
        dateSignature
      }
      dateCreation
      client {
        compte {
          id
        }
      }
    }
  }
`

const GET_ABONNEMENTS_GROUPES = gql`
query abonnementsActifsGroupes($numContrat: String, $withOutVente : Boolean) {
  abonnementsActifsGroupes(numContrat: $numContrat, withOutVente: $withOutVente) {
    idAbo
    idCompte
    groupe
    site
  }
}`

const SAVE_CONTRAT = gql`
  mutation SaveClient($client: ClientInput!) {
    saveClient(client: $client) {
      numcli
      nom
    }
  }`;

export {
  GET_CONTRATS,
  GET_CONTRAT,
  GET_ABONNEMENTS,
  UPDATE_ABONNEMENTS,
  GET_DSLS_ACTIFS_BLUE,
  GET_VGAS_ACTIFS_BLUE,
  GET_DEVIS_LISTE,
  GET_ABONNEMENTS_GROUPES,
  GET_DEVIS_ACTIFS,
  SAVE_CONTRAT
}