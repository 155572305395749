import { Popover } from '@headlessui/react'
import { VirtualElement } from '@popperjs/core';
import clsx from 'clsx';
import React, { useState } from 'react'
import { usePopper } from 'react-popper';
import { useP } from '../services/i18n';

type PopOverType = {
  ButtonElement: JSX.Element,
  ButtonClassName?: string,
  PanelElement: JSX.Element,
  PanelClassName?: string,
  options?: object
}

const defaultOptions = {
  placement: "bottom-end",
  modifiers: [
    {
      name: "flip",
      options: {
        fallbackPlacements: ["top", "right", "bottom", "left"],
      },
    },
    {
      name: "offset",
      options: {
        offset: [10, 10],
      },
    },
  ],
}

export default function PopOver({
  ButtonElement,
  ButtonClassName = "py-1 px-2 rounded-md bg-white hover:bg-gray-100 border border-gray-200",
  PanelElement,
  PanelClassName = "z-50 bg-white rounded-md shadow-xl p-4 border border-gray-200",
  options = defaultOptions,
}: PopOverType) {

  const [referenceElement, setReferenceElement] = useState<Element | VirtualElement | null | undefined>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);



  const { styles, attributes } = usePopper(referenceElement, popperElement, options);
  return <>
    <Popover className="w-full">
      {({ open }) => (
        <div className='w-full'>
          <Popover.Button
            ref={setReferenceElement}
            className={ButtonClassName}
          >
            {ButtonElement}
          </Popover.Button>
          {open && (
            <Popover.Panel
              className={PanelClassName}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {PanelElement}
            </Popover.Panel>
          )}
        </div>
      )}
    </Popover>
  </>
}