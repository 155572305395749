import React from 'react';
import Badge from '../../../../Components/Badge';
import Button from '../../../../Components/Button';
import DescriptionList, { DescriptionListItemAlignType } from '../../../../Components/DescriptionList/DescriptionList';
import Modal from '../../../../Components/Modal/Modal';
import Table from '../../../../Components/Table/Table';
import { TableOptionsType } from '../../../../Components/Table/Types/TableOptionsType';
import { useP } from '../../../../services/i18n';
import PrestationDetailModaleBody from '../../PrestationDetail/PrestationDetailModaleBody';
import PrestationHistoriqueModaleBody from '../../PrestationDetail/PrestationHistoriqueModaleBody';

type PrestationDetailModaleCENTILEType = {
  isOpen: boolean,
  prestation: any,
  onClose: () => void
}

function PrestationDetailModaleCENTILE({ prestation, isOpen = false, onClose }: PrestationDetailModaleCENTILEType) {
  const p = useP();
  const alignTop: DescriptionListItemAlignType = "top"

  const devicesTableOptions: TableOptionsType = {
    colSelect: false,
    pagination: false,
    search: false,
    fullBordered: false,
    toolbar: false
  }

  const devicesColumns = [
    {
      libelle: p.t('prestations.centile.devices.columns.model'),
      code: 'model',
      colType: 'string',
    },
    {
      libelle: p.t('prestations.centile.devices.columns.mac'),
      code: 'devicePhysicalId',
      colType: 'string',
    },
    {
      libelle: p.t('prestations.centile.devices.columns.port'),
      code: 'port',
      colType: 'string',
    }
  ]

  const infos =
    [
      {
        label: p.t('prestations.centile.columns.extension'),
        value: prestation?.extension,
      },
      {
        label: p.t('prestations.centile.columns.identifiant'),
        value: prestation?.loginUser,
      },
      {
        label: p.t('prestations.centile.columns.prenomNom'),
        value: prestation?.denomination,
      },
      {
        label: p.t('prestations.centile.columns.email'),
        value: prestation?.email,
      },
      {
        label: p.t('prestations.centile.columns.sda'),
        value: prestation?.sda,
      },
      {
        label: p.t('prestations.centile.columns.label'),
        value: prestation?.label,
      },
      {
        label: p.t('prestations.centile.columns.devices'),
        value: <>
          <Table columns={devicesColumns} loading={false} datas={prestation?.devices ? prestation.devices : []} idTable="test" options={devicesTableOptions} />
        </>,
        align: alignTop
      },
    ]

  const Tabs = {
    navigation: [
      {
        libelle: p.t('prestations.detail.informations'),
        jsx: <>{p.t('prestations.detail.informations')}</>
      },
      {
        libelle: p.t('prestations.detail.historique'),
        jsx: <>{p.t('prestations.detail.historique')}</>
      }
    ],
    panels: [
      <DescriptionList datas={infos} key="Infos_CENTILE" columnWidthClassName="w-[114px]" />,
      <PrestationHistoriqueModaleBody key="Historique_CENTILE" prestationId={prestation?.id} />,
    ]

  }


  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('prestations.centile.detail.title')}</div>
              <div className='ml-1'>{prestation?.extension}</div>
              <div className='ml-2'><Badge itemLibelle={prestation?.etat?.libelle} itemColor={prestation?.etatColor} /></div>
            </div>
            <div className="text-xs text-gray-600 mt-1">
              <div>{prestation?.site?.nom}</div>
              <div>{prestation?.site?.codePostal} {prestation?.site?.commune}</div>
            </div>
          </>
          ,
          body: <PrestationDetailModaleBody tabsNavigation={Tabs.navigation} tabsPanel={Tabs.panels} />,
          footer: <><div className='flex items-center justify-end'>
            <Button onClick={onClose}>
              {p.t('actions.fermer')}
            </Button>
          </div></>,
        }}
        className="w-full lg:w-6/12 lg:h-5/6"
      />
    </>
  )
}

export default PrestationDetailModaleCENTILE