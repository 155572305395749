import React from 'react'
import { FichiersType } from '../Discussions'
import Attachment from './Attachment'
import { DocumentNode } from 'graphql'


type AttachmentsType = {
  fichiers: Array<FichiersType>
  queryDownload: DocumentNode
}

export default function Attachments({ fichiers, queryDownload }: AttachmentsType) {
  return <>
    {fichiers &&
      <>
        <div className="flex flex-wrap">
          {fichiers.map((fichier, index) =>
            <div key={index} className="mt-2 mr-2">
              <Attachment fichier={fichier} queryDownload={queryDownload} />
            </div>
          )}
        </div>
      </>
    }
  </>
}