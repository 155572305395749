import React from "react";
import { getDataFromHostname } from "../utils/utils";

type LogoType = {
  type?: "original" | "gold" | "tiny"
  className?: string
  width?: number
  height?: number
}

function Logo({type="original", className="", width, height} : LogoType) {

  const {prefixLogo} = getDataFromHostname()

  const getImgFile = () => {
    let img="";
    
    switch (type) {
      case "gold":
        img=`${prefixLogo}-logo-gold`
        break;
    
      case "tiny":
        img=`${prefixLogo}-tiny-white-alt`
        break;
    
      default:
        img=`${prefixLogo}-logo-original`
        break;
    }
    
    return img
  }

  return (
      <img
          className={className}
          src={`/images/${getImgFile()}.png`}
          alt=""
          width={width}
          height={height}
      />
  );

}

export default Logo;