import React, { useContext } from 'react';
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { useP } from '../../../services/i18n';
import ExportsGSM from '../../../Shell/ExportsGSM/ExportsGSM';
import WidgetLayout from '../WidgetLayout/WidgetLayout';
import WidgetSize from '../WidgetSize';
import WidgetElementType from '../WidgetElementType';
import { authContext } from '../../../AuthContext';

function WidgetTelechargementTelephonieTiny(props: WidgetElementType) {
  const {
    selectedCompte,
  } = useContext(authContext)

  const p = useP();

  return (
    <WidgetLayout
      libelle={p.t('dashboardCompte.downloads')}
      Icon={ArrowDownTrayIcon}
      color={"sky"}
      size={WidgetSize.TINY}
      addWidget={props.addWidget}
      moveUpWidget={props.moveUpWidget}
      moveDownWidget={props.moveDownWidget}
      removeWidget={props.removeWidget}
    >
      <div className="">
        {Array.isArray(selectedCompte) && selectedCompte.length > 1 ?
          <div className="text-gray-600 text-sm font-medium">
            {p.t("widgets.multiCompte")}
          </div> :
          <ExportsGSM />}
      </div>
    </WidgetLayout>
  )
}

export default WidgetTelechargementTelephonieTiny