import { useQuery } from '@apollo/client'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import React, { useContext, useEffect, useState } from 'react'
import { authContext } from '../../AuthContext'
import Constants from '../../commons/Constants'
import ButtonsGroup from '../../Components/ButtonsGroup/ButtonsGroup'
import PrependFilter from '../../Components/ButtonsGroup/Prepends/PrependFilter'
import Card from '../../Components/Card'
import Table from '../../Components/Table/Table'
import { colorStateTicket } from '../../Components/Utils/Utils'
import { useP } from '../../services/i18n'
import { GET_TICKET_STATUTS } from '../Compte/DashboardCompte/queries'

type TableHistoriqueTicketsType = {
  title: string
  datas: any[]
}

const {
  TICKET_STATUT_ID_EN_COURS,
  TICKET_STATUT_ID_EN_ATTENTE_CLIENT,
  TICKET_STATUT_ID_EN_ATTENTE_FOURNISSEUR,
  TICKET_STATUT_ID_NOUVEAU,
  TICKET_STATUT_ID_OBSERVATION,
  TICKET_STATUT_ID_CLOTURE_ADMINISTRATIVE,
  TICKET_STATUT_ID_CLOTURE_TECHNIQUE,
  TICKET_STATUT_ID_CLOTURE_CLIENT,
  SUPPORT_URL
} = Constants;

export default function TableHistoriqueTickets({ title, datas }: TableHistoriqueTicketsType) {
  const p = useP()
  const { loggedInUser } = useContext(authContext);

  // table data des tickets
  const columns = [
    {
      libelle: p.t('dashboardCompte.tickets.columns.statut'),
      code: 'statut',
      colType: 'state',
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.numero'),
      code: 'numero',
      colType: 'string',
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.sujet'),
      code: 'titre',
      colType: 'string',
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.description'),
      code: 'description',
      colType: 'string',
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.dateOuverture'),
      code: 'dateCreation',
      colType: 'dateTime',
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.dateCloture'),
      code: 'dateCloture',
      colType: 'dateTime',
    },
    {
      libelle: "",
      code: "detail",
      colType: 'iconAction',
      icon: <ArrowTopRightOnSquareIcon className='h-5 w-5 text-gray-500 group-hover:text-secondary-500 mx-2' />,
      action: (item) => window.location.replace(`${SUPPORT_URL}/auth?token=${loggedInUser?.token}&redir=/support/ticket/${item.numero}`),
      tooltipMessage: p.t('dashboardCompte.voirTicket')
    }
  ]

  const [datasTicketsFilter, setDatasTicketsFilter] = useState<any[]>([])
  const [filterButtonsStatuts, setFilterButtonsStatuts] = useState<any[]>([]);
  const [filterStatut, setFilterStatut] =
    useState<any>([`${TICKET_STATUT_ID_EN_COURS},${TICKET_STATUT_ID_OBSERVATION},${TICKET_STATUT_ID_EN_ATTENTE_FOURNISSEUR}`,
    `${TICKET_STATUT_ID_EN_ATTENTE_CLIENT}`,
    `${TICKET_STATUT_ID_NOUVEAU}`,
    `${TICKET_STATUT_ID_CLOTURE_TECHNIQUE},${TICKET_STATUT_ID_CLOTURE_CLIENT},${TICKET_STATUT_ID_CLOTURE_ADMINISTRATIVE}`
    ]);

  const { loading: loadingTicketStatuts, data: dataTicketStatuts } = useQuery(GET_TICKET_STATUTS, {
    variables: {
      sorters: [
        {
          column: "ordreClient"
        }
      ]
    }
  })

  useEffect(() => {
    if (!loadingTicketStatuts) {

      const status: any[] = [];

      dataTicketStatuts.ticketStatuts.items.forEach((s) => {
        if (status[s.statutPublic]) {
          status[s.statutPublic].push(s.id)
        } else {
          status[s.statutPublic] = [s.id]
        }
      })

      // Filtres statut
      const filters: any[] = []
      for (const key in status) {
        filters.push({
          libelle: key, value: status[key].join(",")
        })
      }
      setFilterButtonsStatuts(filters)
    }
  }, [dataTicketStatuts, loadingTicketStatuts])

  useEffect(() => {

    const datasFormates =
      datas.map((t) => ({
        ...t,
        description: <div style={{ whiteSpace: "pre-wrap" }}>{t.description}</div>,
        statut: {
          libelle: t.ticketStatut.statutPublic,
          colorClasses: `bg-${colorStateTicket(t.ticketStatut.id)}-200 text-${colorStateTicket(t.ticketStatut.id)}-800`
        }
      }))

    // On applique le filtre        
    setDatasTicketsFilter(
      datasFormates
        .filter((t) => filterStatut?.find((f) => f.split(',').includes(t.ticketStatut.id)))
        .sort((t1, t2) => t1.dateCreation > t2.dateCreation ? -1 : 1)
    )

  }, [datas, filterStatut])

  return <>
    <div className='flex flex-col'>
    <div className='flex justify-end'>
      <ButtonsGroup 
        selectedButtons={filterStatut} 
        onSelectedButton={(value) => setFilterStatut(value)} 
        buttons={filterButtonsStatuts} 
        multi={true}        
      />
    </div>
    <Table
      idTable='ec-table-historique-lien-pagination'
      loading={false}
      datas={datasTicketsFilter}
      columns={columns}
      options={{
        search: false,
        pagination: false
      }}
    />
    </div>
  </>


}