import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom";
import { authContext } from "../../AuthContext";
import { useP } from "../../services/i18n";
import { GET_DOCUMENT } from "./queries";


export default function DocumentationRedirect() {

  const params = useParams();
  const p = useP();
  const { loggedInUser } = useContext(authContext);

  const [redirect, setRedirect] = useState<string | null>(null)

  const [getDocument] = useLazyQuery(GET_DOCUMENT, {
    fetchPolicy: "network-only",
    variables: {
      guid: params["*"]    
    }
  });

  useEffect(() => {

    const getDocumentSync = async () => {
      const document = await getDocument();
      const {data} = document;

      if (data?.document){
        setRedirect(`${data?.document?.documentType?.path}?file=${data?.document?.guid}`)      
      }else{
        setRedirect(`/${params["*"]}`)      
      }
      
    }

    getDocumentSync()
  }, [])

  return redirect ? <Navigate to={`/compte/${loggedInUser?.compte.id}/${p.t("router.compte.documentation.route")}${redirect}`} /> : <></>
}