import { InformationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import Tooltip from "./Tooltip";

type TooltipInformationType = {
  sizeClass?: string,
  colorClass?: string,
  otherClass?: string,
  contenu: string | JSX.Element
}
export default function TooltipInformation({ sizeClass = "h-5 w-5", colorClass = "text-gray-700", otherClass, contenu }: TooltipInformationType) {
  return <>
    <Tooltip
      button={<InformationCircleIcon className={clsx(`cursor-pointer`, sizeClass, colorClass, otherClass)} />}
      withArrow
      content={<div className='text-xs'>{contenu}</div>}
      placement='top'
    />
  </>
}