import React from "react"
import { ChevronBarDown, ChevronBarUp } from "react-bootstrap-icons"
import Tooltip from "../Tooltip/Tooltip"
import { useP } from "../../services/i18n"


type DiscussionScrollToType = {
  refElement: Element
  libelleTop?: string,
  libelleBottom?: string,
  tooltip?: boolean,
}
export default function DiscussionScrollTo({ refElement, libelleTop, libelleBottom, tooltip = false }: DiscussionScrollToType) {
  const p = useP();
  const scrollDiscussionsTo = (direction) => {
    if (direction === "top") {
      refElement.scrollTop = 0
    } else if (direction === "bottom") {
      refElement.scrollTop = refElement.scrollHeight
    }
  }

  return <>

    <div
      onClick={() => scrollDiscussionsTo("top")}
      className="shadow h-6 w-6 flex items-center justify-center border-b border-white hover:bg-secondary-200 cursor-pointer bg-secondary-300/80 rounded-t-md">
      {
        tooltip ?
          <Tooltip
            button={<ChevronBarUp className="h-4 w-4 text-secondary-700" />}
            content={<div className="text-xs flex items-center justify-center min-w-[150px]">{libelleTop || p.t('actions.scrollToTop')}</div>}
            offset={[0, 5]}
            placement="auto"
          />
          : <ChevronBarUp className="h-4 w-4 text-secondary-700" />
      }


    </div>
    <div
      onClick={() => scrollDiscussionsTo("bottom")}
      className="shadow h-6 w-6 flex items-center justify-center hover:bg-secondary-200 cursor-pointer bg-secondary-300/80 rounded-b-md">
      {
        tooltip ?
          <Tooltip
            button={<ChevronBarDown className="h-4 w-4 text-secondary-700" />}
            content={<div className="text-xs flex items-center justify-center min-w-[150px]">{libelleBottom || p.t('actions.scrollToBottom')}</div>}
            offset={[0, 5]}
            placement="auto"
          />
          : <ChevronBarDown className="h-4 w-4 text-secondary-700" />
      }

    </div>

  </>
}