import React, { useContext, useEffect, useState } from "react"
import { useP } from "../../services/i18n";
import DocumentationContainer from "./DocumentationContainer";
import ShellLayout from "../ShellLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_DOCUMENTS, GET_TYPE_DOCUMENTS } from "./queries";
import { useNavigate, useParams } from "react-router-dom";
import { authContext } from "../../AuthContext";

export default function Documentation() {

  const p = useP();
  const navigate = useNavigate();
  const params = useParams();
  const { selectedCompte, loggedInUser } = useContext(authContext);


  const breadcrumbDatas = [
    {
      libelle: p.t('navigation.documentation.label'),
      href: "#",
      current: true
    }
  ]

  const [slideOverIsOpen, setSlideOverIsOpen] = useState<boolean>(false)
  const [selectedTreeItem, setSelectedTreeItem] = useState<string | null>(null)

  const { data: dataDocumentTypes, loading: loadingDocumentTypes } = useQuery(GET_TYPE_DOCUMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [getDocuments, { data: dataDocuments, loading: loadingDocuments }] = useLazyQuery(GET_DOCUMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  const onSelectFolderTree = (item: Array<string>) => {
    const path = dataDocumentTypes?.documentTypes.items.find((i) => i.id === item[0]).path
    const prefix = Array.isArray(selectedCompte) ? p.t("router.comptes.route") : p.t("router.compte.route")
    const compteId = Array.isArray(selectedCompte) ? loggedInUser?.compte.id : selectedCompte
    navigate(`/${prefix}/${compteId}/documentation${path}`)    
  };

  useEffect(() => {
    if (dataDocumentTypes?.documentTypes?.items.length > 0) {            
      const selectedItem = dataDocumentTypes.documentTypes.items.find(i=>i.path===`/${params["*"]}`)?.id || dataDocumentTypes.documentTypes.items[0].id      
      setSelectedTreeItem(selectedItem)
      getDocuments({
        variables: {
          documentTypeId: selectedItem,
          filtersfiltersApproval: [{
            column: "document.document_type_id",
            value: selectedItem
          }]
        }
      })
    }
  }, [dataDocumentTypes, params])


  return <>
    <ShellLayout>
      {
        (selectedTreeItem && dataDocumentTypes?.documentTypes?.items &&
          <DocumentationContainer
            breadcrumb={breadcrumbDatas}
            dataTypeDocuments={dataDocumentTypes?.documentTypes?.items || []}
            loadingTypeDocuments={loadingDocumentTypes}
            dataDocuments={dataDocuments?.mesDocuments || []}
            dataDocumentApprovals={dataDocuments?.documentApprovals?.items || []}
            loadingDocuments={loadingDocuments}
            onSelectFolderTree={onSelectFolderTree}
            mobileTreeMenuIsOpen={slideOverIsOpen}
            onCloseMobileTreeMenuIsOpen={() => setSlideOverIsOpen(false)}
            selectedItem={selectedTreeItem}
          /> 
        )
      }

    </ShellLayout>
  </>
}