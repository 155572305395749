import { gql } from "@apollo/client";


const TELECHARGER_EXPORT_PARC_GSM = gql`
query TelechargerParcGsm($idPrestation: ID, $idSite: ID, $numcli: ID) {
  telechargerParcGsm(idPrestation: $idPrestation, idSite: $idSite, numcli: $numcli) {
    hash
    filename
  }
}`

const TELECHARGER_EXPORT_CDR_GSM_PRESTA = gql`query TelechargerCdrGsmPresta($idPrestation: ID, $idSite: ID, $numcli: ID) {
  telechargerCdrGsmPresta(idPrestation: $idPrestation, idSite: $idSite, numcli: $numcli) {
    hash
    filename
  }
}`

const TELECHARGER_EXPORT_CONSO = gql`query TelechargerConso($anneeMois: String!, $idPrestation: ID, $idSite: ID, $numcli: ID) {
  telechargerConso(anneeMois: $anneeMois, idPrestation: $idPrestation, idSite: $idSite, numcli: $numcli) {
    hash
    filename
  }
}`

export {
  TELECHARGER_EXPORT_PARC_GSM,
  TELECHARGER_EXPORT_CDR_GSM_PRESTA,
  TELECHARGER_EXPORT_CONSO,
}