const btBluePalette = {
    50: "#f5f9ff",
    100: "#d9e9ff",
    200: "#bbd1f0",
    300: "#8db7f2",
    400: "#6f9ad8",
    500: "#1d5eba",
    600: "#003f9a",
    700: "#053171",
    800: "#001a3f",
    900: "#001532",
};

const btGoldPalette = {
  50: "#faf8f5",
  100: "#f6f1ea",
  200: "#e8ddcb",
  300:"#d9c9ab",
  400:"#bda06c",
  500: "#a1772d",
  600: "#916b29",
  700: "#795922",
  800: "#61471b",
  900: "#4f3a16",
};


export default {
  primary: btBluePalette,
  secondary: btGoldPalette,
}