import React from "react"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentDetailContrats_Q3(){
  const p = useP()
  return <>
    <P>
      <>{parse(p.t('helpBox.helpContrats.helpDetailContrat.a3'))}</>
    </P>
  </>
}