import React from 'react'
import { useP } from '../../services/i18n';
import Button, { ButtonsColorType } from '../Button';


type ModalFooterActionType = {
  onClickAction?: () => void,
  color?: ButtonsColorType,
  label: string
}
export default function ModalFooterAction({ onClickAction, color, label }: ModalFooterActionType) {
  return <>
    <div className='flex items-center justify-end'>
      <Button onClick={onClickAction} color={color}>
        {label}
      </Button>
    </div>
  </>
}