import { gql } from "@apollo/client";


const GET_LIEN = gql`
  query Lien($prestationId: ID) {
    lien(prestationId: $prestationId) {
      id
      debitUp
      debitDown
      optionGtr
      codeNra
      vlan
      typeLien{
        libelle
      }
      nombrePaires
      numeroRaccordement
      dateMiseEnService
      etat
      hasOptionSupervision
      porteCollecte {
        description
      }
      prestation {
        libelle
        tickets{
          id
          idExterne
          numero
          titre
          description    
          dateCreation
          dateCloture      
          ticketStatut {
            id
            statutPublic
            isClosDefinitif
          }
        }
      }
      logins {
        login
        id       
        isProduction 
        connectedLogin {
          isConnected
        }
        connectedLoginHistoriques {
          isConnectedBas
          date
        }
        ips {
          ipV4
          isWan
          subnet
          isFrameroute
          typeIp {
            nom
          }
          ipStatuts {
            horodatage
            pingResult
          }
        }
        ticketImpact {
          libelle
          id
        }
        activiteTotaleImpactee
      }
      travauxProgrammes {
        id
        dateDebut
        duree
        dateFin
        nature
        referenceFournisseur
        cause
        impact
        statutTravailProgramme {
          code
          libelle   
          couleur       
        }
        typeTravailProgramme {
          code
          libelle
        }
      }
    }
  }`


const GET_DISPONIBILITE = gql`
  query LoginDisponibilite($login: String!, $date: [String]!) {
    loginDisponibilite(login: $login, date: $date) {
      dispoPourcentage
      dispoDuree
      indispoDuree
      dispoPourcentageHo
      dispoDureeHo
      indispoDureeHo
    }
  }
`;

const GET_MESURES_IN_OUT = gql`
  query loginDebitInOut($login: String!, $date: [String]!) {
    loginDebitInOut(login: $login, date: $date) {
      datasets {
        id
        label
        borderColor
        backgroundColor
        pointBackgroundColor
        fill
        borderWidth
        data {
          codeLegend
          value
          ids
        }
      }
    }
  }
`;

const GET_LOGINS = gql`
  query logins($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
  logins(filters: $filters, sorters: $sorters, pagination: $pagination) {
    items {
      activiteTotaleImpactee
      ticketImpact {
        id
        libelle
      }
      id
      login
      isProduction
    }
  }
}
`

export {
  GET_LIEN,
  GET_DISPONIBILITE,
  GET_MESURES_IN_OUT,
  GET_LOGINS
}