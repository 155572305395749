import React, { useContext } from "react"
import { authContext } from "../../AuthContext";

type CheckFeatureFlagType = {
  stableComponent: string
  betaComponent: string
  featureFlag: string
  children: JSX.Element[] | JSX.Element
}

export const useCheckFeatureFlag = () => {

  const { featuresFlag } = useContext(authContext);

  const checkFeatureFlag = (featureFlag: string) => {
    if (featureFlag === null)
      return false;
    if(!featuresFlag || featuresFlag.length === 0)
      return false;
    return featuresFlag?.includes(featureFlag);
  }
  return { checkFeatureFlag }
}


export default function CheckFeatureFlag({ children, featureFlag }: CheckFeatureFlagType) {

  const { checkFeatureFlag } = useCheckFeatureFlag();

  return checkFeatureFlag(featureFlag) ? <>{children}</> : <></>
}
