import dayjs from 'dayjs';
import React from 'react'

type CellDurationFormatterType = {
  value: number,
}

export default function CellDurationFormatter({ value }: CellDurationFormatterType) {
  const duration = dayjs.duration(value ? value * 1000 : 0);      
  return <>
    <div className=''>
      {`${duration.hours()} h ${duration.minutes()} m ${duration.seconds()} s`}
    </div>
  </>
}