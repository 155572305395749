import WidgetCarteLiensTiny from './WidgetCarteLiens/WidgetCarteLiensTiny';
import WidgetCarteLiensWide from './WidgetCarteLiens/WidgetCarteLiensWide';
import WidgetCommandePrestationWide from './WidgetCommandePrestation/WidgetCommandePrestationWide';
import WidgetFactures from './WidgetFactures/WidgetFacturesWide';
import WidgetLiensDecoTiny from './WidgetLiensDeco/WidgetLiensDecoTiny';
import WidgetMesContactsTiny from './WidgetMesContacts/WidgetMesContactsTiny';
import WidgetTelechargementTelephonieTiny from './WidgetTelechargementTelephonie/WidgetTelechargementTelephonieTiny';
import WidgetTicketsEnCoursWide from './WidgetTicketsEnCours/WidgetTicketsEnCoursWide';
import WidgetTpAVenirWide from './WidgetTpAVenir/WidgetTpAVenirWide';

const TABLE_IMAGE_URL = '/images/widgets/widget_table.png';
const LIST_IMAGE_URL = '/images/widgets/widget_list.png';
const ACTIONS_IMAGE_URL = '/images/widgets/widget_actions.png';
const CONTACT_IMAGE_URL = '/images/widgets/widget_contact.png';
const CARTE_IMAGE_URL = '/images/widgets/widget_carte.png';

type WidgetListItemType = {
    TINY?: any,
    WIDE?: any,
    imageUrl: string
}


const LIEN_DECO: WidgetListItemType = {
    TINY: WidgetLiensDecoTiny,
    imageUrl: LIST_IMAGE_URL
}

const TELECHARGEMENT_TELEPHONIE: WidgetListItemType = {
    TINY: WidgetTelechargementTelephonieTiny,
    imageUrl: ACTIONS_IMAGE_URL
}


const MES_CONTACTS: WidgetListItemType = {
    TINY: WidgetMesContactsTiny,
    imageUrl: CONTACT_IMAGE_URL
}


const TICKETS_EN_COURS: WidgetListItemType = {
    WIDE: WidgetTicketsEnCoursWide,
    imageUrl: TABLE_IMAGE_URL
}


const TP_A_VENIR: WidgetListItemType = {
    WIDE: WidgetTpAVenirWide,
    imageUrl: TABLE_IMAGE_URL
}

const MES_FACTURES: WidgetListItemType = {
    WIDE: WidgetFactures,
    imageUrl: TABLE_IMAGE_URL
}


const COMMANDE_PRESTATION: WidgetListItemType = {
    WIDE: WidgetCommandePrestationWide,
    imageUrl: TABLE_IMAGE_URL
}

const LIENS_CARTE: WidgetListItemType = {
    WIDE: WidgetCarteLiensWide,
    TINY: WidgetCarteLiensTiny,
    imageUrl: CARTE_IMAGE_URL
}

const WidgetList = {
    LIEN_DECO,
    TELECHARGEMENT_TELEPHONIE,
    MES_CONTACTS,
    TICKETS_EN_COURS,
    TP_A_VENIR,
    MES_FACTURES,
    COMMANDE_PRESTATION,
    LIENS_CARTE
}

export default WidgetList