import clsx from 'clsx'
import React, { useState } from 'react'
import { motion } from "framer-motion"

export type SwitchIconDataType = {
  id: number,
  icon: JSX.Element,
  label: string,

}

type SwitchIconType = {
  datas: Array<SwitchIconDataType>,
  onSelect: (value: SwitchIconDataType) => void,
  defaultItem: SwitchIconDataType
}

export default function SwitchIcon({ datas, onSelect, defaultItem }: SwitchIconType) {

  const [selectedItem, setSelectedItem] = useState(defaultItem);

  return <>
    <div className='flex items-center shadow-sm bg-gray-50 py-0 rounded-md text-xs border border-gray-300 text-gray-700'>
      {
        datas.map((item) => (
          <div
            key={item.id}
            className={clsx(
              `flex items-center mx-1 my-1 py-0.5 px-1 relative cursor-pointer font-medium`,
              item.id == 0 ? `` : ``
            )}
            onClick={() => {
              setSelectedItem(item)
              onSelect(item)
            }}
          >
            <div className={clsx(`mr-0.5 transition-all delay-75 relative z-20`, item.id == selectedItem.id && `text-primary-800`)}>
              {item.icon}
            </div>
            <div className={clsx(`relative transition-all delay-75 z-20 text-sm`, item.id == selectedItem.id && `text-primary-800`)}>
              {item.label}
            </div>
            {
              item.id == selectedItem.id && <motion.div className="absolute left-0 z-10 h-full w-full rounded-[4px] bg-primary-200" layoutId="SelectedItem" />
            }
          </div>
        ))
      }

    </div>
  </>
}