import React from 'react'
import Td from './Td'
import Tr from './Tr'
import clsx from 'clsx'
import { TableOptionsType } from './Types/TableOptionsType'

type TbodyRowRuptureType = {
  value: React.ReactNode,
  tableOptions: TableOptionsType,
  colspan: number
}

function TbodyRowRupture({ value, tableOptions, colspan }: TbodyRowRuptureType) {

  return (
    <>
      {
        <Tr>
          <Td
            colspan={colspan}
            tableOptions={tableOptions}
            value={value}
            className="pt-0 pb-0 pl-0 pr-0"
          />
        </Tr>
      }
    </>
  )

}

export default TbodyRowRupture