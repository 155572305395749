import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import Tooltip from '../../../../../Components/Tooltip/Tooltip';
import clsx from 'clsx';

type RolesListItemsActionsInfoType = {
  descriptif: string,
  className?: string
}

export default function RolesListItemsActionsInfo({ descriptif, className }: RolesListItemsActionsInfoType) {
  return (
    <>
      <Tooltip
        button={
          <button type="button" className={clsx(`inline-flex group cursor-auto rounded-md focus:bg-white focus:outline-none`, className)}>
            <InformationCircleIcon className="h-5 w-5 mx-2 text-gray-500 group-hover:text-primary-600 group-focus:text-primary-600"
            />
          </button>}
        content={<div className='text-xs'>{descriptif}</ div>}
        placement='auto'
        withArrow={true}
      />
    </>)

}