import React, { ReactNode } from "react";
import clsx from "clsx";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Tooltip from "./Tooltip/Tooltip";

type BadgeType = {
  itemColor?: string,
  itemLibelle: string | ReactNode,
  itemSize?: string,
  className?: string,
  bordered?: boolean,
  deletable?: boolean,
  rounded?: boolean,
  truncate?: boolean,
  narrowPadding?: boolean,
  deleteAction?: () => void
}

function BadgeElement({
  itemColor,
  itemLibelle,
  itemSize,
  className,
  bordered,
  deletable,
  deleteAction,
  rounded,
  truncate,
  narrowPadding }: BadgeType) {
  return (<>
    <span
      className={clsx(
        `flex items-center justify-center ${itemSize} normal-case font-medium`,
        deletable ? `pl-2 pr-1 py-1` : narrowPadding ? `px-1 py-0` : `px-2 py-0.5`,
        rounded ? `rounded-full` : `rounded-md`,
        itemColor != "white" ? `bg-${itemColor}-200 text-${itemColor}-800` : 'bg-white text-gray-800',
        bordered && `border border-${itemColor}-700`,
        truncate && 'truncate',

        className
      )}
    >
      {
        deletable ?
          <div className={clsx(`flex items-center space-x-2`, truncate && 'truncate')}>
            <div className={clsx(truncate && 'truncate')}>{itemLibelle}</div>
            <div
              onClick={deleteAction}
              className={clsx(`
            p-1 cursor-pointer`,
                rounded ? `rounded-full` : `rounded-md`,
                `bg-${itemColor}-200 hover:bg-${itemColor}-100`)}>
              <XMarkIcon className="h-3 w-3" />
            </div>
          </div>
          : itemLibelle
      }
    </span></>)
}

function Badge({
  itemColor = "primary",
  itemSize = "text-xs",
  itemLibelle,
  className = "",
  bordered = false,
  deletable = false,
  deleteAction,
  rounded = true,
  truncate = false,
  narrowPadding = false
 }: BadgeType) {

  const props = {
    itemColor: itemColor,
    itemLibelle: itemLibelle,
    itemSize: itemSize,
    className: className,
    bordered: bordered,
    deletable: deletable,
    deleteAction: deleteAction,
    rounded: rounded,
    truncate: truncate,
    narrowPadding: narrowPadding
  }

  return (

    truncate ? <>
      <Tooltip
        button={<BadgeElement {...props} />}
        content={<div className="text-xs">{itemLibelle}</div>}
        withArrow
      />
    </>
      : <><BadgeElement {...props} /></>
  );
}

export default Badge;