import React from "react";
type sizeImgObjectType = {
  height?: string|number|undefined,
  width?: string|number|undefined,
}
type IMGType = {
  src: string,
  alt?: string,
  sizes? : sizeImgObjectType
}

export default function IMG({src,alt,sizes}:IMGType){
  return <>
    <img src={src} alt={alt} className="border border-gray-200 rounded-md" height={sizes?.height} width={sizes?.width} />
  </>
}