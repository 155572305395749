import React from 'react'
import Logo from '../../../Components/Logo'

export default function PopInformationModalBody(){
  return <>
    <div className="text-md px-24">      
      <h1>
        <span className="mt-2 block text-4xl font-bold leading-8 tracking-tight text-primary-700 sm:text-3xl">Bienvenue sur votre nouvel espace client !</span>
      </h1>
      
      <div className='flex items-start mt-8 space-x-8'>
        <div 
          className='h-[200px] px-20 rounded-lg flex items-center justify-center bg-radient-ellipse-c from-primary-700 from-0% to-primary-800 to-70%'>
          <Logo type="gold" className='w-[150px]' />          
        </div>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'></h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Nous avons voulu vous offrir la meilleure expérience de navigation, 
          et vous simplifier la visibilité de toutes vos prestations par catégorie.</div>
      
          <div className="mt-4 text-md leading-5">
          Vous l&#39;aurez constaté, dès votre 1ère connexion, il vous est demandé de configurer une authentification 
          forte afin de sécuriser l&#39;accès à vos données sur ce nouvel espace.
          </div>

        </div>
      </div>
      
      <h2 className='mt-8 text-xl font-bold leading-6 text-primary-700'>Parmi les nouveautés, vous trouverez ci-dessous les éléments majeurs :</h2>
      <div className='flex items-start mt-8 space-x-8'>
        <div className='rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/comptes.png" alt="" className='w-[200px] rounded-md shadow-xl border-gray-200 border'  />
        </div>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Un accès simplifié à vos entités</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Un menu déroulant dans la barre de navigation vous permet de sélectionner l’entité juridique que vous souhaitez consulter.</div>      
        </div>
      </div>

      <div className='flex items-start mt-8 space-x-8'>
        <div className='rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/contrat_devis.png" alt="" className='w-[200px] rounded-md shadow-xl border-gray-200 border'  />
        </div>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>La visibilité complète de vos contrats</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Vous avez accès au listing de tous vos contrats, de vos données de facturation, de vos abonnements ainsi qu&#39;à la liste des devis signés.</div>      
        </div>
      </div>

      <div className='flex items-start mt-8 space-x-8'>
        <div className='rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/factures.png" alt="" className='w-[200px] rounded-md shadow-xl border-gray-200 border'  />
        </div>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Un accès à toutes vos factures</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Vous pouvez visualiser l&#39;ensemble vos factures, les filtrer et les télécharger.</div>      
        </div>
      </div>

      <div className='flex items-start mt-8 space-x-8'>
        <div className='rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/annuaire.png" alt="" className='w-[200px] rounded-md shadow-xl border-gray-200 border'  />
        </div>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>La gestion de l&#39;annuaire de votre entreprise </h2>
          <div className="mt-4 text-md leading-5 text-gray-500">Vous pouvez gérer vos contacts et leurs droits en fonction de leur profil.</div>      
        </div>
      </div>

      <div className='flex items-start mt-8 space-x-8'>
        <div className='rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/help.png" alt="" className='w-[200px] rounded-md shadow-xl border-gray-200 border'  />
        </div>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Une aide contextuelle</h2>
          <div className="mt-4 text-md leading-5 text-gray-500">En bas à droite de chaque page, une aide vous est également mise à disposition et sera enrichie dans les prochaines semaines pour vous aider à l&#39;utilisation de ce nouvel espace.</div>      
        </div>
      </div>

      <div className="mt-8 mb-6 text-md leading-5 text-gray-500 flex items-center justify-center">
        <div>Très bonne navigation !</div>
      </div>

    </div>
  </>
}