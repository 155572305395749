import { gql } from "@apollo/client";

const GET_TYPE_DOCUMENTS = gql`
  query DocumentTypes {
    documentTypes {
      items {
        id
        libelle
        path
        documentTypeEnfants {
          id
        }
        documentTypeParent {
          id
        }
        code
        nbDocuments
      }
    }
  }
`;

const GET_TYPE_DOCUMENT = gql`
  query DocumentType($path: String) {
    documentType(path: $path) {
      id    
    }
  }
`;

const GET_DOCUMENTS = gql`
  query Documents(
    $documentTypeId: ID
    $filtersApproval: [FilterInput]
  ) {
    mesDocuments(documentTypeId: $documentTypeId) {
      id
      titre
      description
      extension
      version
      guid
      actif        
      dateCreation
      dateModification
      isPublic
      approvalMode            
    }
    documentApprovals(filters: $filtersApproval) {
      items {
        id
        dateApproval
        versionApproval
        document {
          id        
        }
      }    
    }
  }
`;

const GET_DOCUMENT = gql`
  query Document($guid: ID) {
    document(guid: $guid) {
      id      
      titre
      description
      extension
      actif
      guid
      version
      approvalMode
      documentType {
        id
        path
      }
      isPublic
      dateCreation
      dateModification
    }
  }
`;

const APPROUVE_DOCUMENT = gql`
  mutation ApprouveDocument($documentId: ID!) {
    approuveDocument(documentId: $documentId) {
      message
      success
    }
  }
`;

const TELECHARGER_DOCUMENT = gql`
  query TelechargerDocument($documentId: ID) {
    telechargerDocument(id: $documentId) {
      filename
      hash
    } 
  }
`;

export {
  GET_TYPE_DOCUMENTS,
  GET_TYPE_DOCUMENT,
  GET_DOCUMENTS,
  GET_DOCUMENT,
  APPROUVE_DOCUMENT,
  TELECHARGER_DOCUMENT,
};
