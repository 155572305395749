import React from 'react'
import dayjs from 'dayjs';
import { useP } from '../../services/i18n';
import { ContratType } from '../../types/ContratType';
import { SiteType } from "../../types/SiteType";
import { SlideOverItemType } from "./SlideOverItemType";
import Constants from '../../commons/Constants'
import Badge from '../../Components/Badge';

export function reduceSites(records: Array<SiteType>): Array<SlideOverItemType> {

  return records.filter((s) => s.prestations && (s.prestations.length > 0)).map((record) => {
    return {
      id: record.id,
      title: record.nom ? record.nom : '',
      subtitle:
        <>
          <div>{record.libelleVoie}</div>
          <div className='max-w-[180px] truncate'>{record.codePostal} {record.commune}</div>
        </>,
      additionalData: {
        telephonieCount: record?.prestations ? record.prestations.filter((p) => ["GSM", "VGA", "NDI", "CENTILE", "TRUNK"].includes(p.type)).length : "",
        dataCount: record?.prestations ? record.prestations.filter((p) => ["LIEN"].includes(p.type)).length : "",
      },
      link: '.',
      searchParams: `site=${record.id}`,
      record
    }
  });

}


export function reduceContrats(records: Array<ContratType>): Array<SlideOverItemType> {

  const p = useP();

  const {
    CONTRAT_STATE_TERMINE,
    CONTRAT_STATE_ACTIVE,
    CONTRAT_STATE_TACITE,
    CONTRAT_STATE_VERSION_PRELIMINAIRE,
  } = Constants;

  const recordsSort = [
    ...records
  ];

  const getBadgeColor = (code) => {
    let color;
    switch (code) {
      case CONTRAT_STATE_ACTIVE: color = "emerald"; break;
      case CONTRAT_STATE_TERMINE: color = "red"; break;
      case CONTRAT_STATE_TACITE: color = "orange"; break;
      case CONTRAT_STATE_VERSION_PRELIMINAIRE: color = "blue"; break;
    }
    return color;
  }

  recordsSort.sort((r1, r2) => {
    if (r1.statutContrat.code === CONTRAT_STATE_TERMINE && r2.statutContrat.code !== CONTRAT_STATE_TERMINE)
      return 1;
    if (r2.statutContrat.code === CONTRAT_STATE_TERMINE && r1.statutContrat.code !== CONTRAT_STATE_TERMINE)
      return -1;

    return r1.nom > r2.nom ? 1 : -1;
  })

  return recordsSort.map((record) => {

    let subTitle = record.statutContrat.libelle;
    if (record.statutContrat.code === CONTRAT_STATE_TERMINE && record.dateFin) {
      subTitle += ` ${p.t('lien.depuisLe').toLowerCase()} ${dayjs(record.dateFin).format("DD/MM/YYYY")}`
    }

    return {
      id: record.numcli,
      title: record.nom ? record.nom : '',
      subtitle:
        <>
          <div>{record.numcli}</div>
          <div className='flex mt-1'>
            <Badge
              itemColor={getBadgeColor(record.statutContrat.code)}
              itemLibelle={subTitle}
            />
          </div>
        </>,
      link: `.`,
      searchParams: `contrat=${record.numcli}`,
      record
    }
  });

}
