import React from "react";
import { TicketType } from "../types/TicketType";
import { useP } from "../services/i18n";
import List from "../Components/List/List";
import dayjs from "dayjs";
import DescriptionList, { DescriptionListItemAlignType } from "../Components/DescriptionList/DescriptionList";

type TicketPublicDescriptionType = {
  ticket: TicketType,
}

function TicketPublicDescription({ ticket }: TicketPublicDescriptionType) {

  const p = useP()
  const alignTop: DescriptionListItemAlignType = "top"

  return <>
    <div className='py-0 px-0 flex-1 flex flex-col overflow-hidden'>
      <div className='px-2 flex-1 flex flex-col overflow-auto '>
        <DescriptionList
          datas={[
            {
              label: p.t('support.detail.dateCreation'),
              value: dayjs(ticket.dateCreation).format("DD/MM/YYYY à HH:mm:ss")
            },
            {
              label: p.t('support.detail.traitePar'),
              value: ticket?.ticketeurUser ? `${ticket.ticketeurUser.prenom} ${ticket.ticketeurUser.nom}` : "Non attribué"
            },
            ...(ticket.isRecurrent ?
              [{
                label: p.t('support.detail.recurrent'),
                value: ""
              }] : []
            ),
            ...(ticket.sites.length > 0 ? [{
              label: p.t('support.detail.sites'),
              value:
                <div className='max-h-52 overflow-y-auto'>
                  <List
                    libelleClassName='font-normal text-sm text-gray-900'
                    datas={ticket.sites.map((s) => ({
                      id: s.id,
                      libelle: `${s.nom} ${s.codePostal} ${s.commune}`
                    }))}
                    canDeleteItem={false} />
                </div>,
              align: alignTop
            }] : []
            ),
            ...[
              {
                label: p.t('support.detail.compte'),
                value: ticket.compte.raisonSociale
              },
              {
                label: p.t('support.detail.contactSite'),
                value: ticket.nomContactSite
              },
              {
                label: p.t('support.detail.numeroContactSite'),
                value: ticket.numeroContactSite
              },
              {
                label: p.t('support.detail.refInterne'),
                value: ticket.referenceClient
              },
              {
                label: p.t('support.detail.type'),
                value: ticket.qualificatif
              },
              {
                label: p.t('support.detail.service'),
                value: ticket.ticketService.libelle
              },
              {
                align: alignTop,
                label: p.t('support.detail.cc.libelle'),
                value: <List
                  libelleClassName='font-normal text-sm text-gray-900'
                  datas={ticket.listeEmailsSuiveurs ? ticket.listeEmailsSuiveurs.split(';').map((i) => ({ id: i, libelle: i })) : []}
                  canDeleteItem={false}
                />

              }
            ],
            ...(ticket.prestations.length > 0 ?
              [{
                label: p.t('support.detail.prestations'),
                value: <>
                  <div className='max-h-52 overflow-y-auto'>
                    <List
                      libelleClassName='font-normal text-sm text-gray-900'
                      datas={ticket.prestations.map((p) => ({
                        id: p.id,
                        libelle: p.libelle
                      }))}
                      canDeleteItem={false} />
                  </div>
                </>,
                align: alignTop
              }] : []
            ),
            ...(ticket?.client ?
              [{
                label: p.t('support.detail.contrat'),
                value: ticket?.client ? `${ticket.client.nomContrat} - ${ticket.client.numcli}` : ""
              }] : []
            ),
            ...(ticket?.ticketPrecedent ? [{
              label: p.t('support.detail.ticketPrecedent'),
              value: ticket?.ticketPrecedent ?
                <div className='flex'>
                  <div>
                    {`${ticket.ticketPrecedent.numero} - ${ticket.ticketPrecedent.titre}`}
                  </div>
                </div> : ""
            }] : [])
          ]}
          columnWidthClassName="w-[170px]" />

      </div>
    </div>

  </>

}

export default TicketPublicDescription;

