import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { useP } from '../../../../services/i18n';
import { UserNavigationType } from '../../../../types/UserNavigationType';

type UserNavigationItemProps = {
  item: UserNavigationType
}

function UserNavigationItem({ item }: UserNavigationItemProps) {

  const p = useP();

  return (
    <Menu.Item key={p.t(item.name)}>
      {({ active }) => (
        <Link
          to={p.t(item.href)}
          className={clsx(
            active ? 'bg-primary-100' : '',
            'block px-4 py-2 text-sm text-primary-700'
          )}
          onClick={() => item?.onClick && item.onClick()}
        >
          {p.t(item.name)}
        </Link>
      )
      }
    </Menu.Item >
  )
}

export default UserNavigationItem