import React from 'react'
type FormSectionTitleType = {
  title: string,
  subTitle?: string,
}
export default function FormSectionTitle({ title, subTitle }: FormSectionTitleType) {
  return (
    <div className='mb-3'>
      <h3 className="text-md leading-6 font-medium text-gray-900">{title}</h3>
      {subTitle && <p className="mt-1 text-xs text-gray-500">{subTitle}</p>}
    </div>
  )
}