import React from 'react'
import clsx from 'clsx'
import { TreeMenuElementType } from './TreeMenu'
import { useNavigate } from "react-router-dom";


type TreeMenuMobileType = {
  datas: Array<TreeMenuElementType>,
  activeItemMenu: string,
  onActiveItemMenu: (activeItem: TreeMenuElementType | null | undefined) => void,
}
export default function TreeMenuMobile({ datas, activeItemMenu, onActiveItemMenu }: TreeMenuMobileType) {
  const navigate = useNavigate();
  const goToView = (view) => {
    const selectedItem = datas.map((item) => item.children).flat();
    const newActiveItem = selectedItem.filter((item) => item?.view == view)
    if (newActiveItem.length > 0) {
      onActiveItemMenu(newActiveItem[0])
      const href = newActiveItem[0] ? newActiveItem[0].href : "./";
      navigate(href ? href : ``);
    }
  }
  return <>
    <div>
      <select
        onChange={(e) => goToView(e.target.value)}
        value={activeItemMenu}
        name=""
        id=""
        className="block w-full pl-3 pr-10 py-2 mb-2 border border-gray-200 hover:bg-white/90 hover:text-gray-700 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
      >
        {
          datas.map((item, i) => (
            <optgroup label={item.name} key={i}>
              {
                item.children &&
                item.children.map((child, ix) => (
                  <option key={clsx(i, ix)} value={child.view}>{child.name}</option>
                ))
              }
            </optgroup>
          ))
        }
      </select>
    </div>
  </>
}