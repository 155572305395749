import { CheckBadgeIcon, CheckCircleIcon, CheckIcon } from '@heroicons/react/24/outline'
import React from 'react'
import Button from '../../Components/Button'
import { useP } from '../../services/i18n'

type NotificationModalFooterType = {
  onClickRappel: () => void,
  onClickLu: () => void,
}

export default function NotificationModalFooter({ onClickRappel, onClickLu }: NotificationModalFooterType) {
  const p = useP();
  return <>
    <div className='flex items-center justify-end'>
      <div className='mr-4'>
        <Button color='secondary' onClick={onClickRappel}>{p.t('components.popInformation.meRappeler')}</Button>
      </div>
      <div>
        <Button onClick={onClickLu}>{p.t('components.popInformation.nePlusAfficher')}</Button>
      </div>
    </div>
  </>
}