import { Menu } from '@headlessui/react'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { TableActionsType } from '../../Types/TableActionsType'
import ActionsItem from './ActionsItem'
import Tooltip from '../../../Tooltip/Tooltip'

type ActionsItemsType = {
  actions: Array<TableActionsType>,
  row: any
}

function ActionsItems({ actions, row }: ActionsItemsType) {

  return (
    <div className="w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      {
        actions.map((action, index) => {
          return (

            action.tooltipContent ?
              <Tooltip
                content={action.tooltipContent}
                withArrow
                button={<ActionsItem action={action} key={index} row={row} />}
              />
              :
              <ActionsItem action={action} key={index} row={row} />
          )
        })
      }
    </div>
  )

}

export default ActionsItems