import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { pdfjs, Document, Page } from "react-pdf"
import "pdfjs-dist/build/pdf.worker.entry";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useP } from "../../services/i18n";
import Spinner from "../Spinner";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import Button from "../Button";
import Tooltip from "../Tooltip/Tooltip";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

type PDFFile = string | File | null;

type PdfViewerType = {
  file: PDFFile,
  fileDownload: string,
  onDocumentLoadSuccess?: () => void,
  scrollBarIsAtBottom: (AtBottom: boolean) => void,
  maxWidth?: number  
}
const options = {
  standardFontDataUrl: '/standard_fonts/',
};

export default function PdfViewer({ file, fileDownload, onDocumentLoadSuccess, scrollBarIsAtBottom }: PdfViewerType) {
  const p = useP()
  const [PDFFile, setPDFFile] = useState<PDFFile>(file);
  const [PDFFileDownload, setPDFFileDownload] = useState<string>(fileDownload);
  const [numPages, setNumPages] = useState<number>();
  const pdfContainerRef = useRef(null);  

  useEffect(() => {
    setPDFFile(file)
  }, [file])

  useEffect(() => {
    setPDFFileDownload(fileDownload)
  }, [fileDownload])

  const handleScroll = () => {
    const container: any = pdfContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        scrollBarIsAtBottom(true)
      }
    }
  };

  
  const [widthPage, setWidthPage] = useState<number>()
  
  function updatePageSize() {
    const pdfContainerElement: any = pdfContainerRef.current;
    if(pdfContainerElement){
     const width = pdfContainerElement.offsetWidth;
     const marge = 28; 
     setWidthPage(width-marge)
    }
  }

  function onPdfLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    onDocumentLoadSuccess?.()
    updatePageSize()    
  }

  return <>
    <div
      className="relative w-full text-xs overflow-x-hidden overflow-y-auto border-t border-x border-gray-300 rounded-t-md p-2 bg-gray-100 group"
      ref={pdfContainerRef}
      onScroll={() => {
        handleScroll()
      }}>
      <Document
        file={PDFFile}
        onLoadSuccess={onPdfLoadSuccess}
        options={options}
        error={p.t('pdfViewer.error')}
        loading={() => <div className="flex items-center justify-center"><Spinner size="h-6 w-6" /></div>}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            loading={<div className="flex items-center justify-center py-10"><Spinner size="h-6 w-6" /></div>}
            width={widthPage}
            className="shadow-md mb-4 border border-gray-100"
          />
        ))}
      </Document>
    </div>
    <div className="bg-gray-100 border boder-gray-300 rounded-b-md flex items-center justify-between space-x-2 w-auto py-1 px-1">
      <div className="text-xs font-medium text-gray-700">
        {numPages && `${numPages} ${numPages === 1 ? `page` : `pages`}`}
      </div>
      {PDFFileDownload && <div className="flex items-center space-x-2">
        <Tooltip
          button={<Button size="small" color="grayBordered" onClick={() => window.open(PDFFileDownload)}>
            <ArrowDownTrayIcon className="h-4 w-4 text-gray-700" />
          </Button>}
          content={<div className='text-xs'>{p.t('actions.telecharger')}</div>}
        />
      </div>}
    </div>
  </>
}