import React, { useContext, useEffect, useState } from 'react';
import { useP } from '../../../services/i18n';
import DescriptionList, { DescriptionListItemAlignType } from '../../../Components/DescriptionList/DescriptionList';
import { ArrowTopRightOnSquareIcon, LinkIcon, StarIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import List from '../../../Components/List/List';
import Button from '../../../Components/Button';
import Rating from '../../../Components/Rating/Rating';
import Tooltip from '../../../Components/Tooltip/Tooltip';
import { TicketType } from '../../../types/TicketType';
import Spinner from '../../../Components/Spinner';
import dayjs from 'dayjs';
import TicketCommentaireDiscussion from './TicketCommentaireDiscussion';
import Constants from '../../../commons/Constants';
import ModaleRatingTicket from '../ModaleRatingTicket/ModaleRatingTicket';
import ModaleReouvertureTicket from '../ModaleReouvertureTicket/ModaleReouvertureTicket';
import TagsInput from '../../../Components/TagsInput';
import { useMutation } from '@apollo/client';
import { SAVE_TICKET, TICKET_PUBLIC } from './queries';
import { getLocalStorage, toastError, toastSuccess } from '../../../Components/Utils/Utils';
import { useSearchParams } from 'react-router-dom';
import { authContext } from '../../../AuthContext';
import InputSwitch from '../../../Components/Form/InputSwitch';
import { ModalConfirmation } from '../../../Components/ModalDialog/ModalConfirmation';
import { useCheckRight } from '../../../Components/CheckRight/CheckRight';
import Splitter from '../../../Components/Splitter/Splitter';

type ModaleDetailTicketBodyType = {
  loading: boolean,
  ticket: TicketType,
  refetchTicket: () => void,
  onChangeNewCommentaire: (commentaire: string, files: any[]) => void
  onClickTicketPrecedent: (ticketPrecedentId: number) => void
}

export default function ModaleDetailTicketBody({ ticket, loading, refetchTicket, onChangeNewCommentaire, onClickTicketPrecedent }: ModaleDetailTicketBodyType) {
  const p = useP();
  const alignTop: DescriptionListItemAlignType = "top"
  const [searchParams] = useSearchParams();

  const MODE_OUVERT = 0;
  const MODE_CLOS = 1;
  const MODE_CLOS_TECHNIQUE = 2;

  const {
    TICKET_STATUT_ID_CLOTURE_TECHNIQUE,
    ROLE_CONTACT_ALERTE_IMPACT,
    TICKET_QUALIF_INCIDENT
  } = Constants

  const {
    loggedInUser,
  } = useContext(authContext)

  const { checkRight } = useCheckRight();

  const [modaleMode, setModaleMode] = useState<number>(MODE_OUVERT)
  const [openModaleRating, setOpenModaleRating] = useState({
    cloture: searchParams.has("notation"),
    open: searchParams.has("notation"),
  })

  const [openModaleReouverture, setOpenModaleReouverture] = useState(searchParams.has("reopen"))
  const [openModaleActivite, setOpenModaleActivite] = useState(false);
  const [activiteTotaleImpactee, setActiviteTotaleImpactee] = useState(false);

  const closeModaleActivite = (confirm: boolean) => {
    setOpenModaleActivite(false)

    if (confirm) {
      saveActiviteTotaleImpactee(true)
    }
  }

  const saveActiviteTotaleImpactee = (activiteImpactee) => {
    setActiviteTotaleImpactee(activiteImpactee)
    saveTicket({
      variables: {
        ticket: {
          id: ticket.id,
          activiteTotaleImpactee: activiteImpactee
        }
      }
    })
  }

  const [saveTicket] = useMutation(SAVE_TICKET, {
    onError: () => {
      toastError(p.t('error.defaultMessage'));
    }
  });

  const [getLienPublic, { loading: loadingLienPublic }] = useMutation(TICKET_PUBLIC, {
    onError: () => {
      toastError(p.t('error.defaultMessage'));
    }
  })

  useEffect(() => {
    let mode: number;
    if (ticket?.ticketStatut.id == TICKET_STATUT_ID_CLOTURE_TECHNIQUE) {
      mode = MODE_CLOS_TECHNIQUE;
    } else {
      mode = ticket?.ticketStatut.isClos ? MODE_CLOS : MODE_OUVERT;
    }
    setModaleMode(mode)
    setActiviteTotaleImpactee(ticket?.activiteTotaleImpactee)
  }, [ticket])

  const onClickLienPublic = () => {
    getLienPublic({
      variables: {
        ticketId: ticket.id
      },
      onCompleted: (data) => {
        const { ticketLienPublic } = data;
        navigator.clipboard.writeText(ticketLienPublic)
        toastSuccess(p.t(`support.detail.lienPublicClipboard`))
      }
    })
  }

  if (loading) {
    return <div className='w-screen h-screen flex items-center justify-center'>
      <Spinner />
    </div>
  }

  return <>
    {modaleMode === MODE_CLOS_TECHNIQUE && <div className='bg-secondary-100 p-4 border-b border-gray-200 flex flex-col justify-center items-center'>
      <div className='text-secondary-800 mb-4 font-medium'>{p.t(`support.detail.resolution.libelle`)}</div>
      <div className='flex items-center space-x-2'>
        <Button onClick={() => setOpenModaleReouverture(true)} color='primaryBordered'>{p.t(`support.detail.resolution.btnNon`)}</Button>
        <Button onClick={() => setOpenModaleRating({ open: true, cloture: true })}>{p.t(`support.detail.resolution.btnOui`)}</Button>
      </div>
    </div>}

    <Splitter
      orientation='horizontal'
      firstHalfInitialSize={getLocalStorage("ec-firstHalfSizeTicket") || 75}
      collapsible="second"
      LSName="ec-firstHalfSizeTicket"
    >
      <div className='flex flex-col-reverse lg:flex-row overflow-hidden bg-white h-full'>
        <TicketCommentaireDiscussion
          ticketId={ticket.id}
          ticketInitialState={ticket.ticketStatut.id}
          canAddCommentaire={modaleMode === MODE_OUVERT}
          refetchTicket={refetchTicket}
          onChangeNewCommentaire={onChangeNewCommentaire}
        />
      </div>
      <div className='text-sm text-gray-700 flex flex-col flex-1 overflow-hidden h-full'>
        <div className='overflow-x-auto border-t border-b lg:border-t-0 border-gray-300 py-2 px-4 flex items-center w-full space-x-2'>
          {(ticket?.ticketRating || modaleMode === MODE_CLOS) && <div onClick={() => setOpenModaleRating({ open: true, cloture: false })} className='flex items-center'>
            <Tooltip
              button={
                ticket?.ticketRating ?
                  <Rating sizeClass="h-6 w-6" readonly={true} value={ticket?.ticketRating?.rating || -1} />
                  :
                  <Button color="primaryBordered">
                    <StarIcon className='h-4 w-4 mr-2' />{p.t(`support.detail.noter`)}
                  </Button>
              }
              withArrow
              content={<div className="text-xs">{p.t('support.detail.tooltipRating')}</div>}
            />
          </div>
          }
          <div className='flex items-center justify-end space-x-2 w-full'>
            <Button color="primaryBordered" onClick={onClickLienPublic}>
              {loadingLienPublic ? <Spinner size='h-4 w-4 mr-2' /> : <LinkIcon className='h-4 w-4 mr-2' />}
              {p.t(`support.detail.lienPublic`)}
            </Button>
            {
              modaleMode === MODE_OUVERT &&
              <div>
                <Button onClick={() => setOpenModaleRating({ open: true, cloture: true })}>
                  <XCircleIcon className='h-4 w-4 mr-2' />{p.t(`support.detail.cloturer`)}
                </Button>
              </div>
            }
          </div>
        </div>
        <div className='py-0 px-0 flex-1 flex flex-col overflow-hidden'>
          <div className='px-2 flex-1 flex flex-col overflow-auto '>
            <DescriptionList
              datas={[
                {
                  label: p.t('support.detail.dateCreation'),
                  value: dayjs(ticket.dateCreation).format("DD/MM/YYYY à HH:mm:ss")
                },
                {
                  label: p.t('support.detail.traitePar'),
                  value: ticket?.ticketeurUser ? `${ticket.ticketeurUser.prenom} ${ticket.ticketeurUser.nom}` : "Non attribué"
                },
                ...(ticket.ticketImpact && loggedInUser?.compte?.isVip ?
                  [{
                    label: p.t('support.detail.impactProduction'),
                    value: ticket.ticketImpact?.libelle
                  }] : []
                ),
                ...((ticket.qualificatif === TICKET_QUALIF_INCIDENT && checkRight(ROLE_CONTACT_ALERTE_IMPACT)) ?
                  [{
                    label: p.t('support.modaleCreation.activiteTotaleImpactee'),
                    value:
                      <div className='flex'>
                        <div><InputSwitch
                          name="activiteTotaleImpactee"
                          value={activiteTotaleImpactee}
                          onChange={(val) => {
                            if (val) {
                              setOpenModaleActivite(true)
                            } else {
                              saveActiviteTotaleImpactee(false)
                            }
                          }}
                          labelPosition='before'
                          bordered
                          manualOnChange
                        />
                        </div>
                        {activiteTotaleImpactee &&
                          <div className='ml-1'>
                            <Tooltip
                              button={
                                <ExclamationTriangleIcon className='text-red-600 h-5 w-5' />
                              }
                              content={<div className="text-xs">{p.t('support.detail.activiteTotaleImpactee')}</div>}
                              withArrow
                            />
                          </div>}
                      </div>
                  }] : []
                ),
                ...(ticket.isRecurrent ?
                  [{
                    label: p.t('support.detail.recurrent'),
                    value: ""
                  }] : []
                ),
                ...(ticket.sites.length > 0 ? [{
                  label: p.t('support.detail.sites'),
                  value:
                    <div className='max-h-52 overflow-y-auto'>
                      <List
                        libelleClassName='font-normal text-sm text-gray-900'
                        datas={ticket.sites.map((s) => ({
                          id: s.id,
                          libelle: `${s.nom} ${s.codePostal} ${s.commune}`
                        }))}
                        canDeleteItem={false} />
                    </div>,
                  align: alignTop
                }] : []
                ),
                ...[
                  {
                    label: p.t('support.detail.compte'),
                    value: ticket.compte.raisonSociale
                  },
                  {
                    label: p.t('support.detail.contactSite'),
                    value: ticket.nomContactSite
                  },
                  {
                    label: p.t('support.detail.numeroContactSite'),
                    value: ticket.numeroContactSite
                  },
                  {
                    label: p.t('support.detail.refInterne'),
                    value: ticket.referenceClient
                  },
                  {
                    label: p.t('support.detail.type'),
                    value: ticket.qualificatif
                  },
                  {
                    label: p.t('support.detail.service'),
                    value: ticket.ticketService.libelle
                  },
                  {
                    align: alignTop,
                    label: p.t('support.detail.cc.libelle'),
                    value: modaleMode === MODE_OUVERT ? <div className="w-full">
                      <TagsInput
                        placeholder={p.t('support.detail.cc.placeholder')}
                        type="email"
                        inputClassName="w-[155px]"
                        defaultTags={ticket.listeEmailsSuiveurs ? ticket.listeEmailsSuiveurs.split(';') : []}
                        onChange={(tags) => saveTicket({
                          variables: {
                            ticket: {
                              id: ticket.id,
                              listeEmailsSuiveurs: tags.join(";")
                            }
                          }
                        })}
                      />
                    </div> : <>
                      <List
                        libelleClassName='font-normal text-sm text-gray-900'
                        datas={ticket.listeEmailsSuiveurs ? ticket.listeEmailsSuiveurs.split(';').map((i) => ({ id: i, libelle: i })) : []}
                        canDeleteItem={false}
                      />
                    </>
                  }
                ],
                ...(ticket.prestations.length > 0 ?
                  [{
                    label: p.t('support.detail.prestations'),
                    value: <>
                      <div className='max-h-52 overflow-y-auto'>
                        <List
                          libelleClassName='font-normal text-sm text-gray-900'
                          datas={ticket.prestations.map((p) => ({
                            id: p.id,
                            libelle: p.libelle
                          }))}
                          canDeleteItem={false} />
                      </div>
                    </>,
                    align: alignTop
                  }] : []
                ),
                ...(ticket?.client ?
                  [{
                    label: p.t('support.detail.contrat'),
                    value: ticket?.client ? `${ticket.client.nomContrat} - ${ticket.client.numcli}` : ""
                  }] : []
                ),
                ...(ticket?.ticketPrecedent ? [{
                  label: p.t('support.detail.ticketPrecedent'),
                  value: ticket?.ticketPrecedent ?
                    <div className='flex'>
                      <div>
                        {`${ticket.ticketPrecedent.numero} - ${ticket.ticketPrecedent.titre}`}
                      </div>
                      <div className='cursor-pointer'>
                        <ArrowTopRightOnSquareIcon
                          onClick={() => {
                            onClickTicketPrecedent(ticket.ticketPrecedent.id)
                          }}
                          className='h-5 w-5 text-gray-500 group-hover:text-secondary-500 mx-2 '
                        />
                      </div>
                    </div> : ""
                }] : [])
              ]}
              columnWidthClassName="w-[170px]" />

          </div>
        </div>
      </div>
    </Splitter>
    
    <ModaleRatingTicket
      ticketId={ticket.id}
      ticketRatingId={ticket?.ticketRating?.id}
      isOpen={openModaleRating.open}
      cloture={openModaleRating.cloture}
      onClose={() => setOpenModaleRating({
        open: false,
        cloture: false
      })}
      onValider={() => refetchTicket()}
      noteDefaut={searchParams.get("notation")
        ? parseInt(searchParams.get("notation") || "0")
        : ticket?.ticketRating?.rating || -1
      }
      commentaireDefaut={ticket?.ticketRating?.commentaire}
      isModifiable={ticket?.ticketRating?.isModifiableClient}
    />
    <ModaleReouvertureTicket
      ticketId={ticket.id}
      isOpen={openModaleReouverture}
      onClose={() => setOpenModaleReouverture(false)}
      onReouverture={() => refetchTicket()}
    />
    <ModalConfirmation
      isOpen={openModaleActivite}
      closeIcon={false}
      message={`${p.t('support.modaleCreation.confirmActiviteTotaleImpactee')}`}
      onClose={closeModaleActivite}
      okButtonLabel={`${p.t('support.modaleCreation.cancelButtonLabel')}`}
      cancelButtonLabel={`${p.t('support.modaleCreation.okButtonLabel')}`}
    />
  </>;
}
