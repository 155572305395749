import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Button from '../../../../../Components/Button'
import { ModalConfirmation } from '../../../../../Components/ModalDialog/ModalConfirmation'
import Spinner from '../../../../../Components/Spinner'
import { toastError, toastSuccess } from '../../../../../Components/Utils/Utils'
import { useP } from '../../../../../services/i18n'
import { SUBMIT_BON_COMMANDE } from '../../../queries'
import * as Sentry from "@sentry/react";

type ActionBonCommandeType = {
  livraisonId: number,
  action: string,
  label: string,
  message: string,
  prestationId: number
}

export default function ActionBonCommande({ livraisonId, action, label, message, prestationId }: ActionBonCommandeType) {

  const p = useP();

  const [submitBonCommande, { loading }] = useMutation(SUBMIT_BON_COMMANDE);

  const submitAction = () => {
    submitBonCommande({
      variables: {
        bonCommandeInput: {
          action,
          livraisonId: livraisonId,
          values: [],
          prestationId: prestationId,
        }
      }
    }).then(({ data }) => {

      if (data.submitBonCommande.success) {
        toastSuccess(p.t('prestations.gsm.actions.submitBonCommandeSuccessMessage'));
      } else {

        const codeErreur = data?.submitBonCommande?.codeErreur;
        const tradErreur = `prestations.gsm.errors.${codeErreur}`
        const erreurMessage = p.has(tradErreur) ? p.t(tradErreur) : `${p.t('error.defaultMessage')} (Err: ${codeErreur})`

        console.error(data.submitBonCommande.message)
        Sentry.captureException(data.submitBonCommande.message);
        toastError(erreurMessage);
      }
    })
      .catch((error) => {
        Sentry.captureException(error);
        toastError(p.t('error.defaultMessage'));
      });
  }

  // Modale de confirmation
  const [modaleIsOpen, setModaleIsOpen] = useState(false)

  return <>
    <div className=''>
      <Button
        disabled={loading}
        color='primary'
        size='small'
        onClick={() => setModaleIsOpen(true)}>
        {loading ? <Spinner /> : label}
      </Button>
    </div>

    <ModalConfirmation
      message={message}
      isOpen={modaleIsOpen}
      onClose={(action) => {
        if (action) {
          submitAction();
        }
        setModaleIsOpen(false)
      }}
    />
  </>
}