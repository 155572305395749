import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import React, { useState } from 'react'

export type TabsNavigationType = {
  libelle: string,
  jsx: JSX.Element
}

type TabsType = {
  navigation: Array<TabsNavigationType>
  panels: Array<any>,
  cardStyle: boolean
}

function Tabs({
  navigation,
  panels,
  cardStyle
}: TabsType) {

  let tabListStyle = ``;
  let tabNavStyle = ``;
  let tabNavSelectedStyle = ``;
  let tabNavUnselectedStyle = ``;
  let tabPanelStyle = ``;
  let tabNavStyleOnlyOne = ``;
  if (cardStyle) {
    tabListStyle = `space-x-0 relative  mb-3 bg-gray-200 rounded-md p-1 md:flex md:mb-0 md:bg-transparent md:rounded-none md:space-x-1 md:p-0`
    tabNavStyle = `px-2 py-2 text-md font-medium leading-5 focus:outline-none focus:ring-0 rounded-md md:rounded-t-md md:rounded-b-none cursor-pointer`
    tabNavStyleOnlyOne = `px-2 py-2 text-md font-medium leading-5 focus:outline-none focus:ring-0 w-full cursor-auto relative px-0 mb-2 rounded-md`
    tabNavSelectedStyle = `bg-white text-primary-700 `
    tabNavUnselectedStyle = `text-gray-700 hover:bg-white/60`
    tabPanelStyle = ` rounded-tr-md rounded-b-md bg-white p-2 relative after:absolute flex-1 overflow-auto border-t-8 border-white`

  } else {
    tabListStyle = `space-x-6 relative mb-3 bg-gray-200 rounded-md p-1 md:flex md:mb-0 md:bg-transparent md:rounded-none md:p-0`
    tabNavStyle = `px-0 pt-0 pb-2 text-md font-medium text-sm text-gray-500 leading-5 focus:outline-none focus:ring-0 w-full md:w-auto cursor-pointer`
    tabNavStyleOnlyOne = `w-full cursor-auto relative px-0 bg-transparent`
    tabNavSelectedStyle = `bg-white text-secondary-500 relative border-b-2 border-secondary-500 `
    tabNavUnselectedStyle = `text-gray-700 hover:bg-white/60 border-b-2 border-transparent hover:border-gray-200`
    tabPanelStyle = `bg-white py-0 px-0 relative md:border-t md:border-gray-200 flex-1 overflow-auto `
  }

  const [selectedIndex, setSelectedIndex] = useState(0)

  const onChangeTab = (e) => {
    setSelectedIndex(e.target.value)
  }
  
  return (
    <>
      <div className='md:hidden'>
        <select onChange={(e) => onChangeTab(e)} name="" id="" className="block w-full pl-3 pr-10 py-2 mb-2 border border-gray-200 hover:bg-white/90 hover:text-gray-700 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
        >
          {navigation.map((navigationItem, n) => (
            <option key={n} value={n}>{navigationItem?.libelle}</option>
          ))}
        </select>
      </div>

      <div className='flex flex-col flex-1 overflow-hidden'>
        <div className='flex flex-col flex-1 overflow-hidden'>
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className={clsx(tabListStyle, `hidden md:block`)} as="div">
              {navigation.map((navigationItem, n) => (                
                  navigation.length == 1 
                  ? <div key={n} className={clsx(tabNavStyleOnlyOne,tabNavSelectedStyle)}>{navigationItem?.jsx}</div>
                  : <Tab
                    as="div"
                    key={n}
                    className={({ selected }) =>
                      clsx(navigation.length == 1 ? tabNavStyleOnlyOne : tabNavStyle,
                        selected
                          ? tabNavSelectedStyle
                          : tabNavUnselectedStyle
                      )
                    }
                  >
                    {navigationItem?.jsx}
                  </Tab>
                
              ))}
            </Tab.List>
            <Tab.Panels className={clsx(tabPanelStyle, navigation.length == 1 && `rounded-tl-md pt-0 after:hidden`)}>
              {panels.map((tabItemPanel, idx) => (
                <Tab.Panel
                  key={idx}
                  className={clsx(
                    `focus:outline-none focus:ring-0 flex flex-col flex-1 h-full`,
                  )}
                >
                  {tabItemPanel}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  )

}

export default Tabs