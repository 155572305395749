import React, { useRef } from "react"
import { StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment, InteractionMode } from "react-complex-tree"
import TreeContainer from "./Renders/TreeContainer";
import ItemsContainer from "./Renders/ItemsContainer";
import ItemArrow from "./Renders/ItemArrow";
import Item from "./Renders/Item";

type iconsItemType = {
  folderOpen: JSX.Element,
  folder: JSX.Element,
  noChildren: JSX.Element,
}
type iconsArrowType = {
  expanded: JSX.Element,
  notExpanded: JSX.Element,
  noChildren: JSX.Element,
}

type TreeviewType = {
  datas: any,
  treeId: string,
  selectedItem?: string | null,
  onPrimaryAction?: (item: any) => void,
  onSelectItems?: (item: any) => void,
  iconsItem?: iconsItemType,
  iconsArrow?: iconsArrowType,
  openItems?: string[] | 'all',
}

export default function Treeview({
  datas,
  treeId,
  selectedItem,
  onPrimaryAction,
  onSelectItems,
  iconsItem,
  iconsArrow,
  openItems
}: TreeviewType) {
  const treeEnvironment = useRef(null);
  const tree = useRef(null);

  const viewState = {}
  if (selectedItem) {
    viewState[treeId] = {
      ...(viewState[treeId] || {}),
      selectedItems: [selectedItem],
    }
  }

  let expandedItems = openItems;
  if (openItems === 'all') {
    expandedItems = Object.values(datas)
      .filter((objet) => Object.prototype.hasOwnProperty.call(objet, "index"))
      .map((objet: any) => objet.index);
  }

  if (expandedItems) {
    viewState[treeId] = {
      ...(viewState[treeId] || {}),
      expandedItems,
    }
  }

  return <>
    <UncontrolledTreeEnvironment
      ref={treeEnvironment}
      dataProvider={
        new StaticTreeDataProvider(datas, (item, data) => ({
          ...item,
          data
        }))
      }
      disableMultiselect={true}
      defaultInteractionMode={"click-arrow-to-expand" as InteractionMode}
      getItemTitle={(item) => item.data}
      canDragAndDrop={false}
      canDropOnFolder={false}
      canReorderItems={false}
      canSearch={false}
      onPrimaryAction={(item) => {
        onPrimaryAction?.(item)
      }}
      onSelectItems={(item) => {
        onSelectItems?.(item)
      }}
      renderTreeContainer={({ children, containerProps }) => <TreeContainer containerProps={containerProps} >{children}</TreeContainer>}
      renderItemsContainer={({ depth, containerProps, children }) => <ItemsContainer depth={depth} containerProps={containerProps}>{children}</ItemsContainer>}
      renderItem={({ item,
        children,
        title,
        arrow,
        depth,
        context, }) => (
        <Item
          item={item}
          title={title}
          arrow={arrow}
          context={context}
          depth={depth}
          iconsItem={iconsItem}
        >{children}</Item>
      )}
      renderItemArrow={({ context }) => (
        <ItemArrow context={context} iconsArrow={iconsArrow} />
      )}
      viewState={viewState}
    >
      <Tree treeId={treeId} rootItem="root" ref={tree} />
    </UncontrolledTreeEnvironment>
  </>
}