import React from 'react'
import clsx from 'clsx';

type CellBadgeFormatterType = {
  colorClasses: string,
  label: string
}

export default function CellBadgeFormatter({ colorClasses, label }: CellBadgeFormatterType) {

  return <>
    <span className={clsx(`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium truncate`, colorClasses)}>
      {label}
    </span>
  </>
}