import React from 'react'
import dayjs from 'dayjs';

type CellDateFormatterType = {
  value: string,  
}

export default function CellDateFormatter({ value }: CellDateFormatterType) {

  return <>
      {value ? dayjs(value).format("DD/MM/YYYY") : ""}    
  </>
}