import React from "react"
import Card from "../../../Components/Card"
import SwitchIcon, { SwitchIconDataType } from "../../../Components/SwitchIcon/SwitchIcon"
import DocumentationViewTable from "./DocumentationViewTable"
import DocumentationViewThumbnails from "./DocumentationViewThumbnails"
import { ThumbnailType } from "./DocumentationThumbnail"
import Spinner from "../../../Components/Spinner"
import ModalPdfViewer from "../../../Components/PdfViewer/ModalPdfViewer"
import { useP } from "../../../services/i18n"
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline"

type DocumentationFichiersContainerType = {
  datas: Array<ThumbnailType>,
  loading: boolean,
  onClickThumbnail: (thumbnail: ThumbnailType) => void,
  onCopyLink: (thumbnail: ThumbnailType) => void,
  onClosePdfViewer: () => void
  scrollBarIsAtBottom: (isAtBotton: boolean) => void
  modalPdfViewerTitle: string,
  modalPdfFile: string,
  modalDocumentationPdViewerIsOpen: boolean,
  footerComponent: JSX.Element,
  switchIconsDatas: Array<SwitchIconDataType>,
  onSelectView: (view: SwitchIconDataType) => void
  selectedView: SwitchIconDataType
}

function DocumentationFichiersContainer({
  datas,
  loading,
  onClickThumbnail,
  onCopyLink,
  onClosePdfViewer,
  scrollBarIsAtBottom,
  modalPdfViewerTitle,
  modalPdfFile,
  modalDocumentationPdViewerIsOpen,
  footerComponent,
  selectedView,
  onSelectView,
  switchIconsDatas
}: DocumentationFichiersContainerType) {

  const p = useP();

  return <>
    <Card className={{ root: "flex-1 h-full flex flex-col lg:overflow-hidden" }}>
      {loading ? (
        <div className="h-full flex items-center justify-center w-full">
          <div className="w-screen h-screen flex items-center justify-center"><Spinner /></div>
        </div>
      ) :
        datas.length === 0 ?

          <div className="h-full flex flex-col items-center justify-center w-full space-y-2">
            <div className="">
              <ArchiveBoxXMarkIcon className="h-12 w-12 text-primary-500" />
            </div>
            <div className="">
              {p.t('documentation.noDocument')}
            </div>
          </div>
          :
          <div className="flex flex-col">
            <div className="hidden lg:flex lg:justify-end lg:mr-2">
              <SwitchIcon
                defaultItem={selectedView}
                datas={switchIconsDatas}
                onSelect={onSelectView} />
            </div>

            <div>
              {
                selectedView.label === "Vignettes" ?
                  <DocumentationViewThumbnails datas={datas} onClickThumbnail={onClickThumbnail} onCopyLink={onCopyLink}/>
                  :
                  <DocumentationViewTable datas={datas} onClickRow={onClickThumbnail} onCopyLink={onCopyLink}/>
              }
            </div>
          </div>
      }
    </Card>
    <ModalPdfViewer
      file={modalPdfFile}
      fileDownload={`${modalPdfFile}&download=1&name=${modalPdfViewerTitle}.pdf`}
      title={modalPdfViewerTitle}
      onClose={onClosePdfViewer}
      isOpen={modalDocumentationPdViewerIsOpen}
      scrollBarIsAtBottom={scrollBarIsAtBottom}
      footerComponent={footerComponent}
    />
  </>
}

export default DocumentationFichiersContainer;