import React from "react"
import ModalContainer from "./ModalContainer"
import { ModalContentType } from "./ModalContentType"

type ModalType = {
  className?: string,
  onClose: () => void,
  isOpen: boolean,
  content: ModalContentType,
  noPadding?: boolean,
  closeIcon?: boolean,
  disableEsc?: boolean
  fullscreen?: boolean
  heightClass?: string
}

export default function Modal({ className, onClose, isOpen, content, noPadding, closeIcon, disableEsc, heightClass, fullscreen }: ModalType) {

  return (
    <>
      <ModalContainer
        className={className}
        onClose={onClose}
        isOpen={isOpen}
        content={content}
        noPadding={noPadding}
        closeIcon={closeIcon}
        disableEsc={disableEsc}
        heightClass={heightClass}
        fullscreen={fullscreen}
      />
    </>
  )
}