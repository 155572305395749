import React from "react"
import { generateColorPalette } from "../CyberViewDetail/utils";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DoughnutChart from "../../../Components/Charts/DoughnutChart";
import dayjs from "dayjs";

type CyberChartDoughnutType = {
  chartData: any,
  chartDateTime?: Date
}

export default function CyberChartDoughnut({ chartData, chartDateTime }: CyberChartDoughnutType) {

  const optionsBarCharts = {
    responsive: true,
    plugins: {
      ...(chartDateTime && {
        subtitle: {
          display: true,
          text: dayjs(chartDateTime).format("DD/MM/YYYY HH:mm:ss"),
          position: 'right',
        }
      }),
      colors: {
        enabled: true
      },
      datalabels: {
        borderRadius: 4,
        padding: 4,
        font: {
          size: 12,
          weight: "medium"
        },
        anchor: "center",
        backgroundColor: "rgba(255,255,255,0.7)"
      },
      legend: {
        labels: {
          boxHeight: 8,
          usePointStyle: true,
        },
        position: "bottom",
        align: "center",
      },
    },
  };

  const numColors = chartData.datasets[0].data.length;
  const baseColors = ['#3b82f6', '#ec4899', '#14b8a6', '#f59e0b', '#a855f7', '#facc15'];

  return <DoughnutChart
    datasetIdKey="doughnutChart"
    data={chartData}
    options={optionsBarCharts}
    plugins={[ChartDataLabels]}
    colorPalette={generateColorPalette(numColors, baseColors)}
  />

}