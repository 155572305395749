import React, { useContext, useEffect, useState } from 'react'
import { Control, Controller, UseFormRegister, UseFormReset } from 'react-hook-form'
import FormSectionTitle from '../../../Components/Form/FormSectionTitle'
import { Input } from '../../../Components/Form/Input'
import InputChoices from '../../../Components/Form/InputChoices/InputChoices'
import InputCombobox, { OptionType } from '../../../Components/Form/InputCombobox/InputCombobox'
import InputSwitch from '../../../Components/Form/InputSwitch'
import { useP } from '../../../services/i18n'
import { GET_CONTACT, GET_COMPTES } from './../queries';
import { useLazyQuery, useQuery } from '@apollo/client'
import Spinner from '../../../Components/Spinner';
import { DataDonneeReferenceModaleType } from '../../../types/ContactType';
import Badge from '../../../Components/Badge'
import Modal from '../../../Components/Modal/Modal'
import Button from '../../../Components/Button'
import ModalePrestationsContactBody from './ModalePrestationsContactBody'
import Constants from '../../../commons/Constants';
import { GET_LIEN, GET_CENTILE, GET_GSM, GET_NDI, GET_TRUNK, GET_VGA } from '../../Prestations/queries';
import { useCheckRight } from '../../../Components/CheckRight/CheckRight';
import { authContext } from '../../../AuthContext'

const {
  TYPE_CONTACT,
  ROLE_ACCES_PRESTATION,
  ROLE_ADMIN_PRESTATION,
} = Constants;

type ModalContactsBodyType = {
  register: UseFormRegister<any>,
  control: Control<any>,
  errors: any,
  reset?: UseFormReset<any> | null | undefined,
  idContact?: number | null,
  donneesReference: DataDonneeReferenceModaleType,
  typeContact: string
}

const inputComboboxClasses = {
  options: 'max-h-32'
}


function ModalContactsBody({ register, control, errors, reset, idContact, donneesReference, typeContact }: ModalContactsBodyType) {
  const p = useP();

  const {
    selectedCompte,
  } = useContext(authContext)

  const [contact, setContact] = useState({});
  const [openModalePrestationsContact, setOpenModalePrestationsContact] = useState(false);
  const [dataLienFiltre, setDataLienFiltre] = useState([]);
  const [dataCentileFiltre, setDataCentileFiltre] = useState([]);
  const [dataGsmFiltre, setDataGsmFiltre] = useState([]);
  const [dataNdiFiltre, setDataNdiFiltre] = useState([]);
  const [dataTrunkFiltre, setDataTrunkFiltre] = useState([]);
  const [dataVgaFiltre, setDataVgaFiltre] = useState([]);
  const [tabComptes, setTabComptes] = useState<Array<OptionType>>([]);
  const [isContactSupervision, setIsContactSupervision] = useState<boolean>();
  const [isFacture, setIsFacture] = useState<boolean>();

  const [getContact, { loading, data }] = useLazyQuery(GET_CONTACT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setContact(data.contact.items[0]);
    }
  });

  const { loading: loadingComptes, data: dataComptes } = useQuery(GET_COMPTES, {
    variables: {
      filters: [{
        column: "id",
        operator: "IN",
        value: selectedCompte
      }],
      sorters: [{
        column: "raisonSociale",
      }]
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const { checkRight } = useCheckRight();

  // Liste des prestations si mode modification et droits prestas
  const displayPrestations = idContact && (checkRight(ROLE_ACCES_PRESTATION) || checkRight(ROLE_ADMIN_PRESTATION));

  const [getPresationsLien, { loading: loadingLiens, data: dataLien }] = useLazyQuery(GET_LIEN, {
    fetchPolicy: "network-only",
  });
  const [getPresationsCentile, { loading: loadingCentile, data: dataCentile }] = useLazyQuery(GET_CENTILE, {
    fetchPolicy: "network-only",
  });
  const [getPresationsGsm, { loading: loadingGsm, data: dataGsm }] = useLazyQuery(GET_GSM, {
    fetchPolicy: "network-only",
  });
  const [getPresationsNdi, { loading: loadingNdi, data: dataNdi }] = useLazyQuery(GET_NDI, {
    fetchPolicy: "network-only",
  });
  const [getPresationsTrunk, { loading: loadingTrunk, data: dataTrunk }] = useLazyQuery(GET_TRUNK, {
    fetchPolicy: "network-only",
  });
  const [getPresationsVga, { loading: loadingVga, data: dataVga }] = useLazyQuery(GET_VGA, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (idContact) {
      getContact({
        variables: {
          idContact: idContact,
        },
      });
      if (displayPrestations) {
        getPresationsLien({
          variables: {
            idContact: idContact,
          },
        });
        getPresationsCentile({
          variables: {
            idContact: idContact,
          },
        });
        getPresationsGsm({
          variables: {
            idContact: idContact,
          },
        });
        getPresationsNdi({
          variables: {
            idContact: idContact,
          },
        });
        getPresationsTrunk({
          variables: {
            idContact: idContact,
          },
        });
        getPresationsVga({
          variables: {
            idContact: idContact,
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    setIsContactSupervision(data?.contact.items[0].isContactSupervision)
    setIsFacture(data?.contact.items[0].isFacture)
  }, [data])
  

  useEffect(() => {
    // reset form with user data
    reset && reset(contact);
  }, [contact]);

  useEffect(() => {
    if (tabComptes.length === 1) {
      reset && reset({
        ...contact,
        compte: tabComptes[0]
      });
    }
  }, [tabComptes]);

  const filtres = ["EN SERVICE", "INITIEE", "COMMANDE EN COURS"];

  useEffect(() => {
    if (dataLien?.prestationLiens?.length)
      setDataLienFiltre(dataLien?.prestationLiens.filter((d) => filtres.includes(d.etat)))
  }, [dataLien]);

  useEffect(() => {
    if (dataCentile?.prestationCentiles?.length)
      setDataCentileFiltre(dataCentile?.prestationCentiles.filter((d) => filtres.includes(d.etat)))
  }, [dataCentile]);

  useEffect(() => {
    if (dataGsm?.prestationGsms?.length)
      setDataGsmFiltre(dataGsm?.prestationGsms.filter((d) => filtres.includes(d.etat)))
  }, [dataGsm]);

  useEffect(() => {
    if (dataNdi?.prestationNdis?.length)
      setDataNdiFiltre(dataNdi?.prestationNdis.filter((d) => filtres.includes(d.etat)))
  }, [dataNdi]);

  useEffect(() => {
    if (dataTrunk?.prestationTrunks?.length)
      setDataTrunkFiltre(dataTrunk?.prestationTrunks.filter((d) => filtres.includes(d.etat)))
  }, [dataTrunk]);

  useEffect(() => {
    if (dataVga?.prestationVgas?.length)
      setDataVgaFiltre(dataVga?.prestationVgas.filter((d) => filtres.includes(d.etat)))
  }, [dataVga]);

  useEffect(() => {
    if (dataComptes?.comptes?.items?.length > 0)
      setTabComptes(dataComptes.comptes.items.map((c) => { return { code: c.id, libelle: c.raisonSociale } }));
  }, [dataComptes]);

  return (
    <>
      {loading || loadingLiens || loadingCentile || loadingGsm || loadingNdi || loadingTrunk || loadingVga || loadingComptes ?
        <><div className='h-[430px] w-full flex items-center justify-center'><Spinner color='primary' /></div></>
        :
        < div className='flex items-start flex-col lg:flex-row'>
          <div className="lg:pr-4 flex-1">
            <FormSectionTitle title={p.t('contacts.contact.infosPersonnelles')} />
            <div className="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                {idContact ?
                  <div>
                    <div className='truncate block text-sm font-medium text-gray-700 mb-1'>{p.t('contacts.contact.compte')}</div>
                    <div className='shadow-sm block w-full sm:text-sm border-gray-100 rounded-md border py-2 px-3 bg-gray-100'>{data?.contact.items[0].compte.raisonSociale}</div>
                  </div>
                  :
                  tabComptes.length === 1
                    ? <>
                      <div className='truncate block text-sm font-medium text-gray-700 mb-1'>{p.t('contacts.contact.compte')}</div>
                      <div className='shadow-sm block w-full sm:text-sm border-gray-100 rounded-md border py-2 px-3 bg-gray-100'>{tabComptes[0].libelle}</div>
                    </>
                    : <Controller
                      control={control}
                      name="compte"
                      defaultValue={tabComptes.length === 1 ? tabComptes[0] : undefined}
                      render={({ field }) =>
                        <InputCombobox
                          errors={errors}
                          options={tabComptes}
                          classes={inputComboboxClasses}
                          label={p.t('contacts.contact.compte')}
                          autoComplete='off'
                          {...field}
                          value={tabComptes.length === 1 ? tabComptes[0] : undefined}
                        />}
                    />
                }
              </div>
              <div className="sm:col-span-6">
                <Controller
                  control={control}
                  name="civilite"
                  defaultValue={data?.contact.items[0].civilite ? data?.contact.items[0].civilite : null}
                  render={({ field }) => <InputCombobox errors={errors} options={donneesReference?.civilites} classes={inputComboboxClasses} label={p.t('contacts.contact.civilite')} autoComplete='off' {...field} />}
                />
              </div>
              <div className="sm:col-span-3">
                <Input defaultValue={data?.contact.items[0].prenom} register={register} errors={errors} name="prenom" label={p.t('contacts.contact.prenom')} type='text' autoComplete='off' />
              </div>
              <div className="sm:col-span-3">
                <Input defaultValue={data?.contact.items[0].nom} register={register} errors={errors} name="nom" label={p.t('contacts.contact.nom')} type='text' autoComplete='off' />
              </div>
              <div className="sm:col-span-6">
                <Controller
                  control={control}
                  name="poste"
                  defaultValue={data?.contact.items[0].poste ? data?.contact.items[0].poste : null}
                  render={({ field }) => <InputCombobox errors={errors} options={donneesReference?.postes} classes={inputComboboxClasses} label={p.t('contacts.contact.fonction')} autoComplete='off' {...field} />}
                />
              </div>
              <div className="sm:col-span-3">
                <Input defaultValue={data?.contact.items[0].telephoneFixe ? data?.contact.items[0].telephoneFixe : null} register={register} errors={errors} name="telephoneFixe" label={p.t('contacts.contact.telFixe')} type='text' autoComplete='off' />
              </div>
              <div className="sm:col-span-3">
                <Input defaultValue={data?.contact.items[0].telephoneMobile ? data?.contact.items[0].telephoneMobile : null} register={register} errors={errors} name="telephoneMobile" label={p.t('contacts.contact.telMobile')} type='text' autoComplete='off' />
              </div>
              <div className="sm:col-span-6">
                <Input defaultValue={data?.contact.items[0].mail} register={register} errors={errors} name="mail" label={p.t('contacts.contact.email')} type='text' autoComplete='off' />
              </div>
            </div>
            {
              displayPrestations &&
              <div>
                <hr className='my-4' />
                <div
                  onClick={() => setOpenModalePrestationsContact(true)}
                  className='flex items-center justify-center space-x-2 sm:col-span-12 cursor-pointer bg-secondary-100 p-2 rounded-md  hover:bg-secondary-50'>
                  <Badge itemLibelle={(dataLienFiltre?.length +
                    dataCentileFiltre?.length +
                    dataGsmFiltre.length +
                    dataNdiFiltre?.length +
                    dataTrunkFiltre?.length +
                    dataVgaFiltre?.length).toString()} itemColor='primary' />
                  <div className='text-secondary-800 font-medium text-sm'>{p.t('contacts.contact.nbrePrestas')}</div>
                </div>
              </div>
            }
          </div>
          {typeContact === TYPE_CONTACT &&
            <div className='pt-4 lg:pt-0 lg:pl-4 flex-1'>
              <FormSectionTitle title={p.t('contacts.contact.parametrages')} />
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <Controller
                    control={control}
                    name="isActif"
                    defaultValue={data?.contact.items[0].isActif}
                    render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.actif')} {...field} />}
                  />
                  <Controller
                    control={control}
                    name="isMultiCompte"
                    defaultValue={data?.contact.items[0].isMultiCompte}
                    render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.multicompte')} {...field} />}
                  />
                  {!idContact && <Controller
                    control={control}
                    name="isAccesEspaceClient"
                    defaultValue={false}
                    render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.accesEspaceClient')} {...field} />}
                  />}
                  <Controller
                    control={control}
                    name="isContactProjets"
                    defaultValue={data?.contact.items[0].isContactProjets}
                    render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.projet')} {...field} />}
                  />
                  <div className='flex items-center space-x-4'>
                    <Controller
                      control={control}
                      name="isContactSupervision"
                      defaultValue={data?.contact.items[0].isContactSupervision}
                      render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.supervision')} {...field} onChange={(val)=>{
                        field.onChange(val)
                        setIsContactSupervision(val)
                      }} />}
                    />
                    {
                    isContactSupervision &&
                      <Controller
                      control={control}
                      name="isContactSupervisionHierarchie"
                      defaultValue={data?.contact.items[0].isContactSupervisionHierarchie}
                      render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.multicompte')} {...field} />}
                      />
                    }
                  </div>
                  <div className='flex items-center space-x-4'>
                    <Controller
                      control={control}
                      name="isFacture"
                      defaultValue={data?.contact.items[0].isFacture}
                      render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.facture')} {...field} onChange={(val)=>{
                        field.onChange(val)
                        setIsFacture(val)
                      }} />}
                    />
                    {
                    isFacture &&
                      <Controller
                      control={control}
                      name="isFactureHierarchie"
                      defaultValue={data?.contact.items[0].isFactureHierarchie}
                      render={({ field }) => <InputSwitch className='mb-2' label={p.t('contacts.contact.multicompte')} {...field} />}
                      />
                    }
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <FormSectionTitle title={p.t('contacts.contact.communications')} />
                  <InputChoices register={register} type='checkbox' legend={p.t('contacts.contact.mailing')} options={[
                    {
                      "name": "isMailingActif",
                      label: p.t('contacts.contact.mailingActif'),
                      id: "mailingActif",
                      defaultValue: data?.contact.items[0].isMailingActif
                    },
                    {
                      "name": "isMailMaintenance",
                      label: p.t('contacts.contact.maintenance'),
                      id: "maintenance",
                      defaultValue: data?.contact.items[0].isActif
                    },
                    {
                      "name": "isAvisIncident",
                      label: p.t('contacts.contact.avisIncidents'),
                      id: "incidents",
                      defaultValue: data?.contact.items[0].isAvisIncident
                    },
                    {
                      "name": "isRegroupementFactureMail",
                      label: p.t('contacts.contact.regroupementFacture'),
                      id: "regroupementFacture",
                      defaultValue: data?.contact.items[0].isRegroupementFactureMail,
                      hidden: !isFacture,
                    }
                  ]} />

                  <InputChoices register={register} className='' type='checkbox' legend={p.t('contacts.contact.news')} options={[
                    {
                      "name": "isNewsCommerciale",
                      label: p.t('contacts.contact.newsCommerciales'),
                      id: "news_commerciales",
                      defaultValue: data?.contact.items[0].isNewsCommerciale
                    },
                    {
                      "name": "isNewsTechnique",
                      label: p.t('contacts.contact.newsTechniques'),
                      id: "news_techniques",
                      defaultValue: data?.contact.items[0].isNewsTechnique
                    },
                    {
                      "name": "isNewsletterMensuelle",
                      label: p.t('contacts.contact.newsMensuelles'),
                      id: "newsletter",
                      defaultValue: data?.contact.items[0].isNewsletterMensuelle
                    }
                  ]} />
                </div>
              </div>
            </div>
          }
          {
            <Modal
              onClose={() => setOpenModalePrestationsContact(false)}
              isOpen={openModalePrestationsContact}
              content={{
                title: p.t('contacts.contact.titreModalePrestationsAssociees'),
                body:
                  <>
                    <ModalePrestationsContactBody
                      prestationsLien={dataLienFiltre}
                      prestationsCentile={dataCentileFiltre}
                      prestationsGsm={dataGsmFiltre}
                      prestationsNdi={dataNdiFiltre}
                      prestationsTrunk={dataTrunkFiltre}
                      prestationsVga={dataVgaFiltre}
                    />
                  </>,
                footer: <div className='flex justify-end'><Button onClick={() => setOpenModalePrestationsContact(false)}>{p.t('actions.fermer')}</Button></div>,
              }}
              className="w-full lg:w-1/3"
            />
          }
        </div >

      }
    </>)
}

export default ModalContactsBody