import React from 'react'

import { UserType } from '../../../../types/UserType'
import ProfilePicture from './ProfilePicture'
import ProfileInformation from './ProfileInformation'

type MobileUserProps = {
  user: UserType
}

function MobileUser({ user }: MobileUserProps) {
  return (
    <div className="flex items-center px-5">
      <ProfilePicture user={user} />
      <ProfileInformation user={user} />
      {/*<ProfileNotification /> a réactiver*/}
    </div>
  )
}

export default MobileUser