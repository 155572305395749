import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentSupport_Q1 from "./HelpContent/HelpContentSupport_Q1";
import HelpContentSupport_Q2 from "./HelpContent/HelpContentSupport_Q2";

export default function HelpSupport() {
  const p = useP();
  const datas = [{
    libelle: p.t('helpBox.helpSupport.q1'),
    content: <HelpContentSupport_Q1 />
  }, {
    libelle: p.t('helpBox.helpSupport.q2'),
    content: <HelpContentSupport_Q2 />
  }]
  return <>
    <HelpBoxAcordions datas={datas} />
  </>
}