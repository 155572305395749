import { gql } from "@apollo/client"

const GET_TRAVAUX_PROGRAMMES = gql`
  query TravauxProgrammes ($aVenir: Boolean) {
    travauxProgrammesAVenir(aVenir: $aVenir) {          
      id
      dateDebut
      duree
      dateFin
      nature
      referenceFournisseur
      cause
      impact
      
      statutTravailProgramme {
          code
          libelle
          couleur
      }
      typeTravailProgramme {
          code
          libelle
      }
      liens {
        id
        referenceFournisseur
        prestation {
          id
          libelle
          site {
            commune
            codePostal
            complementVoie
            numeroVoie
            nom
            libelleVoie
          }
        }
      }
    }
  }
`

export {
  GET_TRAVAUX_PROGRAMMES
}