import React from 'react'

export default function PopInformationModalBodyRechargeAuto() {
  return <>
    <div className="text-md px-8 xl:px-24">
      <div className='flex items-start mt-8 space-x-8'>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Mise à disposition des documentations téléphonie</h2>
        </div>
      </div>
      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-4/12 text-gray-500'>
          <div className="mt-4 text-md leading-5 text-gray-500">Un nouveau <strong>menu &quot;Documentation&quot;</strong> est mis à votre disposition.<br/>Il sera alimenté au fur et à mesure avec tout type de documents utiles.<br/>
            Pour commencer, Blue vous propose les guides qui concernent les <strong>outils de téléphonie</strong> :
            <ul className="list-disc list-inside"><li>guides de démarrage</li><li>guides administrateur</li><li>guides utilisateur</li></ul>
          </div>
        </div>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/documentation_voix.png" alt="" className='w-[600px] rounded-md shadow-xl border-gray-200 border' />
        </div>
      </div>
    </div>
  </>
}