import React from 'react'
import MultiSelectOption from './MultiSelectOption'
import { MultiSelectOptionType } from './Types/MultiSelectOptionType'

type MultiSelectOptionsListType = {
  datas: Array<MultiSelectOptionType>,
  toggleOption: (val: any) => void,
  selected: Array<MultiSelectOptionType>,
}

export default function MultiSelectOptionsList({ datas, toggleOption, selected }: MultiSelectOptionsListType) {

  // /!\ en commentaire : possibilité de regrouper les datas en fonction d'un group ( optiongroup )
  // const groups = datas.reduce((r, a) => {
  //     r[a.group] = [...r[a.group] || [], a];
  //     return r;
  // }, {});
  return <>
    {
      // Object.keys(groups).map((item, i) => (
      //   <li key={i} className="text-gray-900 cursor-default select-none relative py-2 px-2" id="listbox-option-0" role="option">
      //     <fieldset>
      //       {
      //         (Object.keys(groups).length > 1) &&
      //         <legend className=" sticky top-0 z-10 w-full py-2 border-b border-gray-200 text-base font-medium text-gray-900 bg-white">{item}</legend>
      //       }
      //       {
      //         groups[item].map((option, ind) =>
      //           (<MultiSelectOption option={option} key={ind} />)
      //         )
      //       }
      //     </fieldset>
      //   </li>
      // ))
      <>
        <div className="overflow-auto flex-1 text-gray-900 cursor-default select-none relative py-2 px-2" id="listbox-option-0" role="option">
          {datas.map((option, index) =>
            (<MultiSelectOption key={index} option={option} id={index} toggleOption={toggleOption} selected={selected} />)
          )}
        </div>
      </>
    }
  </>
}