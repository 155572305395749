import React from 'react'
import { GlobeAltIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { RightContainerType } from './RightContainer'

type SitePrestationItemType = {
  itemIcon: any
  value: number
}

function SitePrestationItem({ itemIcon: Component, value = 0 }: SitePrestationItemType) {
  return (
    <div className="flex flex-row text-sm font-medium text-gray-900 mb-1">
      <div><Component className='w-5 text-secondary-600' /></div>
      <div className='ml-1'>{value}</div>
    </div>
  )
}

interface SitePrestationsType extends RightContainerType {
  data: {
    telephonieCount: number
    dataCount: number
    hebergementCount: number
  }
}

export default function SitePrestations({ data }: SitePrestationsType) {
  return (
    <div className="ml-2 flex-shrink-0 relative inline-block text-left">
      <div className="flex flex-col">
        <SitePrestationItem itemIcon={GlobeAltIcon} value={data?.dataCount} />
        <SitePrestationItem itemIcon={PhoneIcon} value={data?.telephonieCount} />
      </div>
    </div>
  )
}
