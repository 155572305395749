import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import React, { ReactNode } from 'react'

type TrDetailType = {
  colspan?: number | undefined,
  children?: ReactNode,
  open: boolean
}

function TrDetail({ colspan = undefined, children, open }: TrDetailType) {

  return (
    <>
      <tr className={clsx(`bg-gray-100 shadow-inner`, !open && `hidden`)}>
        <td colSpan={colspan}>
          <Transition
            show={open}
            enter="transition duration-300 ease-out"
            enterFrom="transform -translate-y-2 opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform -translate-y-2 opacity-0"
          >
            {children}
          </Transition>
        </td >
      </tr>
    </>
  )

}

export default TrDetail