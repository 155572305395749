import { createContext } from "react";
import { LoggedInUserType } from "./types/LoggedInUserType";
import { NotificationType } from "./types/NotificationType";

type AuthContext = {
  loggedInUser: LoggedInUserType,
  setLoggedInUser: (loggedInUser: LoggedInUserType) => void,

  selectedCompte: any,
  setSelectedCompte: (any) => void,

  roles: Array<string> | null,
  setRoles: (roles: Array<string> | null) => void,

  notifications: Array<NotificationType>,
  setNotifications: (notifications: Array<NotificationType>) => void

  featuresFlag: Array<string> | null,
  setFeaturesFlag: (featuresFlag: Array<string> | null) => void,

  sitesRestreints: Array<string> | null,
  setSitesRestreints: (sitesRestreints: Array<string> | null) => void,

}

export const AUTH_DEFAULT_VALUE = {
  loggedInUser: null,
  setLoggedInUser: (loggedInUser: LoggedInUserType) => { return; },

  selectedCompte: null,
  setSelectedCompte: (value: any) => { return },

  roles: null,
  setRoles: (roles: Array<string> | null) => { return },

  notifications: [],
  setNotifications: (notifications: Array<NotificationType>) => { return },

  featuresFlag: null,
  setFeaturesFlag: (featuresFlag: Array<string> | null) => { return },

  sitesRestreints: null,
  setSitesRestreints: (sitesRestreints: Array<string> | null) => { return },

};

export const authContext = createContext<AuthContext>(AUTH_DEFAULT_VALUE);
