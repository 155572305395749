import React from 'react'
import { Disclosure } from '@headlessui/react'
import clsx from 'clsx';
import { useP } from '../../../../services/i18n';
import { NavigationType } from '../../../../types/NavigationType'
import { useNavigate } from 'react-router-dom';

type MobileNavigationItemProps = {
  item: NavigationType
}

function MobileNavigationItem({ item }: MobileNavigationItemProps) {

  const p = useP();
  const navigate = useNavigate();

  return (
    <>
      <Disclosure.Button
        as='div'
        key={p.t(item.name)}
        className={clsx(
          item.current ? 'bg-primary-900 text-white' : 'text-white hover:bg-primary-700 hover:text-white',
          'block px-3 py-2 rounded-md text-base font-medium cursor-pointer'
        )}
        onClick={() => navigate(item.href)}
      >

        {p.t(item.name)}

      </Disclosure.Button>
    </>
  )

}

export default MobileNavigationItem