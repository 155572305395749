import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'

import { CompteType } from '../../../../types/CompteType'
import CompteItem from './CompteItem'
import InputWithClearButton from '../../../../Components/Form/InputWithClearButton'
import InputSwitch from '../../../../Components/Form/InputSwitch'
import { authContext } from '../../../../AuthContext'
import Button from '../../../../Components/Button'
import { useP } from '../../../../services/i18n'

type CompteDropdownProps = {
  open: boolean,
  comptes: Array<CompteType>,
  selectedComptes: Array<string>,
  fieldValue: string,
  fieldLabel: string,
  checkboxRef: any,
  checked: boolean,
  toggleAll: () => void
  searchValue: string,
  setSearchValue: (value: string) => void
  modeMulticompte: boolean,
  setModeMulticompte: (value: boolean) => void
  onClose: () => void
  onReset: () => void
}

function CompteDropdown({ open, comptes, selectedComptes, fieldValue, fieldLabel, checkboxRef, checked, toggleAll, searchValue, setSearchValue, modeMulticompte, setModeMulticompte, onClose, onReset }: CompteDropdownProps) {

  const p = useP();

  const { loggedInUser } = useContext(authContext)

  useEffect(() => {
    setSearchValue('')

    if (!open) {
      onReset()
    }

    setListBoxIsOpen(open)
  }, [open])

  const [listBoxIsOpen, setListBoxIsOpen] = useState(open)

  const onClickConfirm = () => {
    setListBoxIsOpen(false)
    onClose()
  }
  return (

    <Transition
      show={listBoxIsOpen}
      as={Fragment}
      leave="transition ease-in duration-0"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Listbox.Options className="fixed w-screen left-0 top-12 lg:w-auto lg:absolute lg:top-10 z-40 bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm divide-y overflow-hidden">
        <div className="relative flex flex-col divide-y lg:w-72 max-h-96">
          {
            loggedInUser?.user.isMultiCompte && (
              <div
                className={'flex items-center px-8 py-3 space-x-2 cursor-pointer justify-center bg-gray-100'}
              >
                <InputSwitch name='toggle' label={p.t('header.selectionMulticompte')} value={modeMulticompte} onChange={() => setModeMulticompte(!modeMulticompte)} />
              </div>
            )
          }

          <div className='relative w-full font-normal'>
            <InputWithClearButton value={searchValue} setValue={setSearchValue} />
          </div>

          <div className='flex flex-1 flex-col h-full divide-y overflow-y-auto'>
            {comptes.length ? comptes.map((compte, index) => (
              <CompteItem key={index} index={index} compte={compte} fieldLabel={fieldLabel} fieldValue={fieldValue} modeMulticompte={modeMulticompte} />
            )) : <div className='p-2'>Aucun résultats</div>}
          </div>

          <div className='flex relative w-full font-normal items-center pr-2'>
            {
              modeMulticompte && (
                <>
                  <div className="flex-1">
                    <div
                      className={'flex w-fit items-center px-2 py-3 space-x-2 cursor-pointer'}
                      onClick={toggleAll}
                    >
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-slate-500 cursor-pointer"
                        ref={checkboxRef}
                        checked={checked}
                        autoFocus={true}
                      />

                      <div className=' text-slate-500'>
                        {
                          !checked && !checkboxRef?.current?.indeterminate ?
                            p.t('selectedComptes.selectAll') :
                            p.t('selectedComptes.resetSelection')
                        }
                      </div>

                    </div>

                  </div>

                  <div className="">
                    <Button disabled={selectedComptes.length === 0} size='small' color='primary' onClick={onClickConfirm}>{p.t('selectedComptes.confirm')}</Button>
                  </div>
                </>
              )}
          </div>
        </div>

      </Listbox.Options>
    </Transition>

  )
}

export default CompteDropdown
