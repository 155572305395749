import { gql } from "@apollo/client"

const GET_SELECTED_COMPTE = gql`
query SelectedCompte {
  selectedCompte {          
    id
    raisonSociale
    rocUser {
      prenomNomContact
      nom
      prenom      
      mail
      telephoneFixe  
      telephoneMobile
    }
    owner {
      prenomNomContact
      nom
      prenom    
      mail
      telephoneFixe   
      telephoneMobile 
    }
  }
}
`

export {
    GET_SELECTED_COMPTE
}