import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_GRAPH_DATA_HISTO } from "../queries"
import CyberChartContainer from "./CyberChartContainer"
import Constants from "../../../commons/Constants"

type CyberChartHistoriqueType = {
  chartCode: string
  compteId: string
  dateDebut: Date | null
  dateFin: Date | null
}

const {
  LINE_CHART,
} = Constants

export default function CyberChartHistorique({ chartCode, compteId, dateDebut, dateFin }: CyberChartHistoriqueType) {

  const [chartData, setChartData] = useState<any | null>(null)

  const { data, loading, error, refetch } = useQuery(GET_GRAPH_DATA_HISTO, {
    fetchPolicy: "network-only",
    variables:
    {
      compteId: compteId,
      graphCode: chartCode,
      dateDebut: dateDebut,
      dateFin: dateFin,
    }
  })

  useEffect(() => {
    refetch({
      dateDebut: dateDebut,
      dateFin: dateFin,
    })
  }, [dateDebut, dateFin])

  useEffect(() => {
    if (data?.kpiCyberGraphDataHisto) {

      const cyberData = data.kpiCyberGraphDataHisto
      let labels = cyberData.map((data: any) => data.datetime)
      labels = labels.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      let dataSet = cyberData.map((data: any) => data.key)
      dataSet = dataSet.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      setChartData({
        labels: labels,
        datasets: dataSet.map(d => ({
          label: d,
          data: cyberData.filter((data: any) => data.key === d).map((data: any) => data.value),
        }))
      })
    }

  }, [data])

  return <CyberChartContainer chartType={LINE_CHART} chartData={chartData} loading={loading} error={error} />

}