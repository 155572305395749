import React from 'react'
import Modal from '../../Components/Modal/Modal'
import { useP } from '../../services/i18n';
import PopInformationModalBody from './ModalBody/PopInformationModalBody'
import PopInformationModalBodyPegase from './ModalBody/PopInformationModalBodyPegase'
import PopInformationModalBodyRechargeAuto from './ModalBody/PopInformationModalBodyRechargeAuto'
import PopInformationModalBodyDocumentationVoix from './ModalBody/PopInformationModalBodyDocumentationVoix'
import NotificationModalFooter from './NotificationModalFooter'
import { useMutation } from '@apollo/client';
import { NOTIFICATION_LUE, NOTIFICATION_RAPPEL } from './queries';

type NotificationModalType = {
  code: string,
  isOpen: boolean,
  onCloseModal: () => void
}

type NotificationModalBodyType = {
  element: () => JSX.Element,
}

function NotificationModalBody({ element: ModalBody }: NotificationModalBodyType) {
  return <ModalBody />
}

export default function NotificationModal({ code, isOpen, onCloseModal }: NotificationModalType) {
  const p = useP();

  const notificationModalBodys = [
    {
      code: 'WELCOME',
      title: p.t('components.popInformation.title'),
      element: PopInformationModalBody
    },
    {
      code: 'PEGASE_CLIENT',
      title: p.t('components.popInformation.title'),
      element: PopInformationModalBodyPegase
    },
    {
      code: 'RECHARGE_AUTO_BALANCE_DATA',
      title: p.t('components.popInformation.title'),
      element: PopInformationModalBodyRechargeAuto
    },
    {
      code: 'DOCUMENTATION_VOIX',
      title: p.t('components.popInformation.title'),
      element: PopInformationModalBodyDocumentationVoix
    }    
  ]

  const notificationContent = notificationModalBodys.find((n) => n.code === code)

  const onClose = () => { return; }

  const [notificationLue] = useMutation(NOTIFICATION_LUE);
  const [notificationRappel] = useMutation(NOTIFICATION_RAPPEL);

  const onClickRappel = () => {
    notificationRappel({
      variables: {
        notificationCode: code,
      },
    })
    onCloseModal()
  }

  const onClickLu = () => {
    notificationLue({
      variables: {
        notificationCode: code,
      },
    })
    onCloseModal()
  }

  return <Modal
    onClose={onClose}
    isOpen={isOpen}
    content={{
      title: <div className='flex items-center'>{notificationContent?.title}</div>,
      body: notificationContent ? <NotificationModalBody element={notificationContent.element} /> : null,
      footer: <NotificationModalFooter onClickRappel={onClickRappel} onClickLu={onClickLu} />,
    }}
    className="w-full sm:w-10/12 sm:h-5/6 xl:w-9/12"
    closeIcon={false}
  />

}