import Constants from '../commons/Constants';

const {
  MAIL_EXISTE_DEJA,
  NOM_PRENOM_OBLIGATOIRE,
  CLIKKER_HOSTNAME,
  ADMI_HOSTNAME
} = Constants;


const getRedirUrl = ({ url = '', forceParams = [], encode = false }) => {
  const redir = `${window.location.pathname}`;

  const queryParams = new URLSearchParams(window.location.search);

  const redirUrl = `${url}${redir}`;

  forceParams.forEach((param) => {
    queryParams.set(param.key, param.value)
  });

  const encodedURL = `${redirUrl}?${queryParams.toString()}`

  return encode ? encodeURIComponent(encodedURL) : encodedURL;
}

const getErrorMessage = (p, codeMessage = null) => {
  let message = p.t('error.defaultMessage');

  switch (codeMessage) {
    case MAIL_EXISTE_DEJA:
      message = p.t('contacts.contact.mailServerValidation');
      break;
    case NOM_PRENOM_OBLIGATOIRE:
      message = p.t('contacts.contact.nomEtPrenomObligatoire');
      break;
  }
  return message;
}

const convertOctetToMaxSize = (value = 0, decimals = 2) => {
  const octet = Math.abs(parseInt(value, 10));
  const sizes = ["octets", "ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];
  if (octet === 0) return "0 octet";

  const i = parseInt(Math.floor(Math.log(octet) / Math.log(1024)), 10);
  return `${parseFloat(
    // eslint-disable-next-line no-restricted-properties
    (octet / Math.pow(1024, i)).toFixed(parseInt(decimals, 10))
  )} ${sizes[i]} `;
};

const getDataFromHostname = () => {
  
  switch (window.location.hostname) {
    case CLIKKER_HOSTNAME:
      return {
        prefixLogo: 'clikker',
        favicon: 'favicon_clikker.ico',    
        manifest: 'manifest_clikker.json',   
        title: 'Clikker Portail',
        description: 'Clikker - Espace client',
        theme: 'clikker'
      };

    case ADMI_HOSTNAME:
      return {
        prefixLogo: 'admi',
        favicon: 'favicon_admi.ico',        
        manifest: 'manifest_admi.json',   
        title: 'ADMI Portail',
        description: 'ADMI By Blue - Espace client',  
        theme: 'base'
      };

    default:
      return {
        prefixLogo: 'blue',
        favicon : 'favicon.ico',
        manifest: 'manifest.json',   
        title: 'Blue Portail',
        description: 'Blue - Espace client',
        theme: 'base'
      };
  }
}

export {
  getRedirUrl,
  getErrorMessage,
  convertOctetToMaxSize,
  getDataFromHostname
}