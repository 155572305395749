import React from "react"
import IMG from "../../../HelpBoxComponents/IMG"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentPrestations_Q2(){
  const p = useP();
  return <>
    <P>
    <>{parse(p.t('helpBox.helpPrestations.a1.1'))}</>
    </P>
    <div className="mt-1">
      <IMG src="/images/helpBox/informationsSite.png" sizes={{width:120}} />
    </div>
  </>
}