import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import Tooltip from "../../Components/Tooltip/Tooltip";

type LibelleExportGSMType = {
  libelle : string,
  description : string
}

export default function LibelleExportGSM({libelle,description}:LibelleExportGSMType){
  return <>
    
    <div className="flex items-center">
    <div>
    <Tooltip
        button={
          <InformationCircleIcon className="h-5 w-5 mr-1 text-gray-400" />
        }
        content={description}
        placement='top'
        className='flex items-center justify-center text-xs'
        withArrow
      />
      </div>
      <div className="text-gray-600">{libelle}</div>
    </div>
  </>
}