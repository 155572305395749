import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useP } from '../../../services/i18n';
import Table from '../../Table/Table';
import { TableOptionsType } from '../../Table/Types/TableOptionsType';
import WidgetElementType from '../WidgetElementType';
import WidgetLayout from '../WidgetLayout/WidgetLayout';
import WidgetSize from '../WidgetSize';
import { GET_TRAVAUX_PROGRAMMES } from './queries';
import ModaleDetailTP from './ModaleDetailTP';
import CellDetailFormatter from '../../Table/Formatters/CellDetailFormatter';
import ButtonsGroup from '../../ButtonsGroup/ButtonsGroup';

function WidgetTpAVenirWide(props: WidgetElementType) {
  const p = useP();

  const tableOptionsDefault: TableOptionsType = {
    colSelect: false,
    pagination: false,
    search: false,
    fullBordered: false,
    resizable: false,
    naturalWidth: true,
    toolbar: false,
    emptyMessage: p.t('supervisionLien.historiqueTP.emptyMessage')
  }

  const [detailRow, setDetailRow] = useState<any>()
  const [detailIsOpen, setDetailIsOpen] = useState<boolean>(false)
  const openDetail = (row) => {
    if (row.liens.length > 0) {
      setDetailRow(row)
      setDetailIsOpen(true)
    }
  }

  const columnsTPro = [
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.nature'),
      code: 'nature',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.dateDebut'),
      code: 'dateDebut',
      colType: 'dateTime',
      sortable: true
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.dateFin'),
      code: 'dateFin',
      colType: 'dateTime',
      sortable: true
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.duree'),
      code: 'duree',
      colType: 'duree',
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.cause'),
      code: 'cause',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.impact'),
      code: 'impact',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.refFournisseur'),
      code: 'referenceFournisseur',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.type'),
      code: 'typeTravailProgramme.libelle',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('supervisionLien.historiqueTP.columns.statut'),
      code: 'statutTravailProgramme',
      colType: 'state',
    },
    {
      libelle: "Détail",
      code: '',
      colType: 'detail',
      customFormatter: (row, column) =>
        row.liens.length > 0 ? <CellDetailFormatter row={row} action={column.action} onClickRow={true} /> : <></>
      ,
      action: (row) => {
        openDetail(row)
      },
      resizable: false,
      sortable: false
    }
  ]

  const filterButtons = [
    {
      libelle: "A venir", value: true
    },
    {
      libelle: "Tous les TP", value: false
    }
  ]

  const [filter, setFilter] = useState<boolean>(true);

  const [getTps, { loading: loadingTP, data: dataTP, error: errorTP }] = useLazyQuery(GET_TRAVAUX_PROGRAMMES,{
    fetchPolicy: 'network-only'    
  });

  useEffect(() => {
    getTps({
      variables: {
        aVenir: filter
      }
    })
  }, [filter])

  return (
    <WidgetLayout
      header={
        <div className="flex flex-col items-start lg:flex-row lg:items-center justify-between mt-0">
          <div>{p.t('dashboardCompte.TP')}</div>
          <div className="flex items-center">
            <ButtonsGroup
              selectedButtons={filter}
              onSelectedButton={(value) => setFilter(value)}
              buttons={filterButtons}
              multi={false}
            />
          </div>
        </div>
      }
      size={WidgetSize.WIDE}
      addWidget={props.addWidget}
      moveUpWidget={props.moveUpWidget}
      moveDownWidget={props.moveDownWidget}
      removeWidget={props.removeWidget}
    >
      <Table
        idTable="ec-tpro_en_cours"
        loading={loadingTP}
        columns={columnsTPro}
        datas={(!errorTP && dataTP?.travauxProgrammesAVenir) ? dataTP.travauxProgrammesAVenir.map((tp) => ({
          ...tp,
          statutTravailProgramme: {
            ...tp.statutTravailProgramme,
            colorClasses: `bg-${tp.statutTravailProgramme.couleur}-200 text-${tp.statutTravailProgramme.couleur}-800`
          }
        })) : []}
        options={tableOptionsDefault}
        onClickRow={openDetail}
      />
      <ModaleDetailTP
        row={detailRow}
        isOpen={detailIsOpen}
        onClose={() => setDetailIsOpen(false)} />
    </WidgetLayout>
  )

}

export default WidgetTpAVenirWide