import { createContext } from "react";

type TableContext = {
  childrenLoading: boolean,
  idRow: any,
  setChildrenLoading: (loading: boolean) => void,
  setIdRow: (id: number | string) => void,
}

export const TABLE_DEFAULT_VALUE = {
  childrenLoading: false,
  idRow: null,
  setChildrenLoading: (loading: boolean) => { return; },
  setIdRow: (id: number | string) => { return; },
};

export const tableContext = createContext<TableContext>(TABLE_DEFAULT_VALUE);
