
import React, { useContext } from 'react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { datetimeFormatter } from '../../../../Components/Utils/Utils'
import { useP } from '../../../../services/i18n'
import { popupInfosType } from './PopUpInfoMapLien'
import { Link } from 'react-router-dom'
import { authContext } from '../../../../AuthContext'

type PopUpInfoMapLienItemType = {
  item: popupInfosType
}

export default function PopUpInfoMapLienItem({ item }: PopUpInfoMapLienItemType) {

  const {
    selectedCompte,
    loggedInUser
  } = useContext(authContext)

  const p = useP();

  const lienLink = Array.isArray(selectedCompte) ?
    `/${p.t('router.comptes.route')}/${loggedInUser?.compte.id}` :
    `/${p.t('router.compte.route')}/${selectedCompte}`

  return <>
    <div className='mt-2 pr-10 truncate'>{item.nom}</div>
    <div>{item.numeroVoie} {item.libelleVoie}</div>
    {item.complementVoie && <div>{item.complementVoie}</div>}
    <div>{item.codePostal} {item.commune}</div>
    {
      item.liens.map((lien, l) => {
        return <Link key={`lien-${l}`} to={`${lienLink}/lien/${lien.prestationId}`}>
          <a className='block rounded-md mb-1 mt-1 border border-gray-400 p-1.5 hover:bg-gray-100'>
            <div className='text-xs font-medium flex items-start justify-between'>
              <div className=''>
                <div className='text-primary-700 mb-1'>{lien.libelle}</div>
                <div className='rounded-full w-[30px] h-1 bg-secondary-300 mb-1'></div>
                {
                  lien.logins.map((login, l) => {
                    return <div key={l} className="pb-1.5 ">
                      <div className='text-gray-800'>{login}</div>
                      {!lien.loginSupervisionActives[l] ? <div className='inline-flex items-center text-gray-800 bg-gray-100 border border-gray-500 rounded-md px-1.5 mt-0.5'>
                        <div className='mr-1'>{p.t(!lien.loginStates[l] ? 'lien.map.popInfo.deconnecteDepuis' : 'lien.map.popInfo.connecteDepuis')}</div>
                        <div className='text-gray-800  rounded-md'>{(lien.date[l]) ? datetimeFormatter(lien.date[l]) : '--'}</div>
                      </div> :
                        !lien.loginStates[l] ?
                          <div className='inline-flex items-center text-red-800 bg-red-100 border border-red-500 rounded-md px-1.5 mt-0.5'>
                            <div className='mr-1'>{p.t('lien.map.popInfo.deconnecteDepuis')}</div>
                            <div className='text-red-800  rounded-md'>{(lien.date[l]) ? datetimeFormatter(lien.date[l]) : '--'}</div>
                          </div>
                          : <div className='inline-flex items-center text-emerald-800 bg-emerald-200 border border-emerald-500  rounded-md px-1.5 mt-0.5'>
                            <div className='mr-1'>{p.t('lien.map.popInfo.connecteDepuis')}</div>
                            <div className='text-emerald-800 rounded-md'>{(lien.date[l]) ? datetimeFormatter(lien.date[l]) : '--'}</div>
                          </div>
                      }
                    </div>
                  })

                }
              </div>
              <div className=''><ArrowRightIcon className='h-4 w-4' /></div>

            </div>
          </a>
        </Link>
      })
    }
  </>
}