/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react'
import { ColumnType } from './Types/ColumnType'
import Th from './Th'
import Tr from './Tr'
import { TableOptionsType } from './Types/TableOptionsType'
import clsx from 'clsx'
import { colWidthResizeType } from './TableContainer'

export type SortingType = "ASC" | "DESC" | undefined;

type TheadType = {
  columns: Array<ColumnType>,
  tableOptions: TableOptionsType,
  tableHeight: number,
  colWidthResize?: colWidthResizeType | undefined
  defaultSortColumn?: { column: ColumnType, direction: SortingType },
  totalCount: number,
  selectedRows: Array<any>,
  onResize: (width: string, index: number) => void,
  onSort?: (column: ColumnType, direction: SortingType) => void,
  selectAll: () => void,
}

function Thead({ columns, tableOptions, tableHeight, colWidthResize, onResize, onSort, defaultSortColumn, selectAll, totalCount, selectedRows }: TheadType) {

  const [sortingDirection, setSortingDirection] = useState<SortingType | null>(defaultSortColumn ? defaultSortColumn.direction : null);
  const [sortingColumn, setSortingColumn] = useState<ColumnType | null>(defaultSortColumn ? defaultSortColumn.column : null)

  const handleSortingChange = (colIndex) => {
    const sortOrder = sortingColumn === columns[colIndex] && sortingDirection === "ASC" ? "DESC" : "ASC";
    setSortingDirection(sortOrder)
    setSortingColumn(columns[colIndex])
    onSort?.(columns[colIndex], sortOrder)
  }

  return (
    <>
      <thead className={clsx(
        `bg-gray-100 sticky top-0 z-30`)}>
        <Tr>
          {
            tableOptions.colSelect &&
            <Th
              selectAll={selectAll}
              onResize={onResize}
              columns={columns}
              colIndex={-1}
              tableHeight={tableHeight}
              className={clsx(tableOptions.fullBordered ? `border-r border-gray-200` : ``,)}
              libelle=""
              colType="select"
              tableOptions={tableOptions}
              resizable={false}
              colWidthResize={colWidthResize}
              totalCount={totalCount}
              selectedRows={selectedRows}
            />
          }
          {columns.filter((c) => !(c?.visible === false)).map((column, index) => {
            return <Th
              selectAll={selectAll}
              onResize={onResize}
              columns={columns}
              tableHeight={tableHeight}
              colIndex={index}
              className={clsx(tableOptions.fullBordered ? `border-r border-gray-200` : ``, columns.length == index + 1 ? `border-r-0` : ``)}
              key={index}
              libelle={column.libelle}
              colType={column.colType}
              tableOptions={tableOptions}
              resizable={column.resizable}
              sortable={!!column.sortable}
              colWidthResize={colWidthResize}
              initialWidth={column.width}
              handleSortingChange={handleSortingChange}
              sortingDirection={sortingDirection || undefined}
              sortingColumn={sortingColumn || undefined}
            />
          })}
        </Tr>
      </thead>
    </>
  )

}

export default Thead