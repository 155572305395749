import React from 'react'
import clsx from 'clsx';

type CellSelectRowFormatterType = {
  row: any,
  onChange: (row: any, checked: boolean) => void,
  selectedRows: Array<any> | undefined
}

export default function CellSelectRowFormatter({ row, onChange, selectedRows }: CellSelectRowFormatterType) {
  const onSelect = (checked) => {
    onChange(row, checked);
  }
  return <>
    <div className={clsx(`flex items-center justify-center h-full`
    )}>
      <input
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
        value={row}
        onChange={(e) => {
          onSelect(e.target.checked)
        }
        }
        checked={selectedRows?.find((r) => r.idLigne === row.idLigne)}
      />
    </div>
  </>
}