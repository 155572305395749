import React from 'react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import Tooltip from '../../../../../Components/Tooltip/Tooltip';
import { useP } from '../../../../../services/i18n';
import Constants from '../../../../../commons/Constants';

const {
  SAISIE_CONTRAT,
  SAISIE_SITE
} = Constants;

type RolesListItemsActionsAddButtonType = {
  onClick: (event: React.MouseEvent<HTMLDivElement>, type: string) => void,
  scope: string,
  icon: any
}

export default function RolesListItemsActionsAddButton({ onClick, scope, icon: Component }: RolesListItemsActionsAddButtonType) {
  const p = useP();
  return (
    <>
      <Tooltip
        button={
          <button type="button" className="inline-flex group cursor-pointer py-1 rounded-md  focus:bg-gray-100 focus:outline-none">
            <div onClick={(e) => onClick(e, scope)} className="relative group">
              <Component className="h-5 w-5 mx-2 text-primary-500 group-hover:text-primary-500" />
              <PlusCircleIcon className="absolute -right-0.5 -top-1 h-3 w-3 mx-2 text-primary-500 bg-white group-hover:text-green-500" />
            </div>
          </button>
        }
        content={
          <div className='text-xs'>{scope == SAISIE_CONTRAT ? p.t("contacts.addContrats") : p.t("contacts.addSites")}</div>
        }
        placement='top'
      />


    </>)

}