import { gql } from "@apollo/client";

export const IS_LOGGED_IN_QUERY = gql`
  query IsLoggedIn {
    isLoggedIn {
      success      
      roles
      loggedInUser{
        notifications{
          code
        }
      }
      compte {
        id
        raisonSociale
        siren
        isVip
        compteParent {
          id
          comptesEnfant {
            id
            raisonSociale
          } 
        }
      }
      featuresFlag
      sitesRestreints
    }
  }
`

export const CREATE_SESSION = gql`
  mutation CreateAppSession($input: CreateAppSessionInput!) {
    createAppSession(input: $input) {
      id
    }
  }
`

export const UPDATE_SESSION_TIME = gql`
  mutation UpdateAppSession($input: UpdateAppSessionInput!) {
    updateAppSessionTime(input: $input) {
      id
    }
  }
`

export const GET_COMPTES = gql`
  query GetComptes($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    comptes(filters: $filters, sorters: $sorters, pagination: $pagination){
      totalCount
      items {
        id
        raisonSociale
        siren
        analytique1
      }
    }
  }
`