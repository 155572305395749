import { useCallback, useState } from "react";

import secureLocalStorage from "react-secure-storage";
import { getLocalStorage } from "./Components/Utils/Utils";
import { LoggedInUserType } from "./types/LoggedInUserType";
import { NotificationType } from "./types/NotificationType";

export const useAuth = () => {
  const _getStoredAccount = (): LoggedInUserType => {
    const account = secureLocalStorage.getItem("loggedInUser") as LoggedInUserType;

    return account ? account : null;
  };

  const _getStoredSelectedCompte = () => {
    const selectedCompteLS = getLocalStorage("ec-selected-comptes")
    const selectedCompteSS = sessionStorage.getItem("selectedCompte")
    if (selectedCompteLS && !selectedCompteSS) {
      sessionStorage.setItem("selectedCompte", selectedCompteLS)
    }
    const selected = getLocalStorage('ec-mode-multicomptes') && selectedCompteLS ? selectedCompteLS : selectedCompteSS;    
    return selected && JSON.parse(selected)
  }

  const _setStoredAccount = (loggedInUser) => {

    secureLocalStorage.setItem(
      "loggedInUser",
      loggedInUser
    );

  };

  const [loggedInUser, setLoggedInUser] = useState<LoggedInUserType>(_getStoredAccount());

  const setCurrentLoggedInUser = useCallback((currentLoggedInUser) => {
    _setStoredAccount(currentLoggedInUser);
    setLoggedInUser(currentLoggedInUser);
  }, []);

  const [selectedCompte, setSelectedCompte] = useState<any>(_getStoredSelectedCompte());

  const setCurrentSelectedCompte = useCallback((currentCompteId) => {
    setSelectedCompte(currentCompteId)
  }, []);

  const [roles, setRoles] = useState<Array<string> | null>(null);

  const setCurrentRoles = useCallback((currentRole) => {
    setRoles(currentRole)
  }, []);

  const [notifications, setNotifications] = useState<Array<NotificationType>>([]);

  const setCurrentNotifications = useCallback((currentNotifications) => {
    setNotifications(currentNotifications)
  }, []);

  const [featuresFlag, setFeaturesFlag] = useState<Array<string> | null>(null);

  const setCurrentFeaturesFlag = useCallback((currentFeaturesFlag) => {
    setFeaturesFlag(currentFeaturesFlag)
  }, []);

  const [sitesRestreints, setSitesRestreints] = useState<Array<string> | null>(null);

  const setCurrentSitesRestreints = useCallback((currentSitesRestreints) => {
    setSitesRestreints(currentSitesRestreints)
  }, []);

  return {
    loggedInUser,
    setLoggedInUser: setCurrentLoggedInUser,

    selectedCompte,
    setSelectedCompte: setCurrentSelectedCompte,

    roles,
    setRoles: setCurrentRoles,

    notifications,
    setNotifications: setCurrentNotifications,

    featuresFlag,
    setFeaturesFlag: setCurrentFeaturesFlag,

    sitesRestreints,
    setSitesRestreints: setCurrentSitesRestreints,

  };
};
