import React, { useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import Button from '../../../Components/Button'
import ModaleReouvertureTicketBody from './ModaleReouvertureTicketBody'
import { useP } from '../../../services/i18n'
import { useMutation } from '@apollo/client'
import { TICKET_REOUVERTURE } from './queries'
import { toastError } from '../../../Components/Utils/Utils'

type ModaleReouvertureTicketType = {
  ticketId: number,
  isOpen: boolean,
  onClose: () => void,
  onReouverture: () => void
}
export default function ModaleReouvertureTicket({ isOpen, ticketId, onClose, onReouverture }: ModaleReouvertureTicketType) {

  const [commentaire, setCommentaire] = useState("")

  const [ticketReouverture] = useMutation(TICKET_REOUVERTURE)

  const reouverture = async () => {
    ticketReouverture({
      variables: {
        ticketId,
        commentaire
      },
      onCompleted: (data) => {
        if (data?.ticketReouverture?.success) {
          onClose();
          onReouverture();
        } else {
          toastError(p.t('error.defaultMessage'));
        }
      }
    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
    })
  }

  const p = useP()
  return <>
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      content={{
        title: p.t('support.detail.modaleReouverture.title'),
        body: <ModaleReouvertureTicketBody onChangeCommentaire={(value) => setCommentaire(value)} />,
        footer: <div className='flex items-center justify-end'>
          <Button disabled={commentaire === ''} onClick={reouverture}>{p.t('support.detail.modaleReouverture.action')}</Button>
        </div >,
      }}
      className="w-full lg:w-1/2"
    />
  </>
}