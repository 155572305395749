import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client';
import { BuildingOfficeIcon, UserIcon } from '@heroicons/react/24/outline'
import { Input } from '../../../Components/Form/Input'
import { useP } from '../../../services/i18n';
import { GET_CONTACT_SUPERVISION_SITE, GET_SITE } from '../queries';
import { Control, Controller, UseFormRegister, UseFormReset } from 'react-hook-form'
import Spinner from '../../../Components/Spinner';
import Constants from '../../../commons/Constants';
import { useCheckRight } from '../../../Components/CheckRight/CheckRight';
import TooltipInformation from '../../../Components/Tooltip/TooltipInformation';
import SelectionContactEtPersonne from "../../../Components/SelectionContactEtPersonne/SelectionContactEtPersonne";

type InformationsSiteFormType = {
  register: UseFormRegister<any>,
  control: Control<any>,
  errors: any,
  reset: UseFormReset<any>,
  idSite: number,
  setValue: (name: string, value: unknown, config?: any) => void,
}

const {
  ROLE_ADMIN_CONTRAT
} = Constants;


export default function InformationsSiteForm({ register, errors, reset, idSite, control, setValue }: InformationsSiteFormType) {

  const p = useP();
  const { checkRight } = useCheckRight();

  const [site, setSite] = useState<any>({});

  const [getSite, { loading, data }] = useLazyQuery(GET_SITE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setSite(data.site);
    }
  });

  const [getContactSupervisonSite, { loading: loadingContact, data: dataContact }] = useLazyQuery(GET_CONTACT_SUPERVISION_SITE, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (idSite) {
      getSite({
        variables: {
          id: idSite,
        },
      });
      getContactSupervisonSite({
        variables: {
          siteId: idSite,
        },
      });
    }
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(site);
  }, [site]);

  const onSelectPersonne = async (value) => {
    setValue('contact', value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const rightAdminContrat = checkRight(ROLE_ADMIN_CONTRAT);

  return <>
    {loading || loadingContact ?
      <><div className='h-[430px] w-full flex items-center justify-center'><Spinner color='primary' /></div></>
      :
      <div className='flex items-start flex-col lg:flex-row lg:space-x-6 '>
        <div className='flex-1'>
          <div className='font-medium text-sm mb-4 lg:mb-0 p-2 bg-gray-100 rounded-md w-full'>
            <div className=''>
              <div className='mr-2 flex items-center'>
                <UserIcon className='h-6 w-6 text-secondary-500' />
                <div className='h-[1px] bg-gray-300 flex-1 ml-4'></div>
              </div>
              <div>
                {rightAdminContrat ?
                  <Controller
                    control={control}
                    name="contact"
                    defaultValue={dataContact?.getRoleSupervisionProactiveFromSite?.contact}
                    render={() =>
                      <SelectionContactEtPersonne
                        defaultValue={dataContact?.getRoleSupervisionProactiveFromSite?.contact}
                        formErrors={errors}
                        onSelectPersonne={(value) => onSelectPersonne(value.obj)}
                        entity={null}
                        inlineEditable={false}
                        title={p.t('prestations.site.contactSupervision')}
                        filters={[
                          {
                            column: 'compte.id',
                            value: `${site?.compteId}`
                          }
                        ]}
                      />
                    }
                  />
                  : <div>{p.t('prestations.site.contactSupervision')} : {dataContact?.getRoleSupervisionProactiveFromSite?.contact?.nom} {dataContact?.getRoleSupervisionProactiveFromSite?.contact?.prenom}</div>}
              </div>
            </div>

            <div className=''>
              <div className='mr-2 flex items-center mt-4'>
                <BuildingOfficeIcon className='h-6 w-6 text-secondary-500' />
                <div className='h-[1px] bg-gray-300 flex-1 ml-4'></div>
              </div>
              <div className="col-span-6 mb-4">
                <Input
                  defaultValue={data?.site?.nom}
                  register={register}
                  errors={errors}
                  name="nom"
                  label={p.t('prestations.site.nomPersonnalise')}
                  type='text' autoComplete='off' readOnly={!rightAdminContrat} />
              </div>
              <div className='mt-2'>
                <div>{data?.site?.nom}</div>
                <div>{data?.site?.numeroVoie ? data?.site?.numeroVoie : ""} {data?.site?.libelleVoie}</div>
                <div>{data?.site?.complementVoie}</div>
                <div>{data?.site?.codePostal} {data?.site?.commune}</div>
              </div>
            </div>



          </div>
        </div>

        <div className="flex-1 grid grid-cols-1 gap-y-4 gap-x-4 lg:grid-cols-6 w-full mt-2">

          <div className="col-span-6">
            <Input
              defaultValue={data?.site?.analytique3}
              register={register}
              errors={errors}
              name="analytique3"
              label={
                <div className='flex items-center'>
                  <div className='mr-2'>{p.t('prestations.site.codeAnalytique')}</div>
                  {rightAdminContrat && <TooltipInformation contenu={p.t('prestations.site.helpCodeAnalytique')} sizeClass="h-4 w-4" />}
                </div>
              }
              type='text' autoComplete='off' readOnly={!rightAdminContrat} />
          </div>
          <div className="col-span-6">
            <Input
              defaultValue={data?.site?.analytique2}
              register={register}
              errors={errors}
              name="analytique2"
              label={
                <div className='flex items-center'>
                  <div className='mr-2'>{p.t('prestations.site.codeSociete')}</div>
                  {rightAdminContrat && <TooltipInformation contenu={p.t('prestations.site.helpCodeSociete')} sizeClass="h-4 w-4" />}
                </div>
              }
              type='text' autoComplete='off' readOnly={!rightAdminContrat} />
          </div>
          <div className="col-span-6">
            <Input
              defaultValue={data?.site?.analytique1}
              register={register}
              errors={errors}
              name="analytique1"
              label={
                <div className='flex items-center'>
                  <div className='mr-2'>{p.t('prestations.site.codeSite')}</div>
                  {rightAdminContrat && <TooltipInformation contenu={p.t('prestations.site.helpCodeSite')} sizeClass="h-4 w-4" />}
                </div>
              }
              type='text' autoComplete='off' readOnly={!rightAdminContrat} />
          </div>
        </div>

      </div>
    }
  </>
}