import { useLazyQuery, useMutation } from '@apollo/client';
import { ArrowDownTrayIcon, CheckCircleIcon, MinusCircleIcon, NoSymbolIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Constants from '../../../../commons/Constants';
import Badge from '../../../../Components/Badge';
import Button from '../../../../Components/Button';
import { useCheckRight } from '../../../../Components/CheckRight/CheckRight';
import DescriptionList, { DescriptionListItemAlignType } from '../../../../Components/DescriptionList/DescriptionList';
import Editable, { EMPTY_FIELD } from '../../../../Components/Editable/Editable';
import Modal from '../../../../Components/Modal/Modal';
import { ModalConfirmation } from '../../../../Components/ModalDialog/ModalConfirmation';
import Spinner from '../../../../Components/Spinner';
import TooltipInformation from '../../../../Components/Tooltip/TooltipInformation';
import { toastError } from '../../../../Components/Utils/Utils';
import { useP } from '../../../../services/i18n';
import PrestationDetailModaleBody from '../../PrestationDetail/PrestationDetailModaleBody';
import PrestationHistoriqueModaleBody from '../../PrestationDetail/PrestationHistoriqueModaleBody';
import { ACTIVE_INTERNATIONAL, DESACTIVE_INTERNATIONAL, SAVE_NDI, TELECHARGER_CDR_NDI } from '../../queries';

type PrestationDetailModaleNDIType = {
  isOpen: boolean,
  prestation: any,
  onClose: () => void,
  onSave: (ndi: any) => void
}

const {
  FILER_URL,
  ROLE_ADMIN_PRESTATION,
  ROLE_ADMIN_CONTRAT
} = Constants;


function PrestationDetailModaleNDI({ prestation, isOpen = false, onClose, onSave }: PrestationDetailModaleNDIType) {
  const p = useP();
  const alignTop: DescriptionListItemAlignType = "top"

  const { checkRight } = useCheckRight();

  const [saveNdi] = useMutation(SAVE_NDI);
  const [activeInternational] = useMutation(ACTIVE_INTERNATIONAL);
  const [desactiveInternational] = useMutation(DESACTIVE_INTERNATIONAL);

  const [prestationAffiche, setPrestationAffiche] = useState<any>(prestation);
  const [infos, setInfo] = useState<any[]>([]);
  const [errorPin, setErrorPin] = useState<string>("");

  const rightAdminPrestation = checkRight(ROLE_ADMIN_PRESTATION);
  const rightAdminContrat = checkRight(ROLE_ADMIN_CONTRAT);

  // Téléchargement des CDR
  const [telechargerCdr, { loading: loadingCdr }] = useLazyQuery(TELECHARGER_CDR_NDI, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data.telechargerCdr) {
        const { hash, filename } = data.telechargerCdr
        window.location.href = `${FILER_URL}?hash=${hash}&filename=${filename}`
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  const onSaveNdi = async (ndi) => {
    let result = true;

    await saveNdi({
      variables: { ndi: { ndi: prestationAffiche?.ndi, ...ndi } },
      onCompleted: (data) => {
        setPrestationAffiche({ ...prestationAffiche, ...ndi })
        onSave(data.saveNdi)
      },
    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
      result = false
    })

    return result;
  }

  const onActivateInternational = async (ndi, code) => {
    let result = true;
    await activeInternational({
      variables: { ndi, code },
      onCompleted: (data) => {
        setPrestationAffiche({ ...prestationAffiche, international: true, pinCode: data?.ndiActiverInternational })
        onSave({ ndi: ndi, international: true, pinCode: data?.ndiActiverInternational })
      }
    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
      result = false
    })

    return result;
  }

  const onDesactivateInternational = (ndi) => {
    desactiveInternational({
      variables: { ndi },
      onCompleted: () => {
        setPrestationAffiche({ ...prestationAffiche, international: false })
        onSave({ ndi: ndi, international: false })
      },
      onError: (error) => {
        console.error(error)
        toastError(p.t('error.defaultMessage'))
      }
    })
  }

  const onTelechargerCdr = (ndi: any) => {
    telechargerCdr({
      variables: { ndi }
    })
  }

  useEffect(() => {
    setPrestationAffiche(prestation)
  }, [prestation])


  const [confirmNoPinOpen, setConfirmNoPinOpen] = useState(false);
  const confirmNoPinMessage = <div className='text-gray-700 text-sm'>{p.t('prestations.ndi.alertPinCode')}</div>

  useEffect(() => {
    const international = (prestationAffiche?.international ? prestationAffiche?.international : false)
    const infos =
      [
        {
          label: p.t('prestations.ndi.columns.ndi'),
          value: prestationAffiche?.numero,
        },
        {
          label: p.t('prestations.ndi.columns.type'),
          value: prestationAffiche?.type,
        },
        {
          label: p.t('prestations.ndi.columns.isole'),
          value: prestationAffiche?.isTete ? p.t('prestations.ndi.isole') : p.t('prestations.ndi.teteDeGroupement'),
        },
        {
          label: p.t('prestations.ndi.columns.nom'),
          value: rightAdminPrestation ?
            <Editable value={prestationAffiche?.nom ? prestationAffiche.nom : EMPTY_FIELD} onSaveField={(value) => onSaveNdi({ nom: value })} />
            : prestationAffiche?.nom,
        },
        {
          label: p.t('prestations.ndi.columns.provisionne'),
          value: prestationAffiche?.isProvisionne ? p.t('prestations.ndi.oui') : p.t('prestations.ndi.non'),
        },
        {
          label: p.t('prestations.ndi.columns.international'),
          value: rightAdminPrestation ? <>
            <div className='flex items-end flex-col md:items-center md:flex-row '>

              {
                !international ? (
                  <div className='mb-2.5 md:mb-0 md:mr-2'>
                    <Button onClick={() => onActivateInternational(prestationAffiche?.ndi, null)} color='primaryLight' size="small" className=''>
                      <CheckCircleIcon className='h-4 w-4 mr-1.5' />
                      {p.t('prestations.ndi.activer')}
                    </Button>
                  </div>) : (
                  <div className='flex items-center'>
                    <Button onClick={() => onDesactivateInternational(prestationAffiche?.ndi)} size='small' color='secondaryLight' >
                      <NoSymbolIcon className='h-4 w-4 mr-1.5' />
                      {p.t('prestations.ndi.desactiver')}
                    </Button>
                  </div>)
              }

            </div>

            {international &&
              <div className='mt-2 p-2 bg-white rounded-md border border-gray-200'>
                <div className='flex items-center w-full'>
                  <div className='font-medium text-gray-500 mr-2'>{p.t('prestations.ndi.columns.code')} : </div>
                  <div className='flex-1 pr-4'>
                    <Editable
                      type="number"
                      value={prestationAffiche?.pinCode ? prestationAffiche.pinCode : EMPTY_FIELD}
                      onSaveField={(value) => onActivateInternational(prestationAffiche?.ndi, value)}
                      error={errorPin}
                      onChange={(value) => {
                        value && value.length != 6 ? setErrorPin(p.t('prestations.ndi.errorPinCode')) : setErrorPin("")
                      }}
                    />
                  </div>
                </div>
                {prestationAffiche?.pinCode ?
                  <div className={clsx(`text-xs mt-1`, `text-primary-600`)}>
                    {p.t('prestations.ndi.infoPinCode')}
                  </div>
                  :
                  <div className={clsx(`text-xs mt-1`, `text-red-600`)}>
                    {p.t('prestations.ndi.infoPinCodeDesactive')}
                  </div>
                }

                {prestationAffiche?.pinCode ?
                  <div className='mt-2'>
                    <Button size='small' color='secondaryLight' onClick={() => setConfirmNoPinOpen(true)}>
                      <MinusCircleIcon className='h-4 w-4 mr-1.5' />
                      {p.t('prestations.ndi.desactiverPIN')}
                    </Button>
                  </div>
                  :
                  <div className='mt-2'>
                    <Button size='small' color='primaryLight' onClick={() => onActivateInternational(prestationAffiche?.ndi, null)}>
                      <PlusCircleIcon className='h-4 w-4 mr-1.5' />
                      {p.t('prestations.ndi.activerPIN')}
                    </Button>
                  </div>
                }
              </div>
            }
          </> : <>{international ? <div>{p.t('prestations.ndi.oui')}</div> : <div>{p.t('prestations.ndi.non')}</div>} <div>{p.t('prestations.ndi.columns.code')} : {prestationAffiche?.pinCode}</div></>,
          align: international && alignTop
        },
        {
          label: <>
            <div className='flex items-center'>
              <div>{p.t('prestations.ndi.columns.codeAnalytique')}</div>
              {rightAdminContrat &&
                <div className='ml-2'>
                  <TooltipInformation contenu={p.t('prestations.ndi.columns.helpCodeAnalytique')} sizeClass="h-4 w-4" />
                </div>
              }
            </div>
          </>,
          value: rightAdminPrestation ?
            <Editable maxLength={4} value={prestationAffiche?.codeAnalytique ? prestationAffiche.codeAnalytique : EMPTY_FIELD} onSaveField={(value) => onSaveNdi({ codeAnalytique: value })} />
            : prestationAffiche?.codeAnalytique
        },
        {
          label: p.t('prestations.ndi.columns.cdr'),
          value:
            loadingCdr ? <Spinner /> : <div className='mb-2.5 md:mb-0 md:mr-2'>
              <Button onClick={() => onTelechargerCdr(prestationAffiche?.ndi)} color='primaryLight' size="small" className=''>
                <ArrowDownTrayIcon className='h-4 w-4 mr-1.5' />
                {p.t('prestations.ndi.telecharger')}
              </Button>
            </div>
        }
      ]

    setInfo(infos)

  }, [prestationAffiche, loadingCdr, errorPin])

  const Tabs = {
    navigation: [
      {
        libelle: p.t('prestations.detail.informations'),
        jsx: <>{p.t('prestations.detail.informations')}</>
      },
      {
        libelle: p.t('prestations.detail.historique'),
        jsx: <>{p.t('prestations.detail.historique')}</>
      },
    ],
    panels: [
      <DescriptionList datas={infos} key="Infos_NDI" columnWidthClassName="w-[170px]" />,
      <PrestationHistoriqueModaleBody key="Historique_NDI" prestationId={prestationAffiche?.id} />,
    ]

  }

  const disablePin = (ndi) => {
    onActivateInternational(ndi, '')
    setConfirmNoPinOpen(false)
  }

  const confirmNoPinAction = (confirm, ndi) => {
    !confirm ? setConfirmNoPinOpen(false) : disablePin(ndi)
  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('prestations.ndi.detail.title')}</div>
              <div className='ml-1'>{prestation?.numero}</div>
              <div className='ml-2'><Badge itemLibelle={prestation?.etat?.libelle} itemColor={prestation?.etatColor} /></div>
            </div>
            <div className="text-xs text-gray-600 mt-1">
              <div>{prestation?.site?.nom}</div>
              <div>{prestation?.site?.codePostal} {prestation?.site?.commune}</div>
            </div>
          </>
          ,
          body: <PrestationDetailModaleBody tabsNavigation={Tabs.navigation} tabsPanel={Tabs.panels} />,
          footer: <><div className='flex items-center justify-end'>
            <Button onClick={onClose}>
              {p.t('actions.fermer')}
            </Button>
          </div></>,
        }}
        className="w-full lg:w-6/12 lg:h-5/6"
      />
      <ModalConfirmation
        isOpen={confirmNoPinOpen}
        message={confirmNoPinMessage}
        onClose={(confirm) => confirmNoPinAction(confirm, prestationAffiche?.ndi)} />
    </>
  )
}

export default PrestationDetailModaleNDI