import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";

type contactType = {
  name : string,
  phone : string,
  email:string,
  fonction:string
}

type ContactUserType = {
  contact:contactType
}
export default function ContactUser({contact}:ContactUserType){
  return <>
    <div className="flex-1 text-sm pl-4 relative">
      <div className="absolute left-0 top-0 h-full w-[3px] bg-secondary-200 rounded-full"></div>
      <div className="font-medium text-primary-700">{contact.name}</div>
      <div className="font-medium text-xs text-gray-500">{contact.fonction}</div>
      {
      contact.email &&       
      <div className="text-secondary-600 text-xs my-1 flex items-center">
        <EnvelopeIcon className="h-3 w-3 mr-1.5" />
        <a href={`mailto:${contact.email}`}>{contact.email}</a>
      </div>
      }
      {
      contact.phone && 
      <div className="text-secondary-600 text-xs  my-1 flex items-center">
        <PhoneIcon className="h-3 w-3 mr-1.5" />                
        <a href={`tel:${contact.phone}`}>{contact.phone}</a>
      </div>
      }
    </div>
  </>
}