import dayjs from "dayjs";
import React from "react"
import Filters from "../../Components/Filters/Filters";
import { filterTypes } from "../../Components/Filters/Types/filterTypes";

type SupervisionFiltersType = {
  addFilters: (filters: Array<filterTypes>) => void,
  filters: Array<filterTypes>,
}

export default function SupervisionFilters({
  addFilters,
  filters,
}: SupervisionFiltersType) {
  
  return <>          
      <div className="border-l border-gray-200">
        <Filters
          filters={filters}
          addFilters={(filters) => {
            addFilters(filters);
          }}
          buttonClassName="p-1.5 rounded-r-md border bg-white hover:bg-gray-100 border-transparent text-primary-700"
          buttonClassNameOpen="border-white"
        />
      </div>
  </>
}
