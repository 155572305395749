import React from "react";
import clsx from "clsx";

type SplitterHandlebarType = {
  orientation: string;
  resizerRef: any;
  handleMouseDown: (e: any) => void;
  isDragging: boolean;
}
function SplitterHandlebar({
  orientation,
  resizerRef,
  handleMouseDown,
  isDragging,
}:SplitterHandlebarType) {
  

  return (
    <>
      <div
        className={clsx(
          "hidden relative group lg:flex item-center justify-center user-select-none touch-action-none transition-all",
          isDragging ? `bg-primary-300`: `bg-transparent`,
          orientation === "vertical"
            ? "flex-row w-full h-[4px] cursor-row-resize"
            : "flex-col w-[4px] mx-0.5 cursor-col-resize"
        )}
        ref={resizerRef}
        onMouseDown={handleMouseDown}
      >
        <div
          className={clsx(
            "z-10 relative flex items-center justify-center bg-primary-300 rounded-full group-hover:rounded-none transition-all duration-300 ease-in-out group-hover:bg-primary-300",
            orientation === "vertical"
              ? "space-x-1 flex-row w-[50px] hover:w-[70px] group-hover:w-full group-focus:w-full"
              : "space-y-1 flex-col h-[50px] hover:h-full group-hover:h-full group-focus:h-full",
          )}
        ></div>
        <div className={clsx("absolute bg-gray-300",
            orientation === "vertical"
            ? "w-full h-[1px] left-0 top-[1px]"
            : "h-full w-[1px] top-0 left-[1px]"
          )}></div>        
        </div>
        
    </>
  );
}
export default SplitterHandlebar;