import React, { ReactNode } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip';
import './styles.css';

type TriggerType = 'click' | 'right-click' | 'hover' | 'focus';

type TooltipPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

type TooltipType = {
  className?: string,
  classNameButton?: string,
  button: ReactNode,
  content: ReactNode,
  withArrow?: boolean,
  closeOnOutsideClick?: boolean,
  closeOnTriggerHidden?: boolean,
  defaultVisible?: boolean,
  delayHide?: number,
  delayShow?: number,
  followCursor?: boolean,
  interactive?: boolean,
  offset?: [number, number],
  onVisibleChange?: (state: boolean) => void,
  placement?: TooltipPlacement,
  trigger?: TriggerType,
}

export default function Tooltip({
  button,
  content,
  withArrow = false,
  className = '',
  classNameButton = '',
  closeOnOutsideClick,
  closeOnTriggerHidden,
  defaultVisible,
  delayHide,
  delayShow = 500,
  followCursor,
  interactive,
  offset,
  onVisibleChange,
  placement = 'top',
  trigger = 'hover',
}: TooltipType) {

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    {
      closeOnOutsideClick: closeOnOutsideClick,
      closeOnTriggerHidden: closeOnTriggerHidden,
      defaultVisible: defaultVisible,
      delayHide: delayHide,
      delayShow: delayShow,
      followCursor: followCursor,
      interactive: interactive,
      offset: offset,
      onVisibleChange: onVisibleChange,
      placement: placement,
      trigger: trigger,
    }
  );

  return (
    <div className={className}>
      <div ref={setTriggerRef} className={classNameButton}>
        {button}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
          })}
        >
          {withArrow && <div {...getArrowProps({ className: 'tooltip-arrow' })} />}
          {content}
        </div>
      )}
    </div>
  );
}