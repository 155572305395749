import React, { useState } from "react";
import {
  BOOL_TYPE,
  DATA_TYPE,
  DATE_TYPE,
  EMPTY_TYPE,
  LIST_TYPE,
  NUMBER_TYPE,
  OPERATOR_LIKE,
  OPERATOR_NOTLIKE,
  SELECT_TYPE,
  STRING_TYPE,
} from "../../../datas/Operators";
import InputCombobox from "../../Form/InputCombobox/InputCombobox";
import { filterTypes, operatorType } from "../Types/filterTypes";

type OperatorInputType = {
  operator: operatorType,
  type: string,
  filter: filterTypes,
  changeOperatorValue: (code: string, values: any, parent: any) => void,
  showLabel: boolean,
  parent: any,
}

function OperatorInput({
  operator,
  type,
  filter,
  changeOperatorValue,
  showLabel,
  parent,
}: OperatorInputType) {
  const [inputValue, setInputValue] = useState();

  const onChangeValue = (value, code, libValue) => {
    let reqValue = value;
    const reqLibValue = libValue ? libValue : value;

    if ([OPERATOR_LIKE.code, OPERATOR_NOTLIKE.code].includes(code)) {
      reqValue = "%" + value + "%";
    }

    changeOperatorValue(
      code,
      {
        operator: operator,
        value: reqValue,
        libValue: reqLibValue,
      },
      parent
    );

    setInputValue(value);
  };
  const onChangeValueData = (fieldValue, fieldLabel, selectedItems, code) => {
    const itemsValue = selectedItems.length
      ? selectedItems.map((item) => item[fieldValue])
      : null;
    const itemsLabel = selectedItems.length
      ? selectedItems.map((item) => item[fieldLabel])
      : null;

    changeOperatorValue(
      code,
      {
        operator: operator,
        value: itemsValue,
        libValue: itemsLabel,
        isSearchData: true,
      },
      parent
    );

    setInputValue(itemsValue);
  };

  const getInput = (input, inputType) => {
    let res = <></>;

    const type = [
      EMPTY_TYPE,
      NUMBER_TYPE,
      DATE_TYPE,
      STRING_TYPE,
      BOOL_TYPE,
    ].includes(inputType)
      ? inputType
      : STRING_TYPE;

    if (inputType == SELECT_TYPE && filter.type == "date") {
      filter.options = [
        {
          code: "TODAY",
          libelle: "aujourd'hui",
        },
        {
          code: "THISWEEK",
          libelle: "cette semaine",
        },
        {
          code: "THISMONTH",
          libelle: "ce mois-ci",
        },
        {
          code: "THISYEAR",
          libelle: "cette année",
        },
        {
          code: "YESTERDAY",
          libelle: "hier",
        },
        {
          code: "LASTWEEK",
          libelle: "la semaine dernière",
        },
        {
          code: "LASTMONTH",
          libelle: "le mois dernier",
        },
        {
          code: "LASTYEAR",
          libelle: "l'année dernière",
        },
      ];
    }

    switch (inputType) {
      case DATA_TYPE:
        res = (
          <>multiselect Component</>
          // <Multiselect
          //   name={input.code}
          //   refOptions={filter.refOptions}
          //   fieldLabel={filter.fieldLabel}
          //   fieldValue={filter.fieldValue}
          //   fieldSearch={filter.fieldSearch}
          //   onSelectValues={onChangeValueData}
          //   alwaysDisplay={true}
          // />
        );
        break;

      case LIST_TYPE:
        res = (
          <>multiselect Component</>
          // <Multiselect
          //   name={input}
          //   refOptions={filter.refOptions}
          //   onSelectValues={onChangeValue}
          //   alwaysDisplay={true}
          //   list={true}
          // />
        );
        break;

      case SELECT_TYPE:
        res = (
          <>
            <InputCombobox
              name="filtreselect"
              errors
              label=""
              autoComplete="off"
              options={filter.options}
              onChange={(opt) => {
                onChangeValue(opt.code,opt.code, opt.libelle)
              }}
            />
          </>
        );
        break;

      case BOOL_TYPE:
        res = (
          <div className="flex">
            <label
              htmlFor={input.code}
              className="capitalize text-sm font-medium text-gray-700 pr-2"
            >
              Status :
            </label>
            Toggle Component
            {/* <Toggle onChange={onChangeValue} value={inputValue} /> */}
          </div>
        );
        break;

      case EMPTY_TYPE:
        res = <></>;
        break;

      default:
        res = (
          <>
            <input
              type={type}
              className="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md text-gray-700"
              autoFocus={true}
              name={input.code}
              defaultValue={inputValue}
              onChange={(event) =>
                onChangeValue(event.target.value, input.code,null)
              }
            //register={() => null}
            //errors={null}
            />
            {/* {showLabel && (
              <label
                htmlFor={input.code}
                className=" capitalize block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                {input.label} :
              </label>
            )}
            <Input
              autoFocus={true}
              name={input.code}
              type={type}
              value={inputValue}
              onChange={(event) =>
                onChangeValue(event.target.value, input.code)
              }
              register={() => null}
              errors={null}
            /> */}
          </>
        );
        break;
    }

    return res;
  };

  return getInput(operator, type);
}

export default OperatorInput;
