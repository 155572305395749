import React from 'react'
import { usePagination } from '../../Hooks/usePagination'
import PaginationContainer from './PaginationContainer'

type PaginationType = {
  idTable: string
  onPaginate: (page: number, size: number) => void
  currentPage: number,
  totalCount: number,
  pageSize: number,
  siblingCount?: number,
  optionsPageSize: number[],
}

function Pagination({ idTable, onPaginate, currentPage, totalCount, siblingCount, optionsPageSize, pageSize }: PaginationType) {

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  return (
    <PaginationContainer
      idTable={idTable}
      currentPage={currentPage}
      paginationRange={paginationRange}
      optionsPageSize={optionsPageSize}
      pageSize={pageSize}
      nbResultats={totalCount}
      onNext={() => { onPaginate(pageSize, currentPage + 1) }}
      onPrevious={() => { onPaginate(pageSize, currentPage - 1) }}
      onGoToPage={(page) => { onPaginate(pageSize, page) }}
      onSelectPageSize={(pageSize) => { onPaginate(pageSize, currentPage) }}
    />
  )

}

export default Pagination