import React from "react"
import { BadgeCc } from "react-bootstrap-icons"
import Tooltip from "../../Tooltip/Tooltip"
import TagsInput from "../../TagsInput"
type CCModuleType = {
  tooltipContent: string,
  inputPlaceholder: string
}
export default function CCModule({ tooltipContent, inputPlaceholder }: CCModuleType) {
  return <>
    <div className="flex items-start space-x-2">
      <Tooltip
        button={
          <div className="flex items-center justify-center w-8 h-7 bg-gray-100 p-2 rounded-md"><BadgeCc className="w-6 h-6 text-gray-500 " /></div>
        }
        content={<div className="text-xs">{tooltipContent}</div>}
        withArrow
      />

      <div className="w-full">
        <TagsInput placeholder={inputPlaceholder} type="email" inputClassName="w-[155px]" />
      </div>
    </div>
  </>
}