import clsx from 'clsx';
import React from 'react'
import Accordion from '../../../Components/Accordion/Accordion'
import AccordionItemBody from '../../../Components/Accordion/AccordionBody';
import AccordionItemHeader from '../../../Components/Accordion/AccordionHeader';
import AccordionItemHeaderButton from '../../../Components/Accordion/AccordionHeaderButton';
import AccordionItem from '../../../Components/Accordion/AccordionItem';
import Produit from './Produit';


type NiveauType = {
  datas: Array<any>,
  openAllAccordions: boolean,
  withOutVente: boolean
}

// Préparation de la classe d'affichage selon le niveau
const prepareClasse = (numNiveau, numClass) => {

  let classNiveau = '';

  switch (numNiveau) {
    case 0:
      switch (numClass) {
        case 1:
          classNiveau = 'flex items-center justify-between bg-gray-50 border border-gray-300 rounded-md mb-2 mt-2';
          break;
        case 2:
          classNiveau = 'text-sm font-semibold py-2 px-2 text-primary-600 uppercase';
          break;
        case 3:
          classNiveau = 'text-primary-600 ml-2 flex items-center'
          break;
      }
      break;
    case 1:
      switch (numClass) {
        case 1:
          classNiveau = 'flex items-center justify-between rounded-md';
          break;
        case 2:
          classNiveau = 'text-sm font-semibold py-2 px-2 text-secondary-500';
          break;
        case 3:
          classNiveau = 'font-bold text-secondary-500 ml-2 flex items-center'
          break;
      }
      break
    default:
      switch (numClass) {
        case 1:
          classNiveau = 'flex items-center justify-between rounded-md';
          break;
        case 2:
          classNiveau = 'text-sm font-semibold py-2 px-2 text-gray-800';
          break;
        case 3:
          classNiveau = 'text-gray-800 ml-2 flex items-center';
          break;
      }
  }

  return classNiveau;
}

export default function Niveau({ datas, openAllAccordions = true, withOutVente }: NiveauType) {

  return (
    <>
      {datas?.filter((d) => (d.lignes.length > 0)).map((group, ind) => (
        <div key={ind}>
          {
            (withOutVente && group.mode == "Vente") ? <></> :
              <Accordion
                allowItemCollapse
                allowMultipleExpanded
                preExpanded={openAllAccordions ? [1] : [0]}
              >
                <AccordionItem>
                  <AccordionItemHeader>

                    <div className={prepareClasse(group.numSection, 1)}>
                      <AccordionItemHeaderButton className={prepareClasse(group.numSection, 2)}>
                        <div className={prepareClasse(group.numSection, 3)}>
                          {group.nomProduit}
                        </div>
                      </AccordionItemHeaderButton>
                    </div>
                  </AccordionItemHeader>
                  <AccordionItemBody>
                    <div className={clsx(`border-l border-gray-300 border-dashed ml-4`)}>
                      <Accordion
                        allowItemCollapse
                        allowMultipleExpanded
                        preExpanded={openAllAccordions ? [1] : [0]}
                      >
                        <div className="text-sm text-gray-600 truncate">
                          <Niveau withOutVente={withOutVente} datas={group.lignes} openAllAccordions={openAllAccordions} />
                          {group.lignes.filter((e) => (e.lignes.length === 0)).length > 0 && <div className='pl-4 pb-4'><Produit withOutVente={withOutVente} datas={group.lignes.filter((e) => (e.lignes.length === 0))} /></div>}
                        </div>
                      </Accordion>
                    </div>
                  </AccordionItemBody >
                </AccordionItem >
              </Accordion>
          }

        </div>
      ))
      }
    </>
  );

}