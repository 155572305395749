import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import ButtonsGroup from '../../../../Components/ButtonsGroup/ButtonsGroup';
import Table from '../../../../Components/Table/Table'
import { useP } from '../../../../services/i18n';
import { PrestationsViewType } from '../../PrestationsType';
import { GET_NDI } from '../../queries';
import PrestationDetailModaleNDI from './PrestationDetailModaleNDI';

export default function NDIView({ siteId }: PrestationsViewType) {
  // Detail Prestation
  const [openModaleDetailPrestation, setOpenModaleDetailPrestation] = useState(false)
  const [modalePrestation, setModalePrestation] = useState(null)

  const onOpenModaleDetailPrestation = (row) => {
    setModalePrestation(row)
    setOpenModaleDetailPrestation(true)
  }
  const onCloseModaleDetailPrestation = () => {
    setOpenModaleDetailPrestation(false)
  }
  const editRow = (row) => { onOpenModaleDetailPrestation(row) }

  const onSaveNdi = (ndi) => {
    const newTableDatas = [...tableDatas]
    const ndiIndex = newTableDatas.findIndex((e) => e.ndi == ndi.ndi)
    if (ndiIndex >= 0) {
      const ndiUpdate = { ...newTableDatas[ndiIndex], ...ndi }
      newTableDatas[ndiIndex] = ndiUpdate
      setTableDatas(newTableDatas)
    }
  }

  // Table Datas
  const { loading, data } = useQuery(GET_NDI, {
    variables: {
      idSite: siteId,
    },
  });

  const p = useP();

  const columns = [
    {
      libelle: p.t('prestations.ndi.columns.etat'),
      code: 'etat',
      colType: 'state',
    },
    {
      libelle: p.t('prestations.ndi.columns.site'),
      code: 'site.nom',
      colType: 'adresse',
      formatterObjectCode: 'site',
      sortable: true
    },
    {
      libelle: p.t('prestations.ndi.columns.numero'),
      code: 'numero',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.ndi.columns.nom'),
      code: 'nom',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.ndi.columns.international'),
      code: 'international',
      colType: 'boolean',
      sortable: true
    },
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: editRow,
      resizable: false
    },
  ]

  const filterButtons = [
    { libelle: p.t('etat.prestation.EN SERVICE'), value: "EN SERVICE" },
    { libelle: p.t('etat.prestation.INITIEE'), value: "INITIEE" },
    { libelle: p.t('etat.prestation.COMMANDE EN COURS'), value: "COMMANDE EN COURS" },
    { libelle: p.t('etat.prestation.COMMANDE REJETEE'), value: "COMMANDE REJETEE" },
    { libelle: p.t('etat.prestation.RESILIEE'), value: "RESILIEE" },
  ]

  const [tableDatas, setTableDatas] = useState<any[]>([]);
  const [filterEtatPresta, setFilterEtatPresta] = useState<string | string[]>(["EN SERVICE", "INITIEE", "COMMANDE EN COURS"]);

  useEffect(() => {
    let datas = data ? data.prestationNdis : [];
    if (filterEtatPresta) {
      datas = datas.filter((d) => filterEtatPresta.includes(d.etat))
    }

    setTableDatas(datas)
  }, [data, filterEtatPresta]);

  return <>
    <Table
      idTable='table-ndi'
      loading={loading}
      datas={tableDatas.map((d) => ({
        ...d,
        etat: {
          code: d.etat,
          libelle: p.t(`etat.prestation.${d.etat}`),
          colorClasses: `bg-${d.etatColor}-200 text-${d.etatColor}-800`
        }
      }))}
      columns={columns}
      customToolbarActions={
        <>
          <div className=''>
            <ButtonsGroup
              onSelectedButton={(value) => setFilterEtatPresta(value)}
              buttons={filterButtons}
              selectedButtons={filterEtatPresta}
              multi={true}
            />
          </div>
        </>
      }
      onClickRow={(row) => onOpenModaleDetailPrestation(row)}
      options={{
        search: {
          placeholder: p.t('prestations.searchPlaceholder'),
        },
      }}
    />

    <PrestationDetailModaleNDI
      prestation={modalePrestation}
      isOpen={openModaleDetailPrestation}
      onClose={onCloseModaleDetailPrestation}
      onSave={(onSaveNdi)}
    />
  </>

}