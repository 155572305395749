import React from 'react'

export default function ShellLayout(props: { children: JSX.Element | JSX.Element[] }) {

  return (
    <>
      <div className="py-2 px-2 md:py-6 md:px-6 flex-1  overflow-y-auto h-full">
        <div className="h-full flex flex-col">
          {props.children}
        </div>
      </div>
    </>
  )
}
