import React, { useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx';
import WidgetList from '../WidgetList';
import { useP } from '../../../services/i18n';

type WidgetSelectionModaleBodyType = {
  widgets: Array<any>,
  value: string,
  onChange: (code: string) => void
}

export default function WidgetSelectionModaleBody({ widgets = [], value, onChange }: WidgetSelectionModaleBodyType) {

  const p = useP();

  useEffect(() => {
    onChange(widgets[0])
  }, [])

  return (
    <div className='flex flex-col h-full'>
      <RadioGroup className="pb-4 h-full w-full" value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only"> Server size </RadioGroup.Label>
        <div className="space-y-4 h-full w-full">
          {
            widgets.length > 0
              ? (
                widgets.map((widget) => (
                  <RadioGroup.Option
                    key={widget.code}
                    value={widget}
                    className={({ checked, active }) =>
                      clsx(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                        'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <div className='flex w-full'>
                        <span className="flex w-1/3 h-auto items-start">
                          <img src={WidgetList[widget.code]?.imageUrl} className='w-full border border-grey-300 shadow-sm rounded-lg p-2' />
                        </span>
                        <RadioGroup.Description
                          as="span"
                          className="mt-2 flex w-2/3 text-sm sm:mt-0 sm:ml-4 sm:flex-col"
                        >
                          <div className="font-medium text-gray-900">{widget.libelle}</div>
                          <div className="ml-1 text-gray-500 sm:ml-0 text-justify">{widget.description}</div>
                        </RadioGroup.Description>
                        <span
                          className={clsx(
                            active ? 'border' : 'border-2',
                            checked ? 'border-primary-500' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </RadioGroup.Option>
                ))
              )
              : (
                <div className='h-full w-full flex justify-center items-center'>
                  {p.t('widgets.selection.noMoreItems')}
                </div>
              )
          }
        </div>
      </RadioGroup>


    </div>
  )
}