/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react'
import Map, {
  Popup,
  NavigationControl,
  FullscreenControl,
  MapRef,
  Source,
  Layer,
  GeoJSONSource,
  LayerProps
} from 'react-map-gl';

import MapboxLanguage from '@mapbox/mapbox-gl-language';
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapBox.css';
import MapLegend from './MapLegend';
import bbox from '@turf/bbox';
import ResetZoom from './ResetZoom';
import { useP } from '../../services/i18n';
import { Point } from 'mapbox-gl';

const TOKEN = 'pk.eyJ1IjoiYnQtYmx1ZS1kc2kiLCJhIjoiY2w3aHNkdnoyMDA1czNvbWdtNmp6bnhmbiJ9.gB28ZdzGq4TPFWzKqMsM8A';



type MapBoxClustersType = {
  datas: any,
  mapStyle?: string,
  sourceId: string,
  clusterLayer: LayerProps,
  clusterCountLayer: LayerProps,
  unclusteredPointLayer: LayerProps,
  clusterProperties?: any,
  legend?: JSX.Element,
  setPopUpProperties: (datas: any) => void,
  popUpContent: any,
  disableScrollZoom?: boolean
}

export default function MapBoxClusters({
  datas,
  mapStyle = "mapbox://styles/mapbox/streets-v11",
  sourceId,
  clusterProperties,
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  legend = <></>,
  popUpContent,
  setPopUpProperties,
  disableScrollZoom = false

}: MapBoxClustersType) {
  const p = useP();
  const [thisMap, setThisMap] = useState<any>(null);
  const mapRefCallback = useCallback(
    (ref: MapRef | null) => {
      if (ref !== null) {
        const map = ref;
        const language = new MapboxLanguage();
        map.addControl(language); // on set la langue de la carte en fonction de celle du navigateur
      }
    },
    [],
  )


  const getBounds = (mapDatas) => {
    const features = mapDatas?.features;
    const coords: any = [];
    features?.forEach((feature) => {
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);
      coords.push({ minLng: minLng, minLat: minLat, maxLng: maxLng, maxLat: maxLat })
    })
    if (coords.length > 0) {
      const calcMinLng = coords.reduce(function (prev, current) { return (prev.minLng < current.minLng) ? prev : current })
      const calcMinLat = coords.reduce(function (prev, current) { return (prev.minLat < current.minLat) ? prev : current })
      const calcMaxLng = coords.reduce(function (prev, current) { return (prev.maxLng > current.maxLng) ? prev : current })
      const calcMaxLat = coords.reduce(function (prev, current) { return (prev.maxLat > current.maxLat) ? prev : current })
      return [[calcMinLng.minLng, calcMinLat.minLat], [calcMaxLng.maxLng, calcMaxLat.maxLat]]
    }
  }

  const [viewState, setViewState] = useState<any>(null);
  let initialViewState: { bounds: any[][] | undefined; maxZoom: number; fitBoundsOptions: { padding: number; }; bearing: number; pitch: number; };
  useEffect(() => {
    if (datas?.features) {
      const initialBounds = datas.features.length > 0 ? getBounds(datas) : [[2.386742438323852, 46.79270678499469], [2.386742438323852, 46.79270678499469]]; // centrage France si pas de datas
      const maxZoom = datas.features.length > 0 ? 17 : 4;
      initialViewState = {
        bounds: initialBounds,
        maxZoom: maxZoom,
        fitBoundsOptions: {
          padding: 30
        },
        bearing: 0,
        pitch: 0
      }
      setViewState(initialViewState)
    }
  }, [datas])

  const [popupInfo, setPopupInfo] = useState<any>(null);



  const onClick = event => {
    const feature = event.features[0];
    if (feature) {
      if (feature?.properties.cluster) {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = event.target.getSource(sourceId) as GeoJSONSource;
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }
          event.target.easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 500
          });
        });
      } else {
        const [minLng, minLat, maxLng, maxLat] = bbox(feature);
        const cameraBounds = event.target.cameraForBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          {
            maxZoom: event.target.getZoom(),
            offset: [-166, 100],
            padding: 10
          }
        );
        cameraBounds && event.target.easeTo(cameraBounds);
        setPopUpProperties(feature)
        setPopupInfo(feature)
      }
    }
  };

  const onMouseMove = event => {
    const feature = event.features[0];
    if (feature) {
      event.target.getCanvas().style.cursor = 'pointer'
    } else {
      event.target.getCanvas().style.cursor = 'grab'
    }
  }

  const resetZoom = () => {
    thisMap?.fitBounds(viewState.bounds, { offset: [0, 0], padding: 30, maxZoom: 17 })
    setPopupInfo(null)
  }

  return <>
    <div className='w-full h-full flex flex-col flex-1 rounded-md relative'>
      {viewState &&
        <Map
          {...viewState}
          mapStyle={mapStyle}
          style={{ width: '100%', height: '100%', flex: '0 0 1' }}
          mapboxAccessToken={TOKEN}
          ref={mapRefCallback}
          interactiveLayerIds={(clusterLayer.id && unclusteredPointLayer.id) ? [clusterLayer.id, unclusteredPointLayer.id] : undefined}
          onClick={onClick}
          onMouseMove={onMouseMove}
          onLoad={(event) => {
            setThisMap(event.target);
          }}
          cooperativeGestures={disableScrollZoom}
          locale={{
            "AttributionControl.ToggleAttribution": p.t('map.AttributionControl.ToggleAttribution'),
            "AttributionControl.MapFeedback": p.t('map.AttributionControl.MapFeedback'),
            "FullscreenControl.Enter": p.t('map.FullscreenControl.Enter'),
            "FullscreenControl.Exit": p.t('map.FullscreenControl.Exit'),
            "GeolocateControl.FindMyLocation": p.t('map.GeolocateControl.FindMyLocation'),
            "GeolocateControl.LocationNotAvailable": p.t('map.GeolocateControl.LocationNotAvailable'),
            "LogoControl.Title": p.t('map.LogoControl.Title'),
            "Map.Title": p.t('map.Map.Title'),
            "NavigationControl.ResetBearing": p.t('map.NavigationControl.ResetBearing'),
            "NavigationControl.ZoomIn": p.t('map.NavigationControl.ZoomIn'),
            "NavigationControl.ZoomOut": p.t('map.NavigationControl.ZoomOut'),
            "ScrollZoomBlocker.CtrlMessage": p.t('map.ScrollZoomBlocker.CtrlMessage'),
            "ScrollZoomBlocker.CmdMessage": p.t('map.ScrollZoomBlocker.CmdMessage'),
            "TouchPanBlocker.Message": p.t('map.TouchPanBlocker.Message'),
          }}
        >

          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" showCompass={false} />

          <Source
            id={sourceId}
            type="geojson"
            data={datas}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
            clusterProperties={clusterProperties}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
          </Source>

          {popupInfo && (
            <Popup
              anchor="bottom-left"
              offset={new Point(0, -14)}
              longitude={Number(popupInfo.geometry.coordinates[0])}
              latitude={Number(popupInfo.geometry.coordinates[1])}
              onClose={() => setPopupInfo(null)}
              maxWidth="332px"
            >
              {popUpContent}
            </Popup>
          )}
        </Map>
      }
      <MapLegend JSXLegendComponent={legend} />
      <ResetZoom onClick={() => resetZoom()} />
    </div>
  </>

}