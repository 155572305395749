import React from 'react';
import Button from '../../Components/Button';
import Modal from '../../Components/Modal/Modal';
import { useP } from '../../services/i18n';
import ProfilModaleBody from './ProfilModaleBody';

type ProfilModaleType = {
  isOpen?: boolean,
  onClose: () => void
}

function ProfilModale({ isOpen = false, onClose }: ProfilModaleType) {

  const p = useP();

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('userNavigation.profil.label')}</div>
            </div>
          </>
          ,
          body: <ProfilModaleBody />,
          footer: <><div className='flex items-center justify-end'>
            <Button onClick={onClose}>
              {p.t('actions.fermer')}
            </Button>
          </div></>,
        }}
        className="w-full lg:w-1/3 lg:h-3/5"
      />
    </>
  )
}

export default ProfilModale