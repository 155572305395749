import React, { useEffect, useState } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { motion } from "framer-motion"
import Button from '../../../../Components/Button'
import PopUpInfoMapLienItem from './PopUpInfoMapLienItem'
type infosPopUpType = {
  properties: any
}

type PopUpInfoMapLienType = {
  popupInfo: infosPopUpType
}

type popupInfosLiensType = {
  id: number,
  prestationId: number,
  libelle: string,
  numeroRaccordement: string,
  date: Array<string>,
  statut: string,
  logins: Array<string>,
  loginStates: Array<boolean>,
  loginSupervisionActives: Array<boolean>
}

export type popupInfosType = {
  nom: string,
  numeroVoie: string,
  libelleVoie: string,
  commune: string,
  complementVoie: string,
  codePostal: string,
  statut: string,
  liens: Array<popupInfosLiensType>,
}

export default function PopUpInfoMapLien({ popupInfo }: PopUpInfoMapLienType) {
  const [datas, setDatas] = useState([])
  useEffect(() => {
    setDatas(JSON.parse(popupInfo.properties.sites))
  }, [])

  const [[page, direction], setPage] = useState([0, '']);

  const variants = {
    enter: () => {
      return {
        x: direction == 'prev' ? 25 : -25,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    }
  };

  const paginate = (newDirection: number, direction: string) => {
    if (page + newDirection < datas?.length && page + newDirection != -1) {
      setPage([page + newDirection, direction]);
    }
  };

  return <>
    {popupInfo &&
      <div>
        <div className='text-sm text-gray-700 relative'>
          {
            datas?.map((item: popupInfosType, index) => {
              if (page === index) {
                return <motion.div
                  key={index}
                  className='rounded-md h-full font-medium w-[284px]'
                  custom={index}
                  variants={variants}
                  initial="enter"
                  animate="center"
                >
                  <PopUpInfoMapLienItem item={item} />
                </motion.div>
              }
            })
          }
        </div>

        {
          datas.length > 1 &&
          <div className='flex items-center justify-between w-full mt-2'>
            <div className='font-normal text-sm text-gray-700'>{page + 1}/{datas.length}</div>
            <div className='flex tems-center'>
              <div className='mr-2'><Button size='small' className='' color='primaryLight' onClick={() => paginate(-1, "prev")}><ChevronLeftIcon className='h-4 w-4' /></Button></div>
              <div className=''><Button size='small' className='' color='primaryLight' onClick={() => paginate(1, "next")}><ChevronRightIcon className='h-4 w-4' /></Button></div>
            </div>
          </div>
        }
      </div>
    }
  </>
}