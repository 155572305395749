import clsx from "clsx"
import React from "react"
import DescriptionListItem from "./DescriptionListItem"

export type DescriptionListItemAlignType = "center" | "top" | "bottom" | undefined

export type DescriptionListItemType = {
  label: string | JSX.Element,
  value: string | JSX.Element,
  align?: DescriptionListItemAlignType
}


type DescriptionListType = {
  datas: Array<DescriptionListItemType>,
  columnWidthClassName?: string | null,
  bordered?: boolean
}

export default function DescriptionList({ datas, columnWidthClassName = null, bordered = false }: DescriptionListType) {
  return <>
    <div className={
      clsx(
        bordered && `border border-gray-200 rounded-md`
      )
    }>
      <div className="">
        <dl className="">
          {
            datas.map((item, i) => (
              item &&
              <DescriptionListItem bordered={bordered} last={i + 1 == datas.length} item={item} key={i} columnWidthClassName={columnWidthClassName} />
            ))
          }
        </dl>
      </div>
    </div>
  </>
}