import { useLazyQuery, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import ModalFooterAction from '../../../Components/Modal/ModalFooterAction'
import { MultiSelectOptionType } from '../../../Components/MultiSelect/Types/MultiSelectOptionType'
import Spinner from '../../../Components/Spinner'
import { useP } from '../../../services/i18n'
import { DataDonneeReferenceModaleType } from '../../../types/ContactType'
import { GET_CONTACT, GET_ROLES_CONTACT } from '../queries'
import ModalAddRoleBody from './ModalAddRoleBody'


type DataDonneeReferenceTypeRolesType = {
  descriptif: string,
  isMulticompte: boolean,
  saisieSite: boolean,
  saisieContrat: boolean,
  libelle: string,
  code: string
}

type DataDonneeReferenceTypeRolesObjectType = {
  typeRoles: Array<DataDonneeReferenceTypeRolesType>
}

type ModalAddRoleType = {
  isOpen: boolean,
  onClose: () => void,
  onClickAction: () => void,
  selectedValues: (values: Array<any>) => void,
  idContact?: number | null,
  dataDonneesReference: DataDonneeReferenceTypeRolesObjectType,
  rowMulticompte: (checked: boolean, row: any) => void,
  multiCompte: boolean
}

export default function ModalAddRole({ isOpen = false, onClose, onClickAction, selectedValues, idContact, dataDonneesReference, rowMulticompte, multiCompte }: ModalAddRoleType) {
  const p = useP();

  const [rolesContact, setRolesContact] = useState<any>([]);
  const [contact, setContact] = useState<any>({ prenom: '', nom: '' });

  const filterRoles = (selectedRolesContact) => {
    // On filtre les roles en supprimant ceux déjà selectionnés
    const rolesContactCodes = selectedRolesContact.map((role) => role.typeRole.code)
    const dataRoles = dataDonneesReference?.typeRoles;
    const rolesToDeleteSet = new Set(rolesContactCodes);
    const finalRoles = dataRoles.filter((item) => {
      return !rolesToDeleteSet.has(item.code);
    });
    setRolesContact(finalRoles)
  }

  const [getRoles, { loading: loadingRolesContact, data: dataRolesContact }] = useLazyQuery(GET_ROLES_CONTACT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      filterRoles(data.roleContacts)
    }
  });
  const loadRoles = () => {
    getRoles({
      variables: {
        idContact: idContact,
      },
    });
  };

  const [getContact, { loading: loadingContact, data: dataContact }] = useLazyQuery(GET_CONTACT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setContact(data.contact.items[0])
    }
  });
  const loadContact = () => {
    getContact({
      variables: {
        idContact: idContact,
      },
    });
  };

  useEffect(() => {
    if (isOpen && idContact) {
      loadRoles();
      loadContact();
    }
  }, [isOpen]);

  return <>
    <Modal
      onClose={() => onClose()}
      noPadding={true}
      isOpen={isOpen}
      content={{
        title: <div className='flex items-center'>
          <div>{p.t('contacts.modalAddRoleTitle')}</div>
          {(!loadingContact) && <div className='ml-2'>{contact.prenom} {contact.nom}</div>
          }
        </div>,
        body: <>
          {(loadingRolesContact) ? <div className='flex items-center justify-center'><Spinner /></div>
            : <ModalAddRoleBody datas={rolesContact} selectedValues={selectedValues} rowMulticompte={rowMulticompte} multiCompte={multiCompte} />
          }
        </>,
        footer: <>
          {
            loadingRolesContact ?
              <></>
              :
              (rolesContact.length > 0) ?
                <ModalFooterAction label={p.t('actions.ajouter')} color='primary' onClickAction={onClickAction} />
                : <ModalFooterAction label={p.t('actions.annuler')} color='primary' onClickAction={() => onClose()} />
          }</>,
      }}
      className="w-full lg:w-2/6"
    />
  </>


}