import React from 'react'
import { FichiersType } from '../Discussions'
import Tooltip from '../../Tooltip/Tooltip'
import { FileEarmarkExcelFill, FileEarmarkFill, FileEarmarkImageFill, FileEarmarkPdfFill, FileEarmarkTextFill, FileEarmarkWordFill } from 'react-bootstrap-icons'
import { DocumentNode, useLazyQuery } from '@apollo/client'
import { toastError } from '../../Utils/Utils'
import Constants from '../../../commons/Constants'
import { useP } from '../../../services/i18n'
import Spinner from '../../Spinner'


type AttachmentType = {
  fichier: FichiersType
  queryDownload: DocumentNode
}

export default function Attachment({ fichier, queryDownload }: AttachmentType) {

  const {
    FILER_URL
  } = Constants;

  const p = useP();

  // Upload Mutation
  const [downloadPj, { loading: loadingPj }] = useLazyQuery(queryDownload, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      telechargerTicketPieceJointeId: fichier.id
    },
    onCompleted: async (data) => {
      const result = Object.values(data)
      if (result[0]) {
        const { hash, filename } = data.telechargerTicketPieceJointe
        window.open(`${FILER_URL}?hash=${hash}&filename=${filename}`)
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  let icon;
  switch (fichier.extension.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      icon = <FileEarmarkImageFill className="h-8 w-8 text-teal-600" />
      break;
    case "csv":
    case "xls":
    case "xlsx":
      icon = <FileEarmarkExcelFill className="h-8 w-8 text-teal-600" />
      break;
    case "doc":
    case "docx":
    case "rtf":
      icon = <FileEarmarkWordFill className="h-8 w-8 text-teal-600" />
      break;
    case "pdf":
      icon = <FileEarmarkPdfFill className="h-8 w-8 text-red-700" />
      break;
    case "txt":
      icon = <FileEarmarkTextFill className="h-8 w-8 text-gray-700" />
      break;
    default:
      icon = <FileEarmarkFill className="h-8 w-8 text-secondary-300" />
      break;
  }
  return <>

    <div className="mt-0">
      <Tooltip
        button={
          <div onClick={() => downloadPj()} className="flex space-x-1 border border-black/20 p-2 rounded-md text-sm bg-white cursor-pointer hover:bg-gray-50">
            {loadingPj ? <div className='flex items-center justify-center h-8 w-8'><Spinner size='h-5 w-5 top-[0px]' /></div> : icon}
            <div className=''>
              <div className="max-w-[150px] text-xs font-medium text-gray-600 truncate">{fichier.name}</div>
              <div className='flex items-center space-x-1'>
                <div className="text-xs text-gray-400 uppercase">{fichier.extension}</div>
                <div className='bg-gray-300 h-1 w-1 rounded-full'></div>
                <div className="text-xs text-gray-400">{fichier.date}</div>
              </div>
            </div>
          </div>
        }
        content={<div className="text-xs">{fichier.name}</div>}
        withArrow
      />
    </div>

  </>
}