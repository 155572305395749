import React, { useEffect, useState } from "react";
import clsx from "clsx";

import {
  OPERATOR_INTERVALLE,
  OPERATOR_MAX,
  OPERATOR_MIN,
} from "../../../datas/Operators";

import OperatorInput from "./OperatorInput";
import OperatorSelect from "./OperatorSelect";
import { filterTypes, operatorSelectedType, operatorType } from "../Types/filterTypes";

type OperatorType = {
  op: operatorSelectedType,
  pos: string,
  id: string | number,
  filter: filterTypes,
  changeOperatorValue: (values: any) => void,
  handleOperatorChange: (id: number | string, op: operatorSelectedType) => void,
  checked: boolean,
}

function Operator({
  op,
  pos,
  id,
  filter,
  changeOperatorValue,
  handleOperatorChange,
  checked = true,
}: OperatorType) {
  const { operator } = op;

  const onSelectOperator = () => {
    if (!checked) {
      handleOperatorChange(id, op);
    }
  };

  const renderInputs = () => {
    if (op.items) {
      return op.items.map((item, index) => (
        <div key={index} className="grid grid-col-2 grid-flow-col mt-1">
          {renderInput(item, op.type, filter, op.operator, true)}
        </div>
      ));
    }

    return renderInput(op.operator, op.type, filter);
  };

  const renderInput = (operator: operatorType, type: string, filter: filterTypes, parent?: any | null, showLabel = false) => {
    return (
      <OperatorInput
        operator={operator}
        type={type}
        filter={filter}
        changeOperatorValue={onChangeValue}
        showLabel={showLabel}
        parent={parent}
      />
    );
  };

  const onChangeValue = (operator, value, parent) => {
    let newValue = value;
    let operatorCode = operator;

    if (parent?.code === OPERATOR_INTERVALLE.code) {
      let minValue = values.get(OPERATOR_MIN.code);
      let maxValue = values.get(OPERATOR_MAX.code);
      const btwValue = values.get(OPERATOR_INTERVALLE.code);

      const index = operator === OPERATOR_MAX.code ? 1 : 0;

      if (!value?.value) {
        values.delete(operator);
        values.delete(OPERATOR_INTERVALLE.code);

        if (btwValue) {
          const recover = btwValue.value[index == 1 ? 0 : 1];
          const op = index ? OPERATOR_MIN : OPERATOR_MAX;
          newValue = {
            ...btwValue,
            operator: op,
            value: recover,
            libValue: recover,
          };

          values.set(op.code, newValue);
        }
      } else {
        if (btwValue) {
          newValue = btwValue;
          newValue.value[index] = value.value;
          operatorCode = OPERATOR_INTERVALLE.code;
        } else {
          if (operator === OPERATOR_MAX.code) {
            maxValue = value;
          } else {
            minValue = value;
          }

          if (minValue && maxValue) {
            const val = [minValue.value, maxValue.value];

            newValue = {
              ...value,
              operator: OPERATOR_INTERVALLE,
              value: val,
              libValue: val,
            };

            operatorCode = OPERATOR_INTERVALLE.code;
            values.delete(OPERATOR_MIN.code);
            values.delete(OPERATOR_MAX.code);
          }
        }
        values.set(operatorCode, newValue);
      }
    } else if (value?.value || filter.colType === "bool") {
      values.set(operatorCode, newValue);
    } else {
      values.delete(operatorCode);
    }

    setValues(new Map(values));
  };

  const [values, setValues] = useState(new Map());

  useEffect(() => {
    changeOperatorValue(values);
  }, [values]);

  return (
    <div
      key={operator?.code}
      //value={op}
      className={clsx(
        "relative border p-4 flex flex-col cursor-pointer focus:outline-none",
        "transition duration-500 ease-in-out ",
        ["first", "unique"].includes(pos) ? "rounded-tl-md rounded-tr-md" : "",
        ["last", "unique"].includes(pos) ? "rounded-bl-md rounded-br-md" : "",
        checked ? "bg-primary-50 border-primary-200 z-10" : "border-gray-200"
      )}
      onClick={onSelectOperator}
    >
      {pos != "unique" && (
        <OperatorSelect
          id={id}
          checked={checked}
          onSelectOperator={onSelectOperator}
          operator={operator}
          label={op.label}
        />
      )}
      {checked && renderInputs()}
    </div>
  );
}

export default Operator;
