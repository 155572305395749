import React from 'react'

type CellMoneyFormatterType = {
  value: number,
}

export default function CellMoneyFormatter({ value }: CellMoneyFormatterType) {
  const valMoney = value || 0;
  return <>
    <p className='text-right'>
      {valMoney.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
    </p>
  </>
}