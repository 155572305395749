import React from "react";
import { TicketType } from "../types/TicketType";
import TicketPublicHeader from "./TicketPublicHeader";
import TicketPublicDescription from "./TicketPublicDescription";
import TicketPublicCommentaires from "./TicketPublicCommentaires";

type TicketPublicContainerType = {
  ticket: TicketType,
}

function TicketPublicContainer({ ticket }: TicketPublicContainerType) {

  return <div className="flex flex-col h-full">
    <TicketPublicHeader ticket={ticket} />

    <div className='flex flex-1 flex-col-reverse lg:flex-row lg:overflow-hidden h-full'>
      <div className='flex-1 lg:border-r lg:border-gray-300 flex flex-col overflow-hidden'>
        <TicketPublicCommentaires ticket={ticket} />
      </div>
      <div className='text-sm text-gray-700 lg:w-1/3 flex flex-col' >
        <TicketPublicDescription ticket={ticket} />
      </div>
    </div>
  </div>

}

export default TicketPublicContainer;

