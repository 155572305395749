import React from "react"
import Modal from "../../Modal/Modal"
import Button from "../../Button"
import ModaleDetailTPBody from "./ModaleDetailTPBody"
import { useP } from "../../../services/i18n"


type ModaleDetailTP = {
  row: any,
  isOpen: boolean,
  onClose: () => void,
}

export default function ModaleDetailTP({
  row,
  isOpen,
  onClose }: ModaleDetailTP) {

  const p = useP()
  return <>
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      content={{
        title: <div>
          <div className="text-primay-900">{row?.nature} {row?.referenceFournisseur}</div>
          <div className="text-base text-gray-500">{p.t('supervisionLien.historiqueTP.modaleDetailTitle')}</div>
        </div>,
        body: <div><ModaleDetailTPBody row={row} /></div>,
        footer: <div className='flex items-center justify-end'>
          <Button onClick={onClose}>
            {p.t('actions.fermer')}
          </Button>
        </div>,
      }}
      className="w-full lg:w-1/3"
      noPadding
    />
  </>
}