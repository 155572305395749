import React, { useState } from 'react'
import Constants from '../../../../../../commons/Constants'
import MultiSelect from '../../../../../../Components/MultiSelect/MultiSelect'
import { MultiSelectOptionType } from '../../../../../../Components/MultiSelect/Types/MultiSelectOptionType'
import { useP } from '../../../../../../services/i18n'

const {
  SAISIE_CONTRAT,
  SAISIE_SITE
} = Constants;

type ModalAddRoleScopeBodyType = {
  selectedValues: (values: Array<MultiSelectOptionType>) => void,
  datas: Array<MultiSelectOptionType>,
  scope?: string | null
}

export default function ModalAddRoleScopeBody({ selectedValues, datas, scope }: ModalAddRoleScopeBodyType) {
  const p = useP();
  //const selectedValues = (values) => console.log(values)


  const placeholderMultiselect = scope == SAISIE_SITE ? p.t('contacts.sites.recherche') : p.t('contacts.contrats.recherche')

  return <>
    <MultiSelect
      datas={datas}
      placeholder={placeholderMultiselect}
      alwaysVisible={true}
      onSelect={selectedValues}
      searchBoxClassName=''
      dropdownClassName='h-full'
    />
  </>


}