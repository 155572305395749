import clsx from 'clsx'
import React from 'react'
import MultiSelectOptions from './MultiSelectOptions'
import { MultiSelectOptionType } from './Types/MultiSelectOptionType'

type MultiSelectContainerType = {
  datas: Array<MultiSelectOptionType>,
  toggleOption: (val: any) => void,
  selected: Array<MultiSelectOptionType>,
  alwaysVisible?: boolean,
  dropdownClassName?: string
}

export default function MultiSelectContainer({ datas, toggleOption, selected, alwaysVisible, dropdownClassName }: MultiSelectContainerType) {
  return <>
    <div
      className={clsx(`mt-1 bg-white border border-gray-300 rounded-md text-base focus:outline-none sm:text-sm flex flex-col flex-1 overflow-hidden`,
        !alwaysVisible ? `fixed w-auto z-10` : `w-full`,
        dropdownClassName)}
    >
      <MultiSelectOptions datas={datas} toggleOption={toggleOption} selected={selected} />
    </div>
  </>
}