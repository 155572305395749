import React from "react"
import IMG from "../../../HelpBoxComponents/IMG"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentDetailContrats_Q1(){
  const p = useP()
  return <>
    <P>
      <>{parse(p.t('helpBox.helpContrats.helpDetailContrat.a1'))}</>
    </P>    
    <div className="mt-1">
      <IMG src="/images/helpBox/nomContrat_edit.png" sizes={{width:35}} />
    </div>
  </>
}