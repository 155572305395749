import React, { Ref, useEffect, useRef, useState } from "react"
import { dataDiscussionsType } from "./Discussions"
import dayjs from "dayjs"
import clsx from "clsx"
import Attachments from "./Attachments/Attachments"
import { DocumentNode } from "graphql"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import { PaperClipIcon } from "@heroicons/react/24/solid"
import { motion } from 'framer-motion';

type DiscussionType = {
  discussion: dataDiscussionsType,
  queryDownload: DocumentNode,
  maxHeight?: string,
  expandable?: boolean
  isOpen?: boolean,
  onToggle?: () => void,
  reference?: Ref<any>,
  attachmentsHidden?: boolean
}

type ImageZoomType = {
  src: any,
  commentIsOpen: boolean
}
function ImageZoom({ src, commentIsOpen, ...props }: ImageZoomType) {
  const [isOpen, setOpen] = useState(false);
  return <div
    className={`relative w-full`}
  >
    <motion.div
      onClick={() => setOpen(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: isOpen ? 1 : 0 }}
      className={`opacity-0 ${isOpen ? 'fixed inset-0 bg-black/90 cursor-zoom-out z-40' : 'cursor-zoom-in'}`}
    />
    {commentIsOpen &&
      <motion.img
        src={src}
        alt="Zoom Image"
        className={`object-cover inset-0  ${isOpen ? 'w-auto h-auto fixed z-50 max-w-full m-auto cursor-zoom-out' : 'w-auto max-w-full h-full cursor-zoom-in'}`}
        onClick={() => setOpen(!isOpen)}
        layout
        {...props}
      />
    }
  </div>
}

export default function Discussion({ discussion, queryDownload, maxHeight = '', expandable = true, isOpen = false, onToggle, reference, attachmentsHidden = false }: DiscussionType) {

  const [discussionIsOpen, setDiscussionIsOpen] = useState(isOpen);
  const discussionRef = useRef(null);

  useEffect(() => {
    setDiscussionIsOpen(isOpen)
  }, [isOpen])


  const attachClickEventToImages = (node) => {
    if (node?.type === 'img') {
      const clonedProps = { ...node.props };
      clonedProps.src = node.props.src
      clonedProps.commentIsOpen = discussionIsOpen;
      return React.createElement(ImageZoom, clonedProps, null);
    }

    if (node?.props?.children) {
      const children = React.Children.map(node.props.children, attachClickEventToImages);
      return React.cloneElement(node, undefined, children);
    }

    return node;
  };
  return <>
    <div className={clsx(`flex items-start space-x-2`)} ref={reference}>
      <div>
        <div className={clsx(`flex item-center justify-center p-2 text-white rounded-md w-10 h-10 font-medium text-base`, discussion?.colorClassIcon)}>
          <div>{discussion?.auteurIcon}</div>
        </div>
      </div>
      <div className={clsx(`relative flex-1 rounded-md p-2 truncate`, discussion?.bgClass, maxHeight && `overflow-hidden`)}>        
        <div
          className={clsx(`flex items-start space-x-2 truncate`, expandable && `cursor-pointer`)}
          onClick={() => {
            if (expandable) {
              onToggle?.()
              setDiscussionIsOpen(!discussionIsOpen)
            }
          }}
        >
          <div className="bg-white/70 hover:bg-white p-1 rounded-md flex items-center justify-center text-gray-600">
            {discussionIsOpen ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
          </div>
          <div className="flex-1 flex items-center space-x-2 truncate">
            <div className="flex items-baseline space-x-2">
              <div className="text-base font-semibold text-gray-800 hover:text-gray-700">{discussion?.auteur}</div>
              <div className="text-xs text-gray-500 font-normal">{dayjs(discussion?.date).format("DD/MM/YYYY à HH:mm")}</div>
            </div>
            {discussion?.fichiers && discussion?.fichiers.length > 0 &&
              <div className="border border-gray-400 p-1 rounded-full bg-white/70">
                <PaperClipIcon className="h-4 w-4 text-gray-700" />
              </div>
            }
            {!discussionIsOpen && <div className="text-sm truncate italic text-gray-700">{discussion?.contenuOneLine}</div>}
          </div>
          
        
        </div>
        <div
          className={clsx(
            `text-gray-700 text-sm mt-2 transition-all`,
            maxHeight, maxHeight && `overflow-auto`,
            !discussionIsOpen && expandable && "hidden"
          )}
          ref={discussionRef}
        >
          {React.Children.map(discussion?.contenu, attachClickEventToImages)}
        </div>
        {discussionIsOpen && !attachmentsHidden && discussion?.fichiers && discussion?.fichiers.length > 0 &&
          <div className="border-t border-black/10 mt-2">
            <Attachments fichiers={discussion?.fichiers || []} queryDownload={queryDownload} />
          </div>}
      </div>
    </div >
  </>
}