import React from 'react'
import dayjs from 'dayjs'
import Banner from '../../Components/Banner'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb'
import { BreadcrumbDataType } from '../../Components/Breadcrumb/BreadcrumbDataType'
import Spinner from '../../Components/Spinner'
import Tabs, { TabsNavigationType } from '../../Components/Tabs/Tabs'
import { useP } from '../../services/i18n'
import InfosTechniquesLien from './InfosTechniquesLien'
import SupervisionLienTitle from './SupervisionLienTitle'
import TableHistoriqueTickets from './TableHistoriqueTickets'
import TableHistoriqueTravauxProgrammes from './TableHistoriqueTravauxProgrammes'
import TicketsLien from './TicketsLien/TicketsLien'
import TravauxProgrammesLien from './TravauxProgrammesLien/TravauxProgrammesLien'

type LienContainerType = {
  loading: boolean,
  lien: any,
  breadcrumb: Array<BreadcrumbDataType>,
  navigationLogins: Array<TabsNavigationType>
  panelsLogins: Array<JSX.Element>
}

export default function LienContainer({
  breadcrumb,
  navigationLogins,
  panelsLogins,
  loading,
  lien
}: LienContainerType) {
  const p = useP();

  const datasTPLien = lien?.tps.filter((tp) => dayjs(tp.dateDebut) > dayjs())
  const datasTicketLien = lien?.prestation.tickets.filter((t) => !t.ticketStatut.isClosDefinitif)

  return <>
    <Breadcrumb datas={breadcrumb} />

    {(loading || !lien) ? <div className='flex items-center justify-center h-full'><Spinner /></div> :
      <div className="h-full flex flex-col justify-start">
        <div className='flex items-start'>
          <div className='flex-1'>
            <SupervisionLienTitle
              lien={lien}
            />            
          </div>
         
        </div>
        {datasTPLien.length>0 && <div className='mt-6'><TravauxProgrammesLien datas={datasTPLien} /></div>}
        {datasTicketLien.length>0 && <div className='mt-6'><TicketsLien datas={datasTicketLien} /></div>}

        <div className='mb-4 mt-4'>
          <Tabs
            navigation={navigationLogins}
            panels={panelsLogins}
            cardStyle={true}
          />
        </div>
        
        <div className="pb-6">
        
              
          <Tabs
            navigation={[
              {
                libelle: "travaux_programmés",
                jsx: <><div>{p.t('supervisionLien.historiqueTP.title')}</div></>,
              },
              {
                libelle: "tickets_pegase",
                jsx: <><div>{p.t('supervisionLien.historiqueTickets.title')}</div></>,
              },
            ]}
            panels={[
                <>
                    <div className="h-[350px]"><TableHistoriqueTravauxProgrammes
                    key=''
                    title={p.t('supervisionLien.historiqueTP.title')}
                    datas={lien.tps.filter((tp) => dayjs(tp.dateDebut) < dayjs())}
                /></div></>,
                <>
                    <div className="h-[350px]"><TableHistoriqueTickets
                  key=''
                  title={p.t('supervisionLien.historiqueTickets.title')}
                  datas={lien.prestation.tickets}
                />   </div> </>
              ]}
            cardStyle={true}
          />
        </div>

      </div>
    }
  </>
}