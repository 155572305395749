import { gql } from "@apollo/client";

const GET_WRIKE_ENQUETE = gql`
  query WrikeProjetEnquete($id: ID) {
    wrikeProjetEnquete(id: $id) {
      commentaire
      dateCreation
      dateEnvoi
      dateModification
      dateReponse
      id
      noteGlobale
      wrikeProjet {
        code
        id
        nom
        template
        typeProjet
        wrikeStatut {
          code
          etatParent
          libelle
        }
      }
    }
  }
`;

const SAVE_WRIKE_ENQUETE = gql`
  mutation SaveWrikeProjetEnquete($wrikeProjetEnquete: WrikeProjetEnqueteInput) {
    saveWrikeProjetEnquete(wrikeProjetEnquete: $wrikeProjetEnquete) {
      id      
    }
  }
`;

export { GET_WRIKE_ENQUETE, SAVE_WRIKE_ENQUETE };
