import React from 'react'

import MobileNavigationItems from './MobileNavigationItems';
import { NavigationType } from '../../../../types/NavigationType'

type MobileNavigationProps = {
  navigation: Array<NavigationType>
}

function MobileNavigation({ navigation }: MobileNavigationProps) {

  return (
    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
      <MobileNavigationItems navigation={navigation} />
    </div>
  )
}

export default MobileNavigation