import React, { Fragment } from "react"
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb"
import { BreadcrumbDataType } from "../../Components/Breadcrumb/BreadcrumbDataType"
import Spinner from "../../Components/Spinner"
import { ApolloError } from '@apollo/client/errors';
import Error from "../../Components/Error/Error"
import TableFactures from "./TableFactures"
import Card from "../../Components/Card";

type FacturesContainerType = {
  loading: boolean,
  error: ApolloError | undefined,
  breadcrumb: Array<BreadcrumbDataType>,
  numcli: string | undefined,
  dateMinSelecteurAnnee: number,
  dateMaxSelecteurAnnee: number
}

export default function FacturesContainer({
  loading,
  error,
  breadcrumb,
  numcli,
  dateMinSelecteurAnnee,
  dateMaxSelecteurAnnee
}: FacturesContainerType) {

  if (loading) {
    return <div className="flex items-center justify-center h-full"><Spinner /></div>
  }

  if (error) {
    return <Error />
  }

  return (
    <>
      <Breadcrumb datas={breadcrumb} />
      <div className="h-full flex-1 overflow-hidden pb-0.5">
        <div className="flex flex-col xl:flex-row flex-1 h-full">
          <Card className={{ root: "flex-1 h-full" }}>
            <TableFactures numcli={numcli} dateMinSelecteurAnnee={dateMinSelecteurAnnee} dateMaxSelecteurAnnee={dateMaxSelecteurAnnee} />
          </Card>
        </div>
      </div>
    </>
  )

}