import React from 'react'
import Editable from '../../Editable/Editable';

type CellEditableFormatterType = {
  value: any,
  onSaveField: (value: string) => Promise<true | false>
}

export default function CellEditableFormatter({ value, onSaveField }: CellEditableFormatterType) {
  return <>
    <Editable value={value} onSaveField={onSaveField} className="w-full" />
  </>
}