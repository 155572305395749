import React from "react";
import { Control, UseFormRegister, UseFormWatch } from "react-hook-form";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Spinner";
import { useP } from "../../../services/i18n";

import ModalCommandeArrowBody from "./ModalCommandeArrowBody";
import ModalCommandeArrowFooter from "./ModalCommandeArrowFooter";

type ModalCommandeArrowType = {
  loading: boolean,
  isOpen: boolean,
  onClose: () => void,
  register: UseFormRegister<any>,
  control: Control<any>,
  errors: any,
  saveForm: () => void,
  getValues: (payload?: string | string[]) => any,
  setValue: (name: string, value: unknown, config?: any) => void,
  abonnement: any,
  watch: UseFormWatch<any>
}

export default function ModalCommandeArrow({
  loading,
  isOpen,
  onClose,
  register,
  control,
  errors,
  saveForm,
  getValues,
  setValue,
  abonnement,
  watch
}: ModalCommandeArrowType) {
  const p = useP();

  return <>
    <Modal
      onClose={() => onClose()}
      noPadding={false}
      isOpen={isOpen}
      content={{
        title: <div className=''>
          <div>{p.t('commandeArrow.title')}</div>
        </div>,
        body: loading ? <Spinner /> : <>
          <ModalCommandeArrowBody
            setValue={setValue}
            getValues={getValues}
            register={register}
            control={control}
            errors={errors}
            abonnement={abonnement}
            watch={watch}
          />
        </>,
        footer: <><ModalCommandeArrowFooter loading={loading} onSave={() => saveForm()} onCancel={() => onClose()} /></>,
      }}
      className="w-full lg:w-1/3"
    />
  </>
}