import React from 'react'
import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import Button from '../../../Components/Button'
import Modal from '../../../Components/Modal/Modal'
import ModalFooterAction from '../../../Components/Modal/ModalFooterAction'
import { useP } from '../../../services/i18n'
import InformationsSiteForm from './InformationsSiteForm'
import { UseFormReset } from 'react-hook-form'
import Constants from '../../../commons/Constants'
import { useCheckRight } from '../../../Components/CheckRight/CheckRight'

type InformationsSiteType = {
  saveSite: () => void,
  setOpenModaleEditionSite: (bool: boolean) => void,
  openModaleEditionSite: boolean,
  register: any,
  control: any,
  errors: any,
  reset: UseFormReset<any>,
  idSite: number,
  setValue: (name: string, value: unknown, config?: any) => void,
}

const {
  ROLE_ADMIN_CONTRAT
} = Constants;


export default function InformationsSite({
  saveSite,
  setOpenModaleEditionSite,
  openModaleEditionSite,
  register,
  control,
  errors,
  reset,
  idSite,
  setValue
}: InformationsSiteType) {
  const p = useP();

  const { checkRight } = useCheckRight();
  const rightAdminContrat = checkRight(ROLE_ADMIN_CONTRAT);

  return <>

    <Button color="transparent" size='small' onClick={() => setOpenModaleEditionSite(true)} className="mr-4">
      <div className='flex items-center'>
        <BuildingOfficeIcon className="h-4 w-4 mr-2" />
        <div className='mr-2'>{p.t('prestations.boutonEdition')}</div>
      </div>
    </Button>
    <Modal
      onClose={() => setOpenModaleEditionSite(false)}
      isOpen={openModaleEditionSite}
      content={{
        title: p.t('prestations.boutonEdition'),
        body:
          <>
            <InformationsSiteForm idSite={idSite} reset={reset} register={register} control={control} errors={errors} setValue={setValue} />
          </>,
        footer:
          rightAdminContrat && <div className='flex items-center justify-end'>
            <ModalFooterAction onClickAction={saveSite} label={p.t('actions.enregistrer')} />
          </div>,
      }}
      className="w-full lg:w-1/2 lg:h-2/3"
    />
  </>
}