import React from 'react'
import Tooltip from '../../Tooltip/Tooltip';
import { useP } from '../../../services/i18n';
import { ChevronRightIcon } from '@heroicons/react/24/outline'

type CellDetailFormatterType = {
  row: any,
  action: (row: any) => void,
  onClickRow: boolean
}

export default function CellDetailFormatter({ row, action, onClickRow }: CellDetailFormatterType) {
  const p = useP();
  return <>
    <Tooltip

      button={
        <div className="cursor-pointer group flex justify-center w-full" onClick={() => { !onClickRow && action(row) }}>
          <ChevronRightIcon className='h-4 w-4 text-gray-500 group-hover:text-secondary-500' />
        </div>
      }
      classNameButton={'w-full'}
      content={<div className='text-xs'>{p.t("actions.detail")}</ div>}
      placement='top'
      className='flex items-center justify-center p-2'
    />

  </>
}