import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import CardIcon from '../../../Components/CardIcon';
import StatTravailProgramme from './StatTravailProgramme';

type TravauxProgrammesLienType = {
  datas: any[]
}

export default function TravauxProgrammesLien({ datas }: TravauxProgrammesLienType) {

  const datasTP = datas.map((d) => ({
    id: d.id,
    stat: <StatTravailProgramme tp={d} />,
    statType: "JSX",
    icon: ExclamationTriangleIcon,
    classNameIconColor: "bg-orange-400",
  }))

  return <>
    <div className='md:grid md:grid-cols-2 md:gap-4 mb-4'>
      {
        datasTP.map((tp, i) => (
          <CardIcon item={tp} key={i} />
        ))
      }
    </div>
  </>
}