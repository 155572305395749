import { Dialog, Transition } from '@headlessui/react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import React, { Fragment, ReactNode, useState } from 'react'

type MobileSlideOverType = {
  children: ReactNode
}

export default function MobileSlideOver({ children }: MobileSlideOverType) {
  const [open, setOpen] = useState(false);
  return (
    <div className='block lg:hidden'>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed top-[56px] inset-0 overflow-hidden z-40" onClose={setOpen}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="pointer-events-none fixed top-[56px] inset-y-0 left-0 flex w-[270px] pr-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute bottom-20 -right-6 flex items-center justify-center">
                      <button
                        type="button"
                        className="rounded-br-md rounded-tr-md text-primary-500 bg-white py-4 px-2 focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                    <div className="relative flex-1">
                      {children}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={!open} as={Fragment}>
        <div className="fixed z-10 bottom-20 left-0 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-700"
            enterFrom="-translate-x-full opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transform transition ease-in-out duration-700"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-full opacity-0"
          >
            <button
              type="button"
              className="shadow-md rounded-br-md rounded-tr-md text-white bg-primary-500 py-4 px-2 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => setOpen(true)}
            >
              <span className="sr-only">Close panel</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Transition.Child>
        </div>
      </Transition.Root>
    </div>)
}