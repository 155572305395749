import React from "react"
import clsx from "clsx"
import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/24/outline"
import Tooltip from "../../Tooltip/Tooltip";
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

type InputSelectType = {
  label?: string | null,
  options?: Array<any>,
  defaultOptions?: Array<any> | boolean,
  promiseOptions?: (filter: string) => Promise<any>,
  placeholder?: string,
  name?: string,
  create?: boolean,
  isMulti?: boolean,
  onChange?: (val: any) => void,
  onSelect?: (val: any) => void,
  menuPlacement?: any,
  errors?: any,
  value?: InputSelectOptionType | null,
  formatGroupLabel? : undefined | ((data: any) => JSX.Element)
}

export type InputSelectOptionType = {
  value: string
  label: string
}

export default function InputSelect({
  label,
  options = [],
  defaultOptions = true,
  promiseOptions,
  placeholder,
  name,
  create = false,
  isMulti = false,
  onChange,
  onSelect,
  menuPlacement = "auto",
  errors = [],
  value,
  formatGroupLabel= undefined }: InputSelectType) {

  const controlStyles = {
    base: "border rounded-md bg-white hover:cursor-text",
    focus: "border-primary-500 ring-1 ring-primary-500 border-0",
    nonFocus: "border-gray-300 hover:border-gray-300 shadow-sm",
  };
  const placeholderStyles = "text-gray-500 pl-1 py-0.5 text-sm";
  const selectInputStyles = "pl-1 py-0.5 text-sm ring-0";
  const valueContainerStyles = "p-1 gap-1 text-sm";
  const inputContainerStyles = "text-sm";
  const singleValueStyles = "leading-7 ml-1";
  const multiValueStyles =
    "items-center py-0 pl-2 pr-1 gap-1.5 bg-gray-200 rounded-md text-xs font-medium";
  const multiValueLabelStyles = "leading-6 py-0.5";
  const multiValueRemoveStyles =
    "rounded-md w-[20px] h-[20px] hover:bg-gray-100 flex items-center justify-center self-center";
  const indicatorsContainerStyles = "p-1 gap-1";
  const clearIndicatorStyles =
    "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800 cursor-pointer";
  const indicatorSeparatorStyles = "bg-gray-300";
  const dropdownIndicatorStyles =
    "p-1 text-gray-500 rounded-md cursor-auto";
  const menuStyles = "p-0 mt-2 border border-gray-300 bg-white rounded-md text-sm shadow";
  const groupHeadingStyles = "ml-3 mt-2 mb-1 text-primary-500 text-sm font-medium";
  const optionStyles = {
    base: "px-3 py-2 hover:cursor-pointer hover:bg-primary-100 text-sm ",
    focus: "bg-primary-100 active:bg-primary-100",
    selected: "bg-primary-500 text-white hover:bg-primary-500",
  };
  const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 border border-gray-200 rounded-sm";

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {/* Votre contenu personnalisé pour l'indicateur */}
        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
      </components.DropdownIndicator>
    )
  }
  const CustomClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <Tooltip
          button={
            <XMarkIcon className="h-5 w-5 text-red-800" />
          }
          content={<div className="text-xs">Supprimer la sélection</div>}
          withArrow
        />

      </components.ClearIndicator>
    )
  }

  const menuPosition: any = "fixed"
  const sharedProps = {
    onChange: onChange,
    onSelect: onSelect,
    formatGroupLabel: formatGroupLabel,
    options: options,
    menuPlacement: menuPlacement,
    isMulti: isMulti,
    menuPosition: menuPosition,
    menuPortalTarget: document.body,
    closeMenuOnSelect: isMulti ? false : true,
    placeholder: placeholder,
    components: {
      DropdownIndicator: CustomDropdownIndicator,
      ClearIndicator: CustomClearIndicator,
    },
    unstyled: true,
    isClearable: true,
    backspaceRemovesValue: true,
    tabSelectsValue: false,
    name: name,
    className: 'react-select-container',
    classNamePrefix: 'react-select',
    isOptionDisabled:(option) => option.disabled,
    classNames: {
      control: ({ isFocused }) =>
        clsx(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          controlStyles.base,
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      valueContainer: () => valueContainerStyles,
      inputContainer: () => inputContainerStyles,
      singleValue: () => singleValueStyles,
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isFocused, isSelected }) =>
        clsx(
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
          optionStyles.base,
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
      loadingMessage: () => noOptionsMessageStyles,
    },
    styles: {
      menuPortal: (baseStyles) => ({
        ...baseStyles,
        zIndex: 100,
      })    
    },
  }

  return <>
    <div>
      {label && <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>}
      <div>
        {create &&
          <CreatableSelect
            {...sharedProps}
            value={value}
          />
        }

        {!create && promiseOptions &&
          <AsyncSelect
            cacheOptions={false}
            defaultOptions={defaultOptions}
            loadOptions={promiseOptions}
            {...sharedProps}
            value={value}
          />
        }

        {!create && !promiseOptions &&
          <Select
            {...sharedProps}
            value={value}
          />
        }
      </div>
    </div>
    {
      name && errors && errors[name] && <div className='text-red-600 text-xs font-medium mt-0.5'>{errors[name].message}</div>
    }
  </>
}