import React, { Fragment } from 'react'
import { Menu, Transition } from "@headlessui/react";

import { UserType } from '../../../../types/UserType'
import { UserNavigationType } from '../../../../types/UserNavigationType'
import ProfilePicture from './ProfilePicture';
import UserNavigationItems from './UserNavigationItems';

type UserNavigation = {
  user: UserType
  userNavigation: Array<UserNavigationType>
}

function UserNavigation({ user, userNavigation }: UserNavigation) {

  return (
    <Menu as="div" className="ml-3 relative z-40 hidden lg:flex-1 lg:flex lg:justify-end">
      <ProfilePicture user={user} />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div>
          <UserNavigationItems userNavigation={userNavigation} />
        </div>
      </Transition>
    </Menu >
  )

}

export default UserNavigation