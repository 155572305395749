import React from "react";
import { Control, UseFormRegister } from "react-hook-form";
import Modal from "../../../Components/Modal/Modal";
import { ModalConfirmation } from "../../../Components/ModalDialog/ModalConfirmation";
import Spinner from "../../../Components/Spinner";
import { useP } from "../../../services/i18n";
import { GsmMsisdnType } from "../../../types/GsmMsisdn";
import { SiteType } from "../../../types/SiteType";
import ModalCommandeGSMBody from "./ModalCommandeGSMBody";
import ModalCommandeGSMFooter from "./ModalCommandeGSMFooter";
import { CompteType } from "../../../types/CompteType";

type ModalCommandeGSMType = {
  loading: boolean,
  isOpen: boolean,
  confirmOpen: boolean,
  onClose: () => void,
  register: UseFormRegister<any>,
  control: Control<any>,
  errors: any,
  saveForm: () => void,
  confirmMessage: string,
  confirmAction: (confirm: any) => void,
  msisdns: Array<GsmMsisdnType>,
  reloadMsisdn: () => void,
  getValues: (payload?: string | string[]) => any,
  setValue: (name: string, value: unknown, config?: any) => void,
  joursFeries: string[],
  forfait: string,
  sites: SiteType[],
  siteDefaut: SiteType | null,
  dataOnly: boolean
  compte: CompteType
}

export default function ModalCommandeGSM({
  loading,
  isOpen,
  confirmOpen,
  onClose,
  register,
  control,
  errors,
  saveForm,
  confirmMessage,
  confirmAction,
  msisdns,
  reloadMsisdn,
  getValues,
  setValue,
  joursFeries,
  forfait,
  sites,
  siteDefaut,
  dataOnly,
  compte
}: ModalCommandeGSMType) {
  const p = useP();

  return <>
    <Modal
      onClose={() => onClose()}
      noPadding={false}
      isOpen={isOpen}
      content={{
        title: <div className=''>
          <div>{p.t('commandeGSM.title')} {forfait}</div>
        </div>,
        body: loading ? <Spinner /> : <>
          <ModalCommandeGSMBody
            setValue={setValue}
            getValues={getValues}
            msisdns={msisdns}
            reloadMsisdn={reloadMsisdn}
            register={register}
            control={control}
            errors={errors}
            joursFeries={joursFeries}
            sites={sites.filter(s => s.compteId?.toString() === compte?.id)}
            siteDefaut={siteDefaut}
            dataOnly={dataOnly}
            compte={compte}
          />
          <ModalConfirmation
            isOpen={confirmOpen}
            message={confirmMessage}
            onClose={(confirm) => confirmAction(confirm)}
            okButtonLabel={p.t('commandeGSM.form.okButtonLabel')}
            cancelButtonLabel={p.t('commandeGSM.form.cancelButtonLabel')}
          />
        </>,
        footer: <><ModalCommandeGSMFooter loading={loading} onSave={saveForm} onCancel={() => onClose()} /></>,
      }}
      className="w-full lg:w-1/3"
    />
  </>
}