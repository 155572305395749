import React, { useState } from 'react'
import RolesListItemsActionsInfo from './RolesListItemsActionsInfo'
import RolesListItemsActionsRemove from './RolesListItemsActionsRemove'
import RolesListItemsActionsAdd from './RolesListItemsActionsAdd'
import ModalAddRoleScope from './ModalAddRoleScope/ModalAddRoleScope'
import { ModalConfirmation } from '../../../../../Components/ModalDialog/ModalConfirmation'
import { useP } from '../../../../../services/i18n'
import { RoleType } from '../RoleType'
import { useMutation } from '@apollo/client'
import { DELETE_ROLECONTACT } from '../../../queries'
import { toastError, toastSuccess } from '../../../../../Components/Utils/Utils'
import CheckRigth from '../../../../../Components/CheckRight/CheckRight'
import Constants from '../../../../../commons/Constants'


const {
  ROLE_ADMIN_ANNUAIRE
} = Constants;

type RolesListItemsActionsType = {
  //scopeDatas: Array<scopeSiteType> | Array<scopeContratType>
  role: RoleType
}

export default function RolesListItemsActions({ role }: RolesListItemsActionsType) {
  const p = useP();
  const [modalRoleScopeOpen, setModalRoleScopeOpen] = useState(false)
  const [scope, setScope] = useState(null)
  const addRole = (e, scope) => {
    setScope(scope)
    setModalRoleScopeOpen(true)
  }
  const closeModaleScope = () => {
    setScope(null)
    setModalRoleScopeOpen(false)
  }

  const [removeRoleContact, { loading: loadingAddRole }] = useMutation(
    DELETE_ROLECONTACT,
    {
      refetchQueries: [
        'GetContacts',
        'roleContacts'
      ],
    }
  );

  const [confirmOpen, setConfirmOpen] = useState(false)
  const confirmMessage = (<>{p.t("contacts.confirmRemoveRole")}</>)

  const confirmRemoveRole = (confirm) => {
    if (confirm) {
      removeRole()
    }
    setConfirmOpen(false)
  }

  const removeRole = () => {
    removeRoleContact({
      variables: {
        idRoleContact: role.id
      }
    })
      .then(({ data }) => {
        toastSuccess(p.t('actions.supprimeOK'));

      })
      .catch(() => {
        toastError(p.t('error.defaultMessage'));
      });
  }

  return <>
    <div className="flex items-center px-2">
      <div>
        <RolesListItemsActionsAdd onClick={addRole} typeRole={role.typeRole} multicompte={role.isMulticompte} />
        <ModalAddRoleScope
          isOpen={modalRoleScopeOpen}
          onClose={closeModaleScope}
          scope={scope}
          role={role}
        />
      </div>
      <div>
        {role.typeRole.descriptif && <RolesListItemsActionsInfo descriptif={role.typeRole.descriptif} className="py-1" />}
      </div>
      <CheckRigth role={ROLE_ADMIN_ANNUAIRE}>
        <div>
          <RolesListItemsActionsRemove onClick={() => setConfirmOpen(true)} />
          <ModalConfirmation
            isOpen={confirmOpen}
            message={confirmMessage}
            onClose={(confirm) => confirmRemoveRole(confirm)} />
        </div>
      </CheckRigth>
    </div>
  </>
}