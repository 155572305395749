import React, { Fragment, useEffect, useState } from "react"
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb"
import Card from "../../Components/Card"
import Banner from "../../Components/Banner"
import { BreadcrumbDataType } from "../../Components/Breadcrumb/BreadcrumbDataType"
import TreeMenu, { TreeMenuElementType } from "../../Components/TreeMenu/TreeMenu"
import Spinner from "../../Components/Spinner"
import { ApolloError } from '@apollo/client/errors';
import Error from "../../Components/Error/Error"
import { useP } from "../../services/i18n"
import InformationsSite from "./InformationsSite/InformationsSite"
import { ADD_ROLE_SUPERVISION, GET_CONTACT_SUPERVISION_SITE, SAVE_SITE } from "./queries"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLazyQuery, useMutation } from "@apollo/client"
import { toastError, toastSuccess } from "../../Components/Utils/Utils"
import TelechargementsSite from "./TelechargementsSite"

type PrestationsContainerType = {
  loading: boolean,
  error: ApolloError | undefined,
  site: siteType,
  breadcrumb: Array<BreadcrumbDataType>,
  treeMenuDatas: Array<TreeMenuElementType>,
  onActiveItemMenu: (activeItem: TreeMenuElementType | null | undefined) => void,
  activeView: JSX.Element,
  activeItemMenu: string
}

type siteType = {
  id: number,
  nom: string,
  numeroVoie: string,
  libelleVoie: string,
  complementVoie: string,
  codePostal: string,
  commune: string
}

type FormSiteType = {
  id: number,
  nom: string,
  analytique1: string,
  analytique2: string,
  analytique3: string,
  contact: any
}

export default function PrestationsContainer({
  loading,
  error,
  site,
  breadcrumb,
  treeMenuDatas,
  onActiveItemMenu,
  activeView,
  activeItemMenu
}: PrestationsContainerType) {

  if (loading) {
    return <div className="flex items-center justify-center h-full">
      <Spinner color="primary" />
    </div>
  }

  if (error) {
    return <Error />
  }
  const p = useP();
  const [openModaleEditionSite, setOpenModaleEditionSite] = useState(false)
  const [openModaleTelechargementsSite, setOpenModaleTelechargementsSite] = useState(false)

  const validationSchema = yup.object({
    nom: yup.string().nullable(),
    ana1: yup.string().nullable(),
    ana2: yup.string().nullable(),
    ana3: yup.string().nullable(),
  }).required();

  const { register, control, handleSubmit, formState: { errors }, reset, setValue } = useForm<any>({
    resolver: yupResolver(validationSchema)
  });

  const [saveSite] = useMutation(SAVE_SITE,
    {
      refetchQueries: [
        'sites',
        'getRoleSupervisionProactiveFromSite'
      ],
    });

  const [getContactSupervisonSite, { data: dataContact }] = useLazyQuery(GET_CONTACT_SUPERVISION_SITE, {
    fetchPolicy: "network-only",
  });

  const [addRoleSupervision] = useMutation(ADD_ROLE_SUPERVISION, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (site?.id) {
      getContactSupervisonSite({
        variables: {
          siteId: site.id,
        },
      })
    }
  }, [site]);

  const onSubmit: SubmitHandler<FormSiteType> = async data => {

    // Transformation des données reçues
    const dataSave = {
      "id": data.id,
      "analytique1": data.analytique1,
      "analytique2": data.analytique2,
      "analytique3": data.analytique3,
      "nom": data.nom,
    };

    if (data?.contact?.id) {
      // Ajout contact supervision
      await addRoleSupervision({
        variables: {
          contactId: data.contact.id,
          siteId: data.id
        },
        onError: (err) => {
          toastError("Erreur lors de l'ajout du contact supervision");
          throw err;
        },
      }).catch((err) => {
        toastError("Erreur lors de l'ajout du contact supervision");
        throw err;
      });
    }

    await saveSite({
      variables: {
        site: dataSave
      },
      onCompleted: () => {
        toastSuccess("Enregistrement effectué");
      },
      onError: (err) => {
        toastError("Erreur lors de la sauvegarde");
        throw err;
      },
    }).catch((err) => {
      toastError("Erreur lors de l'ajout du contact supervision");
      throw err;
    });

    setOpenModaleEditionSite(false)
    reset({});
  }

  return (
    <>
      <Breadcrumb datas={breadcrumb} />
      <div className="h-full flex-1 overflow-hidden">
        <div className="flex flex-col flex-1 h-full">
          {site && (
            <Banner>
              <Banner.Left>
                <div className="text-sm flex-1">
                  <div className="text-md font-bold mb-1 text-white ">{site.nom}</div>
                  <div className="text-white/80">{site.numeroVoie ? site.numeroVoie : ""} {site.libelleVoie}</div>
                  <div className="text-white/80">{site.complementVoie}</div>
                  <div className="text-white/80">{site.codePostal} {site.commune}</div>
                  {dataContact?.getRoleSupervisionProactiveFromSite && <div className="text-white/80">{p.t('prestations.site.contactSupervision')} : {dataContact?.getRoleSupervisionProactiveFromSite?.contact?.nom} {dataContact?.getRoleSupervisionProactiveFromSite?.contact?.prenom}</div>}
                </div>
              </Banner.Left>
              <Banner.Right>
                <div className="flex mt-4 lg:mt-0">
                  <InformationsSite
                    saveSite={handleSubmit(onSubmit)}
                    openModaleEditionSite={openModaleEditionSite}
                    setOpenModaleEditionSite={setOpenModaleEditionSite}
                    idSite={site.id}
                    register={register}
                    reset={reset}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                  />
                  <TelechargementsSite
                    openModaleTelechargementsSite={openModaleTelechargementsSite}
                    setOpenModaleTelechargementsSite={setOpenModaleTelechargementsSite}
                    idSite={site.id}
                  />
                </div>
              </Banner.Right>
            </Banner>)
          }
          <div className="flex flex-col lg:flex-row flex-1 h-full overflow-auto pb-0.5">
            <TreeMenu
              className="lg:w-[250px] lg:mr-6 lg:h-full"
              datas={treeMenuDatas}
              onActiveItemMenu={onActiveItemMenu}
              activeItemMenu={activeItemMenu}
            />
            <Card className={{ root: "flex-1 h-full" }}>
              {activeView}
            </Card>
          </div>
        </div>
      </div>
    </>
  )

}
