import React, { Fragment, ReactNode, useState } from 'react'
import { Transition } from '@headlessui/react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type DesktopSlideOverType = {
  children: ReactNode
}
export default function DesktopSlideOver({ children }: DesktopSlideOverType) {
  const [open, setOpen] = useState(true);
  return <>

    <div className='hidden lg:block relative'>
      <div className={
        clsx(`transition-all duration-500 h-full`,
          open ? `lg:w-[270px] opacity-100` : `lg:w-0 opacity-0`
        )}>
        <div className="h-full flex flex-col bg-white shadow-xl">{children}</div>
      </div>
      <div className="absolute z-40 w bottom-10 -right-4 flex items-center justify-center">
        <button
          type="button"
          className="rounded-br-md rounded-tr-md text-primary-500 bg-white py-3 px-1 focus:outline-none focus:ring-2 focus:ring-white"
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">Open panel</span>
          <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </div>

      <Transition.Root show={!open} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-700"
          enterFrom="-translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transform transition ease-in-out duration-700"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="-translate-x-full opacity-0"
        >
          <div className="absolute z-40 w bottom-10 left-0 flex items-center justify-center">
            <button
              type="button"
              className="rounded-br-md rounded-tr-md text-white bg-primary-500 py-3 px-1 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => setOpen(true)}
            >
              <span className="sr-only">Open panel</span>
              <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
            </button>

          </div></Transition.Child>
      </Transition.Root>



    </div>
  </>
}