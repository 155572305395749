import React from "react"
import IMG from "../../../HelpBoxComponents/IMG"
import P from "../../../HelpBoxComponents/P"
import UL from "../../../HelpBoxComponents/UL"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentDashboardCompte_Q4() {
  const p = useP()
  return <>
    <IMG src="/images/infosDashboard/multi_comptes.png" sizes={{ width: 300 }} />
    <P>
      <>{parse(p.t('helpBox.HelpDashboardCompte.a4.1'))}</>
    </P>
  </>
}