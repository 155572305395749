import React, { useContext, useEffect, useRef } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { tableContext } from '../TableContext'

type CellDetailRowFormatterProps = {
  value: string,
  onToggleDetail: (row: object) => void,
  open: boolean,
  row: any
}

function CellDetailRowFormatter({ value, onToggleDetail, open, row }: CellDetailRowFormatterProps) {
  const { childrenLoading, idRow } = useContext(tableContext)
  const id = row?.__typename ? row?.__typename + row?.id : '';
  const ref = useRef<any>();
  const rowRef = useRef<any>();
  useEffect(() => {
    if (open && !childrenLoading) {
      const tableContainer = rowRef.current.closest('.tableContainer');
      if (rowRef.current.id.substring(7) === idRow) {
        setTimeout(() => { tableContainer.scrollTop = rowRef.current.offsetTop - 50 }, 0);
        ///ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" })
      }
    }
  }, [childrenLoading, idRow])



  return (
    <>
      <div
        ref={rowRef}
        className={clsx(`relative flex items-center group`, row.subrow.size > 0 && `cursor-pointer`)}
        id={id}
        data-open={open}
        onClick={() => {
          row.subrow && onToggleDetail(row);
        }}
      >
        {
          row.subrow.size > 0 &&
          <div className="mr-2">
            {
              open
                ? <ChevronUpIcon className='h-4 w-4 text-secondary-500 group-hover:text-secondary-500' />
                : <ChevronDownIcon className='h-4 w-4 text-gray-500 group-hover:text-secondary-500' />
            }
          </div>
        }
        <div className={clsx(
          `truncate font-medium text-gray-900`,
          row.subrow.size == 0 && `ml-6`,
          row.subrow.size > 0 && `group-hover:text-secondary-500`,
          (row.subrow.size > 0 && open) && `text-secondary-500`
        )}>{value}</div>
        <div className='absolute -top-[50px]' ref={ref}></div>
      </div>
    </>
  )

}

export default CellDetailRowFormatter