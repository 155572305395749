import React, { useEffect, useState } from "react"
import CyberContainer from "./CyberContainer"
import { useP } from "../../services/i18n"
import ShellLayout from "../ShellLayout"
import { useQuery } from "@apollo/client"
import { GET_ARBORESENCE } from "./queries"
import { KpiCyberGraphType } from "../../types/KpiCyberType"
import { CyberDetailType } from "./CyberViewDetail/CyberViewDetail"

export type DataTreeType = {
  [key: string]: {
    index: string,
    isFolder: boolean,
    children: string[],
    data: string,
    description?: string,
    type?: string
    graphCode?: string
    compteId?: string
  }
};

export default function Cyber() {
  const p = useP()
  const breadcrumbDatas = [{
    libelle: p.t('navigation.cyber.label'),
    href: "#",
    current: true
  }]

  const [datasTree, setDatasTree] = useState<DataTreeType>({})
  const [view, setView] = useState<CyberDetailType | null>(null)

  const { data: dataArborescence, loading: loadingArborescence } = useQuery(GET_ARBORESENCE, {
    fetchPolicy: "network-only",
  })

  const onSelectTree = (item: Array<string>) => {
    const selected = datasTree[item[0]]
    console.log(selected)
    const getChildren = (children, child) => {
      const childData = datasTree[child]
      const newView = [...children]

      if (childData.type) {
        newView.push({
          index: childData.index,
          libelle: childData.data,
          description: childData?.description || null,
          chartType: childData.type,
          chartCode: childData.graphCode,
          compteId: childData.compteId,
        })
      }

      return newView
    }

    setView({
      index: selected.index,
      libelle: selected.data,
      description: selected?.description || null,
      chartType: selected?.type || null,
      chartCode: selected?.graphCode || null,
      compteId: selected?.compteId || null,
      children: selected.children.reduce(getChildren, [])
    })
  }

  useEffect(() => {

    if (dataArborescence?.kpiCyberGraph) {

      const arboKpi = dataArborescence.kpiCyberGraph

      const datasTree: DataTreeType = {
        root: {
          index: 'root',
          isFolder: true,
          children: [],
          data: 'Root item',
        }
      }

      arboKpi.forEach((arboCompte: KpiCyberGraphType) => {
        const compteId = arboCompte.compte.id

        datasTree.root.children = [
          ...datasTree.root.children,
          `${compteId}_ROOT`
        ]

        arboCompte.arborescence.forEach(arbo => {
          const keyArbo = `${compteId}_${arbo.code}`
          const children = arboCompte.arborescence.filter(arbo2 => arbo2.parentCode === arbo.code).map(arbo2 => `${compteId}_${arbo2.code}`);

          datasTree[keyArbo] = {
            index: keyArbo,
            isFolder: children.length > 0,
            children,
            data: arbo.libelle,
            description: arbo.description,
            type: arbo.typeGraph,
            graphCode: arbo.code,
            compteId: compteId
          }
        })
      })
      setDatasTree(datasTree)

    }
  }, [dataArborescence])

  return <>
    <ShellLayout>
      <CyberContainer
        loading={loadingArborescence}
        datasTree={datasTree || {}}
        breadcrumb={breadcrumbDatas}
        chartView={view}
        onSelectNode={onSelectTree}
      />
    </ShellLayout>
  </>
}