import React from "react";
import { useParams, useMatch } from "react-router-dom";
import { useP } from "../../../services/i18n";
import HelpSupport from "./HelpSupport/HelpSupport";
import HelpAnnuaire from "./HelpAnnuaire/HelpAnnuaire";
import HelpDashboardCompte from "./HelpDashboardCompte/HelpDashboardCompte";
import HelpPrestations from "./HelpPrestations/HelpPrestations";
import HelpDetailLien from "./HelpPrestations/HelpDetailLien";
import HelpContrats from "./HelpContrats/HelpContrats";
import HelpDetailContrat from "./HelpContrats/HelpDetailContrat";
import HelpFactures from "./HelpFactures/HelpFactures";
import HelpDefault from "./HelpFactures/HelpDefault";

type HelpBoxContentType = {
  location: string
}

export default function HelpBoxContent({ location }: HelpBoxContentType) {
  const { compteId, comptePrincipalId, numcli, prestationId } = useParams();
  const p = useP();
  const basePath = `/${p.t("router.compte.route")}/${compteId}`
  const basePathMulti = `/${p.t("router.comptes.route")}/${comptePrincipalId}`
  let contentToLoad;

  const match = useMatch(location)

  switch (match?.pathnameBase) {

    // DASHBOARD COMPTE
    case `${basePathMulti}`:
    case `${basePath}`:
      contentToLoad = <HelpDashboardCompte />
      break;

    // PRESTATIONS 
    case `${basePath}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.GSM.route")}`:
    case `${basePath}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.VGA.route")}`:
    case `${basePath}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.NDI.route")}`:
    case `${basePath}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.CENTILE.route")}`:
    case `${basePath}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.TRUNK.route")}`:
    case `${basePath}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.lien.route")}`:
    case `${basePathMulti}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.GSM.route")}`:
    case `${basePathMulti}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.VGA.route")}`:
    case `${basePathMulti}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.NDI.route")}`:
    case `${basePathMulti}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.CENTILE.route")}`:
    case `${basePathMulti}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.TRUNK.route")}`:
    case `${basePathMulti}/${p.t("router.compte.prestations.route")}/${p.t("router.compte.prestations.lien.route")}`:
      contentToLoad = <HelpPrestations />
      break;

    // DETAIL LIEN
    case `${basePathMulti}/${p.t("router.compte.lien.route")}/${prestationId}`:
    case `${basePath}/${p.t("router.compte.lien.route")}/${prestationId}`:
      contentToLoad = <HelpDetailLien />
      break;

    // CONTRATS
    case `${basePathMulti}/${p.t("router.compte.contrats.route")}`:
    case `${basePath}/${p.t("router.compte.contrats.route")}`:
      contentToLoad = <HelpContrats />
      break;

    // DETAIL CONTRAT
    case `${basePathMulti}/${p.t("router.compte.contrats.route")}/${numcli}`:
    case `${basePath}/${p.t("router.compte.contrats.route")}/${numcli}`:
      contentToLoad = <HelpDetailContrat />
      break;

    // FACTURES
    case `${basePathMulti}/${p.t("router.compte.factures.route")}`:
    case `${basePath}/${p.t("router.compte.factures.route")}`:
      contentToLoad = <HelpFactures />
      break;

    // ANNUAIRE
    case `${basePathMulti}/${p.t("router.compte.annuaire.route")}`:
    case `${basePath}/${p.t("router.compte.annuaire.route")}`:
      contentToLoad = <HelpAnnuaire />
      break;

    // SUPPPORT
    case `${basePathMulti}/${p.t("router.compte.support.route")}`:
    case `${basePath}/${p.t("router.compte.support.route")}`:
      contentToLoad = <HelpSupport />
      break;

    default:
      contentToLoad = <HelpDefault />
      break;

  }

  return <>
    <div className="w-full h-full p-2 bg-white rounded-b-md shadow-2xl text-gray-700 text-sm overflow-auto">
      <div>{contentToLoad}</div>
    </div>
  </>
}