import clsx from 'clsx'
import React, { MouseEvent, useRef } from 'react'

type TrType = {
  children: React.ReactNode,
  className?: string,
  onDoubleClick?: () => void,
  onClick?: (e: MouseEvent) => void
}

function Tr({ children, className = '', onDoubleClick, onClick }: TrType) {
  const TrRef = useRef<any>();
  return (
    <>
      <tr
        onClick={(e) => { onClick?.(e) }}
        onDoubleClick={() => onDoubleClick?.()}
        className={clsx(
          className
        )} ref={TrRef}
      >
        {children}
      </tr>
    </>
  )

}

export default Tr