import React from 'react'
import Card from '../../Components/Card'
import Table from '../../Components/Table/Table'
import { ColumnType } from '../../Components/Table/Types/ColumnType'
import { useP } from '../../services/i18n'

type TableHistoriqueTravauxProgrammesType = {
  title: string
  datas: any[]
}

export default function TableHistoriqueTravauxProgrammes({ title, datas }: TableHistoriqueTravauxProgrammesType) {
  const p = useP()

  const columns: Array<ColumnType> = [{
    libelle: p.t('supervisionLien.historiqueTP.columns.nature'),
    code: 'nature',
    colType: 'string',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.dateDebut'),
    code: 'dateDebut',
    colType: 'dateTime',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.dateFin'),
    code: 'dateFin',
    colType: 'dateTime',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.duree'),
    code: 'duree',
    colType: 'duree',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.cause'),
    code: 'cause',
    colType: 'string',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.impact'),
    code: 'impact',
    colType: 'string',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.refFournisseur'),
    code: 'referenceFournisseur',
    colType: 'string',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.type'),
    code: 'typeTravailProgramme.libelle',
    colType: 'string',
  },
  {
    libelle: p.t('supervisionLien.historiqueTP.columns.statut'),
    code: 'statutTravailProgramme',
    colType: 'state',
  }]

  return <>
    <div className='mb-4'>      
      <Table
        idTable='ec-table-historique-lien-pagination'
        loading={false}
        datas={datas}
        columns={columns}
        options={{
          search: false,
          pagination: false
        }}

      />
    </div>
  </>
}