import clsx from "clsx";
import React, { useEffect, useRef } from "react"

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  initialHeight: number
) => {

  useEffect(() => {
    if (textAreaRef) {

      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = initialHeight ? `${initialHeight}px` : "0px";
      //textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [value]);
};

type TextareaType = {
  className?: string,
  label?: string,
  value?: string,
  name: string,
  placeholder?: string,
  height?: number,
  register?: any,
  errors?: Array<any>,
  onChange?: (e) => void,
  disabled?: boolean | undefined,
  autofocus?: boolean | undefined,
}

export default function Textarea({
  className,
  placeholder,
  label,
  name,
  register = () => null,
  errors = [],
  value = '',
  onChange,
  height = 100,
  autofocus = false,
  ...props
}: TextareaType) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef?.current, value, height);


  return (
    <>
      {label &&
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>}
      <textarea
        {...props}
        {...register(name)}
        autoFocus={autofocus}
        name={name}
        className={clsx(
          errors && errors[name]
            ? `focus:ring-red-600 focus:border-red-600 focus:ring-1 focus:outline-none focus:shadow-none focus:border-0`
            : `focus:ring-primary-500 focus:border-primary-500 focus:ring-1 focus:outline-none focus:shadow-none focus:border-0`,
          `shadow-sm block w-full sm:text-sm border-gray-300 rounded-md overflow-hidden resize-none`,
          className)}
        onChange={onChange}
        placeholder={placeholder}
        ref={textAreaRef}
        value={value}
        style={{ height: height }}
      />
      {
        errors && errors[name] && <div className='text-red-600 text-xs font-medium mt-0.5'>{errors[name].message}</div>
      }
    </>
  );
}