import React, { ReactChildren } from 'react'

import RolesList from './Roles/RolesList';

type TableContactsDetailType = {
  renderProps: any
}

function TableContactsDetail({ renderProps }: TableContactsDetailType) {

  return (
    <>
      <div className='w-full p-4'>
        {/* <div className='mb-2'>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Rôles ( un titre ici ? )</p>
        </div> */}
        <RolesList rowIndex={renderProps.index} idContact={renderProps.idEntity} open={renderProps.open} />
      </div>
    </>
  )

}

export default TableContactsDetail;