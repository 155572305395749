/* eslint-disable no-debugger */
import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { authContext } from "../AuthContext";
import Constants from "../commons/Constants";
import queryString from 'query-string'

const { SUPPORT_URL } = Constants
function Logout() {

    const { setLoggedInUser } = useContext(authContext);
    const params = queryString.parse(location.search);

    useEffect(() => {
        const user = {};

        setLoggedInUser(user);
    }, []);

    if (params.all) {
        window.location.href = `${SUPPORT_URL}/index/unlog`
    }

    return <Navigate to="/login" />;
}

export default Logout;
