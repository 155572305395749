import React from 'react'

type MobileButtonProps = {
  icon: JSX.Element
}

function MobileMenuButton({ icon }: MobileButtonProps) {
  return (
    <>
      <icon.type className="block h-6 w-6" aria-hidden="true" />
    </>
  )
}

export default MobileMenuButton