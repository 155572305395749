import { gql } from "@apollo/client"

const GET_LOGINS = gql`
  query Logins($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    logins(filters: $filters, sorters: $sorters, pagination: $pagination) {          
      items {
        login        
        dateLastDisconnected                
        lien {
          id
          prestation {
            id
          }
        }
      }
    }
  }
`

export {
  GET_LOGINS
}