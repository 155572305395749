import React, { useState } from 'react'

import ModalContacts from './ModalContacts/ModalContacts';
import TableContacts from './TableContacts';
import { useP } from '../../services/i18n';
import ModalAddRole from './ModalAddRole/ModalAddRole';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ROLE_CONTACT, GET_TYPES_ROLES } from './queries';
import { simulateMouseClick, toastError, toastSuccess } from '../../Components/Utils/Utils';
import Constants from '../../commons/Constants';

const {
  TYPE_CONTACT,
} = Constants;

type addRoleType = {
  contactId: string | number | null,
  typeRoleCode: string | number | null,
  isMulticompte: boolean | null
}

type rowMulticompte = {
  typeRoleCode: string,
  isMulticompte: boolean | null | undefined
}

function ContactsContainer() {

  const p = useP()

  const [openModalContacts, setOpenModalContacts] = useState(false)
  const [contact, setContact] = useState<any>(null)

  const { loading: loadingTypesRoles, data: dataTypesRoles } = useQuery(GET_TYPES_ROLES, {
    variables: {
      filters: [
        {
          column: "statut.code",
          value: "A"
        }
      ],
      sorters: [
        {
          column: "libelle",
          order: "ASC"
        },
      ],
    }
  });

  const [addRole] = useMutation(
    ADD_ROLE_CONTACT,
    {
      refetchQueries: [
        'GetContacts',
        'roleContacts'
      ],
    }
  );


  const openModaleContact = (row) => {
    setOpenModalContacts(true)
    row && setContact(row);
  }

  const closeModaleContact = () => {
    setOpenModalContacts(false)
    setContact(null)
  }

  const saveModaleContact = (contact) => {
    setOpenModalContacts(false)
    setContact(contact)
  }

  const [openModalAddRole, setOpenModalAddRole] = useState(false)
  const closeModaleRoles = () => {
    setOpenModalAddRole(false)
    setContact(null)
  }
  const [selectedRoles, setSelectedRoles] = useState<Array<any>>();
  const [rowsMulticompte, setRowsMulticompte] = useState<Array<rowMulticompte | any>>([])

  const setRowsAreMulticompte = (checked, row) => {
    const rowIsExistInState = rowsMulticompte.some((el) => el.typeRoleCode == row.code)
    if (!rowIsExistInState) {
      setRowsMulticompte([...rowsMulticompte, {
        typeRoleCode: row.code,
        isMulticompte: checked
      }])
    } else {
      const newMulticompteRows = rowsMulticompte.filter((r) => r.typeRoleCode !== row.code)
      setRowsMulticompte(newMulticompteRows)
    }
  }

  const addRoles = async () => {
    const rolesToAdd: Array<addRoleType> = [];
    const multicomptes = selectedRoles?.filter((item1) => rowsMulticompte.some(item2 => item1.code === item2.typeRoleCode));
    selectedRoles?.map((role) => {
      const multicompte = multicomptes?.some(item => item === role)
      rolesToAdd.push({
        contactId: contact?.id,
        typeRoleCode: role.code,
        isMulticompte: multicompte ? true : false
      })
    })
    addRole({
      variables: {
        "roleContact": rolesToAdd
      }
    })
      .then(() => {

        // Récupération de la div qui contient le l'id du contact
        const element = document.querySelector('#Contact' + contact.id) as HTMLDivElement | null;

        // Ligne ouverte ou fermée
        const openChevron = element?.dataset.open ? element?.dataset.open : 'true';

        // Si la ligne est fermée, alors on ouvre pour afficher le détail
        if (element && openChevron === 'false')
          simulateMouseClick(element);

        toastSuccess(p.t('actions.ajoutOK'));
        closeModaleRoles();
      })
      .catch(() => {
        toastError(p.t('error.defaultMessage'));
      });
  }


  // Modale de confirmation
  const [confirmOpen, setConfirmOpen] = useState(false)
  const confirmMessage = (<>{p.t("actions.confirmCloseModale1")}<br />{p.t("actions.confirmCloseModale2")}</>)

  const confirmAction = (confirm) => {
    if (confirm) {
      setOpenModalContacts(false)
      setContact(null)
    }
    setConfirmOpen(false)
  }

  const showModalAddRole = (row) => {
    setContact(row)
    setOpenModalAddRole(true);
  }

  return (
    <>
      {dataTypesRoles?.typeRoles && <TableContacts
        addContact={() => { setOpenModalContacts(true) }}
        openModaleContact={openModaleContact}
        addRole={showModalAddRole}
        typeRoles={dataTypesRoles.typeRoles}
      />}

      <ModalContacts
        isOpen={openModalContacts}
        onClose={closeModaleContact}
        onSaveContact={saveModaleContact}
        idContact={contact?.id}
        confirmOpen={confirmOpen}
        confirmMessage={confirmMessage}
        confirmAction={(confirm => confirmAction(confirm))}
        typeContact={TYPE_CONTACT}
      />
      <ModalAddRole
        isOpen={openModalAddRole}
        onClose={closeModaleRoles}
        onClickAction={addRoles}
        selectedValues={(values) => setSelectedRoles(values)}
        idContact={contact?.id}
        multiCompte={contact?.isMultiCompte ? contact.isMultiCompte : false}
        dataDonneesReference={!loadingTypesRoles && dataTypesRoles}
        rowMulticompte={(checked, row) => setRowsAreMulticompte(checked, row)}
      />
    </>
  )

}

export default ContactsContainer;