import React, { useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { ListDataType } from './ListDataType';
import { ModalConfirmation } from '../ModalDialog/ModalConfirmation';
import { useP } from '../../services/i18n';
import Tooltip from '../Tooltip/Tooltip';
import clsx from 'clsx';

type ListItemType = {
  item: ListDataType,
  canDeleteItem: boolean,
  removeItem?: (item: ListDataType) => void | undefined
  onClickItem?: (item: ListDataType) => void | undefined,
  libelleClassName?: string
  //removeItem?: any
}

function ListItem({ item, canDeleteItem, removeItem, onClickItem, libelleClassName = "text-sm font-medium text-gray-900" }: ListItemType) {
  const p = useP();
  const [confirmOpen, setConfirmOpen] = useState(false)
  const confirmMessage = (<>{p.t('list.confirmMessage')}</>)
  const confirmRemoveItem = (confirm) => {
    if (confirm) {
      removeItem && removeItem(item)
    }
    setConfirmOpen(false)
  }

  // <Tooltip
  //     button={
  //       <div className="cursor-pointer group flex justify-center" onClick={() => action(row)}>
  //         <PencilAltIcon className='h-5 w-5 text-gray-500 group-hover:text-secondary-500' />
  //       </div>
  //     }
  //     content={<div className='text-xs'>Editer</ div>}
  //     placement='top'
  //     className='flex items-center justify-center'
  //   />



  return (
    <>
      <li
        className={clsx(`flex py-2 first:pt-0`, onClickItem && `hover:bg-gray-50 cursor-pointer first:pt-2`)}
        onClick={() => onClickItem?.(item)}>
        <div className="ml-0 flex items-center justify-between flex-1">
          <div className={clsx(`flex items-center w-full`, libelleClassName)}>
            {
              item.icon &&
              <div className='mr-2'>
                {
                  item.tooltipMessage
                    ? <Tooltip
                      button={item.icon}
                      content={<div className='text-xs'>{item.tooltipMessage}</ div>}
                      placement='top'
                      className='flex items-center justify-center'
                    />
                    : <>{item.icon}</>
                }
              </div>
            }
            <div className='w-full'>{item.libelle}</div>
          </div>
          {
            canDeleteItem &&
            <>
              <button type="button" className="group cursor-pointer" onClick={() => setConfirmOpen(true)}>
                <Tooltip
                  button={<TrashIcon className="h-4 w-4 mx-2 text-gray-400 group-hover:text-red-600" />}
                  content={<div className='text-xs'>{p.t('actions.supprimer')}</ div>}
                  placement='top'
                  className='flex items-center justify-center'
                />

              </button >
              <ModalConfirmation
                isOpen={confirmOpen}
                message={confirmMessage}
                onClose={(confirm) => confirmRemoveItem(confirm)} />
            </>
          }
        </div>

      </li>
    </>
  )

}

export default ListItem;