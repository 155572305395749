import React from 'react'
import { useP } from '../../../../services/i18n';
import { UserType } from '../../../../types/UserType'

type ProfilePictureProps = {
  user: UserType
}

function ProfilePicture({ user }: ProfilePictureProps) {

  const getInitials = () => {

    const flName = user.nom ? user.nom.charAt(0).toUpperCase() : '.';
    const flSurname = user.prenom ? user.prenom.charAt(0).toUpperCase() : '.';

    return `${flSurname}${flName}`
  }

  return (
    <div className="flex-shrink-0">
      <div className="h-10 w-10 rounded-full border-2 border-white flex items-center justify-center  focus:outline-none focus:ring-0 text-white">
        {getInitials()}
      </div>
    </div>
  )
}

export default ProfilePicture