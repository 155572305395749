import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import { GET_ABONNEMENTS_GROUPES } from '../queries';
import ModalContratBody from './ModalContrat/ModalContratBody';
import ModalContratFooter from './ModalContrat/ModalContratFooter';
import TableContrat from './TableContrat';

type TabPanelAbonnementSFType = {
  datas: Array<any>,
  numcli:any
}

export default function TabPanelAbonnementSF({ datas, numcli }: TabPanelAbonnementSFType) {
  const [detailIsOpen, setDetailIsOpen] = useState(false);
  const [titleModalDetail, setTitleModalDetail] = useState('');
  const [idAbo, setIdAbo] = useState(null);
  const onClickGroup = (row) => {
    setDetailIsOpen(true)
    setTitleModalDetail(row.groupe)
    setIdAbo(row.idAbo)
  }
  const [withOutVente, setWithOutVente] = useState(true)
  
  const [getAbonnementsGroupes,{ loading: loadingAbonnementsGroupes }] = useLazyQuery(GET_ABONNEMENTS_GROUPES, {    
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [datasTable, setDatasTable] = useState(datas)

  const reloadTable = (value) => {
    setWithOutVente(value)  
    getAbonnementsGroupes({
        variables: {
          numContrat: numcli,
          withOutVente: value,
        },
        onCompleted: async (data) => {
          data.abonnementsActifsGroupes && setDatasTable(data.abonnementsActifsGroupes)
        }
      })      
  }

  return <>    
    <div className='flex flex-col flex-1 h-full'>
      <>
        <TableContrat loading={loadingAbonnementsGroupes} datas={datasTable} onClickGroup={onClickGroup} onChangeMaskVente={(value)=>{reloadTable(value)}} withOutVente={withOutVente} />
        <Modal
          isOpen={detailIsOpen}
          onClose={() => setDetailIsOpen(false)}
          noPadding={true}
          content={
            {
              title: titleModalDetail,
              body: <><ModalContratBody idAbo={idAbo} withOutVente={withOutVente} /></>,
              footer: <><ModalContratFooter /></>,
            }
          }
          className="w-full lg:w-5/6"
        />
      </>       
    </div>
  </>

}