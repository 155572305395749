const primaryClikkerPalette = {
  50: '#f9eeeb',
  100: '#f3ddd7',
  200: '#e7baae',
  300: '#dc9886',
  400: '#d0755d',
  500: '#c45335',
  600: '#b52802',
  700: '#912002',
  800: '#6d1801',
  900: '#481001',
};

// GRAY
const secondaryGrayClikkerPalette = {
  50: "#F5F5F5",
  100: "#EBEAEB",
  200: "#D4D3D4",
  300: "#BFBEBF",
  400: "#A4A2A4",
  500: "#888688",
  600: "#6F6D6F",
  700: "#525152",
  800: "#363536",
  900: "#1C1C1C",
  950: "#0D0D0D",
};


export default {
  primary: primaryClikkerPalette,
  secondary: secondaryGrayClikkerPalette,
};
