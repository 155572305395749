import React, { useMemo } from 'react'
import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'

import { CompteType } from '../../../../types/CompteType'
import { useP } from '../../../../services/i18n'

type SelectedSiteProps = {
  value: string | string[] | undefined,
  selected: CompteType,
  fieldLabel: string,
  comptes: Array<CompteType>,
}

function SelectedSite({ value, selected, fieldLabel, comptes }: SelectedSiteProps) {

  const p = useP();

  const label = useMemo(() => {

    if (Array.isArray(value) && value.length === 1) {
      const compte = comptes.find((c) => c.id === value[0])
      return compte ? compte[fieldLabel] : `${value.length} ${p.t('selectedComptes.comptes')}`;
    }

    if (Array.isArray(value)) {
      return `${value.length} ${p.t('selectedComptes.comptes')}`;
    }

    return selected && selected[`${fieldLabel}`];
  }, [value, selected])

  return (
    <Listbox.Button className="relative w-full lg:w-52 bg-white/10 text-md shadow-sm pl-3 pr-10 text-left focus:ring-1 focus:ring-primary-500 focus:border-primary-500 transition ease-in-out duration-150 text-white rounded-md py-2 px-3 hover:bg-primary-600 text-sm font-medium focus:outline-none focus:bg-primary-600">
      <span className="block truncate">{label}</span>
      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
    </Listbox.Button>
  )
}

export default SelectedSite
