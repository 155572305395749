import clsx from 'clsx';
import React, { forwardRef, Ref } from 'react'
import { InputType } from './Types/InputType';


export const Input = forwardRef(({ className, ref, label, name, register = () => null, errors = [], onChange, onKeyDown, ...props }: InputType) => (
  <>
    {label &&
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>}
    <input
      ref={ref}
      {...props}
      {...register(name)}
      name={name}
      className={clsx(
        errors && errors[name]
          ? `focus:ring-red-600 focus:border-red-600 `
          : `focus:ring-primary-500 focus:border-primary-500 `,
        `shadow-sm block w-full sm:text-sm border-gray-300 rounded-md`,
        className)}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
    {
      errors && errors[name] && <div className='text-red-600 text-xs font-medium mt-0.5'>{errors[name].message}</div>
    }
  </>
));
Input.displayName = "Input"
