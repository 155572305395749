import React from 'react'
import { useLazyQuery } from '@apollo/client'
import Constants from '../../../../../commons/Constants'
import Button from '../../../../../Components/Button'
import Spinner from '../../../../../Components/Spinner'
import { toastError } from '../../../../../Components/Utils/Utils'
import { useP } from '../../../../../services/i18n'
import { TELECHARGER_CDR_GSM } from '../../../queries'

type ActionTelechargerCdrType = {
  ligne: any,
  prestationId: number | string
}

const {
  FILER_URL,
} = Constants;

export default function ActionTelechargerCdr({ ligne, prestationId }: ActionTelechargerCdrType) {

  const p = useP();

  const onTelechargerCdr = () => {
    telechargerCdr({
      variables: { msisdn: ligne.msisdn, prestationId }
    })
  }

  // Téléchargement des CDR
  const [telechargerCdr, { loading: loadingCdr }] = useLazyQuery(TELECHARGER_CDR_GSM, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data.telechargerCdrGsmLigne) {
        const { hash, filename } = data.telechargerCdrGsmLigne
        window.location.href = `${FILER_URL}?hash=${hash}&filename=${filename}`
      } else {
        toastError(p.t('factures.defaultMessage'))
      }
    },
  });

  return <div className=''>
    <Button
      disabled={loadingCdr}
      color='primary'
      size='small'
      onClick={onTelechargerCdr}>
      {loadingCdr ? <Spinner /> :
        p.t('prestations.gsm.actions.telechargerCdr')}
    </Button>
  </div>
}