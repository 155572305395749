import { multipleExistInArray } from "../../../../Components/Utils/Utils";

const datasGeojsonReducer = (datas) => {
  const mapFeatures = [];
  const map = new Map();

  datas.forEach((prestationLien) => {

    const { site, logins, loginStates, loginStatesDates, loginSupervisionActives, idLien, type } = prestationLien;

    // Map avec les coordonnées en tant que clé
    if (site?.latitude && site?.longitude) {
      const coords = `${site.latitude};${site.longitude}`
      if (!map.has(coords)) {
        map.set(coords, { latitude: site.latitude, longitude: site.longitude, sites: new Map(), statut: [] })
      }
      const mapCoords = map.get(coords)

      // Map des sites
      const sites = mapCoords.sites;
      if (!sites.has(site.id)) {
        sites.set(site.id, {
          id: site.id,
          nom: site.nom,
          numeroVoie: site.numeroVoie,
          libelleVoie: site.libelleVoie,
          complementVoie: site.complementVoie,
          codePostal: site.codePostal,
          commune: site.commune,
          liens: []
        });
      }
      const mapSites = sites.get(site.id);

      // Détermination de l'état des liens en fonction de celui des logins


      let statutLien;

      const loginStatesSuper = loginStates.filter((state, i) => loginSupervisionActives[i])

      if (loginStatesSuper.length > 1) {
        const ismixte = multipleExistInArray(loginStatesSuper, [true, false])
        statutLien = ismixte ? "MIXTE" : loginStatesSuper[0] ? "CONNECTE" : "DECONNECTE"
      } else {
        let etatLien = true;
        loginStatesSuper?.forEach((loginState) => {
          etatLien = loginState && loginState
        });
        statutLien = etatLien ? "CONNECTE" : "DECONNECTE";
      }

      if (!loginSupervisionActives.includes(true)) {
        statutLien = "NO_SUPERVISION";
      }

      const liens = mapSites.liens;

      // tableau des liens du site
      liens.push({
        id: idLien,
        prestationId: prestationLien.id,
        libelle: type,
        numeroRaccordement: prestationLien.numeroRaccordement,
        statut: statutLien,
        date: loginStatesDates,
        logins: logins,
        loginStates: loginStates,
        loginSupervisionActives
      });

      const statuts = mapCoords.statut;

      if (!statuts.includes(statutLien)) {
        statuts.push(statutLien)
      }
    }

  });

  // Constitution du tableau de features de l'objet GEOJSON pour les données de la carte
  map.forEach((point) => {

    const statusSansSuper = point.statut.filter((s) => s !== 'NO_SUPERVISION')
    let statutPoint = '';
    let statutClusterPoint = '';
    if (statusSansSuper.length === 0) {
      statutPoint = 'NO_SUPERVISION';
      statutClusterPoint = 'CONNECTE';
    } else {
      statutPoint = statusSansSuper.length > 1 ? 'MIXTE' : statusSansSuper[0];
      statutClusterPoint = statutPoint;
    }

    mapFeatures.push(
      {
        "type": "Feature",
        "properties": {
          "statut": statutPoint,
          "statutCluster": statutClusterPoint,
          "sites": [...point.sites.values()]
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            point.longitude,
            point.latitude
          ]
        }
      }
    )
  })

  return {
    "type": "FeatureCollection",
    "features": mapFeatures
  }

}

export {
  datasGeojsonReducer
}
