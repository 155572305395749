import React from "react";

type DataBadgeValueType = {
  label : string,
  value:string
}

type BadgeValueType = {
  color:string,
  data:DataBadgeValueType
}
export default function BadgeValue({color,data}:BadgeValueType){
  return <>
  <div className="flex items-center">
    <div className={`text-${color}-800 font-medium bg-${color}-200 px-2 rounded-l-md border border-${color}-800 border-r-0 relative z-20`}>{data.label}</div>
    <div className="relative">
      <div className={`bg-${color}-200 h-3 w-3 rotate-45 absolute -translate-y-1.5 -translate-x-1.5 z-10`}></div>
    </div>
    <div className={`bg-${color}-100 rounded-r-md px-2 text-${color}-800 font-medium border border-${color}-800 border-l-0 pl-3`}>{data.value}</div>
  </div>
  </>
}