import React from 'react'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import MobileMenuButton from './MobileMenuButton'
import { useP } from '../../../services/i18n'

type MobileMenuButtonProps = {
  open: boolean
}

function MobileMenuButtons({ open }: MobileMenuButtonProps) {

  const p = useP();

  return (
    <div className="flex lg:hidden">
      <Disclosure.Button className="bg-primary-800 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-800 focus:ring-white">
        <span className="sr-only">{p.t('header.actions.openMainMenu')}</span>
        {open ? (
          <MobileMenuButton icon={<XMarkIcon />} />
        ) : (
          <MobileMenuButton icon={<Bars3Icon />} />
        )}
      </Disclosure.Button>
    </div>
  )
}

export default MobileMenuButtons