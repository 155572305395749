import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentAnnuaire_Q1 from "./HelpContent/HelpContentAnnuaire_Q1";
import HelpContentAnnuaire_Q2 from "./HelpContent/HelpContentAnnuaire_Q2";

export default function HelpAnnuaire(){
  const p = useP();
  const datas = [{
    libelle : p.t('helpBox.helpAnnuaire.q1'),
    content : <HelpContentAnnuaire_Q1 />
  },{
    libelle : p.t('helpBox.helpAnnuaire.q2'),
    content : <HelpContentAnnuaire_Q2 />
  }]
  return <>
      <HelpBoxAcordions datas={datas} />
  </>
}