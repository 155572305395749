import React from "react";
import { TicketType } from "../types/TicketType";
import TicketCommentaireDiscussion from "../Shell/Support/ModaleDetailTicket/TicketCommentaireDiscussion";

type TicketPublicCommentairesType = {
  ticket: TicketType,
}

function TicketPublicCommentaires({ ticket }: TicketPublicCommentairesType) {

  return <TicketCommentaireDiscussion
    ticketId={ticket.id}
    ticketInitialState={ticket.ticketStatut.id}
    canAddCommentaire={false}
    enabledLectureTicket={false}
    attachmentsHidden={true}
  />

}

export default TicketPublicCommentaires;

