import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useP } from '../../../services/i18n';
import toast from 'react-hot-toast';
import ChangePasswordContainer from '../../../ChangePassword/ChangePasswordContainer/ChangePasswordContainer';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from '../queries';
import { toastError } from '../../../Components/Utils/Utils';

type ChangeProfilePasswordModaleType = {
  isOpen: boolean,
  onClose: () => void,
}

function ChangeProfilePasswordModale(props: ChangeProfilePasswordModaleType) {

  const p = useP();

  const [passwordLevel, setPasswordLevel] = useState<number>(0);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [checkNewPassword, setCheckNewPassword] = useState<string>('');

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  // const onKeyDown = (event) => {

  //   const { key } = event;

  //   switch (key) {
  //     case 'Enter':
  //       if (currentPassword && newPassword && checkNewPassword) {
  //         submitForm();
  //       }

  //       break;

  //     case 'Escape':
  //       props.setOpen(false);
  //       break;
  //   }

  // }

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   submitForm();
  // }

  const submitForm = () => {

    if (currentPassword === newPassword) {
      toastError(p.t('changePassword.toasts.changePassword.differentPasswordRequired'));
      return;
    }

    if (newPassword === checkNewPassword) {
      toast.promise(
        changePassword({
          variables: {
            currentPassword,
            newPassword
          }
        }),
        {
          loading: p.t('changePassword.toasts.changePassword.changePasswordLoading'),
          success: (res) => {
            if (!res.data?.changePassword?.success) throw new Error();

            props.onClose();

            return p.t('changePassword.toasts.changePassword.changePasswordSucces');
          },
          error: p.t('changePassword.toasts.changePassword.changePasswordError')
        }
      );
    }
    else {
      toastError(p.t('changePassword.toasts.changePassword.notSamePasswords'))
    }
  }

  return (
    <>

      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => false}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 px-6">
                  <div className="min-h-full flex bg-white">
                    <ChangePasswordContainer submit={submitForm} onClose={() => props.onClose()} currentPassword={currentPassword} setCurrentPassword={setCurrentPassword} password={newPassword} setPassword={setNewPassword} checkPassword={checkNewPassword} setCheckPassword={setCheckNewPassword} passwordLevel={passwordLevel} setPasswordLevel={setPasswordLevel} loggedIn={true} canCancel={true} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )

}

export default ChangeProfilePasswordModale;