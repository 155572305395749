import React, { useState } from 'react'
import { useP } from "../../../services/i18n";
import { useQuery } from "@apollo/client";
import Spinner from "../../Spinner";
import Table from "../../Table/Table";
import { EmptyBonDeCommandeModele } from '../../../types/BonDeCommandeModeleType';
import Button from '../../Button';
import WidgetElementType from '../WidgetElementType';
import { GET_ABONNEMENTS_A_COMMANDER } from '../../../Shell/Compte/DashboardCompte/queries';
import CommandeGSM from '../../../Shell/CommandeGSM/CommandeGSM';
import WidgetSize from '../WidgetSize';
import WidgetLayout from '../WidgetLayout/WidgetLayout';
import CommandeArrow from '../../../Shell/CommandeArrow/CommandeArrow';
import { useCheckFeatureFlag } from '../../CheckFeatureFlag/CheckFeatureFlag';
import Constants from '../../../commons/Constants';

export default function WidgetCommandePrestationWide(props: WidgetElementType) {

  const p = useP();
  const { checkFeatureFlag } = useCheckFeatureFlag();
  const { BDC_FOURNISSEUR_ALPHALINK, BDC_FOURNISSEUR_ARROW, BDC_FOURNISSEUR_EIT } = Constants;

  // Modale de commande GSM
  const [openCommandeGSM, setOpenCommandeGSM] = useState(false)
  const [openCommandeArrow, setOpenCommandeArrow] = useState(false)
  const [idAbonnement, setIdAbonnement] = useState(0)
  const [bonDeCommandeModele, setBonDeCommandeModele] = useState(EmptyBonDeCommandeModele)

  const onClickButtonCde = (line) => {
    if (line?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ALPHALINK || line?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_EIT) {
      setOpenCommandeGSM(true)
    } else {
      setOpenCommandeArrow(true)
    }
    setIdAbonnement(line.idAbo);
    setBonDeCommandeModele(line.bonDeCommandeModele ? line.bonDeCommandeModele : EmptyBonDeCommandeModele)
  }

  const { loading, data } = useQuery(GET_ABONNEMENTS_A_COMMANDER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const columnsTableDetail = [
    {
      libelle: 'ID',
      code: "idAbo",
      colType: "string",
      visible: false
    },
    {
      libelle: p.t('dashboardCompte.commandeLignes.columns.produit'),
      code: "nomProduit",
      colType: "",
      width: "330px",
      sortable: true,
      customFormatter: (datas) => (
        <div className='truncate'>
          <div><div className='text-gray-900 truncate pr-2' id='nomProduit'>{datas.nomProduit}</div></div>
          <div className='text-xs truncate pr-4'>{datas.nomProduit != datas.description && datas.description}</div>
          {datas?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ALPHALINK && datas.quantite > 0 &&
            <div className='my-1'><Button onClick={() => onClickButtonCde(datas)} size='small' color='primaryBordered'>{p.t('commandeGSM.buttonModalTitle')}</Button></div>
          }
          {datas?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_EIT && datas.quantite > 0 &&
            <div className='my-1'><Button onClick={() => onClickButtonCde(datas)} size='small' color='primaryBordered'>{p.t('commandeGSM.buttonModalTitle')}</Button></div>
          }


          {datas?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ARROW && datas.quantite > 0 &&
            <div className='my-1'><Button onClick={() => onClickButtonCde(datas)} size='small' color='primaryBordered'>{p.t('commandeArrow.buttonModalTitle')}</Button></div>
          }
        </div >
      ),
    },
    {
      libelle: p.t('dashboardCompte.commandeLignes.columns.contrat'),
      code: "infosContrat",
      colType: "",
      sortable: true,
      customFormatter: (datas) => (
        <div className='truncate'>
          <div><div className='text-gray-900 truncate pr-2'>{datas.numcli}</div></div>
          <div className='text-xs truncate pr-4'>{datas.nomContrat}</div>
        </div>
      ),
    },
    {
      libelle: p.t('dashboardCompte.commandeLignes.columns.siteEtGroupe'),
      code: "site",
      colType: "",
      customFormatter: (datas) => (
        <div className='truncate'>
          <div><div className='text-gray-900 truncate pr-2'>{datas.site}</div></div>
          <div className='text-xs truncate pr-4'>{datas.groupe != datas.site && datas.groupe}</div>
        </div>
      ),
    },
    {
      libelle: p.t('dashboardCompte.commandeLignes.columns.reference'),
      code: "reference",
      colType: "",
      sortable: true,
      customFormatter: (datas) => (
        <div className='truncate'>
          <div><div className='text-gray-900 truncate pr-2'>{datas.reference}</div></div>
          <div className='text-xs truncate pr-4'>{datas.referenceDevis}</div>
        </div>
      ),
    },
    {
      libelle: p.t('dashboardCompte.commandeLignes.columns.qte'),
      code: "quantite",
      colType: "number",
      sortable: true,
    },
    {
      libelle: p.t('dashboardCompte.commandeLignes.columns.qteAlivrer'),
      code: "quantiteALivrer",
      colType: "number",
      sortable: true,
    },
    {
      libelle: p.t('dashboardCompte.commandeLignes.columns.prixUnit'),
      code: "prixUnitaire",
      colType: "money",
      resizable: false,
      sortable: true,
    },
  ]

  return (
    <WidgetLayout
      header={
        <div>
          {p.t('dashboardCompte.commandeLignes.titre')}
        </div>
      }
      size={WidgetSize.WIDE}
      addWidget={props.addWidget}
      moveUpWidget={props.moveUpWidget}
      moveDownWidget={props.moveDownWidget}
      removeWidget={props.removeWidget}
    >
      <div className="">
        {
          loading
            ? (<div className="flex items-center justify-center h-full w-full"><Spinner /></div>)
            : (
              <>
                <Table
                  idTable="detail_produits"
                  loading={false}
                  columns={columnsTableDetail}
                  datas={data?.abonnementsActifsACommander}
                  options={{
                    pagination: false,
                    search: {
                      placeholder: p.t('dashboardCompte.commandeLignes.tableSearchPlaceholder'),
                      attributs: ["nomProduit", "description", "nomContrat", "numcli", "site", "reference", "referenceDevis"]
                    },
                    fullBordered: false,
                    resizable: true,
                    toolbar: true,
                    naturalWidth: false,
                    valign: 'top'
                  }}
                />
                <CommandeGSM bonDeCommandeModele={bonDeCommandeModele} abonnementId={idAbonnement} isOpen={openCommandeGSM} onCloseModalCommandeGSM={() => setOpenCommandeGSM(false)} />
                <CommandeArrow bonDeCommandeModele={bonDeCommandeModele} abonnementId={idAbonnement} isOpen={openCommandeArrow} onCloseModalCommandeArrow={() => setOpenCommandeArrow(false)} downPossible={checkFeatureFlag("ARROW_COMMANDE")} />
              </>
            )
        }

      </div>
    </WidgetLayout>
  )

}