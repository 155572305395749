import React, { useEffect, useRef } from "react"
import { dataDiscussionsType, modeAffichageFilType } from "./Discussions"
import Discussion from "./Discussion"
import { DocumentNode } from "graphql"

/** Fil de discussion */
type FilDiscussionsType = {
  discussions: Array<dataDiscussionsType>,
  modeAffichageFil: modeAffichageFilType,
  queryDownload: DocumentNode,
  onToggle?: () => void,
  getLastChild?: (child: any) => void,
  attachmentsHidden?: boolean
}

export default function FilDiscussions({ discussions, modeAffichageFil, queryDownload, onToggle, getLastChild, attachmentsHidden = false }: FilDiscussionsType) {
  const lastDiscussionRef = useRef(null);
  useEffect(() => {
    if (lastDiscussionRef.current) {
      getLastChild?.(lastDiscussionRef.current);
    }
  }, [discussions]);
  return <>
    {
      modeAffichageFil === "LAST_MORE" &&
      <>
        <div className="flex flex-col space-y-4">
          {
            discussions.map((discussion, index) => {
              const isLastDiscussion = index === discussions.length - 1;
              return (
                <Discussion
                  reference={isLastDiscussion ? lastDiscussionRef : null}
                  onToggle={onToggle}
                  key={index}
                  isOpen={isLastDiscussion ? true : discussion.dateLecture ? false : true}
                  discussion={discussion}
                  queryDownload={queryDownload}
                  attachmentsHidden={attachmentsHidden} />
              )
            }
            )
          }
        </div>
      </>
    }

  </>
}
