import React, { useEffect, useState } from 'react'
import Discussions from '../../../Components/Discussions/Discussions';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_COMMENTAIRES, NEW_TICKET_COMMENTAIRE, TELECHARGER_PJ } from './queries';
import Error from '../../../Components/Error/Error';
import { toastError } from '../../../Components/Utils/Utils';
import { useP } from '../../../services/i18n';
import dayjs from 'dayjs';
import axios from 'axios';
import Constants from '../../../commons/Constants';
import { LECTURE_TICKET } from './queries';
import parse from 'html-react-parser';
import Logo from '../../../Components/Logo';
import sanitizeHtml from 'sanitize-html';

type TicketCommentaireDiscussionType = {
  ticketId: number,
  ticketInitialState: number,
  canAddCommentaire?: boolean,
  refetchTicket?: () => void,
  onChangeNewCommentaire?: (commentaire: string, files: any[]) => void
  enabledLectureTicket?: boolean,
  attachmentsHidden?: boolean
}

export default function TicketCommentaireDiscussion({ ticketId, ticketInitialState, canAddCommentaire = true, enabledLectureTicket = true, refetchTicket, onChangeNewCommentaire, attachmentsHidden = false }: TicketCommentaireDiscussionType) {

  const p = useP();

  const {
    FILER_URL_UPLOAD_SUPPORT
  } = Constants;

  const [getCommentaires, { loading: loadingCommentaire, data: dataCommentaire, error: errorCommentaire, refetch: refetchCommentaire }] =
    useLazyQuery(GET_COMMENTAIRES, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

  const [newTicketCommentaire, { loading: loadingNewCommentaire }] = useMutation(NEW_TICKET_COMMENTAIRE)
  const [lectureTicket] = useMutation(LECTURE_TICKET);

  const [commentaires, setCommentaires] = useState([])

  useEffect(() => {
    if (ticketId) {
      getCommentaires({
        variables: {
          ticketId
        },
        onCompleted: () => enabledLectureTicket && lectureTicket({
          variables: {
            ticketId
          }
        })
      });
    }
  }, [ticketId])

  useEffect(() => {

    const comm = dataCommentaire?.ticketCommentairesWithMaitre?.map((c) => {
      const auteur = c.contact ? {
        nom: `${c.contact.prenom} ${c.contact.nom}`,
        icon: `${c.contact.prenom.substring(0, 1).toUpperCase()}${c.contact.nom.substring(0, 1).toUpperCase()}`,
        iconColor: "bg-secondary-600",
        bgClass: "bg-gray-100",
      } : {
        nom: c.ticketeurUser ? c.ticketeurUser.prenomNomContact : `Blue`,
        icon: <Logo type="tiny" height={30} width={30} />,
        iconColor: "bg-primary-600",
        bgClass: "bg-primary-100",
      }

      const ticketMaitre = c.ticket.id === ticketId ? "" : `[TICKET MAITRE ${c.ticket.numero}] - `

      return {
        auteur: `${ticketMaitre}${auteur.nom}`,
        date: c.dateCreation,
        contenu: <div style={{ whiteSpace: 'pre-line' }}>
          {parse(sanitizeHtml(c.descriptionWithImg || '', {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),        
            allowedAttributes: {'img': ['src']},
            allowedSchemes: [ 'data', 'http', 'https'],
            disallowedTagsMode:"escape"
          }))}</div>,
        contenuOneLine: c.description?.replace(/\n/g, " "),
        auteurIcon: auteur.icon,
        colorClassIcon: auteur.iconColor,
        bgClass: auteur.bgClass,
        dateLecture: c.dateLecture,
        fichiers: c.ticketPieceJointes.map((pj) => ({
          id: pj.id,
          extension: pj.extensionFichier,
          name: pj.fichier,
          date: dayjs(pj.dateCreation).format("DD/MM/YY HH:mm"),
        }))
      }
    }) || []

    setCommentaires(comm)
  }, [dataCommentaire])

  if (errorCommentaire) {
    return <Error />
  }

  const saveMessage = async (message: string, files: any[]) => {

    const formData = new FormData();
    files.forEach((f) => {
      formData.append('files', f, encodeURIComponent(f.name));
    })
    formData.append('ticketId', ticketId.toString());

    const res = await axios.post(FILER_URL_UPLOAD_SUPPORT || "", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })

    if (res.status !== 200) {
      toastError(p.t('error.defaultMessage'));
      return false;
    }

    const { data } = res;

    const result = await newTicketCommentaire({
      variables: {
        ticketId: ticketId,
        commentaire: message,
        files: data.files ? data.files.map((f: string) => decodeURIComponent(f)) : []
      },
      onCompleted: (data) => {
        if (data?.newTicketCommentaireClient?.success) {
          data?.newTicketCommentaireClient?.ticketCommentaire?.ticket?.ticketStatut?.id !== ticketInitialState ?
            refetchTicket?.() :
            refetchCommentaire()
        } else {
          toastError(p.t('error.defaultMessage'));
        }
      },
      onError: () => {
        toastError(p.t('error.defaultMessage'));
      }
    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
    })

    return result?.data?.newTicketCommentaireClient?.success || false
  }

  return <Discussions
    discussions={commentaires}
    toolbarNewMessageOptions={canAddCommentaire ? { pj: true, cc: false, colorClassIcon: "bg-primary-600" } : false}
    saveMessage={saveMessage}
    loadingMessage={loadingCommentaire}
    loadingSendMessage={loadingNewCommentaire}
    queryDownload={TELECHARGER_PJ}
    onChangeNewCommentaire={onChangeNewCommentaire}
    attachmentsHidden={attachmentsHidden}
  />
}