import React, { useState } from 'react';
import { useP } from '../../../services/i18n';
import Button from '../../Button';
import Modal from '../../Modal/Modal';
import WidgetSelectionModaleBody from './WidgetSelectionModaleBody';

type WidgetSelectionModaleType = {
  widgets: Array<any>,
  isOpen?: boolean,
  onClose: () => void,
  onSubmit: (code: string) => void
}

function WidgetSelectionModale({ widgets, isOpen = false, onClose, onSubmit }: WidgetSelectionModaleType) {

  const p = useP();

  const [selectedWidget, setSelectedWidget] = useState<any>('')

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: (
            <>
              <div className='flex items-center'>
                <div>{p.t('widgets.selection.title')}</div>
              </div>
            </>
          ),
          body: <WidgetSelectionModaleBody widgets={widgets} value={selectedWidget} onChange={(widget) => setSelectedWidget(widget)} />,
          footer: (
            <div className='flex flex-row space-x-2 items-center justify-end'>
              <Button color="primaryLight" onClick={onClose}>
                {p.t('actions.fermer')}
              </Button>
              <Button onClick={() => onSubmit(selectedWidget.code)}>
                {p.t('actions.valider')}
              </Button>
            </div>
          ),
        }}
        className="w-full lg:w-1/4 lg:h-3/5"
      />
    </>
  )
}

export default WidgetSelectionModale