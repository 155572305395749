import React from 'react'

import clsx from 'clsx'
import WidgetCardHeaderMenu from './WidgetCardHeaderMenu'

type WidgetCardHeaderType = {
  libelle: string,
  Icon: any,
  number?: string | number | null | undefined,
  color: string
  header: any,
  addWidget: any,
  moveUpWidget: any,
  moveDownWidget: any,
  removeWidget: any
}


export default function WidgetCardHeader({ header, libelle, Icon, number = null, color, addWidget, moveUpWidget, moveDownWidget, removeWidget }: WidgetCardHeaderType) {
  return (
    <div className='flex flex-row justify-between'>
      {
        header
          ? <div className='flex-1'>{header}</div>
          : (
            <div className="flex items-center relative">

              <div className={clsx(`bg-${color}-200 p-3 rounded-md`)}>
                <Icon className={clsx(`text-${color}-800 h-6 w-6`)} />
              </div>

              <div className="ml-4">
                {number && <div className="text-2xl leading-6">{number}</div>}
                <div className="text-lg">{libelle}</div>
              </div>
            </div>
          )
      }
      <div className='cursor-pointer'>
        <WidgetCardHeaderMenu
          addWidget={addWidget}
          moveUpWidget={moveUpWidget}
          moveDownWidget={moveDownWidget}
          removeWidget={removeWidget}
        />
      </div>
    </div>
  )
}