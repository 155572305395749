import React, { Fragment } from 'react'
import clsx from 'clsx'
import { Info } from 'react-bootstrap-icons'
import Tooltip from '../../../Components/Tooltip/Tooltip'
import LoginState from '../LoginState'
import dayjs from 'dayjs'
import { useP } from '../../../services/i18n'
import Badge from '../../../Components/Badge'
import { ArrowSmallDownIcon } from '@heroicons/react/24/outline'

type TabNavigationLoginsType = {
  login: any,
  loginCount: number
}

export default function TabNavigationLogins({ login, loginCount }: TabNavigationLoginsType) {

  const p = useP();
  const lastIpStatut: any = [...login.ips.find((e) => e.isWan).ipStatuts].sort((a, b) => a.horodatage > b.horodatage ? -1 : 1)  
  
  const historicalRecords = [...login.connectedLoginHistoriques].sort((a, b) => a.date > b.date ? -1 : 1);
  const lastDeconnectedRecord = historicalRecords.filter((item)=>item.isConnectedBas===false)[0];
  
  
  return <>
    <div className='flex items-center'>
      <div className={clsx(
        `truncate mr-2`,
        loginCount == 1 ? `text-lg font-semibold ml-2` : ``
      )}>{login.login}</div>
      
      {
        historicalRecords[0]==lastDeconnectedRecord &&
        <div className='flex items-center relative'>
          <Badge bordered itemColor='red' itemLibelle={`${p.t('dashboardCompte.deconnecteDepuis')} : ${dayjs(lastDeconnectedRecord.date).format("DD/MM/YYYY à HH:mm")}`} />
        </div>
      }
      
      <LoginState loginStates={[login.connectedLogin?.isConnected]} pingState={lastIpStatut.length > 0 ? lastIpStatut[0].pingResult : 0} />
      
      <div className={clsx(``)}>
        <Tooltip
          button={<div className={clsx(`border rounded-full bg-primary-100 border-primary-700`)}>
            <Info className='h-5 w-5' />
          </div>}
          content={
            <div className='text-xs text-left'>
              {login.ips.map((i, index) => <Fragment key={index}>{`${i.ipV4} / ${i.subnet} (${i.typeIp.nom})`}  <br /></Fragment>)}
            </div>
          }
          placement='top'
          className='flex items-center justify-center mr-3'
        />

      </div>
    </div>
  </>
}