import React from "react";
import { useP } from "../../../../services/i18n";

export default function HelpDefault() {
  const p = useP()
  return <>
    <div className="text-justify">
      <div>{p.t('components.helpBox.default')}</div>
      <div>{p.t('components.helpBox.default2')}</div>
    </div>
  </>
}