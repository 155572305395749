import React, { ReactElement } from "react"
import Button, { ButtonsColorType } from "./Button"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"

type ButtonDropdownType = {
  libelle: string | null | undefined | ReactElement,
  color?: ButtonsColorType,
  className?: string,
  size?: 'small' | 'normal' | 'large',
}

export default function ButtonDropdown({ libelle, color = "primary", size = "normal", className }: ButtonDropdownType) {
  return <>
    <Button color={color} className={className} size={size}>
      <div className="flex items-center space-x-2">
        <div>{libelle}</div>
        <div><ChevronDownIcon className={clsx(`h-4 w-4`,)} /></div>
      </div>
    </Button>
  </>
}