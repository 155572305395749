import dayjs from 'dayjs';
import React from 'react';
import Badge from '../../../../Components/Badge';
import Button from '../../../../Components/Button';
import DescriptionList, { DescriptionListItemAlignType } from '../../../../Components/DescriptionList/DescriptionList';
import Modal from '../../../../Components/Modal/Modal';
import { useP } from '../../../../services/i18n';
import PrestationDetailModaleBody from '../../PrestationDetail/PrestationDetailModaleBody';
import PrestationHistoriqueModaleBody from '../../PrestationDetail/PrestationHistoriqueModaleBody';

type PrestationDetailModaleVGAType = {
  isOpen: boolean,
  prestation: any,
  onClose: () => void
}

function PrestationDetailModaleVGA({ prestation, isOpen = false, onClose }: PrestationDetailModaleVGAType) {
  const p = useP();

  const alignTop: DescriptionListItemAlignType = "top"

  const infosVGA =
    [
      {
        label: p.t('prestations.vga.detail.NDI'),
        value: prestation?.numero,
      },
      {
        label: p.t('prestations.vga.detail.SDA'),
        value: prestation?.sda,
      },
      {
        label: p.t('prestations.vga.detail.nom'),
        value: prestation?.site?.nom,
      },
      {
        label: p.t('prestations.vga.detail.type'),
        value: <>
          <div>
            <div>{prestation?.type}</div>
            <div className='text-gray-500 text-xs'>{prestation.typeLigne === "TL" ? p.t('prestations.vga.accesIsole') : p.t('prestations.vga.groupementAcces')}</div>
          </div>
        </>,
        align: alignTop
      },
      {
        label: p.t('prestations.vga.detail.statut'),
        value: prestation?.statut
      },
      {
        label: p.t('prestations.vga.detail.date_création'),
        value: dayjs(prestation?.dateCreation).format("DD/MM/YYYY")
      },
      {
        label: p.t('prestations.vga.detail.mise_en_service'),
        value: prestation?.dateMiseEnService
      },
    ]

  const VGATabs = {
    navigation: [
      {
        libelle: p.t('prestations.detail.informations'),
        jsx: <>{p.t('prestations.detail.informations')}</>
      },
      {
        libelle: p.t('prestations.detail.historique'),
        jsx: <>{p.t('prestations.detail.historique')}</>
      }
    ],
    panels: [
      <DescriptionList datas={infosVGA} key="Infos_VGA" columnWidthClassName='w-[134px]' />,
      <PrestationHistoriqueModaleBody key="Historique_VGA" prestationId={prestation.id} />,
    ]

  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('prestations.vga.detail.title')}</div>
              <div className='ml-1'>{prestation?.numero}</div>
              <div className='ml-2'><Badge itemLibelle={prestation?.etat?.libelle} itemColor={prestation?.etatColor} /></div>
            </div>
            <div className="text-xs text-gray-600 mt-1">
              <div>{prestation?.site?.nom}</div>
              <div>{prestation?.site?.codePostal} {prestation?.site?.commune}</div>
            </div>
          </>
          ,
          body: <PrestationDetailModaleBody tabsNavigation={VGATabs.navigation} tabsPanel={VGATabs.panels} />,
          footer: <><div className='flex items-center justify-end'>
            <Button onClick={onClose}>
              {p.t('actions.fermer')}
            </Button>
          </div></>,
        }}
        className="w-full lg:w-6/12 lg:h-5/6"
      />
    </>
  )
}

export default PrestationDetailModaleVGA