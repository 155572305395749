import React from 'react';

import { useMutation } from '@apollo/client';
import { useBeforeUnload } from 'react-router-dom';

import { UPDATE_SESSION_TIME } from '../queries';

const SessionHandler = () => {

    const [updateSessionTime] = useMutation(UPDATE_SESSION_TIME);

    useBeforeUnload(async () => {
        const sessionId = sessionStorage.getItem('SessionId');
        if (!sessionId) return;

        await updateSessionTime({
            variables: {
                input: {
                    id: sessionId
                }
            }
        })
    });

    return null;
};

export default SessionHandler
