import React, { useState } from 'react'
import Button from '../Button'
import Card from '../Card'
type MapLegendType = {
  JSXLegendComponent: JSX.Element
}
export default function MapLegend({ JSXLegendComponent }: MapLegendType) {
  const [isOpen, setIsOpen] = useState(false);
  return <>
    <div className='hidden lg:block absolute z-10 right-2 top-2'>
      <div className='flex items-center justify-end mb-2'>
        <Button onClick={() => setIsOpen(!isOpen)} className='shadow-md' color='white'>Légende</Button>
      </div>
      {
        isOpen &&
        <Card>
          {JSXLegendComponent}
        </Card>
      }
    </div>
    {/* <div className='hidden lg:block absolute z-10 right-2 top-2'>
      <Card>
        {JSXLegendComponent}
      </Card>
    </div> */}
  </>
}