import { ArrowRightIcon, BuildingOfficeIcon, GlobeAltIcon } from "@heroicons/react/24/outline"
import React, { useContext } from "react"
import { authContext } from "../../../AuthContext"

type PrestationType = {
  prestations: any
}
function Prestation({ prestations }: PrestationType) {
  const {
    selectedCompte,
  } = useContext(authContext)
  return (
    <div className="flex items-start space-x-3">
      <div className="text-secondary-600 pt-[2px]">
        <GlobeAltIcon className="w-5 h-5 " />
      </div>
      <div className="flex flex-col space-y-2">
        {prestations.map(prestation => (
          <a
            href={`/compte/${selectedCompte}/lien/${prestation.prestation.id}`}
            target="_blank"
            key={prestation.id}
            className="group cursor-pointer flex items-center space-x-3" rel="noreferrer"
          >
            <div className="group-hover:text-primary-600">
              <div className="font-semibold text-gray-900 group-hover:text-primary-600">{prestation.referenceFournisseur}</div>
              <div>{prestation.prestation.libelle}</div>
            </div>
            <div className="hidden group-hover:block mb-1"><ArrowRightIcon className="w-4 h-4 text-primary-600" /></div>
          </a>
        ))}
      </div>
    </div>
  );
}

type SiteType = {
  site: any
}
function Site({ site }: SiteType) {
  return (
    <div className="flex items-start space-x-3 mt-2">
      <div className="text-secondary-600 pt-[2px]">
        <BuildingOfficeIcon className="w-5 h-5 " />
      </div>
      <div>
        {site.nom}<br />
        {site.numeroVoie} {site.libelleVoie}<br />
        {site.codePostal} {site.commune}
      </div>
    </div>
  );
}

type ModaleDetailTPBodyType = {
  row: any
}
export default function ModaleDetailTPBody({ row }: ModaleDetailTPBodyType) {

  const prestationsGroupBySites = new Map();

  row.liens.forEach(element => {
    const siteKey = JSON.stringify(element.prestation.site); // Convertir l'objet site en chaîne JSON pour servir de clé unique
    if (!prestationsGroupBySites.has(siteKey)) {
      prestationsGroupBySites.set(siteKey, {
        site: element.prestation.site, // Stocker les propriétés du site
        elements: [],
      });
    }
    prestationsGroupBySites.get(siteKey).elements.push(element);
  });
  const reducedLiens = Array.from(prestationsGroupBySites.values());

  return <>
    {
      reducedLiens.map((lien, l) => {
        return <div
          className="flex items-center text-sm font-medium text-gray-700 border-b border-gray-300 py-2 px-2 "
          key={l}

        >
          <div className="flex-1">
            <Prestation prestations={lien.elements} />
            <Site site={lien.site} />
          </div>
        </div>
      })
    }

  </>
}