import { gql } from "@apollo/client";

const GET_CONTRATS = gql`
  query Clients($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput, $includes: [IncludeInput]) {
    clients(filters: $filters, sorters: $sorters, pagination: $pagination, includes: $includes) {          
      items {
        numcli
        nom        
        dateDebut
        dateFin
        statutContrat {
          code
          libelle
        }
      }
    }
  }`

const GET_FACTURES = gql`
  query Factures($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput, $includes: [IncludeInput]) {
    factures(filters: $filters, sorters: $sorters, pagination: $pagination, includes: $includes) {          
      items {
        id        
        numcli        
        facture
        mht
        mtt
        dateFacture
        paye
        contrats {          
          numcli
          nom          
        }
        compte{
          raisonSociale
        }
      }
    }
  }`

const TELECHARGER_FACTURE = gql`
  query telechargerFacture($id: ID) {
    telechargerFacture(id: $id) {          
      hash
      filename
    }
  }`

const TELECHARGER_CDR_FIXE = gql`
  query telechargerCdrFixe($id: ID) {
    telechargerCdrFixe(id: $id) {          
      hash
      filename
    }
  }`

const TELECHARGER_CDR_GSM = gql`
  query telechargerCdrGsm($id: ID) {
    telechargerCdrGsm(id: $id) {          
      hash
      filename
    }
  }`

const TELECHARGER_EXPORT = gql`
  query telechargerExport($id: [ID]) {
    telechargerExport(id: $id) {          
      hash
      filename
    }
  }`


export {
  GET_CONTRATS,
  GET_FACTURES,
  TELECHARGER_FACTURE,
  TELECHARGER_CDR_FIXE,
  TELECHARGER_CDR_GSM,
  TELECHARGER_EXPORT,
}