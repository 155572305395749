import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_GRAPH_DATA } from "../queries"
import CyberChartContainer from "./CyberChartContainer"

type CyberChartType = {
  chartType: string,
  chartCode: string,
  compteId: string,
  chartLabel: string
}

export default function CyberChart({ chartType, chartCode, chartLabel, compteId }: CyberChartType) {

  const [chartData, setChartData] = useState<any | null>(null)
  const [chartDateTime, setChartDataTime] = useState<Date | null>(null)

  const { data, loading, error } = useQuery(GET_GRAPH_DATA, {
    fetchPolicy: "network-only",
    variables:
    {
      compteId: compteId,
      graphCode: chartCode
    }
  })

  useEffect(() => {
    if (data?.kpiCyberGraphData) {

      const cyberData = data.kpiCyberGraphData

      setChartData({
        labels: cyberData.map((data: any) => data.key),
        datasets: [
          {
            label: chartLabel,
            data: cyberData.map((data: any) => data.value),
          }
        ]
      })

      if (cyberData.length > 0) {
        setChartDataTime(cyberData[0].datetime)
      }
    }

  }, [data])

  return <CyberChartContainer chartType={chartType} chartData={chartData} chartDateTime={chartDateTime || undefined} loading={loading} error={error} />
}