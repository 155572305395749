import { gql } from "@apollo/client";

const COMMANDER_LIGNE = gql`
  mutation SubmitCommandeGSM($bonCommandeInput: BonCommandeAbonnementInput) {
    submitCommandeGSM(bonCommandeInput: $bonCommandeInput) {
      message
      success
      codeErreur
    }
  }`;

const GET_MSISDNS = gql`
  query GsmMsisdns($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    gsmMsisdns(filters: $filters, sorters: $sorters, pagination: $pagination) {    
      items {
        msisdn
      }
    }
  }
`
const GET_ABONNEMENT_LIVRAISONS = gql`
  query AbonnementLivraisons($filters: [FilterInput], $includes: [IncludeInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    abonnementLivraisons(filters: $filters, includes: $includes, sorters: $sorters, pagination: $pagination) {
      items {
        id
        prestations{
          id
          etat
        }
      }
    }
  }
`

const GET_OPTIONS = gql`
  query JoursFeries($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput, $sortersSite: [SorterInput], $abonnementId: ID) {
    joursFeries(filters: $filters, sorters: $sorters, pagination: $pagination) {
      items {
        date
      }
    }

    sites (sorters: $sortersSite){ 
      id              
      nom
      codePostal
      commune   
      compteId   
    }

    abonnement(id: $abonnementId) {
      description
      devis{
        client{
          compte{
            id
          }
        }
      }
      abonnementGroupe {
        site {
          id
          nom
          codePostal
          commune  
        }
      }
    }
  }
`

export {
  COMMANDER_LIGNE,
  GET_MSISDNS,
  GET_ABONNEMENT_LIVRAISONS,
  GET_OPTIONS,
}