import React from 'react'

export default function PopInformationModalBodyRechargeAuto() {
  return <>
    <div className="text-md px-8 xl:px-24">
      <div className='flex items-start mt-8 space-x-8'>
        <div className='flex-1 text-gray-500'>
          <h2 className='text-xl font-bold leading-6 text-primary-700'>Recharge automatique de la balance DATA des prestations GSM</h2>
        </div>
      </div>
      <div className='flex flex-col xl:flex-row items-start mt-8 space-y-4 xl:space-x-8 xl:space-y-0'>
        <div className='w-full xl:w-4/12 text-gray-500'>
          <div className="mt-4 text-md leading-5 text-gray-500">Sur les lignes GSM avec DATA, il est désormais possible d&apos;activer une recharge automatique de la balance DATA lorsque celle-ci atteint 80% ou 100% du forfait.<br />Le prix de la recharge est indiqué sous l&apos;option.<br /><br />Par défaut, l&apos;option est désactivée.<br /><br /><strong>NB</strong> : Selon l&apos;opérateur, il s&apos;agit d&apos;une recharge intégrale (<strong>Bouygues</strong>) ou recharge d&apos;un volume choisi (<strong>Orange</strong>).</div>
        </div>
        <div className='w-full xl:w-8/12 rounded-lg flex items-center justify-center'>
          <img src="/images/infosDashboard/recharge_data.png" alt="" className='w-[600px] rounded-md shadow-xl border-gray-200 border' />
        </div>
      </div>
    </div>
  </>
}