import React from "react"
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb"
import { BreadcrumbDataType } from "../../Components/Breadcrumb/BreadcrumbDataType"
import Card from "../../Components/Card"
import Treeview from "../../Components/Treeview/Treeview"
import { FolderFill } from "react-bootstrap-icons"
import Spinner from "../../Components/Spinner"
import CyberViewDetail, { CyberDetailType } from "./CyberViewDetail/CyberViewDetail"
import { DataTreeType } from "./Cyber"

type CyberContainerType = {
  loading: boolean,
  breadcrumb: Array<BreadcrumbDataType>,
  chartView: CyberDetailType | null,
  datasTree: DataTreeType,
  onSelectNode: (item: string[]) => void,
  openTreeItems?: string[] | 'all',
}

export default function CyberContainer({ breadcrumb, loading, chartView, datasTree, openTreeItems, onSelectNode }: CyberContainerType) {

  return <>
    <Breadcrumb datas={breadcrumb} />

    {loading ? <div className="w-screen h-screen flex items-center justify-center"><Spinner /></div>
      :
      <div className="flex flex-col space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row flex-1 lg:h-full overflow-auto pb-0.5 px-1">
        <Card className={{ root: "lg:w-1/4 lg:h-full lg:flex lg:flex-col lg:overflow-hidden lg:flex-shrink-0" }} overflow={false}>
          <div className="lg:flex-1 lg:overflow-auto">
            <Treeview
              treeId="KPICyber_Tree"
              datas={datasTree}
              onSelectItems={onSelectNode}
              iconsItem={{
                folderOpen: <FolderFill className="h-4 w-4 text-primary-400" />,
                folder: <FolderFill className="h-4 w-4 text-primary-300" />,
                noChildren: <FolderFill className="h-4 w-4 text-primary-300" />
              }}
              openItems={openTreeItems}
            />
          </div>
        </Card>
        <div className="lg:flex-1 lg:h-full flex flex-col space-y-4">
          {chartView && <CyberViewDetail datasView={chartView} />}
        </div>
      </div>
    }
  </>
}