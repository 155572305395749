import { useQuery } from "@apollo/client";
import React from "react"
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import Card from "../../Components/Card";
import { useP } from "../../services/i18n";
import ShellLayout from "../ShellLayout";
import { GET_RELEASE_NOTES } from "./queries";
import parse from 'html-react-parser';
import dayjs from "dayjs";
import Spinner from "../../Components/Spinner";

export default function ReleaseNotes() {
  const p = useP();
  const { loading, error, data } = useQuery(GET_RELEASE_NOTES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const breadcrumb = [
    {
      libelle: p.t('releaseNotes.title'),
      href: "#",
      current: true
    }
  ]
  return <>
    <ShellLayout>
      <div className="xl:w-1/2 mx-auto flex flex-col"><Breadcrumb datas={breadcrumb} /></div>
      <Card className={{ root: "flex-1 h-full xl:w-1/2 mx-auto flex flex-col" }}>
        <div className="h-full overflow-auto p-2">
          {
            loading ? <><div className="flex items-center justify-center h-full"><Spinner /></div></> : <>
              {
                !error &&
                data?.releaseNotes &&
                data.releaseNotes.map((note) => (
                  <>
                    <div className="flex items-center space-x-2">
                      <h2 className="text-secondary-600 text-lg font-semibold mb-1">
                        {note.titre}
                      </h2>
                      <div className="bg-gray-300 h-1.5 w-1.5 rounded-full"></div>
                      <div className="text-xs text-gray-600">
                        Publié le {dayjs(note.datePublication).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    {parse(note.contenu)}
                    <hr className="my-5" />
                  </>
                ))}
            </>
          }


        </div>
      </Card>
    </ShellLayout>
  </>
}