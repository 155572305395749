import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import React from "react";
import Button from "../../Components/Button";
import Modal from "../../Components/Modal/Modal";
import ModalFooterAction from "../../Components/Modal/ModalFooterAction";
import { useP } from "../../services/i18n";
import ExportsGSM from "../ExportsGSM/ExportsGSM";

type TelechargementsSiteType = {
  openModaleTelechargementsSite : boolean,
  setOpenModaleTelechargementsSite : (bool: boolean) => void,
  idSite : number
}

export default function TelechargementsSite({
  openModaleTelechargementsSite,
  setOpenModaleTelechargementsSite,
  idSite,
}:TelechargementsSiteType){
  const p = useP()
  return <>
  <Button color="transparent" size='small' onClick={() => setOpenModaleTelechargementsSite(true)}>
      <div className='flex items-center'>
        <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
        <div className='mr-2'>{p.t('prestations.boutonTelechargementsSite')}</div>
      </div>
    </Button>
    <Modal
      onClose={() => setOpenModaleTelechargementsSite(false)}
      isOpen={openModaleTelechargementsSite}
      content={{
        title: p.t('prestations.boutonTelechargementsSite'),
        body:
          <>
            <ExportsGSM idSite={idSite} />
          </>,
        footer:
          <div className='flex items-center justify-end'>
            <ModalFooterAction onClickAction={()=>setOpenModaleTelechargementsSite(false)} label={p.t('actions.fermer')} />
          </div>,
      }}
      className="w-full lg:w-2/6"
    />
  </>
}