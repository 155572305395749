import { MagnifyingGlassMinusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ArrowClockwise } from "react-bootstrap-icons";
import { useP } from "../../services/i18n";
import Tooltip from "../Tooltip/Tooltip";

type ResetZoomType = {
  onClick: ()=>void
}

export default function ResetZoom({onClick}:ResetZoomType){
  const p = useP();
  return <>
    <div className='absolute left-[10px] top-[118px] h-7 w-7 bg-white border border-white rounded-md shadow-xl cursor-pointer hover:bg-gray-100 ring-2 ring-black/10' onClick={()=>onClick()}>
        
    <Tooltip
      offset={[0,10]}
      className="" 
      button={
          <div className='relative flex items-center justify-center'>
            <ArrowClockwise className='h-6 w-6 absolute top-0.5 text-gray-800' />
            <MagnifyingGlassMinusIcon className='h-2.5 w-2.5 absolute top-[9px] text-black' />
          </div>
      } 
      withArrow
      content={<div className="text-xs w-[130px] flex items-center justify-center">{p.t('lien.map.resetZoomTooltip')}</div>}
    />
        </div>
    
  </>
}