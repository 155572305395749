import { gql } from "@apollo/client"

const TICKET_REOUVERTURE = gql`
  mutation TicketReouverture($ticketId: ID!, $commentaire: String!) {
    ticketReouverture(ticketId: $ticketId, commentaire: $commentaire) {
      message
      success
    }
  }
`

export {
  TICKET_REOUVERTURE
}