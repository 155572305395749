import React from "react"
import ShellLayout from "../../ShellLayout"
import NotificationsGenerator from "../../Notification/NotificationsGenerator";

import WidgetSize from "../../../Components/Widgets/WidgetSize";
import WidgetContainers from "../../../Components/Widgets/WidgetContainers";

export default function DashboardCompte() {

  const containers: any = [
    {
      width: 'w-1/4',
      size: WidgetSize.TINY,
      direction: "VERTICAL",
      zone: 'DASHBOARD_TINY'
    },
    {
      width: 'w-3/4',
      size: WidgetSize.WIDE,
      direction: "VERTICAL",
      zone: 'DASHBOARD_WIDE'
    }
  ]

  return (
    <>
      <ShellLayout>
        <div className="flex flex-col lg:flex-row w-full h-full space-x-2">

          <WidgetContainers items={containers} />

        </div >
      </ShellLayout >
      <NotificationsGenerator />
    </>
  )
}