import React from 'react'
import { useP } from '../../../services/i18n';

export default function TabPanelAbonnementVide() {
  const p = useP();

  return <>
    <div className='absolute top-0 left-0 flex items-center justify-center h-full w-full text-sm font-medium text-gray-700'>{p.t("contrats.noResult")}</div>
  </>

}