import { gql } from "@apollo/client";

const SAVE_COMPTE = gql`
  mutation SaveCompte($compte: CompteInput) {
    saveCompte(compte: $compte) {
      id
    }
  }`;

export {
  SAVE_COMPTE
}