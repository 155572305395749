import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

type ToolbarSearchType = {
  placeholder?: string
  onSearch: (search: string) => void
}

function ToolbarSearch({ placeholder = "", onSearch }: ToolbarSearchType) {

  const handleChange = (event) => {
    onSearch(event.target.value)
  }

  return (
    <>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="search"
          name="search"
          className="block min-w-[250px] w-full pl-10 pr-3 py-1.5 border border-gray-300 rounded-md bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 text-sm"
          placeholder={placeholder}
          onChange={handleChange}
          type="search"
        />
      </div>
    </>
  )

}

export default ToolbarSearch