import React, { useState } from 'react'
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import Card from '../../../Components/Card'
import LineChart from '../../../Components/Charts/LineChart';
import { filterTypes } from '../../../Components/Filters/Types/filterTypes';
import Spinner from '../../../Components/Spinner';
import { useP } from '../../../services/i18n'
import MesuresLoginInfos from './MesuresLoginInfos';

import objectSupport from "dayjs/plugin/objectSupport";
import { GET_MESURES_IN_OUT } from '../queries';
import Error from '../../../Components/Error/Error';
import Button from '../../../Components/Button';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';
dayjs.extend(objectSupport);

type MesuresLoginType = {
  login: string,
  filters: filterTypes
}

export default function MesuresLogin({ login, filters }: MesuresLoginType) {
  const p = useP();

  const changeDateBeforeRequest = (date) => {
    //Cas des valeur pour opérateur fixe (TODAY, YESTERDAY, ...)
    if (!Array.isArray(date) && !dayjs(date).isValid()) return date;

    // Si une date on créé un tableau de date et après on ajoute les heures de début et de fin
    // Slice permet de copier le tableau par valeur et non par reference
    const dates = Array.isArray(date) ? date.slice() : [date, date];

    dates[0] = dayjs(dates[0])
      .set({ hour: 0, minute: 0, second: 0 })
      .format("YYYY-MM-DD HH:mm:ss");

    dates[1] = dayjs(dates[1])
      .set({ hour: 23, minute: 59, second: 59 })
      .format("YYYY-MM-DD HH:mm:ss");

    return dates;
  };

  const { loading, error, data, refetch } = useQuery(GET_MESURES_IN_OUT, {
    fetchPolicy: "network-only",
    variables: {
      login,
      date: changeDateBeforeRequest(filters[0].value),
    },
  });

  const panZoomComplete = ({ chart }) => {
    const { min, max } = chart.scales.x;
    refetch({
      date: [
        dayjs(min).format("YYYY-MM-DD HH:mm:ss"),
        dayjs(max).format("YYYY-MM-DD HH:mm:ss"),
      ],
    });
    setResetZoomVisible(true)
  };

  const optionsLineChart = {
    scales: {
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: "Mb/s",
        },
      },
      x: {
        type: "time",
        time: {
          displayFormats: {
            millisecond: "DD/MM HH:mm",
            second: "DD/MM HH:mm",
            minute: "DD/MM HH:mm",
            hour: "DD/MM HH:mm",
            day: "DD/MM HH:00",
            week: "WW YYYY",
            month: "MMM YYYY",
            quarter: "MMM YYYY",
            year: "YYYY",
          },
          minUnit: "minute",
          tooltipFormat: "DD/MM HH:mm",
        },
      },
    },
    elements: {
      point: {
        radius: 1,
        backgroundColor: "#FFF",
        borderWidth: 1,
      },
    },
    plugins: {
      tooltip: {
        enable: true,
      },
      legend: {
        labels: {
          boxWidth: 30,
          boxHeight: 1,
          usePointStyle: false,
        },
        position: "bottom",
        align: "center",
      },
      title: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
          modifierKey: "ctrl",
          onPanComplete: panZoomComplete,
        },
        zoom: {
          limits: {
            x: { minRange: 3600000 },
          },
          drag: {
            enabled: true,
          },
          mode: "x",
          onZoomComplete: panZoomComplete,
        },
      },
    },
  };

  const resetZoom = () => {
    refetch({
      date: changeDateBeforeRequest(filters[0].value),
    });
    setResetZoomVisible(false)
  }

  const [resetZoomVisible, setResetZoomVisible] = useState<boolean>(false);

  return <>
    <div className='h-full'>
      <Card className={{ root: "md:mt-4" }} shadow={false} bordered={true}>
        <div className='flex flex-col md:flex-row '>
          <>
            {loading && (
              <div className="pt-3 pb-2 h-full w-full">
                <div className="h-full w-full flex items-center justify-center">
                  <Spinner color="primary" />
                </div>
              </div>
            )}

            {(error) && (
              <div className="flex items-center justify-center h-full italic">
                <Error />
              </div>
            )}

            {!loading && !error && (
              <>
                <div className="w-full md:w-7/12 md:mx-2">
                  <div className="h-[300px] relative">
                    <LineChart
                      data={data?.loginDebitInOut}
                      datasetIdKey="id"
                      options={optionsLineChart}
                    />
                    {resetZoomVisible &&
                      <Button
                        size='small'
                        className='absolute top-4 right-4'
                        onClick={resetZoom}>
                        <ArrowCounterclockwise className='h-4 w-4 mr-1.5' />
                        {p.t('components.lineChart.resetZoom')}
                      </Button>
                    }
                  </div>
                </div>
                <div className="w-full md:w-5/12 md:mx-2">
                  <MesuresLoginInfos
                    data={data?.loginDebitInOut?.datasets ? data?.loginDebitInOut?.datasets : []}
                  />

                </div>
              </>
            )}
          </>
        </div>
      </Card>
    </div>
  </>
}