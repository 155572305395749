import clsx from "clsx";
import React, { ReactNode } from "react"

type ModalBodyType = {
  children: ReactNode,
  noPadding: boolean
}

export default function ModalBody({ children, noPadding = false }: ModalBodyType) {
  return (
    <div className={
      clsx(`flex-1 overflow-x-hidden overflow-y-auto h-full flex flex-col `,
        noPadding ? `p-0` : `p-4`)}
    >
      {children}
    </div>
  );
}