import React from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import Tooltip from '../../../../../Components/Tooltip/Tooltip';
import { useP } from '../../../../../services/i18n';

type RolesListItemsActionsRemoveType = {
  onClick: () => void
}

export default function RolesListItemsActionsRemove({ onClick }: RolesListItemsActionsRemoveType) {
  const p = useP();
  return (
    <>
      <Tooltip
        button={
          <button type="button" className="inline-flex group cursor-pointer py-1 rounded-md  focus:bg-gray-100 focus:outline-none" onClick={onClick} >
            <TrashIcon className="h-5 w-5 mx-2 text-gray-500 group-hover:text-red-600 group-focus:text-red-600" />
          </button>
        }
        content={
          <div className='text-xs'>{p.t("actions.supprimer")}</div>
        }
        placement='top'
      />
    </>)

}