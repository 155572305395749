import React, { Fragment } from 'react';
import WidgetList from './WidgetList';
import WidgetSize from './WidgetSize';

type WidgetType = {
    size: WidgetSize,
    id: number,
    code: string,
    addWidget: any,
    moveUpWidget: any,
    moveDownWidget: any,
    removeWidget: any
}

const WidgetElement = (props: { widgetElement, addWidget: any, moveUpWidget: any, moveDownWidget: any, removeWidget: any }) => {

    const { widgetElement: WidgetElement } = props;

    return (
        <div className='flex flex-row overflow-hidden h-fit z-10' >
            <WidgetElement
                addWidget={props.addWidget}
                moveUpWidget={props.moveUpWidget}
                moveDownWidget={props.moveDownWidget}
                removeWidget={props.removeWidget}
            />
        </div>
    )
}

function Widget(props: WidgetType) {

    const item = WidgetList[props.code] ? WidgetList[props.code] : Fragment;

    const moveUpWidget = () => {
        props.moveUpWidget(props.id)
    }

    const moveDownWidget = () => {
        props.moveDownWidget(props.id)
    }

    const removeWidget = () => {
        props.removeWidget(props.id)
    }

    return (
        <WidgetElement
            widgetElement={item[props.size] ? item[props.size] : Fragment}
            addWidget={props.addWidget}
            moveUpWidget={moveUpWidget}
            moveDownWidget={moveDownWidget}
            removeWidget={removeWidget}
        />
    )
}

export default Widget