import React, { useState } from "react"
import { useP } from "../../../services/i18n";
import Button from "../../../Components/Button";
import clsx from "clsx";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { ThumbnailType } from "./DocumentationThumbnail";

type FooterModalDocumentType = {
  scrollBarIsAtBottom: boolean,
  thumbnail: ThumbnailType
  onApprouve: () => void
}

function FooterModalDocument({ scrollBarIsAtBottom, thumbnail, onApprouve }: FooterModalDocumentType) {
  const p = useP();

  const [approval, setApproval] = useState(false)
  return (
    <div className='flex flex-row space-x-2 items-center justify-center'>
      {
        !thumbnail.dateApproval ?
          <Button
            color="primary"
            disabled={scrollBarIsAtBottom || approval ? false : true}
            onClick={() => {
              setApproval(true)
              onApprouve()
            }}
          >
            <div className="flex items-center space-x-1">
              <div className={clsx(`mr-2 bg-white rounded-full`)}>
                {
                  scrollBarIsAtBottom
                    ? <CheckCircleIcon className="h-6 w-6 text-emerald-500" />
                    : <XCircleIcon className={clsx(`h-6 w-6 text-red-500  `)} />
                }
              </div>
              <div>
                {p.t('documentation.approvalRead_1')}
              </div>
              <div className="italic">{thumbnail.titre}</div>
              <div>
                {p.t('documentation.approvalRead_2')}
              </div>
            </div>
          </Button>
          : <div className="text-sm font-medium text-primary-600">{p.t('documentation.approuve')} {dayjs(thumbnail.dateApproval).format("DD/MM/YYYY HH:mm")}</div>
      }
    </div>
  )
}

export default FooterModalDocument;