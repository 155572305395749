import I18n from './i18n';
import { useContext } from 'react';
import I18nContext from './i18n-context';

const useLocale = () => {
  const { locale, setLocale } = useContext(I18nContext);
  return { locale, setLocale };
};

const useP = () => {
  const { p } = useContext(I18nContext);
  return p;
};

export {
  I18n,
  useLocale,
  useP,
};