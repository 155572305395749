import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react'
import { FieldError } from 'react-hook-form';

type DivEditableType = {
  placeholder?: string,
  className?: string,
  onChange?: (event: React.FormEvent<HTMLDivElement>) => void,
  onFocus?: (event: React.FormEvent<HTMLDivElement>) => void,
  onBlur?: (event: React.FormEvent<HTMLDivElement>) => void,
  error?: FieldError,
  textareaHeight?: string | undefined,
  focus?: boolean,
  withFooterToolbar?: boolean,
  containerHeight: number,
  margin: number,
}

function DivEditable({ placeholder = "", className = "", onChange, onFocus, onBlur, error, textareaHeight, focus, withFooterToolbar = false, containerHeight, margin = 0
}: DivEditableType) {

  const divEditableRef = useRef<any>();
  const [content, setContent] = useState<string>('');

  const handlePaste = (e) => {
    e.preventDefault();

    let content: string;

    if (e.clipboardData) {
      if (((e.originalEvent || e).clipboardData).files.length === 0) {
        content = (e.originalEvent || e).clipboardData.getData('text/plain');
        content = content.replace(/\n\n\s/g, ""); // cas des mail outlook
        content = content.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, ''); // emojis
        document.execCommand('insertText', false, content);
        return false;
      } else {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (const index in items) {
          const item = items[index];
          if (item.kind === 'file') {
            const blob = item.getAsFile();
            const reader = new FileReader();
            reader.onload = function (event) {
              document.execCommand('insertImage', false, event?.target?.result?.toString() || "");
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  };

  useEffect(() => {
    focus && divEditableRef.current.focus()
  }, [focus])



  useEffect(() => {
    const div = divEditableRef.current;
    div.style.height = textareaHeight ? textareaHeight : 'auto';
    div.style.height = `${Math.min(div.scrollHeight, containerHeight - margin)}px`;
  }, [content, containerHeight]);


  return (
    <>
      <div
        ref={divEditableRef}
        onPaste={handlePaste}
        onInput={(event) => {
          onChange?.(event);
          setContent(event.target["innerHTML"]);
        }}
        onFocus={(event) => onFocus?.(event)}
        onBlur={(event) => onBlur?.(event)}
        contentEditable="true"
        className={clsx(
          error
            ? `focus:ring-red-600 focus:border-red-600 `
            : `focus:ring-primary-500 focus:border-primary-500`,
          "border-t border-l border-r  border-gray-300 block w-full sm:text-sm overflow-hidden overflow-y-auto resize-none",
          withFooterToolbar ? "rounded-x-md rounded-t-md border-b-none" : "rounded-md border-b",
          "focus:ring-0 focus:outline-none focus:shadow-none focus:border-t focus:border-l focus:border-r focus:border-b-none",
          " pt-2 pb-2 pl-3 pr-3",
          className)
        }
        placeholder={placeholder}
        style={{ height: `${textareaHeight}`, maxHeight: `${containerHeight - margin}px`,display:"inline-block" }}
      >
      </div>
      {
        error && <div className='text-red-600 text-xs font-medium mt-0.5'>{error.message}</div>
      }
    </>
  )
}

export default DivEditable