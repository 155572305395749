import React, { useEffect, useState } from "react"
import Button from "../../Button";
import { BadgeCc } from "react-bootstrap-icons";
import NewMessageToolbarItem, { toolbarNewMessageOptionsType } from "./NewMessageToolbarItem";
import FilePicker from "../../FilePicker";
import { useP } from "../../../services/i18n";
import clsx from "clsx";

type newMessageToolbarType = {
  toolbarNewMessageOptions: toolbarNewMessageOptionsType,
  onAddCc?: () => void,
  onAddPJ?: (files: Array<any>) => void,
  onSendMessage: () => void,
  buttonEnable: boolean,
  displaySendButton: boolean,
  cancelButton?: boolean,
  onCancel?: () => void,
  focus?: boolean,
}
export default function NewMessageToolbar({
  toolbarNewMessageOptions,
  onAddCc,
  onAddPJ,
  buttonEnable,
  onSendMessage,
  displaySendButton,
  cancelButton,
  onCancel,
  focus
}: newMessageToolbarType) {

  const p = useP();
  const [buttonEnableState, setButtonEnableState] = useState(buttonEnable)

  useEffect(() => {
    setButtonEnableState(buttonEnable)
  }, [buttonEnable])

  return <>
    <div className={clsx(`relative -top-[7px] flex items-end justify-between w-full p-2 `,
      `bg-white border-x border-b border-t-none  rounded-md rounded-t-none`,
      focus ? `border-primary-500` : `border-gray-300`)}>
      <div className="flex items-center">
        {toolbarNewMessageOptions.pj &&
          <FilePicker multiple={true} onFilesSelected={(files) => onAddPJ && onAddPJ(files)} tooltipContent="Ajouter une pièce jointe" filetypes="" />
        }
        {toolbarNewMessageOptions.cc && <NewMessageToolbarItem Icon={BadgeCc} tooltipContent="Ajouter un email en copie" action={() => onAddCc && onAddCc()} />}
      </div>
      <div className="space-x-2">
        {cancelButton && <Button size="small" color="grayBordered" onClick={onCancel}>{p.t('actions.annuler')}</Button>}
        {displaySendButton && <Button size="small" color="primary" disabled={!buttonEnableState} onClick={onSendMessage}>{p.t('actions.envoyer')}</Button>}
      </div>
    </div>
  </>
}