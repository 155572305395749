import React, { Fragment, useEffect, useRef, useState } from "react"
import FiltersTable from "./FiltersTable"
import Button from "../Button"
import { FunnelIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import Badge from "../Badge"
import { useP } from "../../services/i18n"
import useOnClickOutside from "../../Hooks/useOnClickOutside"

type FilterTableType = {
  datas: Array<any>,
  activeFilters: any,
  onSelectedButtonGroup: (value: any, filtreKey: string) => void,
  keyFiltreLowercase?: boolean,
  placement?: string
}

export default function FilterTable({ datas, activeFilters, onSelectedButtonGroup, keyFiltreLowercase = false, placement = "left" }: FilterTableType) {
  const p = useP();
  const [openFiltres, setOpenFiltres] = useState(false)
  const [filterLength, setFilterLength] = useState(0)

  const refFilter = useRef<any>();
  useOnClickOutside(refFilter, () => {
    setOpenFiltres(false)
  });

  useEffect(() => {
    const valeurs = Object.values(activeFilters);
    let nombreTotalElements = 0;
    for (const valeur of valeurs) {
      if (Array.isArray(valeur)) {
        nombreTotalElements += valeur.length;
      } else {
        nombreTotalElements++;
      }
    }
    setFilterLength(nombreTotalElements)


  }, [activeFilters])

  let placementFiltres = "";
  switch (placement) {
    case "right":
      placementFiltres = "right-0"
      break;

    case "left":
    default:
      placementFiltres = "left-0"
      break;
  }
  return <>
    <div className='flex items-center relative lg:static'>
      <div className="block lg:hidden">
        <Button
          className=''
          color="grayBordered"
          onClick={() => {
            setOpenFiltres(!openFiltres)
          }}>
          <div className="flex items-center space-x-1 pr-4">
            <FunnelIcon className='h-4 w-4 mr-2' />
            <div className="absolute right-2"><Badge itemColor="primary" itemLibelle={`${filterLength}`} className='text-[11px] py-0' /></div>
          </div>
        </Button>
      </div>
      <div ref={refFilter} className={clsx(
        !openFiltres ?
          `hidden lg:flex` :
          `lg:flex flex-col space-y-4 absolute top-10 z-40 bg-white shadow rounded-md border border-gray-300 p-4`,
        placementFiltres,
        `lg:items-center lg:space-x-2 lg:space-y-0`)}
      >
        {
          datas.map((item) => {
            return <FiltersTable
              key={item.libFiltre}
              activeFilters={keyFiltreLowercase ? activeFilters[item.libFiltre.toLowerCase()] : activeFilters[item.libFiltre]}
              onSelectedButtonGroup={(value, key) => onSelectedButtonGroup(value, key)}
              filtresItems={item.filtres}
              filtreKey={item.libFiltre}
              multi={item.multi}
              selectAll={item?.selectAll || false}
            />
          })
        }
        <div className="block lg:hidden mt-4 w-full">
          <Button
            className='w-full'
            color="primaryBordered"
            onClick={() => {
              setOpenFiltres(false)
            }}>
            <div className="flex items-center space-x-1">
              <div>{p.t('actions.fermer')}</div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </>
}