
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react'

export type AccordionItemHeaderProps = {
  /** Children of the AccordionHeader */
  children: ReactNode;
};

const AccordionItemHeader = ({
  children,
  ...rest
}: AccordionItemHeaderProps) => {

  return (
    <div {...rest}>
      {React.Children.map(children, (child) => {
        const item = child as ReactElement<PropsWithChildren<any>>;

        if (typeof item === "object") {
          return React.cloneElement(item);
        } else {
          return child;
        }
      })}
    </div>
  );
};

export default AccordionItemHeader;