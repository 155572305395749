import React, { useEffect, useState } from "react"
import ImpactProduction from "./ImpactProduction"
import ActiviteTotaleEntreprise from "./ActiviteTotaleEntreprise"
import { toastError, toastSuccess } from "../../../Components/Utils/Utils"
import { GET_LOGINS } from "../queries"
import { useLazyQuery, useMutation } from "@apollo/client"
import { UPDATE_LOGIN } from "../../Prestations/queries"
import { useP } from "../../../services/i18n"
import Spinner from "../../../Components/Spinner"

type ParametrageSupportLoginsType = {
  lien: any,
  openDetail: boolean
}

export default function ParametrageSupportLogins({ lien, openDetail }: ParametrageSupportLoginsType) {
  const p = useP();
  const [getLogins, { loading: loadingLogins }] = useLazyQuery(GET_LOGINS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      "filters": [
        {
          "column": "lien.id",
          "operator": "EQ",
          "value": lien.id
        }
      ],
      "sorters": [],
    },
    onCompleted: async (data) => {
      setLogins(data.logins.items)
    },
  });

  const [logins, setLogins] = useState<any>()

  useEffect(() => {
    openDetail && getLogins()
  }, [openDetail])

  const [updateLogin] = useMutation(UPDATE_LOGIN, {
    onCompleted: async (data) => {
      if (data.updateLogin) {
        toastSuccess(p.t('actions.sauvegardeOK'))
      } else {
        toastError("error")
      }
    }
  });
  return <>

    {
      loadingLogins ?
        <>
          {lien.logins.filter((l) => (l.isProduction)).map((login, index) => (
            <div key={index} className='bg-gray-100 rounded-md p-2 mb-2 h-28 animate-pulse'>
              <div className="h-5 animate-pulse bg-gray-200  mb-4 rounded-md"></div>
              <div className="h-5 animate-pulse bg-gray-200 mb-4 rounded-md"></div>
              <div className="h-5 animate-pulse bg-gray-200  mb-4 rounded-md"></div>
            </div>
          ))}

        </>
        :
        logins?.filter((l) => (l.isProduction)).map((login, index) => (
          <div className='bg-gray-100 rounded-md p-2 mb-2' key={index}>
            <div className='mb-2 font-medium text-primary-500'>{login.login}</div>
            <div className='w-10 h-1 mb-2 rounded-full bg-secondary-300'></div>
            <div className='mb-3'>{<ImpactProduction login={login} onChange={
              (value) => {
                updateLogin({
                  variables: {
                    "login": login.login,
                    "loginInput": {
                      "ticketImpact": value,
                    }
                  }
                })
              }
            } />}</div>
          </div >
        ))
    }
  </>
}