import { ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

type CardClassType = {
  root?: string,
  container?: string
}

type CardType = {
  children: JSX.Element[] | JSX.Element,
  className?: CardClassType,
  style?: object,
  overflow?: boolean,
  shadow?: boolean,
  bordered?: boolean,
  isFullscreen?: boolean,
  bgClass?: string,
}

type CardHeaderType = {
  key?: string,
  children: JSX.Element[] | JSX.Element | string,
  className?: string,
  fullscreen?: boolean,
  onFullscreenChange?: (bool: boolean) => void,
  fullscreenIsActive?: boolean
}
function CardHeader({ key, children, className, fullscreen = false, onFullscreenChange, fullscreenIsActive }: CardHeaderType) {
  const [isFullscreen, setIsFullscreen] = useState(false)
  useEffect(() => {
    fullscreenIsActive && setIsFullscreen(fullscreenIsActive);
  }, [fullscreenIsActive]);

  const handleFullscreenToggle = () => {
    const newFullscreenState = !isFullscreen;
    setIsFullscreen(newFullscreenState);
    onFullscreenChange?.(newFullscreenState);
  };
  return (
    <>
      <div
        key={key}
        className={clsx(`text-xl font-semibold leading-5 text-primary-700 py-2 px-2`, className, fullscreen && `relative flex items-center justify-between`)}
      >
        {fullscreen && <div
          className='absolute top-1 right-0 cursor-pointer hover:bg-primary-100 rounded-md p-2 lg:p-1'
          onClick={handleFullscreenToggle}
        >
          {
            isFullscreen
              ? <ArrowsPointingInIcon className='h-5 w-5' />
              : <ArrowsPointingOutIcon className='h-5 w-5' />
          }
        </div>}
        {children}
      </div>
    </>
  )
}
Card.Header = CardHeader;

function Card({
  className,
  children,
  style,
  overflow = true,
  shadow = true,
  bordered = false,
  isFullscreen,
  bgClass = 'bg-white'

}: CardType) {

  const [fullscreenIsActive, setFullscreenIsActive] = useState(isFullscreen);
  const [headerKey, setHeaderKey] = useState('headerKey'); // Une clé unique initiale


  const handleFullscreenChange = (newFullscreenState: boolean) => {
    setFullscreenIsActive(newFullscreenState);
    setHeaderKey(new Date().toISOString());
  };
  return (
    <>
      <div
        className={clsx(
          `rounded-lg `,
          bgClass,
          shadow && `shadow`,
          bordered && `border border-gray-200`,
          overflow && `overflow-hidden`,
          //fullscreenIsActive ? `fixed w-full h-full z-50 top-0 left-0` : className?.root,
          className?.root,
        )}
        style={style}>
        <div className={clsx(`px-2 py-2 h-full flex flex-col`,
          fullscreenIsActive ? `bg-white fixed w-full z-50 top-0 left-0` : ``,
          className?.container,
        )}>
          {React.Children.map(children, (child) =>
            React.isValidElement<CardHeaderType>(child)
              ? React.cloneElement(child, { key: headerKey, onFullscreenChange: handleFullscreenChange, fullscreenIsActive: fullscreenIsActive })
              : child
          )}
        </div>
      </div>
    </>
  )

}

export default Card