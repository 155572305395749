import React from "react"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentDetailLien_Q4(){
  const p = useP()
  return <>
    <P>
      <>{parse(p.t('helpBox.helpPrestations.helpDetailLien.a4'))}</>
    </P>
  </>
}