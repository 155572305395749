import React from 'react';
import Tabs, { TabsNavigationType } from '../../../Components/Tabs/Tabs';


type PrestationDetailModaleBodyType = {
  tabsNavigation: Array<TabsNavigationType>,
  tabsPanel: Array<JSX.Element>
}

export default function PrestationDetailModaleBody({
  tabsNavigation,
  tabsPanel,
}: PrestationDetailModaleBodyType) {
  return <>
    <div className='flex flex-col h-full'>
      <Tabs
        navigation={tabsNavigation}
        panels={tabsPanel}
        cardStyle={false}
      />
    </div>
  </>
}