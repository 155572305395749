import React from 'react'
type AdresseType = {
  nom:string,
  codePostal:string,
  commune:string,
}
type CellAdresseFormatterType = {
  obj : AdresseType
  
}

export default function CellAdresseFormatter({ obj }: CellAdresseFormatterType) {
  return <>
    <div className=''>
      {obj.nom}<br />{obj.codePostal} {obj.commune}
    </div>
  </>
}