import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client';
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs';
import CardIcon from '../../Components/CardIcon'
import { filterTypes } from '../../Components/Filters/Types/filterTypes';
import { GET_DISPONIBILITE } from './queries';

type DisponibilitesLoginType = {
  login: string,
  filters: filterTypes
}

export default function DisponibilitesLogin({ login, filters }: DisponibilitesLoginType) {

  const {
    loading: loadingDisponibilite,
    data: dataDisponibilite,
    error: errorDisponibilite,
  } = useQuery(GET_DISPONIBILITE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      login,
      date: filters[0].value,
    },
  });

  const formatDuree = (duree) =>
    `${parseInt(duree.asDays())} j ${parseInt(
      duree.hours()
    )} h ${duree.minutes()} m ${duree.seconds()} s`;


  const formatDatas = (datas) => {
    const dureeDispo = dayjs.duration(datas ? datas.dispoDuree : 0, "seconds");
    const dureeIndispo = dayjs.duration(
      datas ? datas.indispoDuree : 0,
      "seconds"
    );
    const dureeDispoHo = dayjs.duration(
      datas ? datas.dispoDureeHo : 0,
      "seconds"
    );
    const dureeIndispoHo = dayjs.duration(
      datas ? datas.indispoDureeHo : 0,
      "seconds"
    );

    return [
      {
        id: 1,
        name: "Disponibilité globale (%)",
        stat: `${datas ? datas.dispoPourcentage : 100}%`,
        icon: ArrowSmallUpIcon,
        classNameIconColor: "bg-emerald-500",
        loading: loadingDisponibilite,
        error: errorDisponibilite,
      },
      {
        id: 2,
        name: "Disponibilité globale (Durée)",
        stat: formatDuree(dureeDispo),
        icon: ArrowSmallUpIcon,
        classNameIconColor: "bg-emerald-500",
        loading: loadingDisponibilite,
        error: errorDisponibilite,
      },
      {
        id: 3,
        name: "Indisponibilité globale (Durée)",
        stat: formatDuree(dureeIndispo),
        icon: ArrowSmallDownIcon,
        classNameIconColor: "bg-red-500",
        loading: loadingDisponibilite,
        error: errorDisponibilite,
      },
      {
        id: 4,
        name: "Disponibilité HO (%)",
        stat: `${datas ? datas.dispoPourcentageHo : 100}%`,
        icon: ArrowSmallUpIcon,
        classNameIconColor: "bg-emerald-500",
        loading: loadingDisponibilite,
        error: errorDisponibilite,
      },
      {
        id: 5,
        name: "Disponibilité HO (Durée)",
        stat: formatDuree(dureeDispoHo),
        icon: ArrowSmallUpIcon,
        classNameIconColor: "bg-emerald-500",
        loading: loadingDisponibilite,
        error: errorDisponibilite,
      },
      {
        id: 6,
        name: "Indisponibilité HO (Durée)",
        stat: formatDuree(dureeIndispoHo),
        icon: ArrowSmallDownIcon,
        classNameIconColor: "bg-red-500",
        loading: loadingDisponibilite,
        error: errorDisponibilite,
      },
    ];
  };

  const [datas, setDatas] = useState<any[]>([]);

  useEffect(() => {
    setDatas(formatDatas(dataDisponibilite?.loginDisponibilite))
  }, [dataDisponibilite]);

  return <>
    <div className='md:grid md:grid-cols-3 md:gap-4 mb-0'>
      {
        datas.map((card, i) => (
          <div key={i} className="mb-4 md:mb-0"><CardIcon item={card} bordered={true} shadow={false} /></div>
        ))
      }
    </div>
  </>
}