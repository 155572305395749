import React from "react";
import Button from "../../Components/Button";
import Modal from "../../Components/Modal/Modal";
import { useP } from "../../services/i18n";
import ComptesModaleBody from "./ComptesModaleBody";

type ComptesModaleType = {
  isOpen?: boolean,
  onClose: () => void
}
export default function ComptesModale({ isOpen = false, onClose }:ComptesModaleType){
  const p = useP();
  return <>
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      content={{
        title: <>
          <div className='flex items-center'>
            <div>{p.t('userNavigation.comptes.label')}</div>
          </div>
        </>
        ,
        body: <ComptesModaleBody />,
        footer: <><div className='flex items-center justify-end'>
          <Button onClick={onClose}>
            {p.t('actions.fermer')}
          </Button>
        </div></>,
      }}
      className="w-full lg:w-1/3 lg:h-4/5"
    />
  </>
}