import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentDetailContrats_Q1 from "./HelpContent/HelpContentDetailContrats_Q1";
import HelpContentDetailContrats_Q2 from "./HelpContent/HelpContentDetailContrats_Q2";
import HelpContentDetailContrats_Q3 from "./HelpContent/HelpContentDetailContrats_Q3";

export default function HelpDetailContrat(){
  const p = useP();
  const datas = [{
    libelle : p.t('helpBox.helpContrats.helpDetailContrat.q1'),
    content : <HelpContentDetailContrats_Q1 />
  },{
    libelle : p.t('helpBox.helpContrats.helpDetailContrat.q2'),
    content : <HelpContentDetailContrats_Q2 />
  },{
    libelle : p.t('helpBox.helpContrats.helpDetailContrat.q3'),
    content : <HelpContentDetailContrats_Q3 />
  }]
  return <>
      <HelpBoxAcordions datas={datas} />
  </>
}