import React, { ReactNode } from "react"
import Tooltip from "../../Tooltip/Tooltip";
export type toolbarNewMessageOptionsType = {
  colorClassIcon: string,
  pj?: boolean | undefined,
  cc?: boolean | undefined
}

type NewMessageToolbarItemType = {
  Icon: any,
  tooltipContent: ReactNode,
  action: () => void
}

export default function NewMessageToolbarItem({
  Icon,
  tooltipContent,
  action }: NewMessageToolbarItemType) {
  return <>
    <div
      className="flex items-center justify-center w-8 h-8 hover:bg-gray-100 rounded-md cursor-pointer hover:transition-all ease-in-out"
      onClick={() => action()}>
      <Tooltip
        button={<Icon className="text-gray-500 h-4 w-4" />}
        content={<div className="text-xs">{tooltipContent}</div>}
        withArrow
      />
    </div>
  </>
}