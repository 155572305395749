import React from "react"
import List from "../../../Components/List/List"
import { useP } from '../../../services/i18n'

export type PrestationLienItem = {
  id: number,
  type: string,
}
export type PrestationCentileItem = {
  id: number,
  denomination: string,
}
export type PrestationGsmItem = {
  id: number,
  forfait: string,
  numero: string,
}
export type PrestationNdiItem = {
  id: number,
  numero: string,
  nom: string,
}
export type PrestationTrunkItem = {
  id: number,
  nom: string,
}
export type PrestationVgaItem = {
  id: number,
  numero: string,
}

type ModalePrestationsContactBodyType = {
  prestationsLien: Array<PrestationLienItem>,
  prestationsCentile: Array<PrestationCentileItem>,
  prestationsGsm: Array<PrestationGsmItem>,
  prestationsNdi: Array<PrestationNdiItem>,
  prestationsTrunk: Array<PrestationTrunkItem>,
  prestationsVga: Array<PrestationVgaItem>,
}

export default function ModalePrestationsContactBody({ prestationsLien, prestationsCentile, prestationsGsm, prestationsNdi, prestationsTrunk, prestationsVga }: ModalePrestationsContactBodyType) {
  const p = useP();

  const list = prestationsLien.map((prestation) => ({
    id: prestation.id,
    libelle: `${p.t('contacts.contact.prestations.liens')} - ${prestation.type}`,
  }));
  prestationsGsm.map((prestation) => (
    list.push({
      id: prestation.id,
      libelle: `${p.t('contacts.contact.prestations.gsm')} - ${prestation.numero} ${prestation.forfait}`,
    })
  ));
  prestationsVga.map((prestation) => (
    list.push({
      id: prestation.id,
      libelle: `${p.t('contacts.contact.prestations.vga')} - ${prestation.numero}`,
    })
  ));
  prestationsNdi.map((prestation) => (
    list.push({
      id: prestation.id,
      libelle: `${p.t('contacts.contact.prestations.ndi')} - ${prestation.numero} ${prestation.nom}`,
    })
  ));
  prestationsCentile.map((prestation) => (
    list.push({
      id: prestation.id,
      libelle: `${p.t('contacts.contact.prestations.centile')} - ${prestation.denomination}`,
    })
  ));
  prestationsTrunk.map((prestation) => (
    list.push({
      id: prestation.id,
      libelle: `${p.t('contacts.contact.prestations.trunk')} - ${prestation.nom}`,
    })
  ));



  return <>
    <List datas={list} canDeleteItem={false} />
  </>
}