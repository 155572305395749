import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Table from '../../../../Components/Table/Table'
import { useP } from '../../../../services/i18n';
import { PrestationsViewType } from '../../PrestationsType';
import { GET_GSM } from '../../queries';
import GSMDetailModale from './GSMDetailModale/GSMDetailModale';
import dayjs from 'dayjs';
import duration from "dayjs/plugin/duration";
import FilterTable from '../../../../Components/Table/FilterTable';
import TooltipInformation from '../../../../Components/Tooltip/TooltipInformation';

dayjs.extend(duration);

export default function GSMView({ siteId }: PrestationsViewType) {
  const idTableGSM = 'ec-table-gsm'

  // Table Datas
  const { loading, data } = useQuery(GET_GSM, {
    fetchPolicy: "network-only",
    variables: {
      idSite: siteId,
    },
  });

  const p = useP();
  const [openModaleDetailGSM, setOpenModaleDetailGSM] = useState(false)
  const [modalePrestation, setModalePrestation] = useState(null)

  const editRow = (row) => {
    setModalePrestation(row)
    setOpenModaleDetailGSM(true)
  }

  const columns = [
    {
      libelle: p.t('prestations.gsm.columns.etat'),
      code: 'etat',
      colType: 'state',
    },
    {
      libelle: p.t('prestations.gsm.columns.etatLigne'),
      code: 'etatLigne',
      colType: 'state',
    },
    {
      libelle: p.t('prestations.gsm.columns.site'),
      code: 'site.nom',
      colType: 'adresse',
      formatterObjectCode: 'site',
      sortable: true
    },
    {
      libelle: p.t('prestations.gsm.columns.numero'),
      code: 'numero',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.gsm.columns.denomination'),
      code: 'denomination',
      colType: 'string',
      sortable: (_, row: any)=> `${row.contact?.prenom?.trim() || ''} ${row.contact?.nom?.trim() || ''}`,      
    },
    {
      libelle: p.t('prestations.gsm.columns.forfait'),
      code: 'forfait',
      colType: 'string',
      sortable: true
    },
    {
      libelle: <div className='flex items-center space-x-2'>
        <div>{p.t('prestations.gsm.columns.consoData')}</div>
        <TooltipInformation sizeClass="h-5 w-5" contenu={
          <div className="w-[350px] text-justify">
            <div className='whitespace-normal'>{p.t('prestations.gsm.detail.infos.infos_conso_1')}</div>
            <div className='whitespace-normal'>{p.t('prestations.gsm.detail.infos.infos_conso_2')}</div>
          </div>
        }
        />
      </div>,
      code: 'ligne.data',
      colType: 'KiB',
      sortable: true
    },
    {
      libelle: <div className='flex items-center space-x-2'>
        <div>{p.t('prestations.gsm.columns.consoVoix')}</div>
        <TooltipInformation sizeClass="h-5 w-5" contenu={
          <div className="w-[350px] text-justify">
            <div className='whitespace-normal'>{p.t('prestations.gsm.detail.infos.infos_conso_1')}</div>
            <div className='whitespace-normal'>{p.t('prestations.gsm.detail.infos.infos_conso_2')}</div>
          </div>
        }
        />
      </div>,
      code: 'ligne.voix',
      colType: 'duration',
      sortable: true,
      width: "110px"
    },
    {
      libelle: p.t('prestations.gsm.columns.affectation'),
      code: 'ligne.affectation',
      colType: 'string',
      sortable: true,
    },
    {
      libelle: p.t('prestations.gsm.columns.matricule'),
      code: 'ligne.matricule',
      colType: 'string',
      sortable: true,
    },
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: editRow,
      resizable: false
    },
  ]

  const filterButtons = [
    {
      libFiltre: "Etat ligne",
      filtres: [
        { libelle: p.t('etat.prestation.EN SERVICE'), value: "EN SERVICE" },
        { libelle: p.t('etat.prestation.SUSPENDUE'), value: "SUSPENDUE" },
        { libelle: p.t('etat.prestation.CREATION REJETEE'), value: "CREATION REJETEE" },
        { libelle: p.t('etat.prestation.RESILIEE'), value: "RESILIEE" },
        { libelle: p.t('etat.prestation.CREATION EN COURS'), value: "CREATION EN COURS" },
        { libelle: p.t('etat.prestation.ANNULEE'), value: "ANNULEE" },
        { libelle: p.t('etat.prestation.INITIEE'), value: "INITIEE" },
        { libelle: p.t('etat.prestation.PORTA EN COURS'), value: "PORTA EN COURS" },
      ]
    }
  ]

  const [tableDatas, setTableDatas] = useState<any[]>([]);

  const [activeFilters, setActiveFilters] = useState([{ libFiltre: "Etat ligne", filtres: ["EN SERVICE", "CREATION EN COURS", "INITIEE", "PORTA EN COURS"] }])

  const updateSelectedButtons = (value, key) => {
    const newState = activeFilters.map((filter) => {
      if (filter.libFiltre == key) {
        return { ...filter, filtres: value }
      }
      return filter
    })
    setActiveFilters(newState)
  }

  const onSaveGsm = ({ contact, ligne, idPrestation }) => {
    const newTableDatas = [...tableDatas]
    if (contact) {
      const ndiIndex = newTableDatas.findIndex((e) => e?.id == idPrestation)
      if (ndiIndex >= 0) {
        const gsmUpdate = {
          ...newTableDatas[ndiIndex],
          contact: {
            ...newTableDatas[ndiIndex].contact,
            id: contact.id,
            nom: contact.nom,
            prenom: contact.prenom,
            mail: contact.mail
          },
          denomination: <div>{contact.prenom} {contact.nom}</div>,
        }

        newTableDatas[ndiIndex] = gsmUpdate
      }
      setTableDatas(newTableDatas)
    }

    if (ligne) {
      const ndiIndex = newTableDatas.findIndex((e) => e?.ligne?.id == ligne.id)
      if (ndiIndex >= 0) {
        const gsmUpdate = {
          ...newTableDatas[ndiIndex],
          ligne: {
            ...newTableDatas[ndiIndex].ligne,
            affectation: ligne.affectation,
            matricule: ligne.matricule,
            commentaireSi: ligne.commentaireSi,
            rechargeAuto: ligne.rechargeAuto,
            rechargeAutoOption: ligne.rechargeAutoOption
          }
        }

        newTableDatas[ndiIndex] = gsmUpdate
      }

      setTableDatas(newTableDatas)
    }

  }

  useEffect(() => {
    let datas = data ? data.prestationGsms : [];
    const filtresStatut = activeFilters.find((f) => f.libFiltre == "Statut")
    const filtresEtat = activeFilters.find((f) => f.libFiltre == "Etat ligne")

    if (filtresStatut) {
      datas = datas.filter((d) => filtresStatut.filtres.includes(d.etat))
    }

    if (filtresEtat) {
      datas = datas.filter((d) => filtresEtat.filtres.includes(d.ligne?.etat)
      )
    }

    setTableDatas(datas)
  }, [data, activeFilters]);

  return <><Table
    idTable={idTableGSM}
    loading={loading}
    datas={tableDatas.map((d) => {
      return {
        ...d,
        etat: {
          code: d.etat,
          libelle: p.t(`etat.prestation.${d.etat}`),
          colorClasses: `bg-${d.etatColor}-200 text-${d.etatColor}-800`
        },
        etatLigne: {
          code: d.ligne.etat,
          libelle: p.t(`etat.prestation.${d.ligne.etat}`),
          colorClasses: `bg-gray-200 text-gray-800`
        },
        denomination: <div><div>{`${d.contact?.prenom} ${d.contact?.nom}`}</div><div className='text-xs text-gray-500'>{`${d.contact?.mail || ""}`}</div></div>
      }
    })}
    columns={columns}
    customToolbarActions={
      <>
        <FilterTable
          datas={filterButtons}
          onSelectedButtonGroup={(value, key) => updateSelectedButtons(value, key)}
          activeFilters={activeFilters.reduce((acc, item) => { acc[item.libFiltre] = item.filtres; return acc }, {})}
          placement='right'
        />
      </>
    }
    options={{
      search: {
        placeholder: p.t('prestations.searchPlaceholder'),
      },
      naturalWidth: true
    }}
    onClickRow={editRow}
  //onSort={(column,direction)=>console.log(column,direction)}  
  />
    <GSMDetailModale
      prestation={modalePrestation}
      isOpen={openModaleDetailGSM}
      onClose={() => setOpenModaleDetailGSM(false)}
      onSave={(onSaveGsm)}
    />
  </>
}