import React from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { TreeItemRenderContext } from "react-complex-tree";

type ItemArrowType = {
  context: TreeItemRenderContext,
  iconsArrow?: object
}

export default function ItemArrow({ context, iconsArrow }: ItemArrowType) {
  const defaultIcons = {
    expanded: <ChevronDownIcon className="h-4 w-4 text-primary-600" />,
    notExpanded: <ChevronRightIcon className="h-4 w-4 text-primary-600" />,
  };
  const iconsConfig = { ...defaultIcons, ...iconsArrow };
  return (
    <>
      <div {...context.arrowProps} className="px-2 lg:px-1">
        {context.isExpanded
          ? iconsConfig.expanded
          : iconsConfig.notExpanded}
      </div>
    </>
  );
}
