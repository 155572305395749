import { Menu } from '@headlessui/react'
import React from 'react'
import { TableActionsType } from '../../Types/TableActionsType'

type ActionItemType = {
  action: TableActionsType,
  row: any
}

function ActionsItem({ action, row }: ActionItemType) {
  return (

    <div>
      <div className="py-2 hover:bg-primary-100 text-primary-700 px-3 flex items-center justify-start" onClick={() => action.onclick(row)}>
        {
          action.icon &&
          <div className='h-4 w-4'>{action.icon}</div>
        }
        <div className='px-1'>{action.libelle}</div>
      </div>
    </div>

  )

}

export default ActionsItem