import React from 'react'
import InputSwitch from '../../Form/InputSwitch'
import { ColumnType } from '../Types/ColumnType'

type CellCheckboxFormatterProps = {
  row: any,
  column: ColumnType,
  onChange?: (row: any, column: ColumnType, checked: boolean) => void | undefined,
  disabled?: boolean,
  value?: boolean
}

function CellCheckboxFormatter({ row, column, onChange, disabled = false, value = false }: CellCheckboxFormatterProps) {

  return (
    <>
      <InputSwitch name='multicompte' label='' value={value} onChange={(checked) => onChange?.(row, column, checked)} disabled={disabled} />
    </>
  )

}

export default CellCheckboxFormatter