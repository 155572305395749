import Modal from "../Modal/Modal";
import React, { ReactNode } from 'react';
import Button from "../Button";
import { useP } from "../../services/i18n";


type ModalConfirmationType = {
  onClose: (boolean) => void,
  message: string | ReactNode,
  isOpen: boolean,
  closeIcon?: boolean,
  okButtonLabel?: string,
  cancelButtonLabel?: string
}

export function ModalConfirmation({ message, isOpen, onClose, okButtonLabel, cancelButtonLabel, closeIcon = true }: ModalConfirmationType) {
  const p = useP();
  return <>
    <Modal
      closeIcon={closeIcon}
      disableEsc={true}
      onClose={() => onClose(false)}
      isOpen={isOpen}
      content={{
        title: "Confirmation",
        body: <div className="flex-auto overflow-x-hidden overflow-y-auto">
          {message}
        </div>,
        footer: <div className="flex justify-end items-center w-full">
          <div className="flex items-center">
            <div className="flex items-center">
              <Button
                type="button"
                color="primaryLight"
                onClick={() => onClose(false)}
                className="mr-4">{okButtonLabel ? okButtonLabel : p.t('actions.annuler')}</Button>
              <Button
                type="button"
                color="primary"
                onClick={() => onClose(true)}
                className="">{cancelButtonLabel ? cancelButtonLabel : p.t('actions.ok')}</Button>
            </div>
          </div>
        </div>,
      }}
      className="w-full lg:w-2/6"
    />
  </>
}