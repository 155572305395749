import { gql } from "@apollo/client"

const GET_DONNEES_REFERENCE_MODALE = gql`
  query GetDonneesReference {
    ticketImpacts {
      id
      libelle
    } 

    ticketCriticites {
      id
      libelle
    }  
}`;

const GET_TICKET_IMPACTS = gql`
  query ticketImpacts {
    ticketImpacts {
      id
      libelle
    }   
  }`

const GET_TICKET_CRITICITES = gql`
  query ticketCriticites {
    ticketCriticites {
      id
      libelle
    }   
  }`

const TICKET_OUVERTURE_CLIENT = gql`
  mutation TicketOuvertureClient($ticket: TicketInput!) {
    ticketOuvertureClient(ticket: $ticket) {
      message
      success
      ticket {
        id
        numero
      }
    }
  }
`

const GET_TICKETS = gql`
  query Tickets($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    tickets(filters: $filters, sorters: $sorters, pagination: $pagination) {          
      items {
        id
        numero
        titre
      }
    }   
  }`

const GET_PRESTATIONS = gql`
  query Prestations($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    prestations(filters: $filters, sorters: $sorters, pagination: $pagination) {
      items {
        id
        libelle
        type
        site {
          compteId
        }
      }
    }
  }`

const GET_SITES = gql`
    query Sites (
      $filters: [FilterInput]
      $sorters: [SorterInput]
      $pagination: PaginationInput
    ) {
      sites (
        filters: $filters
        sorters: $sorters
        pagination: $pagination
      ) {
        id
        nom
        numeroVoie
        libelleVoie
        codePostal
        commune
        compteId
      }
    }`

const GET_COMPTES = gql`
  query GetComptes($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    comptes(filters: $filters, sorters: $sorters, pagination: $pagination){
      totalCount
      items {
        id
        raisonSociale
      }
    }
  }
`

export {
  GET_DONNEES_REFERENCE_MODALE,
  GET_TICKET_IMPACTS,
  GET_TICKET_CRITICITES,
  TICKET_OUVERTURE_CLIENT,
  GET_TICKETS,
  GET_PRESTATIONS,
  GET_SITES,
  GET_COMPTES
}