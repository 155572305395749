import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import TabFilters from "./TabFilters";
import clsx from "clsx";
import { usePopper } from "react-popper";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { filterTypes } from "./Types/filterTypes";
import { VirtualElement } from "@popperjs/core";

type FiltersType = {
  addFilters: (filters: Array<filterTypes>) => void,
  filters: Array<filterTypes>,
  buttonClassName?: string,
  buttonClassNameOpen?: string
}

function Filters({ filters, addFilters, buttonClassName, buttonClassNameOpen }: FiltersType) {
  const [referenceElement, setReferenceElement] = useState<Element | VirtualElement | null | undefined>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);
  //const [popoverClose, setPopoverClose] = useState(false)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right", "bottom", "left"],
        },
      },
      {
        name: "offset",
        options: {
          offset: [5, 5],
        },
      },
    ],
  });

  return (
    <>
      <Popover>
        {({ open }) => (
          <div>
            <Popover.Button
              ref={setReferenceElement}
              className={clsx(
                open && buttonClassNameOpen,
                buttonClassName
              )}
            >
              <FunnelIcon
                className={clsx(`h-5 w-5`)}
              />
            </Popover.Button>
            {open && (
              <Popover.Panel
                className="z-50 md:flex"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {({ close }) => (
                  <TabFilters
                    filters={filters}
                    addFilters={addFilters}
                    closePopover={() => close()}
                  />
                )}
              </Popover.Panel>
            )}
          </div>
        )}
      </Popover>
    </>
  );
}

export default Filters;
