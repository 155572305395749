import clsx from 'clsx';
import React, { useEffect } from 'react'
import Button from '../Components/Button';
import Spinner from '../Components/Spinner';
import { useP } from '../services/i18n'
import SupervisionItem from './SupervisionItem/SupervisionItem';
import SupervisionTabs from './SupervisionItem/SupervisionTabs';
import Constants from '../commons/Constants'
import Logo from '../Components/Logo';

const { STATUT_ACTIF } = Constants

function SupervisionContainer(props: { data: any, lastUpdated: Date | null, timeRemaining: number, loading: boolean }) {

  const p = useP();

  const goToHome = () => {
    window.location.href = "/";
  }

  useEffect(() => {
    if (props.data?.statut === STATUT_ACTIF && props.timeRemaining == 1) {
      goToHome();
    }
  }, [props.timeRemaining])

  return (
    <div className="flex-1 flex flex-col justify-center p-4 text-center">
      <div className="mx-auto w-full max-w-lg py-4 space-y-8">
        <div className="flex flex-col items-center space-y-2">
          <Logo className="h-16 w-auto" />

          <h2 className="text-3xl font-extrabold text-gray-900">{p.t("supervision.title")}</h2>
          <p className="mt-2 text-sm text-gray-600">
            {p.t("supervision.subtitle")}
          </p>

        </div>

        {
          props.loading ?
            (<div className="flex flex-col text-sm font-light items-center justify-center space-y-2 italic py-4"><Spinner /><div>{p.t('supervision.loading')}</div></div>) :
            (
              <>
                <div className="">
                  <SupervisionItem label='General' statut={props.data.statut} />
                </div>

                <div className="">
                  <SupervisionTabs services={props.data.services} />
                </div>

                <div className="text-sm space-y-2">
                  <div>
                    {p.t('supervision.lastReload')} : {props.lastUpdated?.toLocaleString()}
                  </div>
                  <div>
                    {
                      props.data.statut === STATUT_ACTIF ?
                        p.t('supervision.redirectToHome', { timeRemaining: `${props.timeRemaining}` }) :
                        p.t('supervision.nextReload', { timeRemaining: `${props.timeRemaining}` })
                    }
                  </div>
                </div>
              </>
            )
        }

        <div className={clsx(props.data.statut !== STATUT_ACTIF && `hidden`)}>
          <Button color='grayBordered' onClick={goToHome}>
            {p.t('supervision.goToHome')}
          </Button>
        </div>


      </div >
    </div >
  )
}

export default SupervisionContainer