import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { InputChoicesOptionsType } from '../Types/InputChoicesOptionsType'

type InputChoicesItemType = {
  type: 'checkbox' | 'radio',
  item: InputChoicesOptionsType,
  register: UseFormRegister<any>,
  layout?: 'inline' | 'block'
}
export default function InputChoicesItem({ type, item, register, layout }: InputChoicesItemType) {

  return <>
    <div className={clsx(`flex items-start pb-2`, item.hidden && `hidden`)}>
      <div className="h-5 flex items-center">
        <input
          {...register(item.name)}
          id={item.id}
          name={item.name}
          type={type}
          value={type == 'radio' ? item.id : ''}
          //checked={item.defaultValue}
          //onChange={(e) => console.log(e.target.checked)}
          className={clsx(`focus:ring-primary-600 h-4 w-4 text-primary-600 border-gray-300`, type === 'radio' ? 'rounded-full' : 'rounded')}
        />
      </div>
      {item.label &&
        <div className={clsx(`text-sm`, layout == 'inline' ? 'ml-2' : 'ml-3')}>
          <label htmlFor={item.id} className="text-gray-700">
            {item.label}
          </label>
        </div>}
    </div>
  </>

}
