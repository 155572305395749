import React, { Fragment, ReactNode } from 'react'
import { Menu, Transition } from '@headlessui/react'
import DropdownItems from './DropdownItems'
import { ItemDropdownItemType } from './DropdownItem'


type DropdownMenuType = {
  items: Array<ItemDropdownItemType>,
  buttonComponent: ReactNode
}

function DropdownMenu({ items, buttonComponent }: DropdownMenuType) {
  return (
    <>
      <div className="cursor-pointer group flex items-center justify-center">
        <Menu as="div" className="relative z-[35] flex items-center">
          <Menu.Button as='div'>
            {buttonComponent}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div><DropdownItems items={items} /></div>
          </Transition>
        </Menu>
      </div>
    </>
  )

}

export default DropdownMenu