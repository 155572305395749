import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import InputChoicesItem from './InputChoicesItem'
import { InputChoicesOptionsType } from '../Types/InputChoicesOptionsType'

type InputChoicesType = {
  options: Array<InputChoicesOptionsType>,
  type: 'checkbox' | 'radio',
  legend?: string,
  className?: string,
  register: UseFormRegister<any>,
  layout?: 'inline' | 'block',
}
export default function InputChoices({ type, legend, options, className, register, layout = 'block' }: InputChoicesType) {

  return <>
    <fieldset className={className}>
      {legend && <legend className="block text-sm font-medium text-gray-700 mb-2">{legend}</legend>}
      <div className={clsx(layout == 'inline' ? `space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10` : ``)}>
        {
          options.map((option, key) => (
            <InputChoicesItem key={key} item={option} type={type} register={register} layout={layout} />
          ))
        }
      </div>
    </fieldset>
  </>
}
