import dayjs from 'dayjs';
import React from 'react'
import { useP } from '../../../services/i18n';
import SupervisionLogin from '../SupervisionLogin';
import TableHistoriqueConnexionLogin from '../TableHistoriqueConnexionLogin';
import CheckRigth from '../../../Components/CheckRight/CheckRight';
import Constants from '../../../commons/Constants';

type TabPanelLoginsType = {
  login: any,
  hasOptionSupervision: boolean
}

export default function TabPanelLogins({ login, hasOptionSupervision = false }: TabPanelLoginsType) {
  const p = useP();

  const {
    ROLE_ACCES_SUPERVISION
  } = Constants;

  const datasTableHistoriqueConnexion = login.connectedLoginHistoriques ? [...login.connectedLoginHistoriques].sort((a, b) => a.date > b.date ? -1 : 1).map((d) => ({
    id: 0,
    statut: {
      libelle: d.isConnectedBas ? p.t('supervisionLien.historiqueConnexion.connexion') : p.t('supervisionLien.historiqueConnexion.deconnexion'),
      colorClasses: `bg-${d.isConnectedBas ? "emerald" : "red"}-200 text-${d.isConnectedBas ? "emerald" : "red"}-800`,
    },
    dateDeconnexion: `Depuis le ${dayjs(d.date).format("DD/MM/YYYY à HH:mm")}`,
  })) : []

  const datasTableHistoriquePing = login.ips ? [...login.ips.find((e) => e.isWan).ipStatuts].sort((a, b) => a.horodatage > b.horodatage ? -1 : 1).slice(0, 100).map((d) => {

    const colorsIpStatut = d?.pingResult === 1 ? "emerald" : d?.pingResult === 2 ? "orange" : "red";
    const libelleIpStatut = d?.pingResult === 1 ? p.t('supervisionLien.historiquePing.connecte') : d?.pingResult === 2 ? p.t('supervisionLien.historiquePing.instable') : p.t('supervisionLien.historiquePing.deconnecte');

    return {
      id: 0,
      statut: {
        libelle: libelleIpStatut,
        colorClasses: `bg-${colorsIpStatut}-200 text-${colorsIpStatut}-800`,
      },
      dateDeconnexion: `Depuis le ${dayjs(d.horodatage).format("DD/MM/YYYY à HH:mm")}`,
    }
  }) : []

  const TableHistoriqueConnexionLoginTooltipContent = () => {
    return <>
      <div className='pb-2'>{p.t('lien.legendeEtatPing.connecte')}</div>
      <div className='pb-2'>{p.t('lien.legendeEtatPing.instable')}</div>
      <div className='pb-2'>{p.t('lien.legendeEtatPing.deconnecte')}</div>
    </>
  }

  return <>
    {hasOptionSupervision && <CheckRigth role={ROLE_ACCES_SUPERVISION}><SupervisionLogin login={login} /></CheckRigth>}
    <div className='md:grid md:grid-cols-2 md:gap-4 mt-4'>
      <TableHistoriqueConnexionLogin title={p.t('supervisionLien.historiqueConnexion.title')} datas={datasTableHistoriqueConnexion} />
      <TableHistoriqueConnexionLogin tooltipContent={<><TableHistoriqueConnexionLoginTooltipContent /></>} title={p.t('supervisionLien.historiquePing.title')} datas={datasTableHistoriquePing} />
    </div>
  </>
}