import React, { useState } from 'react';
import ShellLayout from '../ShellLayout';
import SupportContainer from './SupportContainer';
import { useP } from '../../services/i18n';
import { typeNewTicketType } from './ModaleCreationTicket/ModaleCreationTicket';
import { useNavigate, useParams } from 'react-router-dom';


function Support() {

  const navigate = useNavigate();

  const p = useP();
  const breadcrumbDatas = [
    {
      libelle: p.t('navigation.support.label'),
      href: "#",
      current: false
    },
    {
      libelle: p.t('support.tousTickets'),
      href: "#",
      current: true
    }
  ]

  const { ticketId } = useParams();

  const [ticketIdSelected, setTicketIdSelected] = useState<number | null>(ticketId ? parseInt(ticketId) : null)

  const [modaleCreationTicketIsOpen, setModaleCreationTicketIsOpen] = useState(false)
  const [typeTicket, setTypeTicket] = useState<typeNewTicketType>(null)

  return <>
    <ShellLayout>
      <SupportContainer
        breadcrumb={breadcrumbDatas}
        ticketIdSelected={ticketIdSelected}
        onCloseModaleDetailTicket={() => {
          navigate("");
          setTicketIdSelected(null);
        }}
        onClickTableRow={(row) => {
          navigate(row.id);
          setTicketIdSelected(row.id);
        }}
        modaleCreationTicketIsOpen={modaleCreationTicketIsOpen}
        onCloseModaleCreationTicket={() => setModaleCreationTicketIsOpen(false)}
        typeNewTicket={typeTicket}
        addTicket={(type: typeNewTicketType) => {
          setModaleCreationTicketIsOpen(true)
          setTypeTicket(type)
        }}
      />
    </ShellLayout>
  </>;
}

export default Support