import React from 'react';
import Button from '../../Components/Button';
import { FiletypeCsv } from 'react-bootstrap-icons';
import { toastError } from '../../Components/Utils/Utils';
import Constants from '../../commons/Constants';
import { useLazyQuery } from '@apollo/client';
import { TELECHARGER_TICKETS_CSV } from './queries';
import Spinner from '../../Components/Spinner';
import { useP } from '../../services/i18n';

function ExportTicketCsv() {

  const {
    FILER_URL
  } = Constants;

  const p = useP();

  // Téléchargement des Tickets
  const [telechargerTicketCsv, { loading: loadingExportCsv }] = useLazyQuery(TELECHARGER_TICKETS_CSV, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data.telechargerTicketCsv) {
        const { hash, filename } = data.telechargerTicketCsv
        window.open(`${FILER_URL}?hash=${hash}&filename=${filename}`)
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });


  return <Button color='primaryBordered' onClick={() => telechargerTicketCsv()}>
    {loadingExportCsv ? <Spinner size='h-4 w-4 mr-2' /> : <FiletypeCsv className='w-4 h-4 mr-2' />} {p.t('support.telechargerExport')}
  </Button>
}

export default ExportTicketCsv