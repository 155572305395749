
import React from "react"
import { Pie } from "react-chartjs-2"
import chroma from "chroma-js"

type PieChartType = {
  datasetIdKey: any,
  data: any,
  options: any,
  plugins: Array<any>,
  colorPalette?: Array<string>
}

export default function PieChart({ datasetIdKey, data, options, plugins, colorPalette }: PieChartType) {
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
  }
  const chartData = data;

  const alphaColorTab = colorPalette?.map((color) => {
    const alphaColor = chroma(color).alpha(0.4)
    return alphaColor.hex()
  })
  chartData.datasets = chartData.datasets.map((d) => {
    return {
      ...d,
      borderColor: colorPalette ? colorPalette : null,
      backgroundColor: colorPalette ? alphaColorTab : null
    };
  });
  return <>
    <div className="flex-1 h-full">
      <Pie
        options={{ ...defaultOptions, ...options }}
        datasetIdKey={datasetIdKey}
        data={chartData}
        plugins={plugins}
      />
    </div>
  </>
}