import React, { MouseEvent, ReactNode } from 'react'
import { TableDetailRenderType } from './Types/TableDetailRenderType'
import { ColumnType } from './Types/ColumnType'
import { TableOptionsType } from './Types/TableOptionsType'
import TbodyRow from './TbodyRow'
import TbodyRowRupture from './TbodyRowRupture'
import { colWidthResizeType } from './TableContainer'
import clsx from 'clsx'
import { TableRuptureType } from './Types/TableRuptureType'
import Spinner from '../Spinner'
import Error from '../Error/Error'

type TbodyType = {
  columns: Array<ColumnType>,
  rupture: TableRuptureType,
  datas: Array<object>,
  loading: boolean,
  error: boolean,
  tableOptions: TableOptionsType,
  detailComponentRender?(renderProps: TableDetailRenderType): ReactNode,
  onSelectRow?: (row: object, checked: boolean) => void,
  onResize: (width: string, index: number) => void,
  colWidthResize?: colWidthResizeType | undefined,
  onDoubleClickRow?: (row: object) => void,
  onClickRow?: (row: object, event: MouseEvent) => void,
  emptyMessage: string | undefined,
  selectedRows?: Array<number>,
}

function Tbody({
  columns,
  rupture,
  datas,
  tableOptions,
  detailComponentRender,
  loading,
  error,
  onSelectRow,
  onResize,
  colWidthResize,
  onDoubleClickRow,
  onClickRow,
  emptyMessage,
  selectedRows }: TbodyType,) {

  if (loading) {
    return <tbody className='h-full'>
      <tr className=''>
        <td className='' colSpan={columns.length} align="center">
          <div className=''><Spinner color='primary' /></div>
        </td>
      </tr>
    </tbody>
  }

  if (error) {
    return <tbody>
      <tr>
        <td colSpan={columns?.length} className="h-full w-full text-sm font-medium text-red-700 text-center p-2"><Error /></td>
      </tr>
    </tbody>
  }

  if (datas.length === 0) {
    return <tbody>
      <tr>
        <td colSpan={columns?.length} className="h-full w-full text-sm font-medium text-gray-700 text-center p-2">{emptyMessage}</td>
      </tr>
    </tbody>
  }
  return (
    <>
      <tbody className={clsx(`bg-white `)}>
        {
          !loading &&
          datas.map((row, indexRow) => {
            const elements: Array<React.ReactNode> = [];
            if (rupture.isVisible(row, indexRow > 0 ? datas[indexRow - 1] : null)) {
              const hasOneMore = tableOptions.colSelect ? 1 : 0;
              const colspan = columns.length + hasOneMore
              elements.push(<TbodyRowRupture colspan={colspan} key={`rupture-${indexRow}`} value={typeof rupture.value === 'function' ? rupture.value(row) : rupture.value} tableOptions={tableOptions} />)
            }
            elements.push(<TbodyRow
              colWidthResize={colWidthResize}
              onResize={onResize}
              onSelectRow={onSelectRow}
              selectedRows={selectedRows}
              key={indexRow}
              row={row}
              indexRow={indexRow}
              columns={columns}
              tableOptions={tableOptions}
              detailComponentRender={detailComponentRender}
              onDoubleClickRow={onDoubleClickRow}
              onClickRow={onClickRow}
            />)

            return elements
          })
        }
      </tbody>
    </>
  )

}

export default Tbody