import dayjs from "dayjs";
import React from "react";
import { filterTypes } from "../../Components/Filters/Types/filterTypes";


type SupervisionFiltersValueType = {
  filters : Array<filterTypes>
}
export default function SupervisionFiltersValue({filters}:SupervisionFiltersValueType){
  const formatFilterDate = (d) => {
    // Keys ne peut contenir qu'une valeur. Dans ce cas on le remet en tableau
    const keys = Array.isArray(d) ? d : [d];
    return keys
      .map((k) => {
        const d = dayjs(k);
        return d.isValid() ? d.format("DD/MM/YYYY") : k;
      })
      .join(" - ");
  };
  return <>
    <div className="text-sm text-primary-700 mr-2">
        {filters[0] && (
          <div className="text-sm font-medium px-2">{`${formatFilterDate(
            filters[0].libValue
          )}`}</div>
        )}
    </div>
  </>
}