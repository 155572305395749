import clsx from 'clsx';
import React, { useState, useRef, useEffect } from 'react';
import { Star, StarFill } from 'react-bootstrap-icons';
type RatingType = {
  precision?: number,
  totalStars?: number,
  emptyIcon?: any,
  filledIcon?: any,
  value?: number,
  sizeClass?: string,
  colorClass?: string,
  onSelect?: (note: number) => void,
  readonly?: boolean,
}
const Rating = ({
  precision = 1,
  totalStars = 5,
  emptyIcon = Star,
  filledIcon = StarFill,
  value = -1,
  sizeClass = "h-8 w-8",
  colorClass = "text-yellow-500",
  readonly = false,
  onSelect,
}: RatingType) => {

  const [activeStar, setActiveStar] = useState(value);
  const [hoverActiveStar, setHoverActiveStar] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const ratingContainerRef = useRef<any>();

  const calculateRating = (e) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { width, left } = ratingContainerRef?.current.getBoundingClientRect();
    const percent = (e.clientX - left) / width;
    const numberInStars = percent * totalStars;
    const nearestNumber = Math.round((numberInStars + precision / 2) / precision) * precision;

    return Number(nearestNumber.toFixed(precision.toString().split('.')[1]?.length || 0));
  };

  useEffect(() => {
    setActiveStar(value)
  }, [value])


  const handleClick = (e) => {
    if (!readonly) {
      setIsHovered(false);
      setActiveStar(calculateRating(e));
      onSelect?.(calculateRating(e))
    }
  };

  const handleMouseMove = (e) => {
    if (!readonly) {
      setIsHovered(true);
      setHoverActiveStar(calculateRating(e));
    }
  };

  const handleMouseLeave = () => {
    if (!readonly) {
      setHoverActiveStar(-1); // Reset to default state
      setIsHovered(false);
    }
  };
  const EmptyIcon = emptyIcon;
  const FilledIcon = filledIcon;

  return (
    <div
      className={clsx(`inline-flex relative text-left`, readonly && value === -1 ? `cursor-auto` : `cursor-pointer`)}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={ratingContainerRef}
    >
      {[...new Array(totalStars)].map((arr, index) => {
        const activeState = isHovered ? hoverActiveStar : activeStar;

        const showEmptyIcon = activeState === -1 || activeState < index + 1;

        const isActiveRating = activeState !== 1;
        const isRatingWithPrecision = activeState % 1 !== 0;
        const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
        const showRatingWithPrecision =
          isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;

        return (
          <div
            className={clsx(`relative`, readonly && value === -1 ? `cursor-auto` : `cursor-pointer `)}

            key={index}
          >
            <div
              className='overflow-hidden absolute'
              style={{
                width: showRatingWithPrecision ? `${(activeState % 1) * 100}%` : '0%',
              }}
            >
              <FilledIcon className={clsx(sizeClass)} />
            </div>
            {/*Note here */}
            <div
              className={clsx(`mr-1`, showEmptyIcon ? 'text-gray-500' : colorClass)}
            >
              {showEmptyIcon ? <EmptyIcon className={clsx(sizeClass, colorClass)} /> : <FilledIcon className={clsx(sizeClass, colorClass)} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Rating;