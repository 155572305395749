import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Spinner from '../../../Components/Spinner';
import { useP } from '../../../services/i18n';
import ShellLayout from '../../ShellLayout';
import { GET_ABONNEMENTS_GROUPES, GET_DSLS_ACTIFS_BLUE, GET_VGAS_ACTIFS_BLUE } from '../queries';
import ContratContainer from './ContratContainer';
import TabPanelAbonnementSF from './TabPanelAbonnementSF';
import TabPanelAbonnementDslVga from './TabPanelAbonnementDslVga';
import TabPanelAbonnementVide from './TabPanelAbonnementVide';

export default function Contrat() {
  const p = useP();

  const { numcli } = useParams();

  const [navigation, setNavigation] = useState<Array<any>>([])
  const [panels, setPanels] = useState<Array<any>>([])

  const { loading: loadingAbonnementsGroupes, data: dataAbonnements } = useQuery(GET_ABONNEMENTS_GROUPES, {
    variables: {
      numContrat: numcli,
      withOutVente: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const { loading: loadingDsls, data: dataDsls } = useQuery(GET_DSLS_ACTIFS_BLUE, {
    variables: {
      numcli
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const { loading: loadingVgas, data: dataVgas } = useQuery(GET_VGAS_ACTIFS_BLUE, {
    variables: {
      numcli
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const breadcrumbDatas = [
    {
      libelle: p.t('contrats.mainTitle'),
      href: `../`,
      current: false
    },
    {
      libelle: numcli ? numcli : '',
      href: "#",
      current: true
    }
  ]

  // Ajout des onglets selon les données  
  useEffect(() => {
    const navigationArray: Array<any> = []
    const panelsArray: Array<any> = []

    if (dataAbonnements?.abonnementsActifsGroupes) {
      navigationArray.push({
        libelle: "abonnementSF",
        jsx: <><div className='px-2'>{p.t('contrats.detail.abonnementSf')}</div></>
      })
      panelsArray.push(<TabPanelAbonnementSF numcli={numcli} key={'panelAbonnementSF'} datas={dataAbonnements ? dataAbonnements.abonnementsActifsGroupes : []} />)
    }

    if (dataVgas?.vgasActifsBlue || dataDsls?.dslsActifsBlue) {
      navigationArray.push({
        libelle: "abonnementDSL_VGA",
        jsx: <><div className='px-2'>{p.t('contrats.detail.abonnementDslVga')}</div></>
      })
      panelsArray.push(<TabPanelAbonnementDslVga key={'panelAbonnementDslVga'} datasVga={dataVgas?.vgasActifsBlue ? dataVgas.vgasActifsBlue : []} datasDsl={dataDsls?.dslsActifsBlue ? dataDsls.dslsActifsBlue : []} />)
    }
    if (!loadingAbonnementsGroupes && !loadingDsls && !loadingVgas && !dataAbonnements?.abonnementsActifsGroupes && !dataVgas?.vgasActifsBlue && !dataDsls?.dslsActifsBlue) {
      navigationArray.push({
        libelle: "abonnementVide",
        jsx: <><div className='px-2'>{p.t('contrats.detail.abonnementSf')}</div></>
      })
      panelsArray.push(<TabPanelAbonnementVide key={'panelAbonnementVide'} />)
    }
    setNavigation(navigationArray)
    setPanels(panelsArray)
  }, [dataAbonnements, dataVgas, dataDsls, loadingAbonnementsGroupes, loadingDsls, loadingVgas])



  return <>

    <ShellLayout>
      {
        (loadingDsls || loadingVgas) ?
          <div className="flex items-center justify-center h-full w-full"><Spinner /></div>
          :
          <>
            <ContratContainer
              breadcrumb={breadcrumbDatas}
              navigation={navigation}
              panels={panels}
              numcli={numcli ? numcli : ''}
            />
          </>
      }
    </ShellLayout>

  </>

}