import React from "react"
import { useP } from "../../services/i18n"
import FacturesContainer from "./FacturesContainer"
import { GET_CONTRATS, GET_FACTURES } from "./queries";
import { useQuery } from "@apollo/client";
import ShellLayout from "../ShellLayout";
import SlideOver from "../SlideOver/SlideOver";
import { reduceContrats } from "../SlideOver/SlideOverReducer";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

export default function Factures() {

  const p = useP();

  const [searchParams] = useSearchParams();
  const numcli = searchParams.get('contrat');

  // Table Datas site
  const { loading: loadingContrat, data: dataContrat, error: errorContrat } = useQuery(GET_CONTRATS, {
    fetchPolicy: "network-only",
    variables: {
      sorters: [
        { column: 'nom' },
        { column: 'numcli' }
      ],
      includes: [{
        association: 'statutContrat',
        required: true,
      }],
    }
  });
  const slideOverData = reduceContrats(dataContrat?.clients?.items ? dataContrat.clients.items : [])

  //on récupére aussi l'année de la facture la plus ancienne
  const { loading: loadingAnneeDebut, data: dataAnneeDebut } = useQuery(GET_FACTURES, {
    fetchPolicy: "network-only",
    variables: {
      sorters: [
        {
          column: "dateFacture",
          order: "ASC"
        },
      ],
      pagination: {
        limit: 1
      }
    }
  });

  //on récupére aussi l'année de la facture la plus récente
  const { loading: loadingAnneeFin, data: dataAnneeFin } = useQuery(GET_FACTURES, {
    fetchPolicy: "network-only",
    variables: {
      sorters: [
        {
          column: "dateFacture",
          order: "DESC"
        },
      ],
      pagination: {
        limit: 1
      }
    }
  });

  const breadcrumbDatas = [
    {
      libelle: p.t('navigation.factures.label'),
      href: "#",
      current: false
    },
    {
      libelle: numcli ? numcli : p.t('factures.tousContrats'),
      href: "#",
      current: true
    }
  ]

  // Année mini trouvée dans les factures
  const dateMinSelecteurAnnee = dataAnneeDebut?.factures?.items.length > 0 ? dayjs(dataAnneeDebut.factures.items[0].dateFacture).year() : dayjs().year()
  // Année max trouvée dans les factures
  const dateMaxSelecteurAnnee = dataAnneeFin?.factures?.items.length > 0 ? dayjs(dataAnneeFin.factures.items[0].dateFacture).year() : dayjs().year()

  return (
    <>
      <SlideOver
        records={slideOverData}
        defaultItemId={numcli || 0}
        allItemsMenu={{
          link: ".",
          title: p.t('factures.tousContrats')
        }}
        filter={(record: any, searchValue: string) => {

          const { record: contrat } = record

          return ((contrat.nom?.toUpperCase().includes(searchValue.toUpperCase().trim().replace(/[\s]{2,}/g, " ")))
            || (contrat.numcli?.toUpperCase().includes(searchValue.toUpperCase().trim().replace(/[\s]{2,}/g, " ")))
          )

        }}
        placeholder={p.t("slideOver.search.placeholderContrats")}
      />
      <ShellLayout>
        <FacturesContainer
          loading={loadingContrat || loadingAnneeDebut || loadingAnneeFin}
          error={errorContrat}
          breadcrumb={breadcrumbDatas}
          numcli={numcli === null ? undefined : numcli}
          dateMinSelecteurAnnee={dateMinSelecteurAnnee}
          dateMaxSelecteurAnnee={dateMaxSelecteurAnnee}
        />
      </ShellLayout>
    </>
  )
}