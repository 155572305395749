import React, { useState } from 'react'
import SlideOverListItem from './SlideOverListItem'
import { SlideOverItemAll, SlideOverItemType } from './SlideOverItemType'

type SlideOverListType = {
  records: Array<SlideOverItemType>
  rightContainer: any
  allItemsMenu?: SlideOverItemAll
  defaultItemId?: number | string
}

export default function SlideOverList({ records, rightContainer: Component, allItemsMenu, defaultItemId = 0 }: SlideOverListType) {

  const [activeItem, setActiveItem] = useState<number | string>(defaultItemId)

  return (
    <ul role="list" className="flex-1 overflow-x-hidden overflow-y-auto">
      {allItemsMenu && <SlideOverListItem
        key={0}
        id={0}
        title={allItemsMenu.title}
        link={allItemsMenu.link}
        active={activeItem == 0}
        onClick={() => setActiveItem(0)}
      />
      }

      {records.map((record, index) => {
        return <SlideOverListItem
          key={index}
          id={record.id}
          title={record.title}
          subtitle={record.subtitle}
          link={record.link}
          searchParams={record.searchParams}
          active={record.id == activeItem}
          rightContainer={Component ? <Component data={record.additionalData} /> : undefined}
          onClick={(id) => setActiveItem(id)}
        />
      })}
    </ul >
  )
}