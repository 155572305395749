import React from 'react'
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Tooltip from '../../Tooltip/Tooltip';
import { useP } from '../../../services/i18n';

type CellEditFormatterType = {
  row: any,
  action: (row: any) => void,
  //func: React.MouseEventHandler<HTMLDivElement>
}

export default function CellEditFormatter({ row, action }: CellEditFormatterType) {
  const p = useP();
  return <>
    <Tooltip
      button={
        <div className="cursor-pointer group flex justify-center" onClick={() => action(row)}>
          <PencilSquareIcon className='h-5 w-5 text-gray-500 group-hover:text-secondary-500' />
        </div>
      }
      content={<div className='text-xs'>{p.t("actions.editer")}</ div>}
      placement='top'
      className='flex items-center justify-center'
    />

  </>
}