import { DocumentTextIcon } from '@heroicons/react/24/outline';
import React from 'react'
import CardIcon from '../../../Components/CardIcon';
import StatTickets from './StatTickets';

type TicketsLienType = {
  datas: any[]
}

export default function TicketsLien({ datas }: TicketsLienType) {

  const datasTP = datas.map((t) => ({
    id: t.id,
    stat: <StatTickets ticket={t} />,
    statType: "JSX",
    icon: DocumentTextIcon,
    classNameIconColor: "bg-secondary-400",
  }))

  return <>
    <div className='md:grid md:grid-cols-2 md:gap-4 mb-4'>
      {
        datasTP.map((tp, i) => (
          <CardIcon item={tp} key={i} />
        ))
      }
    </div>
  </>
}