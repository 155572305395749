import React, { ReactNode, useEffect, useRef, useState } from "react"
import NewMessage from "./NewMessage"
import FilDiscussions from "./FilDiscussions"
import { toolbarNewMessageOptionsType } from "./NewMessageToolbar/NewMessageToolbarItem"
import Spinner from "../Spinner"
import { DocumentNode } from "graphql"
import Discussion from "./Discussion"
import { PencilSquareIcon } from "@heroicons/react/24/outline"
import Button from "../Button"
import { useP } from "../../services/i18n"
import clsx from "clsx"
import { CircleFill } from "react-bootstrap-icons"
import DiscussionScrollTo from "./DiscussionScrollTo"

export type FichiersType = {
  id: number,
  extension: string,
  name: string,
  size?: string,
  date?: string,
}

export type dataDiscussionsType = {
  auteur: string,
  date: string,
  contenu: ReactNode | string,
  contenuOneLine: string,
  auteurIcon?: ReactNode | string,
  colorClassIcon: string,
  bgClass: string,
  fichiers?: Array<FichiersType>,
  dateLecture?: string | null | undefined
}

export type modeAffichageFilType = "LAST_MORE" | "TIMELINE";


type DiscussionsType = {
  discussions: Array<dataDiscussionsType>,
  toolbarNewMessageOptions: toolbarNewMessageOptionsType | false,
  modeAffichageFil?: modeAffichageFilType,
  saveMessage: (message: string, files: any[]) => Promise<boolean>,
  loadingMessage?: boolean,
  loadingSendMessage?: boolean,
  queryDownload: DocumentNode,
  originalMessageFirst?: boolean
  onChangeNewCommentaire?: (message: string, files: any[]) => void,
  attachmentsHidden?: boolean
}

type changeNewCommentaireType = {
  commentaire: string,
  files: any[]
}

export default function Discussions({ discussions,
  toolbarNewMessageOptions,
  modeAffichageFil = "LAST_MORE",
  saveMessage,
  loadingMessage = false,
  loadingSendMessage = false,
  queryDownload,
  originalMessageFirst = true,
  onChangeNewCommentaire,
  attachmentsHidden = false
}: DiscussionsType) {

  const p = useP();
  const discussionsRef = useRef<any>();
  const firstMessage = discussions.slice(0, 1)
  const reduceDiscussions = discussions.filter((discussion) => discussion !== firstMessage[0])
  const [displayNewMessage, setDisplayNewMessage] = useState(false)
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [scrollbarIsTop, setScrollbarIsTop] = useState(false);
  const [lastDiscussionChild, setLastDiscussionChild] = useState<any>(null);
  const [toggleDiscussion, setToggleDiscussion] = useState<any>(false);
  const [changeNewCommentaire, setChangeNewCommentaire] = useState<changeNewCommentaireType>({ commentaire: '', files: [] })

  useEffect(() => {
    if (!loadingMessage) {
      const positionScrollbar = () => {
        if (discussionsRef.current) {
          setHasScrollbar(discussionsRef.current.scrollHeight > discussionsRef.current.clientHeight);
        }
      }
      setTimeout(positionScrollbar, 0);
    }
  }, [loadingMessage])

  useEffect(() => {
    lastDiscussionChild?.scrollIntoView(true);
  }, [lastDiscussionChild])

  useEffect(() => {
    setHasScrollbar(discussionsRef.current.scrollHeight > discussionsRef.current.clientHeight)
  }, [toggleDiscussion])

  useEffect(() => {
    onChangeNewCommentaire?.(changeNewCommentaire.commentaire, changeNewCommentaire.files)
  }, [changeNewCommentaire]);

  const handleScroll = () => {
    if (discussionsRef.current.scrollTop === 0) {
      setScrollbarIsTop(true)
    } else {
      setScrollbarIsTop(false)
    }
  };

  const containerRef = useRef<any>();

  const [containerHeight, setContainerHeight] = useState(0)
  useEffect(() => {
    if (containerRef) {
      setContainerHeight(containerRef.current.offsetHeight)
    }
  }, [])


  return <>
    <div className=" flex-1 flex flex-col overflow-hidden short:overflow-auto" ref={containerRef}>

      {
        originalMessageFirst &&
        <>
          <div className="">
            <div className={clsx(`px-4 pt-4`)}>
              <Discussion
                maxHeight="lg:max-h-[300px]"
                discussion={firstMessage[0]}
                queryDownload={queryDownload}
                expandable={true}
                isOpen={false}
                // isOpen={displayNewMessage ? false : true}
                attachmentsHidden={attachmentsHidden}
              />
            </div>
          </div>
          <div className="w-full flex items-center justify-center py-0 pr-4 pl-16 h-5">
            <>
              <div className={clsx(`flex items-center space-x-1 transition-opacity `, !scrollbarIsTop && hasScrollbar ? `opacity-1` : `opacity-0`)}>
                <CircleFill className="h-2 w-2 text-gray-600" /><CircleFill className="h-2 w-2 text-gray-600" /><CircleFill className="h-2 w-2 text-gray-600" />
              </div>
            </>

          </div>
        </>
      }

      <div className='mb-4 px-4 flex-1 flex flex-col overflow-hidden short:overflow-visible'>


        {
          loadingMessage ?
            <div className='h-full flex flex-1 items-center justify-center '>
              <Spinner />
            </div> :
            <>
              <div className="relative mt-0 flex-1 flex flex-col overflow-hidden">
                {hasScrollbar &&
                  <div className="absolute top-2 right-4 z-10">
                    <DiscussionScrollTo refElement={discussionsRef.current} tooltip={true} />
                  </div>
                }

                <div className={clsx(`flex flex-col overflow-auto short:mt-4`, hasScrollbar && `pr-2`)} ref={discussionsRef} onScroll={handleScroll}>
                  <FilDiscussions
                    getLastChild={(lastChild) => setLastDiscussionChild(lastChild)}
                    onToggle={() => {
                      setToggleDiscussion(!toggleDiscussion)
                    }
                    }
                    discussions={reduceDiscussions}
                    modeAffichageFil={modeAffichageFil}
                    queryDownload={queryDownload}
                    attachmentsHidden={attachmentsHidden}
                  />
                </div>
              </div></>
        }

      </div>

      {toolbarNewMessageOptions &&
        <div
          className={clsx(`px-4 pt-0 pb-4 flex flex-col short:sticky short:bottom-0 short:bg-white short:pt-4`)}
        >
          {
            !displayNewMessage &&
            <div className="flex items-center justify-center w-full">
              <Button size="small" color="primaryBordered" onClick={() => setDisplayNewMessage(true)}>
                <div className="flex items-center space-x-2">
                  <div><PencilSquareIcon className="h-4 w-4" /></div>
                  <div>{p.t('support.detail.nouveauMessage')}</div>
                </div>
              </Button>
            </div>
          }
          {displayNewMessage &&
            <div className="mt-0 h-full">
              <NewMessage
                toolbarNewMessageOptions={toolbarNewMessageOptions}
                saveMessage={saveMessage}
                loadingSendMessage={loadingSendMessage}
                textareaHeight="100px"
                containerHeight={containerHeight}
                // textareaHeight={`${containerHeight - 155}px`}
                cancelButton
                onCancel={() => {
                  setDisplayNewMessage(false)
                  onChangeNewCommentaire?.("", [])
                }}
                placeholder={p.t('support.detail.nouveauMessage')}
                onChange={(commentaire) => setChangeNewCommentaire({ ...changeNewCommentaire, commentaire })}
                onChangePJ={(files) => setChangeNewCommentaire({ ...changeNewCommentaire, files })}
                autoFocus={displayNewMessage ? true : false}
              />
            </div>}
        </div>
      }

    </div >
  </>
}