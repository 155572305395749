import React, { useContext } from "react"
import { useCheckRight } from "../../../Components/CheckRight/CheckRight";
import { useP } from "../../../services/i18n";
import Constants from "../../../commons/Constants";
import InputButtonsGroup from "../../../Components/Form/InputButtonsGroup/InputButtonsGroup";
import { authContext } from "../../../AuthContext";
import { useQuery } from "@apollo/client";
import { GET_DONNEES_REFERENCE_MODALE } from "../../Support/ModaleCreationTicket/queries";

type ImpactProductionType = {
  login: any,
  onChange: (val: any) => void
}
export default function ImpactProduction({ login, onChange }: ImpactProductionType) {
  const { data: dataDonneesReference } = useQuery(GET_DONNEES_REFERENCE_MODALE);

  const buttonsImpact: any[] = [];
  dataDonneesReference?.ticketImpacts?.map((impact) => {
    buttonsImpact.push(
      {
        value: impact.id,
        libelle: impact.libelle
      }
    )
  })
  const { loggedInUser } = useContext(authContext);
  const { checkRight } = useCheckRight();
  const p = useP();
  const {
    ROLE_ADMIN_PRESTATION
  } = Constants;
  return <>
    {
      (loggedInUser?.compte?.isVip && checkRight(ROLE_ADMIN_PRESTATION)) ?
        <div className='flex lg:items-center space-x-2'>
          <div className='text-gray-700 font-medium'>{p.t('support.detail.impactProduction')}</div>
          <div className='flex-1'>
            <InputButtonsGroup
              onSelectedButton={(value) => {
                onChange(value)
              }}
              buttons={buttonsImpact}
              multi={false}
              name="impact"
              selectedButtons={login.ticketImpact ? [login.ticketImpact.id] : []}
            /></div>
        </div>
        :
        <div className='flex items-center space-x-2'>
          {(login.ticketImpact) ?
            <><div className='text-gray-700 font-medium'>{p.t('support.detail.impactProduction')}</div>
              <div className='font-medium bg-gray-500 text-white rounded-md px-1 py-0'>{login.ticketImpact?.libelle}</div></>
            : <div className='text-gray-700 font-medium'>{p.t('support.detail.pasImpactProduction')}</div>}
        </div>

    }
  </>
}