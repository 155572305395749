import React from "react";
import Button from "../../../Components/Button";
import Spinner from "../../../Components/Spinner";
import { useP } from "../../../services/i18n";

type ModalCommandeGSMFooterType = {
  loading: boolean,
  onCancel: () => void,
  onSave: () => void,
}

export default function ModalCommandeGSMFooter({
  loading,
  onCancel,
  onSave
}: ModalCommandeGSMFooterType) {
  const p = useP()
  return <>
    <div className="flex items-center justify-between">
      <div className="text-gray-700 text-sm">{p.t('commandeGSM.champsObligatoires')}</div>
      <div className="flex items-center justify-end">
        <Button disabled={loading} onClick={() => onCancel()} color="primaryLight" className="mr-2">{p.t('actions.annuler')}</Button>
        <Button disabled={loading} onClick={() => onSave()}>{loading ? <Spinner /> : p.t('actions.valider')}</Button>
      </div>
    </div>
  </>
}