import clsx from "clsx"
import React, { useState } from "react"

export type OptionSelectType = {
  code: string | number,
  libelle: string
}

type SelectNativeType = {
  onSelect: (selectedElement: any) => void,
  defaultValue?: string | number,
  selectDatas: Array<OptionSelectType>,
  className?: string,
  inlineLabel?: boolean,
  label?: string | null,
}
export default function SelectNative({
  selectDatas,
  onSelect,
  defaultValue = "",
  label = null,
  inlineLabel = false,
  className = 'bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default text-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500'
}: SelectNativeType) {

  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const onSelected = (event) => {
    setSelectedValue(event.target.value)
    onSelect(event.target.value)
  }

  return <div className={clsx(inlineLabel && `flex items-center`)}>
    {label && <div className={clsx(`block text-sm font-medium text-gray-700 mb-1`, inlineLabel && `mr-2`)}>{label}</div>}
    <select className={className} defaultValue={selectedValue} onChange={(event) => onSelected(event)}>
      {selectDatas.map((item, o) => (
        <option className="text-sm" key={o} value={item.code}>{item.libelle}</option>
      ))}
    </select>
  </div>

}