import { FunnelIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import Badge from '../Badge';
import Button from '../Button';
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import { CheckSquareFill as CheckSquareFillIcon, DashSquareFill, Square as SquareIcon } from 'react-bootstrap-icons'
import { useP } from '../../services/i18n';

type FiltersTableType = {
  activeFilters: any,
  onSelectedButtonGroup: (value: any, filtreKey: string) => void,
  filtresItems: any,
  filtreKey: any
  multi?: boolean
  selectAll?: boolean
}

const CHECK_ALL = "ALL";
const CHECK_NONE = "NONE";

export default function FiltersTable({
  activeFilters,
  filtresItems,
  filtreKey,
  onSelectedButtonGroup,
  multi = true,
  selectAll = false,
}: FiltersTableType) {

  const p = useP();

  const [isOpen, setIsOpen] = useState(false)
  const [activeFilterButton, setActiveFilterButton] = useState(false)
  const [actifs, setActifs] = useState(activeFilters)
  const [allState, setAllState] = useState(actifs.length > 0 ? CHECK_ALL : CHECK_NONE)

  const refBtnFilter = useRef<any>();
  useOnClickOutside(refBtnFilter, () => {
    setIsOpen(false)
    setActiveFilterButton(false)
  });

  const onSwithAll = () => {
    if (allState === CHECK_ALL) {
      setAllState(CHECK_NONE)
      onSelectedButtonGroup([], filtreKey)
    } else {
      setAllState(CHECK_ALL)
      onSelectedButtonGroup(filtresItems.map((f: any) => f.value), filtreKey)
    }
  }

  useEffect(() => {
    setActifs(activeFilters)
  }, [activeFilters])

  return <>
    <div className='relative' ref={refBtnFilter}>
      {
        multi ?
          <>
            <div className='hidden lg:block'>
              <Button
                size='medium'
                className='py-1.5'
                color={activeFilterButton ? 'secondaryLight' : 'grayBordered'}
                onClick={() => {
                  setIsOpen(true);
                  setActiveFilterButton(true)
                }}>
                <FunnelIcon className='h-4 w-4 mr-1' />
                <div className={clsx(`mr-2`, activeFilterButton ? `text-secondary-800` : ``)}>{filtreKey}</div>
                <Badge itemColor={activeFilterButton ? `white` : 'primary'} itemLibelle={`${actifs.length}`} className='text-[0.65rem] py-0' />
              </Button>
              {
                isOpen && <div className='text-right absolute top-10 right-0 z-50 min-w-[200px] bg-white shadow rounded-md'>
                  {selectAll && <div className='px-2 py-2.5 border border-gray-300 cursor-pointer hover:bg-gray-50 rounded-t-md' onClick={onSwithAll}>
                    <>
                      <button
                        type="button"
                        className={clsx(
                          `relative block w-full items-center border-gray-300  text-sm font-medium `,
                          ` focus:z-10 focus:outline-none focus:ring-0 text-primary-600`,
                        )}>
                        <div className='flex items-center'>
                          {
                            actifs.length !== filtresItems.length
                              ? actifs.length > 0
                                ? <DashSquareFill className='h-3 w-3 mr-1.5' />
                                : <SquareIcon className='h-3 w-3 mr-1.5' />
                              : <CheckSquareFillIcon className='h-3 w-3 mr-1.5' />
                          }
                          <div className='truncate'>
                            {allState === CHECK_ALL ? p.t("filtersTable.selectNone") : p.t("filtersTable.selectAll")}
                          </div>
                        </div>
                      </button>
                    </>
                  </div>}
                  <ButtonsGroup
                    onSelectedButton={
                      (value) => {
                        onSelectedButtonGroup(value, filtreKey)
                      }
                    }
                    buttons={filtresItems}
                    selectedButtons={actifs}
                    multi={multi}
                    vertical={true}
                    selectAll={selectAll}
                  />
                </div>
              }
            </div>
            <div className='block lg:hidden'>
              <div className='text-xs font-medium text-gray-700 mb-1'>{filtreKey}</div>
              <ButtonsGroup
                onSelectedButton={
                  (value) => {
                    onSelectedButtonGroup(value, filtreKey)
                  }
                }
                buttons={filtresItems}
                selectedButtons={actifs}
                multi={multi}
                vertical={true}
              />
            </div>
          </>
          :
          <ButtonsGroup
            onSelectedButton={
              (value) => {
                onSelectedButtonGroup(value, filtreKey)
              }
            }
            buttons={filtresItems}
            selectedButtons={actifs}
            multi={multi}
          />
      }
    </div >
  </>
}