import React, { useEffect, useState } from 'react'
import { useP } from "../../../services/i18n";
import WidgetElementType from '../WidgetElementType';
import WidgetSize from '../WidgetSize';
import WidgetLayout from '../WidgetLayout/WidgetLayout';
import MapLiens from '../../../Shell/Prestations/PrestationView/LienView/MapLiens';
import { GET_LIEN } from '../../../Shell/Prestations/queries';
import { useQuery } from '@apollo/client';

export default function WidgetCarteLiensTiny(props: WidgetElementType) {
    const p = useP();

    // Datas Liens
    const { loading, data } = useQuery(GET_LIEN, {
      variables: {
        idSite: null,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });
    const [mapDatas, setMapDatas] = useState<any[]>([]);
    
    useEffect(() => {
      let datasLiensEnService = data ? data.prestationLiens : [];
      datasLiensEnService = datasLiensEnService.filter((d) => ("EN SERVICE" === d.etat && d.logins.length > 0))
      setMapDatas(datasLiensEnService)
    }, [data])
    
    return (
        <WidgetLayout
          header={
            <div>
                {p.t('dashboardCompte.carteLiens.titre')}
            </div>
          }
          size={WidgetSize.TINY}
          addWidget={props.addWidget}
          moveUpWidget={props.moveUpWidget}
          moveDownWidget={props.moveDownWidget}
          removeWidget={props.removeWidget}
        >
            <div className="h-[420px] border border-gray-200 rounded-md">
              <MapLiens
                loading={loading}
                datas={mapDatas}
                disableScrollZoom={true}
              />
            </div>
        </WidgetLayout>
    )

}