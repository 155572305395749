import React from "react";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import duration, { DurationUnitType } from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
require("dayjs/locale/fr");
dayjs.extend(duration);
dayjs.extend(relativeTime);

import secureLocalStorage from 'react-secure-storage';
import { LoggedInUserType } from "../../types/LoggedInUserType";
import Constants from "../../commons/Constants";
import { isArray } from "@apollo/client/cache/inmemory/helpers";
import convert from "convert";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/24/solid";

const {
  TICKET_STATUT_ID_EN_COURS,
  TICKET_STATUT_ID_EN_ATTENTE_CLIENT,
  TICKET_STATUT_ID_EN_ATTENTE_FOURNISSEUR,
  TICKET_STATUT_ID_CLOTURE_TECHNIQUE,
  TICKET_STATUT_ID_NOUVEAU,
  TICKET_STATUT_ID_OBSERVATION
} = Constants;

/** Toasts */
const TOAST_DEFAULT_DURATION = 4000;
const toastInfo = (message) => {
  toast(message, {
    duration: TOAST_DEFAULT_DURATION,
    style: { background: "#2563eb", color: "#FFF", fontWeight: 600 },
    icon: <div className="w-6"><InformationCircleIcon className="text-white w-6 h-6" /></div>,
    position: "top-center"
  });
};

const toastSuccess = (message) => {
  toast.success(message, {
    duration: TOAST_DEFAULT_DURATION,
    style: { background: "#10b981", color: "#FFF", fontWeight: 600 },
    icon: <div className="w-6"><CheckCircleIcon className="text-white w-6 h-6" /></div>,
    position: "top-center"
  });
};

const toastError = (message, duration = TOAST_DEFAULT_DURATION) => {
  toast.custom((t) => (
    <div
      className={`flex items-center transition ease-in-out duration-1000 ${t.visible ? 'opacity-1' : 'opacity-0'
        } pl-2 max-w-[350px] bg-red-600 text-white font-semibold shadow-lg rounded-md pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="w-6">
        <ExclamationCircleIcon className="text-white w-6 h-6" />
      </div>
      <div className="flex-1 flex ml-2 mr-4 py-2">
        {message}
      </div>
      <div className="flex h-full border-l border-white/30">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium hover:bg-white/10"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  ), {
    duration: duration,
  })
};

/** Local Storage */
const getLocalStorage = (key, defaultValue: any = null) => {
  const account = secureLocalStorage.getItem("loggedInUser") as LoggedInUserType;
  const compteId = account?.compte?.id;
  let value = localStorage.getItem(key + compteId)
    ? localStorage.getItem(key + compteId)
    : defaultValue;

  value = value ? JSON.parse(value) : value;
  return value
};

const setLocalStorage = (key, item) => {
  const account = secureLocalStorage.getItem("loggedInUser") as LoggedInUserType;
  const compteId = account?.compte?.id;
  localStorage.setItem(key + compteId, JSON.stringify(item));
};

const removeLocalStorage = (key) => {
  const account = secureLocalStorage.getItem("loggedInUser") as LoggedInUserType;
  const compteId = account?.compte?.id;
  localStorage.removeItem(key + compteId);
}

/** */
const getItemByCode = (items: Array<any> = [], code) => {
  const res = items.filter((item) => item.code === code);
  return res.at(0);
};

const getPosition = ({ items = [] }, index) => {
  let res = "middle";

  if (items.length === 1) {
    res = "unique";
  } else if (index === 0) {
    res = "first";
  } else if (index === items.length - 1) {
    res = "last";
  }

  return res;
};

/** datetime */
const datetimeFormatter = (value) => {
  return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
};

const durationHumanFormatter = (value = 0, unit: DurationUnitType = "seconds") => {
  const duration = durationFormatter(value, unit);
  const unite = duration.split(" ");

  if (parseInt(unite[0]) <= 1) {
    unite[1] = unite[1].slice(0, -1);
  }
  return unite.join(" ");
};

const durationFormatter = (value = 0, unit: DurationUnitType = "seconds") => {
  const duration = dayjs.duration(value, unit);
  if (Number.isInteger(duration.as("days"))) {
    return duration.as("days") + " jours";
  }

  if (Number.isInteger(duration.as("hours"))) {
    return duration.as("hours") + " heures";
  }

  if (Number.isInteger(duration.as("minutes"))) {
    return duration.as("minutes") + " minutes";
  }

  return duration.as("seconds") + " secondes";
};


// N jours ouvrés après la date
const businessDaysAdd = function (number, date, joursFeries: string[] = []) {
  const numericDirection = number < 0 ? -1 : 1;
  let currentDay = dayjs(date);
  let daysRemaining = Math.abs(number);

  while (daysRemaining > 0) {
    currentDay = currentDay.add(numericDirection, "d");
    if (isWeekday(currentDay) && !joursFeries.includes(currentDay.format("YYYY-MM-DD"))) daysRemaining -= 1;
  }
  return currentDay.hour(0).minute(0).second(0).millisecond(0);
};

// Est un jour de la semaine
const isWeekday = (date) => {
  const day = dayjs(date).get("day");
  return day !== 0 && day !== 6;
};

function simulateMouseClick(element) {
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1
      })
    )
  );
}


const colorStateTicket = (statutTicketId) => {
  let color;
  switch (parseInt(statutTicketId)) {
    case TICKET_STATUT_ID_EN_ATTENTE_CLIENT:
      color = "orange"
      break;
    case TICKET_STATUT_ID_EN_COURS:
    case TICKET_STATUT_ID_EN_ATTENTE_FOURNISSEUR:
      color = "blue"
      break;
    case TICKET_STATUT_ID_CLOTURE_TECHNIQUE:
      color = "emerald"
      break;
    case TICKET_STATUT_ID_NOUVEAU:
      color = "red"
      break;
    case TICKET_STATUT_ID_OBSERVATION:
      color = "blue"
      break;
    default:
      color = "green"
      break;
  }
  return color;
}

const arrayEquals = (a, b) => {
  return isArray(a) && isArray(b) && a.length === b.length &&
    a.every((v, i) => v === b[i]);
}

const multipleExistInArray = (arr, values) => {
  return values.every((value) => {
    return arr.includes(value);
  });
}

const luhnCheck = (num) => {
  const numArr = `${num}`.split('').reverse();
  let total = 0;
  for (let i = 0; i < numArr.length; i++) {
    let number = parseInt(numArr[i]);
    if (i % 2 == 1) {
      number *= 2;
      if (number > 9) {
        number -= 9;
      }
    }
    total += number;
  }
  return total % 10 === 0;
};

const convertDataFr = (value, unit) => {
  const convertData = convert(value ? value : 0, unit).to('best');
  return `${parseFloat(convertData.quantity.toString()).toLocaleString()} ${convertData.quantity === 0 ? 'Ko' : convertData.unit.replace('iB', 'o')}`
}

const copyTextToClipboard = (text, successCallback, errorCallback) => {
  navigator.clipboard.writeText(text).then(successCallback, errorCallback);
};

export {
  toastInfo,
  toastSuccess,
  toastError,
  getLocalStorage,
  removeLocalStorage,
  getItemByCode,
  getPosition,
  setLocalStorage,
  datetimeFormatter,
  durationHumanFormatter,
  durationFormatter,
  businessDaysAdd,
  isWeekday,
  simulateMouseClick,
  colorStateTicket,
  arrayEquals,
  multipleExistInArray,
  convertDataFr,
  luhnCheck,
  copyTextToClipboard
}