import React, { useEffect, useState } from 'react'
import Constants from '../../../../../../commons/Constants'
import Modal from '../../../../../../Components/Modal/Modal'
import ModalFooterAction from '../../../../../../Components/Modal/ModalFooterAction'
import { MultiSelectOptionType } from '../../../../../../Components/MultiSelect/Types/MultiSelectOptionType'
import { useP } from '../../../../../../services/i18n'
import ModalAddRoleScopeBody from './ModalAddRoleScopeBody'
import { ADD_CONTRAT_TO_ROLECONTACT, ADD_SITE_TO_ROLECONTACT, GET_CONTRATS, GET_SITES } from '../../../../queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toastError, toastSuccess } from '../../../../../../Components/Utils/Utils'
import Spinner from '../../../../../../Components/Spinner'
import { RoleType } from '../../RoleType'

const {
  SAISIE_CONTRAT,
  SAISIE_SITE
} = Constants;

type tabDatasLignes = {
  code: string,
  libelle: string,
  selected: boolean
}

const tabDatas: Array<tabDatasLignes> = [];

type ModalAddRoleScopeType = {
  isOpen: boolean,
  onClose: () => void,
  scope?: string | null
  role: RoleType,
}
export default function ModalAddRoleScope({ isOpen = false, onClose, scope, role }: ModalAddRoleScopeType) {
  const p = useP();

  const [selectedScopes, setSelectedScopes] = useState<Array<MultiSelectOptionType>>()

  const scopeTitleModal = (scope == SAISIE_SITE) ? p.t('contacts.sites.selection') : p.t('contacts.contrats.selection')

  const saveFilter = () => {
    let selected = false;
    selectedScopes?.forEach(element => {
      selected = true;
      if (scope == SAISIE_SITE) {
        addSite(element.code);
      } else {
        addContrat(element.code);
      }
    })
    if (selected) {
      toastSuccess(p.t('actions.ajoutOK'));
      onClose();
    }
    else
      toastError(p.t('actions.erreurSelection'));
  }

  /*
   * Ajout d'un contrat
   */
  const addContrat = (id) => {
    addContratMutation({
      variables: {
        idRoleContact: role.id,
        numcli: id
      }
    })
      .then(({ data }) => {
        /*
        if (data.addContratToRoleContact.success)
          toastSuccess("Ajout du contrat effectué");
        else
          toastError(data.addContratToRoleContact.message);
        */
      })
      .catch(() => {
        toastError(p.t('error.defaultMessage'));
      });
  }
  const [addContratMutation, { loading: loadingAddContrat }] = useMutation(
    ADD_CONTRAT_TO_ROLECONTACT,
    {
      refetchQueries: [
        'GetContacts',
        'roleContacts'
      ],
    }
  );

  /*
   * Ajout d'un site
   */
  const addSite = (id) => {
    addSiteMutation({
      variables: {
        idRoleContact: role.id,
        siteId: id
      }
    })
      .then(({ data }) => {
        /*
        if (data.addSiteToRoleContact.success)
          toastSuccess("Ajout du site effectué");
        else
          toastError(data.addSiteToRoleContact.message);
        */
      })
      .catch(() => {
        toastError(p.t('error.defaultMessage'));
      });
  }
  const [addSiteMutation, { loading: loadingAddSite }] = useMutation(
    ADD_SITE_TO_ROLECONTACT,
    {
      refetchQueries: [
        'GetContacts',
        'roleContacts'
      ],
    }
  );

  /*
   * Liste des contrats
   */
  const refreshContrats = () => {
    getContrats({
      variables: {
        sorters: [
          {
            column: "nom",
            order: "ASC"
          },
        ],
        state: Math.random(), // Sert à passer dans le onCompleted systématiquement, sinon seul le premier appel fonctionne
      }
    });
  };
  const [getContrats, { loading: loadingContrats, data: dataContrats }] = useLazyQuery(GET_CONTRATS, {
    fetchPolicy: "network-only",
    onCompleted: (dataContrats) => {
      filterContrats(dataContrats);
    }
  });
  const filterContrats = (datas) => {

    // Contrats sélectionnés
    const contratsSelected = role.contrat.map(tri)
    function tri(contrat) {
      return contrat.numcli;
    }
    const contratsToDelete = new Set(contratsSelected);

    // Transformer les données au format (pour l'affichage)
    // { code: 123, libelle: "XXX", selected: true/false },
    tabDatas.length = 0;
    datas?.clients.items.forEach(element => {
      // N'afficher que les contrats non sélectionnés
      if (!contratsToDelete.has(element.numcli)) {
        let contrat = element.numcli + ' - ' + element.nom + ' - ' + element.adresse + ' ' + element.cp + ' ' + element.ville
        contrat = contrat.replaceAll('null', ' ')
        tabDatas.push({
          code: element.numcli,
          libelle: contrat,
          selected: false
        })
      }
    });
    setSelectedScopes(tabDatas);
  }

  /*
   * Liste des sites
   */
  const refreshSites = () => {
    getSites({
      variables: {
        sorters: [
          {
            column: "nom",
            order: "ASC"
          },
        ],
        state: Math.random(), // Sert à passer dans le onCompleted systématiquement, sinon seul le premier appel fonctionne
      }
    });
  };
  const [getSites, { loading: loadingSites, data: dataSites }] = useLazyQuery(GET_SITES, {
    fetchPolicy: "network-only",
    onCompleted: (dataSites) => {
      filterSites(dataSites);
    }
  });
  const filterSites = (datas) => {
    // Sites sélectionnés
    const sitesSelected = role.site.map(tri)
    function tri(site) {
      return site.id;
    }
    const sitesToDelete = new Set(sitesSelected);

    // Transformer les données au format (pour l'affichage)
    // { code: 123, libelle: "XXX", selected: true/false },
    tabDatas.length = 0;
    datas?.sites.forEach(element => {
      // N'afficher que les sites non sélectionnés
      if (!sitesToDelete.has(element.id)) {
        let site = element.nom + ' - ' + element.numeroVoie + ' ' + element.libelleVoie + ' ' + element.codePostal + ' ' + element.commune
        site = site.replaceAll('null', ' ')
        tabDatas.push({
          code: element.id,
          libelle: site,
          selected: false
        })
      }
    });
    setSelectedScopes(tabDatas);
  }

  useEffect(() => {
    if (isOpen) {
      if (scope == SAISIE_SITE) {
        refreshSites();
      } else {
        refreshContrats();
      }
    }
  }, [isOpen]);


  return <>
    <Modal
      onClose={() => onClose()}
      isOpen={isOpen}
      content={{
        title: `${scopeTitleModal}`,
        body: <>
          {
            (loadingContrats || loadingSites) ? <div className='flex items-center justify-center'><Spinner /></div>
              : <ModalAddRoleScopeBody scope={scope} selectedValues={(values) => setSelectedScopes(values)} datas={tabDatas} />
          }
        </>,
        footer: <>
          {
            (loadingContrats || loadingSites) ? <></>
              : <ModalFooterAction label={p.t('actions.enregistrer')} color='primary' onClickAction={saveFilter} />
          }
        </>,
      }}
      className="min-h-[445px] w-full lg:w-5/12"
    />
  </>


}