import React, { useEffect, useRef } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { MultiSelectOptionType } from './Types/MultiSelectOptionType'
import MultiSelectSearchBoxList from './MultiSelectSearchBoxList'
import clsx from 'clsx'



type InputSearchBoxType = {
  onFilterList: (e: string) => void,
  placeholder?: string
}
const InputSearchBox = ({ onFilterList, placeholder }: InputSearchBoxType) => {

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current)
      inputRef.current.focus()
  }, [])


  return <>
    <input
      ref={inputRef}
      type="search"
      className="flex-1 border-0 p-0 mx-1 mb-1 focus:outline-none focus:ring-0 text-sm"
      onChange={(e) => onFilterList(e.target.value)}
      placeholder={placeholder}
    />
  </>
}


type MultiSelectSearchBoxType = {
  datas: Array<MultiSelectOptionType>,
  onFilterList: (e: string) => void,
  showBadges: boolean | undefined,
  placeholder?: string,
  alwaysVisible?: boolean,
  toggleDropdown?: () => void,
  searchBoxClassName?: string
}
export default function MultiSelectSearchBox({
  datas,
  showBadges,
  onFilterList,
  placeholder,
  alwaysVisible,
  toggleDropdown,
  searchBoxClassName
}: MultiSelectSearchBoxType) {

  const selectedResults = datas
    .map(group => group)
    .filter((el) => el.selected === true)

  return <>
    <div className={clsx(`bg-white`, searchBoxClassName)}>
      <div className={clsx(`focus-within:ring-0 focus-within:ring-primary-500 focus-within:border-primary-500 w-full pt-2 pb-1 px-1 sm:text-sm border border-gray-300 rounded-md flex items-center`)}>
        {
          showBadges
            ?
            <MultiSelectSearchBoxList datas={selectedResults}>
              <InputSearchBox onFilterList={onFilterList} placeholder={placeholder} />
            </MultiSelectSearchBoxList>
            :
            <InputSearchBox onFilterList={onFilterList} placeholder={placeholder} />
        }


        {!alwaysVisible &&
          <div onClick={toggleDropdown} className='cursor-pointer'>
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400 curs" />
          </div>}

      </div>
    </div>
  </>
}