import React from "react"
import DocumentationThumbnail, { ThumbnailType } from "./DocumentationThumbnail"

type DocumentationViewThumbnailsType = {
  datas: Array<ThumbnailType>,
  onClickThumbnail: (thumbnail: ThumbnailType) => void,
  onCopyLink: (thumbnail: ThumbnailType) => void,
}

export default function DocumentationViewThumbnails({ datas, onClickThumbnail, onCopyLink }: DocumentationViewThumbnailsType) {

  return <>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:overflow-y-auto p-2">
      {datas.map((d, idx) =>
        <DocumentationThumbnail
          key={idx}
          thumbnail={d}
          onClick={onClickThumbnail}
          onCopyLink={onCopyLink}
        />
      )}
    </div>

  </>
}