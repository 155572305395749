import React from "react"
import { registerLocale } from "react-datepicker";
import "../../../Components/Datepicker/react-datepicker.css"
import fr from "date-fns/locale/fr";
import CardChart from "./CardChart";
import CardChartDescription from "./CardChartDescription";
registerLocale("fr", fr);

export type CyberDetailType = {
  description: string | null,
  chartType: string | null,
  chartCode: string | null,
  compteId: string | null,
  index: string,
  children: Array<CyberDetailType>,
  libelle: string
}

type CyberViewDetailType = {
  datasView: CyberDetailType
}

export default function CyberViewDetail({ datasView }: CyberViewDetailType) {
  console.log(datasView.description)
  return <>
    {datasView.description && <CardChartDescription descriptionHtml={datasView.description} />}

    {datasView.chartType &&
      <CardChart
        chartType={datasView.chartType}
        chartCode={datasView.chartCode || ""}
        compteId={datasView.compteId || ""}
        libelle={datasView.libelle}
        historique={true}
      />}

    {datasView.children?.length > 0 &&
      <div className="lg:grid lg:grid-cols-2 lg:gap-4 space-y-4 lg:space-y-0 pr-2">
        {
          datasView.children.map((chart, index) => {
            return <CardChart
              chartType={chart.chartType || ""}
              chartCode={chart.chartCode || ""}
              compteId={chart.compteId || ""}
              libelle={chart.libelle} key={index} minHeight={300}
            />
          })
        }
      </div>}
  </>
}