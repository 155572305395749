import React, { Fragment } from "react";

type MesuresLoginInfosType = {
  data: any
}

const MesuresLoginInfos = ({ data }: MesuresLoginInfosType) => {
  const calculateMinMaxAvg = (data) => {
    let minVal = 9999;
    let maxVal = 0;
    let count = 0;
    let total = 0;

    data.forEach((d) => {
      minVal = Math.min(minVal, Math.abs(d.value));
      maxVal = Math.max(maxVal, Math.abs(d.value));
      count += 1;
      total += Math.abs(d.value);
    });

    return {
      min: `${minVal.toFixed(2)} Mb/s`,
      max: `${maxVal.toFixed(2)} Mb/s`,
      avg: `${count > 0 ? (total / count).toFixed(2) : 0} Mb/s`,
    };
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-0 text-sm font-medium bg-slate-100 py-2 px-3 rounded-md mt-2 ">
        <div className="pr-2"></div>
        <div className="text-right text-slate-600 border-x border-slate-300 pr-2 py-1">
          Min
        </div>
        <div className="text-right text-slate-600 border-r border-slate-300 pr-2 py-1">
          Max
        </div>
        <div className="text-right text-slate-600">Moy</div>

        {data.map((d, key) => {
          const value = calculateMinMaxAvg(d.data);
          return (
            <Fragment key={key}>
              <div className="text-right flex items-center border-y border-slate-300">
                <div className="ml-2 w-full pr-2">{d.label}</div>
              </div>
              <div className="text-right border-x border-y border-slate-300 pr-2 py-1">
                {value.min}
              </div>
              <div className="text-right border-r border-y border-slate-300 pr-2 py-1">
                {value.max}
              </div>
              <div className="text-right py-1 border-y border-slate-300">
                {value.avg}
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default MesuresLoginInfos;
