import React, { useState } from 'react'
import Textarea from '../../../Components/Form/Textarea'
import { useP } from '../../../services/i18n';

type ModaleRatingTicketBodyType = {
  onChangeCommentaire: (value: string) => void,
}

export default function ModaleRatingTicketBody({ onChangeCommentaire }: ModaleRatingTicketBodyType) {
  const p = useP();
  const [commentaire, setCommentaire] = useState("")

  return <>
    <div className="mb-2 flex flex-col justify-center items-center">
      <div className="mt-1 w-full">
        <Textarea
          placeholder={p.t('support.detail.modaleReouverture.placeholderCommentaire')}
          name='commentaire'
          value={commentaire}
          onChange={e => {
            setCommentaire(e.target.value)
            onChangeCommentaire(e.target.value)
          }}
          className="h-[100px]"
        />
      </div>
    </div>
  </>
}