import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useP } from '../../services/i18n';
import { GET_LIEN } from './queries';
import ShellLayout from '../ShellLayout';

import LienContainer from './LienContainer';
import TabNavigationLogins from './TabsLogins/TabNavigationLogins';
import TabPanelLogins from './TabsLogins/TabPanelLogins';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { TabsNavigationType } from '../../Components/Tabs/Tabs';
import { authContext } from '../../AuthContext';

type tabsLoginType = {
  navs: Array<TabsNavigationType>,
  panels: Array<JSX.Element>,
}

function Lien() {

  const {
    selectedCompte,
  } = useContext(authContext)

  const p = useP();

  const { prestationId } = useParams();

  // Table Lien
  const { loading, data } = useQuery(GET_LIEN, {
    variables: {
      prestationId: prestationId,
    },
  });

  const [tabsLogin, setTabsLogin] = useState<tabsLoginType>({ navs: [], panels: [] });
  const [lien, setLien] = useState();

  useEffect(() => {
    if (data?.lien) {

      const tabs: tabsLoginType = { navs: [], panels: [] }

      // On récupère les infos des logins pour les mettre en place dans les onglets
      data.lien.logins.filter((l) => l.isProduction).forEach((l, index) => {
        tabs.navs.push(
          {
            libelle: l.login,
            jsx: <TabNavigationLogins key={index} login={l} loginCount={data.lien.logins.length} />
          }
        )
        tabs.panels.push(<TabPanelLogins key={index} login={l} hasOptionSupervision={data.lien.hasOptionSupervision} />)
      })

      // On tri les travaux programmes et on rajouter la propriété colorsClasses
      const lien = { ...data.lien }
      lien.tps = [...lien.travauxProgrammes]
      lien.tps.sort((tp1, tp2) => dayjs(tp1.dateDebut) > dayjs(tp2.dateDebut) ? -1 : 1)

      // On rajoute la propriete colorClasses sur le statut du tp pour afficher le badge
      lien.tps = lien.tps.map((tp) => (
        {
          ...tp,
          statutTravailProgramme:
          {
            ...tp.statutTravailProgramme,
            colorClasses: `bg-${tp.statutTravailProgramme.couleur}-200 text-${tp.statutTravailProgramme.couleur}-800`
          }
        }
      )
      )

      setLien(lien)
      setTabsLogin(tabs)
    }
  }, [data]);

  const breadcrumbDatas = [
    {
      libelle: p.t('navigation.prestations.label'),
      href: `/${p.t("router.compte.route")}/${selectedCompte}/${p.t("router.compte.prestations.route")}/${p.t('router.compte.prestations.lien.route')}`,
      current: false
    },
    {
      libelle: p.t('supervisionLien.mainTitle'),
      href: `/${p.t("router.compte.route")}/${selectedCompte}/${p.t("router.compte.prestations.route")}/${p.t('router.compte.prestations.lien.route')}`,
      current: false
    },
    {
      libelle: `${data?.lien ? data?.lien?.typeLien?.libelle : ``}`,
      href: "#",
      current: true
    }
  ]

  return (
    <ShellLayout>
      <LienContainer
        loading={loading}
        lien={lien}
        breadcrumb={breadcrumbDatas}
        navigationLogins={tabsLogin.navs}
        panelsLogins={tabsLogin.panels}
      />
    </ShellLayout>
  )
}

export default Lien