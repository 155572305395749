import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Combobox } from '@headlessui/react'
import { Float } from '@headlessui-float/react'
import Tooltip from '../../Tooltip/Tooltip'
import Spinner from '../../Spinner'

export type OptionType = {
  code: string,
  libelle: string
}
type ComboboxClassesType = {
  root?: string,
  label?: string,
  input?: string,
  button?: string,
  options?: string,
  option?: string
}
type ListActionType = {
  action?: () => void | null | undefined,
  libelle: string,
  icon?: JSX.Element | null | undefined
}
type InputComboboxType = {
  name: string,
  errors?: any,
  options: Array<OptionType> | undefined,
  label: string,
  classes?: ComboboxClassesType,
  value?: OptionType,
  onChange(value: OptionType): any,
  onFocus?(): void,
  autoComplete: 'off' | 'on',
  multiline?: boolean,
  inlineLabel?: boolean,
  maxResults?: number | undefined,
  listAction?: ListActionType,
  iconTooltipContent?: string | null | undefined
  loading?: boolean
}

export default function InputCombobox({
  name,
  errors = null,
  options = [],
  label,
  classes,
  value,
  onFocus,
  onChange,
  autoComplete,
  multiline = true,
  inlineLabel = false,
  maxResults,
  listAction,
  iconTooltipContent = null,
  loading = false
}: InputComboboxType) {

  const [query, setQuery] = useState('')
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    if (value?.code != '' && value?.libelle != '') { setSelectedValue(value) }
  }, [value])


  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.libelle.toLowerCase().includes(query.toLowerCase())
      })

  const selectValue = (val) => {
    setSelectedValue(val);
    onChange(val)
  }

  const defaultClassesInput = "rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"

  return (<>
    {label && <div className={clsx(`truncate block text-sm font-medium text-gray-700 mb-1`, classes?.label)}>{label}</div>}
    <Combobox
      as="div"
      onFocus={onFocus}
      value={selectedValue}
      onChange={(val) => selectValue(val)}
      className={clsx(inlineLabel ? `flex items-center space-x-2` : ``)}
      nullable={true}
    >
      {
        loading ?
          <><Spinner /></> :
          <Float
            as="div"
            className="relative"
            placement="bottom"
            offset={5}
            flip={10}
            floatingAs={Fragment}
          >
            <div className=" w-full">
              <Combobox.Button className={clsx(`relative flex items-center w-full`)} as='div'>

                <Combobox.Input
                  className={clsx(`truncate w-full `, classes?.input ? classes?.input : defaultClassesInput)}
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={(option: OptionType) => option?.libelle}
                  name={name}
                  autoComplete={autoComplete}
                />
                {iconTooltipContent
                  ? <Tooltip
                    button={
                      <div className={clsx(`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none cursor-pointer`, classes?.button)}>
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </div>
                    }
                    content={<div className='text-xs'>{iconTooltipContent}</ div>}
                    placement='top'
                    offset={[-20, 15]}
                  />
                  : <>
                    <div className={clsx(`absolute inset-y-0 right-0 flex items-center rounded-md px-2 focus:outline-none cursor-pointer`, classes?.button)}>
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </div>
                  </>
                }

              </Combobox.Button>
            </div>

            <Combobox.Options
              className={clsx(`
                absolute z-30 mt-1 w-full max-h-56  overflow-auto rounded-md bg-white py-0 text-base shadow-lg`,
                ` ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`,
                classes?.options)}>
              <>
                {filteredOptions.slice(maxResults && 0, maxResults).map((option) => (
                  <Combobox.Option
                    key={option.code}
                    value={option}
                    className={({ active }) =>
                      clsx(
                        `relative z-10 cursor-default select-none py-2 pl-10 pr-4`,
                        active ? 'bg-primary-100 text-primary-700' : 'text-gray-900',
                        multiline ? `border-b border-gray-300` : ``,
                        classes?.option
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={clsx(`block`,
                            !multiline && `truncate`,
                            selected || selectedValue?.code === option.code ? `font-semibold text-primary-700` : `font-normal`,
                          )}
                        >
                          {option.libelle}
                        </span>
                        {(selected || selectedValue?.code === option.code) ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary-600'
                              }`}
                          >
                            <CheckIcon className="h-5 w-5 text-primary-600" aria-hidden="true" />
                          </span>

                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
              {listAction?.libelle &&
                <div
                  onClick={listAction.action}
                  className='sticky bottom-0 left-0 z-20 select-none py-2.5 pl-10 pr-4 bg-white border-t border-gray-300  cursor-pointer hover:bg-secondary-100'
                >
                  <div className='flex items-center space-x-2'>
                    {listAction.icon && <div>{listAction.icon}</div>}
                    <div className='text-secondary-600 font-medium'>{listAction.libelle}</div>
                  </div>
                </div>
              }
            </Combobox.Options>
          </Float>
      }

    </Combobox >
    {
      (errors && errors[name]) && <div className='text-red-600 text-xs font-medium mt-0.5'>{errors[name].message}</div>
    }
  </>)
}