import React from 'react'
import { ListDataType } from './ListDataType';
import ListItem from './ListItem';



type ListType = {
  datas: Array<ListDataType>,
  canDeleteItem: boolean,
  removeItem?: (item: ListDataType) => void | undefined,
  onClickItem?: (item: ListDataType) => void | undefined,
  libelleClassName?: string
}

function List({ datas, canDeleteItem, removeItem, onClickItem, libelleClassName }: ListType) {

  return (
    <>
      <ul role="list" className="pb-2 divide-y divide-gray-200">
        {
          datas.map((item, index) => {
            return <ListItem libelleClassName={libelleClassName} item={item} key={index} canDeleteItem={canDeleteItem} removeItem={removeItem} onClickItem={onClickItem} />
          })
        }
      </ul>
    </>
  )

}

export default List;