import React from 'react';
import { LoggedInUserType } from '../types/LoggedInUserType';
import { getRedirUrl } from '../utils/utils';

type PrivateRouteProps = {
  loggedInUser: LoggedInUserType,
  children: any
}

function PrivateRoute({ loggedInUser, children }: PrivateRouteProps) {

  if (!loggedInUser?.user?.id) {
    const redirUrl = getRedirUrl({ encode: true });
    window.location.href = `/login?expired=true&redir=${redirUrl}`;
  }

  return children;

}

export default PrivateRoute