import { themes } from './config';

export const hexToRgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r},${g},${b}`;
}

// Génération des variables CSS à partir des variables de thème
export const mapTheme = (variables) => {
  return Object.keys(variables).reduce((themeObject, key) => {
    const colorValues = variables[key];
    Object.keys(colorValues).forEach((colorKey) => {
      const property = `--color-${key}-${colorKey}`;
      themeObject[property] = colorValues[colorKey] || '';
    });
    return themeObject;
  }, {});
};

// Application du thème à l'élément root
export const applyTheme = (theme) => {
  const themeObject = mapTheme(themes[theme]);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }
    root.style.setProperty(property, hexToRgb(themeObject[property]));
  });
};