import { Menu } from '@headlessui/react'
import React from 'react'
import DropdownItem, { ItemDropdownItemType } from './DropdownItem'

type DropdownItemsType = {
  items: Array<ItemDropdownItemType>,
}

function DropdownItems({ items = [] }: DropdownItemsType) {
  return (

    <Menu.Items className="origin-top-right absolute right-0 top-6 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      {
        items.map((item, index) => (
          <DropdownItem item={item} key={index} />
        ))
      }
    </Menu.Items>

  )

}

export default DropdownItems