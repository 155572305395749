import React from 'react'
import clsx from 'clsx';
import ButtonsGroupHorizontal from './ButtonsGroupHorizontal';
import ButtonsGroupVertical from './ButtonsGroupVertical';
import { ButtonsGroupItemButtonType } from './ButtonsGroupItem';

type ButtonsGroupType = {
  buttons: Array<ButtonsGroupItemButtonType>,
  onSelectedButton: (value: any) => void,
  selectedButtons?: any,
  multi?: boolean,
  vertical?: boolean,
  prepend?: JSX.Element,
  ref?: any,
  className?: string,
  forceHorizontalOnMobile?: boolean,
  selectAll?: boolean
}

export default function ButtonsGroup({
  buttons,
  onSelectedButton,
  multi = false,
  vertical = false,
  selectedButtons = [],
  className = 'items-center rounded-md w-full',
  forceHorizontalOnMobile = false,
  prepend,
  selectAll = false }: ButtonsGroupType) {

  const selected: string[] = Array.isArray(selectedButtons) ? selectedButtons : [selectedButtons]

  const onClickRadio = (value: string) => {

    const selectedValues = Object.values(selected);

    if (selected.includes(value)) {
      const index = selectedValues.indexOf(value);
      if (index > -1) {
        selectedValues.splice(index, 1)
      }
    } else {
      if (!multi) {
        selectedValues.splice(0, selectedValues.length);
      }
      selectedValues.push(value)
    }

    onSelectedButton(multi ? selectedValues : selectedValues.length > 0 ? selectedValues[0] : "")
  }

  return (
    <>

      <span className={clsx(`relative hidden lg:inline-flex`, className)}>
        {
          !vertical
            ? <ButtonsGroupHorizontal
              buttons={buttons}
              onClickRadio={onClickRadio}
              selected={selected}
              multi={multi}
              prepend={prepend && prepend}
              selectAll={selectAll}
              forceHorizontalOnMobile={forceHorizontalOnMobile}
            />
            : <ButtonsGroupVertical
              buttons={buttons}
              onClickRadio={onClickRadio}
              selected={selected}
              multi={multi}
              prepend={prepend}
              selectAll={selectAll}
              forceHorizontalOnMobile={forceHorizontalOnMobile}
            />
        }
      </span>


      {
        forceHorizontalOnMobile ?
          <div className={clsx(`lg:hidden flex flex-row`)}>
            <ButtonsGroupHorizontal
              buttons={buttons}
              onClickRadio={onClickRadio}
              selected={selected}
              multi={multi}
              prepend={prepend && prepend}
              selectAll={selectAll}
              forceHorizontalOnMobile={forceHorizontalOnMobile}
            /></div>
          :
          <div className={clsx(`lg:hidden flex flex-col`)}>
            <ButtonsGroupVertical
              buttons={buttons}
              onClickRadio={onClickRadio}
              selected={selected}
              multi={multi}
              prepend={prepend}
              selectAll={selectAll}
              forceHorizontalOnMobile={forceHorizontalOnMobile}
            />
          </div>
      }
    </>
  )

}