import React from 'react'
import { Disclosure } from '@headlessui/react'
import { UserType } from '../../../../types/UserType'
import { UserNavigationType } from '../../../../types/UserNavigationType'
import MobileUser from './MobileUser'
import { useP } from '../../../../services/i18n'


type MobileProfileProps = {
  user: UserType,
  userNavigation: Array<UserNavigationType>
}

function MobileProfile({ user, userNavigation }: MobileProfileProps) {

  const p = useP();

  return (
    <div className="pt-4 pb-3 border-t border-primary-700">
      <MobileUser user={user} />
      <div className="mt-3 px-2 space-y-1">
        {userNavigation.map((item) => (
          <Disclosure.Button
            key={p.t(item.name)}
            as="a"
            href={p.t(item.href)}
            onClick={() => item.onClick && item.onClick()}
            className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-primary-700"
          >
            {p.t(item.name)}
          </Disclosure.Button>
        ))}
      </div>
    </div>
  )
}

export default MobileProfile