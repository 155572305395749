import dayjs from 'dayjs'
import React from 'react'
import Badge from '../../Components/Badge'
import InfosTechniquesLien from './InfosTechniquesLien'

type SupervisionLienTitleType = {
  lien: any
}

export default function SupervisionLienTitle({
  lien
}: SupervisionLienTitleType) {

  return <>
    <div className="text-sm">
      <div className="text-xl font-bold text-primary-800">{lien.typeLien.libelle}</div>
      <div className="text-primary-800/80">{lien.numeroRaccordement}</div>
      <div className='flex items-center mt-2'>
        <Badge
          itemColor="green"
          bordered
          itemLibelle={lien.etat} />      
        <div className='text-primary-800/80 ml-2 text-xs'>Mise en service : {lien.dateMiseEnService ? dayjs(lien.dateMiseEnService).format("DD/MM/YYYY") : "-"}</div>
        { lien.optionGtr && <Badge itemColor="blue" className="ml-2" bordered itemLibelle={lien.optionGtr} /> }
      </div>
      <div className='mt-2'><InfosTechniquesLien lien={lien} /></div>
    </div>
  </>
}