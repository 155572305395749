import React, { useState } from "react";
import Badge from "../../../Components/Badge";
import { CalendarIcon, ClockIcon, Bars4Icon } from "@heroicons/react/24/outline";
import DescriptionList from "../../../Components/DescriptionList/DescriptionList";
import { useP } from "../../../services/i18n";
import Modal from "../../../Components/Modal/Modal";
import ModalFooterAction from "../../../Components/Modal/ModalFooterAction";
import { datetimeFormatter, durationHumanFormatter } from "../../../Components/Utils/Utils";

type typeTravailProgrammeType = {
  libelle: string
}

type statutTravailProgrammeType = {
  libelle: string,
  couleur: string,
}

type tpType = {
  nature: string,
  cause: string,
  impact: string,
  referenceFournisseur: string,
  statutTravailProgramme: statutTravailProgrammeType,
  typeTravailProgramme: typeTravailProgrammeType,
  dateDebut: string,
  dateFin: string,
  duree: number,
}

type StatTravailProgrammeType = {
  tp: tpType
}

function StatTravailProgramme({ tp }: StatTravailProgrammeType) {

  const p = useP();

  const informationsTP = [
    {
      label: `Nature`,
      value: tp.nature,
    },
    {
      label: `Cause`,
      value: tp.cause,
    },
    {
      label: `Impact`,
      value: tp.impact,
    },
    {
      label: `Référence fournisseur`,
      value: tp.referenceFournisseur,
    },
  ];
  const [openDetail, setOpenDetail] = useState(false)


  return (
    <>
      <div className="flex-1">
        <div className="text-lg font-semibold leading-5 text-primary-700 flex flex-col md:flex-row md:items-start md:justify-between">
          <div className="mr-3 flex flex-col md:flex-row md:items-start">
            <div>
              Travail programmé {tp.typeTravailProgramme.libelle} -{" "}
              {tp.referenceFournisseur}
            </div>
            <div className="flex items-center md:ml-3 mt-1 md:mt-0">
              <Badge
                itemLibelle={tp.statutTravailProgramme.libelle}
                itemColor={tp.statutTravailProgramme.couleur}
              />
            </div>
          </div>

          <div className="flex items-center">
            <div className="md:ml-4 mt-2 md:mt-0">
              <div className="flex items-center border border-gray-200 px-1 py-1 rounded-md cursor-pointer hover:bg-gray-100" onClick={() => setOpenDetail(true)}>
                <div className="text-xs md:mr-1">{p.t('actions.detail')}</div>
                <Bars4Icon className="h-4 w-4 text-gray-400" />
              </div>
              <Modal
                onClose={() => setOpenDetail(false)}
                isOpen={openDetail}
                content={{
                  title: p.t('actions.detail'),
                  body:
                    <>
                      <DescriptionList datas={informationsTP} />
                    </>,
                  footer: <ModalFooterAction onClickAction={() => setOpenDetail(false)} label={p.t('actions.fermer')} />,
                }}
                className="w-full lg:w-5/12"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2 md:mt-0.5">
          <div className="flex-1 flex flex-col md:flex-row md:items-center">
            <div className="flex items-center text-sm font-medium md:mr-4">
              <div className="mr-1.5">
                <CalendarIcon className="h-3 w-3" />
              </div>
              <div className="text-xs">
                Du {datetimeFormatter(tp.dateDebut)} au{" "}
                {datetimeFormatter(tp.dateFin)}
              </div>
            </div>
            <div className="flex items-center text-sm font-medium mr-4">
              <div className="mr-1.5">
                <ClockIcon className="h-3 w-3" />
              </div>
              <div className="text-xs">{durationHumanFormatter(tp.duree)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StatTravailProgramme;
