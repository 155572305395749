import React, { useState } from "react";
import DatepickerContainer from "./DatepickerContainer";
// import DatePicker, { registerLocale } from "react-datepicker";
type DateRangePickerType = {
  defaultStartDate: Date | null,
  defaultEndDate: Date | null,
  className?: string
  onChange?: (startDate: Date | null, endDate: Date | null) => void
}

export default function DateRangePicker({
  defaultStartDate,
  defaultEndDate,
  className,
  onChange
}: DateRangePickerType) {

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);


  return <>
    <div className="flex items-center">
      <DatepickerContainer
        stateDate={startDate}
        endDate={endDate}
        selectsStart
        onChange={(date: Date) => {
          setStartDate(date)
          onChange?.(date, endDate)
        }}
        locale="fr"
        dateFormat="dd/MM/yyyy HH:mm:ss"
        showTimeSelect
        className={className}
      />
      <div className="px-1 lg:px-2">-</div>
      <DatepickerContainer
        stateDate={endDate}
        selectsEnd
        onChange={(date: Date) => {
          setEndDate(date)
          onChange?.(startDate, date)
        }}
        minDate={startDate}
        endDate={endDate}
        locale="fr"
        dateFormat="dd/MM/yyyy HH:mm:ss"
        showTimeSelect
        className={className}
      />
    </div></>
}