import React, { useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import Button from '../../../Components/Button'
import ModaleRatingTicketBody from './ModaleRatingTicketBody'
import { useP } from '../../../services/i18n'
import { useMutation } from '@apollo/client'
import { toastError } from '../../../Components/Utils/Utils'
import { TICKET_CLOTURE, TICKET_CLOTURE_RATING, TICKET_RATING } from './queries'

type ModaleRatingTicketType = {
  ticketId: number,
  ticketRatingId?: number | null,
  isOpen: boolean,
  cloture?: boolean,
  onClose: () => void,
  onValider: () => void,
  noteDefaut?: number
  commentaireDefaut?: string
  isModifiable?: boolean
}

export default function ModaleRatingTicket({
  isOpen,
  ticketId,
  ticketRatingId = null,
  onClose,
  onValider,
  cloture = true,
  noteDefaut = 5,
  commentaireDefaut = "",
  isModifiable = true
}: ModaleRatingTicketType) {

  const [mutationTicketClotureRating] = useMutation(TICKET_CLOTURE_RATING)
  const [mutationTicketCloture] = useMutation(TICKET_CLOTURE)
  const [mutationTicketRating] = useMutation(TICKET_RATING)

  const [rating, setRating] = useState({ note: noteDefaut, commentaire: commentaireDefaut })

  const onValidateModale = async () => {

    let funcValidate = cloture ? mutationTicketCloture : null
    if (rating.note > 0) {
      funcValidate = cloture ? mutationTicketClotureRating : mutationTicketRating
    }

    if (!funcValidate){
      onClose();      
      return;
    }
    
    funcValidate({
      variables: {
        ticketRating: {
          id: ticketRatingId,
          rating: rating.note,
          commentaire: rating.commentaire,
          ticketId
        },
        ticketId
      },
      onCompleted: (data) => {
        if ((data?.saveTicketRating || rating.note == -1) && (data?.ticketClotureClient?.success || !cloture)) {
          onClose();
          onValider();
        } else {
          toastError(p.t('error.defaultMessage'));
        }
      }
    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
    })
  }
  

  const p = useP()
  return <>
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      content={{
        title: p.t('support.detail.modaleRating.title'),
        body: <ModaleRatingTicketBody
          cloture={cloture}
          noteDefaut={noteDefaut}
          commentaireDefaut={commentaireDefaut}
          isModifiable={isModifiable}
          onChange={(note, commentaire) => setRating({ note, commentaire })}
          error={rating.note <= 0 && rating.commentaire ? p.t('support.detail.modaleRating.errorRating') : ""}
        />,
        footer: <div className='flex items-center justify-end'>
          <Button onClick={onValidateModale} disabled={!isModifiable || !!(rating.note <= 0 && rating.commentaire)}>
            {cloture ?
              p.t('support.detail.modaleRating.action') :
              p.t('support.detail.modaleRating.valider')}
          </Button>
        </div>,
      }}
      className="w-full lg:w-1/2"
    />
  </>
}