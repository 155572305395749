import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client";
import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import List from "../../../Components/List/List";
import Spinner from "../../../Components/Spinner";
import { useP } from "../../../services/i18n";
import { GET_LOGINS } from "./queries";
import WidgetLayout from "../WidgetLayout/WidgetLayout";
import WidgetSize from "../WidgetSize";
import WidgetElementType from "../WidgetElementType";

export default function WidgetLiensDecoTiny(props: WidgetElementType) {

  const p = useP();

  const { loading: loadingLogin, data: dataLogin, error: errorLogin } = useQuery(GET_LOGINS, {
    variables: {
      filters: [
        {
          column: "isConnectedBas",
          value: "0"
        },
        {
          column: "isProduction",
          value: "1"
        },
        {
          column: "isSupervisionActive",
          value: "1"
        },
      ],
      sorters: [
        {
          column: "login"
        }
      ]
    },
  });

  const [datasLoginsDeco, setDatasLoginsDeco] = useState([])

  useEffect(() => {
    let datas = []

    if (dataLogin?.logins?.items && !errorLogin) {

      datas = dataLogin.logins.items.map((cl) => ({
        id: cl.lien.prestation.id,
        libelle: <>
          <div className="w-full flex">
            <Link to={`lien/${cl.lien.prestation.id}`} className="flex flex-1 items-center justify-between w-full pr-2" >
              <div className="flex items-start flex-col">
                <div className="text-secondary-500">{cl.login}</div>
                <div className="text-xs text-gray-500">{`${p.t('dashboardCompte.deconnecteDepuis')} ${dayjs(cl.dateLastDisconnected).format("DD/MM/YYYY HH:mm")}`}</div>
              </div>
              <div className=""><ChevronRightIcon className="h-4 w-4" /></div>

            </Link>
          </div>
        </>

      }))
    }

    setDatasLoginsDeco(datas)
  }, [dataLogin, errorLogin])


  return (
    <WidgetLayout
      scrollable={true}
      libelle={p.t('dashboardCompte.loginsDeco')}
      Icon={datasLoginsDeco.length > 0 ? ArrowDownIcon : ArrowUpIcon}
      number={loadingLogin ? "" : datasLoginsDeco.length}
      color={loadingLogin ? "gray" : datasLoginsDeco.length > 0 ? "red" : "emerald"}
      size={WidgetSize.TINY}
      addWidget={props.addWidget}
      moveUpWidget={props.moveUpWidget}
      moveDownWidget={props.moveDownWidget}
      removeWidget={props.removeWidget}
    >
      {
        loadingLogin
          ? <div className="flex items-center justify-center py-2 ">< Spinner color="primary" /></div >
          : <div className=""><List datas={datasLoginsDeco} canDeleteItem={false} /></div>
      }
    </WidgetLayout >
  )

}