import React from "react"
import H2 from "../../../HelpBoxComponents/H2"
import IMG from "../../../HelpBoxComponents/IMG"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";

export default function HelpContentFactures_Q1(){
  const p = useP();
  return <>
    <P>
      <>{parse(p.t('helpBox.helpFactures.a1.1'))}      
      <div className="mt-1 mb-3"><IMG src="/images/helpBox/facture_dl.png" sizes={{width:30}} /></div>
      {parse(p.t('helpBox.helpFactures.a1.2'))}
      <div className="mt-1 mb-3"><IMG src="/images/helpBox/factures_exports.png" sizes={{width:250}} /></div>
    </>
    </P>    
  </>
}