import React, { useContext, useEffect, useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import Button from '../../Components/Button'
import Card from '../../Components/Card'
import Table from '../../Components/Table/Table'
import { useP } from '../../services/i18n'
import { TableDetailRenderType } from '../../Components/Table/Types/TableDetailRenderType'
import TableContactsDetail from '../../Shell/Contacts/TableContactDetail/TableContactsDetail'
import { useQuery } from '@apollo/client'
import ButtonsGroup from '../../Components/ButtonsGroup/ButtonsGroup'
import Constants from '../../commons/Constants'
import { getLocalStorage, setLocalStorage } from '../../Components/Utils/Utils'
import CheckRigth, { useCheckRight } from '../../Components/CheckRight/CheckRight'
import { authContext } from '../../AuthContext'
import Badge from '../../Components/Badge'
import FilterTable from '../../Components/Table/FilterTable'
import { GET_CONTACTS } from './queries'
import { typeRoleType } from './TableContactDetail/Roles/RoleType'

type TableContactsType = {
  addContact: () => void
  openModaleContact: (id: string) => void
  addRole: (row: any) => void,
  typeRoles: typeRoleType[]
}

const idTableFiltre = 'ec-table-contacts-filtre'
const idTablePagination = 'ec-table-contacts-pagination'

export default function TableContacts({ addContact, openModaleContact, addRole, typeRoles }: TableContactsType) {
  const p = useP();
  const { checkRight } = useCheckRight();

  const {
    ACTIFS,
    INACTIFS,
    WITHOUT_ROLES,
    ROLE_ADMIN_ANNUAIRE
  } = Constants;

  // Table Options
  const tableOptions = {
    resizable: true,
    colSelect: false,
    search: {
      placeholder: p.t('contacts.rechercher'),
      attributs: ["nomprenom", "fonction", "telephoneFixe", "telephoneMobile", "mail"]
    }
  }

  // Table Datas
  const { loading, data } = useQuery(GET_CONTACTS, {
    variables: {
      sorters: [
        {
          column: "nom",
          order: "ASC"
        },
      ]
    },
  });

  const tableContactsDatas = data?.contacts.items.map((item) => ({
    ...item,
    nomprenom: `${item.nom} ${item.prenom}`,
    fonction: item.poste ? `${item.poste?.libelle}` : `-`,
    subrow: {
      size: item.roleContacts.length,
      idEntity: item.id
    },
    colActions: [
      { libelle: p.t('contacts.addRoles'), icon: <PlusCircleIcon />, onclick: addRole },
    ]
  }))

  const defaultFilterRole = [
    WITHOUT_ROLES,
    ...typeRoles.map((r) => r.code)
  ]

  const [tableDatas, setTableDatas] = useState([]);
  const [filterActif, setFilterActif] = useState(getLocalStorage(idTableFiltre) ? getLocalStorage(idTableFiltre).filterActif : [ACTIFS]);
  const [filterRole, setFilterRole] = useState(getLocalStorage(idTableFiltre) ? getLocalStorage(idTableFiltre).filterRole : defaultFilterRole);

  useEffect(() => {
    let datas = data ? tableContactsDatas : [];
    if (filterActif && datas) {
      datas = datas.filter((d) => (filterActif.includes(ACTIFS) && d.isActif) || filterActif.includes(INACTIFS) && !d.isActif)
    }

    if (filterRole && datas) {
      datas = datas.filter((d) => {
        const rolesContact = d.roleContacts.map((r) => {
          return filterRole.includes(r.typeRole.code)
        })
        return (
          filterRole.includes(WITHOUT_ROLES) && rolesContact.length == 0 ||
          rolesContact.find((r: boolean) => r === true)
        )
      })
    }
    setTableDatas(datas)

    setLocalStorage(idTableFiltre, {
      filterActif: filterActif,
      filterRole: filterRole,
    });
  }, [data, filterActif, filterRole]);

  // Filtre actifs et roles
  const filterButtonsActifs = [
    { libelle: p.t('contacts.actif'), value: ACTIFS },
    { libelle: p.t('contacts.inactif'), value: INACTIFS },
  ]

  const filtreSansRole = { libelle: p.t('contacts.sansRole'), value: WITHOUT_ROLES }

  const {
    selectedCompte
  } = useContext(authContext)

  const editRow = (row) => { openModaleContact(row) }

  // Définition des colonnes de la table Contacts : 
  const columns = [
    {
      libelle: p.t('contacts.columns.nomprenom'),
      code: 'nomprenom',
      colType: 'detail_row',
      sortable: true,
    },
    {
      libelle: p.t('contacts.columns.fonction'),
      code: 'fonction',
      colType: 'string',
      sortable: true,
      width: "140px",
    },
    {
      libelle: p.t('contacts.columns.compte'),
      code: 'compte.raisonSociale',
      colType: 'string',
      sortable: true,
      visible: Array.isArray(selectedCompte) ? true : false,
    },
    {
      libelle: p.t('contacts.columns.telephoneFixe'),
      code: 'telephoneFixe',
      colType: 'string',
      width: "140px",
      sortable: true,
    },
    {
      libelle: p.t('contacts.columns.telephoneMobile'),
      code: 'telephoneMobile',
      colType: 'string',
      width: "140px",
      sortable: true,
    },
    {
      libelle: p.t('contacts.columns.email'),
      code: 'mail',
      colType: 'string',
      sortable: true,
    },
    {
      libelle: p.t('contacts.columns.multicompte'),
      code: 'isMultiCompte',
      colType: 'boolean',
      width: '100px',
      sortable: true,
      resizable: false,
      customFormatter: (row: any) => (<>{row.isMultiCompte &&
        <Badge className='' itemLibelle={p.t('contacts.contact.multicompte')} />}</>)

    },
    {
      libelle: p.t('contacts.columns.actif'),
      code: 'isActif',
      colType: 'boolean',
      width: "54px",
      sortable: true,
      resizable: false
    },
    {
      libelle: "",
      code: 'edit',
      colType: 'edit',
      width: "54px",
      action: editRow,
      resizable: false,
      visible: checkRight(ROLE_ADMIN_ANNUAIRE),
    },
    {
      libelle: "",
      code: 'actions',
      colType: 'actions',
      width: "54px",
      visible: checkRight(ROLE_ADMIN_ANNUAIRE),
    }
  ];

  const renderDetail = (renderProps: TableDetailRenderType) => {
    return (<><TableContactsDetail renderProps={renderProps} /></>)
  }

  <div className="btn-group" role="group" aria-label="Basic example">
    <button type="button" className="btn btn-primary">Left</button>
    <button type="button" className="btn btn-primary">Middle</button>
    <button type="button" className="btn btn-primary">Right</button>
  </div>

  return (
    <>
      <Card className={{ root: "flex-1 h-full" }}>
        <Table
          idTable={idTablePagination}
          loading={loading}
          datas={tableDatas}
          columns={columns}
          options={tableOptions}
          detailComponentRender={renderDetail}
          customToolbarActions={
            <>
              <div className='mr-2 flex items-center  mb-4 mt-2 lg:mt-0 lg:mb-0'>
                <div className='mr-2 flex'>
                  <ButtonsGroup
                    selectedButtons={filterActif}
                    onSelectedButton={(value) => setFilterActif(value)}
                    buttons={filterButtonsActifs}
                    multi={true}
                  />
                </div>
                <div className='flex'>
                  <FilterTable
                    datas={[{
                      libFiltre: "Rôles",
                      selectAll: true,
                      filtres: [filtreSansRole,
                        ...typeRoles.map((r) => ({
                          libelle: r.libelle,
                          value: r.code
                        }))
                      ]
                    }]}
                    onSelectedButtonGroup={(value) => setFilterRole(value)}
                    activeFilters={
                      { Rôles: filterRole }
                    }
                  />
                </div>
              </div >
              <CheckRigth role={ROLE_ADMIN_ANNUAIRE}>
                <Button
                  className='w-full lg:w-auto '
                  onClick={() => addContact()}
                  color='primary'>
                  <PlusCircleIcon className='h-4 w-4 mr-2 text-white' />
                  {p.t('contacts.addContact')}
                </Button>
              </CheckRigth>
            </>
          }
        />
      </Card >
    </>
  )

} 