import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react'
import Accordion from '../../../../Components/Accordion/Accordion';
import AccordionItem from '../../../../Components/Accordion/AccordionItem';
import Spinner from '../../../../Components/Spinner';
import { GET_ROLES_CONTACT } from '../../queries';
import RolesListItems from './RolesListItems';
import { tableContext } from '../../../../Components/Table/TableContext';

type RolesListType = {
  rowIndex: number,
  idContact: number,
  open: boolean
}

function RolesList({ rowIndex, idContact, open }: RolesListType) {
  const { setChildrenLoading, setIdRow } = useContext(tableContext)
  const [getRoles, { loading, data }] = useLazyQuery(GET_ROLES_CONTACT, {
    fetchPolicy: "network-only"
  });
  const refreshRoles = () => {
    getRoles({
      variables: {
        idContact: idContact,
        sorters: [
          {
            column: "typeRole.libelle",
            order: "ASC"
          },
        ],
      }
    });
  };

  useEffect(() => {
    setChildrenLoading(loading)
    setIdRow(idContact)
  }, [loading])


  useEffect(() => {
    if (idContact && open) {
      refreshRoles();
    }
  }, [open, idContact]);

  return (
    <>
      <Accordion
        //allowMultipleExpanded
        //onChange={(uuid, expandedItems) => console.log(uuid, expandedItems)}
        allowItemCollapse
      >
        {
          !loading ?
            (data?.roleContacts.map((role, index) => {
              return <AccordionItem key={index}><RolesListItems role={role} key={index} index={index} idContact={idContact} /></AccordionItem>
            }))
            : (<><div className='h-full w-full flex items-center justify-center'><Spinner color='primary' /></div></>)
        }
      </Accordion>
    </>
  )

}

export default RolesList;