
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { PropsWithChildren, ReactElement, ReactNode, useContext } from 'react'
import { AccordionContext } from './Accordion';
import { AccordionItemContext } from './AccordionItem';

export type AccordionHeaderProps = {
  isExpanded: boolean;
};

export type AccordionItemHeaderButtonProps = {
  /** Children of the AccordionHeader */
  children: ReactNode,
  className?: string,
  disabled?: boolean
};

const AccordionItemHeaderButton = ({
  children,
  className,
  disabled,
  ...rest
}: AccordionItemHeaderButtonProps) => {
  const {
    allowMultipleExpanded,
    allowItemCollapse,
    expandedItems,
    setExpandedItems,
    onChange,
  }: any = useContext(AccordionContext);
  const { uuid, isExpanded }: any = useContext(AccordionItemContext);

  const toggleAccordionItem = () => {
    /**
     * create active uuid List
     * if allowMultipleExpanded is set populate with expandedItems (this enables multiples opened AccordionItems)
     * if allowItemCollapse (but not allowMultipleExpanded) is set only populate the current uuid
     * otherwise with an empty array (only one expanded AccordionItem allowed)
     */
    let uuidList;
    if (allowMultipleExpanded) {
      uuidList = new Set(expandedItems);
    } else if (!allowMultipleExpanded && allowItemCollapse) {
      const values = expandedItems.has(uuid) ? [uuid] : [];
      uuidList = new Set(values);
    } else {
      uuidList = new Set([]);
    }

    /**
     * check if allowItemCollapse is set and the uuid is already in the set
     * if the condition is met - remove the uuid from the set (this will collapse an opened AccordionItem)
     * otherwise add it (this will expand an AccordionItem)
     */
    if (allowItemCollapse && uuidList.has(uuid)) {
      uuidList.delete(uuid);
    } else {
      uuidList.add(uuid);
    }

    setExpandedItems(uuidList);

    /** fire callback function - pass last clicked uuid and array of expandedItems */
    onChange && onChange(uuid, Array.from(uuidList));
  };

  return (
    <div
      {...rest}
      data-uuid={uuid}
      onClick={!disabled ? toggleAccordionItem : undefined}
      data-expand={isExpanded}
      className={clsx(`flex items-center flex-1 `, className, !disabled && `cursor-pointer`)}
    >
      {!disabled ? <div >{isExpanded ? <ChevronUpIcon className='h-4 w-4' /> : <ChevronDownIcon className='h-4 w-4' />}</div> : ''}
      {
        React.Children.map(children, (child) => {
          const item = child as ReactElement<PropsWithChildren<AccordionHeaderProps>>;

          if (typeof item === "object") {
            return React.cloneElement(item);
          } else {
            return child;
          }
        })
      }
    </div>
  );
};

export default AccordionItemHeaderButton;