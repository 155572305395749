import React from "react"
import Card from "../../Components/Card"
import Treeview from "../../Components/Treeview/Treeview"
import { FolderFill } from "react-bootstrap-icons"
import { DocumentTypeType } from "../../types/DocumentTypeType"
import Spinner from "../../Components/Spinner"
import Badge from "../../Components/Badge"

type DocumentationTreeType = {
  dataTypeDocuments: Array<DocumentTypeType>,
  loadingTypeDocuments: boolean,
  onSelect: (items: Array<string>) => void,
  selectedItem?: string | null
}

export default function DocumentationTree({
  dataTypeDocuments,
  loadingTypeDocuments,
  onSelect,
  selectedItem,
}: DocumentationTreeType) {

  const treeItems = dataTypeDocuments.reduce(
    (previous, current) => {
      const tree = {
        ...previous,
      };

      const childrens = current.documentTypeEnfants.map((td) => td.id);
      tree[current.id] = {
        index: current.id,
        canMove: true,
        isFolder: childrens.length > 0,
        children: childrens,
        data: <div className="flex items-center space-x-1">
          <div>{current.libelle}</div>
          {current.nbDocuments>0 &&<Badge narrowPadding itemLibelle={current.nbDocuments} itemSize="text-[10px]"/>}
        </div>,
        canRename: true,
        canDrop: false,
      };
      return tree;
    },
    {
      root: {
        index: "root",
        isFolder: true,
        children: dataTypeDocuments
          .filter((td) => !td.documentTypeParent)
          .map((td) => td.id),
        data: "Root item",
      },
    }
  );
  return <>
    <Card className={{ root: "lg:h-full lg:w-3/12 mb-2 lg:mb-0" }}>
      <div>
        {loadingTypeDocuments ? (
          <div className="h-full flex items-center justify-center w-full">
            <div className="w-screen h-screen flex items-center justify-center"><Spinner /></div>
          </div>
        ) : (
          <Treeview
            treeId="Documentation_Tree"
            datas={treeItems}
            onSelectItems={onSelect}
            selectedItem={selectedItem}
            iconsItem={{
              folderOpen: <FolderFill className="h-4 w-4 text-primary-400" />,
              folder: <FolderFill className="h-4 w-4 text-primary-300" />,
              noChildren: <FolderFill className="h-4 w-4 text-primary-300" />
            }}
            openItems='all'
          />)}
      </div>
    </Card>
  </>
}