import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentDashboardCompte_Q1 from "./HelpContent/HelpContentDashboardCompte_Q1";
import HelpContentDashboardCompte_Q2 from "./HelpContent/HelpContentDashboardCompte_Q2";
import HelpContentDashboardCompte_Q3 from "./HelpContent/HelpContentDashboardCompte_Q3";
import HelpContentDashboardCompte_Q4 from "./HelpContent/HelpContentDashboardCompte_Q4";

export default function HelpDashboardCompte() {
  const p = useP();
  const datas = [{
    libelle: p.t('helpBox.HelpDashboardCompte.q1'),
    content: <HelpContentDashboardCompte_Q1 />
  }, {
    libelle: p.t('helpBox.HelpDashboardCompte.q2'),
    content: <HelpContentDashboardCompte_Q2 />
  }, {
    libelle: p.t('helpBox.HelpDashboardCompte.q3'),
    content: <HelpContentDashboardCompte_Q3 />
  }, {
    libelle: p.t('helpBox.HelpDashboardCompte.q4'),
    content: <HelpContentDashboardCompte_Q4 />
  }]
  return <>
    <HelpBoxAcordions datas={datas} />
  </>
}