import { useCallback, useState } from "react";
export const useTable = () => {


  const [childrenLoading, setChildrenLoading] = useState<boolean>(false);
  const [idRow, setIdRow] = useState<number | string>();

  const setCurrentChildrenLoading = useCallback((currentLoggedInUser) => {
    setChildrenLoading(currentLoggedInUser);
  }, []);

  const setCurrentIdRow = useCallback((currentLoggedInUser) => {
    setIdRow(currentLoggedInUser);
  }, []);


  return {
    childrenLoading,
    setChildrenLoading: setCurrentChildrenLoading,
    idRow,
    setIdRow: setCurrentIdRow

  };
};
