import React, { useState } from 'react'
import Table from '../../../Components/Table/Table';
import { useP } from '../../../services/i18n';

type TabPanelAbonnementDslVgaType = {
  datasVga: Array<any>,
  datasDsl: Array<any>,
}

export default function TabPanelAbonnementDslVga({ datasVga, datasDsl }: TabPanelAbonnementDslVgaType) {
  const p = useP();

  const columnsTableDsl = [
    {
      libelle: p.t('contrats.detailDsl.ndi'),
      code: "ndi",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detailDsl.typeOffre'),
      code: "typeOffre",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detailDsl.prix'),
      code: "prix",
      colType: "money",
    },
    {
      libelle: p.t('contrats.detailDsl.quantite'),
      code: "nbDeMois",
      colType: "number",
    },
    {
      libelle: p.t('contrats.detailDsl.regroupement'),
      code: "regroupement",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detailDsl.commentaire'),
      code: "commentaire",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detailDsl.periodicite'),
      code: "periodicite",
      colType: "number",
    },
  ]

  const columnsTableVga = [
    {
      libelle: p.t('contrats.detailVga.ndi'),
      code: "ndi.ndi",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detailVga.libelle'),
      code: "catalogueVga.libelle",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detailVga.prix'),
      code: "prix",
      colType: "money",
    },
    {
      libelle: p.t('contrats.detailVga.quantite'),
      code: "quantite",
      colType: "number",
    },
    {
      libelle: p.t('contrats.detailVga.commentaire'),
      code: "commentaire",
      colType: "string",
    }
  ]

  return <>
    {datasDsl &&
      <div className='pl-4 pb-4'>
        <div>{p.t('contrats.detailDsl.titre')}</div>
        <Table
          idTable="detail_produits"
          loading={false}
          columns={columnsTableDsl}
          datas={datasDsl}
          options={{
            pagination: false,
            search: false,
            fullBordered: false,
            resizable: true,
            toolbar: false,
            naturalWidth: false,
          }}
        />
      </div>
    }
    {datasVga &&
      <div className='pl-4 pb-4'>
        <br></br>
        <div>{p.t('contrats.detailVga.titre')}</div>
        <Table
          idTable="detail_produits"
          loading={false}
          columns={columnsTableVga}
          datas={datasVga}
          options={{
            pagination: false,
            search: false,
            fullBordered: false,
            resizable: true,
            toolbar: false,
            naturalWidth: false,
          }}
        />
      </div>
    }
  </>

}