import React, {
  createContext,
  useState,
  ReactNode,
  ReactText,
  ReactElement,
  PropsWithChildren,
  useEffect
} from "react";
import { AccordionItemProps } from "./AccordionItem";

export const AccordionContext = createContext({});

export type AccordionProps = {
  /** Children of the Accordion */
  children: ReactNode;
  /** Allow multiple expanded AccordionItems */
  allowMultipleExpanded?: boolean;
  /** Allow collapse an expanded AccordionItem */
  allowItemCollapse?: boolean;
  /** List of preExpanded AccordionItems */
  preExpanded?: Array<ReactText>;
  /** Callback Function that will be called when a AccordionItem changes - will return the uuid of the clicked item and the currently expanded items */
  onChange?: (uuid: ReactText, expandedItems: Array<ReactText>) => void;
};

const Accordion = ({
  allowMultipleExpanded,
  allowItemCollapse,
  preExpanded,
  onChange,
  children
}: AccordionProps) => {
  const [expandedItems, setExpandedItems] = useState<Set<ReactText>>(
    new Set(preExpanded)
  );
  
  // on force le preExpanded lorsque la props change
  useEffect(() => {
    setExpandedItems(new Set(preExpanded))
  }, [preExpanded])
  

  return (
    <AccordionContext.Provider
      value={{
        allowMultipleExpanded,
        allowItemCollapse,
        expandedItems,
        setExpandedItems,
        onChange
      }}
    >
      {React.Children.map(children, (child, index) => {
        const item = child as ReactElement<PropsWithChildren<AccordionItemProps>>;
        const hasCustomUuid = Object.hasOwnProperty.call(item.props, "uuid");
        return React.cloneElement(item, { uuid: hasCustomUuid ? item.props.uuid : index + 1 });
      })}
    </AccordionContext.Provider>
  );
};

export default Accordion
