import { gql } from "@apollo/client";

const GET_ARBORESENCE = gql`
  query KpiCyberGraph {
    kpiCyberGraph {
      compte{
        id
        raisonSociale
      }
      arborescence {
        code
        ordre
        enable
        libelle
        path
        parentCode
        description
        typeGraph
      }
    }
  }`

const GET_GRAPH_DATA = gql`
  query KpiCyberGraphData($compteId: ID!, $graphCode: String!) {
    kpiCyberGraphData(compteId: $compteId, graphCode: $graphCode) {
      datetime
      key
      value
    }
  }
`

const GET_GRAPH_DATA_HISTO = gql`
  query KpiCyberGraphDataHisto($compteId: ID!, $graphCode: String!, $dateDebut: DateTime!, $dateFin: DateTime!) {
    kpiCyberGraphDataHisto(compteId: $compteId, graphCode: $graphCode, dateDebut: $dateDebut, dateFin: $dateFin) {
      datetime
      key
      value
    }
  }
`

export {
  GET_ARBORESENCE,
  GET_GRAPH_DATA,
  GET_GRAPH_DATA_HISTO
}