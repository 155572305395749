import React from "react";

type ULType = {
  children?: JSX.Element|JSX.Element[]|string;
}

export default function UL({children}:ULType){
  return <>
    <ul className="list-disc pl-3 text-xs">{children}</ul>
  </>
}