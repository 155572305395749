import React, { useEffect, useState } from 'react'
import Button from '../../../../../Components/Button'
import Spinner from '../../../../../Components/Spinner'
import Modal from '../../../../../Components/Modal/Modal'
import { useLazyQuery } from '@apollo/client'
import { VISUALISER_BALANCES } from '../../../queries'
import { useP } from '../../../../../services/i18n'
import CircularProgressBar from '../../../../../Components/ProgressBar/CircularProgressBar'
import { convertDataFr } from '../../../../../Components/Utils/Utils'

type ActionVisualiserDataType = {
  livraisonId: any
}

export default function ActionVisualiserData({ livraisonId }: ActionVisualiserDataType) {
  const p = useP();

  const [getDatas, { data, loading }] = useLazyQuery(VISUALISER_BALANCES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      livraisonId
    }
  });

  const [modaleIsOpen, setModaleIsOpen] = useState(false)

  const [dataConsommee, setDataConsommee] = useState<any>(0)
  const [dataRestant, setDataRestant] = useState<any>(0)
  const [dataTotal, setDataTotal] = useState<any>(0)
  const [dataConsommeePercent, setDataConsommeePercent] = useState<any>(0)

  useEffect(() => {
    if (data) {
      const total = data?.visualiserBalances?.total || 0;
      const restant = data?.visualiserBalances?.restant || 0;
      const consomme = total - restant;
      const consommePercent = (consomme * 100) / total;
      setDataConsommeePercent(consommePercent.toFixed(2))
      setDataTotal(convertDataFr(total, 'MiB'));
      setDataConsommee(convertDataFr(consomme, 'MiB'));
      setDataRestant(convertDataFr(restant, 'MiB'));
    }
  }, [data])

  // useEffect(() => {
  //   const total = 10240;
  //   const restant = 3.9609375;
  //   const consomme = total - restant;
  //   const consommePercent = (consomme * 100) / total;
  //   setDataConsommeePercent(consommePercent.toFixed(2))
  //   setDataTotal(convertDataFr(total, 'MiB'));
  //   setDataConsommee(convertDataFr(consomme, 'MiB'));
  //   setDataRestant(convertDataFr(restant, 'MiB'));

  // }, [])

  return <>
    <div className=''>
      <Button
        disabled={loading}
        color='primary'
        size='small'
        onClick={() => {
          setModaleIsOpen(true)
          getDatas({
            variables: {
              livraisonId
            }
          })
        }}>
        {p.t('prestations.gsm.actions.visualiserBalanceData')}
      </Button>
    </div>
    <Modal
      onClose={() => setModaleIsOpen(false)}
      noPadding={false}
      isOpen={modaleIsOpen}
      content={{
        title: <div className=''>
          <div>{p.t('prestations.gsm.actions.visualiserBalanceDataModaleTitle')}</div>
        </div>,
        body: loading ? <Spinner /> : <>
          <div>
            <div className='flex items-center justify-center'>
              <CircularProgressBar
                color='sky-500'
                size={125}
                percents={dataConsommeePercent}
                contenu={
                  <div className='flex flex-col justify-center items-center -space-y-1 font-medium'>
                    <div>{dataRestant}</div>
                    <div className='text-xs lowercase'>{p.t('prestations.gsm.detail.infos.restant')}</div>
                    <div className='font-normal text-xs'>-</div>
                    <div className='font-normal text-xs'>{dataTotal}</div></div>
                } />
            </div>
            <div className='mt-4'>
              <div className='flex items-center space-x-2'>
                <div className='w-[30px] bg-sky-500 h-1.5'></div>
                <div className='text-xs'>{p.t('prestations.gsm.detail.infos.consomme')} ({dataConsommee})</div>
              </div>
              <div className='flex items-center space-x-2'>
                <div className='w-[30px] bg-gray-300 h-1.5'></div>
                <div className='ml-2 text-xs'>{p.t('prestations.gsm.detail.infos.restant')} ({dataRestant})</div>
              </div>
            </div>
          </div>
        </>,
        footer: null,
      }}
      className="w-full lg:w-1/5"
    />
  </>
}