import React from 'react'
import { UserType } from '../../../../types/UserType'

type ProfileInformationProps = {
  user: UserType
}

function ProfileInformation({ user }: ProfileInformationProps) {
  return (
    <div className="ml-3">
      <div className="text-base font-medium leading-none text-white">{user.prenom} {user.nom}</div>
      <div className="text-sm font-medium leading-none text-white">{user.email}</div>
    </div>
  )
}

export default ProfileInformation