import React, { useState } from "react";

import { getItemByCode, getPosition } from "../../Utils/Utils";
import { EMPTY_TYPE, operators as operatorsData, OPERATOR_INTERVALLE } from "../../../datas/Operators";
import Operator from "./Operator";
import Button from "../../Button";
import { filterTypes, operatorSelectedType } from "../Types/filterTypes";
type OperatorsType = {
  addFilters: (filters: Array<filterTypes>) => void,
  filter: filterTypes,
  closePopover: () => void
}
function Operators({ filter, addFilters, closePopover }: OperatorsType) {
  const operators = getItemByCode(operatorsData, filter.type);

  const [selectedOperator, setSelectedOperator] = useState<operatorSelectedType>();
  const [selectedOperatorIdx, setSelectedOperatorIdx] = useState(0);
  const [operatorValues, setOperatorValues] = useState(new Map());

  const handleClickApply = () => {
    let newFilters: Array<filterTypes> = [];

    if (operatorValues.size) {
      operatorValues.forEach((item, key) => {
        const { value, isSearchData, libValue } = item;
        if (Array.isArray(value) && key !== OPERATOR_INTERVALLE.code) {
          let filters = value.map((val) => ({
            ...item,
            value: val,
            libValue: val,
          }));
          if (isSearchData) {
            filters = value.map((val, index) => ({
              ...item,
              value: val,
              libValue: libValue[index],
            }));
          }

          newFilters = filters;
        } else {
          newFilters.push(item);
        }
      });
    } else {
      newFilters.push({
        id: filter.id,
        label: filter.label,
        type: filter.type,
        operator: selectedOperator?.operator,
        value: selectedOperator?.value,
        libValue: selectedOperator?.label,
      });
    }

    addFilters(newFilters);

    closePopover();
  };

  const closeFilters = () => {
    setOperatorValues(new Map());
    closePopover();
  };

  const handleOperatorChange = (index, op) => {
    setSelectedOperator(op);
    setSelectedOperatorIdx(index);
    setOperatorValues(new Map());
  };

  const changeOperatorValue = (values) => {
    const newOpValues = new Map();
    values.forEach((values, key) => {
      newOpValues.set(key, {
        filter: filter,
        ...values,
      });
    });
    setOperatorValues(new Map(newOpValues));
  };

  return (
    <>
      <div className="relative bg-white rounded-md -space-y-px">
        {operators?.items.map((op, index) => (
          <Operator
            key={index}
            id={index}
            op={op}
            filter={filter}
            pos={getPosition(operators, index)}
            //operatorValues={operatorValues}
            changeOperatorValue={changeOperatorValue}
            handleOperatorChange={handleOperatorChange}
            checked={selectedOperatorIdx === index}
          />
        ))}
      </div>
      <div className="flex items-center justify-between mt-2 border-0 border-none">
        <Button
          type="button"
          color="primaryLight"
          onClick={closeFilters}>Annuler</Button>
        <Button
          type="button"
          color="primary"
          onClick={handleClickApply}
          disabled={
            !operatorValues?.size && selectedOperator?.type !== EMPTY_TYPE
          }
        >Appliquer</Button>
      </div>
    </>
  );
}

export default Operators;
