import React, { MouseEvent, ReactNode, useEffect, useState } from 'react'
import { ColumnType } from './Types/ColumnType'
import Td from './Td'
import Tr from './Tr'
import { CheckCircleIcon, NoSymbolIcon, TrashIcon } from '@heroicons/react/24/outline'
import CellDetailRowFormatter from './Formatters/CellDetailRowFormatter'
import TrDetail from './TrDetail'
import { TableOptionsType } from './Types/TableOptionsType'
import { TableDetailRenderType } from './Types/TableDetailRenderType'
import TableActionsFormatter from './Formatters/TableActionsFormatter/TableActionsFormatter'
import CellEditFormatter from './Formatters/CellEditFormatter'
import CellSelectRowFormatter from './Formatters/CellSelectRowFormatter'
import CellCheckboxFormatter from './Formatters/CellCheckboxFormatter'
import clsx from 'clsx'
import CellBadgeFormatter from './Formatters/CellBadgeFormatter'
import dayjs from 'dayjs'
import { colWidthResizeType } from './TableContainer'
import CellMoneyFormatter from './Formatters/CellMoneyFormatter'
import CellNumberFormatter from './Formatters/CellNumberFormatter'
import CellDetailFormatter from './Formatters/CellDetailFormatter'
import { durationHumanFormatter } from '../Utils/Utils'
import CellActionIconFormatter from './Formatters/CellActionIconFormatter'
import CellEditableFormatter from './Formatters/CellEditableFormatter'
import CellKiBFormatter from './Formatters/CellKiBFormatter'
import CellDurationFormatter from './Formatters/CellDurationFormatter'
import CellAdresseFormatter from './Formatters/CellAdresseFormatter'
import CellDateFormatter from './Formatters/CellDateFormatter'

type TbodyRowType = {
  columns: Array<ColumnType>,
  row: any,
  tableOptions: TableOptionsType,
  indexRow: number,
  colWidthResize?: colWidthResizeType | undefined,
  selectedRows?: Array<any>
  detailComponentRender?(renderProps: TableDetailRenderType): ReactNode,
  onDoubleClickRow?: (row: object) => void,
  onClickRow?: (row: object, event: MouseEvent) => void,
  onSelectRow?: (row: object, checked: boolean) => void,
  onResize: (width: string, index: number) => void,
}

function TbodyRow({
  row,
  indexRow,
  columns,
  tableOptions,
  detailComponentRender,
  onSelectRow,
  colWidthResize,
  onDoubleClickRow,
  onClickRow,
  selectedRows }: TbodyRowType) {

  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const toggleDetail = () => {
    openDetail ? setOpenDetail(false) : setOpenDetail(true);
  }

  const getCell = (row, column) => {
    let resultCell;

    let cellValue = ""
    if (column.code) {
      column.code.split(".").forEach((c) => {
        cellValue = (cellValue == "" ? row[c] : cellValue[c])
      })
    }

    switch (column.colType) {
      case 'actions':
        resultCell = <TableActionsFormatter actions={row.colActions} row={row} />
        break;
      case "adresse":
        resultCell = <CellAdresseFormatter obj={row[column.formatterObjectCode]} />
        break;
      case "boolean":
        resultCell = <div className='flex justify-center'>{(row[column.code]) ? <CheckCircleIcon className="h-5 w-5 text-green-500" /> : <NoSymbolIcon className="h-5 w-5 text-red-400" />}</div>
        break;
      case "checkbox":
        resultCell = <div className='flex justify-center'><CellCheckboxFormatter row={row} column={column} /></div>
        break;
      case "date":
        resultCell = <CellDateFormatter value={cellValue} />
        break;
      case "dateTime":
        resultCell = cellValue ? dayjs(cellValue).format("DD/MM/YYYY HH:mm") : ""
        break;
      case "dateFromNow":
        resultCell = cellValue ? dayjs(cellValue).locale("fr").fromNow() : ""
        break;
      case "detail_row":
        resultCell = <CellDetailRowFormatter value={row[column.code]} onToggleDetail={() => toggleDetail()} open={openDetail} row={row} />
        break;
      case "detail":
        resultCell = <CellDetailFormatter row={row} action={column.action} onClickRow={onClickRow ? true : false} />
        break;
      case 'duree':
        resultCell = durationHumanFormatter(parseInt(cellValue))
        break;
      case "duration":
        resultCell = <CellDurationFormatter value={parseInt(cellValue)} />
        break;
      case "edit":
        resultCell = <CellEditFormatter row={row} action={column.action} />
        break;
      case "editable":
        resultCell = <CellEditableFormatter value={row[column.code]} onSaveField={column.action} />
        break;
      case "iconAction":
        resultCell = <CellActionIconFormatter loading={column.loading} row={row} action={column.action} icon={column.icon} tooltipMessage={column.tooltipMessage} />
        break;
      case "KiB":
        resultCell = <CellKiBFormatter value={parseInt(cellValue)} />
        break;
      case "money":
        resultCell = <CellMoneyFormatter value={row[column.code]} />
        break;
      case 'number':
        resultCell = <CellNumberFormatter value={cellValue} />
        break;
      case "remove":
        resultCell = <div className="cursor-pointer group flex justify-center">
          <TrashIcon className='h-5 w-5 text-gray-500 group-hover:text-secondary-500' />
        </div>
        break;
      case "state":
        resultCell = row[column.code] ? <CellBadgeFormatter label={row[column.code].libelle} colorClasses={row[column.code].colorClasses} /> : ""
        break;
      case "select":
        resultCell = <CellSelectRowFormatter row={row} onChange={(row, checked) => onSelectRow?.(row, checked)} selectedRows={selectedRows} />
        break;
      case "string":
      default:
        resultCell = <div className={clsx(tableOptions.naturalWidth ? `` : `truncate`)}>{cellValue}</div>
        break;
    }
    return resultCell
  }

  const renderDetailProps = {
    index: indexRow,
    idEntity: row.subrow?.idEntity,
    open: openDetail
  }

  return (
    <>
      {
        // TR classique
        <Tr
          onClick={(event) => onClickRow?.(row, event)}
          onDoubleClick={() => onDoubleClickRow?.(row)}
          className={
            clsx(`hover:bg-gray-50`,
              onDoubleClickRow || onClickRow && 'cursor-pointer',
              selectedRows?.find((r) => r.idLigne === row.idLigne) && `bg-primary-50 hover:bg-primary-50`
            )}>
          {
            tableOptions.colSelect &&
            <Td
              tableOptions={tableOptions}
              value={getCell(row, { colType: "select", code: 'select' })}
              className={clsx(tableOptions.fullBordered ? `border-r border-gray-200` : ``,)}
            />
          }
          {
            columns.filter((c) => !(c?.visible === false)).map((column, indexCol) => {
              return <Td
                key={indexCol}
                value={column.customFormatter ? column.customFormatter(row, column) : getCell(row, column)}
                className={
                  clsx(
                    tableOptions.fullBordered ? `border-r border-gray-200` : ``,
                    (tableOptions.fullBordered && indexCol + 1 == columns.length) ? `border-r-0` : ``,
                  )
                }
                tableOptions={tableOptions}
                initialWidth={column.width}
                width={indexCol == colWidthResize?.index ? colWidthResize.width : null}
                valign={tableOptions.valign}
                noPadding={(column.colType == 'iconAction' || column.colType == 'detail')}
              />
            })
          }
        </Tr>
      }
      {
        // TR de detail
        (row.subrow?.size > 0 && detailComponentRender) && (
          <TrDetail colspan={columns.length} open={openDetail}>
            {detailComponentRender(renderDetailProps)}
          </TrDetail>)
      }
    </>
  )

}

export default TbodyRow