import React, { RefObject, useRef, useState } from 'react'
import Badge from './Badge'
import clsx from 'clsx';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

type TagsInputType = {
  placeholder: string,
  type: string,
  inputClassName?: string,
  defaultTags?: string[],
  onChange?: (tags: string[]) => void
}

type InputRefType = RefObject<HTMLInputElement>;

function isEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

function TagsInput({ placeholder, type = "text", inputClassName = "", defaultTags = [], onChange }: TagsInputType) {

  const inputRef: InputRefType = useRef<HTMLInputElement>(null);

  const [tags, setTags] = useState<Array<string>>(defaultTags)
  const [error, setError] = useState<string | null>(null)

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return
    const value = e.target.value
    if (!value.trim()) return
    addTag(value)
  }

  const addTag = (value) => {
    /** checks en fonction du type de champ*/
    if (type === "email") {
      if (!isEmail(value)) {
        setError("email incorrect")
        return
      } else {
        setError(null)
      }
    }
    const newTags = [...tags, value];
    onChange?.(newTags);
    setTags(newTags);
    if (inputRef.current)
      inputRef.current.value = '';
  }

  const removeTag = (index) => {
    const newTags = tags.filter((el, i) => i !== index)
    onChange?.(newTags)
    setTags(newTags)
  }

  const focusOnInput = () => {
    inputRef?.current?.focus()
  }

  return (
    <>
      <div className="relative flex items-center flex-wrap border border-gray-300 p-1 mb-1 rounded-md w-full" onClick={() => focusOnInput()}>
        <input
          onKeyDown={handleKeyDown}
          type={type}
          className={clsx(`flex-1 border-0 rounded-md text-xs py-[1px] px-1 focus:outline-none focus:ring-0 focus:border-0`, inputClassName)}
          placeholder={placeholder}
          ref={inputRef}
        />
        <div onClick={() => {
          addTag(inputRef?.current?.value)
        }
        } className='absolute top-0 right-0 h-full  flex items-center justify-center  px-4 bg-gray-100 rounded-r-md cursor-pointer border-l border-gray-300'>
          <PlusCircleIcon className='text-emerald-600 h-5 w-5 bg-white rounded-full hover:text-emerald-500' />
        </div>
      </div>
      {
        error &&
        <div className='text-red-600 text-xs mt-1'>{error}</div>
      }
      <div className='flex items-center flex-wrap'>
        {tags.map((tag, index) => (
          <div key={index} className='mb-1 mt-1 mr-2'>
            <Badge rounded={false} deleteAction={() => removeTag(index)}
              deletable
              itemColor='gray'
              itemLibelle={<><div>{tag}</div></>}
            />
          </div>
        ))}
      </div>

    </>)
}

export default TagsInput