import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Listbox } from '@headlessui/react'

import { CompteType, EmptyCompte } from '../../../../types/CompteType'
import SelectedSite from './SelectedCompte'
import CompteDropdown from './CompteDropdown'
import { authContext } from '../../../../AuthContext'
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '../../../../Components/Utils/Utils'

type CompteSelectionProps = {
  value: string | Array<string> | undefined,
  onChange: (ids: string | Array<string>) => void,
  comptes: Array<CompteType>
  fieldValue: string,
  fieldLabel: string
}

export default function CompteSelection({ comptes, value, onChange, fieldValue, fieldLabel }: CompteSelectionProps) {

  const { loggedInUser } = useContext(authContext)
  
  const [searchValue, setSearchValue] = useState<string>('')

  const [selected, setSelected] = useState<CompteType>({ ...EmptyCompte })

  const [filteredComptes, setFilteredComptes] = useState<Array<any>>([])

  const checkboxRef = useRef<HTMLInputElement>(null);
  const [modeMulticompte, setModeMulticompte] = useState<boolean>(getLocalStorage(`ec-mode-multicomptes`)||false);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedComptes, setSelectedComptes] = useState<Array<string>>([]);

  const onChangeSelectedComptes = (selection) => {
    if (!selection) return

    const newValue = selection;

    if (!modeMulticompte) {
      setLocalStorage('ec-selected-comptes', JSON.stringify(newValue));
      onChange(newValue)
    }
    else {
      setSelectedComptes(newValue)
      setLocalStorage('ec-selected-comptes', JSON.stringify(newValue));
    }
  }

  const onReset = () => {
    setSelectedComptes(Array.isArray(value) ? value : []);
  }

  const onClose = () => {
    if (selectedComptes.length > 0) {
      onChange(selectedComptes);
    }
  }

  useEffect(() => {
    if (!value) return

    if (Array.isArray(value)) {

      // tri des éléments sélectionnés pour les positionner en 1er
      comptes.sort((a, b) => {
        if (value.includes(a.id) && !value.includes(b.id)) {
          return -1;
        } else if (!value.includes(a.id) && value.includes(b.id)) {
          return 1;
        }
        return 0;
      })

      setSelected(EmptyCompte)
      setSelectedComptes(value)
      setModeMulticompte(true)
    }
    else {
      let record = comptes.find(element => element[fieldValue] === value);
      
      if (!record) record = comptes.at(0)

      setSelected(record ? record : EmptyCompte)
    }
  }, [value])

  const toggleAll = () => {
    setSelectedComptes(checked || indeterminate ? [] : comptes.map(compte => compte.id));
    setLocalStorage('ec-selected-comptes',JSON.stringify(checked || indeterminate ? [] : comptes.map(compte => compte.id)));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  useLayoutEffect(() => {

    if (!Array.isArray(selectedComptes)) return

    const isIndeterminate =
      selectedComptes.length > 1 &&
      selectedComptes.length < comptes.length;
    setChecked(selectedComptes.length === comptes.length);
    setIndeterminate(!!isIndeterminate);

    if (checkboxRef?.current) {
      checkboxRef.current.indeterminate = !!isIndeterminate;
    }

  }, [selectedComptes]);

  useEffect(() => {
    setFilteredComptes(comptes.filter(compte => compte.raisonSociale.toUpperCase().includes(searchValue.toUpperCase())))
  }, [searchValue])

  useEffect(() => {
    if (value && modeMulticompte && !Array.isArray(value)) {
      setSelectedComptes([value])
      setModeMulticompte(true)
      setLocalStorage("ec-mode-multicomptes",true)
      setLocalStorage('ec-selected-comptes', JSON.stringify([value]));
    }
    else if (selectedComptes.length > 0 && value && value !== selectedComptes[0]) {
      onChange(selectedComptes[0])
      setModeMulticompte(false)
      setLocalStorage("ec-mode-multicomptes",false)
      removeLocalStorage('ec-selected-comptes');
    }
  }, [modeMulticompte])

  useEffect(() => {

    const selected = sessionStorage.getItem("selectedCompte") ? JSON.parse(`${sessionStorage.getItem("selectedCompte")}`) : []    
    if (selected && !value) {
      setSelectedComptes(selected)
      onChange(selected)
    }
  }, [value])

  return (
    loggedInUser?.user?.isMultiCompte ?
      <>
      <Listbox value={modeMulticompte ? selectedComptes : value} onChange={onChangeSelectedComptes} multiple={!!modeMulticompte} >
        {({ open }) => (
          <>
            <div className="relative">
              <SelectedSite value={value} comptes={filteredComptes} selected={selected} fieldLabel={fieldLabel} />
              <CompteDropdown 
              open={open} 
              comptes={filteredComptes} 
              selectedComptes={selectedComptes} 
              fieldLabel={fieldLabel} 
              fieldValue={fieldValue} 
              checkboxRef={checkboxRef} 
              checked={checked} 
              toggleAll={toggleAll} 
              searchValue={searchValue} 
              setSearchValue={setSearchValue} 
              modeMulticompte={modeMulticompte} 
              setModeMulticompte={(valMuti)=>{
                setModeMulticompte(valMuti)
                setLocalStorage("ec-mode-multicomptes",valMuti)
                !valMuti && removeLocalStorage('ec-selected-comptes');
              }}               
              onClose={onClose} 
              onReset={onReset} />
            </div>
          </>
        )
        }
      </Listbox > </>
      : <div className='lg:w-52 bg-white/10 text-left text-white rounded-md py-2 px-3 text-sm font-medium'>
          {selected.raisonSociale}
        </div>
  )
}
