import React from 'react'
import InputSwitch from '../../../Components/Form/InputSwitch';
import Table from '../../../Components/Table/Table';
import { useP } from '../../../services/i18n';
type TableContratType = {
  datas: Array<any>,
  onClickGroup: (row: any) => void,
  onChangeMaskVente: (value: any) => void,
  withOutVente: boolean,
  loading: boolean,
}
export default function TableContrat({ datas, onClickGroup, withOutVente, onChangeMaskVente, loading }: TableContratType) {
  const p = useP();

  const columns = [
    {
      libelle: "Nom du groupe",
      code: 'groupe',
      colType: 'string'
    },
    {
      libelle: "Site",
      code: 'site',
      colType: 'string'
    },
  ]



  return <>
    <Table
      idTable="ec-table-contrat"
      loading={loading}
      datas={datas}
      columns={columns}
      options={{
        pagination: false,
        resizable: false,
        search: {
          placeholder: p.t('contrat.tableSearchPlaceholder'),
          attributs: ["groupe", "site"]
        }
      }}
      customToolbarActions={
        <>
          <div className='flex items-center'>
            <div className="flex items-center justify-end pb-2 pt-2 pr-1 bg-white text-gray-700">
              <div className="text-sm font-medium mr-2">{p.t('contrat.maskVentes')}</div>
              <div><InputSwitch name="" label="" value={withOutVente} onChange={(value) => onChangeMaskVente(value)} /></div>
            </div>
            {/* <div><ButtonsGroup selectedButtons={filterStatut} onSelectedButton={(value) => setFilterStatut(value)} buttons={filterButtonsStatuts} multi={true} /></div> */}
          </div>
        </>
      }
      onClickRow={(row) => onClickGroup(row)}
    />
  </>

}