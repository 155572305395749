import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import Table from '../../../Components/Table/Table';
import { toastError, toastSuccess } from '../../../Components/Utils/Utils';
import { useP } from '../../../services/i18n';
import { UPDATE_ABONNEMENTS } from '../queries';
import Constants from '../../../commons/Constants';
import CheckRigth, { useCheckRight } from '../../../Components/CheckRight/CheckRight';
import CommandeGSM from '../../CommandeGSM/CommandeGSM';
import Button from '../../../Components/Button';
import { EmptyBonDeCommandeModele } from '../../../types/BonDeCommandeModeleType';
import CommandeArrow from '../../CommandeArrow/CommandeArrow';
import { useCheckFeatureFlag } from '../../../Components/CheckFeatureFlag/CheckFeatureFlag';

const {
  STATUT_CONTRAT_TERMINE,
  ROLE_ADMIN_CONTRAT,
  BDC_FOURNISSEUR_ALPHALINK,
  BDC_FOURNISSEUR_ARROW,
  BDC_FOURNISSEUR_EIT
} = Constants;

type ProduitType = {
  datas: Array<any>,
  withOutVente: boolean
}

export default function Produit({ datas, withOutVente }: ProduitType) {
  const p = useP();
  const { checkFeatureFlag } = useCheckFeatureFlag();
  // Modale de commande GSM
  const [openCommandeGSM, setOpenCommandeGSM] = useState(false)
  const [openCommandeArrow, setOpenCommandeArrow] = useState(false)
  const [idAbonnement, setIdAbonnement] = useState(0)
  const [bonDeCommandeModele, setBonDeCommandeModele] = useState(EmptyBonDeCommandeModele)

  // Lecture id abonnement (colonne cachée, fonction appelée au clic dans une ligne)
  const onClickRow = (row: any) => {
    setIdAbonnement(row ? row.idAbo : 0);
    setBonDeCommandeModele(row ? row.bonDeCommandeModele : EmptyBonDeCommandeModele)
  }

  // Sauvegarde nature
  const saveNature = (row) => {
    return (idAbonnement != 0 ? saveAbonnement({
      nature: row
    }) : true)
  }

  // Sauvegarde code analytique
  const saveCodeAnalytique = (row) => {
    return (idAbonnement != 0 ? saveAbonnement({
      codeAnalytique: row
    }) : true)
  }

  // Sauvegarde
  const saveAbonnement = async (data) => {
    if (idAbonnement != 0) {
      const result = await updateAbonnements({
        variables: {
          abonnement: data,
          id: idAbonnement
        },
        onCompleted: () => {
          toastSuccess(p.t('error.defaultEnregistrement'));
          return true;
        },
      }).catch(() => {
        toastError(p.t('error.defaultMessage'));
        return false;
      })

      return result;
    }
  }

  const [updateAbonnements] = useMutation(
    UPDATE_ABONNEMENTS
  );

  const { checkRight } = useCheckRight();
  const rightAdminContrat = checkRight(ROLE_ADMIN_CONTRAT);

  const columnsTableDetail = [
    {
      libelle: 'ID',
      code: "idAbo",
      colType: "string",
      visible: false
    },
    {
      libelle: p.t('contrats.detail.produit'),
      code: "nomProduit",
      colType: "",
      width: "330px",
      customFormatter: (datas) => (
        <div className='truncate'>
          <div><div className='text-gray-900 truncate pr-2'>{datas.nomProduit}</div></div>
          <div className='text-xs truncate pr-4'>{datas.nomProduit != datas.description && datas.description}</div>
          {datas?.bonDeCommandeModele?.espaceClient && datas?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ALPHALINK && datas.statutContrat !== STATUT_CONTRAT_TERMINE && datas.quantite > 0 && datas.isActif &&
            < CheckRigth role={ROLE_ADMIN_CONTRAT}>
              <div className='my-1'><Button onClick={() => setOpenCommandeGSM(true)} size='small' color='primaryBordered'>{p.t('commandeGSM.buttonModalTitle')}</Button></div>
            </CheckRigth>
          }
          {datas?.bonDeCommandeModele?.espaceClient && datas?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_EIT && datas.statutContrat !== STATUT_CONTRAT_TERMINE && datas.quantite > 0 && datas.isActif &&
            < CheckRigth role={ROLE_ADMIN_CONTRAT}>
              <div className='my-1'><Button onClick={() => setOpenCommandeGSM(true)} size='small' color='primaryBordered'>{p.t('commandeGSM.buttonModalTitle')}</Button></div>
            </CheckRigth>
          }

          {datas?.bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ARROW && datas.statutContrat !== STATUT_CONTRAT_TERMINE && datas.quantite > 0 && datas.isActif &&
            < CheckRigth role={ROLE_ADMIN_CONTRAT}>
              <div className='my-1'><Button onClick={() => setOpenCommandeArrow(true)} size='small' color='primaryBordered'>{p.t('commandeArrow.buttonModalTitle')}</Button></div>
            </CheckRigth>
          }
        </div >
      ),
    },
    {
      libelle: p.t('contrats.detail.reference'),
      code: "reference",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detail.mode'),
      code: "mode",
      colType: "string"
    },
    {
      libelle: p.t('contrats.detail.periodicite'),
      code: "periodicite",
      colType: "string",
    },
    {
      libelle: p.t('contrats.detail.codeAnalytique'),
      code: "codeAnalytique",
      colType: rightAdminContrat ? "editable" : "string",
      action: saveCodeAnalytique
    },
    {
      libelle: p.t('contrats.detail.nature'),
      code: "nature",
      colType: rightAdminContrat ? "editable" : "string",
      action: saveNature
    }, {
      libelle: p.t('contrats.detail.qte'),
      code: "quantite",
      colType: "number",
    },
    {
      libelle: p.t('contrats.detail.qteALivrer'),
      code: "quantiteALivrer",
      colType: "number",
    },
    {
      libelle: p.t('contrats.detail.prixUnitFas'),
      code: "prixUnitaireFas",
      colType: "money",
    },
    {
      libelle: p.t('contrats.detail.prixUnit'),
      code: "prixUnitaire",
      colType: "money",
      resizable: false
    },
  ]

  // "Filtre" sur les ventes ( masquage/affichage des ventes )
  const [tableDatas, setTableDatas] = useState<any[]>([]);
  useEffect(() => {
    let datasProduit;
    if (withOutVente) {
      datasProduit = datas.filter((d) => d.mode != "Vente")
    } else {
      datasProduit = datas
    }

    setTableDatas(datasProduit)
  }, [datas, withOutVente]);

  // Ne pas afficher le tableau des produits si rien à afficher
  if (tableDatas.length === 0)
    return null;

  return <>
    <Table
      idTable="detail_produits"
      loading={false}
      columns={columnsTableDetail}
      datas={tableDatas}
      options={{
        pagination: false,
        search: false,
        fullBordered: false,
        resizable: true,
        toolbar: false,
        naturalWidth: false,
        valign: 'top'
      }}
      onClickRow={onClickRow}
    />
    <CommandeGSM bonDeCommandeModele={bonDeCommandeModele} abonnementId={idAbonnement} isOpen={openCommandeGSM} onCloseModalCommandeGSM={() => setOpenCommandeGSM(false)} />
    <CommandeArrow bonDeCommandeModele={bonDeCommandeModele} abonnementId={idAbonnement} isOpen={openCommandeArrow} onCloseModalCommandeArrow={() => setOpenCommandeArrow(false)}  downPossible={checkFeatureFlag("ARROW_COMMANDE")}/>
  </>

}