import React from 'react';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { BreadcrumbDataType } from '../../Components/Breadcrumb/BreadcrumbDataType';
import Card from '../../Components/Card';
import TableSupport from './TableSupport';
import ModaleCreationTicket, { typeNewTicketType } from './ModaleCreationTicket/ModaleCreationTicket';

type SupportContainerType = {
  breadcrumb: Array<BreadcrumbDataType>,
  ticketIdSelected: number | null,
  modaleCreationTicketIsOpen: boolean,
  onCloseModaleDetailTicket: () => void,
  onClickTableRow: (row: any) => void | undefined,
  onCloseModaleCreationTicket: () => void,
  addTicket: (type: typeNewTicketType) => void,
  typeNewTicket: typeNewTicketType,
}
function SupportContainer({
  breadcrumb,
  ticketIdSelected,
  onCloseModaleDetailTicket,
  modaleCreationTicketIsOpen,
  onCloseModaleCreationTicket,
  addTicket,
  typeNewTicket,
  onClickTableRow,
}: SupportContainerType) {

  return <>
    <Breadcrumb datas={breadcrumb} />
    <div className="h-full flex-1 overflow-hidden pb-0.5">
      <div className="flex flex-col xl:flex-row flex-1 h-full">
        <Card className={{ root: "flex-1 h-full" }}>
          <TableSupport onClickRow={onClickTableRow} addTicket={addTicket} ticketIdSelected={ticketIdSelected} onCloseModaleDetailTicket={onCloseModaleDetailTicket}/>
          <ModaleCreationTicket isOpen={modaleCreationTicketIsOpen} onClose={onCloseModaleCreationTicket} typeNewTicket={typeNewTicket} />
        </Card>
      </div>
    </div>
  </>;
}

export default SupportContainer