import React, { useRef } from "react"
import Tooltip from "./Tooltip/Tooltip"
import { PaperClipIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"

type FilePickerType = {
  filetypes: string,
  tooltipContent: string,
  onFilesSelected: (file: any) => void,
  multiple?: boolean,
  iconOnly?: boolean,
  buttonClassName?: string,
}

export default function FilePicker({ filetypes = "", tooltipContent = "", onFilesSelected, multiple = false, iconOnly = true, buttonClassName = "hover:bg-gray-100 rounded-md" }: FilePickerType) {
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    onFilesSelected(files);
  };
  return <>
    <div>
      {
        iconOnly ? <Tooltip
          button={
            <div className="">
              <div className="">
                <label htmlFor="filepicker" className={clsx(`relative flex items-center justify-center w-8 h-7  cursor-pointer hover:transition-all ease-in-out`, buttonClassName)}>
                  <PaperClipIcon className="text-gray-500 h-4 w-4  cursor-pointer" />
                </label>
                <input
                  id="filepicker"
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleFileChange}
                  multiple={multiple}
                />
              </div>
            </div>

          }
          content={<div className="text-xs">{tooltipContent}</div>}
          withArrow
        />
          :
          <>

            <div className="flex items-center justify-center w-full">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center p-2">
                  <div className="mb-1 text-sm text-gray-500 font-medium">Parcourir ou glisser-déposer</div>
                  <div className="text-xs text-gray-500 ">PNG, JPG or GIF (Max ??MB)</div>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  multiple={multiple} />
              </label>
            </div>

          </>
      }


    </div >
  </>
}