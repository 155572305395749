import React from "react";

type H2Type = {
  children?: JSX.Element|JSX.Element[]|string;
}

export default function H2({children}:H2Type){
  return <>
    <h2 className="text-primary-700 text-md font-semibold mt-4 mb-0">{children}</h2>
  </>
}