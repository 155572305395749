import { useQuery } from '@apollo/client';
import { MapIcon, Bars4Icon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Table from '../../../../Components/Table/Table'
import { useP } from '../../../../services/i18n';
import LoginState from '../../../Lien/LoginState';
import { PrestationsViewType } from '../../PrestationsType';
import { GET_LIEN } from '../../queries';
import MapLiens from './MapLiens';
import ToolbarActionsPrestationLien from './ToolbarActionsPrestationLien';

export default function LienView({ siteId }: PrestationsViewType) {

  const p = useP();
  const navigate = useNavigate();

  // Table Datas
  const { loading, data } = useQuery(GET_LIEN, {
    variables: {
      idSite: siteId,
    },
  });

  const editRow = (row) => { navigate(`../../lien/${row.id}`) }

  const columns = [
    {
      libelle: p.t('prestations.liens.columns.etat'),
      code: 'etat',
      colType: 'state',
    },
    {
      libelle: p.t('prestations.liens.columns.site'),
      code: 'site.nom',
      colType: 'adresse',
      formatterObjectCode: 'site',
      sortable: true
    },
    {
      libelle: p.t('prestations.liens.columns.type'),
      code: 'type',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.liens.columns.debit'),
      code: 'debit',
      colType: 'string',
    },
    {
      libelle: p.t('prestations.liens.columns.logins'),
      code: 'loginsFormate',
      colType: 'string',
    },
    {
      libelle: p.t('prestations.liens.columns.ipWan'),
      code: 'ipWansFormate',
      colType: 'number',
    },
    {
      libelle: p.t('prestations.liens.columns.etatLogin'),
      code: 'etatLogin',
      colType: 'string',
    },
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: editRow,
      resizable: false
    },
  ]

  const filterButtons = [
    {
      libFiltre: p.t("prestations.liens.libFiltreStatut"),
      filtres: [
        { libelle: p.t('etat.prestation.EN SERVICE'), value: "EN SERVICE" },
        { libelle: p.t('etat.prestation.COMMANDE EN COURS'), value: "COMMANDE EN COURS" },
        { libelle: p.t('etat.prestation.COMMANDE REJETEE'), value: "COMMANDE REJETEE" },
        { libelle: p.t('etat.prestation.RESILIE'), value: "RESILIE" }]
    },
    {
      libFiltre: p.t("prestations.liens.libFiltreEtat"),
      filtres: [
        { libelle: p.t('etat.prestation.CONNECTE'), value: true },
        { libelle: p.t('etat.prestation.DECONNECTE'), value: false }
      ]
    }
  ]

  const [tableDatas, setTableDatas] = useState<any[]>([]);
  const [mapDatas, setMapDatas] = useState<any[]>([]);

  const initialFilters = [
    { libFiltre: p.t("prestations.liens.libFiltreStatut"), filtres: ["EN SERVICE"] },
    { libFiltre: p.t("prestations.liens.libFiltreEtat"), filtres: [true, false] }
  ]

  const [filtres, setFiltres] = useState<any>(initialFilters)

  useEffect(() => {
    let datas = data ? data.prestationLiens : [];

    const filtresStatut = filtres.find((f) => f.libFiltre == p.t("prestations.liens.libFiltreStatut"))
    const filtresEtat = filtres.find((f) => f.libFiltre == p.t("prestations.liens.libFiltreEtat"))

    if (filtresStatut) {
      datas = datas.filter((d) => filtresStatut.filtres.includes(d.etat))
    }

    if (filtresEtat) {
      datas = datas.filter((d) =>
        d.loginStates.filter((state) =>
          filtresEtat.filtres.includes(state)
        ).length > 0
      )
    }

    setTableDatas(datas)

  }, [data, filtres]);

  // Initialisation de la carte avec filtre des données sur les liens en service
  useEffect(() => {
    let datasLiensEnService = data ? data.prestationLiens : [];
    datasLiensEnService = datasLiensEnService.filter((d) => ("EN SERVICE" === d.etat && d.logins.length > 0))
    setMapDatas(datasLiensEnService)
  }, [data])

  const switchIconsDatas = [
    {
      id: 0,
      label: p.t('prestations.liens.carte'),
      icon: <MapIcon className='h-4 w-4' />,
    },
    {
      id: 1,
      label: p.t('prestations.liens.liste'),
      icon: <Bars4Icon className='h-4 w-4' />,
    }
  ]

  const [view, setView] = useState(1)

  useEffect(() => {
    if (siteId) {
      setView(1)
    } else {
      setView(0)
    }
  }, [siteId])


  return <>
    {view == 1
      ? <Table
        idTable='table-lien'
        loading={loading}
        datas={tableDatas.map((d) => ({
          ...d,
          debit: `${d.debitUp ? d.debitUp : "-"} / ${d.debitDown ? d.debitDown : "-"}`,
          etat: {
            code: d.etat,
            libelle: p.t(`etat.prestation.${d.etat}`),
            colorClasses: `bg-${d.etatColor}-200 text-${d.etatColor}-800`
          },
          loginsFormate: <div>{d.logins.map((l: string, index: number) => <Fragment key={index}>{l}<br /></Fragment>)}</div>,
          ipWansFormate: <div>{d.ipWans.map((i: string, index: number) => <Fragment key={index}>{i}<br /></Fragment>)}</div>,
          etatLogin: <div className='flex items-center justify-center'><LoginState loginStates={d.loginStates} /></div>
        }))}
        columns={columns}
        customToolbarActions={
          <>
            <ToolbarActionsPrestationLien
              selectedSwitchIcon={view}
              datasSwitchIcon={switchIconsDatas}
              onSelectSwitchIcon={(item) => setView(item.id)}
              updateSelectedFilters={(filters) => setFiltres(filters)}
              buttonsGroup={filterButtons}
              siteId={siteId}
              selectedFilters={filtres}
            />
          </>
        }
        options={{
          search: {
            placeholder: p.t('prestations.searchPlaceholder'),
          },
          naturalWidth: true
        }}
        onClickRow={editRow}
      />
      : <>
        <MapLiens
          loading={loading}
          datas={mapDatas}
          toolbar={<ToolbarActionsPrestationLien
            selectedSwitchIcon={view}
            datasSwitchIcon={switchIconsDatas}
            onSelectSwitchIcon={(item) => setView(item.id)}
            updateSelectedFilters={(filters) => setFiltres(filters)}
            buttonsGroup={filterButtons}
            siteId={siteId}
            selectedFilters={filtres}
          />}
        />

      </>
    }
  </>
}