import { gql } from "@apollo/client";

const GET_WIDGETS = gql`
  query GetWidgets($widgetZoneCodes: [String]) {
    widgetsByZone(widgetZoneCodes: $widgetZoneCodes) {
      code
      libelle
      description
      widgetZones {
        code
      }
    }
    contactWidgets {
      widgetZoneCode
      widgets {
        contactWidgetId
        ordre
        widgetCode
      }
    }
  }
`;

const DELETE_WIDGET_CONTACT = gql`
  mutation DeleteWidgetContact($contactWidgetId: ID!) {
    deleteWidgetContact(contactWidgetId: $contactWidgetId) {
      message
      success
    }
  }
`;

const MOVE_WIDGET_CONTACT = gql`
  mutation MoveWidgetContact($contactWidgetId: ID!, $direction: WidgetMoveDirection!) {
    moveWidgetContact(contactWidgetId: $contactWidgetId, direction: $direction) {
      message
      success
    }
  }
`

const ADD_WIDGET_CONTACT = gql`
  mutation AddWidgetContact($widgetCode: String!, $widgetZoneCode: String!) {
    addWidgetContact(widgetCode: $widgetCode, widgetZoneCode: $widgetZoneCode) {
      message
      success
    }
  }
`

export {
  GET_WIDGETS,
  DELETE_WIDGET_CONTACT,
  MOVE_WIDGET_CONTACT,
  ADD_WIDGET_CONTACT
}