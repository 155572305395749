import React, { ReactElement, useEffect, useState } from 'react';
import Polyglot from 'node-polyglot';
import I18nContext from './i18n-context';
import { fetchPhrases } from './utils';
import Spinner from '../../Components/Spinner';

const ALLOWED_LANGUAGES = ['fr'];
const DEFAULT_LANGUAGE = 'fr';

interface Props {
  children: ReactElement,
}

function I18nProvider({ children }: Props) {
  const [locale, setLocale] = useState(navigator.language.slice(0, 2) || DEFAULT_LANGUAGE);
  const [phrases, setPhrases] = useState<{ [key: string]: string }>();

  useEffect(() => {
    if (locale) {
      const lang = ALLOWED_LANGUAGES.includes(locale) ? locale : DEFAULT_LANGUAGE;
      fetchPhrases(lang).then(setPhrases);
    }
  }, [locale]);

  const p = new Polyglot({ locale: locale, phrases: phrases });

  return (
    <I18nContext.Provider
      value={{
        locale,
        setLocale,
        p,
      }}
    >
      {phrases ? React.Children.only(children) : <div className='h-screen w-screen flex items-center justify-center'><Spinner /></div>}
    </I18nContext.Provider>
  );
}

export default I18nProvider;