import React, { useEffect, useState } from 'react'
import { useP } from '../../services/i18n'
import Table from '../../Components/Table/Table';
import { useLazyQuery } from '@apollo/client';
import { GET_FACTURES, TELECHARGER_CDR_FIXE, TELECHARGER_CDR_GSM, TELECHARGER_EXPORT, TELECHARGER_FACTURE } from './queries';
import dayjs from 'dayjs';
import Select, { OptionSelectType } from '../../Components/Select/Select';
import { ArrowDownTrayIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import Constants from '../../commons/Constants';
import Spinner from '../../Components/Spinner';
import { toastError } from '../../Components/Utils/Utils';
import { FileEarmarkPdfFill } from 'react-bootstrap-icons';
import { TableOptionsType } from '../../Components/Table/Types/TableOptionsType';
import Button from '../../Components/Button';

require('dayjs/locale/fr')
dayjs.locale('fr')

type TableFacturesType = {
  numcli: string | undefined
  selectAnnee?: boolean
  tableOptions?: TableOptionsType
  dateDebut?: string
  dateFin?: string
  dateMinSelecteurAnnee: number
  dateMaxSelecteurAnnee: number
}

type filterType = {
  column: string
  operator?: string
  value: string | string[]
}

export default function TableFactures({
  numcli,
  selectAnnee = true,
  tableOptions = {},
  dateDebut = `${dayjs().year()}-01-01`,
  dateFin = `${dayjs().year()}-12-31`,
  dateMinSelecteurAnnee,
  dateMaxSelecteurAnnee
}: TableFacturesType) {

  const p = useP();

  const tableOptionsDefault: TableOptionsType = {
    resizable: true,
    search: {
      placeholder: p.t('factures.rechercher'),
      attributs: ["numcli", "facture"]
    },
    colSelect: true
  }

  const options: TableOptionsType = {
    ...tableOptionsDefault,
    ...tableOptions
  }

  // On construit les selects des années
  const selectedDatasAnnees: OptionSelectType[] = []
  for (let a = dateMaxSelecteurAnnee; a >= dateMinSelecteurAnnee; a -= 1) {
    selectedDatasAnnees.push({
      code: a,
      libelle: a.toString()
    })
  }

  const [annee, setAnnee] = useState<number>(dateMaxSelecteurAnnee)
  const [dateDebutAffiche, setDateDebutAffiche] = useState<string>(dateDebut)
  const [dateFinAffiche, setDateFinAffiche] = useState<string>(dateFin)

  const [tableDatas, setTableDatas] = useState<object[]>([])
  const [rowActionClick, setRowActionClick] = useState<any>(null)

  const {
    FILER_URL
  } = Constants;

  // Table Datas
  const [getFactures, { loading, data }] = useLazyQuery(GET_FACTURES);

  // Téléchargement de la facture
  const [telechargerFacture, { loading: loadingFacture }] = useLazyQuery(TELECHARGER_FACTURE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data.telechargerFacture) {
        const { hash, filename } = data.telechargerFacture
        window.open(`${FILER_URL}?hash=${hash}&filename=${filename}`)
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  const onClickTelechargerFacture = (row) => {
    setRowActionClick(row)
    telechargerFacture({
      variables: {
        id: row.id,
      },
    })
  }

  // Téléchargement CDR Fixe
  const [telechargerCdrFixe, { loading: loadingCdrFixe }] = useLazyQuery(TELECHARGER_CDR_FIXE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data.telechargerCdrFixe) {
        const { hash, filename } = data.telechargerCdrFixe
        window.location.href = `${FILER_URL}?hash=${hash}&filename=${filename}`
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  const onClickTelechargerCdrFixe = (row) => {
    setRowActionClick(row)
    telechargerCdrFixe({
      variables: {
        id: row.id,
      },
    })
  }

  // Téléchargement CDR Gsm
  const [telechargerCdrGsm, { loading: loadingCdrGsm }] = useLazyQuery(TELECHARGER_CDR_GSM, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data.telechargerCdrGsm) {
        const { hash, filename } = data.telechargerCdrGsm
        window.location.href = `${FILER_URL}?hash=${hash}&filename=${filename}`
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  const onClickTelechargerCdrGsm = (row) => {
    setRowActionClick(row)
    telechargerCdrGsm({
      variables: {
        id: row.id,
      },
    })
  }

  // Téléchargement Export Comptable
  const [telechargerExport, { loading: loadingExport }] = useLazyQuery(TELECHARGER_EXPORT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data.telechargerExport) {
        const { hash, filename } = data.telechargerExport
        window.location.href = `${FILER_URL}?hash=${hash}&filename=${filename}`
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  const onClickTelechargerExport = (row) => {
    setRowActionClick(row)
    telechargerExport({
      variables: {
        id: row.id,
      },
    })
  }

  useEffect(() => {

    if (selectAnnee) {
      setDateDebutAffiche(`${annee}-01-01`);
      setDateFinAffiche(`${annee}-12-31`);
    }

  }, [annee]);

  useEffect(() => {

    const filters: filterType[] = [{
      column: "dateFacture",
      operator: "BETWEEN",
      value: [dateDebutAffiche, dateFinAffiche]
    }]

    if (numcli) {
      filters.push({
        column: "contrats.numcli",
        value: numcli
      })
    }

    getFactures({
      variables: {
        includes: [
          {
            association: 'contrats',
            required: true,
          }
        ],
        filters,
        sorters: [
          {
            column: "dateFacture",
            order: "DESC"
          },
        ],
      },
    })

  }, [dateDebutAffiche, dateFinAffiche, numcli]);

  useEffect(() => {
    const datasTableFactures = data?.factures.items.map((item) => ({
      ...item,
      paye: item.paye === "OUI",
      nom: item.contrats.find((contrat) => contrat.numcli === item.numcli)?.nom,
      compte: item.compte.raisonSociale,
      colActions: [
        { libelle: p.t('factures.telechargerCdrFixe'), icon: (loadingCdrFixe && rowActionClick?.id === item.id) ? <Spinner size='h-4 w-4' borderWidth="xs" /> : <ArrowDownTrayIcon />, onclick: onClickTelechargerCdrFixe },
        { libelle: p.t('factures.telechargerCdrGsm'), icon: (loadingCdrGsm && rowActionClick?.id === item.id) ? <Spinner size='h-4 w-4' borderWidth="xs" /> : <ArrowDownTrayIcon />, tooltipContent: <div className='text-xs'>{p.t('prestations.gsm.actions.infoTelechargerCdr')}</div>, onclick: onClickTelechargerCdrGsm },
        { libelle: p.t('factures.telechargerExport'), icon: (loadingExport && rowActionClick?.id === item.id) ? <Spinner size='h-4 w-4' borderWidth="xs" /> : <ArrowDownTrayIcon />, onclick: onClickTelechargerExport },
      ],
    }))

    setTableDatas(datasTableFactures)
  }, [data, rowActionClick, loadingFacture, loadingCdrFixe, loadingCdrGsm, loadingExport]);

  // Définition des colonnes de la table Contacts :
  const columns = [
    {
      libelle: p.t('factures.columns.dateFacture'),
      code: 'dateFacture',
      colType: 'date',
    },
    {
      libelle: p.t('factures.columns.contrat'),
      code: 'numcli',
      colType: 'string',
    },
    {
      libelle: p.t('factures.columns.compte'),
      code: 'compte',
      colType: 'string',
    },
    {
      libelle: p.t('factures.columns.nomContrat'),
      code: 'nom',
      colType: 'string',
    },
    {
      libelle: p.t('factures.columns.facture'),
      code: 'facture',
      colType: 'string',
    },
    {
      libelle: p.t('factures.columns.montantHT'),
      code: 'mht',
      colType: 'money',
    },
    {
      libelle: p.t('factures.columns.montantTTC'),
      code: 'mtt',
      colType: 'money',
    },
    {
      libelle: p.t('factures.columns.paye'),
      code: 'paye',
      colType: 'boolean',
      visible: false
    },
    {
      libelle: "",
      code: 'actions',
      colType: 'iconAction',
      icon: <FileEarmarkPdfFill className='text-red-700' />,
      tooltipMessage: p.t('factures.telechargerFacture'),
      loading: loadingFacture,
      width: "40px",
      resizable: false,
      action: (row) => {
        onClickTelechargerFacture(row)
      }
    },
    {
      libelle: "",
      code: 'actions',
      colType: 'actions',
      width: "40px",
      resizable: false
    },
  ];

  const [selectedRows, setSelectedRows] = useState<Array<any>>([])

  return (
    <>
      <Table
        idTable='ec-table-facture'
        loading={loading}
        datas={tableDatas ? tableDatas : []}
        columns={columns}
        getSelectedRows={(rows) => { setSelectedRows(rows) }}
        rupture={{
          isVisible: (row, previousRow) => {
            return dayjs(row.dateFacture).month() !== dayjs(previousRow?.dateFacture).month()
          },
          value: (row) => {
            const value = dayjs(row.dateFacture).format("MMMM YYYY")
            return <div className='font-medium flex items-center  text-primary-700 bg-primary-50 py-2 px-2'>
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          }
        }}
        options={options}
        customToolbarActions={
          selectAnnee ?
            <>
              <div className='flex items-center w-full lg:w-auto space-x-2'>
                {selectedRows.length > 0 &&
                  <Button
                    size='small'
                    onClick={() => {
                      telechargerExport({
                        variables: {
                          id: selectedRows.map((row) => row.id),
                        },
                      })
                    }}
                    loading={loadingExport}
                    disabled={loadingExport}
                  >
                    <div className='flex items-center space-x-2 py-1'>
                      <DocumentArrowDownIcon className='h-4 w-4' />
                      <div>{p.t('factures.telechargerSelection')}</div>
                    </div>
                  </Button>
                }
                <Select
                  selectDatas={selectedDatasAnnees}
                  defaultValue={{ code: annee, libelle: annee.toString() }}
                  onSelect={(annee) => setAnnee(parseInt(annee.code))}
                  className="w-full lg:w-[100px]"
                  buttonClassName="text-center"
                  size='base'
                />
              </div>
            </> :
            <></>
        }
      />
    </>
  )

} 