import React, { useContext, useEffect, useState } from 'react'
import Header from './Header/Header'
import { ClientJS } from 'clientjs';

import navigation from '../datas/navigation'
import { Outlet } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_SESSION, IS_LOGGED_IN_QUERY } from '../queries'
import { authContext } from '../AuthContext'
import Spinner from '../Components/Spinner'
import { arrayEquals } from '../Components/Utils/Utils'
import HelpBox from './HelpBox/HelpBox'
import secureLocalStorage from 'react-secure-storage'
import SessionHandler from './SessionHandler';

const clientjs = new ClientJS();

export default function Shell() {

  const [isLoggedIn, { data: isLoggedInData }] = useLazyQuery(IS_LOGGED_IN_QUERY, {
    fetchPolicy: "network-only",
  });

  const [createSession, { loading: createSessionLoading }] = useMutation(CREATE_SESSION);

  const apiCheck = () => {
    isLoggedIn();
  }

  const traceSession = async () => {
    if (!sessionStorage.getItem('SessionId')) {

      const fingerprint = `${clientjs.getFingerprint()}`;

      const res = await createSession({
        variables: {
          input: {
            fingerprint
          }
        }
      });

      if (res.data?.createAppSession) {
        sessionStorage.setItem('SessionId', res.data.createAppSession.id)
      }
    }
  }

  const { setRoles, roles, setNotifications, notifications, loggedInUser, setLoggedInUser, setFeaturesFlag, featuresFlag, sitesRestreints, setSitesRestreints } = useContext(authContext);

  useEffect(() => {
    apiCheck();

    const interval = setInterval(() => {
      apiCheck();
    }, 30000);

    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    if (isLoggedInData != null && !isLoggedInData.isLoggedIn.success) {
      window.location.href = "/logout?all=true"
    }

    /** On met à jour les informations du compte */
    if (loggedInUser && isLoggedInData?.isLoggedIn?.compte) {
      secureLocalStorage.setItem(
        "loggedInUser",
        {
          ...loggedInUser,
          compte: isLoggedInData.isLoggedIn.compte
        }
      );

    }

    if (!arrayEquals(isLoggedInData?.isLoggedIn.roles, roles)) {
      setRoles(isLoggedInData?.isLoggedIn?.roles ? isLoggedInData.isLoggedIn.roles : null)
    }

    if (!arrayEquals(isLoggedInData?.isLoggedIn.loggedInUser.notifications, notifications)) {
      setNotifications(isLoggedInData?.isLoggedIn?.loggedInUser?.notifications)
    }

    if (!arrayEquals(isLoggedInData?.isLoggedIn.featuresFlag, featuresFlag)) {
      setFeaturesFlag(isLoggedInData?.isLoggedIn?.featuresFlag ? isLoggedInData.isLoggedIn.featuresFlag : null)
    }

    if (!arrayEquals(isLoggedInData?.isLoggedIn.sitesRestreints, sitesRestreints)) {
      setSitesRestreints(isLoggedInData?.isLoggedIn?.sitesRestreints ? isLoggedInData.isLoggedIn.sitesRestreints : null)
    }

    if (!createSessionLoading) {
      traceSession()
    }

  }, [isLoggedInData])

  return roles !== null ? (
    <>
      <div className="h-screen flex flex-col">
        <div className=''>
          <Header navigation={navigation} />
        </div>

        <main className="flex flex-row h-full overflow-hidden">
          <Outlet />
        </main>
      </div>
      <div>
        <HelpBox />
      </div >
      <SessionHandler />
    </>
  ) : <div className="h-screen w-screen flex flex-col items-center justify-center"><Spinner /></div>
}
