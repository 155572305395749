import { gql } from "@apollo/client";

const GET_TICKETS = gql`
  query Tickets($filters: [FilterInput], $sorters: [SorterInput]) {
    tickets(filters: $filters, sorters: $sorters) {          
      items {
        id
        idExterne
        numero
        titre
        description
        ticketCriticite {
          libelle
          backgroundColor
          fontColor
        }
        ticketStatut {
          id
          statutPublic
        }
      }
    }   
  }`

const GET_TICKET_STATUTS = gql`
  query TicketStatuts($filters: [FilterInput], $sorters: [SorterInput]){
    ticketStatuts(filters: $filters, sorters: $sorters) {
      items {
        id
        statutPublic      
      }
    } 
  }`


const GET_ABONNEMENTS_A_COMMANDER = gql`
  query abonnementsActifsACommander {
    abonnementsActifsACommander {
      idAbo
      groupe
      nomProduit
      quantite
      quantiteALivrer
      prixUnitaire
      reference
      description
      numcli
      site    
      nomContrat
      referenceDevis  
      bonDeCommandeModele {
        espaceClient
        fournisseur
        modele
        nom
      }  
    }
  }
`

export {
  GET_TICKETS,
  GET_TICKET_STATUTS,
  GET_ABONNEMENTS_A_COMMANDER
}