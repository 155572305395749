import { gql } from "@apollo/client";

const SAVE_PERSONNE = gql`
  mutation SavePersonne($personne: PersonneInput) {
    savePersonne(personne: $personne) {
      success
      message 
      personne{
        id
        nom
        prenom
        mail
        isActif
      }
    }
  }`;

const GET_PERSONNE = gql`
  query GetPersonne($idPersonne: ID!) {
    personne(idPersonne: $idPersonne) {
      totalCount
      items {
        id
        nom
        prenom
        mail
        isActif
        telephoneFixe
        telephoneMobile
        civilite {
          code
          libelle
        }
        poste {
          code
          libelle
        } 
      }    
    }
  }
`;

const GET_CONTACTS_ET_PERSONNES = gql`
  query GetContactsEtPersonnes(
    $pagination: PaginationInput
    $sorters: [SorterInput]
    $filters: [FilterInput]
  ) {
    contactsEtPersonnes(
      filters: $filters
      sorters: $sorters
      pagination: $pagination
    ) {
      totalCount
      items {
        id
        nom
        prenom
        mail
        isActif
        typeContact
      }
    }
  }
`;

const GET_PERSONNES = gql`
  query GetPersonnes(
    $pagination: PaginationInput
    $sorters: [SorterInput]
    $filters: [FilterInput]
  ) {
    personnes(
      filters: $filters
      sorters: $sorters
      pagination: $pagination
    ) {
      totalCount
      items {
        id
        nom
        prenom
        mail
        isActif
        telephoneFixe
        telephoneMobile
        civilite {
          code
          libelle
        }
        poste {
          code
          libelle
        } 
      }
    }
  }
`;

const CHECK_EMAIL = gql`
  query CheckEmailContactExiste($mail: String, $contactId: ID) {
    checkEmailContactExiste(mail: $mail, contactId: $contactId)
  }
`;

export {
  GET_PERSONNE,
  GET_PERSONNES,
  GET_CONTACTS_ET_PERSONNES,
  SAVE_PERSONNE,
  CHECK_EMAIL
}