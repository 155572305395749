import React from "react"
import TooltipInformation from "../../../../../Components/Tooltip/TooltipInformation";
import { useP } from "../../../../../services/i18n";
import ActionBonCommandePrompt from "./ActionBonCommandePrompt";

type ForfaitDatasType = { 
  siteId: string,
  label: string,
  value: string
}

type ActionChangerForfaitType = {
  action: string,
  livraisonId: number,
  prestationId: number,
  siteId: number,
  forfaits:Array<ForfaitDatasType>,
  forfait: any
}

function ActionChangerForfait({
  action,
  livraisonId,
  prestationId,
  siteId,
  forfaits,
  forfait,
}:ActionChangerForfaitType  ) {
  const p = useP();
  const datas = [
    {
      label: p.t('prestations.gsm.actions.promptChangerForfaitMemeSite'),
      index: 1,
      options: forfaits.filter((forfait)=>forfait.siteId===siteId.toString())
    },
    {
      label: p.t('prestations.gsm.actions.promptChangerForfaitAutresSites'),
      index: 2,
      options: forfaits.filter((forfait)=>forfait.siteId!==siteId.toString())
    }
  ].map((category) => {
    if (category.options.length === 0) {
      return { ...category, options: [{ label: category.index===1 
        ? p.t('prestations.gsm.actions.promptChangerForfaitMemeSiteNoResults') 
        : p.t('prestations.gsm.actions.promptChangerForfaitAutresSitesNoResults'), disabled: true }] 
      };
    }
    return category;
  })  

  return (
    <div className='flex items-center space-x-2'>
      <ActionBonCommandePrompt
        action={action}
        keyValue='abonnementId'
        livraisonId={livraisonId}
        label={p.t('prestations.gsm.actions.revaloriserLigne')}
        message={
        <div>
          <div>{p.t('prestations.gsm.actions.promptRevaloriserLigne')}</div>
          <div className="text-gray-500 text-sm">{forfait}</div>
        </div>
        }
        inputLabel={p.t('prestations.gsm.actions.promptRevaloriserLabel')}
        inputType='searchselect'
        inputPlaceholder={p.t('prestations.gsm.actions.promptChangerForfaitPlaceholder')}
        selectDatas={datas}
        prestationId={prestationId}
      />
      <TooltipInformation contenu={<div className='max-w-[350px]'>{p.t('prestations.gsm.actions.infoRevaloriserLigne')}</div>} />
    </div>
  )
}
export default ActionChangerForfait