import { Menu } from '@headlessui/react'
import React, { ReactNode } from 'react'

export type ItemDropdownItemType = {
  libelle: string | ReactNode,
  icon?: ReactNode,
  func?: (e: React.MouseEvent<HTMLDivElement>) => void
}

type DropdownItemType = {
  item: ItemDropdownItemType
}

function DropdownItem({ item }: DropdownItemType) {
  return (
    <Menu.Item>
      <div className="py-2 px-4 hover:bg-primary-100 text-primary-700 flex items-center justify-start space-x-2" onClick={(e) => item.func?.(e)}>
        {
          item.icon &&
          <div className='h-4 w-4'>{item.icon}</div>
        }
        <div className='text-sm'>{item.libelle}</div>
      </div>
    </Menu.Item >
  )

}

export default DropdownItem
// block px-4 py-2 text-sm text-primary-700