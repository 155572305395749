import { gql } from "@apollo/client";

const CHECK_AUTH = gql`
  mutation Authent($authentication: AuthenticationInput!) {
    authent(authentication: $authentication) {
      success
      duration
    }
  }
`;

const DATABASE_AUTH = gql`
  mutation Login($authentication: AuthenticationInput!) {
    login(authentication: $authentication) {
      success
      token
      type
      dateCreation
      dateExpiration
      user {
        id
        raisonSociale
        mail
        isMultiCompte
        nom
        prenom        
        telephoneFixe
        telephoneMobile
      }
      compte {
        id
        raisonSociale
        siren
        isVip
        comptesEnfant {
          id
          raisonSociale
        }
      }
    }
  }
`;

const SEND_RENEW_PASSWORD_REQUEST = gql`
  mutation SendRenewPasswordRequest($email: String!) {
    sendRenewPasswordRequest(email: $email) {
      success
      message
    }
  }
`;

const SEND_RENEW_PASSWORD = gql`
  mutation SendRenewPassword($renewPassword: RenewPasswordInput) {
    renewPassword(renewPassword: $renewPassword) {
      success
      message
    }
  }
`;

const CHECK_RENEW_PASSWORD = gql`
  query CheckRenewPassword($key: String) {
    checkRenewPassword(key: $key) {
      success
      message
    }
  }
`;

export { CHECK_AUTH, DATABASE_AUTH, SEND_RENEW_PASSWORD_REQUEST, SEND_RENEW_PASSWORD, CHECK_RENEW_PASSWORD };
