import React, { useContext, useEffect, useState } from "react"
import NewMessageToolbar from "./NewMessageToolbar/NewMessageToolbar";
import CCModule from "./NewMessageToolbar/CCModule";
import { toolbarNewMessageOptionsType } from "./NewMessageToolbar/NewMessageToolbarItem";
import Badge from "../Badge";
import { authContext } from "../../AuthContext";
import DivEditable from "../DivEditable";
import { FieldError } from 'react-hook-form';
import clsx from "clsx";

type NewMessageType = {
  toolbarNewMessageOptions: toolbarNewMessageOptionsType,
  saveMessage: (message: string, files: any[]) => Promise<boolean>,
  onChange?: (val) => void,
  onChangePJ?: (files: Array<any>) => void,
  displayAuteur?: boolean
  displaySendButton?: boolean,
  autoFocus?: boolean,
  textareaHeight?: string,
  placeholder?: string,
  loadingSendMessage?: boolean,
  cancelButton?: boolean,
  onCancel?: () => void,
  error?: FieldError,
  containerHeight: number

}

export default function NewMessage({
  toolbarNewMessageOptions,
  saveMessage,
  displayAuteur = true,
  displaySendButton = true,
  loadingSendMessage = false,
  onChange,
  onChangePJ,
  textareaHeight,
  placeholder = "Ecrire un nouveau message...",
  cancelButton = false,
  error,
  autoFocus,
  containerHeight,
  onCancel }: NewMessageType) {

  const { loggedInUser } = useContext(authContext);

  const [textValue, setTextValue] = useState<string>("");
  const [CCModuleIsOpen, setCCModuleIsOpen] = useState<boolean>(false);
  const [buttonEnable, setButtonEnable] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<Array<any>>([])

  const saveNewMessage = async (message: string) => {
    const success = await saveMessage(message, selectedFiles);
    if (success) {
      setTextValue("")
      setSelectedFiles([])
    }
  }

  const handleFilesSelected = (files) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removeFile = (index) => {
    const files = selectedFiles.filter((el, i) => i !== index)
    setSelectedFiles(files)
    onChangePJ?.(files)
  }

  useEffect(() => {
    setButtonEnable(textValue ? true : false)
  }, [textValue])

  const [focus, setFocus] = useState(autoFocus)

  return <>
    <div className="flex items-start space-x-2 h-full">
      {displayAuteur &&
        <div className=" flex item-center justify-center p-2 text-white rounded-md w-10 h-10 font-medium text-base bg-secondary-600 ">
          {`${loggedInUser?.user.prenom.substring(0, 1)}${loggedInUser?.user.nom.substring(0, 1)}`}
        </div>
      }

      <div className="w-full h-full">

        <div className={clsx(`flex-1 relative h-full`)}>
          <DivEditable
            placeholder={placeholder}
            onChange={(event) => {
              setTextValue(event.target["innerHTML"]);
              onChange?.(event.target["innerHTML"]);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            textareaHeight={textareaHeight}
            focus={focus}
            withFooterToolbar={true}
            containerHeight={toolbarNewMessageOptions.pj && selectedFiles.length > 0 ? containerHeight - 30 : containerHeight}
            margin={150}
          />
          <NewMessageToolbar
            displaySendButton={displaySendButton}
            onSendMessage={() => saveNewMessage(textValue)}
            buttonEnable={!loadingSendMessage && buttonEnable}
            toolbarNewMessageOptions={toolbarNewMessageOptions}
            onAddCc={() => setCCModuleIsOpen(true)}
            onAddPJ={(files) => {
              handleFilesSelected(files)
              onChangePJ?.(files)
            }}
            cancelButton={cancelButton}
            onCancel={onCancel}
            focus={focus}
          />
          {
            toolbarNewMessageOptions.pj && selectedFiles.length > 0 &&
            <>
              <div className='flex items-center flex-wrap space-x-2 space-y-1 mt-2'>
                {
                  selectedFiles.map((file, i) => {
                    return <Badge key={i} rounded={false} deleteAction={() => removeFile(i)} deletable itemColor='gray' itemLibelle={<><div>{file.name}</div></>} />
                  })
                }
              </div>
            </>
          }
        </div>

        {toolbarNewMessageOptions.cc && CCModuleIsOpen &&
          <CCModule tooltipContent="" inputPlaceholder="" />
        }

      </div>
      {
        error && <div className='text-red-600 text-xs font-medium mt-0.5'>{error.message}</div>
      }
    </div>

  </>
}