import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import PaginationPerPage from './PaginationPerPage'

type PaginationContainerType = {
  idTable: string,
  paginationRange: number[] | undefined,
  currentPage: number,
  optionsPageSize: number[],
  pageSize: number,
  nbResultats: number,
  onPrevious: () => void,
  onNext: () => void,
  onGoToPage: (npage: number) => void
  onSelectPageSize: (pageSize: number) => void
}

export default function PaginationContainer({
  idTable,
  paginationRange,
  currentPage,
  optionsPageSize,
  pageSize,
  nbResultats,
  onSelectPageSize,
  onPrevious,
  onNext,
  onGoToPage }: PaginationContainerType) {

  return (
    <div className=" flex flex-col items-end flex-auto pt-2 lg:flex-1 lg:flex-row lg:items-center lg:justify-between">
      <div className='px-1 flex mb-2 lg:mb-0'>
        <PaginationPerPage
          idTable={idTable}
          onSelectPageSize={onSelectPageSize}
          optionsPageSize={optionsPageSize}
          pageSize={pageSize}
          nbResultats={nbResultats}
        />
      </div>
      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <button
          className="relative inline-flex items-center px-1 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          onClick={onPrevious}
          disabled={currentPage === 1}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />

        </button>
        {paginationRange?.map((pageNumber, key) => {
          return (<button
            key={`${key}-${pageNumber}`}
            type='button'
            aria-current="page"
            className={clsx(
              `relative inline-flex items-center px-3 py-1 border text-sm font-medium`,
              currentPage == pageNumber ? `bg-primary-50 border border-primary-500 text-primary-600 z-10` : `border-gray-300 text-gray-500 hover:bg-gray-50`
            )}
            onClick={() => !isNaN(pageNumber) && onGoToPage(pageNumber)}
          >
            {pageNumber}
          </button>)
        })}
        <button
          type='button'
          className="relative inline-flex items-center px-1 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          onClick={onNext}
          disabled={paginationRange && currentPage === paginationRange[paginationRange.length - 1]}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </nav>
    </div>
  )
}