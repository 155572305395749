import React, { useContext, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import Button from '../../../Components/Button'
import ModaleCreationTicketBody from './ModaleCreationTicketBody'
import { useP } from '../../../services/i18n'
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from "yup";
export type typeNewTicketType = "DATA" | "VOIX" | "HEBERGEMENT" | null
import { useMutation } from '@apollo/client'
import { TICKET_OUVERTURE_CLIENT } from './queries'
import { toastError, toastSuccess } from '../../../Components/Utils/Utils'
import { ModalConfirmation } from '../../../Components/ModalDialog/ModalConfirmation'
import axios from 'axios'
import Constants from '../../../commons/Constants';
import { NEW_TICKET_COMMENTAIRE } from '../ModaleDetailTicket/queries'
import { authContext } from "../../../AuthContext";



const {
  FILER_URL_UPLOAD_SUPPORT
} = Constants;

type FormTicketType = {
  objet: string,
  criticite: string,
  impact: string,
  activiteTotaleImpactee: boolean
  sujet: string,
  description: string,
  contactSite: string,
  numContactSite: string,
  refInterne: string,
  sites: [{ label: string, value: string }],
  prestations: [{ label: string, value: string }],
  recurrence: boolean,
  ticketPrecedent: { label: string, value: string },
  nomContactSite: string,
  mailsSuiveurs: string,
  selectedFiles: any[],
  compte: { label: string, value: string } | null,
}

type ModaleCreationTicketType = {
  isOpen: boolean,
  onClose: () => void,
  typeNewTicket: typeNewTicketType
}

export default function ModaleCreationTicket({ isOpen, onClose, typeNewTicket }: ModaleCreationTicketType) {
  const { sitesRestreints } = useContext(authContext);
  const p = useP()
  const [confirmOnCloseOpen, setConfirmOnCloseOpen] = useState(false)
  const [validationButtonIsDisabled, setValidationButtonIsDisabled] = useState(false)

  const telRegEx = /^(?:\+|00)?(?:\d{1,3})?[-]?(?:(?:\(?\d{1,}\)?[-]?){0,2}(?:\d{1,}[-]?){1,2}\d{1,})$/;

  const validationSchema = yup.object().shape({
    objet: yup.string().trim().required(p.t('form.required')),
    criticite: yup.string().trim().required(p.t('form.required')),
    sujet: yup.string().trim().required(p.t('form.required')),
    description: yup.string().trim().required(p.t('form.required')),
    numContactSite: yup.string().trim().matches(telRegEx, p.t('form.phoneFormat')).required(p.t('form.required')),
    compte: yup.object().nullable().required(p.t('form.required')).typeError(p.t('form.required')),
    ...(sitesRestreints?.length ? { sites: yup.array().min(1, p.t('form.min')).required(p.t('form.required')) } : { sites: yup.array() })
  })

  const {
    setValue,
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
    reset
  } = useForm<FormTicketType>({
    defaultValues: {
      objet: "",
      criticite: "",
      impact: "",
      activiteTotaleImpactee: false,
      sujet: "",
      description: "",
      numContactSite: "",
      refInterne: "",
      sites: [],
      prestations: [],
      recurrence: false,
      ticketPrecedent: undefined,
      nomContactSite: '',
      mailsSuiveurs: '',
      selectedFiles: [],
      compte: null
    },
    resolver: yupResolver(validationSchema),
  });

  const resetForm = () => {
    reset({
      objet: "",
      criticite: "",
      impact: "",
      activiteTotaleImpactee: false,
      sujet: "",
      description: "",
      numContactSite: "",
      refInterne: "",
      sites: [],
      prestations: [],
      recurrence: false,
      ticketPrecedent: undefined,
      nomContactSite: '',
      mailsSuiveurs: '',
      selectedFiles: [],
      compte: null
    })
  }

  const [ouvertureTicketClient] = useMutation(TICKET_OUVERTURE_CLIENT, {
    refetchQueries: [
      'Tickets'
    ],
  })

  const [newTicketCommentaire] = useMutation(NEW_TICKET_COMMENTAIRE)

  const manageConfirmOnCloseModale = (action) => {
    setConfirmOnCloseOpen(false)
    if (action) {
      resetForm()
      onClose()
    }
  }

  const onCloseModale = () => {
    if (isDirty) {
      setConfirmOnCloseOpen(true)
    } else {
      resetForm()
      onClose();
    }
  }

  const onSubmit: SubmitHandler<FormTicketType> = async data => {
    setValidationButtonIsDisabled(true)
    const tabPrestations = data.prestations?.map((p) => p.value)
    const tabSites = data.sites?.map((s) => s.value)

    let service = '';
    switch (typeNewTicket) {
      case "VOIX":
        service = '1';
        break;
      case "DATA":
        service = '2';
        break;
      case "HEBERGEMENT":
        service = '3';
        break;
    }

    // Transformation des données reçues
    const dataSave = {
      qualificatif: data.objet,
      ticketCriticite: data.criticite,
      ticketImpact: ![undefined, ''].includes(data.impact) ? data.impact : null,
      activiteTotaleImpactee: data.activiteTotaleImpactee,
      titre: data.sujet,
      description: data.description,
      nomContactSite: data.nomContactSite ? data.nomContactSite : '',
      numeroContactSite: data.numContactSite,
      ticketService: service,
      referenceClient: data.refInterne,
      prestations: tabPrestations,
      sites: tabSites,
      isRecurrent: data.recurrence ? true : false,
      ticketPrecedent: data.ticketPrecedent ? data.ticketPrecedent.value : null,
      listeEmailsSuiveurs: data.mailsSuiveurs ? data.mailsSuiveurs : null,
      compteId: data.compte?.value
    };

    const files = data.selectedFiles;

    // eslint-disable-next-line no-useless-catch
    try {
      const resultTicket = await ouvertureTicketClient({
        variables: {
          ticket: dataSave
        }
      });

      const ticketId = resultTicket?.data?.ticketOuvertureClient?.ticket?.id;
      const numeroTicket = resultTicket?.data?.ticketOuvertureClient?.ticket?.numero;

      // Sauvegarde des fichiers
      let tabFiles = []
      if (ticketId && files.length > 0) {
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('files', f, encodeURIComponent(f.name));
        })
        formData.append('ticketId', ticketId.toString());

        const res = await axios.post(FILER_URL_UPLOAD_SUPPORT || "", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })

        if (res.status !== 200) {
          toastError(p.t('error.defaultMessage'));
          return false;
        }

        const { data } = res;
        tabFiles = data.files ? data.files.map((f: string) => decodeURIComponent(f)) : []
      }

      // Commentaire
      await newTicketCommentaire({
        variables: {
          ticketId: ticketId,
          commentaire: dataSave.description,
          files: tabFiles,
          activiteTotaleImpactee: dataSave.activiteTotaleImpactee
        }
      });

      toastSuccess(p.t('support.modaleCreation.sauvegardeOk', { numeroTicket }));
      resetForm()
      onClose();
      setValidationButtonIsDisabled(false)
    } catch (error) {
      throw error;
    }

  }

  return <>
    <Modal
      onClose={onCloseModale}
      isOpen={isOpen}
      content={{
        title: `${p.t('support.modaleCreation.title')} ${typeNewTicket}`,
        body: <ModaleCreationTicketBody
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
        />,
        footer: <div className='flex items-center justify-end'>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={validationButtonIsDisabled}
            loading={validationButtonIsDisabled}
          >
            {p.t('support.modaleCreation.action')}
          </Button>
        </div >,
      }}
      className="w-full lg:w-5/6"
      heightClass="h-[95%]"
    />
    <ModalConfirmation
      isOpen={confirmOnCloseOpen}
      closeIcon={false}
      message={<>{p.t("actions.confirmCloseModale1")}<br />{p.t("actions.confirmCloseModale2")}</>}
      onClose={(action) => manageConfirmOnCloseModale(action)}
    />
  </>
}
