import { gql } from "@apollo/client";

const GET_OTP_MODES = gql`
    query GetOtpModes {
        otpModes
    }
`;

const GET_OTP_APP_QR_CODE = gql`
    query Query {
        getOtpAppQrCode
    }
`;

const SEND_OTP = gql`
  mutation SendOtp($otpMode: OtpMode!) {
    sendOtp(otpMode: $otpMode) {
      success
      message
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      success
      message
    }
  }
`;

const CHANGE_OTP_MODE = gql`
  mutation ChangeOtp($otpMode: OtpMode!, $otp: String!) {
    changeOtpMode(otpMode: $otpMode, otp: $otp) {
      success
      message
    }
  }
`;

export {
  GET_OTP_MODES,
  GET_OTP_APP_QR_CODE,
  SEND_OTP,
  CHANGE_OTP_MODE,
  CHANGE_PASSWORD
}