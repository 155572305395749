import React, { useState } from 'react'
import { ButtonsGroupItemButtonType } from './ButtonsGroupItem'
import ButtonsGroupItems from './ButtonsGroupItems'


type ButtonsGroupVerticalType = {
  buttons: Array<ButtonsGroupItemButtonType>
  onClickRadio: (value: string) => void,
  selected: Array<string>
  multi: boolean,
  forceHorizontalOnMobile: boolean,
  selectAll: boolean,
  prepend?: JSX.Element,
}

export default function ButtonsGroupVertical({
  buttons,
  onClickRadio,
  selected,
  multi,
  prepend,
  forceHorizontalOnMobile,
  selectAll
}: ButtonsGroupVerticalType) {

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <div className='w-full max-h-[300px] overflow-y-auto'>
        {prepend
          ? <>
            <div onClick={toggleOpen} className='border border-gray-300 flex w-fit bg-gray-50 rounded-md'>{prepend}</div>
            {isOpen &&
              <div className='flex flex-col'>
                <ButtonsGroupItems
                  buttons={buttons}
                  onClickRadio={onClickRadio}
                  selected={selected}
                  multi={multi}
                  prepend={prepend ? true : false}
                  vertical={true}
                  forceHorizontalOnMobile={forceHorizontalOnMobile}
                  selectAll={selectAll}
                />
              </div>
            }
          </>
          : <><div className='flex flex-col'>
            <ButtonsGroupItems
              buttons={buttons}
              onClickRadio={onClickRadio}
              selected={selected}
              multi={multi}
              prepend={prepend ? true : false}
              vertical={true}
              forceHorizontalOnMobile={forceHorizontalOnMobile}
              selectAll={selectAll}
            />
          </div></>
        }
      </div>
    </>
  )

}