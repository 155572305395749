import React from "react";
import Tabs from "../../../../../Components/Tabs/Tabs";
type GSMDetailModaleBodyType = {
  tabsNavigation:any,
  tabsPanel:any
}
export default function GSMDetailModaleBody({tabsNavigation,tabsPanel}:GSMDetailModaleBodyType){
  return <>
    <Tabs
        navigation={tabsNavigation}
        panels={tabsPanel}
        cardStyle={false}
      />
  </>
}