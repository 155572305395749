import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { TreeMenuElementType } from './TreeMenu'

type TreeMenuItemChildType = {
  childItem: TreeMenuElementType,
  onActiveItem: (childItem: TreeMenuElementType) => void,
  active?: boolean
}

export default function TreeMenuItemChild({ childItem, onActiveItem, active }: TreeMenuItemChildType) {

  return (
    <>
      <Link
        className={clsx(
          `flex items-center px-2 mb-1  rounded-md cursor-pointer`,
          active ? `bg-secondary-100 hover:bg-secondary-100` : `hover:bg-gray-100`
        )}
        to={childItem.href ? childItem.href : "#"}
        onClick={() => {
          onActiveItem(childItem);
        }}
      >

        <div
          className={clsx(
            `flex-1 mr-1 py-2 text-sm font-normal text-gray-700 truncate uppercase`
          )} >{childItem.name}</div>
        {
          childItem.count ? (
            <span
              className={clsx(
                `bg-primary-200 text-primary-800 group`,
                `ml-3 inline-block py-0.5 px-2 text-xs font-medium rounded-full`
              )}
            >
              {childItem.count}
            </span>
          ) : null
        }
      </Link>
    </>)
}