import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import NavigationItem from './NavigationItem'
import { NavigationType } from '../../../types/NavigationType'
import { CompteType } from '../../../types/CompteType'
import CompteSelection from './CompteSelection/CompteSelection'
import { authContext } from '../../../AuthContext'
import { Bars3Icon } from '@heroicons/react/24/outline'
import DropdownMenu from '../../../Components/DropdownMenu/DropdownMenu'
import { useP } from '../../../services/i18n'
import { useNavigate } from 'react-router-dom'

type NavigationProps = {
  navigation: Array<NavigationType>
}

function Navigation({ navigation }: NavigationProps) {
  const p = useP();
  const navigate = useNavigate();
  const {
    selectedCompte,
    setSelectedCompte,
    loggedInUser
  } = useContext(authContext)

  const onChange = (values: string | Array<string>) => {
    setSelectedCompte(values)
  }

  const getComptes = (): Array<CompteType> => {

    if (loggedInUser?.user.isMultiCompte && loggedInUser.compte.compteParent && loggedInUser.compte.compteParent.comptesEnfant.length > 0) {
      return loggedInUser.compte.compteParent.comptesEnfant.sort((c1, c2) => {
        //On positionne le compte parent en premier
        if (loggedInUser?.compte?.compteParent?.id === c1.id)
          return -1;

        if (loggedInUser?.compte?.compteParent?.id === c2.id)
          return 1;

        return c1.raisonSociale > c2.raisonSociale ? 1 : -1
      });
    }
    else {
      return loggedInUser?.compte ? [loggedInUser?.compte] : []
    }
  }

  useEffect(() => {

    let selected;
    if (loggedInUser?.compte?.compteParent?.comptesEnfant) {
      const { compteParent } = loggedInUser.compte;

      if (!selectedCompte && compteParent.comptesEnfant.length) {
        selected = compteParent.comptesEnfant[0].id
      }
      else {
        const compte = compteParent.comptesEnfant.find((record) => record.id === selectedCompte)
        const defaultCompte = compteParent.comptesEnfant.find((record) => record.id === loggedInUser?.compte?.id) ? loggedInUser?.compte?.id : compteParent.comptesEnfant[0].id;
        selected = compte ? compte : defaultCompte;
      }
      setSelectedCompte(selected.id);
    }

  }, [loggedInUser]);

  const checkSelectedCompte = () => {

    const compteIds: string[] = [];

    const comptePrincipal: any = loggedInUser?.compte?.id;
    const comptesEnfant = loggedInUser?.compte?.compteParent?.comptesEnfant;
    let defaultCompte = comptePrincipal;

    if (comptesEnfant && comptesEnfant.length > 0) {
      defaultCompte = comptesEnfant.find((record) => record.id === comptePrincipal)
        ? comptePrincipal
        : comptesEnfant[0].id;

      if (Array.isArray(selectedCompte)) {
        selectedCompte.forEach((compteId) => {
          const res: any = comptesEnfant.find((compte) => compte.id === compteId);
          compteIds.push(res ? res.id : defaultCompte);
        });
      } else {
        const res: any = comptesEnfant.find((compte) => compte.id === selectedCompte);
        compteIds.push(res ? res.id : defaultCompte);
      }
    } else {
      compteIds.push(comptePrincipal);
    }

    // Check if the arrays are equal
    const arraysAreEqual = JSON.stringify(compteIds.sort()) === (Array.isArray(selectedCompte) ? JSON.stringify(selectedCompte.sort()) : JSON.stringify([selectedCompte]));

    if (!arraysAreEqual) {
      setSelectedCompte(defaultCompte);
    }
  };

  useEffect(() => {
    if (selectedCompte) {
      checkSelectedCompte();
    }
  }, [selectedCompte]);

  // Gestion menu
  const navRef = useRef<any>(null);
  const overflowMenuRef = useRef<any>(null);
  const [visibilityMap, setVisibilityMap] = useState({});
  const [overflowMenu, setOverflowMenu] = useState<Array<any>>([]);
  const handleIntersection = (entries) => {
    const updatedEntries = {};
    entries.forEach((entry) => {
      const targetid = entry.target.dataset.targetid;
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries
    }));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1,
      rootMargin: "0px 0px 0px 0px"
    });

    Array.from(navRef.current.children).forEach((item: any) => {
      if (item.dataset.targetid) {
        observer.observe(item);
      }
    });
    return () => observer.disconnect();
  }, [navigation]);

  const shouldShowMenu = useMemo(
    () => Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap]
  );
  useEffect(() => {
    const falseKeys = Object.entries(visibilityMap)
      .filter(([, value]) => !value)
      .map(([key]) => key);

    const overflowItems = navigation.filter(item => falseKeys.includes(item.name))

    const overflowMenuArray: Array<any> = overflowItems.map((item) => ({
      libelle: p.t(`${item.name}`),
      func: () => navigate(`${item.href}`)
    }))
    setOverflowMenu(overflowMenuArray)
  }, [visibilityMap])

  useEffect(() => {
    // Positionnement du menu overflow
    if (navRef.current && overflowMenuRef.current) {
      const element = navRef.current.children[navigation.length - overflowMenu.length - 1];
      overflowMenuRef.current.style.left = `${element?.offsetLeft + element?.offsetWidth + 8}px`;
    }
  }, [overflowMenu])



  return (
    <>
      <div className="flex-1 px-1 lg:flex-initial lg:px-0">
        <CompteSelection comptes={getComptes()} onChange={onChange} fieldLabel="raisonSociale" fieldValue="id" value={selectedCompte} />
      </div>
      <div className="hidden lg:block overflow-hidden pl-2 pr-4">
        <div className="flex items-center leading-tight space-x-2 lg:space-x-2 ">
          <div className='flex items-center space-x-2 overflow-hidden' ref={navRef}>
            {
              navigation.map((item, index) => {
                const visibility = overflowMenu.some(o => o.libelle === p.t(`${item.name}`))
                return <NavigationItem key={index} item={item} visibility={!visibility} />
              })
            }
          </div>
          {/* <Search /> a réactiver plus tard */}
        </div>

        <div className='hidden lg:block absolute top-2.5' ref={overflowMenuRef}>
          {
            shouldShowMenu &&
            <DropdownMenu items={overflowMenu} buttonComponent={
              <div className='p-2 rounded-md hover:bg-primary-600'><Bars3Icon className='text-white h-5 w-5' /></div>
            } />
          }
        </div>
      </div>
    </>
  )
}

export default Navigation