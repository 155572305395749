import clsx from 'clsx'
import React, { ReactNode } from 'react'
import Card from './Card'



type BannerContentType = {
  children: JSX.Element[] | JSX.Element,
  itemsCenter?: "lg:items-center" | "lg:items-start" | "lg:items-end",
  className?: string
}
export function BannerLeft({ children }: BannerContentType) {
  return <>{children}</>
}
export function BannerRight({ children }: BannerContentType) {
  return <>{children}</>
}

Banner.Left = BannerLeft;
Banner.Right = BannerLeft;

function Banner({ children, itemsCenter = "lg:items-center", className = '' }: BannerContentType) {
  return (
    <>
      <div className={className}>
        <Card
          className={{ root: "mb-4 flex-none p-2" }}       
          bgClass="bg-primary-800"   
        >
          <div className={clsx(`flex flex-col lg:flex-row`, itemsCenter)}>
            {children}
          </div>
        </Card>
      </div>
    </>
  )

}

export default Banner