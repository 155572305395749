import React, { useState } from 'react'
import SwitchIcon, { SwitchIconDataType } from '../../../../Components/SwitchIcon/SwitchIcon'
import FilterTable from '../../../../Components/Table/FilterTable';

type filtresDefType = {
  libelle: string,
  value: any
}

export type ButtonsGroupFilterType = {
  libFiltre: string,
  filtres: Array<filtresDefType>
}

type ToolbarActionsPrestationLienType = {
  datasSwitchIcon: Array<SwitchIconDataType>,
  onSelectSwitchIcon: (value: SwitchIconDataType) => void,
  updateSelectedFilters: (filters: any) => void,
  buttonsGroup: Array<ButtonsGroupFilterType>,
  selectedSwitchIcon: number,
  siteId: string | number | null | undefined,
  selectedFilters: Array<ButtonsGroupFilterType>,
}

export default function ToolbarActionsPrestationLien({
  datasSwitchIcon,
  onSelectSwitchIcon,
  updateSelectedFilters,
  buttonsGroup,
  selectedSwitchIcon,
  siteId,
  selectedFilters
}: ToolbarActionsPrestationLienType) {


  const [activeFilters, setActiveFilters] = useState(selectedFilters)

  const updateSelectedButtons = (value, key) => {
    const newState = activeFilters.map((filter) => {
      if (filter.libFiltre == key) {
        return { ...filter, filtres: value }
      }
      return filter
    })
    setActiveFilters(newState)
    updateSelectedFilters(newState)
  }

  return <>
    <div className='flex flex-col '>
      <div className='flex lg:items-center items-end justify-end space-x-2 w-full lg:w-auto'>
        {
          selectedSwitchIcon == 1 &&
          <div className='flex items-center'>
            <FilterTable
              datas={buttonsGroup}
              onSelectedButtonGroup={(value, key) => updateSelectedButtons(value, key)}
              activeFilters={activeFilters.reduce((acc, item) => { acc[item.libFiltre] = item.filtres; return acc }, {})}
            />
          </div>
        }

        {!siteId && <div className='mt-2 lg:ml-3 lg:mb-0 lg:mt-0'>
          <SwitchIcon defaultItem={datasSwitchIcon[selectedSwitchIcon]} datas={datasSwitchIcon} onSelect={(item) => onSelectSwitchIcon(item)} />
        </div>}
      </div>


    </div>
  </>

}