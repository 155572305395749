import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import React, { EventHandler, useEffect, useState } from 'react'
export type SwitchType = {
  name: string,
  className?: string,
  label?: string,
  value: boolean,
  bordered?: boolean,
  disabled?: boolean,
  onChange(value: boolean): any,
  onClick?: (state: boolean) => void,
  labelPosition?: "before" | "after",
  manualOnChange?: boolean
}
export default function InputSwitch({ name, label, className, value, onChange, onClick, disabled, labelPosition = "after", bordered = false, manualOnChange = false }: SwitchType) {

  const [checked, setChecked] = useState(value)

  useEffect(() => {
    setChecked(value)
  }, [value])



  return <>
    <Switch.Group as="div" className={clsx(`flex items-center`, className)} >
      {
        (label && labelPosition === "before") && <Switch.Label as="span" className="mr-3">
          <span className="block text-sm font-medium text-gray-700 cursor-pointer">{label}</span>
        </Switch.Label>
      }
      <Switch
        name={name}
        checked={checked}
        onChange={(checked) => {
          if (!disabled) {
            !manualOnChange && setChecked(!!checked);
            onChange(checked)
          }
        }}
        onClick={() => onClick?.(checked)}
        className={clsx(
          disabled ? `cursor-not-allowed` : ``,
          checked ? 'bg-primary-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-5 w-9 border-2 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
          bordered ? checked ? `border-primary-600` : `border-gray-300` : `border-transparent`,
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            checked ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            //bordered ? `border-red-300` : `border-transparent `
          )}
        />
      </Switch>
      {
        (label && labelPosition === "after") && <Switch.Label as="span" className="ml-3">
          <span className="block text-sm font-medium text-gray-700">{label}</span>
        </Switch.Label>
      }
    </Switch.Group>
  </>
}