import chroma from "chroma-js";

function generateColorPalette(numColors, baseColors) {
  if (numColors <= baseColors.length) {
    return baseColors.slice(0, numColors);
  }

  const palette = [...baseColors];

  const steps = numColors - baseColors.length;

  for (let i = 0; i < steps; i++) {
    const baseColor = baseColors[i % baseColors.length];
    const index = i % 3; // Alterne entre la saturation, la luminosité et la teinte
    const variationAmount = (i % 2 === 0 ? 1 : -1) * (i / steps);

    let modifiedColor = chroma(baseColor);

    if (index === 0) {
      modifiedColor = modifiedColor.saturate(variationAmount);
    } else if (index === 1) {
      modifiedColor = modifiedColor.darken(variationAmount);
    } else {
      modifiedColor = modifiedColor.set('hsl.h', (modifiedColor.get('hsl.h') + (variationAmount * 60)) % 360);
    }

    palette.push(modifiedColor.hex());
  }

  return palette;
}

const chartsBaseColors = ['#3b82f6', '#ec4899', '#14b8a6', '#f59e0b', '#a855f7', '#facc15'];

export {
  generateColorPalette,
  chartsBaseColors
}