import React, { useEffect, useState } from "react";

import { toastError, toastSuccess } from "../Components/Utils/Utils";
import { CHECK_RENEW_PASSWORD, SEND_RENEW_PASSWORD } from "./queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string'
import { useP } from "../services/i18n";
import ChangePasswordContainer from "./ChangePasswordContainer/ChangePasswordContainer";
import Spinner from "../Components/Spinner";

function ChangePassword() {

  const p = useP();


  const params = queryString.parse(location.search);


  const [password, setPassword] = useState<string>('');
  const [checkPassword, setCheckPassword] = useState<string>('');
  const [passwordLevel, setPasswordLevel] = useState<number>(0);

  const [sendRenewPassword] = useMutation(SEND_RENEW_PASSWORD);

  const [checkKey, { loading: checkKeyLoading }] = useLazyQuery(CHECK_RENEW_PASSWORD, {
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();

  const submitRenewPassword = () => {

    if (password !== checkPassword) {
      toastError(p.t('changePassword.toasts.changePassword.notSamePasswords'));
      return
    }

    if (passwordLevel == 0) {
      toastError(p.t('changePassword.toasts.changePassword.passwordWeak'));
      return
    }

    sendRenewPassword({
      variables: {
        renewPassword: {
          key: params.key,
          password
        },
      },
    })
      .then(({ data }) => {

        const { renewPassword } = data;

        if (renewPassword.success) {
          toastSuccess(p.t('changePassword.toasts.changePassword.success'));
        } else {
          toastError(p.t('changePassword.toasts.changePassword.error'));
        }

        navigate(p.t('router.login.route'));
      })
      .catch(() => {
        toastError(p.t('changePassword.toasts.changePassword.error'));
        navigate(p.t('router.login.route'))
      });
  }

  const checkRenewPassword = () => {
    checkKey({
      variables: {
        key: params.key,
      },
      onCompleted: (data) => {
        if (!data.checkRenewPassword.success) {
          toastError(p.t('changePassword.toasts.checkKey.error'))
          navigate(p.t('router.login.route'))
        }
      },
      onError: () => {
        toastError(p.t('changePassword.toasts.checkKey.error'))
        navigate(p.t('router.login.route'))
      }
    });
  }

  // useEffect(() => {

  // }, [password, checkPassword])

  useEffect(() => {
    checkRenewPassword();
  }, [])

  return (
    <>
      <div className="min-h-full flex bg-white">
        {
          checkKeyLoading ?
            (<div className="w-screen h-screen flex items-center justify-center"><Spinner /></div>) :
            (<ChangePasswordContainer submit={submitRenewPassword} password={password} setPassword={setPassword} checkPassword={checkPassword} setCheckPassword={setCheckPassword} passwordLevel={passwordLevel} setPasswordLevel={setPasswordLevel} />)
        }

      </div>
    </>
  );
}

export default ChangePassword;
