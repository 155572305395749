import React from 'react'
import Card from '../Card'
import TreeMenuItem from './TreeMenuItem'
import TreeMenuMobile from './TreeMenuMobile'

export type TreeMenuElementType = {
  name: string,
  href?: string,
  icon?: any,
  count?: number,
  current?: boolean,
  children?: Array<TreeMenuElementType> | undefined | null,
  view?: string,
}

type TreeMenuType = {
  datas: Array<TreeMenuElementType>,
  onActiveItemMenu: (activeItem: TreeMenuElementType | null | undefined) => void,
  activeItemMenu: string,
  className?: string
}

export default function TreeMenu({
  datas,
  onActiveItemMenu,
  activeItemMenu,
  className = ''
}: TreeMenuType) {

  return <>
    <div className={className}>
      <Card className={{ root: "hidden lg:block w-full h-full" }}>
        <nav className="flex-1 px-0 space-y-1 overflow-y-auto" aria-label="Sidebar">
          {datas.map((item, i) => (
            <TreeMenuItem
              item={item}
              key={i}
              onActiveItemMenu={onActiveItemMenu}
              activeItemMenu={activeItemMenu}
            />
          ))}
        </nav>
      </Card>
    </div>
    <div className="block lg:hidden">
      <TreeMenuMobile datas={datas} activeItemMenu={activeItemMenu} onActiveItemMenu={onActiveItemMenu} />
    </div>
  </>
}