import React, { useEffect, useState } from 'react'
import ToolbarSearch from '../../../../Components/Toolbar/ToolbarSearch'
import Toolbar from '../../../../Components/Toolbar/Toolbar'
import { useP } from '../../../../services/i18n'
import MapBoxClusters from '../../../../Components/MapBox/MapBoxClusters'
import { LayerProps } from 'react-map-gl'
import PopUpInfoMapLien from './PopUpInfoMapLien'
import { features } from 'process'
import { datasGeojsonReducer } from './datasGeojsonReducer'
import Spinner from '../../../../Components/Spinner'
import bbox from '@turf/bbox';

type MapLiensType = {
  toolbar?: JSX.Element | null,
  datas: any,
  loading: boolean,
  disableScrollZoom?: boolean
}

export default function MapLiens({ toolbar = null, datas, loading, disableScrollZoom }: MapLiensType) {
  const p = useP();

  const SOURCEID = "liens"

  const [mapDatas, setMapDatas] = useState({})

  useEffect(() => {
    if (!loading) {
      setMapDatas(datasGeojsonReducer(datas))
    }
  }, [datas])

  const CONNECTED_COLOR = "#059669";
  const DISCONNECTED_COLOR = "#dc2626";
  const MIXED_COLOR = "#f97316";
  const NO_SUPERVISION_COLOR = "#6b7280"

  // Expressions
  const connected = ['all', ['==', 'CONNECTE', ["get", "statutCluster"]]]
  const disconnected = ['all', ['==', 'DECONNECTE', ["get", "statutCluster"]]]
  const mixed = ['any', ['==', 'MIXTE', ["get", "statutCluster"]]];

  // Cluster properties
  const clusterProperties = {
    isConnecte: ['all', ['case', connected, true, false]],
    isDeconnecte: ['all', ['case', disconnected, true, false]],
    isMixte: ['all', ['case', mixed, true, false]],
  }

  // Layers
  const clusterLayer: LayerProps = {
    id: 'clusters',
    type: 'circle',
    source: SOURCEID,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color':
        ["case",
          ['all', ['!=', ["get", 'isConnecte'], false]],
          CONNECTED_COLOR,
          ['all', ['!=', ["get", 'isDeconnecte'], false]],
          DISCONNECTED_COLOR,
          MIXED_COLOR
        ],
      'circle-stroke-color': ["case",
        ['all', ['!=', ["get", 'isConnecte'], false]],
        CONNECTED_COLOR,
        ['all', ['!=', ["get", 'isDeconnecte'], false]],
        DISCONNECTED_COLOR,
        MIXED_COLOR
      ],
      'circle-radius':
        ['step',
          ['get', 'point_count'],
          20, 100,
          30, 750,
          40
        ],
      'circle-stroke-width': 2,
      'circle-opacity': 0.9
    }
  };

  const clusterCountLayer: LayerProps = {
    id: 'cluster-count',
    type: 'symbol',
    source: SOURCEID,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 12
    },
    paint: {
      "text-color": "#FFF"
    }
  };

  const unclusteredPointLayer: LayerProps = {
    id: 'unclustered-point',
    type: 'circle',
    source: SOURCEID,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-radius': 8,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff',
      'circle-opacity': 0.7,
      'circle-color': [
        'match',
        ["get", "statut"],
        "CONNECTE",
        CONNECTED_COLOR,
        "MIXTE",
        MIXED_COLOR,
        "DECONNECTE",
        DISCONNECTED_COLOR,
        "NO_SUPERVISION",
        NO_SUPERVISION_COLOR,
        "#000"
      ]
    }
  };
  const mapLegend = <div className='space-y-1'>
    <div className=''>
      <div className='flex items-center mb-1'>
        <div className=' flex items-center justify-center rounded-full h-3 w-3 bg-emerald-500 text-white text-[10px] font-medium'></div>
        <div className='text-xs text-gray-700 ml-2'>{p.t("lien.map.legende.connecte")}</div>
      </div>
      <div className='flex items-center mb-1'>
        <div className=' flex items-center justify-center rounded-full h-3 w-3 bg-orange-500 text-white text-[10px] font-medium'></div>
        <div className='text-xs text-gray-700 ml-2'>{p.t("lien.map.legende.mixte")}</div>
      </div>
      <div className='flex items-center mb-1'>
        <div className=' flex items-center justify-center rounded-full h-3 w-3 bg-red-500 text-white text-[10px] font-medium'></div>
        <div className='text-xs text-gray-700 ml-2'>{p.t("lien.map.legende.deconnecte")}</div>
      </div>
      <div className='flex items-center'>
        <div className=' flex items-center justify-center rounded-full h-3 w-3 bg-gray-500 text-white text-[10px] font-medium'></div>
        <div className='text-xs text-gray-700 ml-2'>{p.t("lien.map.legende.no_supervision")}</div>
      </div>
    </div>
  </div >

  const [popUpProperties, setPopUpProperties] = useState<any>(null)

  const setDatasProperties = (datasProperties) => {
    setPopUpProperties(datasProperties);
  }

  return <>
    <div className='flex flex-col h-full'>
      {toolbar &&
        <Toolbar>
          <div className='ml-0 mb-2 lg:mb-0 w-full lg:w-auto'>
            <div className='text-primary-700 text-md font-medium'>{p.t("lien.map.mainTitle")}</div>
          </div>
          {toolbar}
        </Toolbar>
      }
      <div className='w-full h-full flex items-center justify-center flex-1'>
        {
          loading
            ? <Spinner color='primary' />
            : <MapBoxClusters
              datas={mapDatas}
              sourceId={SOURCEID}
              clusterLayer={clusterLayer}
              clusterCountLayer={clusterCountLayer}
              unclusteredPointLayer={unclusteredPointLayer}
              clusterProperties={clusterProperties}
              legend={mapLegend}
              setPopUpProperties={(datasPopUp) => setDatasProperties(datasPopUp)}
              popUpContent={<PopUpInfoMapLien popupInfo={popUpProperties} />}
              disableScrollZoom={disableScrollZoom}
            />
        }
      </div>
    </div>

  </>
}