import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Button from '../../../../Components/Button';
import Spinner from '../../../../Components/Spinner';
import { useP } from '../../../../services/i18n';
import { GET_OTP_APP_QR_CODE } from '../../queries';

type OtpModeQrCodeType = {
    setCanContinue: (value: boolean) => void
}

function OtpModeQrCode({ setCanContinue }: OtpModeQrCodeType) {

    const p = useP();

    const { data: getQrCodeData, loading: getQrCodeLoading } = useQuery(GET_OTP_APP_QR_CODE)

    useEffect(() => {
        setCanContinue(true)
    }, [])

    return (
        <div className='space-y-6'>
            <div className='space-y-4'>
                <div className='text-gray-800 font-medium'>{p.t('profil.tabs.securite.otpSelection.downloadApp')}</div>

                <div className='text-gray-800 font-medium flex flex-col justify-around space-y-2'>
                    <a className="flex-1" href="https://www.microsoft.com/fr-fr/security/mobile-authenticator-app" target="_blank" rel="noreferrer"><Button className='w-full' color='primaryLight'>{p.t('profil.tabs.securite.otpApp.microsoftAuthenticator')}</Button></a>
                    <a className="flex-1" href="https://support.google.com/accounts/answer/1066447?hl=fr&co=GENIE.Platform%3DAndroid" target="_blank" rel="noreferrer"><Button className='w-full' color='secondaryLight'>{p.t('profil.tabs.securite.otpApp.googleAuthenticator')}</Button></a>
                    <a className="flex-1" href="https://www.cisco.com/c/fr_fr/products/security/adaptive-multi-factor-authentication.html" target="_blank" rel="noreferrer"><Button className='w-full' color='grayBordered'>{p.t('profil.tabs.securite.otpApp.duo')}</Button></a>
                </div>
            </div>
            <div className=''>
                <div className='text-gray-800 font-medium'>{p.t('profil.tabs.securite.otpSelection.openApp')}</div>
            </div>
            <div>
                <div className='text-gray-800 font-medium'>{p.t('profil.tabs.securite.otpSelection.qrCode')}</div>

                <div className='w-full flex justify-center'>
                    {getQrCodeLoading ?
                        <Spinner /> :
                        (
                            <div>
                                <img src={getQrCodeData?.getOtpAppQrCode} className="w-40" />
                            </div>
                        )}
                </div>
            </div>


        </div>
    )

}

export default OtpModeQrCode;