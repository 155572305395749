import { gql } from "@apollo/client"

const TICKET_CLOTURE_RATING = gql`
  mutation clotureRating($ticketRating: TicketRatingInput, $ticketId: ID!) {
    saveTicketRating(ticketRating: $ticketRating) {
      commentaire
      id
      isModifiableClient
      rating
    }
    ticketClotureClient(ticketId: $ticketId) {
      message
      success
    }
  }
`

const TICKET_CLOTURE = gql`
  mutation clotureRating($ticketId: ID!) {
    ticketClotureClient(ticketId: $ticketId) {
      message
      success
    }
  }
`

const TICKET_RATING = gql`
  mutation rating($ticketRating: TicketRatingInput) {
    saveTicketRating(ticketRating: $ticketRating) {
      commentaire
      id
      isModifiableClient
      rating
    }
  }
`

export {
  TICKET_CLOTURE_RATING,
  TICKET_RATING,
  TICKET_CLOTURE
}