import React, { Fragment, useState } from 'react'
import SlideOverSearch from './SlideOverSearch'
import SlideOverList from './SlideOverList'
import { SlideOverItemAll, SlideOverItemType } from './SlideOverItemType'
import MobileSlideOver from './MobileSlideOver'
import DesktopSlideOver from './DesktopSlideOver'

type SlideOverType = {
  records: Array<SlideOverItemType>
  rightContainer?: React.ComponentType<any>
  allItemsMenu?: SlideOverItemAll,
  filter?: (record: any, searchValue: string) => boolean,
  placeholder?: string,
  defaultItemId?: number | string
}

export default function SlideOver({ records, rightContainer, allItemsMenu, filter, placeholder, defaultItemId = 0 }: SlideOverType) {

  const [searchValue, setSearchValue] = useState('');

  const filterRecord = () => {
    const filtered = records.filter(value =>
      filter ?
        filter(value, searchValue) :
        value.title.toUpperCase().includes(searchValue.toUpperCase().trim().trim().replace(/[\s]{2,}/g, " ")))
    return filtered;
  }

  return (
    <>
      <MobileSlideOver>
        <SlideOverSearch value={searchValue} onChange={setSearchValue} placeholder={placeholder} />
        <SlideOverList records={filterRecord()} rightContainer={rightContainer} allItemsMenu={allItemsMenu} defaultItemId={defaultItemId} />
      </MobileSlideOver>
      <DesktopSlideOver>
        <SlideOverSearch value={searchValue} onChange={setSearchValue} placeholder={placeholder} />
        <SlideOverList records={filterRecord()} rightContainer={rightContainer} allItemsMenu={allItemsMenu} defaultItemId={defaultItemId} />
      </DesktopSlideOver>
    </>
  )
}
