import React, { useEffect, useState } from 'react'
import Button from '../../Button';
import Spinner from '../../Spinner';
import Tooltip from '../../Tooltip/Tooltip';

type CellActionIconFormatterType = {
  row: any,
  action: (row: any) => void,
  icon: JSX.Element,
  tooltipMessage?: string | null,
  loading?: boolean
  //func: React.MouseEventHandler<HTMLDivElement>
}

export default function CellActionIconFormatter({ row, action, icon, tooltipMessage = null, loading = false }: CellActionIconFormatterType) {

  const [clickedRow, setClickedRow] = useState<any>(null)
  const onClickIcon = () => {
    action(row)
    setClickedRow(row)
  }

  useEffect(() => {
    !loading && setClickedRow(null)
  }, [loading])


  return <>
    {tooltipMessage
      ? <Tooltip
        button={
          <div className="cursor-pointer group flex justify-center" onClick={(e) => {
            onClickIcon()
            e.stopPropagation()
          }}>
            {
              loading && clickedRow
                ? <Spinner size='h-3 w-3' borderWidth="xs" />
                : <Button color='ghost'>{icon}</Button>
            }
          </div>
        }
        content={<div className='text-xs'>{tooltipMessage}</ div>}
        placement='top'
        className='flex items-center justify-center'
      />
      : <>
        <div className="relative z-10 cursor-pointer group flex justify-center" onClick={(e) => {
          onClickIcon();
          e.stopPropagation()
        }}>
          {
            loading && clickedRow
              ? <Spinner size='h-3 w-3' borderWidth="xs" />
              : <Button color='ghost'>{icon}</Button>
          }
        </div>
      </>
    }
  </>
}