import React from 'react'
import { DocumentTextIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { typeRoleType } from '../RoleType';
import Constants from '../../../../../commons/Constants';
import RolesListItemsActionsAddButton from './RolesListItemsActionsAddButton';

const {
  SAISIE_CONTRAT,
  SAISIE_SITE
} = Constants;

type RolesListItemsActionsAddType = {
  onClick: (event: React.MouseEvent<HTMLDivElement>, type: string) => void,
  typeRole: typeRoleType,
  multicompte: boolean
}

export default function RolesListItemsActionsAdd({ onClick, typeRole, multicompte }: RolesListItemsActionsAddType) {
  return (
    <>
      {
        !multicompte &&
        <div className='flex items-center'>
          {typeRole.saisieContrat &&
            <RolesListItemsActionsAddButton onClick={onClick} scope={SAISIE_CONTRAT} icon={DocumentTextIcon} />
          }
          {typeRole.saisieSite &&
            <RolesListItemsActionsAddButton onClick={onClick} scope={SAISIE_SITE} icon={BuildingOfficeIcon} />
          }
        </div>
      }

    </>)

}