import React from "react";
import { useP } from "../../../../services/i18n";
import HelpBoxAcordions from "../../HelpBoxAccordions/HelpBoxAccordions";
import HelpContentFactures_Q1 from "./HelpContent/HelpContentFactures_Q1";

export default function HelpFactures(){
  const p = useP();
  const datas = [{
    libelle : p.t('helpBox.helpFactures.q1'),
    content : <HelpContentFactures_Q1 />
  }]
  return <>
      <HelpBoxAcordions datas={datas} />
  </>
}