
import React from "react"
import { Radar } from "react-chartjs-2"
import chroma from "chroma-js"

type RadarChartType = {
  datasetIdKey: any,
  data: any,
  options: any,
  colorPalette?: Array<string>
}

export default function RadarChart({ datasetIdKey, data, options, colorPalette }: RadarChartType) {

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
  }
  const chartData = data;
  const alphaColorTab = colorPalette?.map((color) => {
    const alphaColor = chroma(color).alpha(0.2)
    return alphaColor.hex()
  })
  chartData.datasets = chartData.datasets.map((d, i) => {
    return {
      ...d,
      borderColor: colorPalette ? colorPalette[i] : null,
      backgroundColor: colorPalette ? alphaColorTab : null
    };
  });
  return <>
    <div className="flex-1 h-full">
      <Radar
        options={{ ...defaultOptions, ...options }}
        datasetIdKey={datasetIdKey}
        data={data}
      />
    </div>
  </>
}