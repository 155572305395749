import { gql } from "@apollo/client";

const COMMANDER_LIGNE = gql`
  mutation SubmitCommandeArrow($bonCommandeInput: BonCommandeAbonnementInput) {
    submitCommandeArrow(bonCommandeInput: $bonCommandeInput) {
      message
      success
      codeErreur
    }
  }`;

const GET_ABONNEMENT_LIVRAISONS = gql`
  query AbonnementLivraisons($filters: [FilterInput], $includes: [IncludeInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    abonnementLivraisons(filters: $filters, includes: $includes, sorters: $sorters, pagination: $pagination) {
      items {
        id
        prestations{
          id
          etat
        }
      }
    }
  }
`

const GET_OPTIONS = gql`
  query JoursFeries($abonnementId: ID) {
    abonnement(id: $abonnementId) {
      nomProduit
      quantite
      devis{
        client{
          compte{
            id
          }
        }
      }
      abonnementGroupe {
        site {
          id
          nom
          codePostal
          commune  
        }
      }
      abonnementGroupe {
        site {
          id
          nom
          codePostal
          commune  
        }
      }
      abonnementDeploiement {
        id
      }
    }
  }
`

export {
  COMMANDER_LIGNE,
  GET_ABONNEMENT_LIVRAISONS,
  GET_OPTIONS,
}