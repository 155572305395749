import { ChevronRightIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import React from 'react'
import Badge from '../../../Components/Badge'
import Banner from '../../../Components/Banner'
import Card from '../../../Components/Card'
import List from '../../../Components/List/List'
import { useP } from '../../../services/i18n'

export default function Devis(datas) {

  const p = useP();

  const lstDevis: any[] = []
  datas.datas.map((element, index) => {
    const date = element.opportunite?.dateSignature ? element.opportunite.dateSignature : element.dateCreation;
    lstDevis.push({
      id: index,
      libelle: <div className="w-full flex">
        <div className="flex flex-1 items-center justify-between w-full pr-2">
          <div className="flex items-start flex-col flex-1">
            <div className="flex items-center flex-1 w-full">
              <div className="flex items-center flex-1">
                <div className='text-secondary-500 text-xs'>{date ? dayjs(date).format("DD/MM/YYYY") : ''}</div>
                {date ? <div className='h-1 w-1 bg-gray-400 rounded-full mx-1'></div> : ''}
                <div className=' text-primary-700 text-xs'>{element.reference}</div>
              </div>
            </div>
            <div className="text-xs text-gray-500">{element.opportunite?.nom ? element.opportunite.nom : element.projetNom}</div>
          </div>
          <div className='text-xs text-sky-600 px-1.5 bg-sky-100 rounded-full ml-2'>{element.opportunite?.type}</div>
          {/* <div className=""><ChevronRightIcon className="h-4 w-4" /></div> */}
        </div>
      </div>
    })
  })

  return <>
    <Card className={{ root: `h-full mb-4` }}>
      <Card.Header className='text-lg'>{p.t('contrat.titleDevis')}</Card.Header>
      <div className="lg:overflow-y-auto overflow-x-hidden mt-2 mx-2">
        <div className=""><List datas={lstDevis} canDeleteItem={false} /></div>
      </div>
    </Card>
  </>
}