import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client";
import Constants from "../../../commons/Constants";
import ButtonsGroup from "../../../Components/ButtonsGroup/ButtonsGroup";
import Table from "../../../Components/Table/Table";
import { TableOptionsType } from "../../../Components/Table/Types/TableOptionsType";
import { colorStateTicket, getLocalStorage, setLocalStorage } from "../../../Components/Utils/Utils";
import { useP } from "../../../services/i18n";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { GET_TICKETS, GET_TICKET_STATUTS } from "./queries";
import WidgetLayout from "../WidgetLayout/WidgetLayout";
import WidgetSize from "../WidgetSize";
import WidgetElementType from "../WidgetElementType";
import ModaleDetailTicket from "../../../Shell/Support/ModaleDetailTicket/ModaleDetailTicket";

const {
  TICKET_STATUT_ID_EN_COURS,
  TICKET_STATUT_ID_EN_ATTENTE_CLIENT,
  TICKET_STATUT_ID_EN_ATTENTE_FOURNISSEUR,
  TICKET_STATUT_ID_NOUVEAU,
  TICKET_STATUT_ID_OBSERVATION,  
} = Constants;

export default function WidgetTicketsEnCoursWide(props: WidgetElementType) {

  const p = useP();
  const idTableFiltre = 'ec-table-tickets-filtre'
  
  const tableOptionsDefault: TableOptionsType = {
    colSelect: false,
    pagination: false,
    search: false,
    fullBordered: false,
    resizable: false,
    naturalWidth: true,
    toolbar: false
  }

  // table data des tickets
  const columnsTickets = [
    {
      libelle: p.t('dashboardCompte.tickets.columns.statut'),
      code: 'statut',
      colType: 'state',
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.numero'),
      code: 'numero',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.compte'),
      code: 'compte',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('dashboardCompte.tickets.columns.sujet'),
      code: 'titre',
      colType: 'string',
      sortable: true
    },
    {
      libelle: "",
      code: "detail",
      colType: 'iconAction',
      icon: <ArrowTopRightOnSquareIcon className='h-5 w-5 text-gray-500 group-hover:text-secondary-500 mx-2' />,
      action: (item) => {
        setTicketIdSelected(item.numero)
      },
      tooltipMessage: p.t('dashboardCompte.voirTicket')
    }
  ]


  const { loading: loadingTicketStatuts, data: dataTicketStatuts } = useQuery(GET_TICKET_STATUTS, {
    variables: {
      filters: [
        {
          column: "isClosDefinitif",
          value: "0"
        }
      ],
      sorters: [
        {
          column: "ordreClient"
        }
      ]
    }
  })

  const { loading: loadingTickets, data: dataTickets, error: errorTicket } = useQuery(GET_TICKETS, {
    variables: {
      filters: [
        {
          column: "ticketStatut.isClosDefinitif",
          value: "0"
        },
      ],
      sorters: [
        {
          column: "ticketStatut.ordreClient"
        }
      ]
    },
  });

  const [datasTicketsFilter, setDatasTicketsFilter] = useState([])
  const [filterButtonsStatuts, setFilterButtonsStatuts] = useState<any[]>([]);
  const [filterStatut, setFilterStatut] =
    useState<any>(getLocalStorage(idTableFiltre)
      ? getLocalStorage(idTableFiltre).filterStatut
      : [`${TICKET_STATUT_ID_EN_COURS},${TICKET_STATUT_ID_OBSERVATION},${TICKET_STATUT_ID_EN_ATTENTE_FOURNISSEUR}`, `${TICKET_STATUT_ID_EN_ATTENTE_CLIENT}`, `${TICKET_STATUT_ID_NOUVEAU}`]);
  const [ticketIdSelected, setTicketIdSelected] = useState<number | null>(null)


  useEffect(() => {
    if (!loadingTicketStatuts) {

      const status: any[] = [];

      dataTicketStatuts.ticketStatuts.items.forEach((s) => {
        if (status[s.statutPublic]) {
          status[s.statutPublic].push(s.id)
        } else {
          status[s.statutPublic] = [s.id]
        }
      })

      // Filtres statut
      const filters: any[] = []
      for (const key in status) {
        filters.push({
          libelle: key, value: status[key].join(",")
        })
      }
      setFilterButtonsStatuts(filters)
    }
  }, [dataTicketStatuts, loadingTicketStatuts])

  useEffect(() => {
    const datas =
      (!loadingTickets && !errorTicket && dataTickets?.tickets?.items) ? dataTickets.tickets.items.map((t) => ({
        ...t,
        compte: t.compte.raisonSociale,
        statut: {
          libelle: t.ticketStatut.statutPublic,
          colorClasses: `bg-${colorStateTicket(t.ticketStatut.id)}-200 text-${colorStateTicket(t.ticketStatut.id)}-800`
        }
      })) : []

    // On applique le filtre        
    setDatasTicketsFilter(datas.filter((t) =>
      filterStatut?.find((f) => f.includes(t.ticketStatut.id))
    ))

    setLocalStorage(idTableFiltre, {
      filterStatut
    });

  }, [dataTickets, errorTicket, loadingTickets, filterStatut])

  return (
    <WidgetLayout
      header={
        <div className="flex flex-col items-start lg:flex-row lg:items-center justify-between mt-0">
          <div>{p.t('dashboardCompte.ticketsEnCours')}</div>
          <div className="flex items-center">
            <ButtonsGroup
              selectedButtons={filterStatut}
              onSelectedButton={(value) => setFilterStatut(value)}
              buttons={filterButtonsStatuts}
              multi={true}
            />
          </div>
        </div>
      }
      size={WidgetSize.WIDE}
      addWidget={props.addWidget}
      moveUpWidget={props.moveUpWidget}
      moveDownWidget={props.moveDownWidget}
      removeWidget={props.removeWidget}
    >
      <div className="h-[250px]">
        <Table
          idTable="ec-tickets_en_cours"
          loading={loadingTickets}
          columns={columnsTickets}
          datas={datasTicketsFilter}
          options={tableOptionsDefault}
          onClickRow={(row)=>setTicketIdSelected(row.id)}
        />
        <ModaleDetailTicket isOpen={ticketIdSelected ? true : false} onClose={() => setTicketIdSelected(null)} ticketId={ticketIdSelected} />
      </div>

    </WidgetLayout>
  )

}