import React from 'react'
import { useP } from '../../../services/i18n'
export type typeNewTicketType = "DATA" | "VOIX" | "HEBERGEMENT" | null
import { useLazyQuery } from '@apollo/client'
import { GET_TICKETS } from './queries'
import InputSelect from '../../../Components/Form/InputSelect/InputSelect'

export default function InputSelectTicket({ ...field }) {

  const p = useP();

  const [getTickets] = useLazyQuery(GET_TICKETS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const promiseOptionsTicket = async (filter: string) => {

    const filterTicket = filter ? [{
      column: "titre",
      operator: "LIKE",
      value: `%${filter}%`,
      or: [
        {
          column: "numero",
          operator: "LIKE",
          value: `%${filter}%`,
        },
      ]
    }] : []

    return getTickets({
      variables: {
        filters: filterTicket,
        pagination: {
          limit: 100
        },
        sorters: [
          {
            column: "dateCreation",
            order: "DESC"
          }
        ],
      }
    }).then((result) => {
      const { data } = result;
      return data.tickets.items.map(t => ({
        value: t.id,
        label: `${t.numero} : ${t.titre}`
      }));
    })
  }

  return <InputSelect
    promiseOptions={promiseOptionsTicket}
    isMulti={false}
    placeholder={``}
    label={`${p.t('support.modaleCreation.ticketPrecedent')}`}
    menuPlacement="top"
    {...field}
  />

}
