import clsx from 'clsx'
import React, { ReactNode, TdHTMLAttributes } from 'react'
import { TableOptionsType } from './Types/TableOptionsType'

type TdType = {
  value?: string | ReactNode,
  colspan?: number | undefined,
  children?: ReactNode,
  className?: string,
  width?: string | number | null,
  initialWidth?: string | number | null,
  tableOptions: TableOptionsType,
  valign?:"middle" | "top" | "bottom" | "baseline" | undefined,
  noPadding?:boolean
}

function Td({ value, colspan = undefined, children, className, width, initialWidth, tableOptions,valign='middle', noPadding=false }: TdType) {
  return (
    <>
      <td
        valign={valign}
        colSpan={colspan}
        className={clsx(
          !tableOptions.resizable ? `table-cell` : ``,
          `border-b border-gray-200 text-sm text-gray-500`,
          noPadding ? `p-0` : `px-2 py-2`,
          className)}
        style={
          initialWidth && !width
            ? { width: `${initialWidth}` }
            : { width: width === 'auto' ? `auto` : `${width}px` }
        }
      >
        {
          tableOptions.naturalWidth
            ? <div>{value ? value : children}</div>
            : <div style={
              initialWidth && !width
                ? { width: `${initialWidth}` }
                : { width: width === 'auto' ? `auto` : `${width}px` }
            }>{value ? value : children}</div>
        }
      </td>
    </>
  )

}

export default Td