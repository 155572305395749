import React from 'react'
import { useP } from '../../services/i18n'
import ShellLayout from '../ShellLayout';
import ContratsContainer from './ContratsContainer';

function Contrats() {
  const p = useP();

  const breadcrumbDatas = [
    {
      libelle: p.t('contrats.mainTitle'),
      href: "#",
      current: true
    }
  ]

  return (
    <ShellLayout>
      <ContratsContainer breadcrum={breadcrumbDatas} />
    </ShellLayout>
  )

}

export default Contrats;