import Constants from '../commons/Constants';
import { NavigationType } from '../types/NavigationType';

const {
  ROLE_ACCES_PRESTATION,
  ROLE_ACCES_CONTRAT,
  ROLE_ACCES_FACTURATION,
  ROLE_ACCES_ANNUAIRE,
  ROLE_ACCES_SUPPORT,
  ROLE_ACCES_ESPACE_CLIENT,  
  FEATURE_FLAG_KPI_CYBER
} = Constants;

function getNavigation(): Array<NavigationType> {

  return [
    { name: "navigation.accueil.label", href: "navigation.accueil.href", default: true, comptePrefix: true, role: ROLE_ACCES_ESPACE_CLIENT, end:true },
    { name: "navigation.prestations.label", href: "navigation.prestations.href", default: true, comptePrefix: true, role: ROLE_ACCES_PRESTATION, end:false },
    { name: "navigation.contrat.label", href: "navigation.contrat.href", comptePrefix: true, role: ROLE_ACCES_CONTRAT, end:false },
    { name: "navigation.factures.label", href: "navigation.factures.href", comptePrefix: true, role: ROLE_ACCES_FACTURATION,  end:true },
    { name: "navigation.annuaire.label", href: "navigation.annuaire.href", comptePrefix: true, role: ROLE_ACCES_ANNUAIRE, end:true },
    { name: "navigation.support.label", href: "navigation.support.href", comptePrefix: true, role: ROLE_ACCES_SUPPORT, end:true },
    { name: "navigation.documentation.label", href: "navigation.documentation.href", comptePrefix: true, role: ROLE_ACCES_ESPACE_CLIENT, end:false },
    { name: "navigation.cyber.label", href: "navigation.cyber.href", comptePrefix: true, role: ROLE_ACCES_ESPACE_CLIENT, featureFlag: FEATURE_FLAG_KPI_CYBER, end:true }
  ]
}

const navigation = getNavigation();

export default navigation
