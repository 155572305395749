import React, { useContext } from "react";
import Badge from "../../../Components/Badge";
import { useP } from "../../../services/i18n";
import { colorStateTicket, datetimeFormatter } from "../../../Components/Utils/Utils";
import Constants from "../../../commons/Constants";
import { authContext } from "../../../AuthContext";
import { ArrowTopRightOnSquareIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";

type ticketStatutType = {
  id: number,
  statutPublic: string,
}

type ticketType = {
  id: number,
  numero: string,
  titre: string,
  description: string,
  ticketStatut: ticketStatutType
  dateCreation: string
  dateCloture: string
}

type StatTicketsType = {
  ticket: ticketType
}

const {
  SUPPORT_URL
} = Constants;


function StatTickets({ ticket }: StatTicketsType) {
  console.log(ticket)
  const p = useP();
  const { loggedInUser } = useContext(authContext);

  return (
    <>
      <div className="flex-1">
        <div className="text-lg font-semibold leading-5 text-primary-700 flex flex-col md:flex-row md:items-start md:justify-between">
          <div className="mr-3 flex flex-col md:flex-row md:items-start">
            <div>
              {p.t('lien.ticket')} {ticket.numero} - {ticket.titre}
            </div>
            <div className="flex items-center md:ml-3 mt-1 md:mt-0">
              <Badge
                itemLibelle={ticket.ticketStatut.statutPublic}
                itemColor={colorStateTicket(ticket.ticketStatut.id)}
              />
            </div>
          </div>

          <div className="flex items-center">
            <div className="md:ml-4 mt-2 md:mt-0">
              <div className="flex items-center border border-gray-200 px-1 py-1 rounded-md cursor-pointer hover:bg-gray-100"
                onClick={() => window.location.replace(`${SUPPORT_URL}/auth?token=${loggedInUser?.token}&redir=/support/ticket/${ticket.numero}`)}>
                <ArrowTopRightOnSquareIcon className='h-4 w-4 text-gray-400 group-hover:text-secondary-500' />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2 md:mt-0.5">
          <div className="flex-1 flex flex-col md:flex-row md:items-center">
            <div className="flex items-center text-sm font-medium md:mr-4">
              <div className="mr-1.5">
                <CalendarDaysIcon className="h-3 w-3" />
              </div>
              <div className="text-xs">
                {p.t('lien.ouvertLe')} {datetimeFormatter(ticket.dateCreation)}
                {ticket.dateCloture ? ` - ${p.t('lien.closLe')} ${datetimeFormatter(ticket.dateCloture)}` : ''}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2 md:mt-0.5">
          <div className="flex-1 flex flex-col md:flex-row md:items-center">
            <div className="flex items-center font-medium md:mr-4 text-xs" style={{ whiteSpace: "pre-wrap" }}>
              {ticket.description}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StatTickets;
