import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal/Modal';
import ModaleDetailTicketBody from './ModaleDetailTicketBody';
import ModaleDetailTicketTitle from './ModaleDetailTicketTitle';
import ModaleDetailTicketFooter from './ModaleDetailTicketFooter';
import { useLazyQuery } from '@apollo/client';
import Error from '../../../Components/Error/Error';
import { GET_TICKET } from './queries';
import { useNavigate } from 'react-router-dom';
import { ModalConfirmation } from '../../../Components/ModalDialog/ModalConfirmation';
import { useP } from '../../../services/i18n';

type ModaleDetailTicketType = {
  isOpen: boolean,
  onClose: () => void,
  ticketId?: number | null | undefined
}

function ModaleDetailTicket({ isOpen, onClose, ticketId }: ModaleDetailTicketType) {

  const p = useP();
  const navigate = useNavigate();

  const [confirmOnCloseOpen, setConfirmOnCloseOpen] = useState<{ open: boolean, ticketPrecedentId: null | number }>({ open: false, ticketPrecedentId: null })
  const [isWritingNewCommentaire, setIsWritingNewCommentaire] = useState(false)
  const [ticketPrecedentIds, setTicketPrecedentIds] = useState<number[]>([]);
  const [ticketIdEnCours, setTicketIdEnCours] = useState<number | null | undefined>(ticketId);

  const onCloseModal = () => {
    if (isWritingNewCommentaire) {
      setConfirmOnCloseOpen({ open: true, ticketPrecedentId: null })
    } else {
      onCloseAction();
    }
  }

  const onCloseModalConfirm = (action: boolean) => {
    const ticketId = confirmOnCloseOpen.ticketPrecedentId;
    setConfirmOnCloseOpen({ open: false, ticketPrecedentId: null })
    if (action) {
      if (ticketId) {
        clickTicketPrecedentAction(ticketId);
      } else {
        onCloseAction();
      }
    }
  }

  const onCloseAction = () => {
    if (ticketPrecedentIds.length === 0) {
      onClose();
      setTicketPrecedentIds([])
    } else {
      const nextTicket = ticketPrecedentIds[ticketPrecedentIds.length - 1]
      setTicketPrecedentIds(ticketPrecedentIds.slice(0, ticketPrecedentIds.length - 1));
      setTicketIdEnCours(nextTicket)
    }
  }

  const onChangeNewCommentaire = (commentaire: string, files: any[]) => {
    setIsWritingNewCommentaire(commentaire !== '' || files.length > 0)
  }

  const onClickTicketPrecedent = (ticketPrecedentId: number) => {
    if (isWritingNewCommentaire) {
      setConfirmOnCloseOpen({ open: true, ticketPrecedentId })
    } else {
      clickTicketPrecedentAction(ticketPrecedentId)
    }
  }

  const clickTicketPrecedentAction = (ticketPrecedentId: number) => {
    if (ticketIdEnCours) {
      setTicketPrecedentIds([
        ...ticketPrecedentIds,
        ticketIdEnCours
      ])
    }

    navigate(ticketPrecedentId)
    setTicketIdEnCours(ticketPrecedentId)
  }

  const [getTicket, { loading: loadingTicket, data: dataTicket, error: errorTicket, refetch: refetchTicket }] = useLazyQuery(GET_TICKET, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (isOpen && ticketIdEnCours) {
      getTicket({
        variables: {
          ticketId: ticketIdEnCours
        },
      });
    }
  }, [ticketIdEnCours, isOpen])

  useEffect(() => {
    setTicketIdEnCours(ticketId)
  }, [ticketId])

  return <>
    <Modal
      onClose={onCloseModal}
      isOpen={isOpen}
      content={{
        title: dataTicket?.ticket ? <ModaleDetailTicketTitle ticket={dataTicket.ticket} /> : <></>,
        body: errorTicket || (!loadingTicket && !dataTicket?.ticket) ?
          <Error /> :
          <>
            <ModaleDetailTicketBody
              ticket={dataTicket?.ticket}
              loading={loadingTicket}
              refetchTicket={refetchTicket}
              onChangeNewCommentaire={onChangeNewCommentaire}
              onClickTicketPrecedent={onClickTicketPrecedent}
            />
            <ModalConfirmation
              isOpen={confirmOnCloseOpen.open}
              closeIcon={false}
              message={<>{p.t("actions.confirmCloseModale1")}<br />{p.t("actions.confirmCloseModale2")}</>}
              onClose={(action) => onCloseModalConfirm(action)}
            />
          </>,
        footer: <ModaleDetailTicketFooter onClickFermer={() => onCloseModal()} />,
      }}
      className="w-full h-full min-h-full lg:w-11/12 lg:m-4"
      noPadding
    />

  </>;
}


export default ModaleDetailTicket