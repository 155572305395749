import React from "react";
import {
  FileImageFill,
  FilePdfFill,
  FileRuledFill,
  FileSlidesFill,
  FileTextFill,
  FileWordFill,
  FileZipFill,
} from "react-bootstrap-icons";

type FileTypeIconType = {
  extension: string,
  className: string
}

function FileTypeIcon({ extension, className }: FileTypeIconType) {
  const getIcon = (extension) => {
    switch (extension.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
        return <FileImageFill className={className} />;
      case "pdf":
        return <FilePdfFill className={className} />;

      case "doc":
      case "docx":
        return <FileWordFill className={className} />;

      case "csv":
      case "xls":
      case "xlsx":
        return <FileRuledFill className={className} />;
      case "zip":
      case "rar":
        return <FileZipFill className={className} />;
      case "ppt":
      case "pptx":
        return <FileSlidesFill className={className} />;
      case "txt":
      default:
        return <FileTextFill className={className} />;
    }
  };

  const icon = getIcon(extension);

  return icon;
}

export default FileTypeIcon;

