import React from 'react'
import clsx from "clsx";
import PropTypes from "prop-types";
import { operatorType } from "../Types/filterTypes";

type OperatorSelectType = {
  id: number | string,
  checked: boolean,
  onSelectOperator: () => void,
  operator: operatorType,
  label?: string | null,
}

function OperatorSelect({
  id,
  checked,
  onSelectOperator,
  operator,
  label = null,
}: OperatorSelectType) {
  return (
    <div className={clsx(checked && "mb-2", "flex items-center text-sm")}>
      <input
        id={`side-${id}`}
        name="plan"
        type="radio"
        checked={checked}
        onChange={onSelectOperator}
        className="focus:ring-transparent h-4 w-4 text-primary-600 border-gray-300"
      />
      <span
        className={clsx(
          checked ? "text-primary-900" : "text-gray-900",
          "ml-3 font-medium"
        )}
      >
        {label ? label : operator.label}
      </span>
    </div>
  );
}

OperatorSelect.propTypes = {
  id: PropTypes.number,
  checked: PropTypes.bool,
  operator: PropTypes.object,
  label: PropTypes.string,
  onSelectOperator: PropTypes.func,
};

export default OperatorSelect;
