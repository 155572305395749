import React, { useContext, useEffect } from 'react';
import './App.css';

import { Navigate, Route, BrowserRouter as Router, Routes, Outlet } from 'react-router-dom';

import PrivateRoute from './routes/PrivateRoute'
import Shell from './Shell/Shell';

import Support from './Shell/Support/Support';
import { Toaster } from 'react-hot-toast';
import Login from './Login/Login';
import Compte from './Shell/Compte/Compte';
import { useP } from './services/i18n';
import { useAuth } from "./UseAuthContext";
import { authContext } from './AuthContext';
import Logout from './Logout/Logout';
import Constants from './commons/Constants';
import { useCheckRight } from './Components/CheckRight/CheckRight';
import ChangePassword from './ChangePassword/ChangePassword';
import SatisfactionProjet from './SatisfactionProjet/SatisfactionProjet';
import ReleaseNotes from './Shell/ReleaseNotes/ReleaseNotes';
import Supervision from './Supervision/Supervision';
import Lien from './Shell/Lien/Lien';
import Prestations from './Shell/Prestations/Prestations';
import Contrats from './Shell/Contrats/Contrats';
import Contrat from './Shell/Contrats/Contrat/Contrat';
import Factures from './Shell/Factures/Factures';
import Contacts from './Shell/Contacts/Contacts';
import DashboardCompte from './Shell/Compte/DashboardCompte/DashboardCompte';
import TicketPublic from './TicketPublic/TicketPublic';
import Cyber from './Shell/Cyber/Cyber';
import Documentation from './Shell/Documentation/Documentation';
import DocumentationRedirect from './Shell/Documentation/DocumentationRedirect';
import { useCheckFeatureFlag } from './Components/CheckFeatureFlag/CheckFeatureFlag';
import FaviconManager from './Components/FaviconManager';
import { applyTheme } from './themes/utils';
import { getDataFromHostname } from './utils/utils';

const {
  ROLE_ACCES_SUPPORT,
  ROLE_ACCES_PRESTATION,
  ROLE_ACCES_CONTRAT,
  ROLE_ACCES_FACTURATION,
  ROLE_ACCES_ANNUAIRE,
  FEATURE_FLAG_KPI_CYBER,  
} = Constants;

function AppRouter() {

  const p = useP();

  const {
    loggedInUser,    
  } = useContext(authContext)

  const getDefaultRoute = () => {
    return `/${p.t("router.compte.route")}`;
  }

  const { checkRight } = useCheckRight();
  const { checkFeatureFlag } = useCheckFeatureFlag();

  const getCompteRoutes = () => {

    return (
      <>
        { /** Lien */}
        {checkRight(ROLE_ACCES_PRESTATION) ?
          <Route path={p.t("router.compte.lien.route")}>
            <Route path=":prestationId" element={<Lien />} />
          </Route> : null
        }

        {checkRight(ROLE_ACCES_PRESTATION) ?
          <Route path={p.t("router.compte.prestations.route")} element={<Outlet />}>
            <Route path={p.t("router.compte.prestations.GSM.route")} element={<Prestations view="GSM" />} />
            <Route path={p.t("router.compte.prestations.VGA.route")} element={<Prestations view="VGA" />} />
            <Route path={p.t("router.compte.prestations.NDI.route")} element={<Prestations view="NDI" />} />
            <Route path={p.t("router.compte.prestations.CENTILE.route")} element={<Prestations view="CENTILE" />} />
            <Route path={p.t("router.compte.prestations.TRUNK.route")} element={<Prestations view="TRUNK" />} />
            <Route path={p.t("router.compte.prestations.lien.route")} element={<Prestations view="LIEN" />} />
            <Route path={p.t("router.compte.prestations.vcd.route")} element={<Prestations view="VCD" />} />
            <Route path={p.t("router.compte.prestations.vcc.route")} element={<Prestations view="VCC" />} />
            <Route index element={<Navigate to={p.t("router.compte.prestations.lien.route")} />} />
          </Route> : null
        }

        { /** Contrat */}
        {checkRight(ROLE_ACCES_CONTRAT) ?
          < Route path={p.t("router.compte.contrats.route")} element={<Outlet />}>
            <Route index element={<Contrats />} />
            <Route path=":numcli" element={<Contrat />} />
          </Route> : null
        }


        { /** Factures */}
        {checkRight(ROLE_ACCES_FACTURATION) ?
          <Route path={p.t("router.compte.factures.route")} element={<Factures />} /> : null
        }

        { /** Annuaire */}
        {checkRight(ROLE_ACCES_ANNUAIRE) ?
          <Route path={p.t("router.compte.annuaire.route")} element={<Contacts />} /> : null
        }

        {/** Support */}
        {checkRight(ROLE_ACCES_SUPPORT) ?
          <Route path={p.t("router.compte.support.route")} element={<Support />} >
            <Route path=":ticketId" element={<Support />} />
          </Route> : null
        }

        { /** Documentation */}
        <Route path={p.t("router.compte.documentation.route")} element={<Outlet />} >
          <Route index element={<Documentation />} />
          <Route path="*" element={<Documentation />} />
        </Route>
          
        { /** Cyber */}
        {checkFeatureFlag(FEATURE_FLAG_KPI_CYBER) ?
          <Route path={p.t("router.compte.cyber.route")} element={<Cyber />} /> : null
        }
      </>
    )
  }

  const redirectSupport = () => {
    return <Navigate to={`${getDefaultRoute()}/${loggedInUser?.compte.id}/${p.t("router.compte.support.route")}`} />
  }

  return (
    <>
      <Router>

        <Routes>

          <Route path={p.t("router.login.route")} element={<Login />} />

          <Route path={p.t("router.changePassword.route")} element={<ChangePassword />} />

          <Route path={p.t("router.supervision.route")} element={<Supervision />} />

          <Route path={p.t("router.logout.route")} element={<Logout />} />

          <Route path={p.t("router.satisfactionProjet.route")} element={<SatisfactionProjet />} />

          <Route path={p.t("router.ticketPublic.route")} element={<TicketPublic />} />

          <Route path="/" element={<PrivateRoute loggedInUser={loggedInUser}><Shell /></PrivateRoute>}>

            { /** Compte */}
            <Route path={p.t("router.compte.route")} element={<Outlet />}>

              <Route path=":compteId" element={<Compte />}>

                {getCompteRoutes()}

                <Route index element={<DashboardCompte />} />
              </Route >

              <Route index element={<Compte />} />
            </Route >

            <Route path={p.t("router.comptes.route")} element={<Outlet />}>

              <Route path=":comptePrincipalId" element={<Compte multicompte={true} />}>

                {getCompteRoutes()}

                <Route index element={<DashboardCompte />} />
              </Route>

              <Route index element={<Compte multicompte={true} />} />
            </Route >

            { /** Documentation */}
            <Route path={p.t("router.compte.documentation.route")} >
              <Route path="*" element={<DocumentationRedirect />} />
            </Route>

            { /** Release Notes */}
            <Route path={p.t("router.compte.releaseNotes.route")} element={<ReleaseNotes />} />

            {/** Support */}
            {
              checkRight(ROLE_ACCES_SUPPORT) ?
                <Route path={p.t("router.support.route")} element={<Outlet />}>
                  <Route index element={redirectSupport()} />
                  <Route path="*" element={redirectSupport()} />
                </Route> : null
            }

            <Route index element={<Navigate to={getDefaultRoute()} />} />

            <Route path="*" element={<Navigate to={getDefaultRoute()} />} />

          </Route >

        </Routes >
      </Router >
    </>
  )
}

function App() {
  const {theme} = getDataFromHostname()
  const auth = useAuth();

  useEffect(() => {
    applyTheme(theme);
  },[]);
  
  return (
    <>
      <FaviconManager />
      <authContext.Provider value={auth}>
        <AppRouter />
      </authContext.Provider>

      <Toaster />
    </>
  );
}

export default App;
