import React, { useState } from 'react'
import Table from '../../../Components/Table/Table';
import { TableOptionsType } from '../../../Components/Table/Types/TableOptionsType';
import { useP } from '../../../services/i18n';
import LibelleFormatter from './LibelleFormatter';
import MulticompteFormatter from './MulticompteFormatter';

type ModalAddRoleBodyType = {
  datas: Array<any>,
  selectedValues: (values: Array<any>) => void,
  rowMulticompte: (checked: boolean, row: any) => void,
  multiCompte: boolean
}

export default function ModalAddRoleBody({ datas, selectedValues, rowMulticompte, multiCompte }: ModalAddRoleBodyType) {

  const p = useP();

  const tableOptions: TableOptionsType = {
    colSelect: true,
    pagination: false,
    toolbar: false,
    search: false,
    fullBordered: true,
  }

  const columns = [
    {
      libelle: "Rôle",
      code: 'libelle',
      colType: 'string',
      resizable: false,
      customFormatter: (row: object) => (<><LibelleFormatter row={row} /></>)
    }
  ]

  return <>
    {datas.length > 0 ?
      <Table
        idTable='ec-table-addRoleBody-pagination'
        columns={columns}
        datas={datas}
        loading={false}
        options={tableOptions}
        getSelectedRows={(rows) => {
          selectedValues(rows)
        }}
        className='sm:rounded-none border-0'
      />
      :
      <>
        <div className='font-medium text-sm text-gray-700'>
          {p.t("contacts.rolesUnavailable")}
        </div>
      </>
    }
  </>


}