import React from 'react'
import { Menu } from "@headlessui/react"
import { UserNavigationType } from '../../../../types/UserNavigationType'
import UserNavigationItem from './UserNavigationItem'

type UserNavigationItemsProps = {
  userNavigation: Array<UserNavigationType>
}

function UserNavigationItems({ userNavigation }: UserNavigationItemsProps) {

  return (
    <Menu.Items className="origin-top-right absolute top-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      {userNavigation.map((item, index) => (
        <UserNavigationItem key={index} item={item} />
      ))}
    </Menu.Items>
  )
}

export default UserNavigationItems