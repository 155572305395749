import React from "react";

type SpinnerType = {
  color?: 'primary' | 'secondary' | 'white' | 'gray',
  size?: string,
  borderWidth?: string,
}

function Spinner({ color = "primary", size = "h-5 w-5", borderWidth = "sm" }: SpinnerType) {
  let borderColor = "";
  switch (color) {
    case "primary":
      borderColor = "border-primary-600";
      break;
    case "secondary":
      borderColor = "border-secondary-600";
      break;
    case "white":
      borderColor = "border-white";
      break;
    case "gray":
      borderColor = "border-gray-500";
      break;
    default:
      borderColor = "border-gray-900";
      break;
  }

  let borderSize = "";
  switch (borderWidth) {
    case "xs":
      borderSize = "border";
      break;
    case "sm":
      borderSize = "border-2";
      break;
    case "md":
      borderSize = "border-4";
      break;
    case "lg":
      borderSize = "border-6";
      break;
    default:
      borderSize = "border-2";
      break;
  }

  return (
    <div
      className={`${borderColor} border-r-transparent animate-spin inline-block ${size} ${borderSize} rounded-full relative top-[2px]`}
      role="status"
    ></div>
  );
}


export default Spinner;
