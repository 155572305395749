import clsx from 'clsx'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type SlideOverListItemType = {
  id: number | string,
  title: string,
  link: string,
  searchParams?: string,
  subtitle?: JSX.Element,
  rightContainer?: JSX.Element,
  active: boolean,
  onClick?: (id: number | string) => void
}

export default function SlideOverListItem({ id, title, subtitle, rightContainer, active, link, searchParams, onClick }: SlideOverListItemType) {

  const navigate = useNavigate();

  const linkTo = () => {
    onClick && onClick(id)
    navigate({
      pathname: link,
      search: searchParams ? `?${searchParams}` : "",
    })
  }

  return (
    <li className={clsx(
      active
        ? `bg-stone-100 shadow-inner hover:bg-stone-100 `
        : `bg-white border-white `,
      `first:sticky first:top-0 first:z-10`,
      `relative border-l-0 hover:bg-gray-50`
    )}>

      <div onClick={linkTo} className="flex-1 block p-1 border-b border-gray-200 cursor-pointer">
        <div className="relative py-3 px-4 flex items-center -m-1">

          <div className={clsx(
            active && `before:absolute before:-left-1.5 before:top-0 before:rounded-full before:bg-secondary-500 before:h-full before:w-1`,
            `relative -m-1 flex-1 block p-1`
          )}>
            <div className="absolute inset-0" aria-hidden="true" />
            <div className={clsx(`flex-1 flex items-center relative`,rightContainer?`w-[180px]`:`w-[230px]`)}>
              <div className="truncate">
                <div className='text-sm font-medium text-gray-900 truncate' title={title}>{title}</div>
                {
                  subtitle && (
                    <div className="text-sm text-gray-500 truncate">
                      {subtitle}
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          {
            rightContainer
          }
        </div>
      </div>

    </li>
  )
}