import React, { useContext } from 'react'
import { HomeIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { authContext } from '../../AuthContext'
import { useP } from '../../services/i18n'
import { BreadcrumbDataType } from './BreadcrumbDataType'
import BreadcrumbItem from './BreadcrumbItem'

type BreadcrumbType = {
  datas: Array<BreadcrumbDataType>
}

function Breadcrumb({ datas }: BreadcrumbType) {
  const {
    selectedCompte,
    loggedInUser
  } = useContext(authContext)
  const p = useP();

  const homeLink = Array.isArray(selectedCompte) ?
    `/${p.t('router.comptes.route')}/${loggedInUser?.compte.id}` :
    `/${p.t('router.compte.route')}/${selectedCompte}`

  return (
    <>
      <nav className="flex mb-2 md:mb-6" aria-label="Breadcrumb">
        <ol role="list" className="flex space-x-1">
          <li>
            <div>
              <Link to={homeLink} className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {datas.map((item, index) => (
            <BreadcrumbItem key={index} item={item} />
          ))}
        </ol>
      </nav>
    </>
  )

}

export default Breadcrumb