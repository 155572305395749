import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom"
import { NavigationType } from '../../../types/NavigationType'

import { useP } from '../../../services/i18n'


type NavigationItemProps = {
  item: NavigationType,
  visibility?: boolean
}

function NavigationItem({ item, visibility = true }: NavigationItemProps) {

  const [menuIsVisible, setMenuIsVisible] = useState(visibility)
  useEffect(() => {
    setMenuIsVisible(visibility)
  }, [visibility])

  const p = useP();
  const className = `${menuIsVisible ? `visible` : `collapse`} transition ease-in-out duration-150 text-white rounded-md py-2 px-3 hover:bg-primary-600 text-sm font-medium focus:outline-none focus:ring-1 focus:ring-primary-400/50`;
  const activeClassName = 'bg-primary-600'

  return (
    <NavLink
      data-targetid={item.name}
      key={item.name}
      to={item.href}
      className={({ isActive }) =>
        isActive ? clsx(activeClassName, className) : className
      }
      end={!item.end ? undefined :  item.end}
    >
      {p.t(item.name)}
    </NavLink>
  )
}

export default NavigationItem