import React, { createContext, ReactNode, ReactText, useContext, useEffect, useState } from "react"
import { AccordionContext } from "./Accordion";

export type AccordionItemProps = {
  /** Children of the AccordionItem */
  children: ReactNode;
  /** uuid of the AccordionItem */
  uuid?: ReactText;
};

export const AccordionItemContext = createContext({});
const AccordionItem = ({ children, uuid, ...rest }: AccordionItemProps) => {
  const { expandedItems }: any = useContext(AccordionContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(!!expandedItems.has(uuid));

  useEffect(() => {
    setIsExpanded(!!expandedItems.has(uuid));
  }, [uuid, expandedItems]);

  return (
    <AccordionItemContext.Provider value={{ uuid, isExpanded, setIsExpanded }}>
      <div {...rest}>{children}</div>
    </AccordionItemContext.Provider>
  );
};

export default AccordionItem;