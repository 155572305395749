import React, { useState } from 'react';
import { getLocalStorage, setLocalStorage } from '../Utils/Utils';

type PaginationPerPage = {
  idTable: string,
  onSelectPageSize: (perpage: number) => void,
  optionsPageSize: number[],
  nbResultats: number,
  pageSize: number
}

export default function PaginationPerPage({ idTable, onSelectPageSize, optionsPageSize, pageSize, nbResultats }: PaginationPerPage) {
  const localStorageKey = idTable;
  const [perPage, setPerPage] = useState(
    getLocalStorage(localStorageKey)
      ? getLocalStorage(localStorageKey).perPage
      : pageSize
  );

  return <>
    <div className="text-sm text-gray-700 flex items-center">
      <div className="font-medium">Afficher</div>
      <div className='mx-2'>
        <select
          className='w-auto block focus:ring-primary-500 focus:border-primary-500 shadow-sm sm:text-sm border-gray-300 rounded-md py-1'
          value={perPage}
          onChange={(e) => {
            setPerPage(Number(e.target.value));
            onSelectPageSize(Number(e.target.value));
            setLocalStorage(localStorageKey, {
              perPage: Number(e.target.value),
            });
          }}
        >
          {optionsPageSize.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className="font-medium">{`lignes / ${nbResultats} résultat${nbResultats > 1 ? "s" : ""}`}</div>
    </div>
  </>
}