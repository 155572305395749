import React, { useEffect, useState } from "react";
import SupervisionContainer from "./SupervisionContainer";

import Constants from '../commons/Constants'
import { getSupervisionData } from "./utils";

const { STATUT_ACTIF } = Constants

const DEFAULT_ERROR_DATA = {
  statut: 'ERREUR',
  services: [{
    libelle: 'API',
    statut: 'ERREUR'
  }]
}

function Supervision() {

  const [loading, setLoading] = useState(false)
  const [supervisionData, setSupervisionData] = useState({});

  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number>(30);

  const loadSupervisionData = async (loader = true) => {
    setLoading(loader)
    setLastUpdated(new Date());

    const onSuccess = res => {
      setLoading(false)
      setSupervisionData(res)
    }

    const onError = () => {
      setLoading(false)
      setSupervisionData(DEFAULT_ERROR_DATA)
    }

    getSupervisionData(onSuccess, onError);

  }

  useEffect(() => {
    loadSupervisionData();

    const interval = setInterval(() => {
      loadSupervisionData(false);
    }, 30000);

    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    if (lastUpdated) {
      const countdown = setInterval(() => {
        const timeDiff = Math.floor((new Date().getTime() - lastUpdated.getTime()) / 1000);
        setTimeRemaining(30 - timeDiff >= 0 ? 30 - timeDiff : 0);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [lastUpdated]);

  return (
    <>
      <div className="min-h-full flex bg-white">
        <SupervisionContainer data={supervisionData} lastUpdated={lastUpdated} timeRemaining={timeRemaining} loading={loading} />
      </div>
    </>
  );
}

export default Supervision;
