import React from "react"
import { motion } from "framer-motion";

type CircularProgressBarType = {
  color: string,
  bgColor?: string,
  duration?: number,
  delay?: number,
  contenu?: string | JSX.Element
  percents: number,
  size?: number,
}

export default function CircularProgressBar({
  color,
  bgColor = "text-gray-300",
  duration = 0.75,
  delay = 0.1,
  contenu = "",
  percents,
  size = 100
}: CircularProgressBarType) {
  const radius = 45;
  const circumference = Math.ceil(2 * Math.PI * radius);
  const fillPercents = Math.abs(
    Math.ceil((circumference / 100) * (percents - 100))
  );
  const centerCoordinate = 70;
  //const percent = 85;
  const transition = {
    duration: duration,
    delay: delay,
    ease: "linear"
  };
  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition
    },
    show: {
      strokeDashoffset: fillPercents,
      transition
    }
  };
  return <>
    <div
      className="inline-flex items-center justify-center overflow-hidden rounded-full "
    >
      <svg className="-rotate-90" height={size} width={size} viewBox="0 0 100 100">
        <circle
          className={bgColor}
          strokeWidth="5"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={50}
          cy={50}
        />
        <motion.circle
          style={{ animation: "dash 5s linear alternate" }}
          className={`transition-all text-${color}`}
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={fillPercents}
          // strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={50}
          cy={50}
          variants={variants}
          initial="hidden"
          animate="show"
        />
      </svg>
      <span className={`absolute text-sm text-gray-700`}>{contenu}</span>
    </div>
  </>
}