import clsx from "clsx";
import React from "react";
import Accordion from "../../../Components/Accordion/Accordion";
import AccordionItemBody from "../../../Components/Accordion/AccordionBody";
import AccordionItemHeader from "../../../Components/Accordion/AccordionHeader";
import AccordionItemHeaderButton from "../../../Components/Accordion/AccordionHeaderButton";
import AccordionItem from "../../../Components/Accordion/AccordionItem";

type HelpBoxAcordionsType = {
  datas : Array<any> 
}

export default function HelpBoxAcordions({datas}:HelpBoxAcordionsType){
  return <>
  <Accordion
        //allowMultipleExpanded
        //onChange={(uuid, expandedItems) => console.log(uuid, expandedItems)}
        allowItemCollapse
      >
        {
            (datas?.map((item, index) => {
              return <AccordionItem key={index}>
                <div className=" border bg-white rounded-md border-gray-200 mb-2">
                  <div className="focus:outline-none">
                    <AccordionItemHeader>
                      <div className="flex items-start">
                        <AccordionItemHeaderButton className={clsx(`text-sm leading-4 font-medium py-2 px-2 text-primary-600`)}>
                          <div className="flex items-center relative">
                            <div className="h-full w-[3px] rounded-full bg-secondary-200 absolute left-1.5">&nbsp;</div>
                            <div className="ml-1 pl-3">{item.libelle}</div>
                          </div>
                        </AccordionItemHeaderButton>                        
                      </div>
                    </AccordionItemHeader>
                    <AccordionItemBody>
                      <div className="p-2 border-t border-gray-200">{item.content}</div>
                    </AccordionItemBody>
                  </div>
                </div>
              </AccordionItem>
            }))
        }
      </Accordion>
  </>
}