import { Menu } from '@headlessui/react'
import React from 'react'
import { useP } from '../../../../services/i18n';
import { UserType } from '../../../../types/UserType';

type ProfilePictureType = {
  user: UserType
}

function ProfilePicture({ user }: ProfilePictureType) {

  const p = useP();

  const getInitials = () => {

    const flName = user.nom ? user.nom.charAt(0).toUpperCase() : '.';
    const flSurname = user.prenom ? user.prenom.charAt(0).toUpperCase() : '.';

    return `${flSurname}${flName}`
  }

  return (
    <div>
      <Menu.Button className="bg-primary-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-0 focus:bg-white/30">
        <span className="sr-only">{p.t("header.profile.profilePicture.label")}</span>
        <div className="transition ease-in-out duration-150 rounded-full h-10 w-10 border-2 border-white flex items-center justify-center hover:bg-white hover:text-primary-800 focus:outline-none focus:ring-0">{getInitials()}</div>
      </Menu.Button>
    </div>
  )
}

export default ProfilePicture