import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import DescriptionList, { DescriptionListItemAlignType } from '../../../Components/DescriptionList/DescriptionList';
import Error from '../../../Components/Error/Error';
import Spinner from '../../../Components/Spinner';
import { GET_PRESTATION_HISTORIQUES } from '../queries';

type PrestationHistoriqueModaleBodyType = {
  prestationId: number,
}

export default function PrestationHistoriqueModaleBody({
  prestationId,
}: PrestationHistoriqueModaleBodyType) {

  const alignTop: DescriptionListItemAlignType = "top"

  // Table Datas site
  const { loading, data, error } = useQuery(GET_PRESTATION_HISTORIQUES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      filters: [
        { column: "prestation_id", value: prestationId }
      ],
      sorters: [
        {
          column: "date",
          order: "DESC"
        },
      ],
    }
  });
  const [datas, setDatas] = useState([])

  useEffect(() => {
    setDatas(data ?
      data.prestationHistoriques.items.map((h) => ({
        label: dayjs(h.date).format("DD/MM/YYYY HH:mm:ss"),
        value: <>
          <div>
            <div>{h.commentaires} </div>
            <div className='text-gray-500 text-xs'>{h?.contact ? `${h.contact.prenom} ${h.contact.nom}` : h?.user?.prenomNomContact}</div>
          </div>
        </>,
        align: alignTop
      })) : [])

  }, [data]);

  if (loading) {
    return <div className='flex items-center justify-center h-full'><Spinner /></div>
  }

  if (error) {
    return <Error />
  }

  return <>
    <DescriptionList datas={datas} columnWidthClassName="w-[160px]" />
  </>
}