import { gql } from "@apollo/client";

const NOTIFICATION_RAPPEL = gql`
  mutation NotificationRappel($notificationCode: String!) {
    notificationRappel(notificationCode: $notificationCode) {
      success
      message 
    }
  }`

const NOTIFICATION_LUE = gql`
  mutation NotificationLue($notificationCode: String!) {
    notificationLue(notificationCode: $notificationCode) {
      success
      message 
    }
  }`

export {
  NOTIFICATION_RAPPEL,
  NOTIFICATION_LUE,
}