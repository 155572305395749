import React, { useState } from "react";
import { useP } from "../../services/i18n";
import Table from "../../Components/Table/Table";
import { GET_COMPTES } from "../../queries";
import { useMutation, useQuery } from "@apollo/client";
import { toastError, toastSuccess } from "../../Components/Utils/Utils";
import TooltipInformation from "../../Components/Tooltip/TooltipInformation";
import { SAVE_COMPTE } from "./queries";
import Spinner from "../../Components/Spinner";

export default function ComptesModaleBody() {
  const p = useP();

  const { loading, data } = useQuery(GET_COMPTES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [saveCompte] = useMutation(SAVE_COMPTE);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const columns = [
    {
      libelle: p.t('header.comptes.columns.raisonSociale'),
      code: 'raisonSociale',
      colType: 'string',
    },
    {
      libelle: <>
        <div className="flex items-center">
          <div>{p.t('header.comptes.columns.analytiqueEntreprise')}</div>
          <div className="ml-2"><TooltipInformation contenu={p.t('header.comptes.analytique')} /></div>
        </div>
      </>,
      code: 'analytique1',
      colType: 'editable',
      width: "200px",
      action: (value) => {
        if (selectedRow?.id) {
          return saveCompte({
            variables: {
              compte: {
                id: selectedRow.id,
                analytique1: value
              }
            },
            onCompleted: () => {
              toastSuccess("Enregistrement effectué");
              return true;
            },
            onError: (err) => {
              console.log(err)
              toastError(p.t('error.defaultMessage'));
              return false;
            }
          })
        }
        return true;
      }
    }
  ]

  return loading ? <Spinner /> : <>
    <Table
      idTable='ec-table-comptes'
      loading={false}
      datas={data?.comptes?.items ? data.comptes.items : []}
      columns={columns}
      options={{
        resizable: false,
        toolbar: false,
        pagination: false
      }}
      onClickRow={(row) => setSelectedRow(row)}
    />
  </>
}