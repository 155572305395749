import { Dialog, Transition } from "@headlessui/react"
import clsx from "clsx"
import React, { Fragment, ReactNode } from "react"
import ModalBody from "./ModalBody"
import { ModalContentType } from "./ModalContentType"
import ModalFooter from "./ModalFooter"
import ModalHeader from "./ModalHeader"



type ModalContainerType = {
  className?: string,
  onClose: () => void,
  isOpen: boolean,
  content: ModalContentType,
  disableEsc?: boolean,
  noPadding?: boolean,
  closeIcon?: boolean,
  fullscreen?: boolean,
  heightClass?: string
}

export default function ModalContainer({ className, onClose, isOpen, content, disableEsc = false, noPadding = false, closeIcon = true, heightClass = "lg:h-auto", fullscreen = false }: ModalContainerType) {

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hidden"
          onClose={() => disableEsc ? null : onClose()}
        >
          <div className={clsx(`text-center flex justify-center items-center h-screen `,
            fullscreen ? `lg:py-0` : `lg:py-10`)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900/80 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={clsx(
                  `relative z-auto flex flex-col overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md max-h-full h-full`,
                  fullscreen ? `lg:m-0` : `lg:m-8`,
                  heightClass,
                  className
                )}
              >
                <ModalHeader title={content.title} onClose={onClose} closeIcon={closeIcon} />
                <ModalBody noPadding={noPadding}>{content.body}</ModalBody>
                {content.footer && <ModalFooter>{content.footer}</ModalFooter>}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}