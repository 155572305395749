import React from 'react';
import Badge from '../../../Components/Badge';
import { TicketType } from '../../../types/TicketType';

type ModaleDetailTicketTitleType = {
  ticket: TicketType,
}

export default function ModaleDetailTicketTitle({ ticket }: ModaleDetailTicketTitleType) {
  return <>
    <div>
      {ticket.qualificatif} N° {ticket.numero} - {ticket.ticketStatut.statutPublic}
    </div>
    <div className='flex items-center space-x-2 pt-2'>
      <div><Badge itemLibelle={ticket.ticketCriticite.libelle} itemColor='red' /></div>
      <div className='text-gray-600 text-base'>{ticket.titre}</div>
    </div>
  </>;
}
