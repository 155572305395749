import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import HelpBoxContent from "./HelpBoxContent/HelpBoxContent";
import { useLocation } from "react-router-dom";
import { useP } from "../../services/i18n";

export default function HelpBox(){
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const p = useP()
  
  useEffect(() => {
    setIsOpen(false)
  }, [location])
  
  const containerVariants = {
    open:{
      bottom : "10px",
      width:"320px",
      height:"500px",
      transition: { type: "spring",bounce: 0.25}
    },
    closed: {
      bottom:"0px",      
      width : "150px",
      height:"30px",
      transition: { type: "spring",bounce: 0.25}
    }
  };
  return <>
    <motion.div        
        className={clsx(`fixed right-2 bg-primary-700 text-white z-50 shadow flex flex-col items-start`,isOpen ? `rounded-md ` : `rounded-t-md ` )}
        initial={false}
        variants={containerVariants}
        animate={isOpen ? "open" : "closed"}
        layout
      >
        <div className="flex items-center w-full">
          <div className={clsx(`cursor-pointer flex items-center flex-1`)} 
            onClick={() => setIsOpen(true)}
            >
              <div className={clsx(`
                flex items-center rounded-md p-1`,
                isOpen && `mx-2 mb-0 p-1`)}               
              >
                <InformationCircleIcon className={clsx(`h-6 w-6 `,!isOpen ? `` : ``)} />
              </div>
              <div className={clsx(`ml-1 flex-1 font-medium text-white text-sm`)}>{p.t('components.helpBox.title')}</div>
          </div>
          {isOpen && <div className="p-2 cursor-pointer" onClick={()=>setIsOpen(false)}><XMarkIcon className="h-5 w-5" /></div>}
        </div>
        <AnimatePresence>{isOpen && <HelpBoxContent location={location.pathname} />}</AnimatePresence>
      </motion.div>
  </>
}