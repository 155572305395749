import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Constants from '../../../../commons/Constants';
import Badge from '../../../../Components/Badge';
import Button from '../../../../Components/Button';
import { useCheckRight } from '../../../../Components/CheckRight/CheckRight';
import DescriptionList from '../../../../Components/DescriptionList/DescriptionList';
import Editable, { EMPTY_FIELD } from '../../../../Components/Editable/Editable';
import Modal from '../../../../Components/Modal/Modal';
import { toastError } from '../../../../Components/Utils/Utils';
import { useP } from '../../../../services/i18n';
import PrestationDetailModaleBody from '../../PrestationDetail/PrestationDetailModaleBody';
import PrestationHistoriqueModaleBody from '../../PrestationDetail/PrestationHistoriqueModaleBody';
import { GET_DONNEES_TECHNIQUES_VCC, SAVE_VCC } from '../../queries';

type PrestationDetailModaleVCCType = {
  isOpen: boolean,
  prestation: any,
  onClose: () => void,
  onSave: (vcc: any) => void
}

const {
  ROLE_ADMIN_PRESTATION,
} = Constants;

function PrestationDetailModaleVCC({ prestation, isOpen = false, onClose, onSave }: PrestationDetailModaleVCCType) {
  
  const p = useP();
  const { checkRight } = useCheckRight();

  const rightAdminPrestation = checkRight(ROLE_ADMIN_PRESTATION);
  const [prestationAffiche, setPrestationAffiche] = useState<any>(prestation);
  const [saveVcc] = useMutation(SAVE_VCC);
  
  const [getDonneesTech, {data: dataTech}] = useLazyQuery(GET_DONNEES_TECHNIQUES_VCC, {
    fetchPolicy: 'network-only',    
  });

  useEffect(() => {
    setPrestationAffiche(prestation)
    if (prestation){
      getDonneesTech({ 
        variables: { 
          vccId: prestation.vccId 
        }
      })
    }
  }, [prestation])

  const onSaveVcc = async (vcc) => {
    let result = true;

    await saveVcc({
      variables: { vcc: { id: prestationAffiche?.vccId, ...vcc } },
      onCompleted: (data) => {
        setPrestationAffiche({ ...prestationAffiche, ...vcc })
        onSave(data.saveVcc)
      },
    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
      result = false
    })

    return result;
  }
  const infosVCC =
    [
      {
        label: p.t('prestations.vcc.detail.nom'),
        value: rightAdminPrestation ?
          <Editable value={prestationAffiche?.nom ? prestationAffiche.nom : EMPTY_FIELD} onSaveField={(value) => onSaveVcc({ nom: value })} />
          : prestationAffiche?.nom,
      },      
      {
        label: p.t('prestations.vcc.detail.vm'),
        value: dataTech?.getDonneesTechniquesVcc?.nbVm ? `${dataTech?.getDonneesTechniquesVcc.nbVm} VM` : EMPTY_FIELD,
      },
      {
        label: p.t('prestations.vcc.detail.stockage'),
        value: dataTech?.getDonneesTechniquesVcc?.stockage  ? `${dataTech?.getDonneesTechniquesVcc.stockage} To` : EMPTY_FIELD,
      },
      {
        label: p.t('prestations.vcc.detail.zoneStockage'),
        value: dataTech?.getDonneesTechniquesVcc?.zoneStockage || EMPTY_FIELD,
      }
    ]

  const VCCTabs = {
    navigation: [
      {
        libelle: p.t('prestations.detail.informations'),
        jsx: <>{p.t('prestations.detail.informations')}</>
      },
      {
        libelle: p.t('prestations.detail.historique'),
        jsx: <>{p.t('prestations.detail.historique')}</>
      }
    ],
    panels: [
      <DescriptionList datas={infosVCC} key="Infos_VCC" columnWidthClassName='w-[200px]' />,
      <PrestationHistoriqueModaleBody key="Historique_VCC" prestationId={prestation.id} />,
    ]

  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('prestations.vcc.detail.title')}</div>
              <div className='ml-1'>{prestationAffiche?.nom}</div>
              <div className='ml-2'><Badge itemLibelle={prestation?.etat?.libelle} itemColor={prestation?.etatColor} /></div>
            </div>            
          </>
          ,
          body: <PrestationDetailModaleBody tabsNavigation={VCCTabs.navigation} tabsPanel={VCCTabs.panels} />,
          footer: <><div className='flex items-center justify-end'>
            <Button onClick={onClose}>
              {p.t('actions.fermer')}
            </Button>
          </div></>,
        }}
        className="w-full lg:w-6/12 lg:h-5/6"
      />
    </>
  )
}

export default PrestationDetailModaleVCC