import React from "react"
import Constants from "../../../commons/Constants"
import CyberChartLine from "./CyberChartLine"
import CyberChartBar from "./CyberChartBar"
import CyberChartDoughnut from "./CyberChartDoughnut"
import CyberChartPie from "./CyberChartPie"
import CyberChartRadar from "./CyberChartRadar"
import Spinner from "../../../Components/Spinner"
import Error from "../../../Components/Error/Error"

type CyberChartContainerType = {
  chartType: string
  chartData: any
  chartDateTime?: Date
  loading: boolean
  error: any
}

const {
  LINE_CHART,
  BAR_CHART,
  DOUGHNUT_CHART,
  PIE_CHART,
  RADAR_CHART
} = Constants

export default function CyberChartContainer({ chartType, chartData, chartDateTime, loading, error }: CyberChartContainerType) {

  if (error) {
    return <Error error={"Une erreur est survenue lors du chargement du graphique"} />
  }

  if (loading || !chartData) {
    return <div className="flex items-center justify-center h-full w-full"><Spinner /></div>
  }

  switch (chartType) {
    case LINE_CHART:
      return <CyberChartLine chartData={chartData} chartDateTime={chartDateTime} />
      break;

    case BAR_CHART:
      return <CyberChartBar chartData={chartData} chartDateTime={chartDateTime} />
      break;

    case DOUGHNUT_CHART:
      return <CyberChartDoughnut chartData={chartData} chartDateTime={chartDateTime} />
      break;

    case PIE_CHART:
      return <CyberChartPie chartData={chartData} chartDateTime={chartDateTime} />
      break;

    case RADAR_CHART:
      return <CyberChartRadar chartData={chartData} chartDateTime={chartDateTime} />
      break;

    default:
      return <div>Type graphique non reconnu</div>
      break;
  }
}