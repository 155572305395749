import React from 'react'
import Card from '../../Components/Card'
import Table from '../../Components/Table/Table'
import { ColumnType } from '../../Components/Table/Types/ColumnType'
import TooltipInformation from '../../Components/Tooltip/TooltipInformation'
import { useP } from '../../services/i18n'

type TableHistoriqueConnexionLoginType = {
  title: string,
  datas: any[],
  tooltipContent? : string | JSX.Element | null
}

export default function TableHistoriqueConnexionLogin({ title, datas, tooltipContent=null }: TableHistoriqueConnexionLoginType) {
  const p = useP()

  const columns: Array<ColumnType> = [{
    libelle: p.t('supervisionLien.historiqueConnexion.columns.statut'),
    code: 'statut',
    colType: 'state',
    valign: "top",
    width: "40%"
  },
  {
    libelle: p.t('supervisionLien.historiqueConnexion.columns.date'),
    code: 'dateDeconnexion',
    colType: 'string',
    valign: "top",
    width: "60%",
  }]

  return <>
    <div className='mb-4'>
      <Card className={{ root: "h-[300px] flex flex-col p-0", container: "px-0" }} shadow={false} bordered={false}>
        <Card.Header className='px-0 pb-0'>
          <div className='flex items-center'>
            <div>{title}</div>
            {tooltipContent && <div className='ml-2'><TooltipInformation contenu={tooltipContent} /></div>}
          </div>
        </Card.Header>
        <div className='flex-1 overflow-hidden'>
          <Table
            idTable='ec-table-historique-lien'
            loading={false}
            datas={datas}
            columns={columns}
            options={{
              search: false,
              pagination: false
            }}
          />
        </div>
      </Card>
    </div>
  </>
}