import { useMutation } from '@apollo/client';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { authContext } from '../../../AuthContext';
import Constants from '../../../commons/Constants';
import Button from '../../../Components/Button';
import Modal from '../../../Components/Modal/Modal';
import { useP } from '../../../services/i18n';
import { CHANGE_OTP_MODE } from '../queries';
import ChangeOtpModeModaleBody from './ChangeOtpModeModaleBody';
import OtpModeType from './OtpModeType';
import OtpModeQrCode from './Steps/OtpModeApplicationQrCode';
import OtpModeConfirm from './Steps/OtpModeConfirm';
import OtpModeSelection from './Steps/OtpModeSelection';

const {
  MOBILE_FRANCAIS_REGEX
} = Constants

type ChangeOtpModeModaleType = {
  isOpen: boolean,
  onClose: () => void
}

function ChangeOtpModeModale({ isOpen, onClose }: ChangeOtpModeModaleType) {

  const p = useP();

  const [changeOtpMode] = useMutation(CHANGE_OTP_MODE);

  const OTP_MODE_CONFIRM_STEP = { code: 'OTP_MODE_CONFIRM', element: OtpModeConfirm };

  const DEFAULT_STEPS = [
    { code: 'OTP_MODE_SELECTION', element: OtpModeSelection }
  ];

  const { loggedInUser, setLoggedInUser } = useContext(authContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(DEFAULT_STEPS);
  const [currentOtpMode, setCurrentOtpMode] = useState(loggedInUser?.user.otpMode ? loggedInUser?.user.otpMode : 'EMAIL');
  const [canContinue, setCanContinue] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('')


  const otpModes: Array<OtpModeType> = [
    {
      code: 'DISABLED', libelle: p.t('otpModes.disabled'), description: `${loggedInUser?.user?.canDisableOtp ? p.t('otpModes.disabledDescriptionOk') : p.t('otpModes.disabledDescriptionDisabled')}`, steps: [],
      isDisabled: () => {
        return !loggedInUser?.user?.canDisableOtp
      },
    },
    {
      code: 'EMAIL', libelle: p.t('otpModes.email'), description: `${loggedInUser?.user?.mail ? loggedInUser.user.mail : p.t('otpModes.emailDescriptionDisabled')}`, canResend: true, steps: [],
      isDisabled: () => {
        return !loggedInUser?.user?.mail
      }
    },
    {
      code: 'SMS', libelle: p.t('otpModes.sms'), description: `${loggedInUser?.user?.telephoneMobile ? loggedInUser.user.telephoneMobile : p.t('otpModes.smsDescriptionDisabled')}`, canResend: true, steps: [],
      isDisabled: () => {
        const testMobileFrancais = new RegExp(MOBILE_FRANCAIS_REGEX)
        const mobile = loggedInUser?.user ? loggedInUser.user.telephoneMobile : ''

        return !testMobileFrancais.test(mobile);
      }
    },
    {
      code: 'APPLICATION', libelle: p.t('otpModes.application'), description: p.t('otpModes.applicationDescription'),
      steps: [
        { code: 'OTP_MODE_QR_CODE', element: OtpModeQrCode }
      ],
      isDisabled: () => {
        return false;
      }
    },
  ];

  const onSubmit = () => {

    toast.promise(
      changeOtpMode({
        variables: {
          otpMode: currentOtpMode,
          otp: confirmationCode
        }
      }),
      {
        loading: p.t('profil.tabs.securite.otpSelection.toasts.loading'),
        success: (res) => {
          if (!res?.data?.changeOtpMode?.success) {
            throw new Error();
          }

          if (loggedInUser?.user) {
            loggedInUser.user.otpMode = currentOtpMode;
            setLoggedInUser(loggedInUser);
          }

          onClose();

          return p.t('profil.tabs.securite.otpSelection.toasts.success')

        },
        error: () => {
          return p.t('profil.tabs.securite.otpSelection.toasts.error')
        },
      }
    );

  }

  const nextStep = () => {
    if (currentStep + 1 < steps.length) {
      setCanContinue(false)
      setCurrentStep(currentStep + 1)
    }
    else if (currentStep + 1 == steps.length) {
      onSubmit();
    }
  }

  const onChangeOtpMode = (otpMode) => {
    setCurrentOtpMode(otpMode);
  }

  useEffect(() => {

    const record = otpModes.find(val => val.code === currentOtpMode);

    if (!record) return

    const stps = [
      ...DEFAULT_STEPS,
      ...record.steps,
    ];

    if (currentOtpMode != 'DISABLED') stps.push(OTP_MODE_CONFIRM_STEP);

    setSteps(stps);

    setConfirmationCode('');

  }, [currentOtpMode])

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('userNavigation.profil.label')}</div>
            </div>
          </>,
          body: <ChangeOtpModeModaleBody otpModes={otpModes} step={steps[currentStep]} otpMode={otpModes?.find((mode) => mode.code === currentOtpMode)} onChangeOtpMode={onChangeOtpMode} setCanContinue={setCanContinue} confirmationCode={confirmationCode} setConfirmationCode={setConfirmationCode} />,
          footer: <>
            <div className='flex items-center justify-end'>
              <Button onClick={nextStep} disabled={!canContinue} color={canContinue ? 'primary' : 'primaryLight'}>
                {p.t('actions.continuer')} <ArrowRightIcon className="w-4 h-4 ml-1" />
              </Button>
            </div>
          </>,
        }}
        className="w-96"
      />
    </>
  )
}

export default ChangeOtpModeModale