import convert from 'convert';
import React from 'react'

type CellKiBFormatterType = {
  value: number,
}

export default function CellKiBFormatter({ value }: CellKiBFormatterType) {
  const convertData = convert(value ? value : 0, 'KiB').to('best');
  return <>
    <div className=''>
      {`${parseFloat(convertData.quantity.toString()).toLocaleString()} ${convertData.quantity === 0 ? 'Ko' : convertData.unit.replace('iB', 'o')}`}
    </div>
  </>
}