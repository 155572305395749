import { Dialog } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import React, { ReactNode } from "react"
import { useP } from "../../services/i18n";



type ModalHeaderType = {
  title: string | ReactNode,
  onClose: () => void,
  closeIcon: boolean
}

export default function ModalHeader({ title, onClose,closeIcon }: ModalHeaderType) {
  const p = useP();
  return (
    <>
      <div className="border-b border-gray-200 px-4 py-4 flex justify-between items-center">
        <Dialog.Title
          as="h3"
          className="text-xl font-medium leading-6 text-primary-900 flex-1"
        >
          {title}
        </Dialog.Title>

        {closeIcon &&
          <div className="ml-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={onClose}
            >
              <span className="sr-only">{p.t('components.modal.close')}</span>
              <XMarkIcon className="h-5 w-5 relative top-1" aria-hidden="true" />
            </button>
          </div>
        }
      </div>
    </>
  )
}