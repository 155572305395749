import React from 'react'
import { RoleType } from './RoleType'
import List from '../../../../Components/List/List'
import AccordionItemHeader from '../../../../Components/Accordion/AccordionHeader'
import AccordionItemBody from '../../../../Components/Accordion/AccordionBody'
import AccordionItemHeaderButton from '../../../../Components/Accordion/AccordionHeaderButton'
import RolesListItemsActions from './RolesListItemsActions/RolesListItemsActions'
import Badge from '../../../../Components/Badge'
import Constants from '../../../../commons/Constants'
import clsx from 'clsx'
import { useMutation } from '@apollo/client'
import { DELETE_CONTRAT_FOR_ROLECONTACT, DELETE_SITE_FOR_ROLECONTACT } from '../../queries'
import { toastError, toastSuccess } from '../../../../Components/Utils/Utils'
import { DocumentTextIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { useP } from '../../../../services/i18n'

const {
  SAISIE_CONTRAT,
  SAISIE_SITE
} = Constants;

type RolesListItemsType = {
  role: RoleType,
  index: number
  idContact: number
}

function RolesListItems({ role, index, idContact }: RolesListItemsType) {
  const p = useP();
  /*
   * Suppression d'un site ou d'un contrat
   */
  const removeSiteContrat = (item) => {
    switch (item.typeRole) {
      case SAISIE_CONTRAT:
        removeContrat(item);
        break;
      case SAISIE_SITE:
        removeSite(item);
        break;
      default:
        toastError(p.t('contacts.typeRoleInconnu'));
    }
  }

  /*
   * Suppression d'un contrat
   */
  const removeContrat = (item) => {
    removeContratMutation({
      variables: {
        idRoleContact: item.roleContactId,
        numcli: item.id
      }
    })
      .then(({ data }) => {
        toastSuccess(p.t('actions.supprimeOK'));

      })
      .catch(() => {
        toastError(p.t('error.defaultMessage'));
      });
  }
  const [removeContratMutation, { loading: loadingRemoveContrat }] = useMutation(
    DELETE_CONTRAT_FOR_ROLECONTACT,
    {
      refetchQueries: [
        'GetContacts',
        'roleContacts'
      ],
    }
  );

  /*
   * Suppression d'un site
   */
  const removeSite = (item) => {
    removeSiteMutation({
      variables: {
        idRoleContact: item.roleContactId,
        siteId: item.id
      }
    })
      .then(({ data }) => {
        toastSuccess(p.t('actions.supprimeOK'));

      })
      .catch(() => {
        toastError(p.t('error.defaultMessage'));
      });
  }
  const [removeSiteMutation, { loading: loadingRemoveSite }] = useMutation(
    DELETE_SITE_FOR_ROLECONTACT,
    {
      refetchQueries: [
        'GetContacts',
        'roleContacts'
      ],
    }
  );

  /*
   * Contextualisation du rôle
   * Pour la suppression
   * roleContactId : id du roleContact
   * id : siteId ou numcli
   * typeRole : contrat ou site
   * libelle : ce qui est affiché dans la liste
   */
  const listScopeDatas = role.contrat.concat(role.site ? role.site : [])
  const datasList = listScopeDatas.map((item) => {
    if (item.__typename == 'Site') {
      let site = item.nom + ' - ' + item.numeroVoie + ' ' + item.libelleVoie + ' ' + item.codePostal + ' ' + item.commune
      site = site.replaceAll('null', ' ')
      return {
        roleContactId: role.id,
        id: item.id,
        typeRole: SAISIE_SITE,
        libelle: site,
        icon: <BuildingOfficeIcon className='h-4 w-4 text-gray-500' />,
        tooltipMessage: p.t('contacts.sites.tooltip')
      }
    } else {
      let contrat = item.numcli + ' - ' + item.nom + ' - ' + item.adresse + ' ' + item.cp + ' ' + item.ville
      contrat = contrat.replaceAll('null', ' ')
      return {
        roleContactId: role.id,
        id: item.numcli,
        typeRole: SAISIE_CONTRAT,
        libelle: contrat,
        icon: <DocumentTextIcon className='h-4 w-4 text-gray-500' />,
        tooltipMessage: p.t('contacts.contrats.tooltip')
      }
    }
  })
  // Si il n'y a pas de restriction possible ou qu'il n'y a pas de contrats/sites associés on désactive l'accordeon
  const isDisabled = (!role.typeRole.saisieContrat && !role.typeRole.saisieSite) || role.isMulticompte || listScopeDatas.length == 0

  return (
    <>
      <div className=" border bg-white rounded-md border-gray-200 mb-2">
        <div className="focus:outline-none">
          <AccordionItemHeader>
            <div className="flex items-center justify-between">
              <AccordionItemHeaderButton disabled={isDisabled} className={clsx(`text-sm font-semibold py-2 px-2 text-primary-600 uppercase`, isDisabled && `ml-4`)}>
                <div className="text-primary-600 ml-2 flex items-center">
                  <span>{role.typeRole.libelle}</span>
                  {/* {
                    role.isMulticompte &&
                    <span className='ml-2'><Badge itemLibelle={p.t('contacts.contact.multicompte')} /></span>
                  } !!FLAG Retrait multicompte par role */}
                </div>
              </AccordionItemHeaderButton>
              <RolesListItemsActions role={role} />
            </div>
          </AccordionItemHeader>
          <AccordionItemBody>
            <div className="text-sm text-gray-600 truncate border-t border-gray-200">
              <List datas={datasList} canDeleteItem={true} removeItem={removeSiteContrat} />
            </div>
          </AccordionItemBody>
        </div>
      </div>
    </>
  )

}

export default RolesListItems;