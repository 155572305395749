import React from "react";
import { Control, Controller, UseFormRegister, UseFormWatch } from "react-hook-form";
import { Input } from "../../../Components/Form/Input";
import { useP } from "../../../services/i18n";
import { registerLocale } from "react-datepicker";
import "../../../Components/Datepicker/react-datepicker.css"
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

type ModalCommandeArrowBodyType = {
  register: UseFormRegister<any>,
  control: Control<any>,
  errors: any,
  getValues: (payload?: string | string[]) => any,
  setValue: (name: string, value: unknown, config?: any) => void,
  abonnement: any,
  watch: UseFormWatch<any>,
}

export default function ModalCommandeArrowBody({
  register,
  control,
  errors,
  getValues,
  setValue,
  abonnement,
  watch
}: ModalCommandeArrowBodyType) {

  const p = useP();
  const nouvelleQuantite = watch('nouvelleQuantite');

  return <>
    <div className='flex items-start flex-col lg:flex-row'>
      <div className="flex-1">
        <div className="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-12">
            <div className="block text-sm font-medium text-gray-700 mb-1">{p.t('commandeArrow.form.produit')} </div>
            <div className="block text-sm  text-gray-700 mb-1 ">{abonnement.nomProduit}</div>
          </div>
          <div className="sm:col-span-12 flex">
            <div className="block text-sm font-medium text-gray-700 mb-1 flex-1">{p.t('commandeArrow.form.quantiteActuelle')} :  </div>
            <div className="flex-1"><span className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 sm:text-sm font-medium text-gray-700">{abonnement.quantite}</span></div>
          </div>
          < div className="sm:col-span-12 flex">
            <div className="block text-sm font-medium text-gray-700 mb-1 flex-1">{p.t('commandeArrow.form.quantiteNouvelle')} :  </div>
            <div className="flex-1">
              <Controller
                control={control}
                name="nouvelleQuantite"
                defaultValue={parseInt(abonnement.quantite, 10)}
                render={({ field }) => <Input register={register} errors={errors} type='number' autoComplete='off' {...field} />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}