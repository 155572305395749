import React, { useEffect, useState } from 'react';

import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

import Constants from '../../../commons/Constants';
import { useP } from '../../../services/i18n';


const {
    PASSWORD_COMPLEXITY_1_REGEX,
    PASSWORD_COMPLEXITY_2_REGEX
} = Constants;

const passwordComplexity1Regex = new RegExp(PASSWORD_COMPLEXITY_1_REGEX);
const passwordComplexity2Regex = new RegExp(PASSWORD_COMPLEXITY_2_REGEX)

type PasswordStrengthType = {
    password: string,
    checkPassword: string,
    passwordLevel: number,
    setPasswordLevel: any
}

function PasswordRequirement(props: { label: string, password: string, checkPassword: string, pattern: string, required?: boolean, compare?: boolean }) {

    const [valid, setValid] = useState(false)

    useEffect(() => {

        if (props.compare) {
            setValid(props.password ? props.password === props.checkPassword : false)
        }
        else {
            const regex = new RegExp(props.pattern);

            setValid(regex.test(props.password))
        }

    }, [props.password, props.checkPassword])

    return (
        <div className="">
            <div className="flex flex-row items-center space-x-1">
                {
                    valid ?
                        (<CheckCircleIcon className="w-5 h-5 text-green-600" />) :
                        (
                            props.required ? <XCircleIcon className="w-5 h-5 text-red-600" /> : <ExclamationCircleIcon className="w-5 h-5 text-yellow-600" />)
                }

                <div>{props.label}</div>
            </div>
        </div>
    )
}

function PasswordBadge(props: { password: string, passwordLevel: number, setPasswordLevel: any }) {

    const p = useP();

    const [label, setLabel] = useState(p.t("changePassword.passwordStrength.passwordFaible"))
    const [color, setColor] = useState('red')

    useEffect(() => {
        let newLabel = p.t("changePassword.passwordStrength.passwordFaible");
        let newColor = 'red'
        let level = 0;

        if (passwordComplexity2Regex.test(props.password)) {
            newLabel = p.t("changePassword.passwordStrength.passwordFort");
            newColor = 'green';
            level = 2;
        }
        else if (passwordComplexity1Regex.test(props.password)) {
            newLabel = p.t("changePassword.passwordStrength.passwordCorrect");
            newColor = 'yellow'
            level = 1;
        }

        setLabel(newLabel);
        setColor(newColor);
        props.setPasswordLevel(level)
    }, [props.password])

    return (
        <div className="flex flex-row">
            <div className={`w-full p-0.5 text-center font-medium rounded-md border-2 border-${color}-600 text-${color}-600`}>
                {label}
            </div>
        </div>
    )
}

function PasswordStrength(props: PasswordStrengthType) {

    const p = useP();

    return (
        <div className='space-y-2'>

            <PasswordBadge password={props.password} passwordLevel={props.passwordLevel} setPasswordLevel={props.setPasswordLevel} />

            <div>

                <PasswordRequirement label={p.t("changePassword.passwordStrength.lowercaseRequired")} password={props.password} checkPassword={props.checkPassword} pattern="(?=.*[a-z])" />

                <PasswordRequirement label={p.t("changePassword.passwordStrength.uppercaseRequired")} password={props.password} checkPassword={props.checkPassword} pattern="(?=.*[A-Z])" />

                <PasswordRequirement label={p.t("changePassword.passwordStrength.nombreRequired")} password={props.password} checkPassword={props.checkPassword} pattern="(?=.*[0-9])" />

                <PasswordRequirement label={p.t("changePassword.passwordStrength.charNumberRequired")} password={props.password} checkPassword={props.checkPassword} pattern="(?=.{6,})" required={true} />

                <PasswordRequirement label={p.t("changePassword.passwordStrength.specialCharRequired")} password={props.password} checkPassword={props.checkPassword} pattern="(?=.*[^A-Za-z0-9])" required={true} />

                <PasswordRequirement label={p.t("changePassword.passwordStrength.samePasswords")} password={props.password} checkPassword={props.checkPassword} pattern="(?=.*[^A-Za-z0-9])" required={true} compare={true} />

            </div>

        </div>
    )
}

export default PasswordStrength