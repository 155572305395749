import clsx from "clsx";
import React, { HTMLProps } from "react";



type ItemsContainerType = {
  containerProps: HTMLProps<any>,
  depth: number,
  children: React.ReactNode

}

export default function ItemsContainer({ depth,
  containerProps,
  children }: ItemsContainerType) {
  return (
    <>
      <ul
        className={clsx(
          `${depth}`
        )}
        {...containerProps}
      >
        {children}
      </ul>
    </>
  );
}

