import React from 'react';
import OtpModeType from './OtpModeType';

type ChangeOtpModeModaleBodyType = {
  otpModes: Array<OtpModeType>,
  step: any,
  otpMode: OtpModeType | undefined,
  onChangeOtpMode: (value: string) => void
  setCanContinue: (value: boolean) => void
  confirmationCode: string
  setConfirmationCode: (value: string) => void
}

type StepContainerType = {
  Step: any,
  otpModes: Array<OtpModeType>
  otpMode: OtpModeType | undefined,
  onChangeOtpMode: (value: string) => void
  setCanContinue: (value: boolean) => void
  confirmationCode: string
  setConfirmationCode: (value: string) => void
}

function StepContainer({ Step, ...props }: StepContainerType) {
  return <Step {...props} />
}

function ChangeOtpModeModaleBody({ step, ...props }: ChangeOtpModeModaleBodyType) {

  return (
    <div className='p-4'>
      <div className=''>
        <div className='space-y-4'>

          <StepContainer Step={step.element} {...props} />

        </div>

      </div>
    </div>
  )
}

export default ChangeOtpModeModaleBody