import React from "react"
import dayjs from "dayjs"
import Badge from "../../../Components/Badge"
import FileTypeIcon from "../../../Components/FileTypeIcon"
import clsx from "clsx"
import { motion } from "framer-motion";
import { ArrowTopRightOnSquareIcon, LinkIcon } from "@heroicons/react/24/outline"
import Constants from "../../../commons/Constants"
import { useP } from "../../../services/i18n"
import Tooltip from "../../../Components/Tooltip/Tooltip"

export type ThumbnailType = {
  id: string,
  titre: string,
  guid: string,
  extension: string,
  description: string,
  version: string,
  dateModification: string,
  approvalMode: string | null,
  dateApproval: string | null,
}

type DocumentationThumbnailType = {
  thumbnail: ThumbnailType,
  onClick: (thumbnail: ThumbnailType) => void,
  onCopyLink: (thumbnail: ThumbnailType) => void,
}

const FileIconMotion = {
  default: {
    opacity: 1,
    y: 0
  },
  hover: {
    opacity: 1,
    y: 50
  }
}
const DownloadMotion = {
  default: {
    opacity: 0,
    y: -20
  },
  hover: {
    opacity: 1,
    y: 0
  }
}

const {
  APPROBATION_READ
} = Constants

export default function DocumentationThumbnail({ thumbnail, onClick, onCopyLink }: DocumentationThumbnailType) {
  const p = useP();
  return <>
    <motion.div
      initial="default"
      animate="default"
      whileHover="hover"
      className={clsx(
        `relative z-0 rounded-md py-2 pl-2 pr-2 text-sm text-gray-700 cursor-pointer flex flex-col space-y-2`,
        !thumbnail.dateApproval && thumbnail.approvalMode !== "NONE" ? `bg-secondary-100 hover:bg-secondary-200/50 ` : `bg-gray-200/80 hover:bg-gray-200/50 `,
      )}
      onClick={() => {
        onClick(thumbnail);
      }}
    >
      <div className="flex items-start space-x-2">
        <div className="shrink-0 relative overflow-hidden h-10 w-8 py-1 px-0 bg-gradient-to-br from-primary-300 to-primary-700 rounded-md">
          {
            <>
              <motion.div variants={FileIconMotion} className="absolute top-1 left-0">
                <FileTypeIcon extension={thumbnail.extension} className="h-8 w-8 text-white" />
              </motion.div>
              <motion.div variants={DownloadMotion} className="absolute top-2 left-1">
                <ArrowTopRightOnSquareIcon className="h-6 w-6 text-white" />
              </motion.div>
            </>
          }
        </div>
        <div className="flex-1 break-all">
          <div className="font-medium text-primary-500 leading-4 break-all">{thumbnail.titre}</div>
        </div>    
        {!thumbnail.dateApproval && APPROBATION_READ === thumbnail.approvalMode && <div className="bg-red-700 h-2 w-2 rounded-full"></div>}
      </div>
      <div className="flex-1 px-1 text-xs text-justify italic">
        {thumbnail.description &&
          thumbnail.description.split("\n").map((text, i) => i ? [<br key={i} />, text] : text)
        }
      </div>
      <div className="flex items-end">

        <div className="flex-1">
          <div className="flex items-center space-x-1 my-1 pl-1 text-xs">
            <div className="flex items-center space-x-2 text-gray-700 text-xs font-medium">
              <div className="uppercase">{thumbnail.extension}</div>
            </div>
            <div className="h-1 w-1 rounded-full bg-primary-500"></div>
            <div className="font-medium flex items-center space-x-1">
              <div>Version</div>
              {thumbnail.version && <div><Badge rounded={false} narrowPadding bordered itemColor='gray' itemLibelle={thumbnail.version} /></div>}
            </div>
            <div className="font-medium">du {dayjs(thumbnail.dateModification).format("DD/MM/YYYY")}</div>
          </div>        
          {
            APPROBATION_READ === thumbnail.approvalMode && <div className="flex items-center space-x-1 font-medium pl-1 text-xs">
              {thumbnail.dateApproval
                ? <div>{p.t('documentation.approuve')} {dayjs(thumbnail.dateApproval).format("DD/MM/YYYY HH:mm")}</div>
                : <div>{p.t('documentation.nonApprouve')}</div>
              }
            </div>
          }
        </div>
        
        <Tooltip
          button={
            <div className="pt-1 ml-auto" onClick={(e)=> {
              e.stopPropagation()
              onCopyLink(thumbnail)         
            }}>
              <LinkIcon className="h-3 w-3"/>
            </div>    
          }
          content={
            <div className='text-xs'>{p.t("documentation.tooltips.lienCopie") }</div>
          }
          placement='left'
        />      
      </div>      
    </motion.div>

  </>
}