import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Widget from './Widget';
import WidgetSize from './WidgetSize';
import WidgetType from './WidgetType';
import Button from '../Button';
import { useP } from '../../services/i18n';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_WIDGET_CONTACT, DELETE_WIDGET_CONTACT, GET_WIDGETS, MOVE_WIDGET_CONTACT } from './queries';
import WidgetSelectionModale from './WidgetSelectionModal/WidgetSelectionModal';
import Spinner from '../Spinner';

type WidgetContainerType = {
  width: string,
  size: WidgetSize,
  direction: 'HORIZONTAL' | 'VERTICAL'
  zone: string
}


function compareWidget(wa, wb) {
  if (wa.order < wb.order)
    return -1;
  if (wa.order > wb.order)
    return 1;

  return 0;
}

type WContainerType = {
  widgets: Array<WidgetType>,
  container: WidgetContainerType,
  addWidget: any,
  moveUpWidget: any,
  moveDownWidget: any,
  removeWidget: any,
}

// function ResponsiveWidgetContainer() {

//   return (
//     <>

//     </>
//   )

// }

function WidgetContainer(props: WContainerType) {

  const p = useP();

  const addWidget = () => {
    props.addWidget(props.container.zone);
  }

  const moveUpWidget = (contactWidgetId) => {
    props.moveUpWidget(contactWidgetId, props.container.zone)
  }

  const moveDownWidget = (contactWidgetId) => {
    props.moveDownWidget(contactWidgetId, props.container.zone);
  }

  const removeWidget = (contactWidgetId) => {
    props.removeWidget(contactWidgetId, props.container.zone);
  }

  return (
    <div className={clsx(
      'overflow-hidden',
      `w-full lg:${props.container.width}`
    )}>
      <div className={clsx(
        'relative flex h-full overflow-y-auto ',
        props.widgets.length === 0 && 'border border-dashed rounded-md border-gray-400',
        props.container.direction == 'VERTICAL' ?
          'flex-col' :
          'flex-row'
      )}>
        <div className={clsx(
          'relative pr-2 h-full',
          props.container.direction === 'VERTICAL' ?
            'space-y-4' :
            'space-x-4'
        )}>
          {
            [...props.widgets]?.sort(compareWidget).map((widget, index) => (
              <Widget
                key={index}
                size={props.container.size}
                id={widget.contactWidgetId}
                code={widget.widgetCode}
                addWidget={addWidget}
                moveUpWidget={moveUpWidget}
                moveDownWidget={moveDownWidget}
                removeWidget={removeWidget}
              />
            ))
          }
          {
            props.widgets.length === 0 && (
              <div className='w-full h-full flex items-center justify-center'>
                <Button color='secondary' onClick={addWidget}>{p.t("widgets.header.addWidget")}</Button>
              </div>
            )
          }
        </div>
      </div>
    </div >
  )
}


type WidgetContainersType = {
  items: Array<WidgetContainerType>
}

function WidgetContainers(props: WidgetContainersType) {

  const [loadingData, setLoadingData] = useState(true)

  const [getWidgets, { data: widgetsData, loading: widgetsLoading }] = useLazyQuery(GET_WIDGETS, {
    fetchPolicy: "network-only",
    variables: {
      widgetZoneCodes: props.items.map(containers => containers.zone)
    }
  });

  const [widgets, setWidgets] = useState<any>({});

  const [currentZone, setCurrentZone] = useState<string | null>();

  const [isOpenSelectionModale, setOpenSelectionModale] = useState(false)

  const [addNewWidget] = useMutation(ADD_WIDGET_CONTACT);
  const [moveWidget] = useMutation(MOVE_WIDGET_CONTACT);
  const [deleteWidget] = useMutation(DELETE_WIDGET_CONTACT);

  const addWidget = (zone) => {
    setCurrentZone(zone);
    setOpenSelectionModale(true);
  }

  const saveNewWidget = (code) => {
    addNewWidget({
      variables: {
        widgetCode: code,
        widgetZoneCode: currentZone
      }
    }).then(() => loadWidgets())
  }

  const _moveWidget = (contactWidgetId, direction, zone) => {
    moveWidget({
      variables: {
        contactWidgetId,
        direction
      }
    });

    /** Reorganisation de la liste */
    const index = widgets[zone].findIndex((item => item.contactWidgetId === contactWidgetId));

    let tmp;
    switch (direction) {
      case 'UP':

        if (index == 0) break

        tmp = widgets[zone][index - 1];

        widgets[zone][index - 1] = widgets[zone][index]
        widgets[zone][index] = tmp

        break;

      case 'DOWN':

        if (index == widgets[zone].length - 1) break;

        tmp = widgets[zone][index + 1];

        widgets[zone][index + 1] = widgets[zone][index]
        widgets[zone][index] = tmp

        break;
    }

    setWidgets(widgets);

  }

  const moveUpWidget = (contactWidgetId, zone) => {
    _moveWidget(contactWidgetId, 'UP', zone);
  }

  const moveDownWidget = (contactWidgetId, zone) => {
    _moveWidget(contactWidgetId, 'DOWN', zone);
  }

  const removeWidget = (contactWidgetId, zone) => {
    deleteWidget({
      variables: {
        contactWidgetId
      }
    });

    /** Suppression du widget dans la liste */
    const res = widgets[zone].filter((item => item.contactWidgetId !== contactWidgetId))

    widgets[zone] = res

    setWidgets(widgets);
  }

  const onCloseWidgetSelectionModale = () => {
    setCurrentZone(null);
    setOpenSelectionModale(false);
  }

  const onSubmitWidgetSelectionModale = (code) => {
    setOpenSelectionModale(false);

    saveNewWidget(code)
  }

  const loadWidgets = () => {
    getWidgets();
  }

  useEffect(() => {

    if (widgetsData && !widgetsLoading) {

      const res = {};

      widgetsData.contactWidgets.forEach(contactWidget => {
        res[contactWidget.widgetZoneCode] = contactWidget.widgets
      });

      setWidgets(res);

      setLoadingData(false);
    }

  }, [widgetsData]);

  useEffect(() => {
    loadWidgets();
  }, []);

  return (
    <>
      <div className='flex flex-row w-full'>
        <div className='group flex flex-col lg:flex-row flex-1 w-full space-y-4 lg:space-y-0 lg:space-x-4 '>
          {
            loadingData ?
              (
                <div className='h-full w-full flex items-center justify-center'>
                  <Spinner />
                </div>
              ) :
              (
                props.items.map((container, index) => {
                  return <WidgetContainer
                    key={index}
                    widgets={widgets[container.zone] ? widgets[container.zone] : []} // TODO --> filter widgets
                    container={container}
                    addWidget={addWidget}
                    moveUpWidget={moveUpWidget}
                    moveDownWidget={moveDownWidget}
                    removeWidget={removeWidget}
                  />
                })
              )
          }
        </div>
      </div>
      <WidgetSelectionModale widgets={
        widgetsData?.widgetsByZone.filter(item => {

          const isOnThisZone = item.widgetZones.some(zone => zone.code === currentZone);

          const contactWidgetList = currentZone && Array.isArray(widgets[currentZone]) ? widgets[currentZone] : []

          const alreadyAdded = contactWidgetList.some(widget => widget.widgetCode === item.code)

          return isOnThisZone && !alreadyAdded
        })
      } isOpen={isOpenSelectionModale} onClose={onCloseWidgetSelectionModale} onSubmit={onSubmitWidgetSelectionModale} />
    </>
  )

}

export default WidgetContainers