import React, { useState } from 'react';
import ButtonsGroup from '../../../../Components/ButtonsGroup/ButtonsGroup';
import Table from '../../../../Components/Table/Table'
import { useP } from '../../../../services/i18n';
import { PrestationsViewType } from '../../PrestationsType';
import PrestationDetailModaleCENTILE from './PrestationDetailModaleCENTILE';

export default function CentileView({ siteId }: PrestationsViewType) {
  // Detail Prestation
  const [openModaleDetailPrestation, setOpenModaleDetailPrestation] = useState(false)
  const [modalePrestation, setModalePrestation] = useState(null)

  const onOpenModaleDetailPrestation = (row) => {
    setModalePrestation(row)
    setOpenModaleDetailPrestation(true)
  }
  const onCloseModaleDetailPrestation = () => {
    setOpenModaleDetailPrestation(false)
  }
  const editRow = (row) => { onOpenModaleDetailPrestation(row) }

  // Table Datas
  /*const { loading, data } = useQuery(GET_CENTILE, {
    variables: {
      idSite: siteId,
    },
  });*/

  const p = useP();

  const columns = [
    {
      libelle: p.t('prestations.centile.columns.etat'),
      code: 'etat',
      colType: 'state',
    },
    {
      libelle: p.t('prestations.centile.columns.site'),
      code: 'site.nom',
      colType: 'adresse',
      formatterObjectCode: 'site',
      sortable: true
    },
    {
      libelle: p.t('prestations.centile.columns.extension'),
      code: 'extension',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.centile.columns.denomination'),
      code: 'denomination',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.centile.columns.sda'),
      code: 'sda',
      colType: 'string'
    },
    {
      libelle: p.t('prestations.centile.columns.myIstra'),
      code: 'isMyistra',
      colType: 'boolean',
      sortable: true
    },
    {
      libelle: p.t('prestations.centile.columns.softphone'),
      code: 'isSoftphone',
      colType: 'boolean',
      sortable: true
    },
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: editRow,
      resizable: false
    },
  ]

  const filterButtons = [
    { libelle: p.t('etat.prestation.EN SERVICE'), value: "EN SERVICE" },
    { libelle: p.t('etat.prestation.INITIEE'), value: "INITIEE" },
    { libelle: p.t('etat.prestation.COMMANDE EN COURS'), value: "COMMANDE EN COURS" },
    { libelle: p.t('etat.prestation.COMMANDE REJETEE'), value: "COMMANDE REJETEE" },
    { libelle: p.t('etat.prestation.RESILIEE'), value: "RESILIEE" },
  ]

  const [filterEtatPresta, setFilterEtatPresta] = useState<string | string[]>(["EN SERVICE", "INITIEE", "COMMANDE EN COURS"]);


  return <>
    <Table
      idTable='table-centile'
      loading={false}
      datas={[]}
      columns={columns}
      customToolbarActions={
        <>
          <div className=''>
            <ButtonsGroup
              onSelectedButton={(value) => setFilterEtatPresta(value)}
              buttons={filterButtons}
              selectedButtons={filterEtatPresta}
              multi={true}
            />
          </div>
        </>
      }
      onClickRow={(row) => onOpenModaleDetailPrestation(row)}
      options={{
        search: {
          placeholder: p.t('prestations.searchPlaceholder'),
        },
        naturalWidth: true,
        emptyMessage: p.t('prestations.centile.noResultMessage')
      }}
    />
    <PrestationDetailModaleCENTILE
      prestation={modalePrestation}
      isOpen={openModaleDetailPrestation}
      onClose={onCloseModaleDetailPrestation}
    />
  </>
}