import React, { Fragment, useState } from 'react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { TableActionsType } from '../../Types/TableActionsType'
import ActionsItems from './ActionsItems'
import PopOver from '../../../PopOver'


type TableActionsFormatterType = {
  actions: Array<TableActionsType>,
  row: any,
}

function TableActionsFormatter({ actions, row }: TableActionsFormatterType) {

  return (
    <>
      <div className='cursor-pointer flex items-center justify-center'>
        <PopOver
          ButtonElement={
            <div className="cursor-pointer group flex items-center justify-center h-full w-full">
              <div className='relative flex items-center justify-center h-full w-full'>
                <EllipsisHorizontalIcon
                  className='h-5 w-5 text-gray-500 group-hover:text-secondary-500 group-focus:text-primary-700'
                />
              </div>
            </div>}
          ButtonClassName='relative flex flex-col rounded-md focus:ring-0 focus:outline-none focus:bg-primary-100 w-full group'
          PanelClassName='absolute z-40'
          PanelElement={<div><ActionsItems actions={actions} row={row} /></div>}
        />
      </div>
    </>
  )

}

export default TableActionsFormatter