import React from "react"
import LineChart from "../../../Components/Charts/LineChart"
import { generateColorPalette } from "../CyberViewDetail/utils";
import dayjs from "dayjs";

type CyberChartLineType = {
  chartData: any,
  chartDateTime?: Date
}

export default function CyberChartLine({ chartData, chartDateTime }: CyberChartLineType) {

  const optionsLineCharts = {
    responsive: true,
    elements: {
      point: {
        radius: 4,
        backgroundColor: "#FFF",
        borderWidth: 1,
      },
    },
    plugins: {
      ...(chartDateTime && {
        subtitle: {
          display: true,
          text: dayjs(chartDateTime).format("DD/MM/YYYY HH:mm:ss"),
          position: 'right',
        }
      }),
      colors: {
        enabled: true
      },
      tooltip: {
        enable: true,
      },
      legend: {
        labels: {
          boxHeight: 8,
          usePointStyle: true,
        },
        position: "bottom",
        align: "center",
      },
    },
  };

  const numColors = chartData.labels.length;
  const baseColors = ['#3b82f6', '#ec4899', '#14b8a6', '#f59e0b', '#a855f7', '#facc15'];

  return <LineChart
    datasetIdKey="lineChart"
    data={chartData}
    options={optionsLineCharts}
    colorPalette={generateColorPalette(numColors, baseColors)}
  />

}