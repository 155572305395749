import React from 'react'
import { ButtonsGroupItemButtonType } from './ButtonsGroupItem'
import ButtonsGroupItems from './ButtonsGroupItems'


type ButtonsGroupHorizontalType = {
  buttons: Array<ButtonsGroupItemButtonType>
  onClickRadio: (value: string) => void,
  selected: Array<string>
  multi?: boolean,
  forceHorizontalOnMobile?: boolean,
  selectAll?: boolean,
  prepend?: JSX.Element,
}

export default function ButtonsGroupHorizontal({
  buttons,
  onClickRadio,
  selected,
  multi,
  prepend,
  forceHorizontalOnMobile,
  selectAll
}: ButtonsGroupHorizontalType) {

  return (
    <>
      {prepend
        ? <div className='border border-gray-300 bg-gray-50 rounded-tl-md rounded-bl-md'>{prepend}</div>
        : <></>
      }
      {
        <ButtonsGroupItems
          buttons={buttons}
          onClickRadio={onClickRadio}
          selected={selected}
          multi={multi}
          prepend={prepend ? true : false}
          vertical={false}
          forceHorizontalOnMobile={forceHorizontalOnMobile}
          selectAll={selectAll}
        />
      }
    </>
  )

}