export type BonDeCommandeModeleType = {
  id: number,
  nom: string,
  type: string,
  fournisseur: string,
  modele: string,
  espaceClient: boolean
}

export const EmptyBonDeCommandeModele: BonDeCommandeModeleType = {
  id: 0,
  nom: '',
  type: '',
  fournisseur: '',
  modele: '',
  espaceClient: false
}