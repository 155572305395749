import React from "react"
import P from "../../../HelpBoxComponents/P"
import UL from "../../../HelpBoxComponents/UL"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentDashboardCompte_Q3() {
  const p = useP()
  return <>
    <P>
      <>{parse(p.t('helpBox.HelpDashboardCompte.a3.1'))}</>
    </P>
    <UL>
      <>{parse(p.t('helpBox.HelpDashboardCompte.a3.2'))}</>      
    </UL>
  </>
}