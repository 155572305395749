import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LockOpenIcon } from '@heroicons/react/24/outline'

import { useP } from '../../services/i18n';
import Button from '../../Components/Button';
import { Input } from '../../Components/Form/Input';

type ChangePasswordModaleType = {
  open: boolean,
  setOpen: (value: boolean) => void,
  submit: (otp: string) => void,
  loading: boolean,
}

function ChangePasswordModale(props: ChangePasswordModaleType) {

  const p = useP();

  const [value, setValue] = useState<string>('');

  const onKeyDown = (event) => {

    const { key } = event;

    switch (key) {
      case 'Enter':
        if (value) {
          props.submit(value);
        }
        break;

      case 'Escape':
        props.setOpen(false);
        break;
    }

  }

  const onSubmit = (e) => {
    e.preventDefault();
    props.submit(value);
  }

  return (
    <>

      <Transition.Root show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => false}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <form className="fixed inset-0 z-10 overflow-y-auto" onSubmit={onSubmit}>
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg" onKeyDown={onKeyDown}>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                        <LockOpenIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          {p.t('login.changePasswordModale.title')}
                        </Dialog.Title>

                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {p.t('login.changePasswordModale.subtitle')}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <Input name='email' type='email' className='w-full' value={value} onChange={e => setValue(e.target.value)} />
                    </div>
                  </div>
                  <div className="bg-gray-50 px-3 py-3 sm:flex sm:flex-row justify-end">

                    <div className="space-x-2">
                      <Button color='primaryLight' onClick={() => props.setOpen(false)}>{p.t('login.changePasswordModale.annuler')}</Button>
                      <Button color='primary' disabled={!value} loading={props.loading} type='submit'>{p.t('login.changePasswordModale.valider')}</Button>
                    </div>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </form>
        </Dialog>
      </Transition.Root>
    </>
  )

}

export default ChangePasswordModale;