import React from 'react'
import { ArrowSmallDownIcon, ArrowSmallUpIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import Tooltip from '../../Components/Tooltip/Tooltip'
import { useP } from '../../services/i18n'
import { multipleExistInArray } from '../../Components/Utils/Utils'

type TabNavigationLoginsType = {
  pingState?: number,
  loginStates? : any
}

export default function LoginState({ pingState,loginStates }: TabNavigationLoginsType) {
  // colors => emerald / orange / red
  
  let loginState;
  if(loginStates.length>1){
    const ismixte = multipleExistInArray(loginStates,[true, false])
    loginState = ismixte ? "MIXTE" : loginStates[0] ? "CONNECTE" : "DECONNECTE"
  }
  else if(loginStates.length===0){
    loginState=undefined
  }else{
    loginState = loginStates[0] ? "CONNECTE" : "DECONNECTE" ;
  }
  
  const p = useP();

  const colorsIpStatut = pingState === 1 ? "emerald" : pingState === 2 ? "orange" : "red";
  const libelleIpStatut = pingState === 1 ? p.t('supervisionLien.historiquePing.connecte') : pingState === 2 ? p.t('supervisionLien.historiquePing.instable') : p.t('supervisionLien.historiquePing.deconnecte');

  const colorState = loginState=="MIXTE" ? 'orange' : (loginState=="CONNECTE") ? 'emerald' : 'red'
  const infoState = loginState=="MIXTE" ? p.t('supervisionLien.historiquePing.mixte') : (loginState=="CONNECTE") ? p.t('supervisionLien.historiquePing.connecte') : p.t('supervisionLien.historiquePing.deconnecte')

  const states: any[] = []

  if (loginState !== undefined)
    states.push({
      type: "connexion",
      icon: loginState=="CONNECTE" || loginState=="MIXTE" ? <ArrowSmallUpIcon className='h-3 w-3 ' /> : <ArrowSmallDownIcon className='h-3 w-3 ' />,
      color: `bg-${colorState}-200 text-${colorState}-800 border-${colorState}-700`,
      infos: infoState
    })

  if (pingState !== undefined)
    states.push({
      type: "ping",
      icon: <ArrowsUpDownIcon className='h-3 w-3 ' />,
      color: `bg-${colorsIpStatut}-200 text-${colorsIpStatut}-800 border-${colorsIpStatut}-700`,
      infos: `Etat du ping : ${libelleIpStatut}`
    })

  return <>
    <div className='ml-0 md:ml-2 mt-3 md:mt-0 mb-2 md:mb-0 flex items-center'>
      {
        states.map((state, i) => (
          <Tooltip
            key={i}
            button={<div className={clsx(`border rounded-full p-1`, state.color)}>
              {state.icon}
            </div>}
            content={<div className='text-xs'>{state.infos}</ div>}
            placement='top'
            className='flex items-center justify-center mr-3'
          />
        ))
      }

    </div>
  </>
}