import React, { MouseEvent, ReactNode } from 'react'
import Thead from './Thead'
import Tbody from './Tbody'
import { ColumnType } from './Types/ColumnType'
import { TableOptionsType } from './Types/TableOptionsType'
import { TableDetailRenderType } from './Types/TableDetailRenderType'
import clsx from 'clsx'
import Pagination from '../Pagination/Pagination'
import Toolbar from '../Toolbar/Toolbar'
import ToolbarSearch from '../Toolbar/ToolbarSearch'
import { TableRuptureType } from './Types/TableRuptureType'
import { SortingType } from './Thead'

type TableContainerType = {
  idTable: string
  columns: Array<ColumnType>,
  rupture: TableRuptureType,
  datas: Array<object>,
  loading: boolean,
  error?: boolean,
  options: TableOptionsType,
  currentPage: number,
  pageSize: number,
  totalCount: number,
  customToolbarActions?: ReactNode
  tableHeight: number,
  refTable: any | null,
  colWidthResize?: colWidthResizeType | undefined
  className?: string,
  detailComponentRender?(renderProps: TableDetailRenderType): ReactNode,
  onPaginate: (page: number, size: number) => void,
  onSearch: (search: string) => void,
  onSelectRow?: (row: object, checked: boolean) => void | undefined,
  onResize: (width: string, index: number) => void,
  onDoubleClickRow?: (row: object) => void,
  onClickRow?: (row: any, event: MouseEvent) => void,
  onSort?: (column: ColumnType, direction: SortingType) => void,
  defaultSortColumn?: { column: ColumnType, direction: SortingType },
  selectedRows: Array<number>,
  selectAll: () => void
}

export type colWidthResizeType = {
  width: string | number,
  index: number
}

function TableContainer({
  idTable,
  loading,
  error = false,
  columns,
  rupture,
  datas,
  options,
  currentPage,
  pageSize,
  totalCount,
  customToolbarActions,
  tableHeight,
  refTable,
  className = '',
  defaultSortColumn,
  selectedRows,
  colWidthResize,
  onPaginate,
  onSearch,
  detailComponentRender,
  onSelectRow,
  onDoubleClickRow,
  onClickRow,
  onResize,
  onSort,
  selectAll
}: TableContainerType) {

  return (
    <div className='flex flex-col h-full justify-between flex-1 overflow-hidden'>
      {options.toolbar &&
        <Toolbar>
          <div className="ml-0 mb-2 lg:mb-0 w-full lg:w-auto">
            {options.search && <ToolbarSearch placeholder={options.search?.placeholder ? options.search.placeholder : ''} onSearch={onSearch} />}
          </div>
          <div className='w-full lg:w-auto flex flex-col items-end justify-center lg:flex-row '>
            {customToolbarActions}
          </div>
        </Toolbar>
      }
      <div
        className={clsx(`overflow-auto border border-gray-200 lg:rounded-lg flex-1 relative`, className, `tableContainer`)}
      >

        <table
          className={clsx(
            `w-full`,
            (datas.length === 0 || loading) && `h-full`
          )}
          ref={refTable && refTable}
        >
          <Thead
            columns={columns}
            tableOptions={options}
            tableHeight={tableHeight}
            colWidthResize={colWidthResize}
            onResize={onResize}
            onSort={onSort}
            defaultSortColumn={defaultSortColumn}
            selectAll={selectAll}
            totalCount={totalCount}
            selectedRows={selectedRows}
          />
          <Tbody
            loading={loading}
            error={error}
            columns={columns}
            rupture={rupture}
            datas={datas}
            tableOptions={options}
            detailComponentRender={detailComponentRender}
            colWidthResize={colWidthResize}
            emptyMessage={options.emptyMessage}
            selectedRows={selectedRows}
            onResize={onResize}
            onDoubleClickRow={onDoubleClickRow}
            onClickRow={onClickRow}
            onSelectRow={onSelectRow}
          />
        </table>
      </div>

      {
        (options.pagination && datas.length > 0) &&
        <div className='flex items-center justify-end '>
          <Pagination
            idTable={idTable}
            onPaginate={onPaginate}
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={totalCount}
            optionsPageSize={options?.pagination && options.pagination.optionsPerPage}
          />
        </div>
      }
    </div>
  )

}

export default TableContainer