import React, { ReactNode } from 'react'
import MultiSelectSearchBoxItem from './MultiSelectSearchBoxItem'
import { MultiSelectOptionType } from './Types/MultiSelectOptionType'

type MultiSelectSearchBoxListType = {
  datas: Array<MultiSelectOptionType>,
  children: ReactNode
}
export default function MultiSelectSearchBoxList({ datas, children }: MultiSelectSearchBoxListType) {
  return <>
    <div className="flex items-center flex-auto flex-wrap">
      {
        datas.map((selectedItem, indexItem) => (<MultiSelectSearchBoxItem item={selectedItem} key={indexItem} />))
      }
      {children}
    </div>
  </>
}