import React from "react"
import Modal from "../Modal/Modal"
import PdfViewer from "./PdfViewer"

type ModalPdfViewerType = {
  isOpen: boolean,
  file: string,
  fileDownload?: string,
  onClose: () => void,
  title: string,
  footerComponent: JSX.Element,
  scrollBarIsAtBottom: (AtBottom: boolean) => void
}

export default function ModalPdfViewer({ isOpen, file, fileDownload, onClose, title,footerComponent, scrollBarIsAtBottom }: ModalPdfViewerType) {

  return <>
    <Modal
      onClose={onClose}
      //fullscreen
      isOpen={isOpen}
      content={{
        title: (
          <>
            <div className='flex items-center'>
              {title}
            </div>
          </>
        ),
        body: <>
          <PdfViewer
            file={file}
            fileDownload={fileDownload || file}
            scrollBarIsAtBottom={scrollBarIsAtBottom}
          /></>,

        footer: footerComponent,
      }}
      className="sm:w-5/6 lg:w-5/6 xl:w-1/2"
    />
  </>
}