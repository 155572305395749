import React from "react"
import { useQuery } from "@apollo/client";
import { BALANCE_INFOS } from "../../../queries";
import Constants from "../../../../../commons/Constants";
import Spinner from "../../../../../Components/Spinner";
import Select from "../../../../../Components/Select/Select";

const {
  GSM_FOURNISSEUR_EIT
} = Constants;

type ActionInformationsBalanceDataType = {
  prestationAffiche: any,
  onSaveCompleted: (any: any) => void,
}

export default function ActionInformationsBalanceData({ prestationAffiche, onSaveCompleted }: ActionInformationsBalanceDataType) {
  //Récupération des informations pour les recharge balances
  const ligne = prestationAffiche?.ligne;

  const { loading, data } = useQuery(BALANCE_INFOS, {
    variables: {
      livraisonId: parseInt(prestationAffiche?.abonnementLivraisonId),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const onSaveLigne = async (result) => {
    await onSaveCompleted(result);
    return true;
  }

  const selectRecharge = [{ code: 0, libelle: 'Désactivé' }, { code: 80, libelle: `Dès 80% de l'enveloppe DATA` }, { code: 100, libelle: `A 100% de l'enveloppe DATA` }]
  const defaulRecharge = ligne?.rechargeAuto ? selectRecharge.find((opt) => opt.code === parseInt(ligne?.rechargeAuto)) : selectRecharge.find((opt) => opt.code === 0);
  const selectDatas = data?.informationsBalances.map((e) => { return { code: e.value, libelle: e.label } });
  selectDatas?.unshift({ code: null, libelle: '---' });
  const defaultValue = selectDatas?.find((opt) => opt.code === ligne?.rechargeAutoOption);

  const prixEIT = data?.informationsBalances?.find(
    (i) => i.label === "prixVente"
  );

  return <>
    <div className="flex items-center space-x-2">
      <div className="w-full">
        {loading ? <Spinner /> :
          <>
            <div>
              <Select
                size='sm'
                label=""
                inlineLabel={true}
                defaultValue={defaulRecharge}
                selectDatas={selectRecharge}
                onSelect={(val) => onSaveLigne({ rechargeAuto: val.code })}
              />
            </div>
            {prestationAffiche?.ligne?.gsmSim?.fournisseurId === GSM_FOURNISSEUR_EIT ?
              <div className="relative w-full  pl-2 text-left text-sm pt-1">Prix de la recharge : <span className="font-semibold italic">{prixEIT?.value?.length ? prixEIT.value : 'Non défini'}</span></div>
              : <div>
                <Select
                  size='sm'
                  label=""
                  inlineLabel={true}
                  defaultValue={defaultValue}
                  selectDatas={selectDatas}
                  onSelect={(val) => onSaveLigne({ rechargeAutoOption: val.code })}
                />
              </div>
            }
          </>
        }


      </div>
    </div>
  </>
}