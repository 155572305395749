import { gql } from "@apollo/client";

const GET_DONNEES_REFERENCE_MODALE = gql`
  query GetDonneesReference {
    postes {
      code
      libelle
    }

    civilites {
      code
      libelle
    }  
  }  
`

const GET_TYPES_ROLES = gql`
query TypeRoles (
  $filters: [FilterInput]
  $sorters: [SorterInput]
  $pagination: PaginationInput
  ) {
  typeRoles (
    filters: $filters
      sorters: $sorters
      pagination: $pagination
  ) {
    descriptif
    isMulticompte
    saisieSite
    saisieContrat
    libelle
    code
  }
}`;

const GET_CONTACT = gql`
  query GetContact($idContact: ID!) {
    contact(idContact: $idContact) {
      totalCount
      items {
        id
        nom
        prenom
        telephoneFixe
        telephoneMobile
        mail
        isActif
        isMultiCompte
        isMailMaintenance
        isAvisIncident
        isNewsCommerciale
        isNewsTechnique
        isNewsletterMensuelle
        isRegroupementFactureMail
        isFacture
        isFactureHierarchie
        isContactProjets
        isContactSupervision
        isContactSupervisionHierarchie
        isMailingActif
        civilite {
          code
          libelle
        }
        poste {
          code
          libelle
        }  
        prestations {
          id
          libelle       
        }
        compte {
          id
          raisonSociale
        }
      }    
    }
  }`;

const GET_CONTACTS = gql`
  query GetContacts(
    $pagination: PaginationInput
    $sorters: [SorterInput]
    $filters: [FilterInput]
  ) {
    contacts(
      filters: $filters
      sorters: $sorters
      pagination: $pagination
    ) {
      totalCount
      items {
        id
        nom
        prenom
        telephoneFixe
        telephoneMobile
        mail
        isActif
        isMultiCompte
        isRegroupementFactureMail
        isFacture
        poste {
          code
          libelle
        }
        roleContacts {
          id
          typeRole{
            code
          }
        }
        compte {
          raisonSociale
        }
      }
    }
  }`;

const GET_ROLES_CONTACT = gql`
  query roleContacts(
    $idContact: ID!
    $sorters: [SorterInput]
    $pagination: PaginationInput
  ) {
    roleContacts(
      idContact: $idContact
      sorters: $sorters
      pagination: $pagination      
      ) {
      id
      contrat {
        numcli
        nom
        numeroContrat
        nomContrat
        adresse
        cp
        ville            
      }
      site {
        id
        siret
        nom
        raisonSociale
        libelleVoie
        numeroVoie
        codePostal
        commune
      }
      typeRole {
        code
        libelle
        saisieContrat
        saisieSite
        isMulticompte
        descriptif
      }
      isMulticompte
    }
  }`;


const SAVE_CONTACT = gql`
  mutation SaveContact($contact: ContactInput) {
    saveContact(contact: $contact) {
      id
      nom
      prenom
      mail
      isMultiCompte
    }
  }`;


const ADD_ROLE_CONTACT = gql`
  mutation AddRoleContactToContact($roleContact: [RoleContactInput]!) {
    addRoleContactToContact(roleContact: $roleContact) {
      message
      success
    }
  }`

const DELETE_ROLECONTACT = gql`
  mutation DeleteRoleContactFromContact($idRoleContact: [ID]!) {
    deleteRoleContactFromContact(idRoleContact: $idRoleContact)
  }`

const DELETE_CONTRAT_FOR_ROLECONTACT = gql`
  mutation Mutation($idRoleContact: ID!, $numcli: ID!) {
    deleteContratForRoleContact(idRoleContact: $idRoleContact, numcli: $numcli)
  }`

const DELETE_SITE_FOR_ROLECONTACT = gql`
  mutation Mutation($idRoleContact: ID!, $siteId: ID!) {
    deleteSiteForRoleContact(idRoleContact: $idRoleContact, siteId: $siteId)
  }`

const ADD_CONTRAT_TO_ROLECONTACT = gql`
  mutation AddContratToRoleContact($idRoleContact: ID!, $numcli: ID!) {
    addContratToRoleContact(idRoleContact: $idRoleContact, numcli: $numcli) {
      success
      message
    }
  }`

const ADD_SITE_TO_ROLECONTACT = gql`
  mutation AddContratToRoleContact($idRoleContact: ID!, $siteId: ID!) {
    addSiteToRoleContact(idRoleContact: $idRoleContact, siteId: $siteId) {
      success
      message
    }
  }`

const GET_SITES = gql`
  query Sites (
    $filters: [FilterInput]
    $sorters: [SorterInput]
    $pagination: PaginationInput
  ) {
    sites (
      filters: $filters
      sorters: $sorters
      pagination: $pagination
    ) {
      id
      nom
      numeroVoie
      libelleVoie
      codePostal
      commune
    }
  }`

const GET_CONTRATS = gql`
  query Clients {
    clients {
      items {
        numcli
        nom
        adresse
        cp
        ville
        nomContrat
        numeroContrat
      }
    }
  }`

const CHECK_EMAIL = gql`
  query CheckEmailContactExiste($mail: String, $contactId: ID) {
    checkEmailContactExiste(mail: $mail, contactId: $contactId)
  }
  `;

const GET_COMPTES = gql`
  query GetComptes($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    comptes(filters: $filters, sorters: $sorters, pagination: $pagination){
      totalCount
      items {
        id
        raisonSociale
      }
    }
  }
`

export {
  GET_DONNEES_REFERENCE_MODALE,
  GET_TYPES_ROLES,

  GET_CONTACT,
  GET_CONTACTS,
  GET_ROLES_CONTACT,
  SAVE_CONTACT,
  ADD_ROLE_CONTACT,
  DELETE_ROLECONTACT,
  DELETE_CONTRAT_FOR_ROLECONTACT,
  DELETE_SITE_FOR_ROLECONTACT,
  GET_SITES,
  ADD_CONTRAT_TO_ROLECONTACT,
  ADD_SITE_TO_ROLECONTACT,
  GET_CONTRATS,
  CHECK_EMAIL,
  GET_COMPTES
}