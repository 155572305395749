import { gql } from "@apollo/client"

const GET_TICKETS = gql`
  query Tickets($filters: [FilterInput], $sorters: [SorterInput]) {
    tickets(filters: $filters, sorters: $sorters) {          
      items {
        id
        idExterne
        numero
        titre
        description
        ticketCriticite {
          libelle
          backgroundColor
          fontColor
        }
        compte {
          raisonSociale
        }
        ticketStatut {
          id
          statutPublic
        }
      }
    }   
  }`

const GET_TICKET_STATUTS = gql`
  query TicketStatuts($filters: [FilterInput], $sorters: [SorterInput]){
    ticketStatuts(filters: $filters, sorters: $sorters) {
      items {
        id
        statutPublic      
      }
    } 
  }`

export {
  GET_TICKETS,
  GET_TICKET_STATUTS
}