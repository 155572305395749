import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKET } from "./queries";
import Spinner from "../Components/Spinner";
import Error from "../Components/Error/Error";
import TicketPublicContainer from "./TicketPublicContainer";
import queryString from 'query-string'
import secureLocalStorage from "react-secure-storage";

function TicketPublic() {

  const params = queryString.parse(location.search);

  const [getTicket, { loading: loadingTicket, data: dataTicket, error: errorTicket }] = useLazyQuery(GET_TICKET, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      filters: [
        {
          column: "accessToken.token",
          value: params?.key,
        },
      ],
      pagination: {
        limit: 1,
      },
    }
  });

  useEffect(() => {
    secureLocalStorage.setItem(
      "loggedInUser",
      {
        success: true,
        token: params?.key,
      }
    );

    getTicket()

    return () => {
      // Anything in here is fired on component unmount.
      secureLocalStorage.removeItem("loggedInUser")
    }
  }, [])

  if (loadingTicket) {
    return <div className="w-screen h-screen flex items-center justify-center"><Spinner /></div>
  }

  if (errorTicket) {
    return <div className="w-screen h-screen flex items-center justify-center"><Error /></div>
  }

  return dataTicket?.tickets?.items?.length === 1 ? <TicketPublicContainer ticket={dataTicket.tickets.items[0]} /> : null
}

export default TicketPublic;

