import React from "react"
import Datepicker, { registerLocale } from "react-datepicker";
import "./react-datepicker.css";
import fr from "date-fns/locale/fr";
import DatepickerHeader from "./DatepickerHeader";
import clsx from "clsx";
import { FieldError } from "react-hook-form";
import { useP } from "../../services/i18n";
registerLocale("fr", fr);

type DatepickerContainerType = {
  stateDate: Date | null,
  startDate?: Date | null,
  endDate?: Date | null,
  minDate?: Date | null,
  maxDate?: Date | null,
  onChange: (date: Date) => void,
  className?: string
  showTimeSelect?: boolean
  dateFormat?: string
  locale?: string,
  required?: boolean,
  filterDate?: (date: Date) => void,
  error?: FieldError,
  yearPicker?: boolean,
  monthPicker?: boolean,
  selectsStart?: boolean,
  selectsEnd?: boolean,
}

export default function DatepickerContainer({
  stateDate,
  onChange,
  className,
  showTimeSelect = false,
  dateFormat = "dd/MM/yyyy",
  error,
  locale = "fr",
  required = false,
  yearPicker = true,
  monthPicker = true,
  selectsStart = false,
  selectsEnd = false,
  minDate,
  startDate,
  endDate,
  maxDate,
  filterDate
}: DatepickerContainerType) {
  const p = useP();
  return <>
    <Datepicker
      showTimeSelect={showTimeSelect}
      timeCaption={p.t("components.datepicker.timeCaption")}
      showPopperArrow={false}
      selected={stateDate}
      onChange={(date: any) => onChange(date)}
      dateFormat={dateFormat}
      locale={locale}
      filterDate={filterDate}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      minDate={minDate}
      endDate={endDate}
      maxDate={maxDate}
      startDate={startDate}
      className={clsx(
        "border-gray-300 rounded-md text-sm font-normal",
        className)}
      required={required}

      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => <DatepickerHeader
          date={date}
          changeYear={changeYear}
          changeMonth={changeMonth}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
          yearPicker={yearPicker}
          monthPicker={monthPicker}
        />}
    />
    {
      error && <div className='text-red-600 text-xs font-medium mt-0.5'>{error.message}</div>
    }
  </>
}