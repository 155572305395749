import React from 'react'
import { Listbox } from '@headlessui/react'

import { CompteType } from '../../../../types/CompteType'
import clsx from 'clsx'

type CompteItemProps = {
  index: number,
  compte: CompteType,
  fieldValue: string,
  fieldLabel: string,
  modeMulticompte: boolean
}

function CompteItem({ index, compte, fieldValue, fieldLabel, modeMulticompte }: CompteItemProps) {

  return (

    <Listbox.Option
      key={index}
      className={({ active }) =>
        clsx(
          active && 'bg-primary-100',
          'cursor-pointer select-none relative py-2 pl-8 pr-4 text-primary-700'
        )
      }
      value={compte[fieldValue]}
    >
      {({ selected, active }) => (
        <>
          <span className={clsx(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
            {compte[fieldLabel]}
          </span>

          <span
            className={clsx(
              active ? 'text-primary-600' : 'text-primary-600',
              'absolute inset-y-0 left-0 flex items-center pl-2'
            )}
          >

            <input
              type={modeMulticompte ? "checkbox" : "radio"}
              className={clsx(
                "h-4 w-4  border-gray-300 text-blue-600 focus:ring-blue-500",
                modeMulticompte ? "rounded" : "rounded-full"
              )}
              checked={!!selected}
            />
          </span>
          {/* ) : null} */}
        </>
      )}
    </Listbox.Option>
  )
}

export default CompteItem
