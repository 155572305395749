import React, { useEffect, useState } from "react";
import queryString from 'query-string'
import SatisfactionProjetContainer from "./SatisfactionProjetContainer";
import secureLocalStorage from "react-secure-storage";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_WRIKE_ENQUETE, SAVE_WRIKE_ENQUETE } from "./queries";
import Spinner from "../Components/Spinner";
import Error from "../Components/Error/Error";
import { toastError, toastSuccess } from "../Components/Utils/Utils";
import dayjs from "dayjs";
import { useP } from "../services/i18n";

const MAX_NOTE = 5;

function SatisfactionProjet() {

  const p = useP()

  const params = queryString.parse(location.search);

  const noteDefault = params.note && !Array.isArray(params.note) ? parseInt(params.note, 10) : MAX_NOTE

  const [note, setNote] = useState<number>(noteDefault);
  const [commentaire, setCommentaire] = useState<string>('');

  const [saveWrikeEnquete] = useMutation(SAVE_WRIKE_ENQUETE);

  const [getWrikeEnquete, { loading, error, data }] = useLazyQuery(GET_WRIKE_ENQUETE, {
    variables: {
      id: params?.id
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    secureLocalStorage.setItem(
      "loggedInUser",
      {
        success: true,
        token: params?.key,
      }
    );

    getWrikeEnquete()

    return () => {
      // Anything in here is fired on component unmount.
      secureLocalStorage.removeItem("loggedInUser")
    }
  }, [])

  useEffect(() => {
    if (data?.wrikeProjetEnquete) {
      setNote(data.wrikeProjetEnquete?.noteGlobale ? data.wrikeProjetEnquete?.noteGlobale : noteDefault)
      setCommentaire(data.wrikeProjetEnquete?.commentaire || '')
    }
  }, [data])

  const submitEnquete = () => {
    saveWrikeEnquete({
      variables: {
        wrikeProjetEnquete: {
          id: params?.id,
          dateReponse: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          dateModification: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          commentaire,
          noteGlobale: note
        },
      },
    })
      .then(({ data }) => {

        const { saveWrikeProjetEnquete } = data;

        if (saveWrikeProjetEnquete) {
          toastSuccess(p.t('satisfactionProjet.form.success'));
        } else {
          toastError(p.t('satisfactionProjet.form.errorSave'));
        }
      })
      .catch(() => {
        toastError(p.t('satisfactionProjet.form.errorReq'));
      });
  }

  if (loading) {
    return <div className="w-screen h-screen flex items-center justify-center"><Spinner /></div>
  }

  if ((error || !data?.wrikeProjetEnquete || data?.wrikeProjetEnquete?.wrikeProjet?.code !== params?.projet)) {
    return <div className="w-screen h-screen flex items-center justify-center"><Error /></div>
  }

  return (
    <>
      <div className="min-h-full flex bg-white">
        <SatisfactionProjetContainer
          commentaire={commentaire}
          setCommentaire={setCommentaire}
          note={note}
          setNote={setNote}
          nom={data.wrikeProjetEnquete?.wrikeProjet?.nom}
          submit={submitEnquete}
        />
      </div>
    </>
  );
}

export default SatisfactionProjet;

