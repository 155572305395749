import clsx from "clsx"
import React from "react"
import { DescriptionListItemType } from "./DescriptionList"

type DescriptionListItemComponentType = {
  item: DescriptionListItemType,
  columnWidthClassName: string | null,
  bordered: boolean,
  last: boolean
}

export default function DescriptionListItem({ item, columnWidthClassName, bordered, last }: DescriptionListItemComponentType) {

  let alignClass = '';

  switch (item.align) {
    case "bottom":
      alignClass = "items-end"
      break;

    case "top":
      alignClass = "items-start"
      break;

    case "center":
    default:
      alignClass = "items-center"
      break;
  }

  return <>
    <div className={clsx(`py-2 flex flex-col lg:flex-row`,
      bordered && `pl-2`,
      !last && `border-b border-gray-200`)}>
      <dt
        className={
          clsx(
            `text-sm font-medium text-gray-500 flex`,
            alignClass,
            columnWidthClassName,
            columnWidthClassName === null && `flex-1`
          )
        }
      >{item.label}</dt>
      <dd className="flex-1 mt-0 pr-2 text-sm text-gray-900">{item.value}</dd>
    </div>
  </>
}