import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react"
import { toastError } from "../../../Components/Utils/Utils";
import { useP } from "../../../services/i18n";
import { SAVE_PRESTATION } from "../queries";
import { getErrorMessage } from "../../../utils/utils";
import SelectionContactEtPersonne from "../../../Components/SelectionContactEtPersonne/SelectionContactEtPersonne";

type PrestationContactType = {
  prestation: any,
  onSaveCompleted: (any: any) => void,
}

export default function PrestationContact({ prestation, onSaveCompleted }: PrestationContactType) {
  const p = useP();
  const [savePrestation, { loading: loadingSavePrestation }] = useMutation(SAVE_PRESTATION, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const onSavePersonne = async (result) => {
    //cas de la mise à jour du contact
    if (prestation.contact.id === result.id) {
      onSaveCompleted(result)
      return true;
    }

    //cas de l'association à un nouveau contact
    await savePrestation({
      variables: {
        prestation: {
          id: parseInt(prestation?.id),
          contactId: parseInt(result.id)
        }
      },
      onCompleted: (data) => {
        if (data.savePrestation.success) {
          onSaveCompleted(result)
          return true;
        } else {
          toastError(getErrorMessage(p, data.savePrestation.message));
          return false;
        }
      },
      onError: (error) => {
        console.error(error)
        toastError(p.t('error.defaultMessage'))
        return false;
      }
    })
    return true;
  }

  return <>
    <div className="flex items-center space-x-2">
      <div className="w-full">
        <SelectionContactEtPersonne
          onSelectPersonne={(result) => { onSavePersonne(result.obj) }}
          entity={prestation}
          contactIsEditable={true}
          displayEmail={true}
          filters={
            [
              {
                column: 'compte.id',
                value: `${prestation?.client?.compte.id}`
              }
            ]
          }

        />
      </div>
    </div>
  </>
}