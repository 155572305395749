import React, { useEffect } from 'react'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { BreadcrumbDataType } from '../../Components/Breadcrumb/BreadcrumbDataType';
import TableContrats from './TableContrats';
import { toastError } from '../../Components/Utils/Utils';


type ContratsContainerType = {
  breadcrum: Array<BreadcrumbDataType>,
}

function ContratsContainer({ breadcrum }: ContratsContainerType) {

  return (
    <>
      <Breadcrumb datas={breadcrum} />
      <TableContrats />
    </>
  )

}

export default ContratsContainer;