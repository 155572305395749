import React from 'react'
import { useMutation, useQuery } from '@apollo/client';
import Constants from '../../../commons/Constants';
import Banner from '../../../Components/Banner';
import Breadcrumb from '../../../Components/Breadcrumb/Breadcrumb';
import { BreadcrumbDataType } from '../../../Components/Breadcrumb/BreadcrumbDataType';
import Card from '../../../Components/Card';
import CheckRigth, { useCheckRight } from '../../../Components/CheckRight/CheckRight';
import Tabs, { TabsNavigationType } from '../../../Components/Tabs/Tabs';
import { useP } from '../../../services/i18n';
import { GET_CONTRAT, GET_DEVIS_ACTIFS, SAVE_CONTRAT } from '../queries';
import ExportsGSM from '../../ExportsGSM/ExportsGSM';
import Devis from './Devis';
import Spinner from '../../../Components/Spinner';
import Editable from '../../../Components/Editable/Editable';
import { toastError, toastSuccess } from '../../../Components/Utils/Utils';


type ContratContainerType = {
  breadcrumb: Array<BreadcrumbDataType>,
  navigation: Array<TabsNavigationType>,
  panels: Array<JSX.Element>,
  numcli: string
}

export default function ContratContainer({ breadcrumb, navigation, panels, numcli }: ContratContainerType) {
  const {
    ROLE_ACCES_PRESTATION,
    ROLE_ADMIN_CONTRAT,
  } = Constants;
  const p = useP();
  const { checkRight } = useCheckRight();
  const rightAdminContrat = checkRight(ROLE_ADMIN_CONTRAT);

  const { loading, data } = useQuery(GET_CONTRAT, {
    variables: {
      id: numcli,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const { loading: loadingDevis, data: dataDevis } = useQuery(GET_DEVIS_ACTIFS, {
    variables: {
      numContrat: numcli
    },
  });

  const [saveContrat] = useMutation(SAVE_CONTRAT);

  const saveNomPersoContrat = async (value: string) => {
    let result = true;
    await saveContrat({
      variables: {
        client: {
          numcli,
          nom: value
        }
      },
      onCompleted: () => {
        toastSuccess(p.t('error.defaultEnregistrement'));
      }
    }).catch(() => {
      toastError(p.t('error.defaultMessage'));
      result = false
    })

    return result;
  }

  if (loading || loadingDevis)
    return <><div className='h-full w-full flex items-center justify-center'><Spinner color='primary' /></div></>

  return <>
    <Breadcrumb datas={breadcrumb} />
    <div className="h-full flex flex-col lg:flex-row lg:flex-1 lg:overflow-hidden">

      <div className='lg:mr-6 lg:max-w-md'>
        <div className='flex flex-col h-full pb-0.5'>
          <Banner itemsCenter='lg:items-start' >
            <Banner.Left>
              <div className='flex flex-col items-start flex-1'>
                <div className="text-sm w-full">
                  <div className='flex items-center'>
                    {
                      rightAdminContrat
                        ? <Editable
                          className='text-lg font-bold text-white'
                          value={data?.client?.nom}
                          onSaveField={saveNomPersoContrat}
                          inputClass="w-full px-2 py-1 border-0 italic shadow-none rounded-md bg-white/20 focus:outline-none focus:ring-0"
                        />
                        : <div className="text-lg font-bold text-white flex-1">{data?.client?.nom}</div>
                    }
                  </div>
                  <div className="text-sm font-semibold text-white mb-1">{data?.client?.nomContrat}</div>
                  <div className="text-white/80">{p.t('contrats.detail.numContrat')} : {numcli}</div>
                </div>
                <div className='h-1 w-1/4 bg-gray-200 rounded-full my-4'></div>
                <div className="text-sm w-full ">
                  <div className="text-md font-bold mb-1 text-white ">{p.t('contrats.detail.siteFacturation')} :</div>
                  <div className="text-white/80">{data?.client?.siteFacturation?.numeroVoie} {data?.client?.siteFacturation?.libelleVoie}</div>
                  <div className="text-white/80">{data?.client?.siteFacturation?.codePostal} {data?.client?.siteFacturation?.commune}</div>
                </div>
              </div>
            </Banner.Left>
          </Banner>
          {dataDevis?.devisActifs && <Devis key='0' datas={dataDevis?.devisActifs} ></Devis>}
          <div className='flex-1'>
            <CheckRigth role={ROLE_ACCES_PRESTATION}>
              <Card className={{ root: "h-auto" }}>
                <Card.Header className='text-lg'>{p.t('dashboardCompte.downloads')}</Card.Header>
                <div className='p-2'><ExportsGSM numcli={numcli} /></div>
              </Card>
            </CheckRigth>
          </div>
        </div>
      </div>

      <div className='lg:flex-1 lg:flex lg:flex-col  lg:overflow-hidden'>
        <div className='lg:flex-1 lg:flex lg:flex-col  lg:overflow-hidden'>
          <div className='lg:flex-1 lg:flex lg:flex-col  lg:overflow-hidden'>
            <Tabs
              navigation={navigation}
              panels={panels}
              cardStyle={true}
            />
          </div>
        </div>
      </div>

    </div>
  </>
}