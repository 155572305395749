import React from "react"
import H2 from "../../../HelpBoxComponents/H2"
import IMG from "../../../HelpBoxComponents/IMG"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentPrestations_Q1(){
  const p = useP();
  return <>
    <P>{parse(p.t('helpBox.helpPrestations.a1.1'))}</P>
    
    <H2>{parse(p.t('helpBox.helpPrestations.a1.2'))}</H2>
    
    <P><>{parse(p.t('helpBox.helpPrestations.a1.3'))}</></P>
    <div className="mt-1">
      <IMG src="/images/helpBox/liens_carte-liste.png" sizes={{width:120}} />
    </div>
    
    <H2>{parse(p.t('helpBox.helpPrestations.a1.4'))}</H2>
    <P><>{parse(p.t('helpBox.helpPrestations.a1.5'))}</></P>

    <H2>{parse(p.t('helpBox.helpPrestations.a1.6'))}</H2>
    <P><>{parse(p.t('helpBox.helpPrestations.a1.7'))}</></P>    
  </>
}