import React from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

export type OptionSelectType = {
  code: string | number,
  libelle: string
}

type SelectType = {
  onSelect: (selectedElement: any) => void,
  defaultValue?: OptionSelectType | null,
  selectDatas: Array<OptionSelectType>,
  className?: string,
  buttonClassName?: string,
  label?: string | null,
  inlineLabel?: boolean,
  size?: "sm" | "xs" | "base" | "md",
  disabled?: boolean
}

export default function Select({ selectDatas, onSelect, disabled = false, defaultValue = null, className = '', buttonClassName = '', label = null, inlineLabel = false, size }: SelectType) {

  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const onSelected = (option) => {
    setSelectedValue(option)
    onSelect(option)
  }

  let classSize;
  switch (size) {
    case "xs":
      classSize = "py-0"
      break;
    case "sm":
      classSize = "py-1"
      break
    case "base":
      classSize = "py-1.5"
      break;
    case "md":
    default:
      classSize = "py-2"
      break
  }

  return (
    <Listbox disabled={disabled} value={selectedValue} onChange={(option) => onSelected(option)} as="div" className={clsx(inlineLabel && `flex items-center`, className)}>
      {({ open }) => (
        <>
          {label && <Listbox.Label className={clsx(`block text-sm font-medium text-gray-700`, inlineLabel && `mr-2`)}>{label}</Listbox.Label>}
          <div className="relative w-full">
            <Listbox.Button className={
              clsx(`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 text-left cursor-default text-sm`,
                `focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500`,
                buttonClassName, classSize)}>
              <span className="block truncate">{selectedValue?.libelle}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                {selectDatas?.map((item) => (
                  <Listbox.Option
                    key={item.code}
                    className={({ active }) =>
                      clsx(
                        (active) ? 'text-primary-700 bg-primary-100' : 'text-gray-900',
                        item.code == selectedValue?.code ? `text-primary-700` : `text-gray-900`,
                        'cursor-default select-none relative py-2 pl-8 pr-4'
                      )
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        {(selected || item.code == selectedValue?.code) ? (
                          <span
                            className={clsx(
                              'absolute inset-y-0 left-0 flex items-center pl-1.5 text-primary-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}

                        <span className={clsx((selected || item.code == selectedValue?.code) ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {item.libelle}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}