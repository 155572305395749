import React, { useEffect, useMemo, useState } from 'react'
import { useP } from '../../../services/i18n'
export type typeNewTicketType = "DATA" | "VOIX" | "HEBERGEMENT" | null
import { useLazyQuery } from '@apollo/client'
import { GET_PRESTATIONS } from './queries'
import InputSelect from '../../../Components/Form/InputSelect/InputSelect'
import Constants from '../../../commons/Constants'

type InputSelectPrestationType = {
  siteIds: string[]
  compteId: number | null
}

export default function InputSelectPrestation({ siteIds, compteId, ...field }: InputSelectPrestationType) {

  const p = useP();

  const [sitesSelected, setSitesSelected] = useState<string[]>(siteIds)
  const [defaultOptions, setDefaultOptions] = useState([])

  useMemo(() => {
    setSitesSelected(siteIds)
  }, [siteIds])

  useEffect(() => {
    const fetchData = async () => {
      const opt = await promiseOptionsPrestation("")
      setDefaultOptions(opt)
    }
    fetchData();
  }, [sitesSelected, compteId])

  const {
    ETAT_PRESTATION_INITIEE,
    ETAT_PRESTATION_EN_SERVICE,
    ETAT_PRESTATION_COMMANDE_EN_COURS
  } = Constants;

  const [getPrestations] = useLazyQuery(GET_PRESTATIONS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const promiseOptionsPrestation = async (filter: string) => {

    const filterPresta = filter ? [{
      column: "libelle",
      operator: "LIKE",
      value: `%${filter}%`,
      or: [
        {
          column: "type",
          operator: "EQ",
          value: filter,
        },
      ]
    }] : []

    const filterCompte = compteId ? [{
      column: "site.id_compte",
      value: compteId?.toString()
    }] : []

    const filterSite = siteIds.length > 0 ? [{
      column: "site.id",
      operator: "IN",
      value: sitesSelected
    }] : []

    return getPrestations({
      variables: {
        filters: [
          ...filterPresta,
          ...filterSite,
          ...filterCompte,
          {
            column: "etat",
            operator: "IN",
            value: [ETAT_PRESTATION_EN_SERVICE, ETAT_PRESTATION_COMMANDE_EN_COURS, ETAT_PRESTATION_INITIEE]
          }
        ],
        pagination: {
          limit: 100
        },
        sorters: [
          {
            column: "type",
          },
          {
            column: "libelle",
          }
        ],
      }
    }).then((result) => {
      const { data } = result;
      const options = data.prestations.items.reduce((a, p) => {

        const lastPrestation = a.length === 0 ? null : a[a.length - 1]
        if (lastPrestation?.label !== p.type) {
          a.push({
            label: p.type,
            index: 1,
            options: data.prestations.items.filter(p1 => p1.type === p.type).map(p => ({
              value: p.id,
              label: p.libelle
            }))
          })
        }
        return a;
      }, [])
      return options;
    })
  }

  return <InputSelect
    promiseOptions={promiseOptionsPrestation}
    defaultOptions={defaultOptions}
    isMulti={true}
    placeholder={`${p.t('support.modaleCreation.prestationsPlaceHolder')}`}
    label={`${p.t('support.modaleCreation.prestations')}`}
    menuPlacement="top"
    {...field}
  />

}
