import React from 'react'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb'
import { useP } from '../../services/i18n'
import ShellLayout from '../ShellLayout';
import ContactsContainer from './ContactsContainer'

function Contacts() {
  const p = useP();

  const breadcrumbDatas = [
    {
      libelle: p.t('contacts.mainTitle'),
      href: "#",
      current: true
    }
  ]

  return (
    <ShellLayout>
      <Breadcrumb datas={breadcrumbDatas} />
      <ContactsContainer />
    </ShellLayout>
  )

}

export default Contacts