import React from 'react'
import clsx from 'clsx'
import { TreeMenuElementType } from './TreeMenu'
import TreeMenuItemChild from './TreeMenuItemChild'

type TreeMenuItemType = {
  item: TreeMenuElementType,
  onActiveItemMenu: (activeItem: TreeMenuElementType) => void,
  activeItemMenu: string
}

export default function TreeMenuItem({ item, onActiveItemMenu, activeItemMenu }: TreeMenuItemType) {

  const onActiveMenu = (itemMenu) => {
    onActiveItemMenu(itemMenu)
  }

  return (
    <>
      <div
        className={clsx(`flex items-center pl-0.5 pr-1 py-2 text-sm font-medium rounded-md`)}
      >
        {item.icon && <item.icon className="mr-1 flex-shrink-0 h-5 w-5 text-secondary-500" aria-hidden="true" />}
        <span className="flex-1 ml-0.5 truncate">{item.name}</span>
        {
          item.count ? (
            <span
              className={clsx(
                `bg-secondary-200 text-secondary-800 group`,
                `ml-3 mr-1 inline-block py-0.5 px-2 text-xs font-medium rounded-full`
              )}
            >
              {item.count}
            </span>
          ) : null
        }
      </div>
      <div className="ml-3 pl-2 border-l border-gray-300">
        {item?.children &&
          item?.children.map((child, id) => (
            <TreeMenuItemChild
              active={(activeItemMenu === child.view) ? true : false}
              key={id} childItem={child}
              onActiveItem={(itemMenu) => onActiveMenu(itemMenu)} />
          ))
        }
      </div>
    </>
  )
}