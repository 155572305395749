import React, { useState } from 'react'
import CellCheckboxFormatter from '../../../Components/Table/Formatters/CellCheckboxFormatter';
import { useP } from '../../../services/i18n';
import RolesListItemsActionsInfo from '../TableContactDetail/Roles/RolesListItemsActions/RolesListItemsActionsInfo';

type LibelleFormatterType = {
  row: any,
}

export default function LibelleFormatter({ row }: LibelleFormatterType) {

  return <>
    <div className='flex items-center'>
      <div className='flex-1'>{row.libelle}</div>
      {row.descriptif && <div><RolesListItemsActionsInfo descriptif={row.descriptif} className="py-0 relative top-0.5" /></div>}
    </div>
  </>

}