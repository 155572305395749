import React from 'react'
import { NavigationType } from '../../../../types/NavigationType'
import MobileNavigationItem from './MobileNavigationItem'

type MobileNavigationItemsProps = {
  navigation: Array<NavigationType>
}

function MobileNavigationItems({ navigation }: MobileNavigationItemsProps) {

  return (
    <>
      {navigation.map((item, index) => (
        <MobileNavigationItem key={index} item={item} />
      ))}
    </>
  )
}

export default MobileNavigationItems