import Constants from "../commons/Constants";

const { STATUT_ERREUR, STATUT_DEGRADE, API_URL } = Constants

export const getStatutData = (p, statut) => {

  let data;


  switch (statut) {

    case STATUT_ERREUR:
      data = {
        color: "red",
        label: p.t('supervision.statuts.erreur')
      }
      break;

    case STATUT_DEGRADE:
      data = {
        color: "yellow",
        label: p.t('supervision.statuts.degrade')
      }
      break;


    default:
      data = {
        color: "green",
        label: p.t('supervision.statuts.actif')
      }
      break;
  }

  return data


}

export const getSupervisionData = (onSuccess, onError) => {
  fetch(`${API_URL?.replace('/graphql', "")}/supervision`)
    .then(res => res.json())
    .then(
      res => onSuccess(res)
    )
    .catch(e => onError(e))
}