import React, { useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { authContext } from '../../AuthContext';
import { useP } from '../../services/i18n';

function Compte({ multicompte = false }: { multicompte?: boolean, children?: any }) {

  const {
    loggedInUser,
    selectedCompte,
    setSelectedCompte
  } = useContext(authContext)

  const { compteId } = useParams();

  const p = useP();

  useEffect(() => {

    if (!compteId && !multicompte) {
      setSelectedCompte(loggedInUser?.compte?.id)
    }
    else if (compteId && !selectedCompte) {
      setSelectedCompte(compteId ? compteId : selectedCompte);
    }

  }, [compteId, selectedCompte])

  useEffect(() => {
    const selectedCompteSession = sessionStorage.getItem("selectedCompte")

    const url = window.location.pathname.split('/');

    if (selectedCompte && !Array.isArray(selectedCompte) && selectedCompte !== compteId) {
      sessionStorage.removeItem('selectedCompte');
      const compteRoute = p.t("router.compte.route");

      url[1] = compteRoute;
      url[2] = selectedCompte;

      window.location.pathname = url.join('/');

    }
    else if (selectedCompte && Array.isArray(selectedCompte) && selectedCompte.length > 0 && (!multicompte || JSON.stringify(selectedCompte) !== selectedCompteSession)) {
      sessionStorage.setItem('selectedCompte', JSON.stringify(selectedCompte));

      const compteRoute = p.t("router.comptes.route");

      url[1] = compteRoute;

      if (loggedInUser) {
        url[2] = loggedInUser?.compte.id;
      }

      window.location.pathname = url.join('/');
    }
  }, [selectedCompte])

  return (
    <>
      <Outlet />
    </>
  )
}

export default Compte