import React from 'react'
import { MultiSelectOptionType } from './Types/MultiSelectOptionType'


type MultiSelectSearchBoxItem = {
  item: MultiSelectOptionType
}
export default function MultiSelectSearchBoxItem({ item }: MultiSelectSearchBoxItem) {
  return <>
    <span className="inline-flex items-center mx-1 mb-1 py-1 pl-2 pr-0.5 rounded-md text-xs font-medium bg-primary-100 text-primary-700">
      {item.libelle}
      <button type="button" className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white">
        <span className="sr-only">Supprimer option</span>
        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"></path>
        </svg>
      </button>
    </span>
  </>
}