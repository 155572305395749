/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useMutation } from "@apollo/client";
import { ChevronUpDownIcon, PencilIcon, PlusIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useP } from "../../services/i18n";
import { GET_CONTACTS_ET_PERSONNES } from "../../Shell/Personnes/queries";
import Button from "../Button";
import InputCombobox from "../Form/InputCombobox/InputCombobox";
import Tooltip from "../Tooltip/Tooltip";
import { toastError } from "../Utils/Utils";
import ModalContacts from "../../Shell/Contacts/ModalContacts/ModalContacts";
import Constants from '../../commons/Constants';
import Spinner from "../Spinner";

const {
  TYPE_PERSONNE,
} = Constants;

type SuccessSaveType = {
  personne: null | any,
  success: boolean
}

type SelectionContactEtPersonneType = {
  onSelectPersonne?: (value: any) => void,
  entity: any,
  inlineEditable?: boolean,
  formErrors?: any
  defaultValue?: any,
  title?: any,
  contactIsEditable?: boolean,
  displayEmail?: boolean,
  filters: Array<any> | null
}

export default function SelectionContactEtPersonne({
  entity,
  defaultValue,
  inlineEditable = true,
  displayEmail = false,
  title,
  onSelectPersonne,
  contactIsEditable = false,
  filters = null,
  formErrors
}: SelectionContactEtPersonneType) {

  const p = useP();
  const datasPersonne = (datas) => {
    return datas.contactsEtPersonnes.items.map((personne) => {
      return {
        code: personne.id,
        libelle: `${personne.prenom} ${personne.nom}`,
        obj: personne
      }
    })
  }

  const defaultContact = defaultValue ? `${defaultValue.prenom} ${defaultValue.nom}` : ""
  const defaultCode = defaultValue ? `${defaultValue.id}` : ""
  const [contactSelected, setContactSelected] = useState<any>({ code: defaultCode, libelle: defaultContact });
  const [contacts, setContacts] = useState<any>([]);
  const [openModaleContact, setOpenModaleContact] = useState<boolean | null>(null)
  const [idContact, setIdContact] = useState(null);
  const [typeContact, setTypeContact] = useState(TYPE_PERSONNE);

  // On charge les contacts
  const [getContactsEtPersonnes, { data: dataContactsPersonnes, error: errorPersonnes, loading: loadingPersonnes }] = useLazyQuery(GET_CONTACTS_ET_PERSONNES, {
    variables: {
      filters,
      sorters: [
        {
          column: "nom",
          order: "ASC"
        },
      ]
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {

    getContactsEtPersonnes({
      onCompleted: (data) => {
        setContacts(datasPersonne(data))
      },
      onError: () => {
        console.error(errorPersonnes)
        toastError(p.t('error.defaultMessage'))
      }
    });
  }, [])


  useEffect(() => {
    if (dataContactsPersonnes) {
      setContacts(datasPersonne(dataContactsPersonnes))
    }

  }, [dataContactsPersonnes])

  useEffect(() => {
    if (entity) {
      setContactSelected({
        code: entity.contact.id,
        libelle: `${entity.contact.prenom} ${entity.contact.nom}`
      })
    }
  }, [entity])


  const closeModaleContact = () => {
    setOpenModaleContact(false)
  }

  const saveModaleContact = (personne) => {
    setOpenModaleContact(false)
    setContactSelected({ code: personne.id, libelle: `${personne.nom} ${personne.prenom}` })
    onSelectPersonne?.({ obj: { ...personne, typeContact: personne.typeContact } })
  }

  const prepareModaleContact = (create) => {
    if (create) {
      setIdContact(null);
      setTypeContact(TYPE_PERSONNE);
    } else {
      setIdContact(contactSelected.code);
      setTypeContact(entity?.contact?.typeContact);
    }
    setOpenModaleContact(true)
  }

  // Modale de confirmation
  const [confirmOpen, setConfirmOpen] = useState(false)
  const confirmMessage = (<>{p.t("actions.confirmCloseModale1")}<br />{p.t("actions.confirmCloseModale2")}</>)

  const confirmAction = (confirm) => {
    if (confirm) {
      //setOpenModalContacts(false)
      //setContact(null)
    }
    setConfirmOpen(false)
  }

  return <>
    <div className="flex items-center space-x-4">
      <div className={clsx(`flex-1`, inlineEditable && `border-r border-gray-300 pr-2`)}>
        <div className="">

          <InputCombobox
            maxResults={50}
            value={contactSelected}
            name="personne"
            label={inlineEditable ? '' : title ? title : `${p.t("components.selectionContact.inputLabel")} *`}
            options={contacts}
            autoComplete='off'
            iconTooltipContent={p.t('components.selectionContact.selectTooltipMessage')}
            onChange={(value) => {
              onSelectPersonne?.(value)
            }
            }
            listAction={{
              libelle: p.t('components.selectionContact.nouveauContact'),
              action: () => { prepareModaleContact(true) },
              icon: <UserPlusIcon className="text-primary-600 h-5 w-5" />
            }}
            classes={{
              input: clsx(formErrors?.personne && `border-red-600 border-2 rounded-md`, inlineEditable && 'py-0 border-0 text-sm pl-0 pr-5 focus:ring-0')
            }}
            loading={loadingPersonnes}
          />

        </div>
      </div>
      {contactIsEditable &&
        <>
          <Tooltip
            button={<Button
              onClick={() => { prepareModaleContact(false) }}
              size="small"
              color="ghost"
            >
              <PencilIcon className={clsx(`h-4 w-4 text-gray-500`)} />
            </Button>}
            content={<div className="text-xs">{p.t('components.selectionContact.editerContact')}</div>}
            placement='top'
          />
        </>
      }
    </div >
    {displayEmail && <div className="text-gray-700 text-xs">{entity?.contact?.mail}</div>
    }
    {
      openModaleContact &&
      <ModalContacts
        isOpen={openModaleContact}
        onClose={closeModaleContact}
        onSaveContact={saveModaleContact}
        idContact={idContact}
        confirmOpen={confirmOpen}
        confirmMessage={confirmMessage}
        confirmAction={(confirm => confirmAction(confirm))}
        typeContact={typeContact}
      />
    }
  </>
}