import React, { useCallback, useRef, useState } from "react";
import clsx from "clsx";
import SplitterHandlebar from "./SplitterHandlebar";
import { setLocalStorage } from "../Utils/Utils";
import SplitterCollapser from "./SplitterCollapser";

type SplitterType = {
  children: React.ReactNode[];
  orientation: "horizontal" | "vertical";
  min?: number;
  max?: number;
  firstHalfInitialSize: number;
  containerClassName?: string;
  collapsible?: 'first' | 'second' | false;
  LSName: string;
};

function Splitter({
  children,
  orientation,
  min = 0,
  max = 100,
  firstHalfInitialSize,
  containerClassName,
  collapsible=false,
  LSName

}:SplitterType) {
  const containerRef = useRef<any>();
  const firstHalfRef = useRef<any>();
  const secondHalfRef = useRef<any>();
  const resizerRef = useRef<any>();
  const collapserRef = useRef<any>();
  const [isDragging, setIsDragging] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleMouseDown = useCallback((e) => {
    const startPos = {
      x: e.clientX,
      y: e.clientY,
    };
    
    const firstHalfElement = firstHalfRef.current;
    const secondHalfElement = secondHalfRef.current;
    const currentLeftWidth = firstHalfElement ? firstHalfElement.getBoundingClientRect().width : 0;
    const currentFirstHeight = firstHalfElement ? firstHalfElement.getBoundingClientRect().height : 0;
    
    const handleMouseMove = (e) => {
      setIsDragging(true)
      setIsCollapsed(false)
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      firstHalfElement.style.pointerEvents = `none`;
      secondHalfElement.style.pointerEvents = `none`;
      firstHalfElement.style.userSelect = `none`;
      secondHalfElement.style.userSelect = `none`;
      
      orientation === "vertical"
        ? updateSizes(currentFirstHeight, dy, "height")
        : updateSizes(currentLeftWidth, dx, "width");
    };

    const handleMouseUp = () => {
      setLocalStorage(LSName, firstHalfRef.current.style.flex.split(" ")[0]);
      setIsDragging(false)
      firstHalfElement.style.pointerEvents = `auto`;
      secondHalfElement.style.pointerEvents = `auto`;
      firstHalfElement.style.userSelect = `auto`;
      secondHalfElement.style.userSelect = `auto`;
      if (window.getSelection) {
        const selection = window.getSelection();
        selection?.removeAllRanges();
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }, []);

  const updateSizes = (currentSize, dx,type ) => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;
    const secondHalfEle = secondHalfRef.current;
    
    let containerSize;
    if(type === "width"){
      containerSize = container.getBoundingClientRect().width;
    }else{
      containerSize = container.getBoundingClientRect().height;
    }
    const delta = currentSize + dx;
    let newFirstHalfSize = (delta * 100) / containerSize;

    // Appliquer les limites
    if (min && newFirstHalfSize < min) {
      newFirstHalfSize = min;
    } else if (max && newFirstHalfSize > max) {
      newFirstHalfSize = max;
    }
    firstHalfEle.style.flex = `${newFirstHalfSize} 1 0px`;
    secondHalfEle.style.flex = `${100-newFirstHalfSize} 1 0px`;
  };

  const onCollapse = () => {
    const firstHalfEle = firstHalfRef.current;
    const secondHalfEle = secondHalfRef.current;
    setIsCollapsed(!isCollapsed)
    if(collapsible && collapsible==='second'){
      firstHalfEle.style.flex = `100 1 0px`;
      secondHalfEle.style.flex = `0 1 0px`;
      setLocalStorage(LSName, "100");
    }else{
      firstHalfEle.style.flex = `0 1 0px`;
      secondHalfEle.style.flex = `100 1 0px`;
      setLocalStorage(LSName, "0");
    }
  }

  return (
    <div
      className={clsx(
        "h-full overflow-auto relative lg:flex-1 lg:flex lg:items-stretch lg:h-auto lg:overflow-hidden",
        orientation === "vertical" ? "flex-col lg:flex-col" : "flex-col lg:flex-row",
        containerClassName
      )}
      ref={containerRef}
    >
      <div
        className={clsx(
          "overflow-hidden relative group/collapsible",
        )}
        ref={firstHalfRef}
        style={{ flex: `${firstHalfInitialSize} 1 0px` }}
      >
        {collapsible && collapsible==='first' &&
          <SplitterCollapser
            onCollapse={onCollapse}
            collapserRef={collapserRef}
            collapsible={collapsible}
            orientation={orientation}
          />                
        }
        {children[0]}
      </div>
      <SplitterHandlebar
        orientation={orientation}
        resizerRef={resizerRef}
        handleMouseDown={handleMouseDown}
        isDragging={isDragging}
      />
      <div
        className={clsx(
          "overflow-hidden relative group/collapsible",
        )}
        ref={secondHalfRef}
        style={{ flex: `${100-firstHalfInitialSize} 1 0px` }}
      >        
        {collapsible && collapsible==='second' &&
          <SplitterCollapser
            onCollapse={onCollapse}
            collapserRef={collapserRef}
            collapsible={collapsible}
            orientation={orientation}
          />                
        }
        {children[1]}        
      </div>      
    </div>
  );
}
export default Splitter;

