import React from 'react';
import Spinner from '../../Spinner';
import ContactUser from './ContactUser';

import { IdentificationIcon } from "@heroicons/react/24/outline";
import { useP } from '../../../services/i18n';
import { useQuery } from '@apollo/client';
import { GET_SELECTED_COMPTE } from './queries';
import WidgetLayout from '../WidgetLayout/WidgetLayout';
import WidgetSize from '../WidgetSize';
import WidgetElementType from '../WidgetElementType';

function WidgetMesContactsTiny(props: WidgetElementType) {

    const { loading: loadingContact, data: dataContact } = useQuery(GET_SELECTED_COMPTE);

    const p = useP();
    return (

        <WidgetLayout
            libelle={p.t('dashboardCompte.contacts')}
            Icon={IdentificationIcon}
            color={"secondary"}
            size={WidgetSize.TINY}
            addWidget={props.addWidget}
            moveUpWidget={props.moveUpWidget}
            moveDownWidget={props.moveDownWidget}
            removeWidget={props.removeWidget}
        >
            {loadingContact
                ? <div className="flex items-center justify-center py-2 "><Spinner color="primary" /></div> :
                <div className="">
                    <div className="mb-2">
                        {
                            dataContact.selectedCompte.owner && <ContactUser contact={{
                                name: `${dataContact.selectedCompte.owner.prenom} ${dataContact.selectedCompte.owner.nom}`,
                                phone: dataContact.selectedCompte.owner.telephoneFixe,
                                fonction: "Commercial",
                                email: dataContact.selectedCompte.owner.mail
                            }} />
                        }
                    </div>
                    <div>
                        {
                            dataContact.selectedCompte.rocUser && <ContactUser contact={{
                                name: `${dataContact.selectedCompte.rocUser.prenom} ${dataContact.selectedCompte.rocUser.nom}`,
                                phone: dataContact.selectedCompte.rocUser.telephoneFixe,
                                fonction: "ROC",
                                email: dataContact.selectedCompte.rocUser.mail
                            }} />
                        }
                    </div>
                </div>
            }
        </WidgetLayout>
    )
}

export default WidgetMesContactsTiny