import React from "react"
import dayjs from "dayjs";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";


type DatepickerHeaderType = {
  date: any,
  changeYear: any,
  changeMonth: any,
  decreaseMonth: any,
  increaseMonth: any,
  prevMonthButtonDisabled: any,
  nextMonthButtonDisabled: any,
  yearPicker: boolean,
  monthPicker: boolean
}

export default function DatepickerHeader(
  { date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    yearPicker,
    monthPicker
  }: DatepickerHeaderType
) {

  const range = (start: any, end: any, length = end - start) =>
    Array.from({ length }, (_, i) => start + i);

  const generateYearRange = (currentYear: number, yearsBefore: number, yearsAfter: number) => {
    const startYear = currentYear - yearsBefore;
    const endYear = currentYear + yearsAfter + 1;
    return range(startYear, endYear);
  };

  const getYear = (date: Date) => {
    const parsedDate = dayjs(date);
    return parsedDate.year();
  };
  const getMonth = (dateString: Date) => {
    const parsedDate = dayjs(dateString, "DD/MM/YYYY");
    return parsedDate.month();
  };
  const years = generateYearRange(getYear(new Date()), 5, 10);
  const months = [
    "Janvier",
    "Férier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  return <>

    {
      (yearPicker || monthPicker) &&
      <div
        className="mx-2 mb-1 px-1 py-1 flex justify-center bg-gray-100 rounded-md"
      >
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="p-1 rounded-md">
          <ChevronLeftIcon className="h-4 w-4" />
        </button>
        <div className="flex items-center justify-center space-x-1 mx-1">
          {monthPicker &&
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              className="flex-1 text-center pl-3 pr-8 py-1 border border-gray-200 text-sm appearance-none rounded-md  cursor-pointer"
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          }
          {yearPicker &&
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
              className="flex-1 text-center pl-3 pr-8 py-1 border border-gray-200 text-sm appearance-none rounded-md  cursor-pointer"
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          }
        </div>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="p-1 rounded-md">
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    }
  </>
}