import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { authContext } from '../../AuthContext'
import Logo from '../../Components/Logo';
import { useP } from '../../services/i18n';

function HeaderIcon() {
  const {
    selectedCompte
  } = useContext(authContext)
  const p = useP();

  return (
    <div
      className="w-auto lg:px-4 flex items-center justify-center"
    >
      <Link to={`/${p.t('router.compte.route')}/${selectedCompte}`}>
        <Logo type="gold" className="hidden lg:block max-h-10" />      
        <Logo type="tiny" className="lg:hidden block max-h-10" />    
      </Link>
    </div>
  )
}

export default HeaderIcon