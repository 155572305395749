import React from "react"
import P from "../../../HelpBoxComponents/P"
import parse from 'html-react-parser';
import { useP } from "../../../../../services/i18n";
export default function HelpContentDashboardCompte_Q1(){
  const p = useP()
  return <>    
    <P>
      <>{parse(p.t('helpBox.HelpDashboardCompte.a1'))}</>      
    </P>    
  </>
}