import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import ButtonsGroupItem, { ButtonsGroupItemButtonType } from './ButtonsGroupItem'


type ButtonsGroupItemsType = {
  buttons: Array<ButtonsGroupItemButtonType>,
  onClickRadio: (value: string) => void,
  selected: string[],
  multi?: boolean,
  vertical: boolean,
  prepend?: boolean,
  selectAll?: boolean,
  forceHorizontalOnMobile?: boolean,
}
export default function ButtonsGroupItems({
  buttons,
  onClickRadio,
  selected,
  multi = true,
  vertical,
  prepend,
  forceHorizontalOnMobile,
  selectAll
}: ButtonsGroupItemsType) {

  return <>
    {buttons.map((button, i) =>
    (
      <div key={i}>
        {button.description
          ? <Tooltip
            button={
              <ButtonsGroupItem
                key={i}
                button={button}
                index={i}
                lastButton={buttons.length == i + 1}
                clickButton={onClickRadio}
                active={selected.includes(button.value)}
                multi={multi}
                hasPrepend={prepend ? true : false}
                vertical={vertical}
                forceHorizontalOnMobile={forceHorizontalOnMobile}
                selectAll={selectAll}
              />
            }
            content={<div className='text-xs'>{button.description}</ div>}
            placement='top'
          />
          : <ButtonsGroupItem
            button={button}
            index={i}
            lastButton={buttons.length == i + 1}
            clickButton={onClickRadio}
            active={selected.includes(button.value)}
            multi={multi}
            hasPrepend={prepend ? true : false}
            vertical={vertical}
            forceHorizontalOnMobile={forceHorizontalOnMobile}
            selectAll={selectAll}
          />
        }
      </div>
    ))}
  </>
}