import React, { useContext, useEffect, useState } from "react"
import { PhoneIcon, GlobeAltIcon, ComputerDesktopIcon } from '@heroicons/react/24/outline'
import { useP } from "../../services/i18n"
import PrestationsContainer from "./PrestationsContainer"
import LienView from "./PrestationView/LienView/LienView";
import GSMView from "./PrestationView/GSMView/GSMView";
import VGAView from "./PrestationView/VGAView/VGAView";
import NDIView from "./PrestationView/NDIView/NDIView";
import CENTILEView from "./PrestationView/CENTILEView/CENTILEView";
import TRUNKView from "./PrestationView/TRUNKView/TRUNKView";
import { GET_PRESTATIONS, GET_SITES } from "./queries";
import { useQuery } from "@apollo/client";
import { PrestationsViewType } from "./PrestationsType";
import { useSearchParams } from "react-router-dom";
import ShellLayout from "../ShellLayout";
import SlideOver from "../SlideOver/SlideOver";
import SitePrestations from '../SlideOver/RightContainers/SitePrestations'
import { reduceSites } from "../SlideOver/SlideOverReducer";
import dayjs from "dayjs";
import { authContext } from "../../AuthContext";
import VCCView from "./PrestationView/VCCView/VCCView";
import VCDView from "./PrestationView/VCDView/VCDView";

export const prestationViewMap = new Map([
  ["GSM", { view: GSMView }],
  ["VGA", { view: VGAView }],
  ["NDI", { view: NDIView }],
  ["CENTILE", { view: CENTILEView }],
  ["TRUNK", { view: TRUNKView }],
  ["LIEN", { view: LienView }],
  ["VCC", { view: VCCView }],
  ["VCD", { view: VCDView }],
]);

type PrestationsType = {
  view: string
}

const dateFermetureCheck = dayjs().format("YYYY-MM-DD HH:mm:ss");

export default function Prestations({ view }: PrestationsType) {

  const p = useP();

  const {
    selectedCompte,
    loggedInUser
  } = useContext(authContext)

  const [searchParams] = useSearchParams();
  const siteId = searchParams.get('site');

  // Table Datas site
  const { loading: loadingSite, data: dataSite, error: errorSite } = useQuery(GET_SITES, {
    variables: {
      sorters: [
        {
          column: "nom"
        },
        {
          column: "codePostal"
        },
      ],
      attributes: [
        "id",
        "codePostal",
        "commune",
        "complementVoie",
        "libelleVoie",
        "nom",
        "numeroVoie",
      ],
      includes: [
        {
          association: "prestations",
          attributes: [
            "etat",
            "libelle",
            "dateResiliation",
            "type",
            "isVisibleEc",
          ]
        }
      ],
      filters: [{
        column: "date_fermeture_site",
        operator: "GTE",
        value: dateFermetureCheck
      },
      {
        column: "date_fermeture_site",
        operator: "IS",
        value: null,
      }, {
        column: "prestations.is_visible_ec",
        value: "1"
      }]
    }
  });

  // Table Datas prestation
  const filtersPresta = siteId ? [
    {
      column: "site.id",
      value: siteId
    }
  ] : []
  const { loading: loadingPrestations, data: dataPrestations, error: errorPrestations } = useQuery(GET_PRESTATIONS, {
    variables: {
      attributes: [
        "etat",
        "libelle",
        "dateResiliation",
        "type"
      ],
      filters: filtersPresta,
    },
  });

  const prestationsByType = (typesPresta: string[]) => {

    if (!dataPrestations) return [];

    const prestas = dataPrestations.prestations.items.filter((p) => {
      return typesPresta.includes(p.type)
    })
    return prestas ? prestas : []
  };

  const prestasGSM = prestationsByType(["GSM"]);
  const prestasVGA = prestationsByType(["VGA"]);
  const prestasNDI = prestationsByType(["NDI"]);
  const prestasCENTILE = prestationsByType(["CENTILE"]);
  const prestasTRUNK = prestationsByType(["TRUNK"]);
  const prestasLIEN = prestationsByType(["LIEN"]);
  const prestasVCD = prestationsByType(["VCD"]);
  const prestasVCC = prestationsByType(["VCC"]);

  const navigation: any = [
    {
      name: 'Data', icon: GlobeAltIcon, count: prestasLIEN.length, current: false, children: [
        { name: 'Liens', view: 'LIEN', href: `../lien${siteId ? "?site=" + siteId : ""}`, count: prestasLIEN.length, prestations: prestasLIEN, current: false },
      ]
    },
    {
      name: 'Téléphonie', icon: PhoneIcon, count: prestasGSM.length + prestasVGA.length + prestasNDI.length + prestasCENTILE.length + prestasTRUNK.length, current: false, children: [
        { name: 'GSM', view: 'GSM', href: `../gsm${siteId ? "?site=" + siteId : ""}`, count: prestasGSM.length, prestations: prestasGSM, current: true },
        { name: 'VGA', view: 'VGA', href: `../vga${siteId ? "?site=" + siteId : ""}`, count: prestasVGA.length, prestations: prestasVGA, current: false },
        { name: 'NDI', view: 'NDI', href: `../ndi${siteId ? "?site=" + siteId : ""}`, count: prestasNDI.length, prestations: prestasNDI, current: false },

        // Centile : on masque temporairement le nombre de presta ( https://dev.azure.com/bt-blue-dsi/gestion-projet/_workitems/edit/2106 )
        // { name: 'Centile', view: 'CENTILE', href: `../centile${siteId ? "?site=" + siteId : ""}`, count: prestasCENTILE.length, prestations: prestasCENTILE, current: false },
        { name: 'Centile', view: 'CENTILE', href: `../centile${siteId ? "?site=" + siteId : ""}`, count: 0, prestations: prestasCENTILE, current: false },

        { name: 'Trunk', view: 'TRUNK', href: `../trunk${siteId ? "?site=" + siteId : ""}`, count: prestasTRUNK.length, prestations: prestasTRUNK, current: false },
      ]
    },
    {
      name: 'Hébergement', icon: ComputerDesktopIcon, count: prestasVCD.length + prestasVCC.length, current: false, children: [
        { name: 'VCD', view: 'VCD', href: `../vcd${siteId ? "?site=" + siteId : ""}`, count: prestasVCD.length, prestations: prestasVCD, current: false },
        { name: 'VCC', view: 'VCC', href: `../vcc${siteId ? "?site=" + siteId : ""}`, count: prestasVCC.length, prestations: prestasVCC, current: false },        
      ]
    },
  ]

  const [activeView, setActiveView] = useState(view);
  useEffect(() => {
    setActiveView(view)
  }, [view])

  const getView = (v: string) => {
    const prestation = prestationViewMap.get(v);
    if (prestation) {
      const PrestationView: ({ siteId }: PrestationsViewType) => JSX.Element = prestation.view;
      return <PrestationView siteId={siteId} />
    }
    return <></>
  }

  const onActiveMenu = (item) => {
    setActiveView(item.view)
  }

  const slideOverData = reduceSites(dataSite?.sites ? dataSite?.sites : [])
  const site = dataSite?.sites.find((s) => s.id == siteId)

  const allViews = navigation.map((nav) => nav.children).reduce((a, b) => a.concat(b))
  const activeViewObj = allViews.filter((child) => child.view == activeView);

  const homeLink = Array.isArray(selectedCompte) ?
    `/${p.t('router.comptes.route')}/${loggedInUser?.compte.id}` :
    `/${p.t('router.compte.route')}/${selectedCompte}`

  const breadcrumbDatas = [
    {
      libelle: p.t('navigation.prestations.label'),
      href: `${homeLink}/prestations`,
      current: false
    },
    {
      libelle: site ? site?.nom : p.t('prestations.tousSites'),
      href: `${homeLink}/prestations/lien${site ? `?site=${site.id}` : ''}`,
      current: false
    },
    {
      libelle: activeViewObj[0]?.name,
      href: `.${site ? `?site=${site.id}` : ''}`,
      current: true
    }
  ]

  return (
    <>
      <SlideOver
        records={slideOverData}
        defaultItemId={siteId || 0}
        rightContainer={SitePrestations}
        allItemsMenu={{
          link: ".",
          title: p.t('prestations.tousSites')
        }}
        filter={(record: any, searchValue: string) => {

          const { record: site } = record

          const search = searchValue.toUpperCase().trim().replace(/[\s]{2,}/g, " ")

          return ((site.codePostal?.toUpperCase().includes(search))
            || (site.commune?.toUpperCase().includes(search))
            || (site.nom?.toUpperCase().includes(search))
            || (site.numeroVoie?.toUpperCase().includes(search))
            || (site.libelleVoie?.toUpperCase().includes(search))
            || (site.complementVoie?.toUpperCase().includes(search))
          )

        }}
        placeholder={p.t("slideOver.search.placeholderSites")}
      />
      <ShellLayout>
        <PrestationsContainer
          loading={loadingPrestations || loadingSite}
          error={errorPrestations || errorSite}
          site={site}
          breadcrumb={breadcrumbDatas}
          treeMenuDatas={navigation}
          activeItemMenu={activeView}
          onActiveItemMenu={onActiveMenu}
          activeView={getView(activeView)}
        />
      </ShellLayout>
    </>
  )
}