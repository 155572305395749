import { gql } from "@apollo/client"

const GET_COMMENTAIRES = gql`
  query TicketCommentaires($ticketId: ID!) {
    ticketCommentairesWithMaitre(ticketId: $ticketId) {
      ticket {
        id
        numero              
      }
      dateCreation
      id
      ticketeurUser {
        prenomNomContact
      }
      description
      descriptionWithImg
      contact {
        nom
        prenom
      }
      dateLecture
      ticketPieceJointes {
        dateCreation
        extensionFichier
        fichier
        id
      }
    }
  }
  `

const GET_TICKET = gql`
  query Ticket($ticketId: ID!) {
    ticket(id: $ticketId) {              
      id
      numero
      titre
      qualificatif
      dateCreation
      activiteTotaleImpactee
      isRecurrent
      ticketCriticite {
        libelle
      } 
      ticketStatut {
        id
        statutPublic
        isClos
      }
      ticketImpact{
        libelle
      }          
      compte {
        raisonSociale
      }
      nomContactSite
      numeroContactSite
      listeEmailsSuiveurs
      sites {
        id
        nom
        codePostal
        commune
        numeroVoie
        libelleVoie
      }
      ticketeurUser {
        id
        nom
        prenom
      }
      referenceClient
      ticketService {
        libelle
      }
      prestations {
        libelle
      }
      client {
        numcli
        nomContrat      
      } 
      ticketPrecedent {
        id
        numero
        titre
      }
      ticketRating {
        id
        rating
        commentaire
        isModifiableClient
      }
    }  
  }`

const NEW_TICKET_COMMENTAIRE = gql`
  mutation NewTicketCommentaireClient($ticketId: ID!, $commentaire: String!, $files: [String], $activiteTotaleImpactee: Boolean) {
    newTicketCommentaireClient(ticketId: $ticketId, commentaire: $commentaire, files: $files, activiteTotaleImpactee: $activiteTotaleImpactee) {
      message
      success
      ticketCommentaire {        
        ticket {
          ticketStatut {
            id
          }
        }
      }
    }
  }
`

const SAVE_TICKET = gql`
  mutation SaveTicket($ticket: TicketInput!) {
    saveTicket(ticket: $ticket) {
      id
    }
  }
`

const TELECHARGER_PJ = gql`
  query TelechargerTicketPieceJointe($telechargerTicketPieceJointeId: ID) {
    telechargerTicketPieceJointe(id: $telechargerTicketPieceJointeId) {
      filename
      hash
    }
  }
`

const LECTURE_TICKET = gql`
  mutation TicketLectureClient($ticketId: ID!) {
    ticketLectureClient(ticketId: $ticketId) {
      message
      success
    }
  }`

const TICKET_PUBLIC = gql`
  mutation TicketLienPublic($ticketId: ID!) {
    ticketLienPublic(id: $ticketId)
  }`

export {
  GET_TICKET,
  LECTURE_TICKET,
  GET_COMMENTAIRES,
  NEW_TICKET_COMMENTAIRE,
  SAVE_TICKET,
  TELECHARGER_PJ,
  TICKET_PUBLIC
}