import React from 'react'

type CellNumberFormatterType = {
  value: string,
}

export default function CellNumberFormatter({ value }: CellNumberFormatterType) {
  return <>
    <p className='text-right'>
      {value}
    </p>
  </>
}