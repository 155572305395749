import React from "react"
import { BreadcrumbDataType } from "../../Components/Breadcrumb/BreadcrumbDataType";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import DocumentationTree from "./DocumentationTree";
import DocumentationFichiers from "./DocumentationFichiers/DocumentationFichiers";
import SlideOver from "../../Components/SlideOver/SlideOver";
import { DocumentTypeType } from "../../types/DocumentTypeType";
import { DocumentType } from "../../types/DocumentType";
import { DocumentApprovalType } from "../../types/DocumenApprovalType";

type DocumentationContainerType = {
  breadcrumb: Array<BreadcrumbDataType>,
  dataTypeDocuments: Array<DocumentTypeType>,
  loadingTypeDocuments: boolean,
  dataDocuments: Array<DocumentType>,
  dataDocumentApprovals: Array<DocumentApprovalType>,
  loadingDocuments: boolean,
  onSelectFolderTree: (id: Array<string>) => void,
  onCloseMobileTreeMenuIsOpen: () => void,
  mobileTreeMenuIsOpen: boolean
  selectedItem?: string | null
}

export default function DocumentationContainer({
  breadcrumb,
  dataTypeDocuments,
  loadingTypeDocuments,
  dataDocuments,
  dataDocumentApprovals,
  loadingDocuments,
  onSelectFolderTree,
  mobileTreeMenuIsOpen,
  onCloseMobileTreeMenuIsOpen,
  selectedItem
}: DocumentationContainerType) {

  return <>
    <Breadcrumb datas={breadcrumb} />

    {mobileTreeMenuIsOpen && <SlideOver onCloseSlideOver={onCloseMobileTreeMenuIsOpen} isOpen={mobileTreeMenuIsOpen}><div>child</div></SlideOver>}

    <div className="lg:flex lg:flex-1 lg:h-full mt-2  lg:space-x-2 md:space-x-6 overflow-hidden pb-1">
      <DocumentationTree
        dataTypeDocuments={dataTypeDocuments}
        loadingTypeDocuments={loadingTypeDocuments}
        onSelect={onSelectFolderTree}
        selectedItem={selectedItem}
      />
      <DocumentationFichiers
        dataDocuments={dataDocuments}
        dataDocumentApprovals={dataDocumentApprovals}
        loadingDocuments={loadingDocuments}
      />
    </div>
  </>
}