import React, { useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { PencilIcon, CheckIcon } from '@heroicons/react/24/solid'
import InputCombobox from "../Form/InputCombobox/InputCombobox";
import clsx from 'clsx'
import { useP } from '../../services/i18n'
import { Input } from '../Form/Input'
import Tooltip from '../Tooltip/Tooltip'
import { OptionType } from 'dayjs';

type EditableType = {
  value: any,
  onSaveField: (value: any) => Promise<true | false> | void
  maxLength?: number,
  type?: "number" | "text" | "search" | "time" | "date" | "email" | "password",
  className?: string,
  inputClass?: string,
  inputType?: "input" | "select",
  selectMaxResults?: number,
  inputComboboxOptions?: Array<OptionType> | undefined | any,
  isEditing?: (value: boolean) => void,
  editTooltipMessage?: string,
  saveTooltipMessage?: string,
  cancelTooltipMessage?: string,
  error?: string
  onChange?: (value: string) => void,
}

export const EMPTY_FIELD = "--";

export default function Editable({
  value,
  onSaveField,
  isEditing,
  className = '',
  type = 'text',
  inputType = 'input',
  inputComboboxOptions,
  inputClass = "w-full px-2 py-1 border-0 italic shadow-none rounded-md bg-gray-100 focus:outline-none focus:ring-0",
  selectMaxResults,
  editTooltipMessage,
  saveTooltipMessage,
  cancelTooltipMessage,
  error,
  onChange,
  ...props }: EditableType) {

  const p = useP();
  const [editing, setEditing] = useState(false)
  const [lastSaveValue, setLastSaveValue] = useState<any>(value)
  const editField = () => {
    setEditing(true)
    isEditing?.(true)
  }
  const saveField = async () => {
    const resultSave = await onSaveField(inputValue)
    if (resultSave) {
      setEditing(false)
      isEditing?.(false)
      setLastSaveValue(inputValue)
    }
  }

  const onCancel = () => {
    setInputValue(lastSaveValue);
    setEditing(false);
    isEditing?.(false)
  }

  const [inputValue, setInputValue] = useState(value)
  const onInputChange = (value) => {
    onChange?.(value)
    setInputValue(value)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleKeyPress = (event) => {
    if (error) {
      return;
    }

    if (event.key === "Enter") {
      saveField()
      event.preventDefault();
    } else if (event.key === "Escape") {
      onCancel();
      event.preventDefault();
    }
  };

  const getInputType = (inputType) => {
    switch (inputType) {
      case "select":
        return <InputCombobox
          maxResults={selectMaxResults}
          name=""
          label=""
          classes={{ input: "py-1 border-0 shadow-none rounded-md bg-gray-100 focus:outline-none focus:ring-0 text-sm italic" }}
          options={inputComboboxOptions}
          autoComplete='off'
          //value={value}
          onChange={(value) => onInputChange(value)} />
        break;
      case "input":
        return <Input
          {...props}
          onKeyDown={handleKeyPress}
          onChange={(event) => onInputChange(event.target.value)}
          autoFocus
          name='inputEditable'
          type={type}
          errors={error ? { inputEditable: { message: error } } : []}
          defaultValue={inputValue == EMPTY_FIELD ? "" : inputValue}
          className={clsx(inputClass)}
        />
      default:
        break
    }
  }

  return <>
    <div className={clsx(`flex items-center`, className)}>
      <div className={
        clsx(
          editing && `flex-1`
        )
      }>
        {
          editing
            ? getInputType(inputType)
            : <>{inputValue == "" || inputValue == null ? EMPTY_FIELD : (typeof inputValue === "object") ? inputValue.libelle : inputValue}</>
        }
      </div>

      <div className=''>
        <div className='cursor-pointer' >
          {
            editing
              ?
              <div className='flex items-center'>
                <Tooltip
                  button={
                    <div className='mx-3 lg:mx-2 flex items-center justify-center'>
                      <button className='p-0.5 rounded-md bg-white/80 hover:bg-gray-200' onClick={onCancel}>
                        <XMarkIcon
                          className='h-4 w-4 text-gray-500 hover:text-gray-700' />
                      </button>
                    </div>
                  }
                  content={<div className='text-xs'>{cancelTooltipMessage || p.t('actions.annuler')}</ div>}
                  placement='top'
                  className='flex items-center justify-center'
                />

                <Tooltip
                  button={
                    <div className='flex items-center justify-center'>
                      <button
                        disabled={!!error}
                        className={clsx(
                          'p-0.5 rounded-md',
                          error ? "bg-gray-300" : "bg-emerald-500 hover:bg-emerald-400"
                        )}
                        onClick={saveField}>
                        <CheckIcon
                          className='h-4 w-4 text-white' />
                      </button>
                    </div>
                  }
                  content={<div className='text-xs'>{saveTooltipMessage || p.t('actions.enregistrer')}</ div>}
                  placement='top'
                  className='flex items-center justify-center'
                />

              </div>
              :
              <>

                <Tooltip
                  button={
                    <div className='p-1 ml-1' onClick={editField}>
                      <PencilIcon
                        className='h-4 w-4 text-gray-400 hover:text-secondary-500' />
                    </div>
                  }
                  content={<div className='text-xs'>{editTooltipMessage || p.t('actions.editer')}</ div>}
                  placement='top'
                  className='flex items-center justify-center'
                />

              </>
          }
        </div>
      </div>
    </div>
  </>
}