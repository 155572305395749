import { Transition } from '@headlessui/react';
import React, { ReactNode, useContext } from 'react';
import { AccordionItemContext } from './AccordionItem';
export type AccordionItemBodyProps = {
  /** Children of the AccordionHeader */
  children: ReactNode;
};
const AccordionItemBody = ({ children, ...rest }: AccordionItemBodyProps) => {
  const { isExpanded }: any = useContext(AccordionItemContext);

  return (
    <>
      {isExpanded && (
        <div
          // initial="collapsed"
          // animate="expanded"
          // exit="collapsed"
          // variants={{
          //   expanded: { opacity: 1, height: "auto" },
          //   collapsed: { opacity: 0, height: 0, transition: { duration: 0.15 } }
          // }}
          {...rest}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default AccordionItemBody;