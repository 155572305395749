import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import ButtonsGroup from '../../../../Components/ButtonsGroup/ButtonsGroup';
import Table from '../../../../Components/Table/Table'
import { useP } from '../../../../services/i18n';
import PrestationDetailModaleVGA from './PrestationDetailModaleVGA';
import { PrestationsViewType } from '../../PrestationsType';
import { GET_VGA } from '../../queries';

export default function VGAView({ siteId }: PrestationsViewType) {

  const [openModaleDetailPrestation, setOpenModaleDetailPrestation] = useState(false)
  const [modalePrestation, setModalePrestation] = useState<number>(0)

  const onOpenModaleDetailPrestation = (row) => {
    setModalePrestation(row)
    setOpenModaleDetailPrestation(true)
  }
  const onCloseModaleDetailPrestation = () => {
    setOpenModaleDetailPrestation(false)
  }
  const editRow = (row) => { onOpenModaleDetailPrestation(row) }

  // Table Datas
  const { loading, data } = useQuery(GET_VGA, {
    variables: {
      idSite: siteId,
    },
  });

  const p = useP();

  const columns = [
    {
      libelle: p.t('prestations.vga.columns.etat'),
      code: 'etat',
      colType: 'state',
    },
    {
      libelle: p.t('prestations.vga.columns.site'),
      code: 'site.nom',
      colType: 'adresse',
      formatterObjectCode: 'site',
      sortable: true
    },
    {
      libelle: p.t('prestations.vga.columns.numero'),
      code: 'numero',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.vga.columns.sda'),
      code: 'sda',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('prestations.vga.columns.type'),
      code: 'type',
      colType: 'string',
      sortable: true
    },
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: editRow,
      resizable: false
    },
  ]

  const filterButtons = [
    { libelle: p.t('etat.prestation.EN SERVICE'), value: "EN SERVICE" },
    { libelle: p.t('etat.prestation.INITIEE'), value: "INITIEE" },
    { libelle: p.t('etat.prestation.COMMANDE EN COURS'), value: "COMMANDE EN COURS" },
    { libelle: p.t('etat.prestation.COMMANDE REJETEE'), value: "COMMANDE REJETEE" },
    { libelle: p.t('etat.prestation.RESILIEE'), value: "RESILIEE" },
  ]

  const [tableDatas, setTableDatas] = useState<any[]>([]);
  const [filterEtatPresta, setFilterEtatPresta] = useState<string | string[]>(["EN SERVICE", "INITIEE", "COMMANDE EN COURS"]);

  useEffect(() => {
    let datas = data ? data.prestationVgas : [];
    if (filterEtatPresta) {
      datas = datas.filter((d) => filterEtatPresta.includes(d.etat))
    }

    setTableDatas(datas)
  }, [data, filterEtatPresta]);

  return (
    <>
      <Table
        idTable='table-vga'
        loading={loading}
        datas={
          tableDatas.map((d) => ({
            ...d,
            etat: {
              code: d.etat,
              libelle: p.t(`etat.prestation.${d.etat}`),
              colorClasses: `bg-${d.etatColor}-200 text-${d.etatColor}-800`
            }
          }))
        }
        columns={columns}
        customToolbarActions={
          <>
            <div className=''>
              <ButtonsGroup
                onSelectedButton={(value) => setFilterEtatPresta(value)}
                buttons={filterButtons}
                selectedButtons={filterEtatPresta}
                multi={true}
              />
            </div>
          </>
        }
        onClickRow={(row) => onOpenModaleDetailPrestation(row)}
        options={{
          search: {
            placeholder: p.t('prestations.searchPlaceholder'),
          },
        }}
      />
      <PrestationDetailModaleVGA
        prestation={modalePrestation}
        isOpen={openModaleDetailPrestation}
        onClose={onCloseModaleDetailPrestation}
      />
    </>)
}