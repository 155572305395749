import React, { useContext } from "react"
import { authContext } from "../../AuthContext";

type CheckRigthType = {
  children: JSX.Element[] | JSX.Element,
  role: string
}

export const useCheckRight = () => {

  const { roles } = useContext(authContext);

  const checkRight = (role: string) => {
    if (roles === null)
      return false;

    return roles.includes(role);
  }

  return { checkRight }
}

export default function CheckRigth({ children, role }: CheckRigthType) {

  const { checkRight } = useCheckRight();

  return checkRight(role) ? <>{children}</> : <></>
}