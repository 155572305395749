import React from 'react';
import clsx from 'clsx';
import { getStatutData } from '../utils';
import { useP } from '../../services/i18n';

function SupervisionItem(props: { label: string, statut: string }) {

    const p = useP();

    return (
        <div className={clsx(
            "w-full rounded-lg shadow-lg text-white font-semibold text-lg px-6 py-3",
            `bg-${getStatutData(p, props.statut).color}-600`
        )}>
            <div>
                {getStatutData(p, props.statut).label}
            </div>
        </div >
    )
}

export default SupervisionItem