import React from "react"
import { TableOptionsType } from "../../../Components/Table/Types/TableOptionsType"
import Table from "../../../Components/Table/Table"
import { useP } from "../../../services/i18n"
import { EyeIcon } from "@heroicons/react/24/solid"
import { ThumbnailType } from "./DocumentationThumbnail"
import { LinkIcon } from "@heroicons/react/24/outline"


type DocumentationViewTableType = {
  datas: Array<ThumbnailType>,
  onClickRow: (thumbnail: ThumbnailType) => void,
  onCopyLink: (thumbnail: ThumbnailType) => void,
}

export default function DocumentationViewTable({ datas, onClickRow, onCopyLink }: DocumentationViewTableType) {

  const p = useP()

  const tableOptionsDefault: TableOptionsType = {
    colSelect: false,
    pagination: false,
    search: false,
    fullBordered: false,
    resizable: false,
    naturalWidth: true,
    toolbar: false
  }
  const columns = [

    {
      libelle: p.t('documentation.tableDocument.columns.titre'),
      code: 'titre',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('documentation.tableDocument.columns.extension'),
      code: 'extension',
      colType: 'string',
      sortable: true
    },
    {
      libelle: p.t('documentation.tableDocument.columns.version'),
      code: 'version',
      colType: 'string',
    },
    {
      libelle: p.t('documentation.tableDocument.columns.dateModification'),
      code: 'dateModification',
      colType: 'date',
      sortable: true
    },
    {
      libelle: p.t('documentation.tableDocument.columns.dateApproval'),
      code: 'dateApproval',
      colType: 'dateTime',
      sortable: true,
    },
    {
      libelle: "",
      code: '',
      colType: 'iconAction',
      sortable: false,
      icon: <EyeIcon className='h-5 w-5 text-gray-500 group-hover:text-secondary-500 mx-2' />,
      action: (row) => onClickRow(row),
      tooltipMessage: p.t('actions.voir')
    },
    {
      libelle: "",
      code: '',
      colType: 'iconAction',
      sortable: false,
      icon: <LinkIcon className='h-4 w-4 text-gray-500 group-hover:text-secondary-500 mx-2' />,
      action: (row) => onCopyLink(row),
      tooltipMessage: p.t('documentation.tooltips.lienCopie')
    },
  ]

  return <div className="p-2">
    <Table
      idTable="documentationTable"
      loading={false}
      columns={columns}
      datas={datas}
      options={tableOptionsDefault}
    />
  </div>
}