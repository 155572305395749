import React, { useState } from 'react'
import { useP } from '../../services/i18n'
import DisponibilitesLogin from './DisponibilitesLogin'
import MesuresLogin from './MesuresLogin/MesuresLogin'
import SupervisionFilters from './SupervisionFilters'
import SupervisionFiltersValue from './SupervisionFiltersValue'


type SupervisionLoginType = {
  login: any,
}

export default function SupervisionLogin({ login }: SupervisionLoginType) {
  const p = useP();
  const defaultFilter = [
    {
      filter: {
        id: "date",
        label: "Date",
        type: "date",
      },
      operator: {
        code: "FIXE",
        label: "est",
      },
      value: "THISYEAR",
      libValue: "Cette année",
    },
  ];

  const ls_filter_key = "ec-supervision-lien-filter";
  const [filters, setFilters] = useState(
    localStorage.getItem(ls_filter_key)
      ? JSON.parse(localStorage.getItem(ls_filter_key) as string)
      : defaultFilter
  );

  const dateFilters = [
    {
      id: "date",
      label: "Date",
      type: "date",
    },
  ]

  const addFilters = (filters) => {
    localStorage.setItem(ls_filter_key, JSON.stringify(filters));
    setFilters(filters);
  }

  return <>
    <div className='px-0 py-0'>
      <div className='flex items-start justify-between mb-2'>
        <div>
          <div className='text-primary-700 text-xl font-semibold'>{p.t('supervisionLien.supervision.title')}*</div>
          <div className='text-xs text-gray-500'>
          {p.t('supervisionLien.supervision.subtitle')}
          </div>
        </div>
        <div className="flex items-center border border-gray-200 rounded-md pl-2">
          <SupervisionFiltersValue filters={filters} />
          <SupervisionFilters addFilters={addFilters} filters={dateFilters} />
        </div>
      </div>
      <DisponibilitesLogin login={login.login} filters={filters} />
      <MesuresLogin login={login.login} filters={filters} />
    </div>
  </>
}