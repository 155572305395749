import React from 'react';
import Button from '../../../Components/Button';
import { useP } from '../../../services/i18n';

type ModaleDetailTicketFooterType = {
  onClickFermer: () => void,
}


export default function ModaleDetailTicketFooter({ onClickFermer }: ModaleDetailTicketFooterType) {
  const p = useP();

  return <>
    <div className='flex items-center justify-end'>
      <Button onClick={onClickFermer}>{p.t('support.detail.fermer')}</Button>
    </div>
  </>;
}